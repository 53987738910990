import React from "react";
import BuyService from "../../../../Common/StripeForms/BuyService";

const Payment = ({ handleNext }: any) => {
  return (
    <div>
      <div className="step-innercontent step5 payment-step5">
        <div className="consult-section-heading text-center">
          <h3 className="my-5 p-0">Payment</h3>
        </div>
        <div>
          <div className="mt-5">
            <div className="row">
              <BuyService requestCB={handleNext} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Payment;
