import React from "react";

export interface AddClassNameProps {
  element: string;
  allowAddClassName: boolean;
  className: string;
}

const useAddClassName = ({
  element,
  allowAddClassName,
  className,
}: AddClassNameProps) => {
  React.useEffect(() => {
    document
      .querySelector(element)
      ?.classList?.[allowAddClassName ? "add" : "remove"](className);

    return () => document.querySelector("html")?.classList?.remove(className);
  }, [allowAddClassName, className, element]);

  return null;
};

export default useAddClassName;
