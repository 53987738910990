import React from "react";
import { Row } from "react-bootstrap";
import "./Statistics.scss";

interface Statistic {
  title: string;
  value: string;
  type: string;
  color: string;
}
interface Props {
  title: string;
  statistics: Statistic[];
}

const Statistics = ({ statistics, title }: Props) => {
  return (
    <div className="container-fluid statistics">
      <Row className="justify-content-center">
        <h2 className="my-2">
          {title}
        </h2>
        <div className="d-flex flex-wrap justify-content-center w-100">
          {statistics.map(({ title, value, type, color }) => (
            <div className="d-flex justify-content-center flex-column align-items-center mx-5 my-35">
              <div className="statics">
                <span className="sm-follorers count" style={{ color: color }}>
                  {value}
                </span>
                <span className="value">{type}</span>
              </div>
              <div className="stats-head">{title}</div>
            </div>
          ))}
        </div>
      </Row>
    </div>
  );
};

export default Statistics;
