import React, { useState } from "react";
import "./preLogin.scss";
import Hero from "./components/hero/Hero";
import WhatIsIncluded from "./components/what-is-included";
import NursesSupport from "./components/nurses-support";
import HowWeSupport from "./components/how-we-support";
import UnlimitedSupport from "./components/unlimited-support";
import IsForYou from "./components/is-for-you";
import FAQ from "./components/FAQ";
import Popup from "../Membership/Popup";
import { MEMBERSHIP } from "../../Routes/RouteConstent";
import { useNavigate } from "react-router-dom";
import { getDataFromLocalStorage } from "../../Service/getLocalStorage";
import StreetCred from "../../Common/streetCred/streetCred";
import ContactUs from "./components/contact-us";

const MembershipPreLogin = () => {
  const [isPopup, setIsPopup] = useState(true);
  const navigate = useNavigate();
  const [id, setId] = useState("");

  const handleCalBack = () => {
    navigate(MEMBERSHIP + (id ? `/${id}` : ""));
  };

  const closePopUp = () => {
    setIsPopup(true);
    setId("");
  };

  const openMembershipPage = (id: string) => {
    setId(`${id}`);
    const userData = getDataFromLocalStorage();

    if (userData && userData?.is_email_verify != 0) {
      navigate(MEMBERSHIP + `/${id}`)
    } else {
      setIsPopup(false);
    }
  };

  const scrollToMembership = () => {
    document.getElementById("buyMembership")?.scrollIntoView();
  };

  return (
    <>
      <Popup
        isPopup={isPopup}
        callBack={handleCalBack}
        handleCLose={closePopUp}
        signUpFirst
      />
      <div className="preLoginWrapper">
        <Hero scrollToMembership={scrollToMembership} />
        <p className="preLoginDescription">
          We're not a one-stop shop; we're your one-year parenting companion.
          From the first kicks to the first day of preschool, our nurse-led
          platform has your back. All the support and education you need,
          whenever you need it! 
        </p>
      </div>
      <WhatIsIncluded joinTheClubHandle={scrollToMembership} />
      <div className="preLoginWrapperColor">
        <NursesSupport />
        <div className="preLoginWrapper">
          <HowWeSupport />
          <UnlimitedSupport
            openMembershipPage={() => openMembershipPage("2")}
          />
          <IsForYou />
          <FAQ openMembershipPage={() => openMembershipPage("1")} />
          <ContactUs />
          <StreetCred
            data={testimonialData}
            description="Still not convinced? Check out what parents (or soon-to-be parents) like you are saying. And unlike parenthood, you’re free to cancel any time."
          />
        </div>
      </div>
    </>
  );
};

const testimonialData = [
  {
    name: "Kai | Denver, CO",
    data: "I love what you're doing! I think you've created an incredible, and very much needed, community. I will absolutely be recommending you to friends. It's amazing how much is included with a membership. I joined in hopes of navigating this tricky sleep progression/regression period and was so gratified with what I learned and the support I received beyond that one period in time. ",
  },
  {
    name: "Sarah | Boston, MA",
    data: "It’s great you are helping me live my life with a baby instead of drowning in new parenthood! Also it’s so much better just to use your resources instead of looking at a thousand Instagram pages.",
  },
  {
    name: "Christine | Orlando, FL",
    data: "I just have to say, watching Emily and Jamie every weekend with the NAPS membership has honestly made our pregnancy so amazing. Their support, love and passion, it’s just awe inspiring and their true route of wanting to help is so selfless. You don’t find this in the world that often anymore. My husband and I consider ourselves so lucky to have stumbled upon NAPS as I couldn’t imagine this experience without them. On the days I just want to cry because I’m either stressed or worried about things, I can just turn on my NAPS, get my head on straight, feel no judgment, laugh and smile and know that everything is going to be alright. Without Jamie & Emily and their amazing team and guest speakers I don’t know if I’d feel half as ready, confident, cared about and excited to welcome our baby into our lives in a couple weeks. 100% would recommend this (and do...) to every pregnant woman I meet ha-ha. It's great for your husband, partners or support person as well!",
  },
];

export default MembershipPreLogin;
