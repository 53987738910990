import React, { useState } from "react";
import "./accordion.scss";

const Accordion = ({
  title,
  description,
  bottomBorder,
}: {
  title: string;
  description: string | React.ReactNode;
  bottomBorder?: boolean;
}) => {
  const [open, setOpen] = useState(false);

  return (
    <div
      className={[
        "accordionItemWrapper",
        bottomBorder ? "accordionItemWrapperBorder" : "",
      ].join(" ")}
    >
      <div className="accordionTitle">
        {title}
        <button onClick={() => setOpen((o) => !o)}>
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.58374 8H14.4166"
              stroke="#4CC4B0"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            {!open && (
              <path
                d="M8.00019 1.58355V14.4165"
                stroke="#4CC4B0"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            )}
          </svg>
        </button>
      </div>
      {open && <div className="accordionDescription">{description}</div>}
    </div>
  );
};

export default Accordion;
