import React from "react";
import Cross from "../../Assets/img/icons/cross.svg";
import { CircularProgress } from "@mui/material";
import "./Filter.scss";

const toggleCheckboxId = (
  e: React.ChangeEvent<HTMLInputElement>,
  array: [string]
) => {
  if (array.includes(e.target.value)) {
    return array.filter((v) => v !== e.target.value);
  }
  return [...array, e.target.value];
};

const Filter = ({
  selectedStage,
  selectedCategory,
  stage,
  category,
  filter,
  reset,
  isVisible,
  closeModal,
  pending,
}: any) => {
  const [localSelectedStage, setLocalSelectedStage] = React.useState<any>([]);
  const [localSelectedCategory, setLocalSelectedCategory] = React.useState<any>(
    []
  );

  const handleChangeStage = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLocalSelectedStage((stages: any) => toggleCheckboxId(e, stages));
  };

  const handleChangeCategory = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLocalSelectedCategory((category: any) => toggleCheckboxId(e, category));
  };

  const submitHandler = (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();

    filter({ category: localSelectedCategory, stage: localSelectedStage });
  };

  const handleReset = () => {
    reset();
    setLocalSelectedStage([]);
    setLocalSelectedCategory([]);
  };

  React.useEffect(() => {
    setLocalSelectedStage(selectedStage);
    setLocalSelectedCategory(selectedCategory);
  }, [selectedStage, selectedCategory, isVisible]);

  React.useEffect(() => {
    if (pending) closeModal();
  }, [pending, closeModal]);

  return !isVisible ? null : (
    <div>
      <div className="filter_content p-4">
        <h3>Filter</h3>
        <div onClick={closeModal}>
          <span className="cross">
            <img src={Cross} alt="cross" />
          </span>
        </div>
        <div className="filter_type">
          <form onSubmit={submitHandler}>
            <div className="radio-area p-0 mt-3">
              <h4>Stages</h4>
              {stage?.map((stagedata: any, index: any) => {
                return (
                  <div className="custom-radio" key={index}>
                    <input
                      type="checkbox"
                      id={stagedata?.name}
                      name={stagedata?.name}
                      className="custom-control-input form-check-input"
                      onChange={handleChangeStage}
                      value={stagedata?.id}
                      checked={localSelectedStage.includes(`${stagedata?.id}`)}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor={stagedata?.name}
                    >
                      {stagedata?.name}
                    </label>
                  </div>
                );
              })}
            </div>

            <div className="radio-area p-0 mt-3">
              <h4>Category</h4>
              {category?.map((categorydata: any, catindex: any) => {
                return (
                  <div className="custom-radio" key={catindex}>
                    <input
                      type="checkbox"
                      id={categorydata?.name}
                      name={categorydata?.name}
                      className="custom-control-input form-check-input"
                      onChange={handleChangeCategory}
                      value={categorydata?.id}
                      checked={localSelectedCategory.includes(
                        `${categorydata?.id}`
                      )}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor={categorydata?.name}
                    >
                      {categorydata?.name}
                    </label>
                  </div>
                );
              })}
            </div>

            <div className="row mt-4 mb-3">
              <div className="col-md-12">
                <button
                  type="reset"
                  disabled={pending}
                  className="primary-outline-btn me-3"
                  onClick={handleReset}
                >
                  {pending ? <CircularProgress size={18} /> : "Reset"}
                </button>

                <button
                  type="submit"
                  disabled={pending}
                  className="btn primary-blue-small-btn"
                >
                  {pending ? <CircularProgress size={18} /> : "Apply"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Filter;
