import React from "react";

export const PrivacyPolicy = () => {
  return (
    <div className="legal container px-md-5">
      <div className="sqs-block-content">
        <h1 style={{ textAlign: "center", whiteSpace: "pre-wrap" }}>
          Privacy Practices
        </h1>
        <p style={{ whiteSpace: "pre-wrap" }}>
          <em>
            This notice describes how medical information about you may be used
            and disclosed and how you can get access to this information. Please
            review it carefully.
          </em>
        </p>
        <p style={{ whiteSpace: "pre-wrap" }}>
          <em>
            If you have any questions about this Notice, please feel free to ask
            a staff member at Boston NAPS.
          </em>
        </p>
      </div>

      <div className="sqs-block-content">
        <hr />
      </div>

      <div className="sqs-block-content">
        <p style={{ whiteSpace: "pre-wrap" }}>
          This Notice of Privacy Practices describes how we may use and disclose
          your protected health information to carry out treatment, payment or
          health care operations and for other purposes that are permitted or
          required by law.&nbsp; It also describes your rights to access and
          control your protected health information. &nbsp;“Protected health
          information” is information about you, including demographic
          information, that may identify you and that relates to your past,
          present or future physical or mental health or condition and related
          health care services.
        </p>
        <p style={{ whiteSpace: "pre-wrap" }}>
          We are required to abide by the terms of this Notice of Privacy
          Practices.&nbsp; We may change the terms of our notice, at any
          time.&nbsp; The new notice will be effective for all protected health
          information that we maintain at that time.&nbsp; Upon your request, we
          will provide you with any revised Notice of Privacy Practices.&nbsp;
          You may request a revised version by accessing our website, or calling
          the office and requesting that a revised copy be sent to you in the
          mail or asking for one at the time of your next appointment.
        </p>
        <h3 style={{ whiteSpace: "pre-wrap" }}>
          <strong>1. </strong>
          <span>
            <strong>
              Uses and Disclosures of Protected Health Information
            </strong>
          </span>
        </h3>
        <p style={{ whiteSpace: "pre-wrap" }}>
          <strong>&nbsp;</strong>Your protected health information may be used
          and disclosed by your nurse, our office staff and others outside of
          our office who are involved in your care and treatment for the purpose
          of providing health care services to you.&nbsp; Your protected health
          information may also be used and disclosed to pay your health care
          bills and to support the operation of your health care provider’s
          practice.
        </p>
        <p style={{ whiteSpace: "pre-wrap" }}>
          Following are examples of the types of uses and disclosures of your
          protected health information that your health care provider’s office
          is permitted to make. These examples are not meant to be exhaustive,
          but to describe the types of uses and disclosures that may be made by
          our office.
        </p>
        <p style={{ whiteSpace: "pre-wrap" }}>
          <span style={{ textDecoration: "underline" }}>
            <strong>Treatment:</strong>
          </span>
          &nbsp; We will use and disclose your protected health information to
          provide, coordinate, or manage your health care and any related
          services.&nbsp; This includes the coordination or management of your
          health care with another provider. For example, we would disclose your
          protected health information, as necessary, to a home health agency
          that provides care to you.&nbsp; We will also disclose protected
          health information to other health care providers who may be treating
          you. For example, your protected health information may be provided to
          a health care provider to whom you have been referred to ensure that
          the health care provider has the necessary information to diagnose or
          treat you.&nbsp; In addition, we may disclose your protected health
          information from time-to-time to a physician or health care provider (
          <em>e.g.</em>, a specialist or laboratory) who, at the request of your
          physician, becomes involved in your care by providing assistance with
          your health care diagnosis or treatment to your physician.
        </p>
        <p style={{ whiteSpace: "pre-wrap" }}>
          <span style={{ textDecoration: "underline" }}>
            <strong>Payment:</strong>
          </span>
          <strong>&nbsp; </strong>Your protected health information will be used
          and disclosed, as needed, to obtain payment for your health care
          services provided by us or by another provider.&nbsp; This may include
          certain activities that your health insurance plan may undertake
          before it approves or pays for the health care services we recommend
          for you such as: making a determination of eligibility or coverage for
          insurance benefits, reviewing services provided to you for medical
          necessity, and undertaking utilization review activities.&nbsp; For
          example, obtaining approval for a hospital stay may require that your
          relevant protected health information be disclosed to the health plan
          to obtain approval for the hospital admission.&nbsp;
        </p>
        <p style={{ whiteSpace: "pre-wrap" }}>
          <span style={{ textDecoration: "underline" }}>
            <strong>Health Care Operations:</strong>
          </span>
          &nbsp; We may use or disclose, as needed, your protected health
          information in order to support the business activities of your health
          care provider’s practice.&nbsp; These activities include, but are not
          limited to, quality assessment activities, employee review activities,
          training of medical students, licensing, fundraising activities, and
          conducting or arranging for other business activities.
        </p>
        <p style={{ whiteSpace: "pre-wrap" }}>
          We will share your protected health information with third party
          “business associates” that perform various activities (for example,
          billing or transcription services) for our practice.&nbsp; Whenever an
          arrangement between our office and a business associate involves the
          use or disclosure of your protected health information, we
          <strong> </strong>
          will have a written contract that contains terms that will protect the
          privacy of your protected health information.
        </p>
        <p style={{ whiteSpace: "pre-wrap" }}>
          We may use or disclose your protected health information, as
          necessary, to provide you with information about treatment
          alternatives or other health-related benefits and services that may be
          of interest to you.&nbsp; You may contact our Privacy Officer to
          request that these materials not be sent to you.
        </p>
        <p style={{ whiteSpace: "pre-wrap" }}>
          We may use or disclose your demographic information and the dates that
          you received treatment from your health care provider, as necessary,
          in order to contact you for fundraising activities supported by our
          office.&nbsp; If you do not want to receive these materials, please
          contact our Privacy Officer and request that these fundraising
          materials not be sent to you.
        </p>
        <p style={{ whiteSpace: "pre-wrap" }}>
          <strong>
            Other Permitted and Required Uses and Disclosures That May Be Made
            Without Your Authorization or Opportunity to Agree or Object&nbsp;:
          </strong>
        </p>
        <p style={{ whiteSpace: "pre-wrap" }}>
          <strong>&nbsp;</strong>We may use or disclose your protected health
          information in the following situations without your authorization or
          providing you the opportunity to agree or object.&nbsp; These
          situations include:
        </p>
        <p style={{ whiteSpace: "pre-wrap" }}>
          <strong>Required By Law</strong>:&nbsp; We may use or disclose your
          protected health information to the extent that the use or disclosure
          is required by law.&nbsp; The use or disclosure will be made in
          compliance with the law and will be limited to the relevant
          requirements of the law.&nbsp; You will be notified, if required by
          law, of any such uses or disclosures.
        </p>
        <p style={{ whiteSpace: "pre-wrap" }}>
          <strong>Public Health</strong>:&nbsp; We may disclose your protected
          health information for public health activities and purposes to a
          public health authority that is permitted by law to collect or receive
          the information.&nbsp; For example, a disclosure may be made for the
          purpose of preventing or controlling disease, injury or disability.
        </p>
        <p style={{ whiteSpace: "pre-wrap" }}>
          <strong>Communicable Diseases</strong>:&nbsp; We may disclose your
          protected health information, if authorized by law, to a person who
          may have been exposed to a communicable disease or may otherwise be at
          risk of contracting or spreading the disease or condition.
        </p>
        <p style={{ whiteSpace: "pre-wrap" }}>
          <strong>Health Oversight</strong>:&nbsp; We may disclose protected
          health information to a health oversight agency for activities
          authorized by law, such as audits, investigations, and
          inspections.&nbsp; Oversight agencies seeking this information include
          government agencies that oversee the health care system, government
          benefit programs, other government regulatory programs and civil
          rights laws.&nbsp;
        </p>
        <p style={{ whiteSpace: "pre-wrap" }}>
          <strong>Abuse or Neglect</strong>:&nbsp; We may disclose your
          protected health information to a public health authority that is
          authorized by law to receive reports of child abuse or neglect.&nbsp;
          In addition, we may disclose your protected health information if we
          believe that you have been a victim of abuse, neglect or domestic
          violence to the governmental entity or agency authorized to receive
          such information.&nbsp; In this case, the disclosure will be made
          consistent with the requirements of applicable federal and state laws.
        </p>
        <p style={{ whiteSpace: "pre-wrap" }}>
          <strong>&nbsp;Food and Drug Administration</strong>:&nbsp; We may
          disclose your protected health information to a person or company
          required by the Food and Drug Administration for the purpose of
          quality, safety, or effectiveness of FDA-regulated products or
          activities including, to report adverse events, product defects or
          problems, biologic product deviations, to track products; to enable
          product recalls; to make repairs or replacements, or to conduct post
          marketing surveillance, as required.
        </p>
        <p style={{ whiteSpace: "pre-wrap" }}>
          <strong>Legal Proceedings</strong>:&nbsp; We may disclose protected
          health information in the course of any judicial or administrative
          proceeding, in response to an order of a court or administrative
          tribunal (to the extent such disclosure is expressly authorized), or
          in certain conditions in response to a subpoena, discovery request or
          other lawful process.&nbsp;
        </p>
        <p style={{ whiteSpace: "pre-wrap" }}>
          <strong>Law Enforcement</strong>:&nbsp; We may also disclose protected
          health information, so long as applicable legal requirements are met,
          for law enforcement purposes.&nbsp; These law enforcement purposes
          include (1)&nbsp;legal processes and otherwise required by law,
          (2)&nbsp;limited information requests for identification and location
          purposes, (3)&nbsp;pertaining to victims of a crime,
          (4)&nbsp;suspicion that death has occurred as a result of criminal
          conduct, (5)&nbsp;in the event that a crime occurs on the premises of
          our practice, and (6)&nbsp;medical emergency (not on our practice’s
          premises) and it is likely that a crime has occurred.&nbsp;
        </p>
        <p style={{ whiteSpace: "pre-wrap" }}>
          <strong>Coroners, Funeral Directors, and Organ Donation</strong>
          :&nbsp; We may disclose protected health information to a coroner or
          medical examiner for identification purposes, determining cause of
          death or for the coroner or medical examiner to perform other duties
          authorized by law.&nbsp; We may also disclose protected health
          information to a funeral director, as authorized by law, in order to
          permit the funeral director to carry out their duties.&nbsp; We may
          disclose such information in reasonable anticipation of death.&nbsp;
          Protected health information may be used and disclosed for cadaveric
          organ, eye or tissue donation purposes.
        </p>
        <p style={{ whiteSpace: "pre-wrap" }}>
          <strong>Research</strong>:&nbsp; We may disclose your protected health
          information to researchers when their research has been approved by an
          institutional review board that has reviewed the research proposal and
          established protocols to ensure the privacy of your protected health
          information.
        </p>
        <p style={{ whiteSpace: "pre-wrap" }}>
          <strong>Criminal Activity</strong>:&nbsp; Consistent with applicable
          federal and state laws, we may disclose your protected health
          information, if we believe that the use or disclosure is necessary to
          prevent or lessen a serious and imminent threat to the health or
          safety of a person or the public.&nbsp; We may also disclose protected
          health information if it is necessary for law enforcement authorities
          to identify or apprehend an individual.&nbsp;
        </p>
        <p style={{ whiteSpace: "pre-wrap" }}>
          <strong>Military Activity and National Security</strong>
          :&nbsp; When the appropriate conditions apply, we may use or disclose
          protected health information of individuals who are Armed Forces
          personnel (1)&nbsp;for activities deemed necessary by appropriate
          military command authorities; (2)&nbsp;for the purpose of a
          determination by the Department of Veterans Affairs of your
          eligibility for benefits, or (3)&nbsp;to foreign military authority if
          you are a member of that foreign military services.&nbsp; We may also
          disclose your protected health information to authorized federal
          officials for conducting national security and intelligence
          activities, including for the provision of protective services to the
          President or others legally authorized.&nbsp;
        </p>
        <p style={{ whiteSpace: "pre-wrap" }}>
          <strong>Workers’ Compensation</strong>:&nbsp; We may disclose your
          protected health information as authorized to comply with workers’
          compensation laws and other similar legally-established programs.
        </p>
        <p style={{ whiteSpace: "pre-wrap" }}>
          <strong>Inmates</strong>:&nbsp; We may use or disclose your protected
          health information if you are an inmate of a correctional facility and
          your health care provider created or received your protected health
          information in the course of providing care to you.
        </p>
        <p style={{ whiteSpace: "pre-wrap" }}>
          <strong>
            Uses and Disclosures of Protected Health Information Based upon Your
            Written Authorization:
          </strong>
        </p>
        <p style={{ whiteSpace: "pre-wrap" }}>
          Other uses and disclosures of your protected health information will
          be made only with your written authorization, unless otherwise
          permitted or required by law as described below.&nbsp; You may revoke
          this authorization in writing at any time. &nbsp;If you revoke your
          authorization, we will no longer use or disclose your protected health
          information for the reasons covered by your written
          authorization.&nbsp; Please understand that we are unable to take back
          any disclosures already made with your authorization.
        </p>
        <p style={{ whiteSpace: "pre-wrap" }}>
          <strong>
            Other Permitted and Required Uses and Disclosures That Require
            Providing You the Opportunity to Agree or Object:
          </strong>
        </p>
        <p style={{ whiteSpace: "pre-wrap" }}>
          We may use and disclose your protected health information in the
          following instances.&nbsp; You have the opportunity to agree or object
          to the use or disclosure of all or part of your protected health
          information.&nbsp; If you are not present or able to agree or object
          to the use or disclosure of the protected health information, then
          your health care provider may, using professional judgement, determine
          whether the disclosure is in your best interest.
        </p>
        <p style={{ whiteSpace: "pre-wrap" }}>
          <strong>Facility Directories</strong>: Unless you object, we will use
          and disclose in our facility directory your name, the location at
          which you are receiving care, your general condition (such as fair or
          stable), and your religious affiliation.&nbsp; All of this
          information, except religious affiliation, will be disclosed to people
          that ask for you by name.&nbsp; Your religious affiliation will be
          only given to a member of the clergy, such as a priest or rabbi.
        </p>
        <p style={{ whiteSpace: "pre-wrap" }}>
          <strong>
            Others Involved in Your Health Care or Payment for your Care
          </strong>
          :&nbsp; Unless you object, we may disclose to a member of your family,
          a relative, a close friend or any other person you identify, your
          protected health information that directly relates to that person’s
          involvement in your health care.&nbsp; If you are unable to agree or
          object to such a disclosure, we may disclose such information as
          necessary if we determine that it is in your best interest based on
          our professional judgment.&nbsp; We may use or disclose protected
          health information to notify or assist in notifying a family member,
          personal representative or any other person that is responsible for
          your care of your location, general condition or death.&nbsp; Finally,
          we may use or disclose your protected health information to an
          authorized public or private entity to assist in disaster relief
          efforts and to coordinate uses and disclosures to family or other
          individuals involved in your health care.
          <strong>&nbsp;</strong>
        </p>

        <h3 style={{ whiteSpace: "pre-wrap" }}>
          <strong>
            2. <span>Your Rights</span>
          </strong>
        </h3>

        <p style={{ whiteSpace: "pre-wrap" }}>
          Following is a statement of your rights with respect to your protected
          health information and a brief description of how you may exercise
          these rights.
        </p>
        <p style={{ whiteSpace: "pre-wrap" }}>
          <span style={{ textDecoration: "underline" }}>
            <strong>
              You have the right to inspect and copy your protected health
              information.
            </strong>
          </span>
          &nbsp; This means you may inspect and obtain a copy of protected
          health information about you for so long as we maintain the protected
          health information.&nbsp; You may obtain your medical record that
          contains medical and billing records and any other records that your
          health care provider and the practice uses for making decisions about
          you.&nbsp; As permitted by federal or state law, we may charge you a
          reasonable copy fee for a copy of your records.&nbsp;
        </p>
        <p style={{ whiteSpace: "pre-wrap" }}>
          &nbsp;Under federal law, however, you may not inspect or copy the
          following records: psychotherapy notes; information compiled in
          reasonable anticipation of, or use in, a civil, criminal, or
          administrative action or proceeding; and laboratory results that are
          subject to law that prohibits access to protected health information.
          Depending on the circumstances, a decision to deny access may be
          reviewable.&nbsp; In some circumstances, you may have a right to have
          this decision reviewed.&nbsp; Please contact our Privacy Officer if
          you have questions about access to your medical record.&nbsp;
        </p>
        <p style={{ whiteSpace: "pre-wrap" }}>
          <span style={{ textDecoration: "underline" }}>
            <strong>
              You have the right to request a restriction of your protected
              health information
            </strong>
            .
          </span>
          &nbsp; This means you may ask us not to use or disclose any part of
          your protected health information for the purposes of treatment,
          payment or health care operations.&nbsp; You may also request that any
          part of your protected health information not be disclosed to family
          members or friends who may be involved in your care or for
          notification purposes as described in this Notice of Privacy
          Practices.&nbsp; Your request must state the specific restriction
          requested and to whom you want the restriction to apply.&nbsp;
        </p>
        <p style={{ whiteSpace: "pre-wrap" }}>
          &nbsp;Your health care provider is not required to agree to a
          restriction that you may request.&nbsp; If your health care provider
          does agree to the requested restriction, we may not use or disclose
          your protected health information in violation of that restriction
          unless it is needed to provide emergency treatment.&nbsp; With this in
          mind, please discuss any restriction you wish to request with your
          health care provider. &nbsp;
        </p>
        <p style={{ whiteSpace: "pre-wrap" }}>
          <span style={{ textDecoration: "underline" }}>
            <strong>
              You have the right to request to receive confidential
              communications from us by alternative means or at an alternative
              location.
            </strong>
          </span>{' '}
          We will accommodate reasonable requests. We may also condition this
          accommodation by asking you for information as to how payment will be
          handled or specification of an alternative address or other method of
          contact.&nbsp; We will not request an explanation from you as to the
          basis for the request. Please make this request in writing to our
          Privacy Officer.
        </p>
        <p style={{ whiteSpace: "pre-wrap" }}>
          <span style={{ textDecoration: "underline" }}>
            <strong>
              You may have the right to have your health care provider amend
              your protected health information.
            </strong>
          </span>
          &nbsp;&nbsp; This means you may request an amendment of protected
          health information about you in a designated record set for so long as
          we maintain this information.&nbsp; In certain cases, we may deny your
          request for an amendment.&nbsp; If we deny your request for amendment,
          you have the right to file a statement of disagreement with us and we
          may prepare a rebuttal to your statement and will provide you with a
          copy of any such rebuttal.&nbsp; Please contact our Privacy Officer if
          you have questions about amending your medical record.&nbsp;&nbsp;
        </p>
        <p style={{ whiteSpace: "pre-wrap" }}>
          <span style={{ textDecoration: "underline" }}>
            <strong>
              You have the right to receive an accounting of certain disclosures
              we have made, if any, of your protected health information
            </strong>
          </span>
          .&nbsp; This right applies to disclosures for purposes other than
          treatment, payment or health care operations as described in this
          Notice of Privacy Practices.&nbsp; It excludes disclosures we may have
          made to you if you authorized us to make the disclosure, for a
          facility directory, to family members or friends involved in your
          care, or for notification purposes, for national security or
          intelligence, to law enforcement (as provided in the privacy rule) or
          correctional facilities, as part of a limited data set
          disclosure.&nbsp; You have the right to receive specific information
          regarding these disclosures that occur after April&nbsp;14, 2003. The
          right to receive this information is subject to certain exceptions,
          restrictions and limitations.&nbsp;
        </p>
        <p style={{ whiteSpace: "pre-wrap" }}>
          <span style={{ textDecoration: "underline" }}>
            <strong>
              You have the right to obtain a paper copy of this notice from us
            </strong>
          </span>
          , upon request, even if you have agreed to accept this notice
          electronically.
        </p>
        <h3 style={{ whiteSpace: "pre-wrap" }}>
          <strong>3. </strong>
          <span>
            <strong>Complaints</strong>
          </span>
        </h3>
        <p style={{ whiteSpace: "pre-wrap" }}>
          &nbsp;You may complain to us or to the Secretary of Health and Human
          Services if you believe your privacy rights have been violated by
          us.&nbsp; You may file a complaint with us by notifying our Privacy
          Officer of your complaint.&nbsp; We will not retaliate against you for
          filing a complaint.
        </p>
        <p style={{ whiteSpace: "pre-wrap" }}>
          You may contact us at{' '}
          <a href="mailto:bostonnaps@gmail.com">bostonnaps@gmail.com</a> for further information about
          the complaint process.&nbsp;
        </p>
        <p style={{ whiteSpace: "pre-wrap" }}>
          This notice was published and becomes effective on October 17, 2011
          <strong>.</strong>
        </p>
        <p style={{ whiteSpace: "pre-wrap" }}>&nbsp;</p>
      </div>
    </div>
  );
};
