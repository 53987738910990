import React from "react";
import * as asyncState from "../Utils/asyncState";
import { toast } from "react-toastify";
import { getUserID } from "../Service/getLocalStorage";
import { getUserStripePayments } from "../Service/update_profile";

const useStripePayments = () => {
  const [stripePayments, setStripePayments] = React.useState({
    ...asyncState.initState,
    data: {},
  });

  const paymentsMethodsRequest = React.useCallback(async () => {
    try {
      setStripePayments({ ...asyncState.pendingState, data: {} });
      const res: any = await getUserStripePayments(getUserID());

      if (res.status !== 200) {
        throw { message: res?.data?.message?.join(" ") };
      }

      setStripePayments({ ...asyncState.successState, data: res?.data });
    } catch (err: { message: string } | any) {
      toast.error(err?.message);
      setStripePayments({
        ...asyncState.failedState,
        failedMessage: err?.message,
        data: {},
      });
    }
  }, []);

  React.useEffect(() => {
    paymentsMethodsRequest();
  }, [paymentsMethodsRequest]);

  return {
    paymentsMethodsRequest,
    stripePayments,
  };
};

export default useStripePayments;
