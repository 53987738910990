import React from "react";
import Wrapper from "./Components/Wrapper";
import MagnetHero from "./Components/MagnetHero";
import OwnersStory from "./Components/OwnersStory";
import SendGuideForm from "./Components/SendGuideForm";
import tips9 from "./assets/9tips.png";

const TipsToStopStressing = () => {
  return (
    <Wrapper>
      <MagnetHero image={tips9}>
        <h3>Free download!</h3>
        <p>
          Feeling overwhelmed{" "}
          <span className="strong">with your new baby?</span>
        </p>
        <h1 className="mt-2 mt-md-5">
          9 Tips to Stop{" "}
          <div className="teal strong">Stressing & Start Thriving</div>
        </h1>
      </MagnetHero>
      <SendGuideForm
        event="NewbornLeadMagNew"
        description={
          <>
            Having a new baby can be FREAKING HARD! Some babies face feeding
            challenges, some babies are nocturnal, and all babies change the
            dynamics of your relationship with your partner.{" "}
            <span className="strong">
              So we created this free guide to equip parents with the knowledge
              and tools they need to improve all of these areas!
            </span>
          </>
        }
      />
      <OwnersStory />
    </Wrapper>
  );
};

export default TipsToStopStressing;
