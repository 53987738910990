import React, { useEffect, useMemo, useState } from 'react'
import Steppers from '../../../Common/Stepper/Stepper';
import { bookConsultApi, getAddonForPreBabyApi, getPreBabyBootcampApi } from '../../../Service/Cousult.service';
import { getDataFromLocalStorage } from '../../../Service/getLocalStorage';
import AppoinmentDetail from './Steps/AppoinmentDetail';
import ChooseDate from './Steps/ChooseDate';
import Payment from './Steps/Payment';
import SingleAddClass from './Steps/SingleAddClass';
import UserInfo from './Steps/UserInfo';
import "../consult.scss";
import CongratsModel2 from '../../../Common/CongratsPopup2/CongratsPopup2';
import { GIFTCARD } from '../../../Routes/RouteConstent';
import dayjs from 'dayjs';
import isMebershipAndActiveCheck from '../../../Utils/membership_check';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { useMembershipData } from '../../../Utils/getUserData/getUserData';
import { useQuery } from '../../../Hooks/useQuery';
import { capturePurchasedServiceEvent, useCaptureServiceEvent } from '../../../Service/serviceData';

function StepForPrebabyConsults({ state }: any) {

  const [activeStep, setActiveStep] = React.useState(0)
  const [hostData, setHostData] = useState<any>([])
  const [addClass, setAddClass] = useState<any>([])
  const [userInfoVal, setUserInfoVal] = useState<any>([])
  const [dateVisible, setDateVisible] = useState<Array<number>>([])
  const [date, setDate] = useState<any>({})
  const [serviceId, setServiceId] = useState<any>()
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingWash, setLoadingWash] = useState<boolean>(false)
  const [modelOpen, setModelOpen] = useState<boolean>(false)
  const [term_condition, setPolicy] = useState<any>(false)
  const [slots, setSlots] = useState<any>([])
  let [price, setPrice] = useState<any>()
  const [priceArry, setPriceArry] = useState<any>([])

  const [objName, setObjName] = useState<any>({})
  const [addOn, setAddOn] = React.useState<any>({})
  const [selectClasses, setSelectClasses] = React.useState<any>([])
  const [selectSlots, setSelectSlots] = React.useState<any>([])
  const [classTime, setClassTime] = useState<any>([])
  const [serviceName, setSerivceName] = useState([])
  let steps = [1, 2, 3, 4, 5]

  const serviceParam = useQuery().get('service') || state?.param;

  const Navigate = useNavigate()
  const dispatch = useDispatch()

  const isCheck = (res: any) => {
    isMebershipAndActiveCheck(res, Navigate, toast, dispatch)
  }

  const stepNames = useMemo(
    () => ['date-step', 'add-class-step', 'user-step', 'details-step', 'payment-step'],
    []
  );
  useCaptureServiceEvent(activeStep, stepNames);

  function getStepContent(step: number) {
    switch (step) {
      case 0:
        return (
          <ChooseDate
            host={hostData}
            value={date}
            setValue={setDate}
            loadingWash={loadingWash}
            dateVisible={dateVisible}
            setDateVisible={setDateVisible}
            handleNext={handleNext}
            setObjName={setObjName}
            slots={slots}
            setSlots={setSlots}
            serviceName={serviceName}
            price={price}
            setPrice={setPrice}
            setAddOn={setAddOn}
            setSelectClasses={setSelectClasses}
            setSelectSlots={setSelectSlots}
            setClassTime={setClassTime}
          />
        );
      case 1:
        return (
          <SingleAddClass
            data={addClass}
            objName={objName}
            handleNext={handleNext}
            dateVisible={dateVisible}
            loadingWash={loadingWash}
            setDateVisible={setDateVisible}
            value={date}
            setValue={setDate}
            price={price}
            setPrice={setPrice}
            slots={slots}
            setSlots={setSlots}
            priceArry={priceArry}
            setPriceArry={setPriceArry}
            setAddOn={setAddOn}
            addOn={addOn}
            selectClasses={selectClasses}
            setSelectClasses={setSelectClasses}
            selectSlots={selectSlots}
            setSelectSlots={setSelectSlots}
            classTime={classTime}
            setClassTime={setClassTime}
          />
        );
      case 2:
        return (
          <UserInfo
            state={state}
            handleNext={handleNext}
            value={userInfoVal}
            setValue={setUserInfoVal}
            handlePrev={handlePrev}
            setPolicy={setPolicy}
            term_condition={term_condition}
            setActiveStep={setActiveStep}
          />
        )
      case 3:
        return (
          <AppoinmentDetail
            host={hostData}
            objName={objName}
            data={date}
            setData={setDate}
            handleSubmit={buyConsultHandler}
            handlePrev={handlePrev}
            handleNext={handleNext}
            loading={loading}
            setStep={setActiveStep}
            dateVisible={dateVisible}
            setDateVisible={setDateVisible}
            price={price}
            setPrice={setPrice}
            slots={slots}
            setSlots={setSlots}
            setAddOn={setAddOn}
            setSelectClasses={setSelectClasses}
            setSelectSlots={setSelectSlots}
            setClassTime={setClassTime}
            service_id={serviceParam.slice(8)}
          />
        )
      case 4:
        return (
          <Payment
            handleNext={buyConsultHandler}
          />
        )
      default:
        return "Unknown step";
    }
  }
  const [userMember] = useMembershipData()

  useEffect(() => {
    if (userMember) {
      setUserInfoVal({
        ...userInfoVal,
        ["due_date"]: userMember?.due_date,
        ["child_name"]: userMember?.child_name,
        ["is_your_first_baby"]: userMember?.your_first_baby,
        ["partner_support_person_name"]: userMember?.partner_name,
        ["partner_support_person_email_address"]: userMember?.partner_email
      })
    }
  }, [userMember])
  
  const getHostApi = async () => {
    try {
      setLoadingWash(true)
      let prefix = {
        service_prefix: serviceParam
      }
      let res: any = await getPreBabyBootcampApi(serviceParam, prefix)
      isCheck(res)
      if (res?.data?.success) {
        setSerivceName(res?.data?.service_name)
        setHostData(res?.data?.data)
        setPrice(res?.data?.data[0]?.price)
        setServiceId(res.data.service_id)
        setLoadingWash(false)
      }
    } catch (err) {
      setLoadingWash(false)
      console.log(err)
    }
  }
  const getAddonApi = async () => {
    try {
      let res: any = await getAddonForPreBabyApi({ service_prefix: serviceParam })
      setAddClass(res?.data?.data)
    } catch (err) {
      console.log(err)
    }
  }


  const handleNext = () => {
    setActiveStep(activeStep + 1)
  }
  const handlePrev = () => {
    setActiveStep(activeStep - 1)
  }

  useEffect(() => {
    getHostApi()
    getAddonApi()
  }, []);
  useEffect(() => {
    setPrice(date?.total_ammount)
  }, []);

  const buyConsultHandler = async (data: any = null) => {
    try {
      setLoading(true);
      const localData = getDataFromLocalStorage();
      userInfoVal.due_date = dayjs(userInfoVal.due_date).format("YYYY-MM-DD");

      let param = {
        ...date,
        ...userInfoVal,
        email: localData.email,
        service_id: serviceId,
        service_name: serviceParam,
        first_name: localData.first_name,
        last_name: localData.last_name,
        user_id: localData.id,
        phone: localData?.phone,
        paymentMethod: data,
        slots: date.slots.map((slot: any) => ({
          ...slot,
          appointment_type_id: slot.appointmentTypeID,
          appointment_date_time: slot.time,
          calendar_id: slot.calendarID,
        })),
      };

      let res: any = await bookConsultApi(param);

      if (res?.data?.success) {
        setModelOpen(true);
        setLoading(false);
        capturePurchasedServiceEvent(serviceParam);
      } else {
        let message = res?.data?.message;
        let allMsg = "";
        for (let i = 0; i < message.length; i++) {
          allMsg += message[i];
        }

        toast.error(allMsg);
        setLoading(false);

        return { ...res, params: param };
      }
    } catch (error: any) {
      toast.error(error.message);
      setLoading(false);
    }
  };

  

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      //@ts-ignore
      behavior: "instant"
    })
  }, [activeStep])

  return (
    <div>
      <CongratsModel2
        open={modelOpen}
        setOpen={setModelOpen}
        btnText="Send gift card to your friend"
        Page={GIFTCARD}
        callBack={() => setActiveStep(0)}
        text={'All information about your upcoming appointment has been sent to your email.<br>In the meantime, feel free to check out our other offerings, or if you have a friend who you think would be interested in the same booking as you, tell them using the button below!'} title="Thank you for booking with NAPS!" />

      <Steppers steps={steps} getStepContent={getStepContent}
        activeStep={activeStep} setActiveStep={setActiveStep}
        handleNext={handleNext}
      />
    </div>
  )
}

export default StepForPrebabyConsults
