import posthog from "posthog-js";
import { LOGIN, MEMBERSHIP_PRE_LOGIN, VERIFYEMAIL } from "../Routes/RouteConstent"
import { getDataFromLocalStorage } from "../Service/getLocalStorage"
import { debounce, uniqBy } from 'lodash';

const errorMsg = (() => {
    type message = [string, Function];
    const messages: message[] = [];

    const reduceRepeatErrorMsg = debounce(() => {
        uniqBy<message>(messages, '1').
            forEach(([message, toast]: message) => {
                toast(message)
            })
    }, 300)

    return (message: string, toast: any) => {
        messages.push([message, toast])
        reduceRepeatErrorMsg();
    }
})()

const isMebershipAndActiveCheck = (res: any, navigate: any, toast: any, dispatch?: any) => {

    let status = res?.data?.status_code
    const getData = getDataFromLocalStorage()
    const msg = res?.data?.message;

    if (status === 409) {
        errorMsg(msg, toast)
        posthog.reset(true);
        localStorage.clear()
        navigate("/")
    }
    if (status === 310) {
        errorMsg(msg, toast)
        getData.is_email_verify = 0;
        localStorage.setItem("Nurture_user_data", JSON.stringify(getData))
        navigate(VERIFYEMAIL)
    }
    if (status === 403) {
        errorMsg(msg, toast)
        posthog.reset(true);
        localStorage.clear()
        navigate(LOGIN)
    }
    if (status === 402) {
        errorMsg(msg, toast)
        getData.is_stripe_membership = 0;
        localStorage.setItem("Nurture_user_data", JSON.stringify(getData))
        window.dispatchEvent(new Event("storage"))
        navigate(MEMBERSHIP_PRE_LOGIN)
    }
}
export default isMebershipAndActiveCheck;