import React, { useEffect } from "react";
import MembershipDetail from "../../Common/membershipDetail/membershipDetail";
import { useLocation, useNavigate } from "react-router-dom";
import "./paymentdetails.scss";
import { Container, Row } from "react-bootstrap";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { usePostHog } from "posthog-js/react";
import { formatSlug } from "../../Utils/Regex";

const PaymentDetail = () => {
  const { state }: any = useLocation();
  const Navigate = useNavigate();
  const posthog = usePostHog();

  useEffect(() => {
    if (state?.data?.title) {
      console.log(`[course-details]:${formatSlug(state?.data?.title)}`);
      posthog.capture(`[course-details]:${formatSlug(state?.data?.title)}`, {
        $set: { "last-course-details": formatSlug(state?.data?.title) },
      });
    }
  }, [state?.data?.title, posthog]);

  return (
    <div className="nonMember">
      <div className="default-plan">
        <Container fluid>
          <Row>
            <div className="col-lg-12 py-2">
              <div className="back-pg" onClick={() => Navigate(-1)}>
                <ArrowBackIosNewIcon />
                Back
              </div>
            </div>
          </Row>
          <MembershipDetail data={state?.data} type="course" />
        </Container>
      </div>
    </div>
  );
};

export default PaymentDetail;
