import React, { useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import Steppers from '../../../Common/Stepper/Stepper';
import { bookConsultApi, getMultipleClassApi } from '../../../Service/Cousult.service';
import { getDataFromLocalStorage } from '../../../Service/getLocalStorage';
import AppoinmentDetail from './Steps/AppoinmentDetail';
import ChooseDate from './Steps/ChooseDate';
import Payment from './Steps/Payment';
import UserInfo from './Steps/userInfo';
import UserInfo2 from './Steps/userInfo2';
import './multipleClassStep.scss';
import CongratsModel2 from '../../../Common/CongratsPopup2/CongratsPopup2';
import { GIFTCARD } from '../../../Routes/RouteConstent';
import dayjs from 'dayjs';
import isMebershipAndActiveCheck from '../../../Utils/membership_check';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { useMembershipData } from '../../../Utils/getUserData/getUserData';
import { useQuery } from '../../../Hooks/useQuery';
import { capturePurchasedServiceEvent, useCaptureServiceEvent } from '../../../Service/serviceData';

const MultipleClassStep = () => {

    let steps = [1, 2, 3, 4]

    const [modelOpen, setModelOpen] = useState<boolean>(false)
    const [activeStep, setActiveStep] = useState(0);
    const [policy, setPolicy] = useState<boolean>(false)
    const [term_condition, setTermPolicy] = useState<boolean>(false)
    const [userInfoVal, setUserInfoVal] = useState<any>([])
    const [host, setHost] = useState<any>([])
    const [date, setDate] = useState({})
    const [loading, setLoading] = useState(false)
    const [loadingWash, setLoadingWash] = useState<boolean>(false)
    const [dateVisible, setDateVisible] = useState<Array<number>>([])
    const [objName, setObjName] = useState({})
    const { state }: any = useLocation()

    const serviceParam = useQuery().get('service') || state?.param;

    const Navigate = useNavigate()
    const dispatch = useDispatch()

    const isCheck = (res: any) => {
        isMebershipAndActiveCheck(res, Navigate, toast, dispatch)
    }

    const stepNames = useMemo(
        () => ['date-step', 'user-step', 'details-step', 'payment-step'],
        []
    );
    useCaptureServiceEvent(activeStep, stepNames);

    function getStepContent(step: number) {

        switch (step) {
            case 0:
                return (
                    <ChooseDate
                        data={host}
                        value={date}
                        setValue={setDate}
                        loadingWash={loadingWash}
                        handleNext={handleNext}
                        setDateVisible={setDateVisible}
                        dateVisible={dateVisible}
                        setObjName={setObjName}

                    />
                );
            case 1:
                return (

                    serviceParam === "service_8" ?
                        <UserInfo2
                            handleNext={handleNext}
                            value={userInfoVal}
                            setValue={setUserInfoVal}
                            handlePrev={handlePrev}
                            term_condition={term_condition}
                            setTermPolicy={setTermPolicy}
                        /> :
                        <UserInfo
                            handleNext={handleNext}
                            value={userInfoVal}
                            setValue={setUserInfoVal}
                            handlePrev={handlePrev}
                            setPolicy={setPolicy}
                            policy={policy}
                            param={serviceParam}
                        />
                );
            case 2:
                return (
                    <AppoinmentDetail
                        data={date}
                        setData={setDate}
                        handleNext={handleNext}
                        handleSubmit={buyConsultHandler}
                        loading={loading}
                        setStep={setActiveStep}
                        handlePrev={handlePrev}
                        host={host}
                        setDateVisible={setDateVisible}
                        dateVisible={dateVisible}
                        objName={objName}
                    />
                )
            case 3:
                return (
                    <Payment
                        handleNext={buyConsultHandler}
                    />
                )
            default:
                return "Unknown step";
        }
    }

    const [userMember] = useMembershipData()

    useEffect(() => {
        if (userMember) {
            setUserInfoVal({
                ...userInfoVal,
                ["due_date"]: userMember?.due_date,
                ["child_name"]: userMember?.child_name,
                ["is_your_first_baby"]: userMember?.your_first_baby,
                ["partner_support_person_name"]: userMember?.partner_name,
                ["partner_support_person_email_address"]: userMember?.partner_email
            })
        }
    }, [userMember])
    const handleNext = () => {
        setActiveStep(activeStep + 1)
    };

    const handlePrev = () => {
        setActiveStep(activeStep - 1)
    }
    const getClassData = async () => {

        try {
            setLoadingWash(true)
            const prefix = {
                service_prefix: serviceParam

            }
            const res: any = await getMultipleClassApi(serviceParam, prefix)
            isCheck(res)
            if (res?.data?.success) {
                setHost(res?.data)
                setLoadingWash(false)
            }

        } catch (error) {
            console.log("Error", error)
            setLoadingWash(false)
        }
    }
    useEffect(() => {
        getClassData()
    }, []);

    const buyConsultHandler = async (data: any = null) => {

        try {
            setLoading(true)
            const localData = getDataFromLocalStorage()

            userInfoVal.due_date = dayjs(userInfoVal.due_date).format("YYYY-MM-DD")

            let param = {
                ...date,
                ...userInfoVal,
                email: localData.email,
                service_id: host?.service_id,
                service_name: host?.service_name,
                first_name: localData.first_name,
                last_name: localData.last_name,
                user_id: localData.id,
                phone: localData?.phone,
                paymentMethod: data,
            }
            let res: any = await bookConsultApi(param);
            
            if (res?.data?.success) {
                setModelOpen(true);
                setLoading(false);
                capturePurchasedServiceEvent(serviceParam);
            } else {
                let message = res?.data?.message;
                let allMsg = "";
                for (let i = 0; i < message.length; i++) {
                    allMsg += message[i];
                }

                toast.error(allMsg);
                setLoading(false);

                return { ...res, params: param };
            }
        } catch (error: any) {
            toast.error(error.message);
            setLoading(false);
        }
    }

    const modelCallback = () => {
        getClassData()
        setActiveStep(0)
    }
    useEffect(() => {
        window.scroll({
            top: 0,
            left: 0,
            //@ts-ignore
            behavior: "instant"
        })
    }, [activeStep])

    return (
        <div>
            <CongratsModel2
                open={modelOpen}
                setOpen={setModelOpen}
                btnText="Send gift card to your friend"
                Page={GIFTCARD}
                callBack={() => modelCallback()}
                text={'All information about your upcoming appointment has been sent to your email.<br>In the meantime, feel free to check out our other offerings, or if you have a friend who you think would be interested in the same booking as you, tell them using the button below!'} title="Thank you for booking with NAPS!" />

            <Steppers activeStep={activeStep} steps={steps}
                setActiveStep={setActiveStep}
                handleNext={handleNext}
                getStepContent={getStepContent} />
        </div>
    )
}

export default MultipleClassStep;