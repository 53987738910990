import React from "react";
import useWindowDimensions from "../../Hooks/useViewWidth";
import "./HeroBanner.scss";

interface Props {
  hero: string;
  heroMobile?: string;
  title?: React.ReactNode | string;
  desktopWidth?: number;
  className?: string;
  maxDesktopHeight?: string | number;
}
const HeroBanner = ({
  hero,
  heroMobile,
  title,
  desktopWidth = 50,
  maxDesktopHeight = "auto",
  className,
}: Props) => {
  const mobile = useWindowDimensions(767.99);

  return (
    <div
      className={`heroBannerMain ${className} ${
        heroMobile ? "heroBannerMobile" : ""
      }`}
      style={{
        backgroundImage: `url(${mobile.isSmaller ? heroMobile ?? hero : hero})`,
        maxHeight:
          typeof maxDesktopHeight === "number"
            ? `${maxDesktopHeight}px`
            : maxDesktopHeight,
      }}
    >
      {title && (
        <h1 className="heroBannerTitle" style={{ width: `${desktopWidth}%` }}>
          {title}
        </h1>
      )}
    </div>
  );
};
export default HeroBanner;
