import httpService from "./httpService";
interface Contact {
    first_name: string,
    last_name: string,
    email: string,
    phone: string | number,
    question: string,
    user_id: string | number
}

export const contactUs = (data: Contact) => {
    return httpService.post("user/contact-us/store", data)
}

export const getCalenderApi = (data: any) => {
    return httpService.post("event/calendar", data)
}

export const sponsorshipContactApi = (data: any) => {
    return httpService.post("user/sponsorship/contact-us/store", data)
}