import React, { useEffect, useMemo, useState } from 'react'
import Steppers from '../../../Common/Stepper/Stepper';
import AppoinmentDetail from './Steps/AppointmentDetail';
import UserInfo from './Steps/UserInfo';
import ChooseDate from './Steps/ChooseDate';
import Payment from './Steps/Payment';
import { useLocation, useNavigate } from 'react-router-dom';
import { bookConsultApi, getFormFieldApi, gethostNameApi } from '../../../Service/Cousult.service';
import { getDataFromLocalStorage } from '../../../Service/getLocalStorage';
import CongratsModel2 from '../../../Common/CongratsPopup2/CongratsPopup2';
import { GIFTCARD } from '../../../Routes/RouteConstent';
import dayjs from 'dayjs';
import isMebershipAndActiveCheck from '../../../Utils/membership_check';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useMembershipData } from '../../../Utils/getUserData/getUserData';
import { useQuery } from '../../../Hooks/useQuery';
import { capturePurchasedServiceEvent, useCaptureServiceEvent } from '../../../Service/serviceData';

const StepsForNurse = () => {

  const [host, setHost] = useState<any>([])
  const [dateVisible, setDateVisible] = useState<Array<number>>([])
  const [date, setDate] = useState<any>({})
  const [activeStep, setActiveStep] = React.useState(0);
  const [serviceId, setServiceId] = useState<any>(1)
  const [formField, setFormField] = useState<any>([])
  const [userInfoVal, setUserInfoVal] = useState<any>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingWash, setLoadingWash] = useState<boolean>(false)
  const [modelOpen, setModelOpen] = useState<boolean>(false)
  const [term_condition, setPolicy] = useState<any>(false)
  const [formErr, setFormErr] = useState<boolean>(false)
  const [objName, setObjName] = useState<any>({})
  const { state }: any = useLocation()
  const [deliveryType, setDeliveryType] = useState<any>([])
  const serviceParam = useQuery().get('service') || state?.param;

  let steps = [1, 2, 3, 4]

  const Navigate = useNavigate()
  const dispatch = useDispatch()

  const isCheck = (res: any) => {
    isMebershipAndActiveCheck(res, Navigate, toast, dispatch)
  }

  const stepNames = useMemo(
    () => ["date-step", "user-step", "details-step", "payment-step"],
    []
  );
  useCaptureServiceEvent(activeStep, stepNames);

  function getStepContent(step: number) {

    switch (step) {
      case 0:
        return (
          <ChooseDate
            data={host}
            value={date}
            host={host}
            setValue={setDate}
            handleNext={handleNext}
            dateVisible={dateVisible}
            loadingWash={loadingWash}
            setDateVisible={setDateVisible}
            setObjName={setObjName}
          />
        );
      case 1:
        return (
          <UserInfo
            handleNext={handleNext}
            error={formErr}
            setError={setFormErr}
            value={userInfoVal}
            setValue={setUserInfoVal}
            handlePrev={handlePrev}
            setPolicy={setPolicy}
            term_condition={term_condition}
            field={formField}
            state={state}
            deliveryType={deliveryType}
            setDeliveryType={setDeliveryType}
          />
        );
      case 2:
        return (
          <AppoinmentDetail
            data={date}
            host={host}
            setData={setDate}
            handleNext={handleNext}
            handleSubmit={confirmHandler}
            setStep={setActiveStep}
            handlePrev={handlePrev}
            objName={objName}
            loading={loading}

          />
        )
      case 3:
        return (
          <Payment
            handleNext={confirmHandler}
          />
        )
      default:
        return "Unknown step";
    }
  }
  const [userMember] = useMembershipData()

  useEffect(() => {
    if (userMember) {
      setUserInfoVal({
        ...userInfoVal,
        ["due_date"]: userMember?.due_date,
        ["child_name"]: userMember?.child_name,
        ["is_your_first_baby"]: userMember?.your_first_baby,
        ["partner_support_person_name"]: userMember?.partner_name,
        ["partner_support_person_email_address"]: userMember?.partner_email
      })
    }
  }, [userMember]);

  const confirmHandler = async (data: any = null) => {
    try {
      setLoading(true);
      const localData = getDataFromLocalStorage();
      userInfoVal.due_date = dayjs(userInfoVal.due_date).format("YYYY-MM-DD");
      userInfoVal.mother_dob = dayjs(userInfoVal.mother_dob).format(
        "YYYY-MM-DD"
      );

      let param = {
        ...date,
        ...userInfoVal,
        email: localData.email,
        service_id: serviceId,
        first_name: localData.first_name,
        last_name: localData.last_name,
        user_id: localData.id,
        phone: localData?.phone,
        paymentMethod: data,
      };
      let res: any = await bookConsultApi(param);

      if (res?.data?.success) {
        setModelOpen(true);
        setLoading(false);
        capturePurchasedServiceEvent(serviceParam);
      } else {
        let message = res?.data?.message;
        let allMsg = "";
        for (let i = 0; i < message.length; i++) {
          allMsg += message[i];
        }
        setLoading(false);
        toast.error(allMsg);
      }

      return { ...res, params: param };
    } catch (error: any) {
      setLoading(false);
      toast.error(error.message);
    }
  };

  const getFormField = async (id: any) => {
    try {
      let res: any = await getFormFieldApi({ service_id: id })
      isCheck(res)
      if (res?.data?.success) {
        setFormField(res.data.data)
      }

    } catch (error) {
      console.log("error in getting forms", error)
    }
  }

  const service = useQuery().get('service');

  const getHostName = async () => {
    try {
      setLoadingWash(true)
      let res: any = await gethostNameApi({ service_prefix: service || state.param });
      isCheck(res)
      if (res?.data?.success) {
        getFormField(res?.data?.service_id)
        setServiceId(res?.data?.service_id)
        setHost(res.data)
        setLoadingWash(false)
      }

    } catch (error: any) {
      setLoadingWash(false)
    }
  }
  useEffect(() => {
    getHostName()
  }, [])

  const handleNext = () => {
    setActiveStep(activeStep + 1)
    setDateVisible([])
  };

  const handlePrev = () => {
    setActiveStep(activeStep - 1)
  }
  
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      //@ts-ignore
      behavior: "instant"
    })
  }, [activeStep])


  return (
    <div>
      <CongratsModel2
        open={modelOpen}
        setOpen={setModelOpen}
        btnText="Send gift card to your friend"
        Page={GIFTCARD}
        callBack={() => setActiveStep(0)}
        text={'All information about your upcoming appointment has been sent to your email.<br>In the meantime, feel free to check out our other offerings, or if you have a friend who you think would be interested in the same booking as you, tell them using the button below!'} title="Thank you for booking with NAPS!" />

      <Steppers activeStep={activeStep} steps={steps}
        setActiveStep={setActiveStep}
        handleNext={handleNext}
        getStepContent={getStepContent} />
    </div>
  )
}

export default StepsForNurse;