import React, { useEffect, useMemo, useState } from 'react'
import Steppers from '../../../Common/Stepper/Stepper';
import AppoinmentDetail from './Steps/AppointmentDetail';
import UserInfo from './Steps/UserInfo';
import ChooseDate from './Steps/ChooseDate';
import { bookConsultApi, getGroupConsultApi } from '../../../Service/Cousult.service';
import { getDataFromLocalStorage } from '../../../Service/getLocalStorage';
import { useLocation, useNavigate } from 'react-router-dom';
import CongratsModel2 from '../../../Common/CongratsPopup2/CongratsPopup2';
import { GIFTCARD } from '../../../Routes/RouteConstent';
import dayjs from 'dayjs';
import isMebershipAndActiveCheck from '../../../Utils/membership_check';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { useMembershipData } from '../../../Utils/getUserData/getUserData';
import { useQuery } from '../../../Hooks/useQuery';
import { capturePurchasedServiceEvent, useCaptureServiceEvent } from '../../../Service/serviceData';


const StepsForDate = () => {

    const { state }: any = useLocation();


    const [activeStep, setActiveStep] = useState(0)
    const [data, setData] = useState<any>({});
    const [date, setDate] = useState({})
    const [loading, setLoading] = useState(false)
    const [loadingWash, setLoadingWash] = useState<boolean>(false)
    const [userInfoVal, setUserInfoVal] = useState<any>([])
    const [term_condition, setPolicy] = useState(false)
    const [modelOpen, setModelOpen] = useState(false)
    const [objName, setObjName] = useState<any>({})

    const Navigate = useNavigate()
    const dispatch = useDispatch()

    const isCheck = (res: any) => {
        isMebershipAndActiveCheck(res, Navigate, toast, dispatch)
    }

    const serviceParam = useQuery().get('service') || state?.param;

    const stepNames = useMemo(
        () => ['date-step', 'user-step', 'details-step'],
        []
    );
    useCaptureServiceEvent(activeStep, stepNames);

    let steps = [1, 2, 3]
    function getStepContent(step: number) {
        switch (step) {
            case 0:
                return (
                    <ChooseDate
                        data={data}
                        setData={setData}
                        value={date}
                        setValue={setDate}
                        loadingWash={loadingWash}
                        handleNext={handleNext}
                        setObjName={setObjName}
                    />
                );
            case 1:
                return (
                    <UserInfo
                        handleNext={handleNext}
                        value={userInfoVal}
                        setValue={setUserInfoVal}
                        handlePrev={handlePrev}
                        setPolicy={setPolicy}
                        term_condition={term_condition}
                    />
                );
            case 2:
                return (
                    <AppoinmentDetail
                        host={data}
                        data={date}
                        handleSubmit={confirmHandler}
                        handlePrev={handlePrev}
                        loading={loading}
                        setStep={setActiveStep}
                        objName={objName}

                    />
                )
            default:
                return "Unknown step";
        }
    }

    const [userMember] = useMembershipData()

    useEffect(() => {
        if (userMember) {
            setUserInfoVal({
                ...userInfoVal,
                ["due_date"]: userMember?.due_date,
                ["child_name"]: userMember?.child_name,
                ["is_your_first_baby"]: userMember?.your_first_baby,
                ["city"]: userMember?.city,
                ["state"]: userMember?.state,
                ["zip_code"]: userMember?.zip_code
            })
        }
    }, [userMember])
    
    const service = useQuery().get('service');

    const getData = async () => {
        try {
            setLoadingWash(true)
            let res: any = await getGroupConsultApi({ service_prefix: service || state.param })
            // debugger
            isCheck(res)
            if (res?.data?.success) {
                setData(res?.data)
                setLoadingWash(false)
            }
        } catch (error: any) {
            setLoadingWash(false)
        }
    }

    const confirmHandler = async () => {
        try {
            setLoading(true)
            const localData = getDataFromLocalStorage()
            userInfoVal.due_date = dayjs(userInfoVal?.due_date).format("YYYY-MM-DD")

            let param = {
                ...date,
                ...userInfoVal,
                email: localData.email,
                service_id: data?.service_id,
                // service_name: state?.data?.title,
                first_name: localData.first_name,
                last_name: localData.last_name,
                user_id: localData.id,
                // calendar_id: state?.data?.acuity_calendar_id,
                phone: localData.phone,
                // appointment_type_id: state?.data?.acuity_appointment_type_id
            }
            let res: any = await bookConsultApi(param)
            isCheck(res)

            let message = res?.data?.message
            let allMsg = "";
            for (let i = 0; i < message.length; i++) {
                allMsg += message[i]
            }
            if (res?.data?.success) {
                setModelOpen(true)
                setLoading(false)
                capturePurchasedServiceEvent(serviceParam);
                return;
            }
            alert(allMsg)

            //ali
            setLoading(false)

        } catch (error) {
            setLoading(false)
        }
    }

    useEffect(() => {
        getData()
    }, [])


    const handleNext = () => {
        setActiveStep(activeStep + 1)
    }
    const handlePrev = () => {
        setActiveStep(activeStep - 1)
    }
    const handleCallBack = () => {
        setActiveStep(0)
        getData()
    }
    useEffect(() => {
        window.scroll({
          top: 0,
          left: 0,
          //@ts-ignore
          behavior: "instant"
        })
    },[activeStep])
    
    return (
        <div>
            <CongratsModel2
                open={modelOpen}
                setOpen={setModelOpen}
                btnText="Send gift card to your friend"
                Page={GIFTCARD}
                callBack={() => { handleCallBack() }}
                // otherPageSetting={{ param: nursing_consult }}
                text={'All information about your upcoming appointment has been sent to your email.<br>In the meantime, feel free to check out our other offerings, or if you have a friend who you think would be interested in the same booking as you, tell them using the button below!'} title="Thank you for booking with NAPS!" />

            <Steppers steps={steps} getStepContent={getStepContent}
                activeStep={activeStep} setActiveStep={setActiveStep}
                handleNext={handleNext}
            />

        </div>
    )
}

export default StepsForDate;