import React, { useRef } from 'react'
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import jsPDF from 'jspdf';
import TableContainer from './table';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function ReceiptView({ open, setOpen, value }: any) {
    const handleClose = () => {
        setOpen(false);
    };
    const pdfRef = useRef(null)
    const handleDownload = () => {
        const content: any = pdfRef.current
        const doc = new jsPDF('p', 'mm', [1070, 1070]);
        
        doc.html(content, {
            callback: function (doc) {
                doc.save('receiptView.pdf');
            }
        });
    }

    return (
        <div>
            <Modal
                size="lg"
                show={open}
                onHide={handleClose}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton >
                </Modal.Header>
                <TableContainer data={value} />
                <Modal.Footer className='border-0 d-flex justify-content-center'>
                    <Button className='primary-outline-btn h50' onClick={handleDownload}>Download</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default ReceiptView
