import { useEffect, useState } from "react";
import * as asyncState from "../../Utils/asyncState";
import { toast } from "react-toastify";
import { getQuestions } from "./transport";

type Question = {
  id: number;
  user_photo: string;
  user_name: { name: string };
  user_id: number;
  message: string;
  likes: number;
  created_at: string;
  is_liked: boolean;
  stage: { id: number; color_code: string; name: string }[];
  category: { id: number; color_code: string; name: string }[];
  replies: { is_seen_user: 0 | 1 }[];
  title: string;
};

const useQuestions = ({
  show,
  search,
  categories,
  stages,
  page,
}: {
  show?: string;
  search?: string;
  categories?: string[];
  stages?: string[];
  page: string;
}) => {
  const [questions, setQuestions] = useState<
    asyncState.AsyncState & {
      data: Question[];
      currentPage: number | null;
      lastPage: number | null;
      hasNextPage: boolean;
    }
  >({
    ...asyncState.initState,
    currentPage: null,
    lastPage: null,
    hasNextPage: false,
    data: [],
  });

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        setQuestions((questions) => ({
          ...questions,
          ...asyncState.pendingState,
          currentPage: null,
          lastPage: null,
          hasNextPage: false,
          data: [],
        }));

        const res: any = await getQuestions({
          show,
          search,
          categories,
          stages,
          page,
        });

        if (res.status !== 200) {
          throw new Error(res?.message || res?.data?.message?.join(" "));
        }

        setQuestions({
          ...asyncState.successState,
          data: res?.data.data,
          currentPage: res?.data.current_page,
          lastPage: res?.data.last_page,
          hasNextPage: !!res?.data.next_page_url,
        });
      } catch (err: { message: string } | any) {
        toast.error(err?.message);
        setQuestions({
          ...asyncState.failedState,
          failedMessage: err?.message,
          currentPage: null,
          lastPage: null,
          hasNextPage: false,
          data: [],
        });
      }
    };

    fetchQuestions();
  }, [show, search, categories, stages, page]);

  const deleteChat = (id: number) =>
    setQuestions((q) => ({
      ...q,
      data: q.data.filter((q) => q.id !== id),
    }));

  const updateChatQuestion = (question: Question) =>
    setQuestions((q) => ({
      ...q,
      data: q.data.map((q) =>
        q.id === question.id ? { ...q, ...question } : q
      ),
    }));

  return { questions, deleteChat, updateChatQuestion };
};

export default useQuestions;
