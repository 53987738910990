import { Container } from "react-bootstrap";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { COURSEVIEW } from "../../Routes/RouteConstent";
import "../Membership/Membership.scss";
import BuyCourseForm from "../../Common/StripeForms/BuyCourse";

const BuyCourse = () => {
  const location: any = useLocation();
  const Navigate = useNavigate();

  const callBack = (courseId: number) => {
    Navigate(`${COURSEVIEW}${courseId}`);
  };

  return (
    <div className="nonMember">
      <Container fluid className="paymentUI-main">
        <div className="step-innercontent step5 payment-step5">
          <h3>Payment details</h3>
          <div className="row">
            <BuyCourseForm
              courseData={location?.state?.obj}
              successCB={callBack}
            />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default BuyCourse;
