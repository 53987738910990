import React from 'react'
import { Modal } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { isUserLoggedInAction } from '../../Redux/user/user'
import { usePostHog } from 'posthog-js/react'

const Popup = ({ open, setOpen, setUpdate }: any) => {
    const posthog = usePostHog();

    const handleClose = () => {
        setUpdate()
        setOpen(false)
    }

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const handleLogout = () => {
        posthog.reset(true);
        dispatch(isUserLoggedInAction(false))
        localStorage.clear();
        window.dispatchEvent(new Event("storage"))
        navigate("");
    }
    return (
        <div>
            <Modal
                show={open} onHide={handleClose}
                size="sm"
                id="delete-msg-modal"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='custom-modalTeal'
            >
                <Modal.Header closeButton onClick={handleClose} className='border-0'>
                    {/* <Modal.Title id="contained-modal-title-vcenter">
            Modal heading
        </Modal.Title> */}
                </Modal.Header>
                <Modal.Body>
                    {/* <h4>Logout</h4> */}
                    <h2>Are you sure you want to logout?</h2>
                </Modal.Body>
                <Modal.Footer className='border-0 d-flex flex-column justify-content-center'>
                    <button className='primary-blue-btn h50' onClick={handleLogout}>Confirm</button>
                    <button className='btn gotohome h50' onClick={handleClose}>Cancel</button>

                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default Popup