import React from "react";
import "./PinkContentBox.scss";

interface Props {
  title: React.ReactNode | React.ReactNode[] | string,
  paragraph: React.ReactNode | React.ReactNode[] | string,
  imgSrc: string,
  btnClick?: () => any,
  btnText?: React.ReactNode | React.ReactNode[] | string,
  reverse?: boolean,
  className?: string,
};

const PinkContentBox = ({
  className,
  title,
  paragraph,
  imgSrc,
  btnClick,
  btnText,
  reverse
}: Props) => {
  return (
    <div className={`${className} pinkContentBox ${reverse ? 'pinkContentBoxReverse' : ''}`}>
      <img src={imgSrc} alt="pinkContentBox" className={`pinkContentBox-img ${reverse ? 'pinkContentBox-img_reverse' : ''}`} />
        <div className="pinkContentBox-textWrapper">
          <h3>{title}</h3>
          <p>{paragraph}</p>
          {!!btnClick && (
            <button className="w-auto secondary-teal-btn-small" onClick={btnClick}>{btnText}</button>
          )}
        </div>
    </div>
  );
};

export default PinkContentBox;
