import moment from "moment";
import React from "react";
import banner from "./banner-receipt.png";
import visa from "./visa.png";

import master_card_img from "../../Assets/img/master-card.png";

const cardBrands: { [key: string]: any } = {
  visa,
  mastercard: master_card_img,
};

function TableContainer({ data }: any) {
  const boldtext = {
    backgroundColor: "#F6F9FC",
    margin: 0,
    fontfamily: "Poppins" || "sans-serif",
    padding: "30px",
  };
  const table = {
    width: "100%",
    border: "0",
    bgcolor: "white",
    cellpadding: "0",
    cellspacing: "0",
    style: "margin: 0 auto",
  };
  const table1 = {
    width: "100%",
    border: "0",
    cellpadding: "0",
    cellspacing: "0",
  };
  const image = {
    width: "100%",
  };
  const styleTag = {
    width: "100%",
    border: "0",
    cellpadding: "0",
    cellspacing: "0",
    padding: "0 20px",
  };
  const pare = {
    fontweight: 400,
    margin: "0,0,10px",
    color: "#32325D",
    fontsize: "24px",
    lineHeight: "32px",
    fontFamily: "Isidora Sans",
    fontWeight: "600",
  };
  const head = {
    color: "#8898AA",
    fontsize: "15px",
    lineHeight: "18px",
    margin: 0,
  };
  const par1 = {
    texttransform: "uppercase",
    color: "#8898AA",
    fontsize: "12px",
    lineHeight: "16px",
    whitespace: "nowrap",
    fontweight: "600",
  };
  const styleTag1 = {
    width: "100%",
    border: "0",
    cellpadding: "0",
    cellspacing: "0",
    margin: "25px 0",
    padding: "0 20px",
  };
  const par2 = {
    color: "#000000",
    fontsize: "15px",
    lineHeight: "16px",
    whitespace: "nowrap",
    fontweight: "400",
    margin: 0,
  };
  const par3 = {
    color: "#000000",
    fontsize: "15px",
    lineHeight: "16px",
    whitespace: "nowrap",
    fontweight: "400",
    margin: "0",
    display: "flex",
    alignItems: "center",
  };
  const image1 = {
    width: "40",
    marginRight: "5px",
  };
  const tablediv = {
    width: "100%",
    border: "0",
    cellpadding: "0",
    cellspacing: "0",
    margin: 0,
    padding: "0 20px",
    fontweight: "600",
  };
  const tableb = {
    texttransform: "uppercase",
    color: "#8898AA",
    fontsize: "12px",
    lineHeight: "16px",
    whitespace: "nowrap",
    fontweight: "600",
    margin: "0",
  };
  const tabled = {
    texttransform: "uppercase",
    color: "#525F7F",
    fontsize: "16px",
    lineHeight: "16px",
    whitespace: "nowrap",
    fontweight: "400",
    margin: "0",
  };
  const pargraph = {
    width: "60%",
    border: "0",
    bgcolor: "#F6F9FC",
    cellpadding: "15",
    cellspacing: "0",
    margin: "0 auto",
    padding: "20px",
    backgroundColor: "#f6fafd",
  };
  const par5 = {
    texttransform: "uppercase",
    color: "#525F7F",
    fontsize: "16px",
    lineHeight: "16px",
    whitespace: "nowrap",
    fontweight: "600",
    margin: "0",
  };
  const par7 = {
    width: "100%",
    border: "0",
    cellpadding: "0",
    cellspacing: "0",
    margin: "40px 0 20px",
    padding: "0 20px",
  };
  const par8 = {
    display: "block",
    borderBottom: "1px solid #E6EBF1",
  };
  const anch = {
    color: "#333333",
    fontsize: "16px",
    lineHeight: "16px",
    whitespace: "nowrap",
    fontweight: "600",
    margin: 0,
  };
  const anch1 = {
    textDecoration: "none",
    fontWeight: "700",
  };
  const span1 = {
    color: "#8898AA",
    fontsize: "12px",
    lineHeight: "16px",
    display: "inline-block",
    padding: "0 25px",
  };

  const getMoneyDiscount = (amount_off: number, amount: number) => {
    const calcAmount = (amount - amount_off) / 100;

    return calcAmount >= 0 ? `$${calcAmount.toFixed(2)}` : "$0.00";
  };

  const getPercentDiscount = (percent_off: number, amount: number) => {
    return `$${(amount - amount * (percent_off / 100)).toFixed(2)}`;
  };

  const { last4, brand }: { last4: string; brand: string } =
    data?.payment?.payment_intent?.payment_method?.card ?? {};

  return (
    <div style={boldtext}>
      <table style={table}>
        <tr>
          <td>
            <table style={table1}>
              <tr>
                <td colSpan={3} align={"center"}>
                  <img style={image} src={banner} alt="" />
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td>
            <table style={styleTag}>
              <tr>
                <td colSpan={3} align={"center"}>
                  <h1 style={pare}>Receipt from NAPS</h1>
                  <p style={head}>
                    <span>Receipt</span> <span>{data?.txn_id}</span>
                  </p>
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td>
            <table style={styleTag1}>
              <tr>
                <td>
                  <p style={par1}>Amount Paid</p>
                  <p style={par2}>${(data?.amount / 100).toFixed(2)}</p>
                </td>
                <td>
                  <p style={par1}>Date Paid</p>
                  <p style={par2}>
                    {moment(data?.period?.start * 1000).format(
                      "MMM DD, YYYY, h:mm:ss A"
                    )}{" "}
                  </p>
                </td>
                <td>
                  <p style={par1}>Payment Method</p>
                  <p style={par3}>
                    <span>
                      <img
                        style={image1}
                        src={cardBrands[brand] || last4}
                        alt={brand}
                      />
                    </span>
                    -{" "}
                    {data?.payment?.payment_intent?.payment_method?.card?.last4}
                    <span></span>
                  </p>
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td>
            <table style={tablediv}>
              <tr>
                <td colSpan={3}>
                  <p style={tableb}>SUMMARY</p>
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td>
            <table style={pargraph} cellPadding="20">
              <tr>
                <td colSpan={1} align={"left"} width="50%">
                  <span style={tabled}>{data?.description}</span>
                </td>
                <td colSpan={1} align={"right"} width="50%">
                  <span style={tabled}>${(data?.amount / 100).toFixed(2)}</span>
                </td>
              </tr>
              <tr>
                <td colSpan={1} align={"left"} width="50%">
                  <span style={par5}>Discount name</span>
                </td>
                <td colSpan={1} align={"right"} width="50%">
                  <span style={tabled}>
                    {data?.payment?.discount?.coupon?.name || "-"}
                  </span>
                </td>
              </tr>
              <tr>
                <td colSpan={1} align={"left"} width="50%">
                  <span style={par5}>Discount</span>
                </td>
                <td colSpan={1} align={"right"} width="50%">
                  <span style={tabled}>
                    {data?.payment?.discount?.coupon?.percent_off && (
                      <>{data?.payment?.discount?.coupon?.percent_off}%</>
                    )}
                    {data?.payment?.discount?.coupon?.amount_off && (
                      <>
                        $
                        {(
                          data?.payment?.discount?.coupon?.amount_off / 100
                        ).toFixed(2)}
                      </>
                    )}
                    {!data?.payment?.discount?.coupon?.percent_off &&
                      !data?.payment?.discount?.coupon?.amount_off &&
                      "-"}
                  </span>
                </td>
              </tr>
              <tr>
                <td colSpan={1} align={"left"} width="50%">
                  <span style={par5}>Total amount</span>
                </td>
                <td colSpan={1} align={"right"} width="50%">
                  <span style={tabled}>
                    {data?.payment?.discount?.coupon?.percent_off && (
                      <>
                        {getPercentDiscount(
                          data?.payment?.discount?.coupon?.percent_off,
                          data.amount
                        )}
                      </>
                    )}
                    {data?.payment?.discount?.coupon?.amount_off && (
                      <>
                        {getMoneyDiscount(
                          data?.payment?.discount?.coupon?.amount_off,
                          data.amount
                        )}
                      </>
                    )}
                    {!data?.payment?.discount?.coupon?.percent_off &&
                      !data?.payment?.discount?.coupon?.amount_off &&
                      "-"}
                  </span>
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td colSpan={3}>
            <span style={{ ...par8, paddingTop: "30px" }}></span>
          </td>
        </tr>
        <tr>
          <td>
            <table style={par7}>
              <tr>
                <td colSpan={3}>
                  <p>
                    {" "}
                    <a style={anch}>
                      If you have any questions, contact us at{" "}
                    </a>
                    <a href="mailto:hello@nurturebynaps.com" style={anch1}>
                      hello@nurturebynaps.com
                    </a>{" "}
                    or call at{" "}
                    <a href="tel:+1 857-496-5095" style={anch1}>
                      {" "}
                      +1 857-496-5095
                    </a>
                    .
                  </p>
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td colSpan={3}>
            <span style={par8}></span>
          </td>
        </tr>
        <tr>
          <td>
            <table style={par7}>
              <tr>
                <td colSpan={3}>
                  <span style={span1}>
                    You're receiving this email because you made a purchase at
                    NAPS, which partners with Stripe to provide invoicing and
                    payment processing.
                  </span>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
    </div>
  );
}
export default TableContainer;
