import React from "react";
import "./tabs.scss";

const Tabs = ({
  tabs,
  active,
  className = "",
  updateActiveTab,
}: {
  tabs: { value: string; text: string }[];
  active: string;
  updateActiveTab: (tab: string) => void;
  className?: string;
}) => (
  <div className={`tabsWrapper ${className}`}>
    <div className="tabsRow">
      {tabs.map(({ value, text }) => (
        <button
          className={`tabsRow__tab ${
            active === value ? "tabsRow__tab--active" : ""
          }`}
          key={value}
          onClick={() => updateActiveTab(value)}
        >
          {text}
        </button>
      ))}
    </div>
  </div>
);

export default Tabs;
