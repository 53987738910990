import React from "react";
import "./askQuestion.scss";
import chatIcon from "../assets/chatIcon.svg";
import useModalWindowControls from "../../../Hooks/useModal";
import StartChatModal from "../start-chat-modal";

const AskQuestion = () => {
  const newChatModal = useModalWindowControls();

  return (
    <div className="askQuestionWrapper">
      <StartChatModal
        isVisible={newChatModal.isVisible}
        closeModal={newChatModal.closeModal}
      />
      <div className="askQuestionRow">
        <img src={chatIcon} alt="chat" className="askQuestionRow__icon" />
        <h2 className="askQuestionRow__title">
          <img src={chatIcon} alt="chat" />
          <span>
            Ask <span className="strong">a Nurse</span>
          </span>
        </h2>
        <div className="askQuestionRow__text">
          <p>
            <span className="strong">
              Ask A Nurse forum with 24-hour response time to keep you from a
              google rabbit hole
            </span>
          </p>
          <p>
            Don't let the word "nurse" fool you. This isn't just a sick kid
            board, this board can be utilized to ask any of your pregnancy,
            feeding, sleep, potty training, challenging behaviors questions in
            addition to your sick kid questions! All answered by our staff of
            experienced maternal-child health nurses and nurse practitioners.
          </p>
        </div>
        <button
          className="btn primary-blue-small-btn askQuestionRow__btn"
          onClick={newChatModal.openModal}
        >
          + Ask a Nurse
        </button>
      </div>
    </div>
  );
};

export default AskQuestion;
