import httpService from "./httpService";

export let StripePayment = (data: any) => {
  return httpService.post("stripe", data);
};

export const getPaymentCards = (user_id: number) => {
  return httpService.get(`connect/get-payment-methods?user_id=${user_id}`, );
};

export const postNewPaymentCard = (data: any) => {
  return httpService.post("connect/store-payment-method", data);
};

export const deletePaymentCard = (data: any) => {
  return httpService.post("connect/remove-payment-method", data);
};

export const makeDefaultPaymentCard = (data: any) => {
  return httpService.post("connect/set-default-payment-method", data);
};