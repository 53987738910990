import React from "react";
import { useSearchParams } from "react-router-dom";
import BuyGiftCard from "../../Common/StripeForms/BuyGiftCard";
import { getUser } from "../../Service/getLocalStorage";

const GiftCardPayment = () => {
  const [searchParams] = useSearchParams();

  const type = searchParams.get("type") || "";
  const id = searchParams.get("id") || "";
  const yourName = searchParams.get("yourName") || "";
  const yourEmail = searchParams.get("yourEmail") || "";
  const giftTargetName = searchParams.get("giftTargetName") || "";
  const giftTargetEmail = searchParams.get("giftTargetEmail") || "";
  const price = searchParams.get("price") || "";

  const isAuthorized = !!getUser();

  return (
    <div>
      <div className="nonMember">
        <div className="step-innercontent step5 payment-step5">
          <div className="container pt-5">
            <div className="step-container step3">
              <h3>Payment</h3>
              <div className={isAuthorized ? "mt-5" : "mt-3"}>
                <div className="row">
                  <BuyGiftCard
                    type={type}
                    id={id}
                    yourName={yourName}
                    yourEmail={yourEmail}
                    giftTargetName={giftTargetName}
                    giftTargetEmail={giftTargetEmail}
                    price={price}
                    isAuthorized={isAuthorized}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GiftCardPayment;
