import React from "react";
import { prepareRequestParams } from "../helpers";
import * as asyncState from "../../Utils/asyncState";
import { postNote } from "../../Service/notesPages";
import { toast } from "react-toastify";
import useModalWindowControls from "../../Hooks/useModal";

type InitFields = { [key: string]: any };
type InitCheckboxFields = { [key: string]: string[] | [] };
interface Props {
  requiredFields: string[];
  initFields: InitFields;
  initCheckboxFields: InitCheckboxFields;
  formName: string;
}
const useSubmit = ({
  initFields,
  initCheckboxFields,
  requiredFields,
  formName,
}: Props) => {
  const [submitState, setSubmitState] = React.useState(asyncState.initState);
  const successModal = useModalWindowControls();

  const [fields, setFields] = React.useState({
    ...initFields,
  });
  const [checkboxFields, setCheckboxFields] = React.useState<{
    [key: string]: string[] | [];
  }>(initCheckboxFields);

  const [errors, setErrors] = React.useState<{ [key: string]: string }>({});

  const resetForm = () => {
    setFields({ ...initFields });
    setCheckboxFields({ ...initCheckboxFields });
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFields((f) => ({ ...f, [e.target.name]: e.target.value }));
    setErrors((err) => ({ ...err, [e.target.name]: "" }));
  };

  const handleChangeDate = (name: string) => (date: Date) => {
    setFields((f) => ({ ...f, [name]: date }));
    setErrors((err) => ({ ...err, [name]: "" }));
  };

  const handleChangeCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCheckboxFields((cF) => ({
      ...cF,
      [e.target.name]: cF[e.target.name].some((v) => v === e.target.value)
        ? cF[e.target.name].filter((v) => v !== e.target.value)
        : [...cF[e.target.name], e.target.value],
    }));
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const errors: { [key: string]: string } = {};
    requiredFields.forEach((requiredKey: string) => {
      if (fields.hasOwnProperty(requiredKey) && !fields[requiredKey]) {
        errors[requiredKey] = "Required field";
      }
      if (
        checkboxFields.hasOwnProperty(requiredKey) &&
        !checkboxFields[requiredKey].length
      ) {
        errors[requiredKey] = "Required field";
      }
    });

    setErrors(errors);

    if (!Object.values(errors).length) {
      const prepareData = prepareRequestParams({
        ...fields,
        ...checkboxFields,
      });
      setSubmitState(asyncState.pendingState);

      try {
        await postNote({ ...prepareData, formName });

        setSubmitState(asyncState.successState);
        successModal.openModal();
        resetForm();
      } catch (err: any) {
        setSubmitState({
          ...asyncState.failedState,
          failedMessage: err.message,
        });
        toast.error(err.message);
      }

      console.log(prepareData);
    }
  };

  return {
    fields,
    checkboxFields,
    submitState,
    successModal,
    errors,
    onSubmit,
    resetForm,
    handleChange,
    handleChangeDate,
    handleChangeCheckbox,
  };
};

export default useSubmit;
