import { CircularProgress, Typography } from "@mui/material";
import React from "react";
import "./card.scss";
import Options from "./Options";

function Card({
  method,
  className,
  openRemoveCardModal,
  defaultPaymentMethod,
  makePrimaryPaymentMethod,
  primaryPaymentMethod,
}: any) {
  const { exp_month, exp_year, last4 } = method?.card || {};

  const isPrimary = method.id === defaultPaymentMethod;

  const showPreloaderForPrimary =
    primaryPaymentMethod.paymentId === method.id &&
    primaryPaymentMethod.pending;
  return (
    <div
      className={`cardMethodWrapper my-2 d-flex w-100 edit-profile-payment-history-container align-items-start py-4 px-4 flex-column position-relative ${className} ${
        isPrimary ? "isPrimary" : ""
      }`}
    >
      <Options
        removePaymentMethod={() => openRemoveCardModal(method.id)}
        makePrimaryPaymentMethod={() => makePrimaryPaymentMethod(method.id)}
        isPrimary={isPrimary}
      />
      <div>
        <div>Credit card number</div>
        <div className="mt-1">
          <Typography sx={{ fontWeight: 700, fontSize: 18 }}>
            X X X X X X X X X X X X X -{" "}
            {last4?.split("").join(" ") ?? "0 0 0 0"}
          </Typography>
        </div>
      </div>
      <div className="cardMethodRow">
        <div>
          <div>Expiration</div>
          <div>
            <Typography sx={{ fontWeight: 700, fontSize: 18 }}>
              {exp_month ?? "00"} / {exp_year ?? "00"}
            </Typography>
          </div>
        </div>
        <div>
          <div>CVV</div>
          <div>
            <Typography sx={{ fontWeight: 700, fontSize: 18 }}>
              X X X X
            </Typography>
          </div>
        </div>
        <div>
          <div>Billing Zip</div>
          <div>
            <Typography sx={{ fontWeight: 700, fontSize: 18 }}>
              XXXXX
            </Typography>
          </div>
        </div>
      </div>
      {(showPreloaderForPrimary || isPrimary) && (
        <div className="primaryCard">
          Primary {showPreloaderForPrimary && <CircularProgress size={14} />}
        </div>
      )}
    </div>
  );
}

export default Card;
