import React from "react";
import useQuestions from "./useQuestions";
import "./askANurse.scss";
import Question from "./question";
import { CircularProgress } from "@mui/material";
import useModalWindowControls from "../../Hooks/useModal";
import useRequestState from "../../Hooks/useRequestState";
import { postDeleteChat, postToggleLikeChat } from "./transport";
import ConfirmDeleteModal from "../../Common/confirm-delete-modal";

const UserQuestions = () => {
  const { questions, deleteChat, updateChatQuestion } = useQuestions({
    show: "my",
    page: "1",
  });

  const deleteChatModal = useModalWindowControls();
  const deleteChatRequestState = useRequestState({
    initData: {},
    onSuccess: () => {
      deleteChat(deleteChatModal.openModalData);
      deleteChatModal.closeModal();
    },
    asyncRequest: postDeleteChat,
  });

  const toggleLikeChatRequestState = useRequestState({
    initData: {},
    onSuccess: (res) => {
      updateChatQuestion(res.data);
    },
    asyncRequest: postToggleLikeChat,
  });

  return (
    <>
      <ConfirmDeleteModal
        isVisible={deleteChatModal.isVisible}
        closeModal={deleteChatModal.closeModal}
        pending={deleteChatRequestState.requestState.pending}
        confirmBtnCB={() =>
          deleteChatRequestState.request({ id: deleteChatModal.openModalData })
        }
        title="Delete chat"
        description="Are you sure you want to delete this chat?"
      />

      {questions.data?.map((question, i) => (
        <Question
          className={i ? "mt-3" : ""}
          question={question}
          key={question.id}
          onDelete={(id) => deleteChatModal.openModal(id)}
          onToggleLike={(id) => toggleLikeChatRequestState.request({ id })}
        />
      ))}

      {questions.pending && (
        <div className="d-flex justify-content-center align-items-center h-100">
          <CircularProgress size={30} />
        </div>
      )}
    </>
  );
};

export default UserQuestions;
