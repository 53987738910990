import React from 'react';
import { Carousel, Container, Row } from 'react-bootstrap';
import "./streetCred.scss";

const StreetCred = ({ data, description = 'Still not convinced? Check out what parents (or soon-to-be parents) like you are saying.' }: any) => {
    return (
        <Row className='naps-testimonial-row pale-bg py-5'>
            <div className='col-lg-12 col-md-12'>
                <Container>
                    <Row className='justify-content-center'>
                        <div className="col-lg-8 col-md-8 justify-content-center text-center mt-0 mb-3">
                            <h1 className="mt-2 mb-1 pb-0">Some <span className="strong">Street Cred</span></h1>
                            <p className='px-0 px-md-5 mx-0 mx-md-5'>{description}</p>
                        </div>
                    </Row>
                    <Row>
                        <Carousel controls={false}>
                            {Array.isArray(data) && data.map((res) => (
                                <Carousel.Item>
                                    <Container>
                                        <Row className='align-items-center justify-content-center'>
                                            {/* <div className='col-lg-6 col-md-6 col-sm-12 mb-5 mb-md-0'>
                                        <div className='p-0 p-md-5'>
                                            <img
                                                className="d-block img-fluid"
                                                src={testimonial1}
                                                alt="First slide"
                                            />
                                        </div>
                                    </div> */}

                                            <div className='col-lg-8 col-md-8 col-sm-12'>
                                                <Carousel.Caption className='text-center position-relative'>
                                                    {/* <h5>First slide label</h5> */}
                                                    <p className='testimony-caption'>{res?.data}</p>
                                                    <p className='strong testi'>{res?.name}</p>
                                                </Carousel.Caption>
                                            </div>
                                        </Row>
                                    </Container>
                                </Carousel.Item>
                            ))}
                        </Carousel>
                    </Row>
                </Container>
            </div>
        </Row>
    )
}

export default StreetCred