import { getUserID } from "../../Service/getLocalStorage";
import httpService from "../../Service/httpService";

export const getChat = (id: string | number) => {
  return httpService.get(`ask-a-nurse/${id}`);
};

export const uploadChatImage = ({
  file,
  chatId,
}: {
  file: File;
  chatId?: number | string;
}) => {
  const formData = new FormData();
  formData.append("image", file);
  chatId && formData.append("chat_id", `${chatId}`);
  return httpService.post<{ url: string }>(`chat/image`, formData);
};

export const postChatReply = ({
  id,
  message,
}: {
  id: string | number;
  message: string;
}) => {
  return httpService.post(`ask-a-nurse/reply/${id}`, {
    message,
    user_id: getUserID(),
  });
};

export const postDeleteReply = ({ id }: { id: string | number }) => {
  return httpService.post(`ask-a-nurse/delete-reply/${id}`, {
    user_id: getUserID(),
  });
};
