import React from "react";
import vimeo from "@vimeo/player";
import { getDataFromLocalStorage } from "../../../../Service/getLocalStorage";
import { updateQuickScreenApi } from "../../../../Service/postLogin";
import "./OnboardingVideo.scss";

const Video = ({ closeModal }: { closeModal: () => void }) => {
  const [ended, setEnded] = React.useState(false);
  const [play, setPlay] = React.useState(false);

  const vimeoRef: any = React.useRef(null);

  React.useEffect(() => {
    const video = new vimeo(vimeoRef.current);

    video.on("ended", () => {
      setEnded(true);
    });

    video.on("play", () => {
      setPlay(true);
    });

    video.on("pause", () => {
      setPlay(false);
    });

    return () => {
      // video.destroy();
    };
  }, []);

  const togglePlay = () => {
    const video = new vimeo(vimeoRef.current);

    !play ? video.play() : video.pause();
  };

  const finishOnboarding = async () => {
    closeModal();
    await updateQuickScreenApi();
    localStorage.setItem(
      "Nurture_user_data",
      JSON.stringify({ ...getDataFromLocalStorage(), welcome_screen_status: 1 })
    );
  };

  return (
    <div className="OnboardingVideoModal">
      <div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
        <iframe
          src="https://player.vimeo.com/video/846312975?h=4b26755a21&amp;badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479"
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          }}
          title="Nurture by NAPS Member Walkthrough"
          ref={vimeoRef}
        ></iframe>
      </div>
      <div className="OnboardingVideoModalControls">
        {
          <button
            className="btn primary-blue-small-btn-40 OnboardingVideoModalControlsBtn"
            onClick={togglePlay}
          >
            {!play ? "Play" : "Pause"}
          </button>
        }
        {!ended && (
          <button
            onClick={finishOnboarding}
            className="btn primary-blue-small-btn-40 OnboardingVideoModalControlsBtn OnboardingVideoModalControlsBtnSkip"
          >
            Skip
          </button>
        )}
        {ended && (
          <button
            onClick={finishOnboarding}
            className="btn primary-blue-small-btn-40 OnboardingVideoModalControlsBtn OnboardingVideoModalControlsBtnDone"
          >
            Done
          </button>
        )}
      </div>
    </div>
  );
};

export default Video;
