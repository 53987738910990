import React from "react";
import { Routes, Route } from "react-router-dom";
import BrightHorizons from "./BrightHorizons";
import SitterCity from "./SitterCity";

export const screens = {
  BrightHorizons,
  SitterCity,
};

export const navigateLinks: { [key: string]: string } = {
  brightHorizons: "/bright-horizons",
  sitterCity: "/sittercity",
};

export const navigateLinksList: string[] = Object.values(navigateLinks);

export const BrightHorizonsRouter = () => (
  <Routes>
    <Route
      path={navigateLinks.brightHorizons}
      element={<screens.BrightHorizons />}
    />
    <Route path={navigateLinks.sitterCity} element={<screens.SitterCity />} />
  </Routes>
);
