import * as React from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Logout from '@mui/icons-material/Logout';
import { useNavigate } from 'react-router-dom';
import { PROFILEUSER } from '../../Routes/RouteConstent';
import Popup from './Popup';

export default function ProfileMenu({ closeNav }: any) {

  const [profileImage, setProfileImage] = React.useState("")
  const [logoutPopup, setLogoutPopup] = React.useState(false)
  const [userProfile, setUserProfile] = React.useState<any>({})

  let user: any = localStorage.getItem("Nurture_user_data");
  user = JSON.parse(user)
  let userProf: any = localStorage.getItem("profile_data_update");
  userProf = JSON.parse(userProf)

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement | any>(null);
  const open = Boolean(anchorEl);

  const userProfileUpdate = () => {
    setUserProfile(userProfile)
  }
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  let navigate = useNavigate()

  const logout = () => {
    setLogoutPopup(true)
  }
  React.useEffect(() => {
    setProfileImage(user?.photo)
    userProfileUpdate()
    window.addEventListener("storage", () => {
      userProfileUpdate()
    })
    window.addEventListener("profile", () => {
      user = localStorage.getItem("Nurture_user_data");
      user = JSON.parse(user)
      setProfileImage(user?.photo)
    })
  }, [])

  return (
    <div className='profile-main-container'>
      <Popup open={logoutPopup} setOpen={setLogoutPopup} setUpdate={handleClose} />
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }} className='user-profile'>
        {/* <Typography sx={{ minWidth: 100 }}>Contact</Typography>
        <Typography sx={{ minWidth: 100 }}>Profile</Typography> */}
        <Tooltip title="">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <Avatar sx={{ width: 60, height: 60 }} alt="Remy Sharp"
              // src={data?.photo?data?.photo:user?.photo}
              src={profileImage}
            />
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={anchorEl}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={() => { navigate(`${PROFILEUSER}?stp=MyCourses&sub=1`); closeNav() }}>
          <Avatar src={profileImage} /> {userProfile?.first_name ? userProfile?.first_name : user?.first_name}
        </MenuItem>
        <Divider />
        <MenuItem onClick={logout}>
          <ListItemIcon onClick={closeNav}>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </div>
  );
}
