import React from "react";
import BuyService from "../../../../Common/StripeForms/BuyService";

export default function Payment({ handleNext }: any) {
  return (
    <div className="step-innercontent step5 payment-step5">
      <div className="consult-section-heading text-center">
        <h3 className="my-5 p-0">Payment</h3>
      </div>
      <div className="row">
        <BuyService requestCB={handleNext} />
      </div>
    </div>
  );
}
