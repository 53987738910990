import { DialogProps, Stack, TextField } from '@mui/material';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import React, { useState } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import dayjs from 'dayjs';
import CityStateZip from '../commonFIeld/cityStateZip';
import { CityStateZipValSub } from '../commonFIeld/validation';
import { Link } from 'react-router-dom';
import * as path from "../../Routes/RouteConstent";

const ConsultForm = ({ handleNext, value, setValue, handlePrev, term_condition, setTermPolicy, param }: any) => {

    const [errors, setErrors] = useState<any>({})
    const [open, setOpen] = React.useState<boolean>(false);
    const [scroll, setScroll] = React.useState<DialogProps['scroll']>('paper');

    const handleChange = (e: React.ChangeEvent<HTMLInputElement> | any) => {

        let name = e.target.name;
        let inputValue = e.target.value

        setValue({
            ...value,
            [name]: inputValue
        })
    }

    const dateHandler = (date: Date | null | any) => {

        setErrors({
            ...errors,
            ["due_date"]: ""
        })
        setValue({
            ...value,
            ["due_date"]: date
        })
    }


    const handleSubmit = (e: any) => {
        if (
            !value?.term_condition ||
            !value?.due_date ||
            value?.due_date === "Invalid date" ||
            (param !== "service_1" && Object.values(CityStateZipValSub(value)).some(v => v)) ||
            (param !== "service_5" && !value?.is_your_first_baby) ||
            (param === "service_1" && !value?.phone) ||
            (param === "service_1" && !/^\+?[1-9][0-9]{7,14}$/.test(value?.phone))
        ) {

            let error: any = {};

            if (!value?.due_date) {
                error.due_date = "Please select date";
            }
            if (value?.due_date === "Invalid date") {
                error.due_date = "Please select date";
            }
            if (!value?.is_your_first_baby && param !== "service_5") {
                error.is_your_first_baby = "Please input the value";
            }
            if (!value?.term_condition) {
                error.term_condition = "Please check the policy";
            }
            if (!/^\+?[1-9][0-9]{7,14}$/.test(value?.phone)) {
                error.phone = "Invalid phone number"
            }
            if (!value?.phone) {
                error.phone = "Phone number is Required"
            }
            if (!value?.about_yourself) {
                error.about_yourself = "Please input the value"
            }
            let restError = param === "service_1" ? {} : CityStateZipValSub(value);
            setErrors({ ...error, ...restError });
        } else {
            handleNext(value);
            setErrors({});
        }
    };

    const policyHandler = (e: React.ChangeEvent<HTMLInputElement>) => {

        setErrors({
            ...errors,
            [e.target.name]: ""
        })
        setTermPolicy(e.target.checked)
        setValue({
            ...value,
            term_condition: e.target.checked
        })
    }

    return (
        <div>
            <section className='step-container step2'>
                <div className='consult-section-heading text-center'>
                    <h3 className='my-5'>Complete your Information</h3>
                </div>
                <form >
                    <div className='row'>
                        <div className="form-group col-lg-12 col-md-12 mb-3 due_dt_container">
                            <label>Due Date/Child's Birthday <span className='mandatory text-danger mx-2'>*</span></label>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>

                                <Stack spacing={3}>
                                    <MobileDatePicker
                                        // label="Date picker"
                                        toolbarTitle="Choose date"
                                        value={value?.due_date || ""}
                                        // disableFuture
                                        onChange={dateHandler}
                                        renderInput={(params: any) => <TextField placeholder="MM-DD-YYYY"  {...params} className='due-date-field' />}
                                    />
                                </Stack>
                            </LocalizationProvider>
                            <h4 className="error-msgtext">{errors.due_date}</h4>
                        </div>

                        <div className="form-group col-lg-12 col-md-12 mb-3">
                            <label>Child's Name: <span className='fw-light'> (if applicable)</span></label>
                            <input type="text" value={value?.child_name} name="child_name" onChange={handleChange} className='form-control' />
                            {/* <h4 className="error-msgtext">{errors?.child_name}</h4> */}
                        </div>

                        {param !== "service_5" && <div className="form-group col-lg-12 col-md-12 mb-3">
                            <label>Is this your first baby ? <span className='mandatory text-danger mx-2'>*</span></label>
                            <div className="custom-radio custom-control-inline">
                                <input type="radio" id="customRadio1" name="is_your_first_baby" checked={value?.is_your_first_baby === "yes"} onChange={handleChange} value="yes"
                                    className="custom-control-input form-check-input"

                                />
                                <label className="custom-control-label me-3" htmlFor="customRadio1">yes</label>
                                <input type="radio" id="customRadio2" name="is_your_first_baby"
                                    onChange={handleChange}
                                    value="no" checked={value?.is_your_first_baby === "no"}
                                    className="custom-control-input form-check-input"
                                />
                                <label className="custom-control-label step_two" htmlFor="customRadio2">no</label>
                            </div>
                            <h4 className="error-msgtext">{errors.is_your_first_baby}</h4>
                        </div>}

                        {param !== "service_1" && <>
                            <div className="form-group col-sm-12 mb-3">
                            <label>City <span className='mandatory text-danger mx-2'>*</span></label>
                            <input type="text" name='city'
                                value={value?.city}
                                className="form-control"
                                onChange={handleChange}
                            />
                            <h4 className="error-msgtext">{errors?.city}</h4>
                        </div>
                        <div className="form-group col-sm-12 mb-3">
                            <label>State <span className='mandatory text-danger mx-2'>*</span></label>
                            <input type="text" name='state'
                                value={value?.state}
                                className="form-control"
                                onChange={handleChange}
                            />
                            <h4 className="error-msgtext">{errors?.state}</h4>
                        </div>
                        </>}
                        {(param === "service_1") ?
                            <>
                                <div className="form-group col-lg-12 col-md-12 mb-3">
                                    <label>Phone Number <span className='mandatory text-danger mx-2'>*</span></label>
                                    <input type="text" name='phone' value={value?.phone} className='form-control' onChange={handleChange} />
                                    <h4 className="error">{errors?.phone}</h4>
                                </div>
                                <div className="form-group col-lg-12 col-md-12 mb-3">
                                    <label>What is your full address <span className="fw-light">(including zip code): </span></label>
                                    <input type="text" className='form-control' name="address" value={value?.address} onChange={handleChange} placeholder='Enter address' />
                                </div>

                                <div className="form-group col-lg-12 col-md-12 mb-3">
                                    <label>Please tell us a little more about yourself and how we can best support you now or in the future: <span className='mandatory text-danger mx-2'>*</span></label>
                                    <textarea className='form-control' placeholder='Type something' name='about_yourself' value={value?.about_yourself} onChange={handleChange} />
                                    <h4 className="error-msgtext">{errors?.about_yourself}</h4>
                                </div>
                            </> : <>
                                <> <div className="form-group col-lg-12 col-md-12 mb-3">
                                    <label>Partner or Support Person's Name <span className="fw-light"> (if applicable):</span> </label>
                                    <input type="text" className='form-control' name="partner_support_person_name" value={value?.partner_support_person_name} onChange={handleChange} placeholder='Enter name' />
                                </div>

                                    <div className="form-group col-lg-12 col-md-12 mb-3">
                                        <label>Partner or Support Person's Email address <span className="fw-light"> (for reminders, content, and follow up): </span> </label>
                                        <input type="text" className='form-control' name='partner_support_person_email_address' value={value?.partner_support_person_email_address} onChange={handleChange} placeholder='Enter email address' />
                                    </div>
                                </>
                            </>}
                        {/* <div className="form-group col-lg-12 col-md-12 mb-3">
                            <label>Please tell us a little more about yourself and how we can best support you now or in the future: <span className='mandatory text-danger mx-2'>*</span></label>
                            <textarea name="medical_surgical_history_baby" className="form-control" value={value?.medical_surgical_history_baby} onChange={handleChange} placeholder="Type something" > </textarea>

                        </div> */}

                        <div className='agreement-container py-4'>
                            <div className="custom-radio custom-control-inline">
                                <input type="radio" id="customRadio1" name='term_condition'
                                    className="custom-control-input form-check-input"
                                    checked={value?.term_condition}
                                    onChange={policyHandler}
                                />
                                {/* <label className="custom-control-label" htmlFor="customRadio1">I have read and agree to the <a href='#' className='blue-link' onClick={termOfService} > terms of service</a></label> */}
                                <label className="custom-control-label" htmlFor="customRadio1">I have read and agree to the <Link target="_blank" className='blue-link' to={path.TERMS} > terms of service</Link></label>
                            </div>
                            <h4 className="error-msgtext">{errors?.term_condition}</h4>
                        </div>
                    </div>
                </form>

                <div className='section-navigation my-5 d-flex justify-content-between'>
                    <button className='primary-blue-small-btn' onClick={handlePrev}>Previous</button>
                    <button className='primary-blue-small-btn next' onClick={handleSubmit}>Next</button>
                </div>
            </section>
        </div>
    )
}

export default ConsultForm;