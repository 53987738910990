import React from "react";
import { GiftCardRecieveAPI } from "../../Service/edit_profile";
import * as asyncState from "../../Utils/asyncState";
import "./ReceivedGiftCards.scss";
import { CircularProgress } from "@mui/material";

interface ReceivedGifts extends asyncState.AsyncState {
  data: {}[];
}

function ReceivedGiftCards({ apply, hideEmptyState, coupon, product = {} }: any) {
  const [receivedGifts, setReceivedGifts] = React.useState<ReceivedGifts>({
    ...asyncState.initState,
    data: [],
  });

  React.useEffect(() => {
    const getGiftCards = async () => {
      setReceivedGifts({ data: [], ...asyncState.pendingState });

      try {
        let userID: any = localStorage.getItem("Nurture_user_data");
        userID = JSON.parse(userID)?.id;

        let res: any = await GiftCardRecieveAPI({ user_id: userID, ...product });
        setReceivedGifts({ data: res?.data?.data ?? [], ...asyncState.successState });
      } catch (err: any) {
        setReceivedGifts({
          data: [],
          ...asyncState.failedState,
          failedMessage: err?.message,
        });
      }
    };

    getGiftCards();
  }, []);

  const availableGifts = React.useMemo(
    () => receivedGifts.data.filter((gift: any) => gift?.remaining_amount > 0),
    [receivedGifts.data]
  );

  return (
    <div className="mt-3">
      {receivedGifts.pending && (
        <div className="receivedGiftCardsCopyMain receivedGiftCardsGiftedFrom">
          <CircularProgress />
        </div>
      )}

      {!receivedGifts.pending && !availableGifts.length && !hideEmptyState && (
        <div className="receivedGiftCardsCopyMain receivedGiftCardsGiftedFrom">
          You do not have any available gift cards
        </div>
      )}

      {availableGifts?.map((gift: any, i: any) => (
        <div className="mt-2" key={gift.id}>
          <div className="receivedGiftCardsGiftedFrom">
            {gift?.sender_name}{" "}
            <span className=""> ({gift?.sender_email})</span> gifted you{' '}
            {gift?.title}
          </div>
          <div className="receivedGiftCardsAmount">
            <div className="">Total amount: ${gift?.amount} </div>
            <div className="">Remaining amount: ${gift?.remaining_amount}</div>
          </div>

          <div className="receivedGiftCardsCopyMain mt-2">
            <input
              type="text"
              value={gift?.code}
              className="form-control"
              id={gift?.id}
            />

            <button
              className="btn secondary-teal-btn ms-2"
              onClick={() => apply(gift?.code)}
              disabled={coupon === gift?.code}
            >
              Use
            </button>
          </div>
        </div>
      ))}
    </div>
  );
}

export default ReceivedGiftCards;
