import React from "react";
import "./question.scss";
import useBlankIcon from "../assets/userBlankIcon.svg";
import moment from "moment";
import unlike from "../../../Assets/img/icons/like.svg";
import liked from "../../../Assets/img/icons/liked.svg";
import { Link } from "react-router-dom";
import { getUserID } from "../../../Service/getLocalStorage";
import { ASK_A_NURSE_CHAT } from "../../../Routes/RouteConstent";
import { FiberManualRecord } from "@mui/icons-material";

const Question = ({
  question,
  onDelete,
  onToggleLike,
  className = "",
  hasNewMessages,
}: {
  onDelete: (id: number) => void;
  onToggleLike: (id: number) => void;
  question: {
    id: number;
    user_photo: string;
    user_name: { name: string };
    user_id: number;
    message: string;
    likes: number;
    created_at: string;
    is_liked: boolean;
    stage: { id: number; color_code: string; name: string }[];
    category: { id: number; color_code: string; name: string }[];
    title: string;
  };
  className?: string;
  hasNewMessages?: boolean;
}) => {
  return (
    <div className={`question ${className}`}>
      <div className="questionTags">
        {question.stage.map((c: any) => (
          <div
            className="questionTags__tag"
            style={{
              backgroundColor:
                c.color_code === "#fbf8ef" ? "#30426d" : c.color_code,
            }}
            key={c.id}
          >
            {c.name}
          </div>
        ))}
        {question.category.map((c: any) => (
          <div
            className="questionTags__tag"
            style={{
              backgroundColor:
                c.color_code === "#fbf8ef" ? "#30426d" : c.color_code,
            }}
            key={c.id}
          >
            {c.name}
          </div>
        ))}
      </div>
      <div className="questionRow">
        <div className="questionUser">
          {question.user_photo ? (
            <img
              className="questionUser__avatar"
              src={question.user_photo}
              alt={question.user_name.name}
            />
          ) : (
            <div
              className="questionUser__avatar"
              style={{ backgroundImage: `url("${useBlankIcon}")` }}
            />
          )}
          <div>{question.user_name.name}</div>
        </div>
        <div className="questionMain">
          <div className="questionMain__date">
            {moment(question?.created_at).fromNow()}{" "}
            {hasNewMessages && (
              <FiberManualRecord
                style={{ color: "rgb(245, 125, 125)" }}
                fontSize="small"
              />
            )}
          </div>
          <div className="questionMain__message">{question.title}</div>
          <Link
            className="questionMain__readMore"
            to={`/${ASK_A_NURSE_CHAT}/${question.id}`}
          >
            Read more
          </Link>
        </div>

        <div className="questionControlsWrapper">
          <div className="questionControlsLike">
            <button onClick={() => onToggleLike(question.id)}>
              {question.is_liked ? (
                <img src={liked} alt="like" />
              ) : (
                <img src={unlike} alt="unlike" />
              )}
            </button>
            {question.likes}
          </div>
          {question.user_id === getUserID() && (
            <button
              className="questionControlsDelete"
              onClick={() => onDelete(question.id)}
            >
              Delete
            </button>
          )}
        </div>
      </div>
      <div className="questionMobile">
        <div className="questionControlsLike">
          <button onClick={() => onToggleLike(question.id)}>
            {true ? (
              <img src={liked} alt="like" />
            ) : (
              <img src={unlike} alt="unlike" />
            )}
          </button>
          {question.likes}
        </div>
        {question.user_id === getUserID() && (
          <button
            className="questionControlsDelete"
            onClick={() => onDelete(question.id)}
          >
            Delete
          </button>
        )}
      </div>
    </div>
  );
};

export default Question;
