import { CircularProgress } from '@mui/material';
import React from 'react'
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import { getDataFromLocalStorage } from '../../Service/getLocalStorage';
import { cancelMembershipApi } from '../../Service/membership';

const ConfirmPopup = (props: any) => {
    let { open, setOpen, data, setData, setMemberData, otherApi, openSuccessfullyCancelledModal } = props;
    const [loading, setLoading] = React.useState(false)
    let handleClose = () => {
        setOpen(false)
    }
    const userData = getDataFromLocalStorage()

    const cancelSubs = async () => {
        try {
            setLoading(true)
            let response: any = await cancelMembershipApi({ user_id: userData.id, membership_id: data?.id })
            if (response.data.success) {
                otherApi();
                localStorage.setItem("Nurture_user_data", JSON.stringify(response?.data?.data));
                window.dispatchEvent(new Event('storage'));
                openSuccessfullyCancelledModal()
            }
            setOpen(false)
            setLoading(false)
            let messageArr = response?.data?.message
            let msg = ""
            for (let i = 0; i < messageArr.length; i++) {
                msg += messageArr[i] + "\n"
            }
        } catch (error) {
            setLoading(false)
        }
    }

    return (
        <Modal
            show={open} onHide={handleClose}
            size="sm"
            id="delete-msg-modal"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className='custom-modalTeal modal'
        >
            <Modal.Body>
                <p style={{ color: '#fff' }}>Once you cancel your subscription, you will lose all membership benefits at the end of your current billing cycle. </p>
                <h4>Are you sure you want to cancel?</h4>
            </Modal.Body>
            <Modal.Footer className='border-0 d-flex flex-column justify-content-center modal-footer'>
                <button className='w-50 primary-outline-btn h50' disabled={loading} onClick={cancelSubs}>{loading ? <CircularProgress /> : 'Yes, Cancel'}</button>
                <button className='btn gotohome h50' onClick={handleClose}>No, Go Back</button>
            </Modal.Footer>
        </Modal>
    )
}

export default ConfirmPopup;