
import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import './congratsModel.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import { GIFTCARD } from '../../Routes/RouteConstent';
import { useQuery } from '../../Hooks/useQuery';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    justifyContent: "flex-start",


};
interface Iprops {
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    text?: String,

}
export default function CongratsModel(props: Iprops) {
    let { open, setOpen, text = "Thanks for buying our membership" } = props;

    const { state }: any = useLocation();
    const serviceParam = useQuery().get('service') || state?.param;

    let Navigate = useNavigate()
    const [serviceName, setServiceName] = React.useState<any>("Gift a Support group to your friend")

    const handleClose: any = (data: any, type: any) => {
        // debugger
        if (type === "backdropClick" || "escapeKeyDown") {
            return;
        } else {
            setOpen(false)
        }

    }
   
    const giftButton = () => {

        if (serviceParam == "service_4") {
            return setServiceName("Gift a Support group to your friend")

        } else if (serviceParam === "service_9" || serviceParam === "service_10" || serviceParam === "service_11" || serviceParam === "service_12") {
            return setServiceName("Gift a Virtual Class to your friend")
        } else if (
            serviceParam === "service_1" ||
            serviceParam === "service_2" ||
            serviceParam === "service_3" ||
            serviceParam === "service_5" ||
            serviceParam === "service_6" ||
            serviceParam === "service_7" ||
            serviceParam === "service_24" ||
            serviceParam === "service_23" ||
            serviceParam === "service_8" ||
            serviceParam === "service_13" ||
            serviceParam === "service_25" ||
            serviceParam === "service_14" ||
            serviceParam === "service_17"
        ) {
            return setServiceName("Gift a Consult to your friend")
        }
        else if (serviceParam === "CPR and first aid private class") {

            return setServiceName("Gift a private Class to your friend")
        }
        else if (serviceParam === "Initial lactation consult private class") {

            return setServiceName("Gift a private Class to your friend")
        }

        else if (state?.tab === "1") {
            return setServiceName("Gift to your friend")
        }
    }
    React.useEffect(() => {
        giftButton()
    }, [])

    const modelClose = () => {
        // debugger
        setOpen(false)
    }
    return (
        <div>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >

                <Box sx={style} className="membership-congrats">
                    <IconButton sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8
                    }}>
                        <CloseIcon onClick={modelClose} />

                    </IconButton>
                    {/* <button onClick={handleCls}>Close</button> */}
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Thank you!
                    </Typography>
                    {/* <h3> {text}</h3> */}
                    <p>{text}</p>

                    {/* <Typography className="charged" id="modal-modal-description" sx={{ mt: 2 }}>
                        You will be charged only after the 7-day free trial ends.
                    </Typography> */}

                    {serviceParam == "service_1" && <button className='primary-blue-btn' onClick={() => Navigate("/")} >Explore home page</button> }
                    {serviceParam == "service_1" ? "" : <button className='btn gotohome' onClick={() => Navigate("/")}>Go to home page</button>}
                </Box>
            </Modal>
        </div>
    );
}
