import React from 'react'
import { DialogProps } from '@mui/material';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { CityStateZipValSub } from '../../../../Common/commonFIeld/validation';
import { Link } from 'react-router-dom';
import * as path from "../../../../Routes/RouteConstent";

const UserInfo2 = ({ handleNext, value, setValue, handlePrev, term_condition, setTermPolicy }: any) => {

  const [error, setError] = React.useState<any>({})
  const [open, setOpen] = React.useState<boolean>(false);
  const [scroll, setScroll] = React.useState<DialogProps['scroll']>('paper');
  const [clientService,setClientService] = React.useState(false)
    const [consent,setConsent] = React.useState(false)
    const [privacy,setPrivacy] = React.useState(false)

  let handleDueDate = (e: Date | null | any) => {

    setError({
      ...error,
      ['due_date']: ""
    })
    setValue({
      ...value,
      ['due_date']: e
    })
  }

  let handleRadioButton = (e: any) => {
    let val = e.target.value;
    setError({
      ...error,
      [e.target.name]: ""
    })
    setValue({
      ...value,
      [e.target.name]: val
    })
  }

  const handleChange = (e: any) => {
    let name = e.target.name;
    let inputValue = e.target.value;

    if (e.target.name === "client_service_agreement" || e.target.name === "privacy_practises") {

      if (e.target.checked) {
        setError({
          ...error,
          [e.target.name]: ""
        })
      } else {
        setError({
          ...error,
          [e.target.name]: "Please select the field"
        })
      }
      setValue({
        ...value,
        [e.target.name]: e.target.checked
      })
      return

    }
    setValue({
      ...value,
      [name]: inputValue
    })
  };

  const policyHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError({
      ...error,
      ["policy"]: ""
    })
    setTermPolicy(e.target.checked)
    setValue({
      ...value,
      term_condition: e.target.value
    })

  }
  const handleSleepConsult = (e: any) => {

    e.preventDefault()
    let cityStateZipErr = CityStateZipValSub(value)
    if (!value?.due_date || value?.due_date === "Invalid date" || cityStateZipErr
      || !value?.is_your_first_baby || !value?.client_service_agreement
      || !value?.medical_surgical_history_baby || !value?.privacy_practises
      || !value?.what_is_your_biggest_sleep_concern || !value?.what_are_your_goals
      || !term_condition || !/^\+?[1-9][0-9]{7,14}$/.test(value?.phone)
      || !value?.phone
    ) {
      let errors: any = {}
      if (!value?.due_date) {
        errors.due_date = "Please select date"
      }
      if (value?.due_date === "Invalid date") {
        errors.due_date = "Please select date"
      }
      if (!value?.is_your_first_baby) {
        errors.is_your_first_baby = "Please input the value"
      }
      if (!value?.medical_surgical_history_baby) {
        errors.medical_surgical_history_baby = "Please input the value"
      }
      if (!value?.what_is_your_biggest_sleep_concern) {
        errors.what_is_your_biggest_sleep_concern = "Please input the value"
      }
      if (!value?.what_are_your_goals) {
        errors.what_are_your_goals = "Please check the policy"
      }
      if (!term_condition) {
        errors.term_condition = "Please the select the policy to continue "
      }
      if (!value?.client_service_agreement) {
        errors.client_service_agreement = "Please select the policy to continue"
      }
      if (!value?.privacy_practises) {
        errors.privacy_practises = "Please select the policy to continue"
      }
      if (!/^\+?[1-9][0-9]{7,14}$/.test(value?.phone)) {
        errors.phone = "Invalid phone number"
      }
      if (!value?.phone) {
          errors.phone = "Phone number is Required"
      }
      setError({ ...errors, ...cityStateZipErr })
      return
    }
    handleNext(value)
  }
  

//   const consentHandle = (e: any) => {
//     e.preventDefault()
    
//     setConsent(true)
// }

  return (
    // <div>UserInfo2</div>
    <div className='remote-sleep-consult'>
      {/* <TermConditionModel open={open} setOpen={setOpen} scroll={scroll} setScroll={setScroll} /> */}
      <section className='step-container step2'>
        <div className='consult-section-heading text-center'>
          <h3 className='my-5'>Complete your Information</h3>
        </div>
        <form className=' '>
          <div className='row'>
            <div className="form-group col-lg-12 col-md-12 mb-3 due_dt_container">
              <label >Due Date/Child's Birthday <span className='mandatory text-danger mx-2'>*</span></label>
              <LocalizationProvider dateAdapter={AdapterDayjs} >

                <Stack spacing={3}>
                  <MobileDatePicker
                    value={value?.due_date || ""}
                    onChange={handleDueDate}
                    renderInput={(params: any) => <TextField {...params} placeholder="MM-DD-YYYY" className='due-date-field' />}
                  />
                </Stack>
              </LocalizationProvider>
              <h4 className="error-msgtext">{error?.due_date}</h4>
            </div>

            <div className="form-group col-lg-12 col-md-12 mb-3">
              <label>Child's Name: <span className="fw-light"> (if applicable)</span> </label>
              <input type="text" name="child_name" className="form-control" value={value?.child_name} onChange={handleChange} />
              {/* <h4 className="error-msgtext">{error?.child_name}</h4> */}
            </div>

            <div className="form-group col-lg-12 col-md-12 mb-3">
              <label>Is this your first baby? <span className='mandatory text-danger mx-2'>*</span></label>
              <div className="custom-radio custom-control-inline">
                <input type="radio" id="customRadio1" name="is_your_first_baby" value="yes"
                  onChange={handleRadioButton} checked={value?.is_your_first_baby === "yes"}
                  className="custom-control-input form-check-input"
                />
                <label className="custom-control-label me-3" htmlFor="customRadio1">yes</label>
                <input type="radio" id="customRadio2" name="is_your_first_baby"
                  value="no" onChange={handleRadioButton} checked={value?.is_your_first_baby === "no"} className="custom-control-input form-check-input"
                />
                <label className="custom-control-label step_two" htmlFor="customRadio2">no</label>
              </div>
              <h4 className="error-msgtext">{error?.is_your_first_baby}</h4>
            </div>

            <div className="form-group col-lg-12 col-md-12 mb-3">
                <label>Phone Number <span className='mandatory text-danger mx-2'>*</span></label>
                <input type="text" name='phone' value={value?.phone} className='form-control' onChange={handleChange} />
                <h4 className="error">{error?.phone}</h4>
            </div>

            <div className="form-group col-sm-12 mb-3">
              <label>
                City <span className="mandatory text-danger mx-2">*</span>
              </label>
              <input
                type="text"
                name="city"
                value={value?.city}
                className="form-control"
                onChange={handleChange}
              />
              <h4 className="error-msgtext">{error?.city}</h4>
            </div>
            <div className="form-group col-sm-12 mb-3">
              <label>
                State <span className="mandatory text-danger mx-2">*</span>
              </label>
              <input
                type="text"
                name="state"
                value={value?.state}
                className="form-control"
                onChange={handleChange}
              />
              <h4 className="error-msgtext">{error?.state}</h4>
            </div>
          
            <div className="form-group col-lg-12 col-md-12 mb-3">
              <label>Partner or Support Person's Name <span className='fw-light'>(if applicable) </span></label>
              <input type="text" className='form-control' name='partner_support_person_name' onChange={handleChange} value={value?.partner_support_person_name} placeholder='Type something' />
              {/* <h4 className="error-msgtext">{error?.partner_support_person_name}</h4> */}
            </div>

            <div className="form-group col-lg-12 col-md-12 mb-3">
              <label>Partner or Support Person's Email address <span className='fw-light'>(for reminders, content, and follow up)</span></label>
              <input type="text" name='partner_support_person_email_address' onChange={handleChange} className='form-control' value={value?.partner_support_person_email_address} placeholder='Type something' />
              {/* <h4 className="error-msgtext">{error?.partner_support_person_email_address}</h4> */}
            </div>

            <div className="form-group col-lg-12 col-md-12 mb-3">
              <label>Will your partner be on the call? <span className='fw-light'>(encouraged by not required!)</span></label>
              <div className="custom-radio custom-control-inline">
                <input type="radio" id="customRadio1" name="your_partner_be_the_call" onChange={handleRadioButton} value="yes"
                  className="custom-control-input form-check-input"
                  checked={value?.your_partner_be_the_call === "yes"}
                />
                <label className="custom-control-label me-3" htmlFor="customRadio1">yes</label>
                <input type="radio" id="customRadio2" name="your_partner_be_the_call" onChange={handleRadioButton}
                  value="no" checked={value?.your_partner_be_the_call === "no"} className="custom-control-input form-check-input"
                />
                <label className="custom-control-label step_two" htmlFor="customRadio2">no</label>
              </div>
              <h4 className="error-msgtext d-none">{"error"}</h4>
            </div>

            <div className="form-group col-lg-12 col-md-12 mb-3">
              <label>Please list any medical or surgical history for your baby that we should be aware of: <span className='mandatory text-danger mx-2'>*</span></label>
              <textarea name='medical_surgical_history_baby' className='form-control' value={value?.medical_surgical_history_baby} onChange={handleChange} placeholder='Type something'> </textarea>
              <h4 className="error-msgtext">{error?.medical_surgical_history_baby}</h4>
            </div>

            <div className="form-group col-lg-12 col-md-12 mb-3">
              <label>What methods <span className='fw-light'> (if any) </span> of sleep training have you tried? <span className='mandatory text-danger mx-2'>*</span></label>
              <textarea name='what_method_of_sleep_training' className='form-control' value={value?.what_method_of_sleep_training} onChange={handleChange} placeholder='Type something'> </textarea>
              {/* <h4 className="error-msgtext">{error?.what_method_of_sleep_training}</h4> */}
            </div>

            <div className="form-group col-lg-12 col-md-12 mb-3">
              <label>What is your biggest sleep concern you would like to address during the consultation? <span className='mandatory text-danger mx-2'>*</span></label>
              <textarea name='what_is_your_biggest_sleep_concern' className='form-control' value={value?.what_is_your_biggest_sleep_concern} onChange={handleChange} placeholder='Type something'> </textarea>
              <h4 className="error-msgtext">{error?.what_is_your_biggest_sleep_concern}</h4>
            </div>

            <div className="form-group col-lg-12 col-md-12 mb-3">
              <label>What are your goals for this consultation? <span className='mandatory text-danger mx-2'>*</span></label>
              <textarea name='what_are_your_goals' className='form-control' value={value?.what_are_your_goals} onChange={handleChange} placeholder='Type something'> </textarea>
              <h4 className="error-msgtext">{error?.what_are_your_goals}</h4>
            </div>

            {/* <div className="radio-area mb-3">
              <h4>How did you hear about us? (optional)</h4>
              <div className="custom-control custom-radio">
                <div className="form-check">
                  <input className="form-check-input" type="radio" name="hear_about" value="full time working parent" onChange={handleRadioButton} id="customCheck1" />
                  <label className="form-check-label" >
                    Social Media
                  </label>
                </div>
              </div>

              <div className="custom-control custom-radio">
                <div className="form-check">
                  <input className="form-check-input" type="radio" name="hear_about" value="part time working parent" onChange={handleRadioButton} id="flexRadioDefault2" />
                  <label className="form-check-label" >
                    Friend
                  </label>
                </div>
              </div>

              <div className="custom-control custom-radio">
                <div className="form-check">
                  <input className="form-check-input" type="radio" name="hear_about" value="full time stay at home parent" onChange={handleRadioButton} id="flexRadioDefault2" />
                  <label className="form-check-label" >
                    Print
                  </label>
                </div>
              </div>

              <div className="custom-control custom-radio">
                <div className="form-check">
                  <input className="form-check-input" type="radio" name="hear_about" value="full time stay at home parent" onChange={handleRadioButton} id="flexRadioDefault2" />
                  <label className="form-check-label" >
                    Internet Search
                  </label>
                </div>
              </div>

              <div className="custom-control custom-radio">
                <div className="form-check">
                  <input className="form-check-input" type="radio" name="hear_about" value="full time stay at home parent" onChange={handleRadioButton} id="flexRadioDefault2" />
                  <label className="form-check-label" >
                    Healthcare professional
                  </label>
                </div>
              </div>

            </div> */}
            <div className='agreement-container py-4 d-flex flex-column'>
              <div>
                  <div className="custom-radio custom-control-inline">
                      <input type="checkbox" id="customRadio1" name='term_condition'
                          className="custom-control-input form-check-input" value="Accepted"
                          checked={term_condition}
                          onChange={policyHandler}
                      />
                      <label className="custom-control-label" htmlFor="customRadio1">I have read and agree to the <Link target="_blank" className='blue-link' to={path.TERMS} > terms of service</Link></label>
                  </div>
                  <h4 className="error-msgtext">{error?.term_condition}</h4>
              </div>

              <div>
                  <div className="custom-radio custom-control-inline">
                      <input type="checkbox" id="customRadio1" name='client_service_agreement'
                          className="custom-control-input form-check-input" value="Accepted"
                          checked={value?.client_service_agreement}
                          onChange={handleChange}
                      />
                      <label className="custom-control-label" htmlFor="customRadio1">I have read and agree to the <Link target="_blank" className='blue-link' to={path.CLIENT_SERVICE_AGREEMENT} > client service agreement </Link></label>
                  </div>
                  <h4 className="error-msgtext">{error?.client_service_agreement}</h4>
              </div>

              <div>
                  <div className="custom-radio custom-control-inline">
                      <input type="checkbox" id="" name='privacy_practises'
                          className="custom-control-input form-check-input" value="Accepted"
                          checked={value?.privacy_practises}
                          onChange={handleChange}
                      />
                      <label className="custom-control-label" htmlFor="">I have read and agree to the <Link target="_blank" className='blue-link' to={path.CONSENT_FOR_DISCLOSURE} > consent for disclosure </Link>and <Link target="_blank" className='blue-link' to={path.PRIVACY} > privacy practices</Link></label>
                  </div>
                  <h4 className="error-msgtext">{error?.privacy_practises}</h4>
              </div>
            </div>
          </div>
        </form>

        <div className='section-navigation my-5 d-flex justify-content-between'>
          <button className='primary-blue-small-btn previous' onClick={handlePrev}>Previous</button>
          <button className='primary-blue-small-btn next' onClick={handleSleepConsult}>Next</button>
        </div>
      </section>
    </div>
  )
}

export default UserInfo2