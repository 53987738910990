import React from 'react';
import fav_video_icon from '../../../Assets/img/course/fav-icon-video.svg'


const FavCourseEmpty = ({ data }: any) => {
    return (
        <div> {data?.length === 0 && <div className='row justify-content-center'>
            <div className="col-lg-6 col-md-6 text-center">
                <div className='d-flex flex-column justify-content-center'>
                    <div className='d-flex justify-content-center align-items-center fav-icon-cont mx-auto'>
                        <img src={fav_video_icon} />
                    </div>
                    <h2 className='text-center my-3 pt-3 pb-0'>Collect your <span className='strong'> Favorite</span></h2>
                    <p>Your Favorite courses will appear here</p>
                </div>
            </div>
        </div>}
        </div>
    )
}

export default FavCourseEmpty;