import React, { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import podCastImg from "../../Assets/img/podCast.svg";
import "./PodcastQuestion.scss";
import { TextFieldProps } from "@mui/material/TextField";
import { CreateQuestion, Question } from "../../Interfaces/userInterfaces";
import { podCastSubmissionData } from "../../Service/blog";
import { Autocomplete, CircularProgress } from "@mui/material";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { NURSECONSULTS } from "../../Routes/RouteConstent";
import { useNavigate } from "react-router-dom";
import { nursing_consult, phoneRegex } from "../../Utils/constant";
import { GetPrefilledData } from "../../Utils/getUserData/getUserData";
import CongratsModel2 from "../../Common/CongratsPopup2/CongratsPopup2";
import isMebershipAndActiveCheck from "../../Utils/membership_check";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { HeroBanner } from "../../Common/HeroBanner";

let initialState: CreateQuestion = {
  first_name: "",
  last_name: "",
  email: "",
  phone: "",
  dob: "",
  subject: "",
  question: "",
  level: "medium",
  perspective: "",
  address: "",
  age: "",
  ethnicity: "",
  otherAddress: "",
};

interface IProps {}

const PodcastQuestion: React.FC<IProps> = () => {
  const [questionData, setQuestionData] =
    useState<CreateQuestion>(initialState);
  const [error, setError] = useState<Question>({});
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = useState(false);
  const [user_data] = GetPrefilledData();
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  let user: any = localStorage.getItem("Nurture_user_data");
  user = JSON.parse(user);

  const handleChange = (e: any) => {
    let { name, value } = e.target;
    setQuestionData({
      ...questionData,
      [name]: value,
    });
    setError({
      ...error,
      [name]: '',
    });
  };

  const handleDateChange = async (val: Date | any) => {
    setQuestionData({
      ...questionData,
      ["dob"]: val,
    });
    setError({
      ...error,
      ["dob"]: "",
    });
  };

  const checkErrors = () => {
    let errors: any = {};

    if (!questionData?.first_name) {
      errors.first_name = "Please enter your first name";
    }

    if (!questionData?.last_name) {
      errors.last_name = "Please enter your last name";
    }
    if (!questionData?.email) {
      errors.email = "Please enter your email";
    }
    if (!phoneRegex.test(questionData?.phone)) {
      errors.phone = "Please enter valid number";
    }
    if (!questionData?.phone) {
      errors.phone = "Please enter your phone number";
    }
    if (!questionData?.subject) {
      errors.subject = "Please enter your subject";
    }
    if (!questionData?.question) {
      errors.question = "Please enter your question";
    }
    if (!questionData?.dob) {
      errors.dob = "Please select date";
    }
    if (!questionData?.perspective) {
      errors.perspective = "Please enter your perspective";
    }
    if (!questionData?.address) {
      errors.address = "Please enter your address";
    }
    if (!questionData?.age) {
      errors.age = "Please select your age";
    }

    setError(errors);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    try {
      if (
        !questionData?.first_name ||
        !questionData?.last_name ||
        !questionData?.email ||
        !questionData?.phone ||
        !phoneRegex.test(questionData?.phone) ||
        !questionData?.subject ||
        !questionData?.question ||
        !questionData?.perspective ||
        !questionData?.address ||
        !questionData?.age ||
        !questionData?.dob ||
        questionData?.dob === "Invalid date"
      ) {
        checkErrors();
      } else {
        setLoading(true);

        let param = {
          first_name: questionData?.first_name,
          last_name: questionData?.last_name,
          email: questionData?.email,
          phone: questionData?.phone,
          dob: questionData?.dob,
          subject: questionData?.subject,
          question: questionData?.question,
          perspective: questionData?.perspective,
          address: questionData?.address,
          age: questionData?.age,
          ethnicity:
            questionData?.ethnicity === "Other:"
              ? `Other: ${questionData?.otherAddress}`
              : questionData?.ethnicity,
          user_id: user?.id,
        };

        let res: any = await podCastSubmissionData(param);
        isMebershipAndActiveCheck(res, Navigate, toast, dispatch);
        if (res?.data?.success) {
          setLoading(false);
          setOpen(true);
          // setQuestionData(initialState)
        }
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };
  useEffect(() => {
    if (user_data) {
      setQuestionData(user_data);
    }
  }, [user_data]);

  return (
    <div>
      <CongratsModel2
        open={open}
        setOpen={setOpen}
        btnText="Book a 30 min Nursing consult"
        Page={NURSECONSULTS}
        otherPageSetting={{ param: nursing_consult }}
        text={
          "Thanks for submitting the podcast question. Someone from NAPS may reach out to you soon. If you do not wish to have your question answered live on our podcast, you may be interested more in a nursing consultation call."
        }
      />
      <Container fluid className="Podcast-container">
        <div className="py-3">
          <HeroBanner hero={podCastImg} maxDesktopHeight={500} />
        </div>
        <Row no-gutter className="pale-bg py-4">
          <div className="col-lg-12 col-md-12 podcast-discription-section">
            <div className="container">
              <Row className="justify-content-center">
                <div className="col-lg-12 col-md-12">
                  <h3 className="text-center podcast-section-title my-3">
                    Podcast Story{" "}
                    <span className="strong red">Submission</span>
                  </h3>
                  <div className="px-5 podcast_description">
                    <p className="text-center mt-5">
                      <span className="strong">
                        Thank you for your interest in joining us on{" "}
                        <a
                          href="https://podcasts.apple.com/us/podcast/unswaddled/id1560213381"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Unswaddled: The Truth About Parenting
                        </a>{" "}
                        to share your postpartum story.
                      </span>{" "}
                      Please provide some information about yourself and the
                      postpartum story you'd like to share so we can curate a
                      wide range of episodes for our listeners. If your story is
                      selected, we will contact you directly via email or phone
                      to schedule a recording.
                    </p>

                    <p className="mt-5">
                      <span className="strong">
                        Things to know before you sign up:
                      </span>
                    </p>
                    <ul className="podcast-custom-list">
                      <li>
                        <p>
                          We record our podcast using Riverside and will send
                          you a link to join us.
                        </p>
                      </li>
                      <li>
                        <p>
                          Emily and Jamie only record on Wednesdays from 11 AM -
                          12 PM EST.
                        </p>
                      </li>
                      <li>
                        <p>
                          While our videos will be on, you don’t need to be
                          camera-ready, as we only use the audio for the
                          podcast.
                        </p>
                      </li>
                      <li>
                        <p>
                          The episode will air on all major podcast platforms,
                          and we will also share the audio on our social media
                          channels, including Instagram and YouTube.
                        </p>
                      </li>
                    </ul>
                    <p className="mt-5">
                      <span className="strong">We ask that you:</span>
                    </p>
                    <ul className="podcast-custom-list">
                      <li>
                        <p>
                          Have reliable internet and a quiet place to record.
                        </p>
                      </li>
                      <li>
                        <p>Wear headphones if possible.</p>
                      </li>
                      <li>
                        <p>
                          Submit 30 seconds of video clips or pictures of you or
                          your family. We are looking for real footage, directly
                          from your phone, to create a short promotional video
                          for the podcast. (Don’t worry about background noise
                          in the videos; we will overlay the final podcast audio
                          on top!)
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </Row>
            </div>
          </div>
        </Row>
        <Row className="podcast-form my-4">
          <div className="col-lg-12 col-md-12">
            <div className="container">
              <Row className="justify-content-center">
                <div className="col-lg-8 col-md-8">
                  <form className="row">
                    <div className="form-group col-lg-12 mb-3">
                      <div className="row">
                        <div
                          className="form-group col-lg-6 col-md-6"
                          id="scrollTargetElement"
                        >
                          <label>
                            First Name <span className="required">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={handleChange}
                            value={questionData?.first_name || ''}
                            name="first_name"
                            placeholder="First Name"
                          />
                          <h4 className="error-msgtext">{error.first_name}</h4>
                        </div>
                        <div className="form-group col-lg-6 col-md-6">
                          <label>
                            Last Name <span className="required">*</span>
                          </label>
                          <input
                            type="text"
                            name="last_name"
                            className="form-control"
                            onChange={handleChange}
                            value={questionData?.last_name || ''}
                            placeholder="Last name"
                          />
                          <h4 className="error-msgtext">{error.last_name}</h4>
                        </div>
                      </div>
                    </div>

                    <div className="form-group col-lg-12 mb-3">
                      <div className="row">
                        <div className="form-group col-lg-6 col-md-6">
                          <label>
                            Email <span className="required">*</span>
                          </label>
                          <input
                            type="email"
                            name="email"
                            className="form-control"
                            value={questionData?.email || ''}
                            id=""
                            onChange={handleChange}
                            placeholder="Email"
                          />
                          <h4 className="error">{error?.email}</h4>
                        </div>
                        <div className="form-group col-lg-6 col-md-6">
                          <label>
                            Phone number <span className="required">*</span>
                          </label>
                          <input
                            type="number"
                            name="phone"
                            className="form-control"
                            id=""
                            value={questionData?.phone || ''}
                            onChange={handleChange}
                            placeholder="Phone number"
                          />
                          <h4 className="error">{error?.phone}</h4>
                        </div>
                      </div>
                    </div>

                    <div className="form-group col-lg-12 mb-3">
                      <div className="row">
                        <div className="form-group col-lg-6 col-md-6">
                          <label>
                            Due Date / Child's Birthday{" "}
                            <span className="required">*</span>
                          </label>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Stack spacing={3}>
                              <MobileDatePicker
                                value={questionData?.dob || ""}
                                toolbarTitle="Choose date"
                                // disableFuture

                                onChange={(val: any) => handleDateChange(val)}
                                renderInput={(
                                  params: JSX.IntrinsicAttributes &
                                    TextFieldProps
                                ) => (
                                  <TextField
                                    error={false}
                                    placeholder="MM-DD-YYYY"
                                    {...params}
                                  />
                                )}
                              />
                            </Stack>
                          </LocalizationProvider>
                          <h4 className="error">{error?.dob}</h4>
                        </div>
                        <div className="form-group col-lg-6 col-md-6">
                          <label>
                            Subject or suggested title for your story
                            <span className="required">*</span>
                          </label>
                          <input
                            type="text"
                            name="subject"
                            className="form-control"
                            onChange={handleChange}
                            id=""
                            value={questionData?.subject || ""}
                            placeholder="Subject"
                          />
                          <h4 className="error">{error?.subject}</h4>
                        </div>
                      </div>
                    </div>

                    <div className="form-group col-lg-12 mb-3">
                      <div className="row">
                        <div className="form-group col-lg-12 col-md-12">
                          <label>
                            <span className="strong">
                              If you had to pick one part of your postpartum
                              story to share, what would it be?
                            </span>{" "}
                            <span className="podcast-label-text-normal">
                              We want to hear the truths of postpartum – the
                              good, the hard, what surprised you the most, and
                              how you helped yourself or who supported you. Tell
                              us more:
                            </span>{" "}
                            <span className="required">*</span>
                          </label>
                          <textarea
                            name="question"
                            className="form-control"
                            onChange={handleChange}
                            value={questionData?.question || ""}
                            placeholder="Question"
                          />
                          <h4 className="error">{error?.question}</h4>
                        </div>
                      </div>
                    </div>

                    <div className="form-group col-lg-12 mb-3">
                      <div className="row">
                        <div className="form-group col-lg-12 col-md-12">
                          <label>
                            <span className="strong">
                              What unique perspective do you feel your story
                              offers to the audience?
                            </span>{" "}
                            <span className="podcast-label-text-normal">
                              Or, what is something you’re sharing that others
                              can relate to, but we don’t talk about enough?
                            </span>{" "}
                            <span className="required">*</span>
                          </label>
                          <textarea
                            name="perspective"
                            className="form-control"
                            onChange={handleChange}
                            value={questionData?.perspective || ""}
                            placeholder="Perspective"
                          />
                          <h4 className="error">{error?.perspective}</h4>
                        </div>
                      </div>
                    </div>

                    <div className="form-group col-lg-12 mb-3">
                      <div className="row">
                        <div className="form-group col-lg-12 col-md-12">
                          <label>
                            Where do you live?
                            <span className="required">*</span>
                          </label>
                          <input
                            type="text"
                            name="address"
                            className="form-control"
                            onChange={handleChange}
                            id=""
                            value={questionData?.address || ""}
                            placeholder="Address"
                          />
                          <h4 className="error">{error?.address}</h4>
                        </div>
                      </div>
                    </div>

                    <div className="form-group col-lg-12 mb-3">
                      <div className="row">
                        <div className="form-group col-lg-12 col-md-12">
                          <label>
                            What is your age?
                            <span className="required">*</span>
                          </label>
                          <Autocomplete
                            options={["18", "19-24", "25-34", "35-44", "45+"]}
                            onChange={(_, value) => {
                              setQuestionData({
                                ...questionData,
                                age: value,
                              });
                              setError((errors) => ({
                                ...errors,
                                age: "",
                              }));
                            }}
                            clearOnBlur={true}
                            value={questionData?.age || null}
                            renderInput={(params) => <TextField {...params} />}
                          />
                          <h4 className="error">{error?.age}</h4>
                        </div>
                      </div>
                    </div>

                    <div className="form-group col-lg-12 mb-3">
                      <div className="row">
                        <div className="form-group col-lg-12 col-md-12">
                          <label>I identify my ethnicity as (optional):</label>
                          <Autocomplete
                            options={[
                              "White",
                              "Hispanic/Latinx",
                              "Black/African",
                              "American Indian or Alaska Native",
                              "Asian",
                              "Pacific Islander or Native Hawaiian",
                              "Other:",
                            ]}
                            onChange={(_, value) => {
                              setQuestionData({
                                ...questionData,
                                ethnicity: value,
                              });
                            }}
                            clearOnBlur={true}
                            value={questionData?.ethnicity || null}
                            renderInput={(params) => <TextField {...params} />}
                          />
                          <h4 className="error">{error?.ethnicity}</h4>
                        </div>
                      </div>
                    </div>

                    {questionData?.ethnicity === "Other:" && (
                      <div className="form-group col-lg-12 mb-3">
                        <div className="row">
                          <div className="form-group col-lg-12 col-md-12">
                            <label>Other (optional)</label>
                            <input
                              type="text"
                              name="otherAddress"
                              className="form-control"
                              onChange={handleChange}
                              id=""
                              value={questionData?.otherAddress || ""}
                            />
                            <h4 className="error">{error?.otherAddress}</h4>
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="col-lg-12 col-md-12 mt-3">
                      <button
                        className="primary-blue-btn mb-5 w-100"
                        disabled={loading}
                        onClick={handleSubmit}
                      >
                        {loading ? <CircularProgress /> : "Submit"}
                      </button>
                    </div>
                  </form>
                </div>
              </Row>
            </div>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default PodcastQuestion;
