import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import "./ProfileUser.scss";
import { Container, Row } from 'react-bootstrap';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Step1 from './step/step1';
import Step2 from './step/step2';
import Step3 from './step/step3';
import Step4 from './step/step4';
import StepperMenu from './step/step';
import { 
    CourseCompletedAPI, 
    CourseFavouriteAPI, 
    CoursePurchased, 
    CourseStartedApi, 
    FavouriteEventAPI, 
    GiftCardRecieveAPI, 
    GiftCardSendAPI, 
    PastEventAPI, 
    PastServiceAPI, 
    UpcomingEventAPI, 
    UpcomingServiceAPI 
} from '../../Service/edit_profile';
import StepBottom from './step/stepBottom';
import { EDITPROFILE } from '../../Routes/RouteConstent';
import Step5 from './step/step5';
import { getUserProfileEdit } from '../../Service/update_profile';
import { Avatar, Stack } from '@mui/material';
import { useDispatch } from 'react-redux';
import { getFavEvent, getUpComingEvent } from '../../Redux/Events/EventsReducer';
import { EventFavSetSate } from '../../Utils/Events/Fav';
import { EventLikeSetState } from '../../Utils/Events/Like';
import SuggestConsult from './step/SuggestConsult';
import isMebershipAndActiveCheck from '../../Utils/membership_check';
import { toast } from 'react-toastify';
import { MEMBERSHIP_PRE_LOGIN } from '../../Routes/RouteConstent';



const ProfileUser = (props: any) => {

    const [activeStep, setActiveStep] = React.useState('MyCourses');
    const [profileData, setProfileData] = useState<any>({})
    const [earnCon, SetEarnCon] = useState<any>([])

    const [subStep, setSubStep] = useState<number>()
    const [subStep1, setSubStep1] = useState<number>(1)
    const [subStep2, setSubStep2] = useState<number>(1)
    const [subStep3, setSubStep3] = useState<number>(1)

    const [allCourse, setAllCourse] = useState<any>([])
    const [startCourses, setStartCourses] = useState<any>([])
    const [completeCourses, setCompletedCourses] = useState<any>([])
    const [favCourses, setFavCourses] = useState<any>([])
    const [recStart, setRecStart] = useState<any>([])
    const [recComplete, setRecComplete] = useState<any>([])
    const [recFav, setRecFav] = useState<any>([])
    const [purchased, setPurchased] = useState<any>([])

    const [upComeEvt, setUpComeEvt] = useState<any>([])
    const [pastEvt, setPastEvt] = useState<any>([])
    const [favEvt, setFavEvt] = useState<any>([])
    const [recUpcomeEvt, setRecUpcomeEvt] = useState<any>([])
    const [recPastEvt, setRecPastEvt] = useState<any>([])
    const [recFavEvt, setRecFavEvt] = useState<any>([])

    const [upComeService, setUpComeService] = useState<any>([])
    const [pastService, setPastService] = useState<any>([])
    const [recUpService, setRecUpService] = useState<any>([])
    const [recPastService, setRecPastService] = useState<any>([])
    const [messageDisp, setMessageDisp] = useState<any>([])

    const [giftRecieve, setGiftRecieve] = useState<any>([])
    const [giftSent, setGiftSent] = useState<any>([])
    const [recGiftCard, setRecGiftCard] = useState<any>([])

    const [askNurse, setAskNurse] = useState<any>([])
    const [mobileOpen, setMobileOpen] = useState(false)
    const [notify, setNotify] = useState<any>([])

    let userID: any = localStorage.getItem("Nurture_user_data");
    userID = JSON.parse(userID);

    const Navigator = useNavigate()
    const dispatch = useDispatch();


    const isCheck = (res: any) => {
        isMebershipAndActiveCheck(res, Navigator, toast, dispatch)
    }


    const setSubStepName = (id: any = 0, name: any) => {
        const setSubSteps: any = {
            MyCourses: setSubStep(Number(id)),
            MyEvents: setSubStep1(Number(id)),
            MyServices: setSubStep2(Number(id)),
            Ask_a_Nurse: setSubStep2(Number(id)),
            gift: setSubStep3(Number(id))
        }
        return setSubSteps[name]
    }

    const param = new URLSearchParams(window.location.search)

    const eventLikeFav = (type: string, data: any, isUpdate: boolean, isSuccess: boolean) => {
        if (isSuccess) {
            dispatch(getUpComingEvent())
            dispatch(getFavEvent())
            return;
        }
        if (type === "fav") {
            if (subStep1 == 1) {
                EventFavSetSate(data, recUpcomeEvt, setRecUpcomeEvt)
            } else if (subStep1 == 2) {
                EventFavSetSate(data, recPastEvt, setRecPastEvt)
            } else {
                EventFavSetSate(data, recFavEvt, setRecFavEvt)
            }
            //   EventFavSetSate(crrdata, eventData, setEventData)
        } else if (type === "like") {
            if (subStep1 == 1) {
                EventLikeSetState(data, recUpcomeEvt, setRecUpcomeEvt, isUpdate)
            } else if (subStep1 == 2) {
                EventLikeSetState(data, recPastEvt, setRecPastEvt, isUpdate)
            } else {
                EventLikeSetState(data, recFavEvt, setRecFavEvt, isUpdate)
            }
        }

    }

    function getStepContent(stepIndex: any) {

        switch (stepIndex) {
            case 'MyCourses':
                return (<Step1 data={subStep == 1 ? startCourses : subStep == 2 ? completeCourses : subStep == 3 ? favCourses : subStep == 4 ? purchased : allCourse} subStep={subStep} />);
            case 'MyEvents':
                return (<Step2 data={subStep1 == 1 ? upComeEvt : subStep1 == 2 ? pastEvt : subStep1 == 3 ? favEvt : allCourse} getUpcomingEventAPI={getUpcomingEventAPI} subStep1={subStep1} />);
            case 'MyServices':
                return (<Step3 data={subStep2 == 1 ? upComeService : subStep2 == 2 ? pastService : subStep2 == 3 ? giftRecieve : upComeService}
                    setData={subStep2 == 1 ? setUpComeService : subStep2 == 2 ? setPastService : subStep2 == 3 ? setGiftRecieve : setUpComeService}
                    subStep2={subStep2} getUpcomingServiceAPI={getUpcomingServiceAPI} />);
            case 'Ask_a_Nurse':
                return (<Step4 allData={askNurse} step={stepIndex} data={messageDisp} />);
            case 'gift':
                return (<Step5 data={subStep3 == 1 ? giftRecieve : subStep3 == 2 ? giftSent : giftRecieve} subStep3={subStep3} />);
            default:
                return "";
        }
    }

    function getStepBottom(stepIndex: any) {
        switch (stepIndex) {
            case 'MyEvents':
                return (<StepBottom type="event" title='Events you might be interested in' data={subStep1 == 1 ? recUpcomeEvt : subStep1 == 2 ? recPastEvt : subStep1 == 3 ? recFavEvt : recUpcomeEvt}
                    setData={subStep1 == 1 ? setRecUpcomeEvt : subStep1 == 2 ? setRecPastEvt : subStep1 == 3 ? setRecFavEvt : setRecUpcomeEvt}
                    upDateData={eventLikeFav} />);
            case 'MyServices':
                return (<SuggestConsult data={recUpService} />);
            case 'Ask_a_Nurse':
                return (<StepBottom title='' datas={askNurse} />);
            case 'gift':
                return (<SuggestConsult data={recUpService} />)
            default:
                return "";
        }
    }

    const getProfileUser = async () => {
        try {
            let id: any = { user_id: userID?.id }
            let res: any = await getUserProfileEdit(id)

            setProfileData(res?.data)
            SetEarnCon(res?.data?.earn_consult)
        } catch (err) {
            console.log(err)
        }
    }

    const getCoursesStartedApi = async () => {
        try {
            let id: any = {
                user_id: userID?.id
            }
            let res: any = await CourseStartedApi(id)
            isCheck(res)
            setStartCourses(res?.data?.data)
            setRecStart(res?.data?.recommended_course_data)
        } catch (err) {
            console.log(err)
        }
    }

    const getCourseCompletedAPI = async () => {
        try {
            let id: any = {
                user_id: userID?.id
            }
            let res: any = await CourseCompletedAPI(id)
            isCheck(res)
            setCompletedCourses(res?.data?.data)
            setRecComplete(res?.data?.recommended_course_data)
        } catch (err) {
            console.log(err)
        }
    }

    const getCourseFavouriteAPI = async () => {
        try {
            let id: any = {
                user_id: userID?.id
            }
            let res: any = await CourseFavouriteAPI(id)
            isCheck(res)
            setFavCourses(res?.data?.data)
            setRecFav(res?.data?.recommended_course_data)
        } catch (err) {
            console.log(err)
        }
    }

    const getUpcomingEventAPI = async () => {
        try {
            let data: any = {
                user_id: userID?.id,
                type: "live"
            }
            let res: any = await UpcomingEventAPI(data)
            isCheck(res)
            setUpComeEvt(res?.data?.data)
            setRecUpcomeEvt(res?.data?.upcoming_event)
        } catch (err) {
            console.log(err)
        }
    }

    const getPastEventAPI = async () => {
        try {
            let data: any = {
                user_id: userID?.id,
                type: "past"
            }
            let res: any = await PastEventAPI(data)
            isCheck(res)
            setPastEvt(res?.data?.data)
            setRecPastEvt(res?.data?.upcoming_event)
        } catch (err) {
            console.log(err)
        }
    }

    const getFavouriteEventAPI = async () => {
        try {
            let id: any = {
                user_id: userID?.id
            }
            let res: any = await FavouriteEventAPI(id)
            isCheck(res)
            setFavEvt(res?.data?.data)
            setRecFavEvt(res?.data?.upcoming_event)
            console.log(res)
        } catch (err) {
            console.log(err)
        }
    }

    const getUpcomingServiceAPI = async () => {
        try {
            let id: any = {
                user_id: userID?.id
            }
            let res: any = await UpcomingServiceAPI(id)
            isCheck(res)
            setUpComeService(res?.data?.data)
            setRecUpService(res?.data?.recommended_course_data)
        } catch (err) {
            console.log(err)
        }
    }
    const getPastServiceAPI = async () => {
        try {
            let id: any = {
                user_id: userID?.id
            }
            let res: any = await PastServiceAPI(id);
            isCheck(res)
            setPastService(res?.data?.data)
            setRecPastService(res?.data?.recommended_course_data)
        } catch (err) {
            console.log(err)
        }
    }
    const getGiftCardRecieveAPI = async () => {
        try {
            let id: any = {
                user_id: userID?.id
            }
            let res: any = await GiftCardRecieveAPI(id)
            isCheck(res)
            setGiftRecieve(res?.data?.data)
            setRecGiftCard(res?.data?.recommended_course_data)
        } catch (err) {
            console.log(err)
        }
    }
    const sendGiftCardAPI = async () => {
        try {
            let id: any = {
                user_id: userID?.id
            }
            let res: any = await GiftCardSendAPI(id)
            isCheck(res)
            setGiftSent(res?.data?.data)
        } catch (err) {
            console.log(err)
        }
    }

    const getCoursePurchased = async () => {
        try {
            let id: any = {
                user_id: userID?.id
            }
            let res: any = await CoursePurchased(id)
            isCheck(res)
            setPurchased(res?.data?.data)
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        getCoursesStartedApi()
        getCourseCompletedAPI()
        getCourseFavouriteAPI()
        getUpcomingEventAPI()
        getPastEventAPI()
        getFavouriteEventAPI()
        getUpcomingServiceAPI()
        getPastServiceAPI()
        getGiftCardRecieveAPI()
        sendGiftCardAPI()
        getCoursePurchased()
        getProfileUser()
    }, [])

    useEffect(() => {
        if (userID?.is_stripe_membership == 0) {
            setSubStep(4)
            setActiveStep('MyCourses')
        } else {
            setSubStep(1)
            setActiveStep('MyCourses')
        }
    }, [])
    useEffect(() => {
        return () => {
            let stableState: any = { state: 'MyCourses' }
            localStorage.setItem('stabeleComponent', JSON.stringify(stableState))
            let id: any = { step: 1 }
            localStorage.setItem('stabeleStep', JSON.stringify(id))
        }
    }, [])
    useEffect(() => {
        window.scroll({
            top: 0,
            left: 0,
            //@ts-ignore
            behavior: "instant"
        })
    }, [activeStep])

    useEffect(() => {
        const step: any = param.get("stp");
        setActiveStep(step)
        const subStep = param.get("sub");
        setSubStepName(subStep, subStep)
    }, [window.location.search])

    return (
        <div className="profileContainer pale-bg">
            <Container fluid>
                <div className='profile-header-container mb-4'>
                    <Container fluid >
                        <Row className='profile-header px-md-5 px-0 py-md-5 py-4'>
                            <div className='col-lg-12 col-md-12'>
                                <Container className='paddingLR-0'>
                                    <div className='row'>
                                        <div className='col-lg-6 col-md-6'>
                                            <div className='d-flex profile-header-inner justify-container-center align-items-center'>
                                                <div className='d-flex align-items-center profile-info-section'>
                                                    <div className='user-profie-img px-0 px-md-4'>
                                                        <Stack direction="row" spacing={2}>
                                                            <Avatar
                                                                alt="Remy Sharp"
                                                                src={profileData?.about_you?.photo}
                                                                sx={{ width: 106, height: 106 }}
                                                            />
                                                        </Stack>
                                                        {/* <img src={profile_thumb} className="img-responsive" /> */}
                                                    </div>
                                                    <div className='user-info px-2 px-sm-2 px-md-4'>
                                                        <h3 className='user-name'>{profileData?.about_you?.name}</h3>
                                                        <button className='secondary-teal-btn px-3' onClick={() => { Navigator(`${EDITPROFILE}?stp=edit&sub=0`) }} ><EditOutlinedIcon /> Edit Profile</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-md-6 mt-3 mt-md-0'>
                                            {
                                                userID?.is_stripe_membership == 0 &&
                                                <div role="button" className='profile-becomemember-info w-md-75 w-100 me-0 ms-auto' onClick={() => Navigator(MEMBERSHIP_PRE_LOGIN)}>
                                                    <div className='d-flex w-100 justify-content-between align-items-center'>
                                                        <div className='member-title'><h4 className='p-0 m-0'>Become a member </h4></div>
                                                        <div className='member-price d-flex align-items-center'>from <span className='value ms-1'> ${profileData?.become_a_member}</span></div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </Container>
                            </div>
                        </Row>
                    </Container>
                </div>
                {/* end of profile */}
                <Row>
                    <div className='col-lg-12 col-md-12'>
                        <div className='paddingLR-0 ProfileUserWrapper'>
                            <Row>
                                <StepperMenu activeStep={activeStep} setActiveStep={setActiveStep}
                                    subStep={subStep} setSubStep={setSubStep} subStep1={subStep1}
                                    setSubStep1={setSubStep1} subStep2={subStep2} setSubStep2={setSubStep2}
                                    subStep3={subStep3} setSubStep3={setSubStep3} mobileOpen={mobileOpen} setMobileOpen={setMobileOpen} setNotify={setNotify} notify={notify} />
                                <div className='col-lg-10 col-md-9'>
                                    {getStepContent(activeStep)}
                                </div>
                            </Row>
                        </div>
                        {!["MyCourses", "Ask_a_Nurse"].includes(activeStep) && (
                            <div className={`${activeStep === "MyEvents" && "event-container pt-1"} container-fluid profile-section-bottom`}>
                                <div className='Row mt-5'>
                                    <div className={`${activeStep === "MyCourses" && 'seperator'} container paddingLR-0`}>
                                        {getStepBottom(activeStep)}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </Row>

            </Container>

        </div>
    )
    // return <CircularProgressWithLabel value={20} />;
}



export default ProfileUser
