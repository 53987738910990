import moment from 'moment';
import React from 'react';
import { startCase } from 'lodash';
import { Card, Image, Row } from 'react-bootstrap';
import "./EventCard.scss";
import parse from "html-react-parser";
import Like from "../../Assets/img/icons/like.svg";
import Liked from "../../Assets/img/icons/liked.svg";
import heart_outline from "../../Assets/img/course/heart-outline.svg";
import heart_fill from "../../Assets/img/course/heart-fill.svg";
import { EVENTS, EVENTSDETAIL, LOGIN } from '../../Routes/RouteConstent';
import { useNavigate } from 'react-router-dom';
import { FavEventHandle } from '../../Utils/Events/Fav';
import { EventLikeHandler } from '../../Utils/Events/Like';
import { ToEst } from '../../Utils/imageCrop/TimeConvert/convertToEst';
import { useSelector } from 'react-redux';
import { isLoggedInSelector } from '../../Redux/user/user';

interface Props {
    data: any,
    upDateData?: any,
    tab?: string,
    past?: boolean,
}
const eventType: any = {
    past: "tag5",
    Past: "tag5",
    live: "tag2",
    Live: "tag2"
}
const EventCard = (props: Props) => {
    const isUserLoggedIn = useSelector(isLoggedInSelector);

    let { data, upDateData, tab, past } = props;
    const Navigate = useNavigate();

    const EventFavHandle = async (res: any) => {
        upDateData("fav", res, true, false)
        let isFav = await FavEventHandle(res)
        upDateData("fav", res, isFav, isFav)
    }

    const EventLikeHandle = async (res: any) => {
        upDateData("like", res, true, false)
        let isLike = await EventLikeHandler(res)
        upDateData("like", res, isLike, isLike)
    }

    const handleJoin = (res: any) => {
        const id = res?.slug || res?.id;

        if (!isUserLoggedIn) {
            Navigate(LOGIN, {
                state: { relocate: { path: `${EVENTSDETAIL}${id}` } },
            });
        } else {
            Navigate(`${EVENTSDETAIL}${id}`, { state: { tab: tab && tab, page: tab ? EVENTS : "" } })
        }
    }

    return (
        <>
            <Row>
                {Array.isArray(data) &&
                    data.map((res: any) => (
                        <div
                            key={res.id}
                            className="col-lg-4 col-md-6 mb-4 event-card"
                            id={`event_card_${res.id}`}
                        >
                            <Card className="video-container-main">
                                <div className="video-container-inner">
                                    <Image fluid src={res?.image} className="image-fluid eventCardImage" />
                                    <div className="tags-likes d-flex justify-content-between">
                                        {/* <div className="tags-row">
                                            <span className={`${eventType[res?.type]} tags`}>
                                                {res?.type}
                                            </span>
                                            {res?.tag && !Array.isArray(res.tag)
                                                ? res.tag
                                                    .split(",")
                                                    .map((res: any) => (
                                                        <span className="tag1 tags">
                                                            {startCase(res || "")}
                                                        </span>
                                                    ))
                                                : Array.isArray(res) &&
                                                res?.map((res: any) => (
                                                    <span className="tag1 tags">
                                                        {startCase(res || "")}
                                                    </span>
                                                ))}
                                        </div> */}
                                        {isUserLoggedIn && res?.type !== "Past" && (
                                            <div className="fav-container">
                                                <img
                                                    onClick={() => EventFavHandle(res)}
                                                    src={res?.favourite ? heart_fill : heart_outline}
                                                    className="img-responsive"
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <Card.Body>
                                    <div className="d-flex align-items-start justify-content-between mb-2">
                                        <Card.Title>{res?.title}</Card.Title>
                                        <div className="like">
                                            <img
                                                src={res?.like ? Liked : Like}
                                                onClick={() => isUserLoggedIn && EventLikeHandle(res)}
                                            />{" "}
                                            {res?.like_count}
                                        </div>
                                    </div>
                                    <Card.Text className="eventCard">
                                        {parse(String(res?.description))}
                                    </Card.Text>
                                    <div className="event-time pt-2">
                                        {moment(ToEst(res?.event_date_time)).format("LLLL")} EST
                                        {/* {moment(res?.event_date_time).format("LLLL")} */}
                                    </div>
                                </Card.Body>
                                <Card.Body>
                                    <div className="live-class-card-footer d-flex justify-content-between">
                                        <button
                                            className="btn primary-blue-small-btn-40"
                                            onClick={() => handleJoin(res)}
                                        >
                                            {past && "Watch Now"}
                                            {!past && res?.is_book && "Registered"}
                                            {!past && !res?.is_book && "Join"}
                                        </button>
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>
                    ))}
            </Row>
        </>
    );
}

export default EventCard