import { isNumber } from "lodash";
import React from "react";

interface SaS {
  amount: number;
  orderId: number | null;
  couponCode?: string | number;
}

const ShareASaleScript = React.memo(({ amount, orderId, couponCode }: SaS) => {
  const ref = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    const doesPixelWasRendered = ref.current?.getElementsByTagName("img");

    if (
      isNumber(orderId) &&
      isNumber(amount) &&
      !doesPixelWasRendered?.length &&
      window.location.origin.includes("www.nurturebynaps.com")
    ) {
      const pixel: HTMLImageElement = document.createElement("img");

      let params = `tracking=${orderId}&amount=${amount.toFixed(
        2
      )}&merchantID=136995&transtype=sale`;

      if (couponCode) {
        params = `${params}couponcode=${couponCode}`;
      }

      pixel.setAttribute('src', `https://www.shareasale.com/sale.cfm?${params}` );
      pixel.setAttribute('width', '1px');
      pixel.setAttribute('height', '1px');

      const script: HTMLScriptElement = document.createElement("script");
      script.setAttribute('src', "https://www.dwin1.com/44235.js");
      script.setAttribute('type', "text/javascript");
      script.setAttribute('defer', 'defer');

      ref.current?.appendChild(pixel);
      ref.current?.appendChild(script);
    }
  }, [orderId, amount, couponCode]);

  return <div ref={ref} />;
});

export default ShareASaleScript;
