import React from "react";
import "./unlimitedSupport.scss";

const UnlimitedSupport = ({
  openMembershipPage,
}: {
  openMembershipPage: () => void;
}) => {
  return (
    <div className="preLoginUnlimitedSupportWrapper" id="buyMembership">
      <div className="preLoginUnlimitedSupport">
        <h2 className="heading2">
          Grab a year of <span>unlimited support</span>
        </h2>
        <div className="priceBox">
          <div className="priceBoxPrices">
            <div className="title">Save $1500</div>
            <div className="priceAmount">
              <s>$2500</s> <span>$999*</span>
            </div>
          </div>
          <button
            className="w-auto btn-small btn primary-blue-small-btn-40"
            onClick={openMembershipPage}
          >
            Join now
          </button>
        </div>
      </div>
    </div>
  );
};

export default UnlimitedSupport;
