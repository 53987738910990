import React from "react";
import "./OnboardingVideo.scss";
import { Modal } from "react-bootstrap";
import { getUser } from "../../../../Service/getLocalStorage";
import Video from "./Video";

const OnboardingVideo = () => {
  const userData = getUser();

  const [isVisible, setIsVisible] = React.useState(
    !userData?.welcome_screen_status && userData?.is_stripe_membership
  );

  const closeModal = () => {
    setIsVisible(false);
  };

  return (
    <>
      <Modal show={isVisible} size={"lg"} centered>
        <Video closeModal={closeModal} />
      </Modal>
    </>
  );
};

export default OnboardingVideo;
