// leadid

import React from "react";
import { useSearchParams } from "react-router-dom";
import httpService from "../../Service/httpService";

const useBHApi = () => {
  const [searchParams] = useSearchParams();

  React.useEffect(() => {
    const leadId = searchParams.get("leadid");

    const savedLeadId = localStorage.getItem("savedLeadId");

    if (!savedLeadId && leadId) {
      httpService
        .post("brighthorizons/first", { id: leadId })
        .then((res: any) => {
          if (res.data.success) {
            localStorage.setItem("savedLeadId", leadId);
          }
        });
    }
  }, [searchParams]);

  return null;
};

export const getSavedLeadId = () => localStorage.getItem("savedLeadId");
export const removeSavedLeadId = () => localStorage.removeItem("savedLeadId");

export default useBHApi;
