import React from "react";
import { useNavigate } from "react-router-dom";
import { MEMBERSHIP_PRE_LOGIN } from "../../Routes/RouteConstent";
import { getDataFromLocalStorage } from "../../Service/getLocalStorage";
import "./BecomeAMemberNow.scss";
import nuture_by_naps from "../../Assets/img/nuture-naps-logo.svg";

const BecomeAMemberNow = ({ joinNowPath }: { joinNowPath?: string }) => {
  const userData = getDataFromLocalStorage();
  const Navigate = useNavigate();

  return (
    <>
      {(!userData || (userData && !userData?.is_stripe_membership)) && (
        <>
          <div className="join-now">
            <img src={nuture_by_naps} alt="nuture_by_naps" width="128" />
            <h3 className="join-now-title">
              Become a Member
              <span className="strong">for total access</span>
            </h3>
            <p className="join-now-p">
              This is your all-access pass to our courses, videos, live
              webinars, Ask A Nurse, and more. Plus 1:1 consultation discounts
              and an onboarding call with a NAPS nurse to learn how we can best
              support you.
            </p>
            <div className="JoinNowBtnWrapper">
              <button
                className="secondary-teal-btn-small mt-4"
                onClick={() =>
                  Navigate(joinNowPath ? joinNowPath : MEMBERSHIP_PRE_LOGIN)
                }
              >
                Join Now
              </button>
            </div>
          </div>
          <div className="join-now-mobile">
            <h3 className="join-now-title">
              Become a Member
              <span className="strong">for total access</span>
            </h3>
            <p className="join-now-p">
              This is your all-access pass to our courses, videos, live
              webinars, Ask A Nurse, and more. Plus 1:1 consultation discounts
              and an onboarding call with a NAPS nurse to learn how we can best
              support you.
            </p>
            <div className="JoinNowBtnWrapper">
              <button
                className="secondary-teal-btn-small mt-4"
                onClick={() =>
                  Navigate(joinNowPath ? joinNowPath : MEMBERSHIP_PRE_LOGIN)
                }
              >
                Join Now
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default BecomeAMemberNow;
