import React, { useState } from 'react'
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';
import { privateBookingApi } from '../../Service/Cousult.service';
import { useNavigate } from 'react-router-dom';
import { emailRegex } from '../../Utils/Regex';
import { CircularProgress } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import CongratsModel2 from '../../Common/CongratsPopup2/CongratsPopup2';
import { GIFTCARD } from '../../Routes/RouteConstent';
import isMebershipAndActiveCheck from '../../Utils/membership_check';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';

function PrivateClassForm2() {

    const [error, setError] = React.useState<any>({})
    const [congrats, setCongrats] = useState(false)
    const [loading, setLoading] = useState(false)

    const Navigate = useNavigate()
    const dispatch = useDispatch()

    let user: any = localStorage.getItem("Nurture_user_data")
    user = JSON.parse(user)

    let iValue: any = {
        first_name: user?.first_name,
        last_name: user?.last_name,
        email: user?.email,
        phone: user?.phone,
    }
    const [values, setValues] = React.useState<any>(iValue)

    const consult_type = [
        { label: 'Initial lactation consultation', value: 1 },
        { label: 'Follow Up Lactation Consultation', value: 2 },
        { label: 'Bottle Consultation', value: 3 },
        { label: 'Prenatal Consultation', value: 4 },
        { label: 'Virtual Consultation', value: 5 },

    ]
    const handlerRadioBtn = (e: any) => {

        if (e.target.name === "would_you_like_to_book_an_in_home_or_virtual_consult") {
            setValues({
                ...values,
                [e.target.name]: e.target.value
            })

        }
        if (e.target.name === "prenatal_classes_being_requested") {
            setValues({
                ...values,
                [e.target.name]: e.target.value
            })
        }
        if (e.target.name === "do_you_have_any_pets_in_the_home") {
            setValues({
                ...values,
                [e.target.name]: e.target.value
            })
            setError({
                ...error,
                [e.target.name]: ""
            })
        }
        if (e.target.name === "are_you_a_current_member_of_nurture_by_naps") {
            setValues({
                ...values,
                [e.target.name]: e.target.value
            })
            setError({
                ...error,
                [e.target.name]: ""
            })
        }
    }
    const handleChangeInfo = (e: any) => {
        let name = e.target.name;
        let value = e.target.value;

        if (name === "first_name") {
            if (!value) {
                setError({
                    ...error,
                    [name]: "Field is required"
                })
            } else {
                setError({
                    ...error,
                    [name]: ""
                })
            }
        }
        setValues({ ...values, [name]: value });
        if (name === "last_name") {
            if (!value) {
                setError({
                    ...error,
                    [name]: "Field is required"
                })
            } else {

                setError({
                    ...error,
                    [name]: ""
                })
            }
        }
        setValues({ ...values, [name]: value });

        if (name === "email") {
            if (!emailRegex.test(value)) {
                setError({
                    ...error,
                    [name]: "Email in not valid"
                })
            } else {
                setError({
                    ...error,
                    [name]: ""
                })
            }
        }
        setValues({ ...values, [name]: value });
        if (name === "phone") {
            if (value.length < 10 || value.length > 10) {
                setError({
                    ...error,
                    [name]: "Contact number should be of 10 digits"
                })
            } else {
                setError({
                    ...error,
                    [name]: ""
                })
            }
        }
        setValues({ ...values, [name]: value });
        if (name === "total_anticipated_participants") {
            setError({
                ...error,
                [name]: ""
            })

        }
        if (name === "street_address") {
            setError({
                ...error,
                [name]: ""
            })

        }
        setValues({
            ...values,
            [e.target.name]: e.target.value
        })
        if (name === "city") {
            setError({
                ...error,
                [name]: ""
            })

        }
        setValues({
            ...values,
            [e.target.name]: e.target.value
        })
        if (name === "state") {
            setError({
                ...error,
                [name]: ""
            })

        }
        setValues({
            ...values,
            [e.target.name]: e.target.value
        })
        if (name === "zip_code") {
            setError({
                ...error,
                [name]: ""
            })

        }
        setValues({
            ...values,
            [e.target.name]: e.target.value
        })
        if (name === "reason_for_visit") {
            setError({
                ...error,
                [name]: ""
            })

        }
        if (name === "insurance_carrier") {
            setError({
                ...error,
                [name]: ""
            })

        }
        setValues({
            ...values,
            [e.target.name]: e.target.value
        })

    }
    const dateHandler = (val: any, name: string) => {
        setValues({
            ...values,
            [name]: val
        })
        setError({
            ...error,
            [name]: ""
        })
    }
    const handleSelectConsult = (e: any, val: any) => {
        setValues({
            ...values,
            ["type_of_consultation"]: val?.label
        })
        setError({
            ...error,
            ["type_of_consultation"]: ""
        })
    }

    const handleLactationSubmit = async (e: any) => {

        e.preventDefault()
        try {
            if (
                !values?.type_of_consultation ||
                !values?.street_address || !values?.city || !values?.state || !values?.zip_code || !values?.due_date || !values?.reason_for_visit || !values?.insurance_carrier
                || !values?.do_you_have_any_pets_in_the_home || !values?.are_you_a_current_member_of_nurture_by_naps) {

                let errors: any = {}
                if (!values?.first_name) {
                    errors.first_name = "First name is Required"
                }
                if (!values?.insurance_carrier) {
                    errors.insurance_carrier = "Please Enter the Value"
                }
                if (!values?.reason_for_visit) {
                    errors.reason_for_visit = "Please Enter the Value"
                }
                if (!values?.due_date) {
                    errors.due_date = "Please select date"
                }

                if (!values?.last_name) {
                    errors.last_name = "Last name is Required"
                }

                if (!values?.email) {
                    errors.email = "Email is required"
                }

                if (!values?.phone) {
                    errors.phone = "Phone no. is Required"
                }

                if (values?.phone.length < 10 || values?.phone.length > 10) {
                    errors.phone = "Contact number should be of 10 digits"
                }
                if (!values?.type_of_consultation) {
                    errors.type_of_consultation = "Please Select the Value"
                }
                if (!values?.street_address) {
                    errors.street_address = "Please Enter the Value"
                }
                if (!values?.city) {
                    errors.city = "Please Enter the Value"
                }

                if (!values?.zip_code) {
                    errors.zip_code = "Please Enter the Value"
                }
                if (!values?.state) {
                    errors.state = "Please Enter the Value"
                }

                if (!values?.do_you_have_any_pets_in_the_home) {
                    errors.do_you_have_any_pets_in_the_home = "Please Select the Value"
                }
                if (!values?.are_you_a_current_member_of_nurture_by_naps) {
                    errors.are_you_a_current_member_of_nurture_by_naps = "Please Select the Value"
                }
                setError(errors)

            } else {
                setLoading(true)
                let param = {
                    type: "In-Person Lactation Consult Request Form",
                    user_id: user?.id,
                    first_name: values?.first_name,
                    last_name: values?.last_name,
                    email: values?.email,
                    phone: values?.phone,
                    type_of_consultation: values?.type_of_consultation,
                    street_address: values?.street_address,
                    city: values?.city,
                    state: values?.state,
                    zip_code: values?.zip_code,
                    due_date: values?.due_date?.$d,
                    reason_for_visit: values?.reason_for_visit,
                    insurance_carrier: values?.insurance_carrier,
                    do_you_have_any_pets_in_the_home: values?.do_you_have_any_pets_in_the_home,
                    are_you_a_current_member_of_nurture_by_naps: values?.are_you_a_current_member_of_nurture_by_naps,
                }

                let res: any = await privateBookingApi(param)
                isMebershipAndActiveCheck(res, Navigate, toast, dispatch)
                if (res?.data?.success) {
                    setValues({
                        first_name: user?.first_name,
                        last_name: user?.last_name,
                        email: user?.email,
                        phone: user?.phone,
                        type_of_consultation: "",
                        street_address: "",
                        city: "",
                        state: "",
                        zip_code: "",
                        due_date: "",
                        reason_for_visit: "",
                        insurance_carrier: "",
                        do_you_have_any_pets_in_the_home: "",
                        are_you_a_current_member_of_nurture_by_naps: "",
                    })
                    setCongrats(true)
                    setLoading(false)
                } else {
                    setLoading(false)
                } 


            }
        } catch (err) {
            console.log(err)
            setLoading(false)
        }
    }

    return (
        <div>
            <CongratsModel2
                open={congrats} 
                setOpen={setCongrats}
                title="Thank you for your request!"
                btnText="Send gift card to your friend"
                Page={GIFTCARD}
                text={'We have received your form submission and someone from our team will be in touch ASAP. In the meantime, feel free to check out our other offerings. Or if you have a friend you think would be interested in the same booking as you, tell them using the button below!'} />

            <section className='private-consult step-container'>
                <div className='consult-section-heading text-center'>
                    <h5>Lactation Consult </h5>
                    <h3 className='mb-3 mt-2'>In-Person Lactation Consult Request Form</h3>
                    <p className="text-center">
                        <span className="strong">*We accept many insurance options</span>,
                        including: Anthem PPO, BCBS PPO, UnitedHealthcare PPO and HMO, VA
                        Optum Easat, Premera, Regence, Oxford, or a plan that includes the
                        Multiplan or PNOA on your insurance card.{" "}
                        <span className="strong">
                        Click{" "}
                        <a href="https://registration.mytln.care/?practiceId=001RP00000KP1OnYAL">
                            here
                        </a>{" "}
                        to check your eligibility
                        </span>{" "}
                        for the insurance covered care we offer through The Lactation
                        Network.
                    </p>
                    <p className='text-center pb-3'>If you are based in Massachusetts and would like to schedule an in-person lactation consultation, please complete our lactation consult request form below and a naps staff member will be in touch with you shortly to book your appointment. we will respond to your request within 24 hours Monday - Friday and within 48 hours on the weekend.</p>
                </div>
                <form className=' '>
                    <div className='row mb-3'>
                        <div className="form-group col-lg-6 col-md-6 mb-3">
                            <label>First Name <span className='mandatory text-danger mx-2'>*</span></label>
                            <input type="text" name='first_name' value={values.first_name} className='form-control' onChange={handleChangeInfo} placeholder='Enter first name' />
                            <h4 className="error">{error.first_name}</h4>

                        </div>
                        <div className="form-group col-lg-6 col-md-6 mb-3">
                            <label>Last Name<span className='mandatory text-danger mx-2'>*</span></label>
                            <input type="text" name='last_name' value={values?.last_name} className='form-control' onChange={handleChangeInfo} placeholder='Enter last name' />
                            <h4 className="error">{error.last_name}</h4>

                        </div>
                    </div>
                    <div className='row mb-3'>
                        <div className="form-group col-lg-6 col-md-6 mb-3">
                            <label>Email <span className='mandatory text-danger mx-2'>*</span></label>
                            <input type="text" name='email' value={values?.email} className='form-control' onChange={handleChangeInfo} placeholder='Enter email address' />
                            <h4 className="error">{error?.email}</h4>
                        </div>
                        <div className="form-group col-lg-6 col-md-6 mb-3">
                            <label>Phone Number <span className='mandatory text-danger mx-2'>*</span></label>
                            <input type="text" name='phone' value={values?.phone} className='form-control' onChange={handleChangeInfo} placeholder='Enter phone number' />
                            <h4 className="error">{error?.phone}</h4>
                        </div>
                    </div>
                    <div className='row mb-3'>
                        <div className="form-group col-lg-12 col-md-12 mb-3">
                            <label >Type of Consultation: <span className='mandatory text-danger mx-2'>*</span> </label>
                            <Autocomplete
                                disablePortal
                                disableClearable
                                id="consult-type"
                                options={consult_type}
                                value={values?.type_of_consultation || ""}
                                className={'mu-custom-fields'}
                                onChange={handleSelectConsult}
                                renderInput={(params) => <TextField {...params} name='type_of_consultation' placeholder='Select Consultation' />}
                            />

                            <h4 className="error">{error.type_of_consultation}</h4>
                        </div>
                    </div>
                    <div className='row mb-3'>
                        <div className="form-group col-lg-12 col-md-12 mb-3">
                            <label>Street address <span className='mandatory text-danger mx-2'>*</span></label>
                            <input type="text" name='street_address' value={values?.street_address} className='form-control' onChange={handleChangeInfo} />
                            <h4 className="error">{error.street_address}</h4>
                        </div>
                    </div>
                    <div className='row mb-3'>
                        <div className="form-group col-lg-4 col-md-4 mb-3">
                            <label>City <span className='mandatory text-danger mx-2'>*</span></label>
                            <input type="text" name='city' className='form-control' value={values?.city} placeholder='' onChange={handleChangeInfo} />
                            <h4 className="error">{error.city}</h4>
                        </div>
                        <div className="form-group col-lg-4 col-md-4 mb-3">
                            <label>State <span className='mandatory text-danger mx-2'>*</span></label>
                            <input type="text" name='state' className='form-control' placeholder='' value={values?.state} onChange={handleChangeInfo} />
                            <h4 className="error">{error.state}</h4>
                        </div>
                        <div className="form-group col-lg-4 col-md-4 mb-3">
                            <label>Zip Code <span className='mandatory text-danger mx-2'>*</span></label>
                            <input type="text" name='zip_code' className='form-control' placeholder='' value={values?.zip_code} onChange={handleChangeInfo} />
                            <h4 className="error">{error.zip_code}</h4>
                        </div>
                    </div>

                    <div className='row mb-3'>
                        <div className="form-group col-lg-12 col-md-12 mb-3 due_dt_container">
                            <label >Due Date/Child's Birthday <span className='mandatory text-danger mx-2'>*</span> </label>
                            <LocalizationProvider dateAdapter={AdapterDayjs} >

                                <Stack spacing={3}>
                                    <MobileDatePicker
                                        value={values?.due_date || ""}
                                        onChange={(val: any) => dateHandler(val, "due_date")}
                                        renderInput={(params: any) => <TextField {...params} placeholder="MM-DD-YYYY" className='due-date-field' />}
                                    />
                                </Stack>
                            </LocalizationProvider>
                            <h4 className="error">{error.due_date}</h4>
                        </div>
                    </div>

                    <div className='row mb-3'>
                        <div className="form-group col-lg-12 col-md-12 mb-3">
                            <label>Reason for visit<span className='mandatory text-danger mx-2'>*</span></label>
                            <input type="text" name='reason_for_visit' value={values?.reason_for_visit} className='form-control'
                                placeholder='' onChange={handleChangeInfo} />
                            <h4 className="error">{error.reason_for_visit}</h4>
                        </div>
                    </div>

                    <div className='row mb-3'>
                        <div className="form-group col-lg-12 col-md-12 mb-3">
                            <label>Insurance Carrier<span className='mandatory text-danger mx-2'>*</span></label>
                            <input type="text" name='insurance_carrier' value={values?.insurance_carrier} className='form-control'
                                placeholder='' onChange={handleChangeInfo} />
                            <h4 className="error">{error.insurance_carrier}</h4>
                        </div>
                    </div>


                    <div className="radio-area mb-3">
                        <h4>Do you have any pets in the home? <span className='mandatory text-danger mx-2'>* </span></h4>
                        <div className="custom-control custom-radio">
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="do_you_have_any_pets_in_the_home" value="No" onChange={handlerRadioBtn}
                                    checked={values?.do_you_have_any_pets_in_the_home === "No"} id="petsNo" />
                                <label className="form-check-label" htmlFor='petsNo' >
                                    No
                                </label>
                            </div>
                        </div>

                        <div className="custom-control custom-radio">
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="do_you_have_any_pets_in_the_home"
                                    checked={values?.do_you_have_any_pets_in_the_home === "Yes - dog"} value="Yes - dog" onChange={handlerRadioBtn} id="pet-yes" />
                                <label className="form-check-label" htmlFor='pet-yes' >
                                    Yes - dog
                                </label>
                            </div>
                        </div>

                        <div className="custom-control custom-radio">
                            <div className="form-check">
                                <input className="form-check-input" type="radio"
                                    checked={values?.do_you_have_any_pets_in_the_home === "Yes - cat"}
                                    name="do_you_have_any_pets_in_the_home" value="Yes - cat" id="petcat" onChange={handlerRadioBtn} />
                                <label className="form-check-label" htmlFor='petcat' >
                                    Yes - cat
                                </label>
                            </div>

                        </div>
                        <div className="custom-control custom-radio">
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="do_you_have_any_pets_in_the_home"
                                    checked={values?.do_you_have_any_pets_in_the_home === "Yes - both (cat and dog)"}
                                    value="Yes - both (cat and dog)" id="petboth" onChange={handlerRadioBtn} />
                                <label className="form-check-label" htmlFor='petboth' >
                                    Yes - both
                                </label>
                            </div>

                        </div>
                        <p className="error">{error.do_you_have_any_pets_in_the_home}</p>
                    </div>

                    <div className="radio-area mb-3">
                        <h4>Are you a current member of Nurture by NAPS? <span className='mandatory text-danger mx-2'>* </span></h4>
                        {(!!user?.is_stripe_membership || !user) && <div className="custom-control custom-radio">
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="are_you_a_current_member_of_nurture_by_naps"
                                    checked={values?.are_you_a_current_member_of_nurture_by_naps === "Yes, give me my member discount!"} value="Yes, give me my member discount!" onChange={handlerRadioBtn} id="discountYes" />
                                <label className="form-check-label" htmlFor='discountYes'>
                                    Yes, give me my member discount!
                                </label>
                            </div>
                        </div>}

                        <div className="custom-control custom-radio">
                            <div className="form-check">
                                <input className="form-check-input" type="radio"
                                    checked={values?.are_you_a_current_member_of_nurture_by_naps === "No, but I would like more information"}
                                    name="are_you_a_current_member_of_nurture_by_naps" value="No, but I would like more information" onChange={handlerRadioBtn} id="discountNo" />
                                <label className="form-check-label" htmlFor='discountNo' >
                                    No, but I would like more information
                                </label>
                            </div>
                        </div>
                        <p className="error">{error.are_you_a_current_member_of_nurture_by_naps}</p>
                    </div>



                    <div className='section-navigation my-5 d-flex justify-content-between'>
                        <button className='primary-blue-small-btn next w-100' disabled={loading} onClick={handleLactationSubmit}>{loading ? <CircularProgress /> : 'Submit'}</button>
                    </div>
                </form>
            </section>
        </div>
    )
}

export default PrivateClassForm2
