import React from 'react'
import { useNavigate } from 'react-router-dom';
import { type } from 'os';
import { COURSE, EVENTS } from '../../../Routes/RouteConstent';
import FavouriteHandler, { favCoursesetState } from '../../../Utils/course/fav';
import courseLikeHandler, { likeCourseSetState } from '../../../Utils/course/like';
import { EventFavSetSate, FavEventHandle } from '../../../Utils/Events/Fav';
import { EventLikeHandler, EventLikeSetState } from '../../../Utils/Events/Like';
import EventCard from '../../../Common/EventCard/EventCard';
import CourseCard from '../../../Common/CourseCard/CourseCard';
import { getCourse, getFavCourse } from '../../../Redux/Course/CourseReducer';
import { useDispatch } from 'react-redux';
import { getUserID } from '../../../Service/getLocalStorage';
import { getFavEvent, getUpComingEvent } from '../../../Redux/Events/EventsReducer';

const StepBottom = ({ title, data, datas, upDateData, type, setData }: any) => {

  let Navigate = useNavigate()

  const dispatch = useDispatch()
  let userID = getUserID()

  const courseLikeFav = (types: any, crrData: any, isUpdate: boolean, isSuccess: boolean) => {
    if (isSuccess) {
      dispatch(getCourse({ user_id: userID }))
      dispatch(getFavCourse({ user_id: userID }))
      return;
    }
    if (types === "fav") {
      favCoursesetState(crrData, data, setData)
    } else if (types === "like") {
      likeCourseSetState(crrData, data, setData)
    }
  }
  const eventLikeFav = (types: string, crrdata: any, isUpdate: boolean, isSuccess: boolean) => {
    if (isSuccess) {
      dispatch(getUpComingEvent())
      dispatch(getFavEvent())
      return;
    }
    if (types === "fav") {
      EventFavSetSate(crrdata, data, setData)
    } else if (types === "like") EventLikeSetState(crrdata, data, setData, isUpdate)
  }
  const navigateScreen = () => {
    if (type === "course") {
      Navigate(COURSE)
    }
    if (type === "event") {
      Navigate(EVENTS)

    }
  }

  return (
    <>
      <div className='row'>
        <div className='col-lg-12 col-md-12'>
          <h2>{title}</h2>
        </div>

        <div className='col-lg-12 col-md-12'>
          <div className='row'>
            {
              type === "course" && <CourseCard data={data} upDateData={courseLikeFav} />
            }
            {
              type === "event" && <EventCard data={data} upDateData={eventLikeFav} />
            }
          </div>
        </div>

        {data && <div className='col-lg-12 col-md-12 text-center'>
          <button className="btn primary-blue-small-btn-40 my-5 mx-auto" onClick={navigateScreen}>View All</button>
        </div>}
      </div>
    </>
  )
}

export default StepBottom
