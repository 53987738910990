import React from 'react';
import { useNavigate } from 'react-router-dom';
import { MEMBERSHIP_PRE_LOGIN } from '../../Routes/RouteConstent';
import { getDataFromLocalStorage } from '../../Service/getLocalStorage';
import "./LockScreen.scss";

const LockScreen = () => {
    const userData = getDataFromLocalStorage()
    const Navigate = useNavigate();

    return (
        <>
            {(!userData || userData && !userData?.is_stripe_membership) && <div className='col-lg-12 col-md-12 become-main'>
                <div className='become-member my-4'>
                    <div className='container'>
                        <div className='col-lg-12 col-md-12'>
                            <div className='become-member-container p-0 p-md-5'>
                                <div className='row'>
                                    <div className='col-lg-6 col-md-6 col-sm-12 ms-0 ms-md-5 me-0 me-md-5 order-1 order-md-0'>
                                        <div className='become-meber-captions'>
                                            <h3>Become a Member
                                                <span className="strong">for total access</span>
                                            </h3>
                                            {/* <div className='price-dtls'>
                                                <p>from <span className='pvalue strong'>$83.00</span> /mo</p>
                                            </div> */}
                                            <div>
                                                <ol>
                                                    <li><span className='strong'>Get access to everything:</span> courses, videos, live webinars, and more.</li>
                                                    <li>Responses from <span className='strong'> real nurses within 24 hours.</span></li>
                                                    <li>Unfiltered answers <span className='strong'> any time, anywhere.</span></li>
                                                </ol>
                                            </div>
                                            <div>
                                                <button className='secondary-teal-btn-small mt-4' onClick={() => Navigate(MEMBERSHIP_PRE_LOGIN)}>Read More</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-sm-12 mob-membership-img-container order-0 order-md-1'>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
        </>
    )
}

export default LockScreen