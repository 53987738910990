import React from "react";
import "./BUMPHero.scss";
import { Link } from "react-router-dom";

const BUMPHero = ({ learnMoreHandle }: { learnMoreHandle?: () => void }) => {
  return (
    <div className="BUMPHeroWrapper">
      <div className="BUMPHeroContent">
        <div className="BUMPHeroContent__logo" />
        <p className="BUMPHeroContent__text">
          NAPS™ provides trustworthy, uncensored support and coaching from
          honest, judgment-free Registered Nurses who are parents themselves—so
          parents can feel confident and capable as they raise the next
          generation of confident and capable kids!
          <br />
          <br />
          <strong className="BUMPHeroContent__strong-text">
            As a member of the BUMP community, we’re offering you a free month
            to Nurture by NAPS, our online membership community connecting you
            to real nurses at your fingertips! Use the code below at checkout.
          </strong>
        </p>
        {learnMoreHandle ? (
          <button
            className="btn primary-blue-small-btn-40 BUMPHeroContent__btn"
            onClick={learnMoreHandle}
          >
            Learn More
          </button>
        ) : (
          <Link
            className="btn primary-blue-small-btn-40 BUMPHeroContent__btn"
            to="/"
          >
            Learn More
          </Link>
        )}

        <div className="BUMPHeroCode">
          <h2>First month free</h2>
          <p>
            using code <strong>BUMPFREE</strong>
          </p>
        </div>
      </div>
    </div>
  );
};

export default BUMPHero;
