import React from "react";

interface Props {
  options: string[];
  name: string;
  onChange: any;
  value: string[];
  mainLabel: string;
  className?: string;
}

const CheckboxList = ({
  className,
  mainLabel,
  options,
  name,
  onChange,
  value,
}: Props) => {
  return (
    <div className={className}>
      <label className="">{mainLabel}</label>
      {options.map((opt) => (
        <div key={opt} style={{ display: "flex" }}>
          <input
            type="checkbox"
            name={name}
            className="form-check-input"
            value={opt}
            checked={value.includes(opt)}
            onChange={onChange}
            id={opt}
          />
          <label htmlFor={opt}>{opt}</label>
        </div>
      ))}
    </div>
  );
};

export default CheckboxList;
