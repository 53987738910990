import React from "react";
import { Row } from "react-bootstrap";
import "./legal.scss";

export const ConsentForDisclosure = () => {
  return (
    <div className="container-fluid ">
      <Row>
        <div className="Col-lg-12 Col-sm-12">
          <div className="container">
            <div className="Row">
              <div className="col-lg-12 col-sm-12 py-5">
                <h1 className="text-start">Consent for Disclosure</h1>
                <h2 className="text-start">
                  Client/Patient Consent For Use And Disclosure Of Protected
                  Health Information
                </h2>
                <h4 className="text-start">Client/Patient Consent for Use *</h4>
                <p>
                  I hereby give my consent for Boston NAPS, LLC to use and
                  disclose protected health information (PHI) about me to carry
                  out treatment, payment and health care operations (TPO). (The
                  Notice of Privacy Practices provided by Boston NAPS describes
                  such uses and disclosures more completely.) I have the right
                  to review the Notice of Privacy Practices prior to signing
                  this consent. Boston NAPS, LLC reserves the right to revise
                  its Notice of Privacy Practices at any time. A revised Notice
                  of Privacy Practices may be obtained by forwarding a written
                  request to Boston NAPS, LLC, 724 East 2nd Street, Unit 1,
                  Boston, MA 02127, or by emailing bostonnaps@gmail.com. With
                  this consent, Boston NAPS, LLC may call my home or other
                  alternative location and leave a message on voice mail or in
                  person in reference to any items that assist the practice in
                  carrying out TPO, such as appointment reminders, insurance
                  items and any calls pertaining to my clinical care, including
                  laboratory test results, among others. With this consent,
                  Boston NAPS, LLC may mail to my home or other alternative
                  location any items that assist the practice in carrying out
                  TPO, such as appointment reminder cards and patient statements
                  as long as they are marked “Personal and Confidential.” With
                  this consent, Boston NAPS, LLC may e-mail to my home or other
                  alternative location any items that assist the practice in
                  carrying out TPO, such as appointment reminder cards and
                  patient statements. I have the right to request that Boston
                  NAPS, LLC restrict how it uses or discloses my PHI to carry
                  out TPO. The practice is not required to agree to my requested
                  restrictions, but if it does, it is bound by this agreement.
                  By signing this form, I am consenting to allow Boston NAPS,
                  LLC to use and disclose my PHI to carry out TPO. I may revoke
                  my consent in writing except to the extent that the practice
                  has already made disclosures in reliance upon my prior
                  consent. If I do not sign this consent, or later revoke it,
                  Boston NAPS, LLC may decline to provide treatment to me.
                </p>
              </div>
            </div>
          </div>
        </div>
      </Row>
    </div>
  );
};
