import React from "react";
import "./textContent.scss";

interface TextContentProps {
  title: JSX.Element | string | JSX.Element[];
  description: JSX.Element | string | JSX.Element[];
  buttonText: String;
  buttonType?: "yellow" | "green";
  className?: String;
  onClick: () => void;
}
const TextContent = ({
  className,
  title,
  description,
  buttonText,
  buttonType = "yellow",
  onClick,
}: TextContentProps) => {
  return (
    <div className={`textContentContent ${className}`}>
      <div className="textContentHead">
        <h2>{title}</h2>
      </div>
      <p className="textContentDescription">{description}</p>
      <button
        className={`btn primary-blue-small-btn-40 textContentBtnLink ${
          buttonType === "yellow" && "textContentBtnLink--yellow"
        }
        ${buttonType === "green" && "textContentBtnLink--green"}`}
        onClick={onClick}
      >
        {buttonText}
      </button>
    </div>
  );
};

export default TextContent;
