import React, { useEffect, useState } from 'react'
import { Container, Row } from 'react-bootstrap';
import sponsorship_banner from '../../Assets/img/sponsors/Sponsor-banner.jpg';
import "./sponsorships.scss";
import { CircularProgress } from '@mui/material';
import { sponsorshipContactApi } from '../../Service/contact';
import { getUserID } from '../../Service/getLocalStorage';
import { toast } from 'react-toastify'
import CongratsModel from '../../Common/CongratsPopoup/congratsModel';
import { emailRegex, phoneRegex } from '../../Utils/constant';
import { GetPrefilledData } from '../../Utils/getUserData/getUserData';
import isMebershipAndActiveCheck from '../../Utils/membership_check';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { HeroBanner}  from '../../Common/HeroBanner';
import Sponsors from '../../Common/sponsors';
import do_not_harm from "../../Assets/img/Promises/do_not_harm.svg";
import Mouth_shut from "../../Assets/img/Promises/Mouth-shut.svg";
import Lead_curiosity from "../../Assets/img/Promises/Lead-curiosity.svg";
import ChessGridPromises from '../../Common/ChessGridPromises';
interface Cdata {
  first_name: string,
  last_name: string,
  email: string,
  phone: string | number,
  question: string,
  user_id: string | number,
  company_name: string
}
const Sponsorships = () => {
  let intialData = {
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    question: "",
    user_id: "",
    company_name: ""
  }

  const [cData, setCData] = useState<Cdata>(intialData)
  const [loading, setLoading] = useState(false)
  const [modelOpen, setModelOpen] = useState(false)
  const [error, setError] = useState<Cdata | any>(intialData)
  const [user_data] = GetPrefilledData()
  const id = getUserID()
  const Navigate = useNavigate()
  const dispatch = useDispatch()

  const handleChange = (e: React.ChangeEvent<HTMLInputElement> | any) => {
    let name = e.target.name;
    let value = e.target.value;
    if (name === "first_name" || name === "last_name" || name === "question" || name === "company_name") {
      if (error[name]) {
        setError({
          ...error,
          [name]: ""
        })
      }
    }

    if (name === "email") {
      if (!emailRegex.test(value)) {
        setError({
          ...error,
          ["email"]: "Please enter valid email"
        })
      } else {
        setError({
          ...error,
          ["email"]: ""
        })
      }
    }
    if (name === "phone") {
      if (!phoneRegex.test(value)) {
        setError({
          ...error,
          ["phone"]: "Please enter valid number"
        })
      } else {
        setError({
          ...error,
          ["phone"]: ""
        })
      }
    }
    setCData({
      ...cData,
      [name]: value
    })
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault()

      if (!cData.first_name || !cData.last_name || !emailRegex.test(cData.email) || !phoneRegex.test(cData.phone) || !cData.email || !cData.phone || !cData.question || !cData.company_name) {
        let errors: any = {}
        if (!cData.first_name) {
          errors.first_name = "Please enter your first name"
        }
        if (!cData.last_name) {
          errors.last_name = "Please enter your last name"
        }
        if (!cData.email) {
          errors.email = "Please enter your email"
        }
        if (!cData.phone) {
          errors.phone = "Please enter your phone number"
        }
        if (!emailRegex.test(cData.email)) {
          errors.email = "Please enter a valid email"
        }
        if (!phoneRegex.test(cData.phone)) {
          errors.phone = "Please enter valid phone number"
        }
        if (!cData.question) {
          errors.question = "Please enter your message"
        }
        if (!cData.company_name) {
          errors.company_name = "Please enter your company name"
        }
        setError(errors)
        return
      }
      setLoading(true)

      let res: any = await sponsorshipContactApi({ ...cData, user_id: id })
      isMebershipAndActiveCheck(res, Navigate, toast, dispatch)
      let msg = "";
      let arrMsg = res.data.message
      for (let i = 0; i < arrMsg.length; i++) {
        msg += arrMsg[i] + "\n"
      }
      if (!res.data.success) {
        toast.error(msg)
      } else
        if (res.data.success) {
          // toast.success(msg)
          setModelOpen(true)
          setCData(intialData)
        }
      setLoading(false)
    } catch (error: any) {
      setLoading(false)
      if (typeof error.message === "string") {

        toast.error(error?.message)
      } else {
        toast.error("Something went wrong")
      }
      console.log(error)
    }
  }
  useEffect(() => {
    user_data && setCData(user_data)
  }, [user_data])
  return (
    <div>
      <Container fluid className='sponsorship-container'>
        
        <CongratsModel
          open={modelOpen}
          setOpen={setModelOpen}
          text="Thank you for showing your interest. Our team will be get back to you soon. "
        />
        <div className="py-3">
          <HeroBanner
            hero={sponsorship_banner}
          />
        </div>
        <Row no-gutter className='pale-bg py-4' >
          <div className='col-lg-12 col-md-12 sponsorship-discription-section'>
            <div className='container'>
              <Row className='justify-content-center'>
                <div className='col-lg-10 col-md-10'>
                  <h3 className="text-center sponsor-section-title my-3">Sponsorships & <span className="strong red">Collabs</span></h3>
                  <div className='px-5 sponsor_description'>
                    <p className='text-center'>
                      If you want to give your community a boost via honest, evidence-based parenting advice or gain a new, engaged parenting audience, we want to hear from you. At NAPS™, we partner with a limited number of value-aligned brands to either provide specialized content to your audience or amplify your brand to the NAPS audience - or both!
                    </p>
                    <p className='text-center'>
                      <span className='strong'>
                      We partner ourselves with brands who believe, speak, and act upon a foundation of:
                      </span>
                    </p>
                  </div>
                </div>
              </Row>
            </div>

          </div>
        </Row>

        <ChessGridPromises
              className="my-5"
              promises={[
                {
                  description: (
                    <>
                      Putting the wellbeing of parents and babies at the center of your purpose
                    </>
                  ), colorClass: 'greenText',
                  iconSrc: do_not_harm,
                },
                {
                  description: (
                    <>
                      Avoiding fear-based marketing and messaging to parents
                    </>
                  ), colorClass: 'purpleText',
                  iconSrc: Mouth_shut,
                },
                {
                  description: (
                    <>
                      Continually innovating and adjusting to the changes in perinatal care and needs of parents and babies
                    </>
                  ), colorClass: 'yellowText',
                  iconSrc: Lead_curiosity,
                },
                
              ]}
            />

        <Row className='py-4 our-audience-main' >
          <div className='col-lg-12 col-md-12 my-3'>
            <div className='container-fluid our-audience py-5 '>
              <Row className='justify-content-center'>
                <div className='col-lg-8 col-md-10 my-5'>
                  <h2 className='my-3'>Our <span className='strong'>Audience</span></h2>
                  <div className='d-flex flex-wrap justify-content-center w-100'>
                    <div className='d-flex justify-content-center flex-column align-items-center mx-5 my-35'>
                      <div className='statics'><span className='sm-follorers count'>24</span><span className='value'>k </span></div>
                      <div className='stats-head'>Social Media Followers</div>
                    </div>
                    <div className='d-flex justify-content-center flex-column align-items-center mx-5 my-5'>
                      <div className='statics'><span className="nurture-member count">450</span><span className='value'>avg</span></div>
                      <div className='stats-head'>Nurture Members</div>
                    </div>
                    <div className='d-flex justify-content-center flex-column align-items-center mx-5 my-3'>
                      <div className='statics'><span className='email-subscriber count'>15</span><span className='value'>k</span></div>
                      <div className='stats-head'>Email Subscribers</div>
                    </div>
                    <div className='d-flex justify-content-center flex-column align-items-center mx-5 my-5'>
                      <div className='statics'><span className='podcast-download count'>90</span><span className='value'>k</span></div>
                      <div className='stats-head'>Podcast Downloads</div>
                    </div>
                    <div className='d-flex justify-content-center flex-column align-items-center mx-5 my-5'>
                      <div className='statics'><span className='webinars-view count'>350</span><span className='value'>avg</span></div>
                      <div className='stats-head'>Webinars Views</div>
                    </div>
                  </div>
                </div>
              </Row>
            </div>
          </div>
        </Row>

        <Row className='my-4 all-sponsor-container'>
          <div className='col-lg-12 col-md-12'>
            <Sponsors />
          </div>
        </Row>

        <Row className='pale-bg py-4'>
          <h3 className="text-center contact-section-title my-5">Contact <span className="strong red">Us</span></h3>
          <div className='col-lg-12 col-md-12'>
            <div className='container'>
              <Row className='justify-content-center'>
                <div className='col-lg-8 col-md-8'>
                  <form className='row' onSubmit={handleSubmit}>
                    <div className='form-group col-lg-12 mb-3'>
                      <div className='row'>
                        <div className='form-group col-lg-6 col-md-6'>
                          <label>First Name <span className='required'>*</span></label>
                          <input type="text" name="first_name" value={cData?.first_name}
                            onChange={handleChange}
                            className="form-control" id="" placeholder='Enter' />
                          <h4 className="error-msgtext">{error.first_name}</h4>
                        </div>
                        <div className='form-group col-lg-6 col-md-6'>
                          <label>Last Name <span className='required'>*</span></label>
                          <input type="text" name="last_name"
                            onChange={handleChange}
                            value={cData?.last_name} className="form-control" id="" placeholder='Enter' />
                          <h4 className="error-msgtext">{error.last_name}</h4>
                        </div>
                      </div>
                    </div>
                    <div className='form-group col-lg-12 mb-3'>
                      <div className='row'>

                        <div className='form-group col-lg-12 col-md-12'>
                          <label>Company name <span className='required'>*</span></label>
                          <input type="text" name="company_name"
                            onChange={handleChange}
                            value={cData?.company_name} className="form-control" placeholder='Enter' />
                          <h4 className="error-msgtext"> {error.company_name}</h4>
                        </div>
                      </div>
                    </div>
                    <div className='form-group col-lg-12 mb-3'>
                      <div className='row'>
                        <div className='form-group col-lg-6 col-md-6'>
                          <label>Email <span className='required'>*</span></label>
                          <input type="email" name="email" className="form-control"
                            onChange={handleChange}
                            id="" value={cData?.email} placeholder='Enter' />
                          <h4 className="error-msgtext">{error.email}</h4>
                        </div>
                        <div className='form-group col-lg-6 col-md-6'>
                          <label>Phone Number <span className='required'>*</span></label>
                          <input type="number" name="phone" className="form-control"
                            onChange={handleChange}
                            id="" value={cData?.phone} placeholder='Enter' />
                          <h4 className="error-msgtext">{error.phone}</h4>
                        </div>
                      </div>
                    </div>



                    <div className='form-group col-lg-12 mb-3'>
                      <div className='row'>
                        <div className='form-group col-lg-12 col-md-12'>
                          <label>Message <span className='required'>*</span></label>
                          <textarea className="form-control" onChange={handleChange}
                            value={cData?.question}
                            name="question"
                          />
                          <h4 className="error-msgtext">{error.question}</h4>
                        </div>

                      </div>
                    </div>

                    <div className='col-lg-12 col-md-12'>
                      <button type="submit" className="primary-blue-btn mt-5">{loading ? <CircularProgress /> : "Submit"}</button>
                    </div>
                  </form>
                </div>
              </Row>
            </div>
          </div>
        </Row>
      </Container>
    </div>
  )
}

export default Sponsorships