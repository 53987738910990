import { CircularProgress } from '@mui/material'
import React from 'react'
import { Button, Modal } from 'react-bootstrap'

const ConfirmPopup = ({ open, setOpen, text, callBack, loading }: any) => {

    const handleClose = () => {
        setOpen(false)
    }
    return (
        <div>
            <Modal
                show={open} onHide={handleClose}
                size="sm"
                id="delete-msg-modal"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='custom-modalTeal'
            >
                <Modal.Header closeButton onClick={handleClose} className='border-0'>
                    {/* <Modal.Title id="contained-modal-title-vcenter">
                        Modal heading
                    </Modal.Title> */}
                </Modal.Header>
                <Modal.Body>
                    <h2>{text}</h2>
                </Modal.Body>
                <Modal.Footer className='border-0 d-flex flex-column justify-content-center'>
                    <button className='primary-blue-btn h50' disabled={loading} onClick={callBack} >{loading ? <CircularProgress /> : "Confirm"}</button>
                    <button className='btn gotohome h50' onClick={handleClose}>Cancel</button>

                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default ConfirmPopup