import React from "react";
import "./GCBanner.scss";
import giftCardBanner from "./giftCardBanner.png";
import giftCardBannerMobile from "./giftCardBannerMobile.png";

const GCBanner = () => {
  return (
    <>
      <h1 className="GiftCardBannerTitleMobile">Gift Cards</h1>
      <div className="GiftCardBannerWrapper">
        <img
          className="GiftCardBanner"
          src={giftCardBanner}
          alt="GiftCardBanner"
        />
        <img
          className="GiftCardBannerMobile"
          src={giftCardBannerMobile}
          alt="GiftCardBanner"
        />
        <div className="GiftCardBannerTextWrapper">
          <div className="GiftCardBannerText">
            <h1>Gift Cards</h1>
            <p className="pTwo">
              Give your loved one the gift that keeps on giving... The gift of
              support! <br />
              <br />
              <span className="pOne">
                In addition to our individual classes and services, our
                signature Nurture by NAPS membership provides support for a
                year.
              </span>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default GCBanner;
