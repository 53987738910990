import React from "react";

const ReplaceUrlWithA = ({ text }: { text: string }) => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;

  const parts = text.split(urlRegex);

  const replacedText = parts.map((part: string, index: number) => {
    if (part.match(urlRegex)) {
      return (
        <a key={index} href={part} target="_blank" rel="noopener noreferrer">
          {part}
        </a>
      );
    }
    return part;
  });

  return <div>{replacedText}</div>;
};

export default ReplaceUrlWithA;
