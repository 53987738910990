import React from "react";
import "./faq.scss";
import Accordion from "../../../../Common/Accordion/Accordion";

const FAQ = ({ openMembershipPage }: { openMembershipPage: () => void }) => (
  <div className="preLoginFAQWrapper">
    <h2 className="heading2">FAQ</h2>
    <Accordion
      bottomBorder
      title="If I don't want to commit to a year do I have another option?"
      description={
        <>
          Yes! While we hope you see the value in having ongoing support from
          the same professionals, we understand a shorter time-frame may work
          better for your family's needs. In which case, you can sign up for a
          monthly membership <button onClick={openMembershipPage}>here</button>{" "}
          for $149/mo.
        </>
      }
    />
    <Accordion
      bottomBorder
      title="What are the terms and conditions for the money back guarantee?"
      description={
        <>
          You are entitled to your money back on an annual membership, no
          questions asked, so long as you make your request within 14 days of
          purchase, have booked and showed up to your welcome call within 14
          days of purchase, and have not used your discount code on any services
          already rendered.
        </>
      }
    />
    <Accordion
      bottomBorder
      title="I'm expecting a baby, when is the best time to sign up for the membership?"
      description={
        <>
          Honestly, now! We have so much content and resources to start
          supporting you while you wait for the arrival of your baby from
          must-have items, to how your partner can support you during your
          pregnancy, in labor and after delivery, to managing common symptoms
          experienced in pregnancy. Jump in now to get the support you probably
          want but don't have through your healthcare provider!
        </>
      }
    />
    <Accordion
      bottomBorder
      title="What services are included in the annual membership?"
      description={
        <>
          All bootcamps including Pre Baby Bootcamp, Veteran Bootcamp, and
          Grandparent Bootcamp. All monthly prenatal classes including labor and
          delivery prep, newborn essentials, breastfeeding basics, and infant
          and child CPR with first aid. All support groups, including new,
          veteran, and working mom survival groups as well as the group sleep
          consult. One virtual 1:1 feeding consultation. Two 1:1 30 minute phone
          consults. If you choose a monthly membership, you do not get these
          included services, but instead get a discount on services.
        </>
      }
    />
    <Accordion
      title="For services not included, or if I am a monthly member, what is the discount on services and are there any exclusions?"
      description={
        <>
          For any services not included in the annual membership, or for monthly
          members, you get a 20% discount on all services except daytime and
          overnight nursing care. Your unique 20% off discount code will be
          emailed to you when you enroll.
        </>
      }
    />
  </div>
);

export default FAQ;
