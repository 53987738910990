import React, { useRef } from 'react'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useLocation, useNavigate } from 'react-router-dom';
import { privateBookingApi } from '../../Service/Cousult.service';
import { emailRegex } from '../../Utils/Regex';
import "./PrivateClassForm.scss";
import moment from 'moment';
import { CircularProgress } from '@mui/material';
import { Dayjs } from 'dayjs';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import CongratsModel2 from '../../Common/CongratsPopup2/CongratsPopup2';
import { GIFTCARD } from '../../Routes/RouteConstent';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import isMebershipAndActiveCheck from '../../Utils/membership_check';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';


function PrivateClassForm() {

    let user: any = localStorage.getItem("Nurture_user_data")
    user = JSON.parse(user)
    let iValue = {
        first_name: user?.first_name,
        last_name: user?.last_name,
        email: user?.email,
        phone: user?.phone
    }
    const [value, setValue] = React.useState<Dayjs | null | any>({});
    const [values, setValues] = React.useState<any>(iValue)
    const [error, setError] = React.useState<any>({})
    const { state }: any = useLocation()
    const [congrats, setCongrats] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    const inputRef = useRef<any>(null)

    const Navigate = useNavigate();
    const dispatch = useDispatch();

    const handleChangeInfo = (e: any) => {

        let name = e.target.name;
        let value = e.target.value;
        if (name === "first_name") {
            if (!value) {
                setError({
                    ...error,
                    [name]: "Field is required"
                })
            } else {
                setError({
                    ...error,
                    [name]: ""
                })
            }
        }
        if (name === "last_name") {
            if (!value) {
                setError({
                    ...error,
                    [name]: "Field is required"
                })
            } else {
                setError({
                    ...error,
                    [name]: ""
                })
            }
        }

        if (name === "email") {
            if (!emailRegex.test(value)) {
                setError({
                    ...error,
                    [name]: "Email in not valid"
                })
            } else {
                setError({
                    ...error,
                    [name]: ""
                })
            }
        }
        if (name === "phone") {
            if (value.length < 10) {
                setError({
                    ...error,
                    [name]: "Please enter valid contact no."
                })
            }

            else {
                setError({
                    ...error,
                    [name]: ""
                })
            }
        }
        if (name === "total_anticipated_participants") {
            setError({
                ...error,
                [name]: ""
            })

        }
        if (name === "street_address") {
            setError({
                ...error,
                [name]: ""
            })

        }
        setValues({
            ...values,
            [e.target.name]: e.target.value
        })

    }
    const handlerRadioBtn = (e: any) => {
        setValues({
            ...values,
            [e.target.name]: e.target.value
        })
        setError({
            ...error,
            [e.target.name]: ""
        })


    }
    const dateHandler = (val: any, name: string) => {
        setValues({
            ...values,
            [name]: moment(val).format("L")
        })
        setError({
            ...error,
            [name]: ""
        })
    }
    const timeHandler = (val: any, name: string) => {

        if (String(val) === "Invalid Date") {
            setError({
                ...error,
                [name]: "Invalid time (HH:MM am/pm)"
            })
        } else {
            setError({
                ...error,
                [name]: ""
            })
        }
        if (name === "preferred_time_1") {
            setValue({
                ...value,
                ["time1"]: val
            })
        }
        if (name === "preferred_time_2") {
            setValue({
                ...value,
                ["time2"]: val
            })
        }
        setValues({
            ...values,
            [name]: moment(val).format("LT")
        })

    }
    const handlePrivateBook = async (e: any) => {


        e.preventDefault()
        try {
            if (
                !values?.total_anticipated_participants || !values?.street_address ||
                !values?.due_date || values?.due_date === "Invalid date" || !values?.would_you_like_to_book_an_in_home_or_virtual_consult ||

                !values?.preferred_date_1 || !values?.preferred_date_2 || !values?.preferred_time_1 ||
                !values?.preferred_time_2 || values?.preferred_time_1 === "Invalid date" || values?.preferred_time_2 === "Invalid date" ||
                !values?.are_you_isolating_or_quarantining) {

                let errors: any = {}

                if (user?.first_name) {
                    errors.first_name = ""
                }

                if (user?.last_name) {
                    errors.last_name = ""
                }

                if (user?.email) {
                    errors.email = ""
                }


                if (user?.phone) {
                    errors.phone = ""
                }

                if (!values?.total_anticipated_participants) {
                    errors.total_anticipated_participants = "Please Enter the Value"
                }

                if (!values?.street_address) {
                    errors.street_address = "Please Enter the Value"
                }
                if (!values?.due_date) {
                    errors.due_date = "Please select date"
                }
                if (!values?.would_you_like_to_book_an_in_home_or_virtual_consult) {
                    errors.would_you_like_to_book_an_in_home_or_virtual_consult = "Please Select the Value"
                }

                if (!values?.preferred_date_1) {
                    errors.preferred_date_1 = "Please select date"
                }
                if (values?.preferred_time_1 === "Invalid date") {
                    errors.preferred_time_1 = "Invalid (HH:MM am/pm)"
                }
                if (values?.preferred_time_2 === "Invalid date") {
                    errors.preferred_time_2 = "Invalid (HH:MM am/pm)"
                }
                if (!values?.preferred_date_2) {
                    errors.preferred_date_2 = "Please select date"
                }
                if (!values?.preferred_time_1) {
                    errors.preferred_time_1 = "Please select time"
                }
                if (!values?.preferred_time_2) {
                    errors.preferred_time_2 = "Please select time"
                }
                if (!values?.regardless_of_your_vaccination_status) {
                    errors.regardless_of_your_vaccination_status = "Please Select the Value"
                }
                if (!values?.are_you_isolating_or_quarantining) {
                    errors.are_you_isolating_or_quarantining = "Please Select the Value"
                }

                setError(errors)

            } else {
                setLoading(true)
                let param = {
                    type: "CPR and first aid private class",
                    user_id: user?.id,
                    first_name: values?.first_name,
                    last_name: values?.last_name,
                    email: values?.email,
                    phone: values?.phone,
                    due_date: values?.due_date,
                    street_address: values?.street_address,
                    total_anticipated_participants: values?.total_anticipated_participants,
                    would_you_like_to_book_an_in_home_or_virtual_consult: values?.would_you_like_to_book_an_in_home_or_virtual_consult,
                    prenatal_classes_being_requested: values?.prenatal_classes_being_requested,
                    preferred_date_1: values?.preferred_date_1,
                    preferred_date_2: values?.preferred_date_2,
                    preferred_time_1: values?.preferred_time_1,
                    preferred_time_2: values?.preferred_time_2,
                    regardless_of_your_vaccination_status: values?.regardless_of_your_vaccination_status,
                    are_you_isolating_or_quarantining: values?.are_you_isolating_or_quarantining,
                    have_you_been_in_close_physical_contact_is_defined: values?.have_you_been_in_close_physical_contact_is_defined
                }
                
                let res: any = await privateBookingApi(param)
                isMebershipAndActiveCheck(res, Navigate, toast, dispatch)
                if (res?.data?.success) {
                    setValues({
                        ...iValue,
                        due_date: "",
                        street_address: "",
                        total_anticipated_participants: "",
                        would_you_like_to_book_an_in_home_or_virtual_consult: "",
                        prenatal_classes_being_requested: "",
                        preferred_date_1: "",
                        preferred_date_2: "",
                        preferred_time_1: "",
                        preferred_time_2: "",
                        regardless_of_your_vaccination_status: "",
                        are_you_isolating_or_quarantining: "",
                        have_you_been_in_close_physical_contact_is_defined: ""
                    })
                    setCongrats(true)
                    setLoading(false)
                }
            }
        } catch (err: any) {
            console.log(err)
            if (err && typeof err?.message === "string") {
                toast.error(err?.message)
            }
            setLoading(false)
        }
    }

    const onWheel = (val: any) => {
        val.current.blur()

    }

    return (
        <div>
            <CongratsModel2
                open={congrats}
                setOpen={setCongrats}
                btnText="Send gift card to your friend"
                Page={GIFTCARD}
                callBack={() => setValue(iValue)}
                text={'All information about your upcoming appointment has been sent to your email.<br>In the meantime, feel free to check out our other offerings, or if you have a friend who you think would be interested in the same booking as you, tell them using the button below!'} title="Thank you for booking with NAPS!" />

            <div className='Veterans-Moms’-Survival-Group'>
                <section className='private-consult step-container'>
                    <div className='consult-section-heading text-center'>
                        <h5 className='mb-3'>CPR and first aid private class</h5>
                        <h3 className='mb-3 mt-2'>Private Class Request Form</h3>
                        <p className='text-center pb-3'>Please fill out the form below to request an appointment. We will respond to your request within 24 hours Monday - Friday and within 48 hours on the weekend.</p>
                        <p className='text-center'>Private, prenatal classes are held privately in your home with a Registered Nurse. Or, if you prefer, you may book a private, virtual session instead. Please indicate your choice on the form.</p>
                    </div>
                    <form className=' '>
                        <div className='row'>
                            <div className="form-group col-lg-6 col-md-6 mb-3">
                                <label>First Name <span className='mandatory text-danger mx-2'>*</span></label>
                                <input type="text" name='first_name' className='form-control' value={values?.first_name} onChange={handleChangeInfo} placeholder='Enter first name' />
                                <h4 className="error">{error?.first_name}</h4>
                            </div>
                            <div className="form-group col-lg-6 col-md-6 mb-3">
                                <label>Last Name<span className='mandatory text-danger mx-2'>*</span></label>
                                <input type="text" name='last_name' className='form-control' value={values?.last_name} onChange={handleChangeInfo} placeholder='Enter last name' />
                                <h4 className="error">{error.last_name}</h4>
                            </div>
                        </div>
                        <div className='row'>
                            <div className="form-group col-lg-6 col-md-6 mb-3">
                                <label>Email <span className='mandatory text-danger mx-2'>*</span></label>
                                <input type="text" name='email' className='form-control' value={values?.email} onChange={handleChangeInfo} placeholder='Enter email address' />
                                <h4 className="error">{error?.email}</h4>
                            </div>
                            <div className="form-group col-lg-6 col-md-6 mb-3">
                                <label>Phone Number <span className='mandatory text-danger mx-2'>*</span></label>
                                <input type="number" name='phone' ref={inputRef} value={values?.phone} onWheel={() => onWheel(inputRef)} min="0"
                                    className='form-control' onChange={handleChangeInfo} placeholder='123456' />
                                <h4 className="error">{error.phone}</h4>
                            </div>
                        </div>
                        <div className='row'>
                            <div className="form-group col-lg-12 col-md-12 mb-3 due_dt_container">
                                <label >Due Date/Child's Birthday <span className='mandatory text-danger mx-2'>*</span> </label>
                                <LocalizationProvider dateAdapter={AdapterDateFns} >

                                    <Stack spacing={3}>
                                        <MobileDatePicker
                                            value={values?.due_date || ""}
                                            onChange={(val: any) => dateHandler(val, "due_date")}
                                            renderInput={(params: any) => <TextField {...params} placeholder="MM-DD-YYYY" className='due-date-field' />}
                                        />
                                    </Stack>
                                </LocalizationProvider>
                                <h4 className="error">{error.due_date}</h4>
                            </div>
                        </div>
                        <div className='row'>
                            <div className="form-group col-lg-12 col-md-12 mb-3">
                                <label>Street address <span className='mandatory text-danger mx-2'>*</span></label>
                                <input type="text" name='street_address' className='form-control' value={values?.street_address} onChange={handleChangeInfo} />
                                <h4 className="error">{error.street_address}</h4>
                            </div>
                        </div>
                        <div className='row'>
                            <div className="form-group col-lg-12 col-md-12 mb-3">
                                <label>Total Anticipated Participants<span className='mandatory text-danger mx-2'>*</span></label>
                                <input type="text" name='total_anticipated_participants' value={values?.total_anticipated_participants} className='form-control' onChange={handleChangeInfo} />

                                <h4 className="error">{error.total_anticipated_participants}</h4>
                            </div>
                        </div>
                        <div className='row'>
                            <div className="radio-area pb-4 ">
                                <h4>Would you like to book an in-home or virtual consult? <span className='mandatory text-danger mx-2'>* </span></h4>
                                <div className="custom-radio custom-control-inline">
                                    <input type="radio" id="customRadio1" name="would_you_like_to_book_an_in_home_or_virtual_consult" value="In-home"
                                        onChange={handlerRadioBtn} className="custom-control-input form-check-input"
                                        checked={values?.would_you_like_to_book_an_in_home_or_virtual_consult === "In-home"}
                                    />
                                    <label className="custom-control-label step_two me-3" htmlFor="customRadio1">In-home</label>
                                    <input type="radio" id="customRadio177" name="would_you_like_to_book_an_in_home_or_virtual_consult" value="Virtual Class"
                                        onChange={handlerRadioBtn} className="custom-control-input form-check-input"
                                        checked={values?.would_you_like_to_book_an_in_home_or_virtual_consult === "Virtual Class"}

                                    />
                                    <label className="custom-control-label step_two" htmlFor="customRadio177">Virtual Class</label>
                                </div>
                                <p className="error">{error.would_you_like_to_book_an_in_home_or_virtual_consult}</p>
                            </div>

                        </div>

                        <div className='row'>
                            <div className="form-group col-lg-6 col-md-6 mb-3">
                                <label>Preferred Date #1<span className='mandatory text-danger mx-2'>*</span></label>
                                <LocalizationProvider dateAdapter={AdapterDateFns} >
                                    <Stack spacing={3}>
                                        <MobileDatePicker
                                            value={values?.preferred_date_1 || ""}
                                            onChange={(val: any) => dateHandler(val, "preferred_date_1")}
                                            renderInput={(params: any) => <TextField {...params} placeholder="MM-DD-YYYY" className='preferred-dt' />}
                                        />
                                    </Stack>
                                </LocalizationProvider>
                                <h4 className="error">{error.preferred_date_1}</h4>
                            </div>
                            <div className="form-group col-lg-6 col-md-6 mb-3">
                                <label>Preferred Date #2<span className='mandatory text-danger mx-2'>*</span></label>
                                <LocalizationProvider dateAdapter={AdapterDateFns} >

                                    <Stack spacing={3}>
                                        <MobileDatePicker
                                            value={values?.preferred_date_2 || ""}
                                            onChange={(val: any) => dateHandler(val, "preferred_date_2")}
                                            renderInput={(params: any) => <TextField {...params} placeholder="MM-DD-YYYY" className='preferred-dt' />}
                                        />
                                    </Stack>
                                </LocalizationProvider>
                                <h4 className="error">{error.preferred_date_2}</h4>
                            </div>
                        </div>

                        <div className='row mb-3'>
                            <div className="form-group col-lg-6 col-md-6 mb-3">
                                <label>Preferred Time #1<span className='mandatory text-danger mx-2'>*</span></label>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <MobileTimePicker
                                        value={value?.time1 || ""}
                                        onChange={(val: any) => timeHandler(val, "preferred_time_1")}
                                        className="d-flex"
                                        disableMaskedInput={true}
                                        views={['hours', 'minutes']}
                                        renderInput={(params: any) => <TextField placeholder='HH:MM' {...params} className='preferred-tm' />}
                                    />
                                </LocalizationProvider>
                                <h4 className="error">{error.preferred_time_1}</h4>
                            </div>
                            <div className="form-group col-lg-6 col-md-6 mb-3">
                                <label>Preferred Time #2<span className='mandatory text-danger mx-2'>*</span></label>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <MobileTimePicker
                                        value={value?.time2 || ""}
                                        onChange={(val: any) => timeHandler(val, "preferred_time_2")}
                                        views={['hours', 'minutes']}
                                        renderInput={(params: any) => <TextField placeholder='HH:MM' {...params} className='preferred-tm' />}
                                    />
                                </LocalizationProvider>
                                <h4 className="error">{error.preferred_time_2}</h4>
                            </div>
                        </div>

                        <div className='row mb-3'>
                            <p>Regardless of your vaccination status, have you experienced any of the symptoms in the list below in the past 48 hours?</p>
                            <label>Fever or chills, cough, shortness of breath or difficulty breathing, fatigue, muscle or body aches, headache, new loss of taste or smell, sore throat, congestion or runny nose, nausea or vomiting, diarrhea.<span className='mandatory text-danger mx-2'>*</span></label>
                            <div className="custom-radio custom-control-inline">
                                <input type="radio" id="customRadio15" checked={values?.regardless_of_your_vaccination_status === "yes"} name="regardless_of_your_vaccination_status" value="yes"
                                    onChange={handlerRadioBtn} className="custom-control-input form-check-input" />
                                <label className="custom-control-label step_two me-3" htmlFor="customRadio15">yes</label>

                                <input type="radio" id="customRadio17" checked={values?.regardless_of_your_vaccination_status === "no"} name="regardless_of_your_vaccination_status" value="no"
                                    onChange={handlerRadioBtn} className="custom-control-input form-check-input" />
                                <label className="custom-control-label step_two me-3" htmlFor="customRadio17">no</label>
                            </div>
                            <h4 className="error">{error.regardless_of_your_vaccination_status}</h4>
                        </div>

                        <div className='row mb-3'>
                            <label>Are you isolating or quarantining because you tested positive for COVID-19 or are worried that you may be sick with COVID-19?<span className='mandatory text-danger mx-2'>*</span></label>
                            <div className="custom-radio custom-control-inline">
                                <input type="radio" id="customRadio13" name="are_you_isolating_or_quarantining" checked={values?.are_you_isolating_or_quarantining === "yes"} value="yes"
                                    onChange={handlerRadioBtn} className="custom-control-input form-check-input" />
                                <label className="custom-control-label step_two me-3" htmlFor="customRadio13">yes</label>

                                <input type="radio" id="customRadio12" name="are_you_isolating_or_quarantining" checked={values?.are_you_isolating_or_quarantining === "no"} value="no"
                                    onChange={handlerRadioBtn} className="custom-control-input form-check-input" />
                                <label className="custom-control-label step_two me-3" htmlFor="customRadio12">no</label>
                            </div>
                            <h4 className="error">{error.are_you_isolating_or_quarantining}</h4>
                        </div>

                        <div className='row mb-3'>
                            <label>Have you been in close physical contact in the last 14 days with anyone who is known to have laboratory-confirmed COVID-19 or anyone who has any symptoms consistent with COVID-19? </label>
                            <p>Close physical contact is defined as being within 6 feet of an infected/symptomatic person for a cumulative total of 15 minutes or more over a 24-hour period starting from 48 hours before illness onset (or, for asymptomatic individuals, 48 hours prior to test specimen collection).</p>
                            <div className="custom-radio custom-control-inline">
                                <input type="radio" id="have_you_yes" name="have_you_been_in_close_physical_contact_is_defined" checked={values?.have_you_been_in_close_physical_contact_is_defined === 'yes'} value="yes"
                                    onChange={handlerRadioBtn} className="custom-control-input form-check-input" />
                                <label className="custom-control-label step_two me-3" htmlFor="have_you_yes">yes</label>

                                <input type="radio" id="have_you_no" name="have_you_been_in_close_physical_contact_is_defined" value="no"
                                    onChange={handlerRadioBtn} className="custom-control-input form-check-input"
                                    checked={values?.have_you_been_in_close_physical_contact_is_defined == "no"}
                                />
                                <label className="custom-control-label step_two me-3" htmlFor="have_you_no">no</label>
                            </div>
                        </div>

                        <div className='row  info'>
                            <label>Prenatal Classes</label>
                            <div className='prenatal-consult-info'>
                                <p className='m-0 p-0'>In case you haven’t checked out our other prenatal classes beyond CPR, please visit our pages about Newborn Essentials, Breastfeeding Basics, and Labor & Delivery Prep. </p>
                            </div>
                        </div>

                        <div className='section-navigation my-5 d-flex justify-content-between'>
                            <button className='primary-blue-small-btn w-100' disabled={loading} onClick={handlePrivateBook}>{loading ? <CircularProgress /> : "Submit"} </button>
                        </div>
                    </form>
                </section>
            </div>
        </div>
    )
}

export default PrivateClassForm
