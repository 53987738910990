import React from "react";
import "./BHUnswaddled.scss";
import Unswaddled from "../../../Common/Unswaddled";

const BHUnswaddled = () => (
  <div className="bhUnswaddled">
    <Unswaddled />
  </div>
);

export default BHUnswaddled;
