import React from "react";
import "./ChooseWayToPay.scss";
import { PayWith, PayWithEnum } from "./useChooseWayToPay";

interface ChooseWayToPayProps {
  setPayWith: (payWith: PayWith) => void;
  payWith: PayWith;
}

const ChooseWayToPay = ({ setPayWith, payWith }: ChooseWayToPayProps) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPayWith(e.target.value as PayWith);
  };

  return (
    <div>
      <input
        type="radio"
        id={PayWithEnum.saved_card}
        name={PayWithEnum.saved_card}
        checked={payWith === PayWithEnum.saved_card}
        onChange={handleChange}
        value={PayWithEnum.saved_card}
        className="custom-control-input form-check-input"
      />
      <label className="custom-control-label me-3" htmlFor={PayWithEnum.saved_card}>
        Use saved card
      </label>
      <input
        type="radio"
        id={PayWithEnum.new_card}
        name={PayWithEnum.new_card}
        onChange={handleChange}
        value={PayWithEnum.new_card}
        checked={payWith === PayWithEnum.new_card}
        className="custom-control-input form-check-input"
      />
      <label
        className="custom-control-label step_two"
        htmlFor={PayWithEnum.new_card}
      >
        Add new card
      </label>
    </div>
  );
};

export default ChooseWayToPay;
