import React from "react";
import "./howWeSupport.scss";
import contentSvg from "./content.svg";
import messageSvg from "./message.svg";
import healthSvg from "./health.svg";
import smallGroupSvg from "./smallGroup.svg";
import qaSvg from "./qa.svg";
import workshopSvg from "./workshop.svg";
import { Carousel } from "react-bootstrap";

const HowWeSupport = () => (
  <div className="preLoginHowWeSupport">
    <h3 className="heading3">
      How we <span>support you...</span>
    </h3>

    <div className="preLoginHowWeSupportList">
      {howWeSupportList.map((card) => (
        <div className="preLoginHowWeSupportCard" key={card.id}>
          <div className="preLoginHowWeSupportListGrow">
            <img src={card.icon} alt={card.title} />
          </div>
          <p className="title">{card.title}</p>
          <p className="description">{card.description}</p>
        </div>
      ))}
    </div>
    
    <Carousel className="preLoginHowWeSupportCarousel">
      {howWeSupportList.map((card) => (
        <Carousel.Item key={card.id}>
          <div className="preLoginHowWeSupportCard">
            <div className="preLoginHowWeSupportListGrow">
              <img src={card.icon} alt={card.title} />
            </div>
            <p className="title">{card.title}</p>
            <p className="description">{card.description}</p>
          </div>
        </Carousel.Item>
      ))}
    </Carousel>
  </div>
);

const howWeSupportList = [
  {
    id: 0,
    icon: contentSvg,
    description:
      "Video, Audio, and Written searchable recorded content for quick answers 24/7",
    title: "On Demand Content",
  },
  {
    id: 1,
    icon: messageSvg,
    description:
      "Same day answers by an RN to your everyday parenting questions on our ask a nurse message board",
    title: "Message Board",
  },
  {
    id: 2,
    icon: healthSvg,
    description:
      "Ability to book 1:1 phone consultations or Telehealth appointments as needed",
    title: "Live, virtual 1:1 Support",
  },
  {
    id: 3,
    icon: smallGroupSvg,
    description:
      "Small group forum for new, veteran, working, and sleep deprived parents ",
    title: "Live, Virtual Small Group Support",
  },
  {
    id: 4,
    icon: qaSvg,
    description:
      "Weekly drop-in calls to address questions and develop a plan for acute concerns",
    title: "Live, virtual Q&As",
  },
  {
    id: 5,
    icon: workshopSvg,
    description:
      "Monthly prenatal and parenting classes for every stage and topic, from pregnancy through preschool.",
    title: "Live, Virtual Workshops",
  },
];

export default HowWeSupport;
