import React, { useState } from "react";
import { Menu, MenuItem, IconButton } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const Options = ({
  removePaymentMethod,
  makePrimaryPaymentMethod,
  isPrimary,
}: any) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => setAnchorEl(null);

  const handleRemovePaymentMethod = () => {
    handleClose();
    removePaymentMethod();
  };

  const handleMakePrimaryPaymentMethod = () => {
    handleClose();
    makePrimaryPaymentMethod();
  };

  return (
    <div className="cardMethodMenu">
      <IconButton
        aria-controls="paymentMethodMenu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="paymentMethodMenu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <MenuItem onClick={handleRemovePaymentMethod}>
          Remove Payment Method
        </MenuItem>
        {!isPrimary && (
          <MenuItem onClick={handleMakePrimaryPaymentMethod}>
            Make primary
          </MenuItem>
        )}
      </Menu>
    </div>
  );
};

export default Options;
