import { FiberManualRecord } from '@mui/icons-material';
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { PROFILEUSER } from '../../../Routes/RouteConstent';
import { newMessagesSelector } from '../../../Redux/new-messages/newMessages';


function StepperMenu(props: any) {
    let { activeStep, setActiveStep, setSubStep, setSubStep1, subStep, subStep1, subStep2, setSubStep2, subStep3, setSubStep3, mobileOpen, setMobileOpen, data, setNotify, notify } = props

    const Navigate = useNavigate()
    let user: any = localStorage.getItem("Nurture_user_data");
    user = JSON.parse(user)
    let unseenMsg: any = localStorage.getItem("NapsUnseenMsg")
    unseenMsg = JSON.parse(unseenMsg)

    const manageRoute = (val: number | string) => {
        Navigate(`${PROFILEUSER}?stp=${val}&sub=1`)
    }
    const manageSubRoute = (val: number) => {
        Navigate(`${PROFILEUSER}?stp=${activeStep}&sub=${val}`)
    }

    useEffect(() => {
        setNotify(unseenMsg?.length)
    }, [])

    const handleNext = (value: any) => {
        manageRoute(value)
        setActiveStep(value);
        if (user?.is_stripe_membership == 0) {
            setSubStep(4)
        }
        setSubStep1(1)
        setSubStep2(1)
        setSubStep3(1)
        setMobileOpen(true)
    }
    const handleCourses = (value: number) => {
        manageSubRoute(value)
        setSubStep(value)
    }
    const handleEvents = (value: number) => {
        manageSubRoute(value)
        setSubStep1(value)
    }
    const handleConsults = (value: number) => {
        manageSubRoute(value)
        setSubStep2(value)
    }
    const handleGiftCard = (value: number) => {
        manageSubRoute(value)
        setSubStep3(value)
    }
  
    const newMessages = useSelector(newMessagesSelector);

    return (
        <div className='col-lg-2 col-md-3'>
            <ul className={`list-unstyled ps-0 sidebar-nav ${!mobileOpen ? 'mobile-nav-close' : 'mobile-nav-open'} `}>
                <li className="mb-1 w-100 tier1">
                    <button className={`btn btn-toggle align-items-center rounded border-0 ${activeStep !== "MyCourses" && 'collapsed'}`} data-bs-toggle="collapse" data-bs-target="#home-collapse" aria-expanded="false" onClick={() => handleNext('MyCourses')}>
                        My Courses
                    </button>
                    <div className={`collapse ${activeStep === "MyCourses" && 'show'} collapsible-nav`} id="home-collapse">
                        <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                            {/* <li onClick={() => handleCourses(0)}><Link to={''} className={`${subStep == '' && "active"}`}>All</Link></li> */}
                            {
                                user?.is_stripe_membership == 0 &&
                                <li onClick={() => handleCourses(4)}><Link to={''} className={`${subStep == 4 && "active"} link-dark rounded`}>Purchased</Link></li>
                            }
                            {
                                user?.is_stripe_membership == 1 && <>
                                    <li onClick={() => handleCourses(1)}><Link to={''} className={`${subStep == 1 && "active"}`}>Started</Link></li>
                                    <li onClick={() => handleCourses(2)}><Link to={''} className={`${subStep == 2 && "active"} link-dark rounded`}>Completed</Link></li>
                                </>
                            }
                            <li onClick={() => handleCourses(3)}><Link to={''} className={`${subStep == 3 && "active"} link-dark rounded`}>Favorites</Link></li>
                        </ul>
                    </div>
                </li>
                <li className="mb-1 w-100 ">
                    <button className={`btn btn-toggle align-items-center rounded border-0 ${activeStep !== "MyEvents" && 'collapsed'}`} data-bs-toggle="collapse" data-bs-target="#dashboard-collapse" aria-expanded="false" onClick={() => handleNext('MyEvents')}>
                        My Events
                    </button>
                    <div className={`collapse ${activeStep === "MyEvents" && 'show'} collapsible-nav`} id="dashboard-collapse">
                        <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                            {/* <li onClick={() => handleEvents(0)}><Link to={''} className={`${subStep1 == '' && "active"} link-dark rounded`}>All</Link></li> */}
                            <li onClick={() => handleEvents(1)}><Link to={''} className={`${subStep1 == 1 && "active"} link-dark rounded`}>Upcoming</Link></li>
                            <li onClick={() => handleEvents(2)}><Link to={''} className={`${subStep1 == 2 && "active"} link-dark rounded`}>Past</Link></li>
                            <li onClick={() => handleEvents(3)}><Link to={''} className={`${subStep1 == 3 && "active"} link-dark rounded`}>Favorites</Link></li>
                        </ul>
                    </div>
                </li>
                <li className="mb-1 w-100">
                    <button className={`btn btn-toggle align-items-center rounded border-0 ${activeStep !== "MyServices" && 'collapsed'}`} data-bs-toggle="collapse" data-bs-target="#service-collapse" aria-expanded="false" onClick={() => handleNext('MyServices')}>
                        My Services
                    </button>
                    <div className={`collapse ${activeStep === "MyServices" && 'show'} collapsible-nav`} id="service-collapse">
                        <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                            {/* <li onClick={() => handleConsults(0)}><Link to={''} className={`${subStep2 == '' && "active"} link-dark rounded`}>All</Link></li> */}
                            <li onClick={() => handleConsults(1)}><Link to={''} className={`${subStep2 == 1 && "active"} link-dark rounded`}>Upcoming</Link></li>
                            <li onClick={() => handleConsults(2)}><Link to={''} className={`${subStep2 == 2 && "active"} link-dark rounded`}>Past</Link></li>
                            {/* <li onClick={() => handleConsults(3)}><Link to={''} className={`${subStep2 == 3 && "active"} link-dark rounded`}>Gift Card</Link></li> */}
                        </ul>
                    </div>
                </li>
                <li className="mb-1 w-100 ">
                    <button className={`btn btn-toggle align-items-center rounded border-0 ${activeStep !== "gift" && 'collapsed'}`} data-bs-toggle="collapse" data-bs-target="#gift-card-collapse" aria-expanded="false" onClick={() => handleNext('gift')}>
                        Gift Card
                    </button>
                    <div className={`collapse ${activeStep === "gift" && 'show'} collapsible-nav`} id="gift-card-collapse">
                        <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                            <li onClick={() => handleGiftCard(1)}><Link to={''} className={`${subStep3 == 1 && "active"} link-dark rounded`}>Received</Link></li>
                            <li onClick={() => handleGiftCard(2)}><Link to={''} className={`${subStep3 == 2 && "active"} link-dark rounded`}>Sent</Link></li>
                        </ul>
                    </div>
                </li>
                <li className="mb-1">
                    {
                        user?.is_stripe_membership
                            ? <button className={`btn btn-toggle align-items-center border-0 rounded ${activeStep !== "Ask_a_Nurse" && 'collapsed'}`} data-bs-toggle="collapse" data-bs-target="#orders-collapse" aria-expanded="false" onClick={() => handleNext('Ask_a_Nurse')}>
                                Ask a Nurse{' '}
                                {!!newMessages.data.length && <FiberManualRecord
                                    style={{ color: "rgb(245, 125, 125)" }}
                                    fontSize="small"
                                />}
                            </button>
                            : <></>
                    }
                </li>
            </ul>
        </div>

    )
}

export default StepperMenu