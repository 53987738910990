import React from "react";
import "./ChessGridPromises.scss";

interface Props {
    className: string,
  promises: {
    colorClass: string;
    iconSrc: string;
    description: string | React.ReactNode | React.ReactNode[];
  }[];
}

const ChessGridPromises = ({ promises, className }: Props) => (
  <div className={`chess-grid-promises ${className}`}>
    {promises.map(({ iconSrc, description , colorClass}, i) => (
      <div className={`chess-grid-promises-row ${colorClass}`} key={i}>
        <img
          src={iconSrc}
          className="chess-grid-promises-icon"
          alt="we promise"
        />
        <div className="chess-grid-promises-text">
          <div className="chess-grid-promises-point">{i < 10 ? `0${i + 1}` : i + 1}</div>
          <div className="chess-grid-promises-description">{description}</div>
        </div>
      </div>
    ))}
  </div>
);

export default ChessGridPromises;
