import { useEffect, useRef } from "react";

const useScrollToElementId = ({
  doScroll,
  prefix,
  scrollId,
}: {
  doScroll: boolean;
  prefix: string;
  scrollId: string | number;
}) => {
  const didScrollToCourseId = useRef(false);

  useEffect(() => {
    if (!didScrollToCourseId.current && doScroll) {
      didScrollToCourseId.current = true;

      document.getElementById(`${prefix}${scrollId}`)?.scrollIntoView();
    }
  }, [scrollId, prefix, doScroll]);
};

export default useScrollToElementId;
