import React from "react";
import "./HeroBanner.scss";

interface Props {
  hero: string;
  title?: React.ReactNode | string;
  width?: number;
  className?: string;
  maxDesktopHeight?: string | number;
}
const HeroBannerHorizontal = ({
  hero,
  title,
  width = 50,
  maxDesktopHeight = "auto",
  className,
}: Props) => {
  return (
    <div
      className={`heroBannerMain ${className}`}
      style={{
        backgroundImage: `url(${hero})`,
        maxHeight:
          typeof maxDesktopHeight === "number"
            ? `${maxDesktopHeight}px`
            : maxDesktopHeight,
      }}
    >
      {title && (
        <h1
          className="heroBannerHorizontalTitle"
          style={{ width: `${width}%` }}
        >
          {title}
        </h1>
      )}
    </div>
  );
};
export default HeroBannerHorizontal;
