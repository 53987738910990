import React from "react";
import "./judgmentFreeSupport.scss";
import Wrapper from "../Wrapper";
import TextContent from "../TextContent";
import sleep from "./sleep.png";
import group from "./group.png";
import baby from "./Baby.svg";
import hi from "./Hi.svg";
import { SLEEPCONSULT, SUPPORTGROUP } from "../../../Routes/RouteConstent";
import { useNavigate } from "react-router-dom";

const ServiceCard = () => {
  const navigate = useNavigate();

  return (
    <>
      <Wrapper.Title maxWidth="906px">
        <span className="judgmentFreeLayerTextWrapper">
          When you want{" "}
          <span className="judgmentFreeLayerText">judgment-free</span>
        </span>
        <br />
        support and a <strong>community of peers:</strong>
      </Wrapper.Title>
      <div className="judgmentFreeSupport">
        <div className="judgmentFreeSupportImgWrapper judgmentFreeSupportAlignToEnd judgmentFreeSupport-a">
          <div className="judgmentFreeSupportImgGroupWrapper">
            <img
              className="judgmentFreeSupportImgGroup"
              src={group}
              alt="group"
            />
            <img
              className="judgmentFreeSupportImgLayer"
              src={baby}
              alt="baby"
            />
          </div>
        </div>
        <div className="judgmentFreeSupportAlignToStart judgmentFreeSupport-b">
          <TextContent
            className="judgmentFreeSupportText"
            title="Moms Survival Groups™"
            description={
              <>
                From our signature Mom Survival Groups (MSGs) that cater to new,
                veteran, and working moms to our free breastfeeding (but really
                all kinds of feeding!) group, we’ve got you covered!
                <br />
                Come for the content, from our expert lead groups, and leave
                with a community of moms walking this motherhood journey
                alongside you.
              </>
            }
            buttonText="Learn More"
            onClick={() => {
              navigate(SUPPORTGROUP);
            }}
          />
        </div>
        <div className="judgmentFreeSupportAlignToEnd judgmentFreeSupport-c">
          <TextContent
            className="judgmentFreeSupportText"
            title="Sleep Consults"
            description={
              <>
                If you can’t sleep, you can’t think (well you can think, just
                not clearly!). And if your baby isn’t sleeping, then neither are
                you.
                <br />
                <br />
                Let us take the thought out of it for you, and work directly
                with our pediatric nurse, and expert in pediatric sleep, to
                formulate a plan to get you and your baby sleeping better!
              </>
            }
            buttonText="Learn More"
            buttonType="green"
            onClick={() => {
              navigate(SLEEPCONSULT);
            }}
          />
        </div>
        <div className="judgmentFreeSupportImgWrapper judgmentFreeSupportAlignToStart judgmentFreeSupport-d">
          <div className="judgmentFreeSupportImgSleepWrapper">
            <img
              className="judgmentFreeSupportImgSleep"
              src={sleep}
              alt="sleep"
            />
            <img className="judgmentFreeSupportImgLayer" src={hi} alt="hi" />
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceCard;
