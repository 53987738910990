import React from "react";

import CheckboxList from "../Components/CheckboxList";
import useSubmit from "../Hooks/useSubmit";
import DateField from "../Components/DateField";
import CongratsPopUp from "../../Common/CongratsPopUp";
import { CircularProgress } from "@mui/material";

const initFields = {
  dateOfVisit: "",
  timeOfVisit: "",
  RNFirstName: "",
  RNLastName: "",
  clientFirstName: "",
  clientLastName: "",
  clientDateOfBirth: "",
  pediatricianFirstName: "",
  pediatricianLastName: "",
  OBFirstName: "",
  OBLastName: "",
  deliveryType: "",
  patientConcerns: "",
  maternalPPDAssessmentScore: "",
  energyEmotionalStatus: "",
  pulse: "",
  bloodPressure: "",
  lowerExtremityAssessment: "",
  bleedingBladderBowelsBum: "",
  other: "",
  babyFirstName: "",
  babyLastName: "",
  babyDateOfBirth: "",
  birthWeight: "",
  newbornAppearanceColoring: "",
  heartAndLungs: "",
  oralFeedingAssessment: "",
  diaperCarePeesAndPoops: "",
  additionalNotesRelatedToVisit: "",
  recommendedProductsAmazonNaps: "",
};

const requiredFields = [
  "dateOfVisit",
  "RNFirstName",
  "RNLastName",
  "clientFirstName",
  "clientLastName",
  "clientDateOfBirth",
  "pediatricianFirstName",
  "pediatricianLastName",
  "babyDateOfBirth",
  "babyFirstName",
  "babyLastName",
  "OBFirstName",
  "OBLastName",
];

const DayOfDischarge = () => {
  const {
    onSubmit,
    submitState,
    successModal,
    fields,
    errors,
    handleChange,
    handleChangeDate,
    handleChangeCheckbox,
    checkboxFields,
  } = useSubmit({
    formName: "NAPS Postpartum Intake Form",
    requiredFields: [...requiredFields],
    initFields: initFields,
    initCheckboxFields: {
      answerToPPDQuestion10: [],
      preeclampsiaSymptomsScreening: [],
      perinium: [],
      incision: [],
      breastAssessment: [],
      teachingProvided: [],
      additionalOpportunitiesForLearningAndSupport: [],
    },
  });

  return (
    <div className="container">
      <CongratsPopUp
        closeModal={successModal.closeModal}
        isVisible={successModal.isVisible}
      >
        <div className="notesLandingPagesSubmitModalTitle">Success!</div>
      </CongratsPopUp>
      <h1 className="py-5">NAPS Postpartum Intake Form</h1>
      <form onSubmit={onSubmit}>
        <div className="col-lg-6 col-md-6 mb-3">
          <label>Date of Visit (required)</label>
          <DateField
            value={fields.dateOfVisit}
            onChange={handleChangeDate("dateOfVisit")}
          />
          <h4 className="error-msgtext">{errors.dateOfVisit}</h4>
        </div>

        <div className="form-group col-lg-12 col-md-12 mb-3">
          <label>Time of Visit</label>
          <input
            type="text"
            value={fields.timeOfVisit}
            name="timeOfVisit"
            onChange={handleChange}
            className="form-control"
          />
        </div>

        <label>RN Name (required)</label>
        <div className="row">
          <div className="form-group col-lg-6 col-md-6 mb-3">
            <label className="tinyLabel">First Name</label>
            <input
              type="text"
              value={fields.RNFirstName}
              name="RNFirstName"
              onChange={handleChange}
              className="form-control"
            />
            <h4 className="error-msgtext">{errors.RNFirstName}</h4>
          </div>
          <div className="form-group col-lg-6 col-md-6 mb-3">
            <label className="tinyLabel">Last Name</label>
            <input
              type="text"
              value={fields.RNLastName}
              name="RNLastName"
              onChange={handleChange}
              className="form-control"
            />
            <h4 className="error-msgtext">{errors.RNLastName}</h4>
          </div>
        </div>

        <label>Client Name (required)</label>
        <div className="row">
          <div className="form-group col-lg-6 col-md-6 mb-3">
            <label className="tinyLabel">First Name</label>
            <input
              type="text"
              value={fields.clientFirstName}
              name="clientFirstName"
              onChange={handleChange}
              className="form-control"
            />
            <h4 className="error-msgtext">{errors.clientFirstName}</h4>
          </div>
          <div className="form-group col-lg-6 col-md-6 mb-3">
            <label className="tinyLabel">Last Name</label>
            <input
              type="text"
              value={fields.clientLastName}
              name="clientLastName"
              onChange={handleChange}
              className="form-control"
            />
            <h4 className="error-msgtext">{errors.clientLastName}</h4>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 mb-3">
          <label>Client's Date of Birth: (required)</label>
          <DateField
            value={fields.clientDateOfBirth}
            onChange={handleChangeDate("clientDateOfBirth")}
          />
          <h4 className="error-msgtext">{errors.clientDateOfBirth}</h4>
        </div>

        <label>OB Name (required)</label>
        <div className="row">
          <div className="form-group col-lg-6 col-md-6 mb-3">
            <label className="tinyLabel">First Name</label>
            <input
              type="text"
              value={fields.OBFirstName}
              name="OBFirstName"
              onChange={handleChange}
              className="form-control"
            />
            <h4 className="error-msgtext">{errors.OBFirstName}</h4>
          </div>
          <div className="form-group col-lg-6 col-md-6 mb-3">
            <label className="tinyLabel">Last Name</label>
            <input
              type="text"
              value={fields.OBLastName}
              name="OBLastName"
              onChange={handleChange}
              className="form-control"
            />
            <h4 className="error-msgtext">{errors.OBLastName}</h4>
          </div>
        </div>

        <label>Pediatrician Name (required)</label>
        <div className="row">
          <div className="form-group col-lg-6 col-md-6 mb-3">
            <label className="tinyLabel">First Name</label>
            <input
              type="text"
              value={fields.pediatricianFirstName}
              name="pediatricianFirstName"
              onChange={handleChange}
              className="form-control"
            />
            <h4 className="error-msgtext">{errors.pediatricianFirstName}</h4>
          </div>
          <div className="form-group col-lg-6 col-md-6 mb-3">
            <label className="tinyLabel">Last Name</label>
            <input
              type="text"
              value={fields.pediatricianLastName}
              name="pediatricianLastName"
              onChange={handleChange}
              className="form-control"
            />
            <h4 className="error-msgtext">{errors.pediatricianLastName}</h4>
          </div>
        </div>

        <div className="form-group col-lg-12 col-md-12 mb-3">
          <label>Delivery Type:</label>
          <input
            type="text"
            value={fields.deliveryType}
            name="deliveryType"
            onChange={handleChange}
            className="form-control"
          />
          <h4 className="error-msgtext">{errors.deliveryType}</h4>
        </div>

        <div className="form-group col-lg-12 col-md-12 mb-3">
          <label>Patient Concerns:</label>
          <textarea
            value={fields.patientConcerns}
            name="patientConcerns"
            onChange={handleChange}
            className="form-control"
          />
        </div>

        <div className="form-group col-lg-12 col-md-12 mb-3">
          <label>Maternal PPD Assessment Score</label>
          <input
            type="text"
            value={fields.maternalPPDAssessmentScore}
            name="maternalPPDAssessmentScore"
            onChange={handleChange}
            className="form-control"
          />
        </div>

        <CheckboxList
          className="mb-3"
          mainLabel="Answer to PPD question #10"
          name="answerToPPDQuestion10"
          onChange={handleChangeCheckbox}
          value={checkboxFields.answerToPPDQuestion10}
          options={[
            "Patient denies suicidal thoughts",
            "Patient reports suicidal thoughts",
          ]}
        />

        <div className="form-group col-lg-12 col-md-12 mb-3">
          <label>Energy / Emotional Status:</label>
          <input
            type="text"
            value={fields.energyEmotionalStatus}
            name="energyEmotionalStatus"
            onChange={handleChange}
            className="form-control"
          />
        </div>

        <div className="form-group col-lg-12 col-md-12 mb-3">
          <label>Pulse:</label>
          <input
            type="text"
            value={fields.pulse}
            name="pulse"
            onChange={handleChange}
            className="form-control"
          />
        </div>

        <div className="form-group col-lg-12 col-md-12 mb-3">
          <label>Blood Pressure:</label>
          <input
            type="text"
            value={fields.bloodPressure}
            name="bloodPressure"
            onChange={handleChange}
            className="form-control"
          />
        </div>

        <CheckboxList
          className="mb-3"
          mainLabel="Preeclampsia Screening- ANY SEVERE SYMPTOMS NEED TO GO TO ER:"
          name="preeclampsiaSymptomsScreening"
          onChange={handleChangeCheckbox}
          value={checkboxFields.preeclampsiaSymptomsScreening}
          options={[
            "Severe headache",
            "Nausea/ Vomiting/ Stomach pain",
            "Swelling of hands and face",
            "Hepatic tenderness (upper right quadrant of abdomen)",
            "Shortness of breath",
            "Decreased urine output",
          ]}
        />

        <div className="form-group col-lg-12 col-md-12 mb-3">
          <label>Lower Extremity Assessment:</label>
          <input
            type="text"
            value={fields.lowerExtremityAssessment}
            name="lowerExtremityAssessment"
            onChange={handleChange}
            className="form-control"
          />
        </div>

        <div className="form-group col-lg-12 col-md-12 mb-3">
          <label>Bleeding, Bladder, Bowels, Bum:</label>
          <input
            type="text"
            value={fields.bleedingBladderBowelsBum}
            name="bleedingBladderBowelsBum"
            onChange={handleChange}
            className="form-control"
          />
        </div>

        <CheckboxList
          className="mb-3"
          mainLabel="Perinium"
          name="perinium"
          onChange={handleChangeCheckbox}
          value={checkboxFields.perinium}
          options={["Assessed", "Not Assessed"]}
        />

        <CheckboxList
          className="mb-3"
          mainLabel="Incision"
          name="incision"
          onChange={handleChangeCheckbox}
          value={checkboxFields.incision}
          options={["Clean", "Dry", "Intact"]}
        />

        <div className="form-group col-lg-12 col-md-12 mb-3">
          <label>Other:</label>
          <input
            type="text"
            value={fields.other}
            name="other"
            onChange={handleChange}
            className="form-control"
          />
        </div>

        <CheckboxList
          className="mb-3"
          mainLabel="Breast Assessment"
          name="breastAssessment"
          onChange={handleChangeCheckbox}
          value={checkboxFields.breastAssessment}
          options={[
            "cracked nipples",
            "nipple pain",
            "engorgement",
            "clogged ducts",
          ]}
        />

        <label>Baby's Name (required)</label>
        <div className="row">
          <div className="form-group col-lg-6 col-md-6 mb-3">
            <label className="tinyLabel">First Name</label>
            <input
              type="text"
              value={fields.babyFirstName}
              name="babyFirstName"
              onChange={handleChange}
              className="form-control"
            />
            <h4 className="error-msgtext">{errors.babyFirstName}</h4>
          </div>
          <div className="form-group col-lg-6 col-md-6 mb-3">
            <label className="tinyLabel">Last Name</label>
            <input
              type="text"
              value={fields.babyLastName}
              name="babyLastName"
              onChange={handleChange}
              className="form-control"
            />
            <h4 className="error-msgtext">{errors.babyLastName}</h4>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 mb-3">
          <label>Baby's Date of Birth (required)</label>
          <DateField
            value={fields.babyDateOfBirth}
            onChange={handleChangeDate("babyDateOfBirth")}
          />
          <h4 className="error-msgtext">{errors.babyDateOfBirth}</h4>
        </div>

        <div className="form-group col-lg-12 col-md-12 mb-3">
          <label>Birth Weight</label>
          <input
            type="text"
            value={fields.birthWeight}
            name="birthWeight"
            onChange={handleChange}
            className="form-control"
          />
        </div>

        <div className="form-group col-lg-12 col-md-12 mb-3">
          <label>Newborn Appearance (coloring/HEENT)</label>
          <input
            type="text"
            value={fields.newbornAppearanceColoring}
            name="newbornAppearanceColoring"
            onChange={handleChange}
            className="form-control"
          />
        </div>

        <div className="form-group col-lg-12 col-md-12 mb-3">
          <label>Heart and Lungs</label>
          <input
            type="text"
            value={fields.heartAndLungs}
            name="heartAndLungs"
            onChange={handleChange}
            className="form-control"
          />
        </div>

        <div className="form-group col-lg-12 col-md-12 mb-3">
          <label>Oral/Feeding Assessment</label>
          <input
            type="text"
            value={fields.oralFeedingAssessment}
            name="oralFeedingAssessment"
            onChange={handleChange}
            className="form-control"
          />
        </div>

        <div className="form-group col-lg-12 col-md-12 mb-3">
          <label>Diaper care/ pees & poops</label>
          <input
            type="text"
            value={fields.diaperCarePeesAndPoops}
            name="diaperCarePeesAndPoops"
            onChange={handleChange}
            className="form-control"
          />
        </div>

        <CheckboxList
          className="mb-3"
          mainLabel="Teaching provided"
          name="teachingProvided"
          onChange={handleChangeCheckbox}
          value={checkboxFields.teachingProvided}
          options={[
            "Safe storage of breastmilk (Rule of 4's: https://www.cdc.gov/breastfeeding/recommendations/handling_breastmilk.htm)",
            "Proper setup/use/cleaning of breast pump",
            "Use of nipple shield including how to wean",
            "Warning signs for baby and when to call pediatrician",
            "Warning signs for mom and when to call OB",
            "Paced bottle feeding",
            "5 s's (1. swaddle 2. side lying 3. swinging 4. shushing 5. sucking)",
            "Proper preparation of infant formula",
            "Other (see below)",
          ]}
        />

        <div className="form-group col-lg-12 col-md-12 mb-3">
          <label>Additional notes related to this visit:</label>
          <textarea
            value={fields.additionalNotesRelatedToVisit}
            name="additionalNotesRelatedToVisit"
            onChange={handleChange}
            className="form-control"
          />
        </div>

        <div className="form-group col-lg-12 col-md-12 mb-3">
          <label>
            Recommended Products: (https://www.amazon.com/shop/nurturebynaps)
          </label>
          <textarea
            value={fields.recommendedProductsAmazonNaps}
            name="recommendedProductsAmazonNaps"
            onChange={handleChange}
            className="form-control"
          />
        </div>

        <CheckboxList
          className="mb-3"
          mainLabel="Additional Opportunities for Learning & Support: (Here a few other resources available)"
          name="additionalOpportunitiesForLearningAndSupport"
          onChange={handleChangeCheckbox}
          value={checkboxFields.additionalOpportunitiesForLearningAndSupport}
          options={[
            "Lactation Consultation Request https://www.nurturebynaps.com/lactation-consult-request",
            "Free Breastfeeding Support Group: virtual every Wednesday at 10:30am. You can register here: https://www.nurturebynaps.com/free-breastfeeding-support-group",
            'Nurture by NAPS Free 14-day Trial: Ask any folllow up questions or just parening questions in general on our 24/7 "ask a nurse" message board following your visit. Link to your trial is here: boston-naps.teachable.com/p/nurture-by-naps-membership-program-2-week-trial',
            "Unswaddled: The Truth About Parenting Podcast. Listen here: https://podcasts.apple.com/us/podcast/unswaddled/id1560213381",
          ]}
        />

        <div className="d-flex justify-content-end">
          <button className="w-auto primary-blue-btn px-5 my-5 notesLandingPagesSubmitBtn">
            {submitState.pending ? <CircularProgress size={24} /> : "SUBMIT"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default DayOfDischarge;
