import React, { useEffect, useState } from 'react'
import { Container, Row, } from 'react-bootstrap';
import "./Footer.scss";
import Logo from "../../Assets/img/footer/footer-logo.svg"
import LogoPreLogin from "../../Assets/img/footer/logoPreLogin.svg"
import Facebook from "../../Assets/img/footer/Facebook.svg"
import Instagram from "../../Assets/img/footer/instagram.svg"
import Pintrest from "../../Assets/img/footer/pinterest.svg"
import { Link, useNavigate } from 'react-router-dom';
import * as path from "../../Routes/RouteConstent";
import { getDataFromLocalStorage } from '../../Service/getLocalStorage';
import useModalWindowControls from '../../Hooks/useModal';
import CongratsPopUp from '../CongratsPopUp';

const Footer = () => {
    const [isLogin, setIsLogin] = useState(false)

    const subscribedToNewsLettersModal = useModalWindowControls();

    const Navigate = useNavigate()

    const isUserLogin = () => {

        let user = getDataFromLocalStorage()
        if (!user) {
            setIsLogin(false)
        } else {
            setIsLogin(true)
        }
    }

    useEffect(() => {
        isUserLogin()
        window.addEventListener("storage", () => {
            isUserLogin()

        })
    }, [Navigate]);

    return (
        <Container fluid>
            <CongratsPopUp 
                closeModal={subscribedToNewsLettersModal.closeModal} 
                isVisible={subscribedToNewsLettersModal.isVisible}
            >
                <h3>Thank you!</h3>
                <p>You have successfully subscribed to our newsletter.</p>
            </CongratsPopUp>
            <div className={`footer ${!isLogin && "pre-login-ft"}`}>
                <Row>
                    <div className='col-lg-12 col-md-12'>
                        <div className="container g-0">
                            <div className="row">
                                <div className='d-flex justify-content-between footer-flex-container'>
                                    <div className='d-flex block block1 flex-column px-2 '>
                                        <div className="footer-logo mb-5">
                                            <img src={isLogin ? Logo : LogoPreLogin} alt="" />
                                        </div>
                                        <div className="social-icons d-inline-block">
                                            <a href='https://www.facebook.com/NurturebyNAPS/' target="_blank"> <img src={Facebook} alt="" className='rounded' /></a>
                                            <a href='https://www.instagram.com/nurturebynaps/' target="_blank"><img src={Instagram} alt="" /></a>
                                            <a href='https://www.pinterest.com/nurturebynaps/_created/' target="_blank"><img src={Pintrest} alt="" /></a>
                                        </div>
                                    </div>
                                    <div className='d-flex block block2 flex-column px-2'>
                                        <h4>Contact</h4>
                                        <p className="address">395 West Broadway Boston, MA 02127 hello@nurturebynaps.com (857) 496-5095</p>
                                        {/* </div> */}
                                    </div>
                                    <div className='d-flex block block3 flex-column px-2'>
                                        <h4>Explore</h4>
                                        <ul className='footerExplore'>
                                            <li onClick={() => Navigate(path.PODCASTQUESTION)}>Podcast Question Submission</li>
                                            <a style={{ "textDecoration": "none" }} href={`${process.env.REACT_APP_BASE_URL}blog/show`}><li >Blog</li></a>
                                            <li onClick={() => Navigate(path.SPONSORSHIP)}>partnerships/sponsorships</li>
                                            <li onClick={() => Navigate(path.CRPROGRAMS)}>corporate program</li>
                                            <li onClick={() => Navigate(path.OURSTORY)}>Our Story</li>
                                            <li onClick={() => Navigate(path.GIFTCARD)}>Gift cards</li>
                                            <li onClick={() => Navigate(path.CONTACT)}>Contact Us </li>
                                            <li><a href="https://cloud.mail.nurturebynaps.com/nursehire">Careers</a></li>
                                        </ul>

                                    </div>
                                    <div className='d-flex block block4 px-2'>
                                        <div className="stay-loop">
                                            <a href="https://cloud.mail.nurturebynaps.com/newsletter">
                                                <button className="btn w-auto px-4 ms-0 ms-md-2">Stay in the Loop</button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr className="ft-hr"></hr>
                            <div className='row bottom'>
                                <div className='d-flex justify-content-between footer-inner'>
                                    <div className='rigths'>
                                        <p className="bottomm">Nurture by NAPS 2023 @   All rights reserved.</p>
                                    </div>
                                    <div className='d-flex'>
                                        <div><p className="privacy-policy policy me-4"><Link to={path.PRIVACY}>Privacy Policy </Link></p></div>
                                        <div><p className="privacy-policy policy"><Link to={path.TERMS}>Terms and Conditions</Link></p></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Row>
            </div>
        </Container>
    )
}

export default Footer
