import React from "react";
import Logo from "./Logo";
import "./wrapper.scss";
import Footer from "../../../Common/Footer/Footer";
import { Link } from "react-router-dom";
import { MEMBERSHIP_PRE_LOGIN } from "../../../Routes/RouteConstent";
import { useSelector } from "react-redux";
import { isLoggedInSelector } from "../../../Redux/user/user";

interface Props {
  children: JSX.Element | string | JSX.Element[];
}

const Wrapper = ({ children }: Props) => {
  return <div className="brightHorizonsWrapper">{children}</div>;
};

const BackgroundWrapper = ({ children }: Props) => {
  const isLoggedIn = useSelector(isLoggedInSelector);

  return (
    <div className="brightHorizonsMainWrapper">
      {children}

      <div
        className="brightHorizonsWrapperFooterContainer"
      >
        <div className={`brightHorizonsWrapperFooter ${
          isLoggedIn && "brightHorizonsWrapperFooterLoggedIn"
        }`}>
          <Footer />
        </div>
      </div>
    </div>
  );
};
Wrapper.BackgroundWrapper = BackgroundWrapper;

Wrapper.Header = () => (
  <header className="brightHorizonsHeader">
    <div className="brightHorizonsLogo">
      <Link className="brightHorizonsLogo__logo" to="/">
        <Logo />
      </Link>
      <h3 className="brightHorizonsLogo__title">
        Newborn &<br /> Parenting Support
      </h3>
    </div>
    <Link
      className="btn primary-blue-small-btn-40 brightHorizonsHeader__btn"
      to={MEMBERSHIP_PRE_LOGIN}
    >
      Become a Member
    </Link>
  </header>
);

Wrapper.Title = ({
  children,
  maxWidth,
  className,
}: {
  children: React.ReactChild | React.ReactChild[];
  maxWidth?: string;
  className?: string;
}) => (
  <div
    className={`brightHorizonsWrapperTitle ${className}`}
    style={{ maxWidth }}
  >
    {children}
  </div>
);

export default Wrapper;
