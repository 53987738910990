import React from "react";
import "./SendGuideForm.scss";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { CircularProgress, Stack, TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { emailRegex } from "../../../Utils/constant";
import * as asyncState from "../../../Utils/asyncState";
import httpService from "../../../Service/httpService";
import useModalWindowControls from "../../../Hooks/useModal";
import CongratsPopUp from "../../../Common/CongratsPopUp";
import { toast, ToastContainer } from "react-toastify";

const guideRequest = async (data: any) => {
  return httpService.post("form-landing-guide", data);
};

interface Props {
  title?: React.ReactChild | React.ReactChild[] | string;
  description: React.ReactChild | React.ReactChild[] | string;
  event: string;
}

const SendGuideForm = ({
  event,
  title = "What’s Inside?",
  description,
}: Props) => {
  const [requestState, setRequestState] = React.useState(asyncState.initState);
  const successModal = useModalWindowControls();

  const [fields, setFields] = React.useState({
    due_date: "",
    firstName: "",
    lastName: "",
    email: "",
  });

  const [errors, setErrors] = React.useState({
    due_date: "",
    firstName: "",
    lastName: "",
    email: "",
  });

  const updateFields =
    (name: string) => (v: React.ChangeEvent<HTMLInputElement>) =>
      setFields((f) => ({ ...f, [name]: v?.target?.value }));

  const updateDueDate = (date: any) =>
    setFields((f) => ({ ...f, due_date: date }));

  const submitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const { due_date, firstName, lastName, email } = fields;
    const nextErrors = { ...errors };
    nextErrors.due_date = due_date ? "" : "Due Date is required.";
    nextErrors.firstName = firstName ? "" : "First name is required.";
    nextErrors.lastName = lastName ? "" : "Last name is required.";

    const isEmailValid = emailRegex.test(email) ? "" : "Enter valid Email.";
    nextErrors.email = email ? isEmailValid : "Email fis required.";

    setErrors(nextErrors);

    if (!Object.values(nextErrors).some((v) => v)) {
      setRequestState(asyncState.initState);

      try {
        await guideRequest({
          "First Name": firstName,
          "Last Name": lastName,
          "Email Address": email,
          "DOB/ Estimated Delivery Date": due_date,
          event,
        });

        setRequestState(asyncState.successState);
        setFields({
          due_date: "",
          firstName: "",
          lastName: "",
          email: "",
        });
        successModal.openModal();
      } catch (err: any) {
        toast.error(err.message || 'Something went wrong...');
        setRequestState({
          ...asyncState.failedState,
          failedMessage: err.message,
        });
      }
    }
  };

  return (
    <div className="SendGuide">
      <ToastContainer />
      <CongratsPopUp
        isVisible={successModal.isVisible}
        closeModal={successModal.closeModal}
      >
        <h2 style={{ color: "#fff" }}>Success</h2>
      </CongratsPopUp>
      <div className="SendGuideText">
        <div className="SendGuideText__title">{title}</div>
        {description}
      </div>

      <div className="SendGuideForm">
        <div className="SendGuideForm__title">Send me the guide!</div>
        <form onSubmit={submitHandler}>
          <div className="">
            <label>First name*</label>
            <input
              type="text"
              value={fields.firstName}
              name="firstName"
              onChange={updateFields("firstName")}
              className="SendGuideForm__field"
            />
            <div className="SendGuideForm__error">{errors.firstName}</div>
          </div>

          <div className="">
            <label>Last name*</label>
            <input
              type="text"
              value={fields.lastName}
              name="lastName"
              onChange={updateFields("lastName")}
              className="SendGuideForm__field"
            />
            <div className="SendGuideForm__error">{errors.lastName}</div>
          </div>

          <div className="">
            <label>Email*</label>
            <input
              type="text"
              value={fields.email}
              name="email"
              onChange={updateFields("email")}
              className="SendGuideForm__field"
            />
            <div className="SendGuideForm__error">{errors.email}</div>
          </div>

          <div>
            <label>Due Date / Child’s Birthday*</label>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Stack spacing={3}>
                <MobileDatePicker
                  toolbarTitle="Choose date"
                  value={fields.due_date}
                  onChange={updateDueDate}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      className="due-date-field"
                      value={fields.due_date}
                    />
                  )}
                />
              </Stack>
            </LocalizationProvider>
            <div className="SendGuideForm__error">{errors.due_date}</div>
          </div>

          <button
            disabled={requestState.pending}
            className="SendGuideForm__button"
          >
            {requestState.pending ? <CircularProgress size={30} /> : "Submit"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default SendGuideForm;
