import React from "react";
import "./LandingDescription.scss";

const LandingDescription = ({
  ySpace,
  content,
}: {
  ySpace?: boolean;
  content: (React.ReactNode | String)[];
}) => {
  return (
    <div
      className={`landingDescriptionMain ${
        ySpace ? "landingDescriptionMainYSpace" : ""
      }`}
    >
      {content.map((text) => (
        <p className="landingDescriptionParagraph">{text}</p>
      ))}
    </div>
  );
};

export default LandingDescription;
