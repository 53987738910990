import React from 'react'
import FilterSearch from '../Events/FilterSearch'
import Header from './Header/Header'
import "./MemberChat.scss"

const MemberChat = () => {
  return (
    <div>
      <Header />
      <FilterSearch />
    </div>
  )
}

export default MemberChat