import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { EDITPROFILE } from '../../../Routes/RouteConstent';
import { classNames } from 'react-easy-crop/helpers';


function StepperMenu(props: any) {
    let { activeStep, setActiveStep, setSubStep, setSubStep1, subStep, subStep1, subStep2, setSubStep2, className } = props
    // const [activeStep, setActiveStep] = React.useState(0);
    let user: any = localStorage.getItem('Nurture_user_data')
    user = JSON.parse(user)
    const Navigate = useNavigate();

    const manageRoute = (val: number | string) => {
        if (val === "promotion") {
            Navigate(`${EDITPROFILE}?stp=${val}&sub=1`)
        } else {
            Navigate(`${EDITPROFILE}?stp=${val}&sub=0`)

        }
    }
    const manageSubRoute = (val: number) => {
        Navigate(`${EDITPROFILE}?stp=${activeStep}&sub=${val}`)
    }

    const handleNext = (value: any) => {
        manageRoute(value)
        setActiveStep(value);
    }

    const handleEditFun = (value: number) => {
        manageSubRoute(value)
        setSubStep(value)
    }

    const handleBillingFun = (value: number) => {
        manageSubRoute(value)
        setSubStep1(value)

    }

    const handlePromoFun = (value: number) => {
        manageSubRoute(value)
        setSubStep2(value)
    }

    return (
        <>
            <div className={className ? className : 'col-lg-3 col-md-3'}>
                <ul className="list-unstyled ps-0 sidebar-nav edit-profile">
                    <li className="mb-1">
                        <button className={`btn btn-toggle align-items-center rounded border-0 ${activeStep !== "edit" && 'collapsed'}`} data-bs-toggle="collapse" data-bs-target="#edit-profile" aria-expanded="true" onClick={() => handleNext('edit')} >
                            Edit Profile
                        </button>
                        <div className={`collapse ${activeStep === "edit" && 'show'} collapsible-nav`} id="edit-profile">
                            <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                                <li onClick={() => handleEditFun(0)}><Link to={''} className={`${subStep == 0 && "active"}`}>About you</Link></li>
                                {user?.is_stripe_membership ? <>
                                    <li onClick={() => handleEditFun(1)}><Link to={''} className={`${subStep == 1 && "active"}`}>About your Baby</Link></li>
                                    {user?.current_life_status !== "I’m Single" &&
                                        <li onClick={() => handleEditFun(2)}><Link to={''} className={`${subStep == 2 && "active"} link-dark rounded`}>About your Partner</Link></li>}
                                </>
                                    : <></>
                                }
                                <li onClick={() => handleEditFun(3)}><Link to={''} className={`${subStep == 3 && "active"} link-dark rounded`}>Account Settings</Link></li>
                            </ul>
                        </div>
                    </li>
                    <li className="mb-1">
                        <button className={`btn btn-toggle align-items-center rounded border-0 ${activeStep !== "billing" && 'collapsed'}`} data-bs-toggle="collapse" data-bs-target="#billing-nav" aria-expanded="false" onClick={() => handleNext('billing')} >
                            Billing
                        </button>
                        <div className={`collapse ${activeStep === "billing" && 'show'} collapsible-nav`} id="billing-nav">
                            <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                                <li onClick={() => handleBillingFun(0)}><Link to={''} className={`${subStep1 == 0 && "active"} link-dark rounded`}>Your Membership</Link></li>
                                <li onClick={() => handleBillingFun(1)}><Link to={''} className={`${subStep1 == 1 && "active"} link-dark rounded`}>Payment</Link></li>
                            </ul>
                        </div>
                    </li>
                    <li className="mb-1">
                        <button className={`btn btn-toggle align-items-center rounded border-0 ${activeStep !== "promotion" && 'collapsed'}`} data-bs-toggle="collapse" data-bs-target="#promotion-nav" aria-expanded="false" onClick={() => handleNext('promotion')} >
                            Promotional
                        </button>
                        <div className={`collapse ${activeStep === "promotion" && 'show'} collapsible-nav`} id="promotion-nav">
                            <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                                {/* <li onClick={() => handlePromoFun(0)}><Link to={''} className={`${subStep2 == 0 && "active"} link-dark rounded`}>Earn Consult</Link></li> */}
                                <li onClick={() => handlePromoFun(1)}><Link to={''} className={`${subStep2 == 1 && "active"} link-dark rounded`}>Invite Friend</Link></li>
                                <li onClick={() => handlePromoFun(2)}><Link to={''} className={`${subStep2 == 2 && "active"} link-dark rounded`}>My offers</Link></li>
                            </ul>
                        </div>
                    </li>


                </ul>
            </div>
        </>


    )
}

export default StepperMenu