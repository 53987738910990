import React, { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import gift_Sicon from "../../../Assets/img/service/gift-icon.svg";
import lavender_gift_icon from "../../../Assets/img/service/lavender-gift-icon.svg";
import add_more_icon from "../../../Assets/img/service/add-con.svg";
import "../service.scss";
import serviceData from "../../../Service/serviceData";
import parse from "html-react-parser";
import {
  getDataFromLocalStorage,
  getUser,
} from "../../../Service/getLocalStorage";
import { useNavigate } from "react-router-dom";
import { getConsultRoute } from "../../../Utils/ConsultRoute";
import {
  GIFTCARD,
  MEMBERSHIP,
  MEMBERSHIP_PRE_LOGIN,
} from "../../../Routes/RouteConstent";
import Popup from "../../Membership/Popup";
import LockScreen from "../../../Common/LockScreen/LockScreen";
import StreetCred from "../../../Common/streetCred/streetCred";
import MemberPopUp from "../../../Utils/Popup/MemberPopUp";
import useModalWindowControls from "../../../Hooks/useModal";
import useWindowDimensions from "../../../Hooks/useViewWidth";
import ModalWindow from "../../../Common/ModalWindow";
import { usePostHog } from "posthog-js/react";
import Accordion from "../../../Common/Accordion/Accordion";

const NurseConsult30 = ({ service }: { service: string }) => {
  const [data, setData] = useState<any>([]);
  const [isPopup, setIsPopup] = useState(true);
  const [route, setRoute] = useState("");
  const userData = getDataFromLocalStorage();
  const mobile = useWindowDimensions(767.99);
  const posthog = usePostHog();

  const Navigate = useNavigate();
  const updateData = () => {
    let data = serviceData.find((aData: any) => aData?.url === service);
    if (!data) {
      setData(serviceData[0]);
    } else {
      setData(data);
    }
    console.log(data);
  };
  const linkedPage = () => {
    setTimeout(() => {
      let getLinks: any = document.getElementsByClassName("link");

      for (let i = 0; i < getLinks.length; i++) {
        getLinks[i].addEventListener("click", (linkata: any) => {
          let link = linkata.currentTarget.attributes.data.value;
          Navigate(link);
        });
      }
    }, 1000);
  };
  useEffect(() => {
    updateData();
    linkedPage();
  }, [service]);

  useEffect(() => {
    if (data?.posthogEvent) {
      console.log(`[service-page]:${data?.posthogEvent}`);
      posthog.capture(`[service-page]:${data?.posthogEvent}`, {
        $set: { "last-service-page": data?.posthogEvent },
      });
    }
  }, [data, posthog]);

  const NavigateToConsult = (param?: any, service?: string) => {
    if (param) {
      setRoute(param);
    } else {
      let pathName: any = getConsultRoute(service ?? data?.service);

      setRoute(pathName);
    }
    if (userData?.id && userData?.is_email_verify != 0) {
      let pathName = getConsultRoute(service ?? data?.service);

      if (!pathName) return;
      if (param) {
        Navigate(param);
      } else {
        Navigate(`${pathName}?service=${service ?? data?.service}`, {
          state: { param: service ?? data?.service },
        });
      }
    } else {
      setIsPopup(false);
    }
  };
  const handleCalBack = (param: string) => {
    if (param === "navigate") {
      if (!route) return;

      Navigate(`${route}?service=${data?.service}`, {
        state: { param: data?.service },
      });
    }
  };
  const user = getUser();
  const memberPopUp = useModalWindowControls();
  const includedForMembersPopUp = useModalWindowControls();

  const checkMemebrship = (cb: any) => {
    user?.is_stripe_membership ? cb() : memberPopUp.openModal();
  };

  const openService = () => {
    if (data?.forMembers) {
      checkMemebrship(() => NavigateToConsult(data?.privateClass?.url));
      return;
    }

    if (data?.privateClass?.outsideUrl) {
      window.open(data?.privateClass?.outsideUrl);
      return;
    }

    NavigateToConsult(data?.privateClass?.url);
  };

  const [isSignUp, setSignUp] = useState(true);
  const handleSignUpCalBack = () => {
    Navigate(`${MEMBERSHIP}/2`);
  };
  const openMembershipPage = () => {
    const userData = getDataFromLocalStorage();

    if (userData && userData?.is_email_verify != 0) {
      Navigate(`${MEMBERSHIP}/2`);
    } else {
      setSignUp(false);
    }
  };

  return (
    <div className="service-container">
      <Popup
        isPopup={isPopup}
        setIsPopup={setIsPopup}
        callBack={handleCalBack}
        signUpFirst
      />
      <Popup
        isPopup={isSignUp}
        setIsPopup={setSignUp}
        callBack={handleSignUpCalBack}
        signUpFirst
      />
      <MemberPopUp
        closeModal={memberPopUp.closeModal}
        isVisible={memberPopUp.isVisible}
      />
      <ModalWindow
        isVisible={includedForMembersPopUp.isVisible}
        closeModal={includedForMembersPopUp.closeModal}
      >
        <p className="pt-3 pb-2">
          This service is included in the annual membership, would you like to
          become a member and get this service for free?
        </p>

        <div className="serviceIncludedInMembershipModal__buttonsWrapper">
          <button
            className="btn primary-blue-small-btn-40 serviceIncludedInMembershipModal__refuseBtn"
            onClick={() => {
              if (includedForMembersPopUp.openModalData) {
                NavigateToConsult(
                  includedForMembersPopUp.openModalData.url,
                  includedForMembersPopUp.openModalData.service
                );
              } else {
                openService();
              }
            }}
          >
            No, continue
          </button>
          <button
            className="btn primary-blue-small-btn-40"
            onClick={() => Navigate(`${MEMBERSHIP}/2`)}
          >
            Become a member
          </button>
        </div>
      </ModalWindow>

      <Container fluid>
        <Row className="service-header">
          <div className="col-lg-12 col-md-12 ">
            <div className="row service-header-container position-relative g-0">
              <div className="col-lg-12 col-md-12 srv-col">
                <Container fluid className="g-0">
                  <Row className="justify-content-start align-items-center">
                    <div className="col-lg-7 col-md-12 service-caption-container order-last order-lg-first">
                      <div className="d-flex flex-column service-content px-3 px-md-3 px-lg-3 py-3 py-md-3 py-lg-3 py-xl-3 ms-0 ms-md-3">
                        <h1 className="service-name text-start mt-3 mb-1">
                          {data?.tittle}
                        </h1>
                        {data?.taught && (
                          <h3 className="survice-subtitle">
                            Taught by {data?.taught}
                          </h3>
                        )}
                        {data?.subtitle && (
                          <h3 className="survice-subtitle">{data?.subtitle}</h3>
                        )}
                        <div className="service-description">
                          <div className="service-information">
                            {data?.cDuration && (
                              <>
                                <div className="info-left">
                                  <span className="pri-text ">Duration:</span>
                                </div>
                                <div> {data?.cDuration}</div>
                              </>
                            )}
                            {data?.classType && (
                              <>
                                <div className="info-left">
                                  <span className="pri-text ">Type:</span>
                                </div>
                                <div>{data?.classType}</div>
                              </>
                            )}
                            {data?.points?.map(
                              ({
                                title,
                                description,
                              }: {
                                title: string;
                                description: string;
                              }) => (
                                <React.Fragment key={`${description}${title}`}>
                                  <div className="info-left">
                                    <span className="pri-text ">{title}</span>
                                  </div>
                                  <div>{description}</div>
                                </React.Fragment>
                              )
                            )}
                          </div>
                          {data?.topicsDescription && (
                            <div className="pri-text my-1">
                              {data?.topicsDescription}
                            </div>
                          )}
                          <div className="service-information">
                            <div className="info-left">
                              <span className="pri-text ">
                                {!data?.topicsDescription &&
                                  (data?.topicsTitle
                                    ? data?.topicsTitle
                                    : "Topics covered:")}
                              </span>
                            </div>
                            <div>
                              <ul className="list-style">
                                {data?.topics &&
                                  data?.topics.map((li: any) => (
                                    <>
                                      {typeof li !== "object" ? (
                                        <li> {li}</li>
                                      ) : (
                                        <ul>
                                          {" "}
                                          {li.map((rli: any) => (
                                            <li>{rli}</li>
                                          ))}
                                        </ul>
                                      )}
                                    </>
                                  ))}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-5 col-md-12 order-first order-md-first service-banner-img-wrapper">
                      <div className="service-banner-img">
                        <img
                          src={
                            data.mobileImage && mobile.isSmaller
                              ? data?.mobileImage
                              : data.image
                          }
                          alt="service"
                        />
                      </div>
                    </div>
                  </Row>
                </Container>
              </div>
            </div>
          </div>
        </Row>
      </Container>
      <Container>
        <Row className="consult-more-info mt-5 mb-4">
          <div className="col-lg-6 col-md-12">
            <div className="pe-0 pe-md-3">
              {data?.description && (
                <div className="course-description-main mb-4">
                  <h3 className="course-dec-head"> Course Description: </h3>
                  <p>{data?.description}</p>
                </div>
              )}
            </div>
            <>{parse(String(data?.duration))}</> 
          </div>
          <div className="col-lg-6 col-md-12">
            {data?.groupButtons?.map(
              ({
                url,
                text,
                sendGift,
                giftUrl,
                price,
                service,
                includedInMembership,
                publicRoute,
              }: {
                url: string;
                giftUrl?: string;
                text: string;
                sendGift: any;
                price: "string";
                service?: string;
                includedInMembership?: boolean;
                publicRoute?: boolean;
              }) => (
                <div className="service-btn-sec-container service-btn-pri-container w-100 mb-3 mt-5 mt-md-3">
                  <div className="d-flex flex-column flex-md-row justify-content-start align-items-center service-btn-sec-container-inner-flex">
                    <div className="service-btn-container service-primary-container d-flex">
                      <div
                        className="service-btn d-flex align-items-center justify-content-between"
                        onClick={() => {
                          if (
                            includedInMembership &&
                            user &&
                            !user?.is_stripe_membership
                          ) {
                            includedForMembersPopUp.openModal({
                              url,
                              service,
                            });
                          } else {
                            publicRoute
                              ? Navigate(url)
                              : NavigateToConsult(url, service);
                          }
                        }}
                      >
                        <div>{text}</div>
                        {price && (
                          <div className="consult-price d-flex align-items-center">
                            ${price}
                          </div>
                        )}
                      </div>
                    </div>
                    {sendGift && (
                      <div className="btn-container-right d-flex p-3 align-items-center">
                        <div className="me-2">
                          <img src={gift_Sicon} />
                        </div>
                        <div
                          className="gift-caption"
                          onClick={() => Navigate(giftUrl ?? GIFTCARD)}
                        >
                          Send as Gift
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )
            )}

            <div className="service-btn-sec-container service-btn-pri-container w-100 mb-3 mt-5 mt-md-0">
              <div className="d-flex flex-column flex-md-row justify-content-start align-items-center service-btn-sec-container-inner-flex">
                <div className="service-btn-container service-secondary-continer d-flex">
                  <div
                    className="service-btn d-flex align-items-center justify-content-between"
                    onClick={
                      data.includedInMembership &&
                      user &&
                      !user?.is_stripe_membership
                        ? includedForMembersPopUp.openModal
                        : openService
                    }
                  >
                    <div>
                      {" "}
                      {data?.privateClass
                        ? data?.privateClass?.text
                        : "Book Class"}
                    </div>
                    {data?.price && (
                      <div className="consult-price d-flex align-items-center">
                        <span></span>${data?.price}
                      </div>
                    )}
                    {data?.free && (
                      <div className="consult-price d-flex align-items-center">
                        <span></span>free
                      </div>
                    )}
                  </div>
                </div>
                <div className="btn-container-right d-flex p-3 align-items-center">
                  <div className="me-2">
                    <img src={lavender_gift_icon} />
                  </div>
                  <div
                    className="gift-caption"
                    onClick={() => Navigate(GIFTCARD)}
                  >
                    Send as Gift
                  </div>
                </div>
              </div>
            </div>

            {(!userData?.is_stripe_membership || !userData) && (
              <div className="service-btn-ter-container service-btn-sec-container  w-100 mb-3">
                <div className="d-flex flex-column flex-md-row justify-content-start align-items-center service-btn-sec-container-inner-flex">
                  <div className="service-btn-container service-tertiary-continer d-flex">
                    <div
                      className="service-btn d-flex align-items-center justify-content-between"
                      onClick={openMembershipPage}
                    >
                      <div> Become a Member </div>
                      <div className="consult-price d-flex align-items-center">
                        <div>
                          <span className="small me-2 d-md-block d-lg-block">
                            from
                          </span>
                        </div>
                        <div>$83</div>
                      </div>
                    </div>
                  </div>
                  <div className="btn-container-right d-flex p-3 align-items-center">
                    <div className="me-2">
                      <img src={add_more_icon} />
                    </div>
                    <div
                      className="gift-caption"
                      onClick={() => {
                        Navigate(MEMBERSHIP_PRE_LOGIN);
                      }}
                    >
                      Learn More{" "}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </Row>
      </Container>
      <Container>
        <Row className="mb-4 px-3 accordionSection">
          {data.accordion?.map(
            (
              {
                title,
                description,
              }: {
                title: string;
                description: string;
              },
              i: number
            ) => (
              <Accordion
                title={title}
                description={description ? parse(String(description)) : ""}
                bottomBorder={i !== data.accordion.length - 1}
              />
            )
          )}
        </Row>
      </Container>
      <Container fluid>
        {/* <Row> */}
        {data?.testimonial && <StreetCred data={data && data?.testimonial} />}
        {/* </Row> */}
      </Container>
      <Container>
        <Row>
          {(!userData?.is_stripe_membership || !userData) && <LockScreen />}
        </Row>
      </Container>
    </div>
  );
};

export default NurseConsult30;
