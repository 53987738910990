export type AsyncState = {
  failed: boolean;
  failedMessage: string;
  pending: boolean;
  success: boolean;
}

export const failedState: AsyncState = {
  failed: true,
  failedMessage: "",
  pending: false,
  success: false,
};
export const successState: AsyncState = {
  failed: false,
  failedMessage: "",
  pending: false,
  success: true,
};
export const pendingState: AsyncState = {
  failed: false,
  failedMessage: "",
  pending: true,
  success: false,
};
export const initState: AsyncState = {
  failed: false,
  failedMessage: "",
  pending: false,
  success: false,
};

export const getDidNotAnyRequests = (data: AsyncState) =>
  !data.success && !data.failed && !data.pending;
