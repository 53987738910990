import React from 'react';
import "./quickScreen.scss";
import quick_ask from '../../Assets/img/quick/quick-Ask-a-nurse.jpg';
import quick_profile from '../../Assets/img/quick/Quick-profile.jpg';
import quick_levent from '../../Assets/img/quick/qucik-live-event.jpg';
import quick_course from '../../Assets/img/quick/quick-course.jpg';

const QuickScreen = () => {
    return (
        <div>
             <a className="btn btn-primary" data-bs-toggle="modal" href="#exampleModalToggle" role="button">Open first modal</a>
            <div className="modal fade quick-modal" id="exampleModalToggle" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" >
                <div className="modal-dialog modal-dialog-centered quick-ask-modal">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="container-fluid g-0">
                                <div className="row align-items-center">
                                    <div className="col-md-6 text-center text-md-start order-1 order-md-0 mt-5 mt-md-0 mb-3 mb-md-0">
                                        <div className='quick-caption-block ms-0 ms-md-3 px-3 px-md-0'>
                                            <h1 className='quick-title text-center text-md-start p-0 m-0'>Ask A Nurse</h1>
                                            <p className='mt-2'>To get answers to your questions, from feeding, sleeping, milestones and more from our NAPS Registered Nurses (and founders) within 24 hours! </p>
                                            <button className="btn btn-primary primary-blue-small-btn-40 mt-2" data-bs-target="#exampleModalToggle2" data-bs-toggle="modal" data-bs-dismiss="modal">Next</button>
                                        </div>
                                    </div>
                                    <div className="col-md-6 img-container img-right-container order-0 order-md-1">
                                        <div className='quick-img-main position-relative'>
                                            <img src={quick_ask} className='img-fluid' ></img>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade quick-modal" id="exampleModalToggle2" aria-hidden="true" aria-labelledby="exampleModalToggleLabel2" >
                <div className="modal-dialog modal-dialog-centered quick-profile-modal">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="container-fluid g-0">
                                <div className="row align-items-center">
                                    <div className="col-md-6 img-container img-left-container">
                                        <div className='quick-img-main position-relative'>
                                            <img src={quick_ask} className='img-fluid' ></img>
                                        </div>
                                    </div>
                                    <div className="col-md-6 text-center text-md-start mt-5 mt-md-0 mb-3 mb-md-0">
                                        <div className='quick-caption-block ms-0 ms-md-1 px-3 px-md-0'>
                                            <h1 className='quick-title text-center text-md-start p-0 m-0'>Profile</h1>
                                            <p className='mt-2'>Go to update settings, see your past and upcoming appointments, and anything you've favored.  </p>
                                            <button className="btn btn-primary primary-blue-small-btn-40 mt-2" data-bs-target="#exampleModalToggle3" data-bs-toggle="modal" data-bs-dismiss="modal">Next</button>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="modal-footer">
                    <button className="btn btn-primary" data-bs-target="#exampleModalToggle3" data-bs-toggle="modal" data-bs-dismiss="modal">Back to first</button>
                </div> */}
            </div>

            <div className="modal fade quick-modal" id="exampleModalToggle3" aria-hidden="true" aria-labelledby="exampleModalToggleLabel3" >
                <div className="modal-dialog modal-dialog-centered quick-course-modal">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="container-fluid g-0">
                                <div className="row align-items-center">
                                    <div className="col-md-6 text-center text-md-start order-1 order-md-0 mt-5 mt-md-0 mb-3 mb-md-0">
                                        <div className='quick-caption-block ms-0 ms-md-1'>
                                            <h1 className='quick-title text-center text-md-start p-0 m-0'>Courses</h1>
                                            <p className='mt-2'>Go to update settings, see your past and upcoming appointments, and anything you've favored.  </p>
                                            <button className="btn btn-primary primary-blue-small-btn-40 mt-2" data-bs-target="#exampleModalToggle4" data-bs-toggle="modal" data-bs-dismiss="modal">Next</button>
                                        </div>
                                    </div>
                                    <div className="col-md-6 img-container img-right-container order-0 order-md-1">
                                        <div className='quick-img-main position-relative'>
                                            <img src={quick_ask} className='img-fluid' ></img>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           
            <div className="modal fade quick-modal" id="exampleModalToggle4" aria-hidden="true" aria-labelledby="exampleModalToggleLabel4" >
                <div className="modal-dialog modal-dialog-centered quick-event-modal">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="container-fluid g-0">
                                <div className="row align-items-center">
                                    <div className="col-md-6 img-container img-left-container">
                                        <div className='quick-img-main position-relative'>
                                            <img src={quick_ask} className='img-fluid' ></img>
                                        </div>
                                    </div>
                                    <div className="col-md-6 text-start mt-5 mt-md-0 mb-3 mb-md-0">
                                        <div className='quick-caption-block text-center text-md-start ms-0 ms-md-1 px-5 px-md-0'>
                                            <h1 className='quick-title text-center text-md-start p-0 m-0'>Live events</h1>
                                            <p className='mt-2'>Our upcoming live events that you want to mark your calendar for!   </p>
                                            <button className="btn btn-primary primary-blue-small-btn-40 mt-2" data-bs-target="#exampleModalToggle1" data-bs-toggle="modal" data-bs-dismiss="modal">Next</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default QuickScreen;