import axios from "axios";


const Axios = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL + "api/"
})

Axios.interceptors.request.use(
    (config) => {
        const Token = localStorage.getItem("Nurture_user_token")
        let TokenType: any = localStorage.getItem("Nurture_user_data");
        TokenType = JSON.parse(TokenType)?.token_type ?? "Bearer"
        if (Token) {
            config.
                headers = {
                Authorization: `${TokenType} ${Token}`,
                Accept: "application/json",
            }
        }
        return config;
    }
)

Axios.interceptors.response.use(
    r => r,
    (error) => {
        if (error?.response?.status === 401 && localStorage.getItem("Nurture_user_token")) {
            window.dispatchEvent(new Event("userLogOut"));
        }
        
        return error;
    }
);

export default Axios;