import { CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify'
import { BUYCOURSE, COURSEVIEW } from '../../Routes/RouteConstent';
import { getCoupenData } from '../../Service/membership';
import "./membershipDetail.scss";
import parse from 'html-react-parser';
import ReceivedGiftCards from '../../Modules/ReceivedGiftCards';
import * as asyncStates from "../../Utils/asyncState";
import { postBuyCourse } from '../StripeForms/paymentsAPI';
import { purchaseEvent } from '../../Utils/gtm';
import { getUserID } from '../../Service/getLocalStorage';
import { usePostHog } from 'posthog-js/react';
import { grinEvent } from '../../Utils/grin';
import { formatSlug } from '../../Utils/Regex';


const MembershipDetail = ({ data, handleNext, loading, setLoading, type }: any) => {
    let tax = 0;
    let Navigate = useNavigate()
    const [coupon, setCoupon] = useState<string>("")
    const [couponLoading, setCouponLoading] = useState(false)
    const [couponSaving, setCouponSaving] = useState<any>(null)
     let [calculationTax, setCalculationTax] = useState<number>(data?.amount ? Number(data?.amount) * tax / 100 : 0)
    const [finalAmt, setFinalAmt] = useState(0)
    const [couponId, setCouponId] = useState<any>(null)
    const [couponErr, setCoponErr] = useState<any>("")
    const [paymentAsyncState, setPaymentAsyncState] =
        useState<asyncStates.AsyncState>(asyncStates.initState);
    const posthog = usePostHog();

    const updateFinalAmt = (amount:number) => setFinalAmt(+amount.toFixed(2));
    const updateCalculationTax = (amount:number) => setCalculationTax(+amount.toFixed(2));
    const updateCouponSaving = (amount:number|null) => {
        if (amount === null) return setCouponSaving(null);
        setCouponSaving(+amount.toFixed(2))
    };

    const finalAmount = () => {
        if (type === "member") {
            data?.price_deduction ? updateFinalAmt((Number(data?.price_deduction) * tax / 100) + Number(data?.price_deduction)) :
                updateFinalAmt((Number(data?.amount) * tax / 100) + Number(data?.amount))
        } else {

            updateFinalAmt((Number(data?.amount) * tax / 100) + Number(data?.amount))
        }
    }
    const taxCalculateFun = () => {
        updateCalculationTax(Number(data?.amount) * tax / 100)
    }
    useEffect(() => {

        finalAmount();
    }, [])


    let user: any = localStorage.getItem("Nurture_user_data")
    user = JSON.parse(user)

    let coupenHandler = async (passedCoupon?: string) => {
        const couponCode = passedCoupon || coupon;

        if (passedCoupon) {
            setCoupon(passedCoupon)
        }

        try {
            if (!couponCode) return;
            setCouponLoading(true)
            setCoponErr("")
            let res: any = await getCoupenData({ coupon_code: couponCode, user_id: user.id })
            let isSucess = res.data.success;
            let msgArray = res.data.message;
            let msg: any = "";

            for (let i = 0; i < msgArray.length; i++) {
                msg += msgArray[i]
            }
            if (!isSucess) {
                setCoponErr(msg)
            }
            if (isSucess) {
                let resData = res.data.data;
                if (resData.type === "amount") {
                    if (type === "member") {
                        if (data?.price_deduction) {
                            // updateCouponSaving(+resData?.amount)
                            let discount = Number(resData?.amount) > Number(data?.price_deduction) ?Number(data?.price_deduction):Number(resData?.amount)
                            let discountAmt = Number(data?.price_deduction) - discount;    
                            updateCouponSaving(discount)    
                            updateCalculationTax(discountAmt * tax / 100)
                            // updateFinalAmt((discountAmt * tax / 100) + Number(data?.amount))
                            updateFinalAmt((discountAmt * tax / 100) + discountAmt)                            
                        } else {
                            updateCouponSaving(+resData?.amount) 
                            let discount = Number(resData?.amount) > Number(data?.price_deduction) ?Number(data?.price_deduction):Number(resData?.amount)                           
                            let discountAmt = Number(data?.amount) - discount;
                            updateCalculationTax(discountAmt * tax / 100)
                            // updateFinalAmt((discountAmt * tax / 100) + Number(data?.amount))
                            updateFinalAmt((discountAmt * tax / 100) + discountAmt)                            
                        }
                    } else {
                        updateCouponSaving(+resData?.amount);
                        let discount = Number(resData?.amount) > Number(data?.amount) ?Number(data?.amount):Number(resData?.amount)
                        let discountAmt = Number(data?.amount) - discount;
                        updateCalculationTax(discountAmt * tax / 100)
                        // updateFinalAmt((discountAmt * tax / 100) + Number(data?.amount))
                        updateFinalAmt((discountAmt * tax / 100) + discountAmt)
                    }
                }
                if (resData.type === "percentage") {
                   
                    if (type === "member") {
                        if (data?.price_deduction) {
                            let Amount = Number(data?.price_deduction)
                            let discount = Number(resData?.amount)
                            let withDisc = Amount * discount / 100;
                            let calculationTaxes = (Amount - discount) * tax / 100;
                            updateCouponSaving(withDisc)
                            updateCalculationTax(calculationTaxes)
                            updateFinalAmt(calculationTaxes + (Amount - withDisc))
                        } else {
                            let Amount = Number(data?.amount)
                            let discount = Number(resData?.amount)
                            let withDisc = Amount * discount / 100;
                            let calculationTaxes = (Amount - discount) * tax / 100;
                            updateCouponSaving(withDisc)
                            updateCalculationTax(calculationTaxes)
                            updateFinalAmt(calculationTaxes + (Amount - withDisc))
                        }
                    } else {

                        let Amount = Number(data?.amount)
                        let discount = Number(resData?.amount)
                        let withDisc = Amount * discount / 100;
                        let calculationTaxes = (Amount - discount) * tax / 100;
                        updateCouponSaving(withDisc)
                        updateCalculationTax(calculationTaxes)
                        updateFinalAmt(calculationTaxes + (Amount - withDisc))
                    }
                }
                setCouponId(resData.id)
            }
            setCouponLoading(false)
        } catch (error) {
            setCouponLoading(false)
        }
    }
    let removeCoupon = () => {
        setCoupon("")
        updateCouponSaving(null)
        setCouponId(null)
        finalAmount()
        taxCalculateFun()
    }


    const buyCourseRequest = async (courseData: any) => {
        let params = {
            ...courseData,
            user_id: getUserID(),
        };

        try {
            setPaymentAsyncState(asyncStates.pendingState);

            const res: any = await postBuyCourse(params);

            if (res.status !== 200) {
                throw { message: res?.data?.message?.join(" ") };
            }

            setPaymentAsyncState(asyncStates.successState);

            purchaseEvent({
                ecommerce: {
                    value: courseData?.amount,
                    transaction_id: `${res?.data?.transaction_id}`,
                    coupon: courseData?.coupon_id || "",
                    finalAmount: courseData.total_amount,
                },
                items: [
                    {
                        item_name: courseData?.data?.title,
                        item_id: courseData?.course_id,
                        price: courseData?.amount,
                        quantity: "1",
                    },
                ],
            });

            posthog.capture("user-purchases-a-course", {
                courseName: courseData?.data?.title,
                courseId: courseData?.course_id,
                price: courseData?.amount,
                userId: getUserID(),
                coupon: courseData.coupon_id || "",
                finalAmount: courseData.total_amount,
            });

            posthog.capture(`[course-purchased]:${formatSlug(courseData?.data?.title || '')}`, {
                $set: { "last-course-purchased": formatSlug(courseData?.data?.title || '') },
            });

            grinEvent({
                amount: courseData?.total_amount, 
                order_number: `${res?.data?.transaction_id}` 
            });

            Navigate(`${COURSEVIEW}${data?.id}`);

        } catch (err: any) {
            toast.error(err?.message);
            setPaymentAsyncState({
                ...asyncStates.failedState,
                failedMessage: err?.message,
            });
        }
    };

    let handlePayment = () => {
        if (type === "course") {
            const obj = {
                course_id: data?.id,
                coupon_id: couponId,
                tax,
                amount: data?.amount,
                total_amount: finalAmt,
                coupon_amount: couponSaving,
                tax_amount: calculationTax,
                data,
            }

            if (finalAmt === 0) {
                buyCourseRequest(obj);
            } else {
                Navigate(BUYCOURSE, { state: { obj } })
            }
            
        } else {
            let amt;
            if (data?.price_deduction) {
                amt = data?.price_deduction;
            } else {
                amt = data.amount;
            }
            let obj = {


                amount: amt,
                total_amount: finalAmt,
                tax,
                membership_id: data.id,
                coupon_amount: couponSaving,
                coupon_id: couponId,
                tax_amount: calculationTax
            }

            handleNext(obj)
        }
    }
    // debugger
    return (
        <div>
            <div>
                
                <div className="step-innercontent detail-step4">
                    <div>
                        <h3>{type === "member" ? "Membership" : "Billing"} <span className="strong">Details</span></h3>

                        <div className="inner-monthdiv">
                            <div className={`${data?.class}`}>
                                <div className='payment-details' style={{ border: `1px solid ${data?.color_code}` }}>
                                    <div className="monthbox">
                                        <div className='monthbox-head' style={{ backgroundColor: `${data?.color_code}` }}>
                                            <div className='d-flex justify-content-between justify-content-md-start align-items-start align-items-md-center membership-nm flex-column flex-md-row' >
                                                <h4 className='me-0 me-md-5 pe-0 pe-md-3'>{data?.title}</h4>
                                                <div className="d-flex justify-content-end align-items-center align-items-md-end Plan-price flex-row flex-md-column">
                                                    <div>
                                                        {/* <span className="pCost-old">${data?.base_price} </span> */}
                                                        {/* <span className="pCost">${type === "member" ? data?.price_deduction ? data?.price_deduction : data?.amount : data?.amount} </span><span className="membership-cycle">{type === "member" && "/mo"}</span> */}
                                                        <span className="pCost">${type === "member" ? data?.amount : data?.amount} </span><span className="membership-cycle">{type === "member" && "/mo"}</span>
                                                    </div>
                                                    <div className='ms-2 ms-md-0'>
                                                      {type === "member"?<p className='text-end m-0 p-0'>{data?.sub_title}</p>:""}  
                                                    </div>
                                                </div>
                                                {/* <p className="pricepara">${data?.amount}</p> */}
                                            </div>
                                        </div>

                                        <div className='monthbox-details'>
                                            {parse(String(data?.description))}
                                        </div>

                                        {/* <h4>{data?.title}</h4>
                                        <p className="pricepara">${data?.amount}</p> */}
                                        {/* <p>Monthly access to our members-only classes, Q&As, nursing forum and more! You will be charged $149 each month for access to our full membership program and all member perks.
                                        Enjoy special discounts on lactation and sleep consults. This subscription renews every month.</p> */}
                                        {/* <p>{data?.description}</p> */}
                                    </div>
                                    <div className="apply-couponcode border-top mt-4">
                                        <h6 className="apply-heading">Apply Coupon Code</h6>
                                        <div className="position-relative">
                                            <div className='d-flex justify-content-between'><input type="text" value={coupon || data?.coupon_code} onChange={(e) => setCoupon(e.target.value)} className="form-control" />
                                                <button className="btn secondary-teal-btn ms-2" disabled={couponLoading || couponId} onClick={() => coupenHandler()} style={{ background: `${data?.color_code}` }}>{couponLoading ? <CircularProgress size="1rem" /> : couponId ? "Applied" : "Apply"}</button></div>
                                            <span className="error">{couponErr}</span>
                                        </div>
                                        <ReceivedGiftCards apply={(giftCoupon: string) => coupenHandler(giftCoupon)} coupon={coupon || data?.coupon_code} hideEmptyState />
                                    </div>
                                    {/* <div className="apply-couponcode">
                                        <h6 className="apply-heading">Apply Coupon Code</h6>
                                        <div className="position-relative">
                                            <input value={coupon} onChange={(e) => setCoupon(e.target.value)} className="form-control" />
                                            <button className="btn" disabled={couponLoading || couponId} onClick={coupenHandler}>{couponLoading ? <CircularProgress size="1rem" /> : couponId ? "applied" : "apply"}</button>
                                            <span className="error">{couponErr}</span>
                                        </div>
                                    </div> */}
                                    {couponSaving && <div className="coupon-applied d-flex justify-content-between">
                                        <div className='d-flex justify-content-between flex-column'>
                                            <h6>Coupon Applied</h6>
                                            <p className='p-0 m-0'><span className='disc-amount'>${couponSaving}</span> Coupon saving</p>
                                        </div>
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <a onClick={removeCoupon} className="">Remove</a>
                                        </div>

                                    </div>}
                                </div>
                                <div className="bill-details" style={{ border: `1px solid ${data?.color_code}` }}>
                                    <h6 style={{ backgroundColor: `${data?.color_code}` }}>Billing Details</h6>
                                    <ul className="first">
                                        <li>
                                            <span className="headingspan">{type !== "course" ? "Consult" : "Course"}  Total</span>
                                            <span className="pricespan">${type === "member" ? data?.price_deduction ? data?.price_deduction : data?.amount : data?.amount}</span>
                                        </li>
                                        <li className="inner-spans coupon-discount">
                                            <span>Coupon Discount</span>
                                            <span>- ${couponSaving ? couponSaving : "0"}</span>
                                        </li>
                                        <li className="inner-spans">
                                            <span>Taxes & Charges</span>
                                            <span>+${calculationTax}</span>
                                        </li>
                                    </ul>
                                    <ul className="pay-list">
                                        <li>
                                            <span className="payspan">Total:</span>
                                            <span className="pay-total">${finalAmt}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button className="btn primary-blue-btn mt-3 mb-5" onClick={handlePayment} style={{ backgroundColor: `${data?.color_code}` }}>{paymentAsyncState.pending ? <CircularProgress /> : "Confirm & Pay"}</button>
                </div>
            </div>
        </div>
    )
}

export default MembershipDetail