import React from "react";
import { Container, Row } from "react-bootstrap";
import "./ourStory.scss";
import jamie_pic from "../../Assets/img/about/Jamie-pic.jpg";
import emily_pic from "../../Assets/img/about/emily.jpg";
import { PROMISE } from "../../Routes/RouteConstent";
import { useNavigate } from "react-router-dom";
import PinkContentBox from "../../Common/PinkContentBox";
import { HeroBannerHorizontal}  from "../../Common/HeroBanner";
import hero from "../../Assets/img/about/about_us.png";

const OurStory = () => {
    const Navigate = useNavigate();
    return (
        <div>
            <Container fluid className="our-story">
                <div className="py-3">
                    <HeroBannerHorizontal
                        hero={hero}
                        width={70}
                        title={
                            <>
                                Imagine chatting with your best mom friend who is also a
                                registered nurse. <span className="strong green">That’s us!</span>
                            </>
                        }
                        
                    />
                </div>

                <Row no-gutter className="py-md-5 py-sm-3">
                    <div className="col-lg-12 col-md-12 about-discription-section">
                        <div className="container">
                            <Row className="justify-content-center">
                                <div className="px-2 px-md-5 about_description">
                                    Working the overnight shift in the Labor & Delivery unit,
                                    Registered Nurses Jamie O’Day and Emily Silver prepared
                                    patients for discharge after coaching and supporting them
                                    through their child’s first moments. After so frequently
                                    hearing new parents say{" "}
                                    <span className="cursive strong respondent">
                                        “Can I take you home with us?!”,
                                    </span>{" "}
                                    they took charge and started NAPS in 2011.
                                    <br /> <br />
                                    After working together on the company as a side hustle for six
                                    years, Jamie and Emily realized in 2017 that they either had
                                    to ‘sh!t or get off the pot’ and follow their true calling of
                                    running NAPS full time!
                                    <br /> <br />
                                    Being moms themselves, and knowing that there is{" "}
                                    <span className="cursive strong respondent">
                                        no one right way to parent
                                    </span>
                                    , Jamie and Emily built NAPS as an honest, real-life,
                                    judgment-free space with personalized support from trained
                                    medical professionals. The classes, bootcamps, podcast, as
                                    well as their signature membership service, Nurture by NAPS,
                                    are designed to help parents thrive in every stage from
                                    conception through preschool.
                                </div>
                            </Row>
                        </div>
                    </div>
                </Row>
                <Container className="founder-details">
                    <Row>
                        <div className="col-lg-12 col-sm-12 text-center">
                            <h3 className="text-center about-section-title mb-3 mb-md-5">
                                {" "}
                                Meet <span className="strong red">Our Founders </span>
                            </h3>
                        </div>
                    </Row>
                </Container>

                <PinkContentBox
                    imgSrc={jamie_pic}
                    title="Jamie"
                    paragraph={
                        <>
                            Jamie is most passionate about helping families cut through all
                            the bullsh!t and judgment and find the way that works best for
                            you. You can always count on Jamie to give you the right medical
                            answer and to help you feel empowered and confident with honest,
                            real-life advice based on her own experiences as a nurse and as a
                            mother to three young girls. <br />
                            Refusing to take the traditional nursing path by starting in
                            General Medicine, Jamie fought hard to find a job that fulfilled
                            her passion of working with expecting mothers in Labor & Delivery,
                            where she met NAPS co-founder Emily Silver.
                            <br />
                            Jamie graduated from Villanova University and is a Registered
                            Nurse and International Board Certified Lactation Consultant
                            (IBCLC). She lives in South Boston, MA with her husband, James,
                            and her daughters Cate (age 7), Mackenzie (age 6), and Camila (age
                            3).
                        </>
                    }
                />
                <div className="my-4">
                    <PinkContentBox
                        reverse
                        imgSrc={emily_pic}
                        title="Emily"
                        paragraph="Emily’s passion for educating and supporting families
                            stems from her personal experience dealing with loss.
                            She lost a sister to SIDS (Sudden Infant Death
                            Syndrome), has had multiple miscarriages, and has dealt
                            with the anxiety and the stigma that comes with taking
                            medication while pregnant and breastfeeding. You can be
                            assured that taboo topics are just not taboo for Emily!
                            Emily began her career in General Medicine before
                            working as a Labor & Delivery nurse, where she met NAPS
                            co-founder Jamie O’Day. While working in Labor &
                            Delivery, Emily went back to school to get her master’s
                            degree in nursing and become a Family Nurse
                            Practitioner. She practiced as a Nurse Practitioner in
                            an OBGYN office for several years before running NAPS
                            full time with Jamie. Emily best supports families
                            through NAPS by pulling from her vast medical
                            background, and her own experiences with pregnancy and
                            parenting as a mother of three girls. After graduating
                            from Boston College, Emily received her graduate degree
                            from UMass Boston. Emily is a Family Nurse Practitioner
                            and International Board Certified Lactation Consultant
                            (IBCLC). She lives in Charlestown, MA with her husband,
                            AJ, three daughters Grace (age 7), Madelyn (age 5),
                            Eloise (age 1), and dog Maisey (age 9)."
                    />
                </div>
                <Row>
                    <Row className="py-4">
                        <div className="col-lg-12 col-md-12 ">
                            <div className="container">
                                <Row className="curious-main">
                                    <div className="col-lg-12">
                                        <div className="read-story w-100 py-4 py-md-5 px-2 px-md-5 mb-5">
                                            <div className="d-flex justify-content-center align-items-center flex-column mx-auto px-0 px-md-5 sec-caption">
                                                <h2>
                                                    Still curious?{" "}
                                                    <span className="head-bold">
                                                        Read about our promise
                                                    </span>
                                                </h2>
                                                <button
                                                    className="btn primary-blue-small-btn-40 mt-1 mt-md-4"
                                                    onClick={() => Navigate(PROMISE)}
                                                >
                                                    Read our promise
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </Row>
                            </div>
                        </div>
                    </Row>
                </Row>
            </Container>
        </div>
    );
};

export default OurStory;
