import { Box, Typography, Button, Stepper, Step, StepLabel } from '@mui/material';
import React from 'react'

const Steppers = ({ steps, getStepContent, handleNext, activeStep, setActiveStep }: any) => {

    const [skipped, setSkipped] = React.useState(new Set<number>());




    const isStepOptional = (step: number) => {
        return step === 3;
    };

    const isStepSkipped = (step: number) => {
        return skipped.has(step);
    };
    let StepHandlerClick = (data: any) => {

        if (activeStep === data) return
        // if (isClick?.[data] === true) {
        //     setActiveStep(data);
        // }
    }

    const handleReset = () => {
        setActiveStep(0);
    };

    const handleBack = () => {
        if (activeStep === 0) return;
        setActiveStep((prevActiveStep: any) => prevActiveStep - 1);
    };



    return (
        <div>
            <section className={`steps-section position-relative`}>
                <Box sx={{ width: '100%' }} className={`position-relative main-step1`}> {activeStep > 0 && <button className="membership-back " onClick={handleBack}>BACK</button>}
                    <Stepper activeStep={activeStep}>
                        {steps?.map((label: any, index: any) => {
                            const stepProps: { completed?: boolean } = {};
                            const labelProps: {
                                optional?: React.ReactNode;
                            } = {};
                            if (isStepOptional(index)) {
                                labelProps.optional = (
                                    <Typography variant="caption" ></Typography>
                                );
                            }
                            if (isStepSkipped(index)) {
                                stepProps.completed = false;
                            }
                            return (
                                <Step key={label} {...stepProps}>
                                    <StepLabel {...labelProps} onClick={() => StepHandlerClick(index)} >
                                        {/* {label} */}
                                    </StepLabel>
                                </Step>
                            );

                        })}
                    </Stepper>
                    <div className="container">

                        {activeStep === steps.length ? (
                            <React.Fragment>
                                <Typography sx={{ mt: 2, mb: 1 }}>
                                    All steps completed - you&apos;re finished
                                </Typography>
                                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                    <Box sx={{ flex: '1 1 auto' }} />
                                    <Button onClick={handleReset}>Reset</Button>
                                </Box>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                {/* <Button onClick={handleBack}>back</Button>
                                <Button onClick={handleNext}>Next</Button> */}

                                <Typography sx={{ mt: 2, mb: 1 }}>
                                    {getStepContent(activeStep)}
                                </Typography>

                            </React.Fragment>
                        )}
                    </div>
                </Box>
            </section>
        </div>
    )
}

export default Steppers;