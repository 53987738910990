import { useEffect } from 'react'
import { useNavigate } from 'react-router'
import { LOGIN, VERIFYEMAIL } from './RouteConstent';

export const PrivateRoute = (props: any) => {

    const { Component } = props
    const navigate = useNavigate();
    useEffect(() => {
        let user: any = localStorage.getItem("Nurture_user_data");
        user = JSON.parse(user)
        if (user) {
            if (user?.is_email_verify === 0) {
                navigate(VERIFYEMAIL)
            }
        }
        if (!user) {
            navigate(LOGIN)
        }

    }, [])

    return Component
}
