import {
  Select,
  MenuItem,
  SelectChangeEvent,
  CircularProgress,
} from "@mui/material";
import React from "react";
import "./SavedCards.scss";

interface SavedCardsProps {
  savedCard: string;
  setSavedCard: (value: string) => void;
  paymentMethodsList: any[];
  disableAll: boolean;
  paymentMethods: any;
  showError: boolean;
}

const SavedCards = ({
  savedCard,
  setSavedCard,
  paymentMethodsList,
  disableAll,
  paymentMethods,
  showError,
}: SavedCardsProps) => {
  const handleChange = (event: SelectChangeEvent) => {
    setSavedCard(event.target.value);
  };

  return (
    <div className={disableAll ? "savedCardsFieldDisabled" : ""}>
      <Select
        className="savedCardsField"
        value={savedCard}
        label="Age"
        onChange={handleChange}
        disabled={disableAll}
      >
        {paymentMethodsList.map((method: any) => (
          <MenuItem value={method.id} key={method.id}>
            {method.card.brand.toUpperCase()} ************{method.card.last4}
          </MenuItem>
        ))}

        {paymentMethods.pending && (
          <div className="savedCardsEmpty">
            <p>
              Loading <CircularProgress size={10} />
            </p>
          </div>
        )}

        {!paymentMethodsList.length && paymentMethods.success && (
          <div className="savedCardsEmpty">
            <p>You don't have saved payment methods.</p>
          </div>
        )}
      </Select>
      {showError && <p className="error">Please select the Payment Method</p>}
    </div>
  );
};

export default SavedCards;
