import React, { useState } from "react";
import usePaymentMethodForm from "./usePaymentMethodForm";
import * as asyncStates from "../../../Utils/asyncState";
import PaymentMethodForm from "./PaymentMethodForm";
import { CircularProgress } from "@mui/material";
import { postBuyMembership } from "../paymentsAPI";
import {
  getUser,
  getUserID,
  getDataFromLocalStorage,
} from "../../../Service/getLocalStorage";
import { toast } from "react-toastify";
import SavedCards from "../Components/SavedCards";
import useSavedCards from "../Components/SavedCards/useSavedCards";
import ChooseWayToPay from "../Components/ChooseWayToPay/ChooseWayToPay";
import useChooseWayToPay, {
  PayWithEnum,
} from "../Components/ChooseWayToPay/useChooseWayToPay";
import Lock from "../../../Assets/img/lock.svg";
import ShareASaleScript from "../../../Screens/Membership/ShareASaleScript";
import useModalWindowControls from "../../../Hooks/useModal";
import CongratsPopUp from "../../CongratsPopUp";
import { useNavigate } from "react-router-dom";
import { purchaseEvent } from "../../../Utils/gtm";
import { usePostHog } from "posthog-js/react";
import "./BuyMembership.scss";
import { grinEvent } from "../../../Utils/grin";

interface BuyMembershipProps {
  membershipData: any;
  maxWidth?: string;
}

const BuyMembership = ({ membershipData, maxWidth }: BuyMembershipProps) => {
  const navigate = useNavigate();
  const posthog = usePostHog();

  const chooseWayToPay = useChooseWayToPay({
    initPayWith: PayWithEnum.saved_card,
  });

  const [paymentAsyncState, setPaymentAsyncState] =
    useState<asyncStates.AsyncState>(asyncStates.initState);

  const successModal = useModalWindowControls();

  const buyMembershipRequest = async (fields: any) => {
    let params = {
      ...fields,
      user_id: getUserID(),
      amount: membershipData?.amount,
      total_amount: membershipData?.total_amount,
      tax: membershipData?.tax,
      membership_id: membershipData?.membership_id,
      coupon_amount: membershipData?.coupon_amount,
      coupon_id: membershipData?.coupon_id,
      tax_amount: membershipData?.tax_amount,
      email: getUser()?.email,
    };

    try {
      setPaymentAsyncState(asyncStates.pendingState);

      const res: any = await postBuyMembership(params);

      if (res.status !== 200 || !res.data.data) {
        throw { message: res?.data?.message?.join(" ") };
      }

      setPaymentAsyncState(asyncStates.successState);
      successModal.openModal(res?.data?.transaction_id);

      localStorage.setItem("Nurture_user_data", JSON.stringify(res.data.data));

      purchaseEvent({
        ecommerce: {
          value: membershipData?.amount,
          transaction_id: `${res?.data?.transaction_id}`,
          coupon: membershipData?.coupon_id || "",
          finalAmount: membershipData?.total_amount,
        },
        items: [
          {
            item_name:
              membershipData?.membership_id === 1
                ? "MONTHLY MEMBERSHIP"
                : "ANNUAL MEMBERSHIP",
            item_id: membershipData?.membership_id,
            price: membershipData?.amount,
            quantity: "1",
          },
        ],
      });

      posthog.capture("user-purchases-a-membership", {
        frequency:
          membershipData?.membership_id === 1
            ? "MONTHLY MEMBERSHIP"
            : "ANNUAL MEMBERSHIP",
        membershipId: membershipData?.membership_id,
        price: membershipData?.amount,
        userId: getUserID(),
        coupon: membershipData?.coupon_id || "",
        finalAmount: membershipData?.total_amount,

        $set: {
          free_membership: false,
          monthly_membership: membershipData?.membership_id === 1,
          annual_membership: membershipData?.membership_id === 2,
        },
      });

      if (["BUMPFREE", "BUMP150"].includes(membershipData?.coupon_code)) {
        posthog.capture(
          `used-${membershipData?.coupon_code}-membership-coupon`,
          {
            $set: {
              BUMP_coupon: membershipData?.coupon_code,
            },
          }
        );
      }

      grinEvent({
        amount: membershipData?.total_amount,
        order_number: `${res?.data?.transaction_id}`,
      });

      window.dispatchEvent(new Event("storage"));
    } catch (err: any) {
      toast.error(err?.message);
      setPaymentAsyncState({
        ...asyncStates.failedState,
        failedMessage: err?.message,
      });
    }
  };

  const savedCards = useSavedCards({ paymentRequest: buyMembershipRequest });

  const paymentMethodForm = usePaymentMethodForm({
    paymentRequest: buyMembershipRequest,
  });

  const closeSuccessModal = () => {
    successModal.closeModal();
    localStorage.setItem(
      "Nurture_user_data",
      JSON.stringify({ ...getDataFromLocalStorage(), welcome_screen_status: 0 })
    );
    navigate("/");
  };

  React.useEffect(() => {
    if (savedCards.paymentMethods.success && !savedCards.defaultPaymentMethod) {
      chooseWayToPay.setPayWith(PayWithEnum.new_card);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedCards.paymentMethods.success, savedCards.defaultPaymentMethod]);

  if (savedCards.paymentMethods.pending) {
    return (
      <div className="buyMembershipPending">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div>
      {successModal.isVisible && (
        <ShareASaleScript
          amount={+membershipData.total_amount}
          orderId={successModal.openModalData}
          couponCode={membershipData.coupon_id}
        />
      )}

      <CongratsPopUp
        isVisible={successModal.isVisible}
        closeModal={closeSuccessModal}
        doneBtnTitle="Show Me Around"
      >
        <h3>Congratulations</h3>
        <p>You are now part of the club!</p>
      </CongratsPopUp>

      {!!savedCards.paymentMethodsList.length && (
        <>
          <div className="mt-3">
            <ChooseWayToPay {...chooseWayToPay} />
          </div>
          <div className="mt-3">
            <SavedCards
              {...savedCards}
              disableAll={chooseWayToPay.payWith === PayWithEnum.new_card}
            />
          </div>
        </>
      )}

      <form
        onSubmit={
          chooseWayToPay.payWith === PayWithEnum.new_card
            ? paymentMethodForm.submit
            : savedCards.submit
        }
      >
        <PaymentMethodForm
          {...paymentMethodForm}
          paymentAsyncState={paymentAsyncState}
          maxWidth={maxWidth}
          disableAll={chooseWayToPay.payWith === PayWithEnum.saved_card}
        />
        <div className="pt-5 d-flex align-items-center">
          <img src={Lock} style={{ width: 25, marginRight: 4 }} alt="lock" />
          Your transaction is secure
        </div>
        <div className="col-sm-12 mb-5 mt-3">
          <button
            className="btn primary-blue-btn"
            disabled={paymentAsyncState.pending}
          >
            {paymentAsyncState.pending ? (
              <CircularProgress size={30} />
            ) : (
              "Confirm"
            )}
          </button>

          {membershipData?.membership_id === 1 && (
            <p className="mt-5">
              You will be charged $149/month until you cancel. If you used a
              coupon code your next payment will be due 30 days after
              redemption.
            </p>
          )}
          {membershipData?.membership_id === 2 && (
            <p className="mt-5">
              You will be charged $999/year until you cancel.
            </p>
          )}
        </div>
      </form>
    </div>
  );
};

export default BuyMembership;
