import { useSelect } from "@mui/base"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import { getMemberPriffiled, userData } from "../../Redux/user/user"
import { getUserID } from "../../Service/getLocalStorage"
import { getUserProfileEdit } from "../../Service/update_profile"

export const GetPrefilledData = () => {

    const [user_data, setUser_data] = useState<any>({})
    const [member_data, setMember_data] = useState<any>({})

    useEffect(() => {
        let data: any = localStorage.getItem("Nurture_user_data")
        data = JSON.parse(data)

        if (data) {
            setUser_data({
                first_name: data.first_name,
                last_name: data.last_name,
                phone: data.phone,
                email: data.email
            })
            // if (data.is_stripe_membership === 1) {
            //     // setMember_data({

            //     // })
            // }
        }


    }, [localStorage])
    return [user_data]
}
export const MembershipData = () => {
    const [memberData, setMemberData] = useState<any>({
        about_you: {},
        about_your_baby: {},
        about_your_partner: {}
    })
    const dispatch = useDispatch()
    const getUserData = useSelector(((state: any) => state?.userData?.data))


    useEffect(() => {
        if (getUserData?.length === 0 || !getUserData) {
            dispatch(userData())
        } else {
            setMemberData(getUserData)
        }
    }, [getUserData])

    return [memberData];
}

export const useMembershipData = () => {
   
    const [userMember, setUserMember] = useState<any>({})

    const dispatch = useDispatch()
    const getUserData = useSelector(((state: any) => state?.userData?.memberData))

    useEffect(() => {
        if (getUserData?.length === 0 || !getUserData) {
            dispatch(getMemberPriffiled())
        } else setUserMember(getUserData)
    }, [getUserData])
    return [userMember]
}