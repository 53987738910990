import React from "react";
import Wrapper from "./Components/Wrapper";
import BHHero from "./Components/BHHero";
import IWantItAll from "./Components/IWantItAll";
import PreBabyBootCamp from "./Components/PreBabyBootcamp";
import ServiceCard from "./Components/ServiceCard";
import newborn from "./Assets/services/newborn.png";
import breastfeeding from "./Assets/services/breastfeeding.png";
import laborAndDelivery from "./Assets/services/labor&delivery.png";
import JudgmentFreeSupport from "./Components/JudjmentFreeSupport";
import BHCourses from "./Components/BHCourses";
import BHUnswaddled from "./Components/BHUnswaddled";
import BHBecomeMember from "./Components/BHBecomeMember";
import {
  BREASTFEEDING_BASICS_URL,
  LABOUR_DELEVERY_URL,
  NEW_BORN_URL,
} from "../Utils/ServiceUrl";
import useBHApi from "./hooks/useBHApi";

const BrightHorizons = () => {
  useBHApi();
  
  return (
    <Wrapper.BackgroundWrapper>
      <Wrapper.Header />
      <BHHero type="BH" />
      <Wrapper>
        <IWantItAll />
        <Wrapper.Title maxWidth="673px">
          When you’re prepping <br /> <strong>for baby:</strong>
        </Wrapper.Title>
        <PreBabyBootCamp />
        <Wrapper.Title maxWidth="673px">
          If you can’t make <br /> <strong>Bootcamp:</strong>
        </Wrapper.Title>
        <ServiceCard.ServiceCardWrapper>
          <ServiceCard
            navTo={LABOUR_DELEVERY_URL}
            src={laborAndDelivery}
            title="Labor & Delivery Prep"
            description="Dive into a robust course covering the first signs of labor to the birth of your baby and postpartum recovery. See live demonstrations of breathing, massage and relaxation techniques and an overview of pain medication options."
          />
          <ServiceCard
            navTo={NEW_BORN_URL}
            src={newborn}
            title="Newborn Essentials"
            description="Learn how to prepare for parenthood with clear demonstrations of newborn care techniques for your first weeks at home.This live, virtual class is run by a maternal/child health Registered Nurse and is not only educational, but interactive and fun!"
          />
          <ServiceCard
            navTo={BREASTFEEDING_BASICS_URL}
            src={breastfeeding}
            title="Breastfeeding Basics"
            description="Everything you need to know to feed your baby, including all options that exist, so you can make the best plan for you and your family. We cover how to position your baby, get good latch, when to start pumping, supplementing and introducing bottles."
          />
        </ServiceCard.ServiceCardWrapper>
        <JudgmentFreeSupport />
        <BHCourses />
        <BHBecomeMember />
        <BHUnswaddled />
      </Wrapper>
    </Wrapper.BackgroundWrapper>
  );
};

export default BrightHorizons;
