export const grinEvent = ({
  amount,
  order_number,
}: {
  amount: string;
  order_number: string;
}) => {
  const Grin = (window as any).Grin || ((window as any).Grin = []);

  Grin.push(["conversion", amount, { order_number: order_number }]);
};
