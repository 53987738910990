import React, { useEffect } from 'react'
import {
  Routes,
  useLocation,
  Route,
  useNavigate,
} from "react-router-dom";
import EmailVerifyPopup from '../Utils/Popup/emailVerifyPopup';
import * as navigate from "./RouteConstent";
import * as Screens from "../Screens";
import { PrivateRoute } from "./PrivateRoute"
import Footer from '../Common/Footer/Footer';
import PublicRoute from './PublicRoute';
import HomePageRender, { CourseRender } from './homePageRender';
import { NeedAuthRoute } from './NeedAuthRoute';
import { serviceRoutesList } from '../Utils/ServiceUrl';
import { isLoggedInSelector } from '../Redux/user/user';
import { useSelector } from 'react-redux';
import { nursingNotesRoutes } from '../NursingNotesScreens';

const Routing = () => {
  const { pathname } = useLocation();
  const navigateTo = useNavigate();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      //@ts-ignore
      behavior: "instant",
    });
  }, [pathname]);

  const isUserLoggedIn = useSelector(isLoggedInSelector);

  useEffect(() => {
    if (
      pathname.startsWith("/ask_a_nurse") ||
      pathname === `/${navigate.ASK_A_NURSE}` || 
      pathname === `/${navigate.ASK_A_NURSE}/`
    ) {
      navigateTo(`/${navigate.ASK_A_NURSE}/1`);
    }
  }, [pathname, navigateTo]);

  return (
    <div>
      <Routes>
        {/* <Route path={navigate.HOME} element={<PrivateRoute Component={<Screens.Home />} />} /> */}
        <Route path="/home/temp" element={<PrivateRoute Component={<Screens.Home />} />} />
        <Route path={navigate.HOME} element={HomePageRender()} />
        <Route path={navigate.LOGIN} element={<NeedAuthRoute Component={Screens.Login} />} />
        <Route path={navigate.ABOUTUS} element={<Screens.AboutUs />} />
        <Route path={navigate.COURSELANDING} element={<Screens.CourseLanding />} />
        <Route path={navigate.CRPROGRAMS} element={<Screens.CorporatePrograms />} />
        {/* <Route path={navigate.LOGINPOPUP} element={<LoginPopup />} /> */}
        <Route path={`${navigate.MEMBERSHIP}/:id`} element={<Screens.Membership />} />
        <Route path={navigate.SIGNUP} element={<NeedAuthRoute Component={Screens.Signup} />} />
        <Route path={navigate.FORGOTPASSWORD} element={<PublicRoute Component={Screens.ForgotPassword} />} />
        <Route path={navigate.VERIFYEMAIL} element={<PublicRoute Component={Screens.EmailVerify} />} />
        <Route path={navigate.CHECKMAIL} element={<PublicRoute Component={Screens.SendEmail} />} />
        <Route path={navigate.SETPASSWORD} element={<PublicRoute Component={Screens.SetNewPassword} />} />
        <Route path={navigate.VERIFYEMAILPOPUP} element={<PublicRoute Component={EmailVerifyPopup} />} />
        <Route path={navigate.COURSE} element={CourseRender()} />
        <Route path={navigate.COURSEDETAILS + ":id"} element={<PrivateRoute Component={<Screens.CourseDetails />} />} />
        <Route path={navigate.BUYCOURSEDETAIL} element={<PrivateRoute Component={<Screens.PaymentDetail />} />} />
        <Route path={navigate.BUYCOURSE} element={<PrivateRoute Component={<Screens.BuyCourse />} />} />
        <Route path={navigate.INSTRUCTOR + ":id"} element={<Screens.Instructor />} />

        <Route
          path={`${navigate.ASK_A_NURSE_CHAT}/:id`}
          element={<PrivateRoute Component={<Screens.AskANurseChat />} />}
        />
        <Route
          path={`${navigate.ASK_A_NURSE}/:page`}
          element={<PrivateRoute Component={<Screens.AskANurse />} />}
        />

        {isUserLoggedIn ? (
          <Route path={navigate.EVENTS} element={<Screens.Events />} />
        ) : (
          <Route
            path={navigate.LIVEWEBINAR}
            element={<Screens.LiveWebinars />}
          />
        )}
        <Route path={navigate.EVENTSDETAIL + ":id"} element={<PrivateRoute Component={<Screens.EventsDetails />} />} />
        <Route path={navigate.COURSEVIEW + ":id"} element={<Screens.CourseView />} />
        <Route path={navigate.MEMBERCHAT} element={<PrivateRoute Component={<Screens.MemberChat />} />} />
        <Route path={navigate.CONSULTS} element={<Screens.Consult />} />
        <Route path={navigate.NONMEMBER} element={<PrivateRoute Component={<Screens.PostLogin />} />} />
        <Route path={navigate.MEMBER} element={<PrivateRoute Component={<Screens.Member />} />} />
        <Route path={navigate.OURSTORY} element={<Screens.OurStory />} />
        {/* <Route path={navigate.CONSULTSTEMP} element={<Screens.ConsultLanding />} /> */}
        <Route path={navigate.CONSULTSTEMP2} element={< PrivateRoute Component={<Screens.ConsultLanding2 />} />} />
        <Route path={navigate.NURSECONSULTS} element={<Screens.NurseConsult />} />
        <Route path={navigate.DATECONSULTS} element={<PrivateRoute Component={<Screens.DateConsult />} />} />
        <Route path={navigate.MULTIPLECLASS} element={<PrivateRoute Component={<Screens.MultipleClassStep />} />} />
        <Route path={navigate.VIRTUALCLASSCONSULTS} element={<Screens.VirtualClassConsult />} />
        <Route path={navigate.PRIVATECLASS} element={<PrivateRoute Component={<Screens.PrivateClassForm />} />} />
        <Route path={navigate.PRIVATECLASS2} element={<Screens.PrivateClassForm2 />} />
        {/* <Route path={navigate.PREBABYBOOTCAMP} element={<Screens.PrebabyConsult />} /> */}
        <Route path={navigate.PREBABYBOOTCAMP} element={<PrivateRoute Component={<Screens.PrebabyConsult />} />} />
        <Route path={navigate.PRIVATESLEEPCONSULT} element={<PrivateRoute Component={<Screens.StepsForPrivateSleep />} />} />
        <Route path={navigate.GIFTCARD} element={<Screens.GiftCard />} />
        <Route path={navigate.GIFTCARDPURCHASE} element={<Screens.GiftCardPayment />} />
        <Route path={navigate.PROFILEUSER} element={<PrivateRoute Component={<Screens.ProfileUser />} />} />
        <Route path={navigate.EDITPROFILE} element={<PrivateRoute Component={<Screens.EditProfile />} />} />
        <Route path={navigate.PODCASTQUESTION} element={<Screens.PodcastQuestion />} />
        <Route path={navigate.SPONSORSHIP} element={<Screens.Sponsorships />} />
        <Route path={navigate.CONTACT} element={<Screens.Contact />} />
        <Route path={navigate.PROMISE} element={<Screens.Promises />} />
        <Route path={navigate.PRELOGIN} element={<PrivateRoute Component={<Screens.PreLogin />} />} />
        {/* <Route path={navigate.NURSECONSULT30} element={<PrivateRoute Component={<Screens.NurseConsult30 />} />} /> */}
        {/* <Route path={navigate.NURSECONSULT30} element={<PrivateRoute Component={<Screens.MainLandingPage />} />} /> */}
        {/* <Route path={navigate.SERVICE + ":service_name"} element={<Screens.MainLandingPage />} /> */}
        <Route path={navigate.MEMBERSHIP_PRE_LOGIN} element={<Screens.MembershipPreLogin />} />
        <Route path={navigate.PRIVACY} element={<Screens.PrivacyPolicy />} />
        <Route path={navigate.TERMS} element={<Screens.TermsAndCondition />} />
        <Route path={navigate.CLIENT_SERVICE_AGREEMENT} element={<Screens.ClientServiceAgreement />} />
        <Route path={navigate.CONSENT_FOR_DISCLOSURE} element={<Screens.ConsentForDisclosure />} />
        <Route path={navigate.QUICK_SCREENS} element={<Screens.QuickScreen />} />
        <Route path={navigate.STAGE1} element={<Screens.Stage1 />} />
        <Route path={navigate.STAGE2} element={<Screens.Stage2 />} />
        <Route path={navigate.STAGE3} element={<Screens.Stage3 />} />
        <Route path={navigate.STAGE4} element={<Screens.Stage4 />} />
        <Route path={navigate.STAGE5} element={<Screens.Stage5 />} />
        <Route path={navigate.STAGE6} element={<Screens.Stage6 />} />

        <Route path={navigate.SUPPORTGROUP} element={<Screens.SupportGroup />} />
        <Route path={navigate.SLEEPCONSULT} element={<Screens.SleepConsultation />} />
        <Route path={navigate.LACTATIONCONSULT} element={<Screens.LactationConsultLanding />} />
        <Route path={navigate.DAYTIMEOVERNIGHT} element={<Screens.DayTimeOvernight />} />
        <Route path={navigate.BUMP} element={<Screens.BUMP />} />
        {serviceRoutesList.map((path) => <Route key={path} path={path} element={<Screens.MainLandingPage service={path} />} />)}
        {nursingNotesRoutes.map(({ path, Screen }) => <Route key={path} path={path} element={<Screen />} />)}
      </Routes>
      <Footer />
    </div>
  )
}
export default Routing