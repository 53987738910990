import React from "react";
import * as asyncState from "../../Utils/asyncState";
import { toast } from "react-toastify";
import {
  getPaymentCards,
  postNewPaymentCard,
  deletePaymentCard,
  makeDefaultPaymentCard,
} from "../../Service/payment";
import { getUser } from "../../Service/getLocalStorage";

const usePaymentMethod = () => {
  const [paymentMethods, setPaymentMethods] = React.useState<any>({
    ...asyncState.initState,
    data: {},
  });

  const [postNewPaymentMethod, setPostNewPaymentMethod] = React.useState(
    asyncState.initState
  );

  const [removePaymentMethod, setRemovePaymentMethod] = React.useState(
    asyncState.initState
  );

  const [primaryPaymentMethod, setPrimaryPaymentMethod] = React.useState({
    ...asyncState.initState,
    paymentId: null,
  });

  const paymentsMethodsRequest = React.useCallback(async () => {
    try {
      setPaymentMethods({ ...asyncState.pendingState, data: {} });
      const user = getUser();
      const res: any = await getPaymentCards(user?.id);

      if (res.status !== 200) {
        throw { message: res?.data?.message?.join(" ") };
      }
      if (user?.user_need_recheck_payment) {
        localStorage.setItem(
          "Nurture_user_data",
          JSON.stringify({ ...user, user_need_recheck_payment: 0 })
        );
      }
      setPaymentMethods({ ...asyncState.successState, data: res?.data });
    } catch (err: { message: string } | any) {
      toast.error(err?.message);
      setPaymentMethods({
        ...asyncState.failedState,
        failedMessage: err?.message,
        data: {},
      });
    }
  }, []);

  const addPaymentMethodRequest = React.useCallback(async (params) => {
    try {
      setPostNewPaymentMethod({ ...asyncState.pendingState });
      let user: any = localStorage.getItem("Nurture_user_data");
      user = JSON.parse(user);

      const res: any = await postNewPaymentCard({
        ...params,
        user_id: user.id,
        email: user.email,
      });

      if (res.status !== 200) {
        throw { message: res?.data?.message?.join(" ") };
      }

      setTimeout(() => {
        setPostNewPaymentMethod({ ...asyncState.successState });
        setPaymentMethods({ ...asyncState.successState, data: res?.data });
      }, 1000);
    } catch (err: { message: string } | any) {
      toast.error(err?.message);

      setPostNewPaymentMethod({
        ...asyncState.failedState,
        failedMessage: err?.message,
      });
    }
  }, []);

  const removePaymentMethodRequest = React.useCallback(async (id) => {
    try {
      setRemovePaymentMethod({ ...asyncState.pendingState });
      let user: any = localStorage.getItem("Nurture_user_data");
      user = JSON.parse(user);

      const res: any = await deletePaymentCard({
        id,
        user_id: user.id,
      });

      if (res.status !== 200) {
        throw { message: res?.data?.message?.join(" ") };
      }

      setRemovePaymentMethod({ ...asyncState.successState });
      setPaymentMethods({ ...asyncState.successState, data: res?.data });
    } catch (err: { message: string } | any) {
      toast.error(err?.message);

      setRemovePaymentMethod({
        ...asyncState.failedState,
        failedMessage: err?.message,
      });
    }
  }, []);

  const makePrimaryPaymentMethod = React.useCallback(async (id) => {
    try {
      setPrimaryPaymentMethod({ ...asyncState.pendingState, paymentId: id });
      let user: any = localStorage.getItem("Nurture_user_data");
      user = JSON.parse(user);

      const res: any = await makeDefaultPaymentCard({
        payment_id: id,
        user_id: user.id,
      });

      if (res.status !== 200) {
        throw { message: res?.data?.message?.join(" ") };
      }

      setPrimaryPaymentMethod({ ...asyncState.successState, paymentId: id });
      setPaymentMethods({ ...asyncState.successState, data: res?.data });
    } catch (err: { message: string } | any) {
      toast.error(err?.message);

      setPrimaryPaymentMethod({
        ...asyncState.failedState,
        failedMessage: err?.message,
        paymentId: id,
      });
    }
  }, []);

  const clearPostNewPaymentMethod = () => {
    setPostNewPaymentMethod(asyncState.initState);
  };

  const clearRemovePaymentMethodRequest = () => {
    setRemovePaymentMethod(asyncState.initState);
  };

  React.useEffect(() => {
    paymentsMethodsRequest();
  }, [paymentsMethodsRequest]);

  return {
    paymentsMethodsRequest,
    addPaymentMethodRequest,
    removePaymentMethodRequest,
    makePrimaryPaymentMethod,
    clearPostNewPaymentMethod,
    clearRemovePaymentMethodRequest,
    paymentMethods,
    postNewPaymentMethod,
    removePaymentMethod,
    primaryPaymentMethod,
  };
};

export default usePaymentMethod;
