import React from "react";
import "./preBabyBootCamp.scss";
import pbbc from "./bcmp.png";
import { Link } from "react-router-dom";
import { PREBABYBOOT_URL } from "../../../Utils/ServiceUrl";

const PreBabyBootCamp = () => {
  return (
    <div className="preBabyBootCamp">
      <div className="preBabyBootCampContent">
        <div className="preBabyBootCampHead">
          <h2>Pre-Baby Bootcamp</h2>
          <span>Bestseller!</span>
        </div>
        <p className="preBabyBootCampDescription">
          It’s your one-stop-shop for everything you’ll want to know before the
          big day (or night). In just one weekend, learn all the essentials plus
          everything we wish traditional hospital classes taught. All delivered
          in a non-terrifying way by registered nurses with lots of
          opportunities for questions.
        </p>
        <Link
          className="btn primary-blue-small-btn-40 preBabyBootCampBtnLink"
          to={PREBABYBOOT_URL}
        >
          Learn More
        </Link>
      </div>
      <div className="preBabyBootCampImage">
        <img src={pbbc} alt="pre baby bootcamp" />
      </div>
    </div>
  );
};

export default PreBabyBootCamp;
