import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import "./editProfile.scss";
import { Container, Row } from 'react-bootstrap';
import StepperMenu from './step/step';
import Step1 from './step/step1';
import Step2 from './step/step2';
import Step3 from './step/step3';
import { getUserBillingAPI, getUserProfileEdit } from '../../Service/update_profile';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import isMebershipAndActiveCheck from '../../Utils/membership_check';
import { useDispatch } from 'react-redux';
import { PROFILEUSER } from '../../Routes/RouteConstent';
import useStripePayments from '../../Hooks/useStripePayments';


const EditProfile = (props: any) => {
  const stripePayments = useStripePayments();

  const [activeStep, setActiveStep] = React.useState('edit');

  const [subStep, setSubStep] = useState<number>(0)
  const [subStep1, setSubStep1] = useState<number>(0)
  const [subStep2, setSubStep2] = useState<number>(1)

  const [formData1, setFormData1] = useState<any>({
    type_mom: []
  })
  const [formData2, setFormData2] = useState<any>({})
  const [formData3, setFormData3] = useState<any>({})
  const [formData4, setFormData4] = useState<any>({})
  const [feedingChoice, setFeedingChoice] = React.useState<any>([])

  const [userMember, setUserMember] = useState<any>({})
  const [memberData, setMemberData] = useState<any>([])
  const [payDetail, setPayDetail] = useState<any>([])

  const [earnCon, SetEarnCon] = useState<any>([])
  const [formInvite, setFormInvite] = useState<any>({
    name: "",
    email: ""
  })
  const [myOffer, setMyOffer] = useState<any>([])

  const [error, setError] = useState<any>({})

  const [profileData, setProfileData] = useState<any>({})
  const [openPop, setOpenPop] = React.useState<boolean>(false)
  const [allPayment, setAllPayment] = React.useState({})

  const Navigate = useNavigate()
  const dispatch = useDispatch()

  const myOffersWithPromotions = useMemo(() => {
    if (userMember?.membership?.id === 1) {
      return [
        {
          id: 2.1,
          name: "ALL Services",
          type: "percentage",
          description: [
            "Apply your discount code at checkout for all services*",
            "*Discount does not apply to daytime and overnight nursing care.",
          ],
          code: "MEMBER20",
          amount: "20",
        },
        ...myOffer,
      ];
    }
    if (userMember?.membership?.id === 2) {
      return [
        {
          id: 2.1,
          name: "Group Services",
          type: "percentage",
          description: [
            "Pre Baby Bootcamp | Veteran Bootcamp | Labor & Delivery Prep | Newborn Essentials | Breastfeeding Basics | Support Groups | Group Sleep Consultations | Group Infant and Child CPR",
          ],
          code: "GROUPPERK",
          amount: "100",
        },
        {
          id: 2.2,
          name: "Individual Services",
          type: "percentage",
          description: [
            "Virtual Feeding Consultation | 30 minute phone Consults",
          ],
          code: "INDIVIDUALPERK",
          amount: "100",
        },
        {
          id: 2.3,
          name: "Individual Services",
          type: "percentage",
          description: [
            "All other services not included above*",
            "*Discount does not apply to daytime and overnight nursing care.",
          ],
          code: "MEMBER20",
          amount: "20",
        },
        ...myOffer,
      ];
    }

    return myOffer;
  }, [myOffer, userMember]);

  let userID: any = localStorage.getItem("Nurture_user_data");
  userID = JSON.parse(userID)?.id;
  const setSubStepName = (id: any = 0, name: any) => {
    const setSubSteps: any = {
      edit: setSubStep(Number(id)),
      billing: setSubStep1(Number(id)),
      promotion: setSubStep2(Number(id))
    }
    return setSubSteps[name]
  }

  const param = new URLSearchParams(window.location.search)

  function getStepContent(stepIndex: any) {
    switch (stepIndex) {
      case "edit":
        return (
          <Step1
            formNo={subStep}
            formData1={formData1}
            setFormData1={setFormData1}
            feedingChoice={feedingChoice}
            setFeedingChoice={setFeedingChoice}
            formData2={formData2}
            setFormData2={setFormData2}
            formData3={formData3}
            setFormData3={setFormData3}
            formData4={formData4}
            setFormData4={setFormData4}
            error={error}
            setError={setError}
            openPop={openPop}
            setOpenPop={setOpenPop}
            profileData={profileData}
            getProfileBaby={getProfileBaby}
          />
        );
      case "billing":
        return (
          <Step2
            resetBilling={getUserbillingData}
            billingNo={subStep1}
            allPayment={allPayment}
            setAllPayment={setAllPayment}
            setMemberData={setMemberData}
            setUserMember={setUserMember}
            userMember={userMember}
            memberData={memberData}
            stripePayments={stripePayments}
            payDetail={payDetail}
          />
        );
      case "promotion":
        return (
          <Step3
            promoNo={subStep2}
            props={props}
            earnConst={earnCon}
            inputVal={formInvite}
            setInputVal={setFormInvite}
            offer={myOffersWithPromotions}
            value={profileData?.earn_consult_progress}
            getProfileBaby={getProfileBaby}
          />
        );
      default:
        return "";
    }
  }

  const getProfileBaby = async () => {
    try {
      let id: any = { user_id: userID }
      let res: any = await getUserProfileEdit(id)
      isMebershipAndActiveCheck(res, Navigate, toast, dispatch)
      setProfileData(res?.data)
      setFormData1(res?.data?.about_you)
      setFormData2(res?.data?.about_your_baby)
      setFormData3(res?.data?.about_your_partner)
      SetEarnCon(res?.data?.earn_consult)
      setMyOffer(res?.data?.my_offer)
    } catch (err) {
      console.log(err)
    }
  }

  const getUserbillingData = async () => {
    try {
      let res: any = await getUserBillingAPI({ user_id: userID });

      if (res?.data) {
        isMebershipAndActiveCheck(res, Navigate, toast, dispatch);
        setUserMember(res?.data?.user_membership);
        setMemberData(res?.data?.membership);
        setPayDetail(res?.data?.payment_details);
        setAllPayment(res?.data);
      } else {
        toast.error(res?.message || "Something went wrong with user/billing");
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getProfileBaby()
    getUserbillingData()
  }, [])

  useEffect(() => {
    console.log("param", param.get("stp"))
    const step: any = param.get("stp");
    setActiveStep(step)
    const subStep = param.get("sub");
    setSubStepName(subStep, subStep)
  }, [window.location.search])

  return (
    <>

      <div className="profileContainer pale-bg">
        <Container fluid>
          <div className='profile-header-container mb-4 position-relative py-5'>
            <button className="membership-back " onClick={() => Navigate(PROFILEUSER)}>BACK</button>
            <Container>
              <Row className='profile-header  '>

                <div className='col-lg-12 col-md-12'>
                  <Container className='paddingLR-0'>
                    <div className='row'>
                      <div className='col-lg-12 col-md-12'>
                        <div className='d-flex profile-header-inner w-100 justify-container-center align-items-center'>

                        </div>

                        <h3 className='m-0 p-0 edit-head text-center'><span className="strong">Edit profile</span></h3>
                      </div>

                    </div>
                  </Container>
                </div>
              </Row>
            </Container>
          </div>

          <Row>
            <div className='col-lg-12 col-md-12'>
              <div className='paddingLR-0 EditProfileWrapper'>
                <StepperMenu className="EditProfileTabs" activeStep={activeStep} setActiveStep={setActiveStep}
                  subStep={subStep} setSubStep={setSubStep} subStep1={subStep1}
                  setSubStep1={setSubStep1} subStep2={subStep2} setSubStep2={setSubStep2} />
                <div className='EditProfileContent'>
                  {getStepContent(activeStep)}
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </div>
    </>
  )
}



export default EditProfile
