import React from "react";

export type isVisible = boolean;
export type openModal = (data?: any) => void;
export type closeModal = (data?: any) => void;
export type openModalData = any;
export type closeModalData = any;

const useModalWindowControls = () => {
  const [isVisible, setIsVisible] = React.useState(false);
  const [openModalData, setOpenModalData] = React.useState<openModalData>(null);
  const [closeModalData, setCloseModalData] = React.useState<closeModalData>(null);

  const closeModal = React.useCallback((data = null) => {
    setIsVisible(false);
    setOpenModalData(null);
    setCloseModalData(data);
  }, []);

  const openModal = React.useCallback((data = null) => {
    setIsVisible(true);
    setOpenModalData(data);
    setCloseModalData(null);
  }, []);

  return { isVisible, closeModal, openModal, openModalData, closeModalData };
};

export default useModalWindowControls;
