import React from 'react'

const FavEventEmpty = ({ data }: any) => {
    return (
        <div>
            {data.length === 0 && < div className='emptys-container d-flex flex-column align-items-center justify-content-center my-5'>
                <div className='icon-container favorite'>

                </div>
                <h3>Collect your <strong>Favorites</strong></h3>
                <p>Your favorite events will appear here</p>
                {/* <button className="btn primary-blue-small-btn-40 mt-3">View Event</button> */}
            </div>}
        </div >
    )
}

export default FavEventEmpty