import React from "react";
import "./PaymentMethodForm.scss";
import { scheme, handleDate, yupValidation } from "./helpers";

interface UsePaymentMethodFormProps {
  paymentRequest?: (params: any) => void;
}

interface Errors {
  [key: string]: number;
}

export interface PaymentFields {
  firstName: string;
  lastName: string;
  exp: string;
  cvv: string;
  zipCode: string;
  cardNumber: string;
}

const usePaymentMethodForm = ({
  paymentRequest,
}: UsePaymentMethodFormProps) => {
  const [fields, setFields] = React.useState<PaymentFields>({
    firstName: "",
    lastName: "",
    exp: "",
    cvv: "",
    zipCode: "",
    cardNumber: "",
  });

  const [errors, setErrors] = React.useState<Errors>({});

  const handleChange = (evt: any) => {
    setFields((v) => ({ ...v, [evt.target.name]: evt.target.value }));
  };

  const handleNumber = (max: number) => (evt: any) => {
    setFields((v) => ({
      ...v,
      [evt.target.name]: evt.target.value.slice(0, max),
    }));
  };

  const handleExpirationDate = (evt: any) => {
    setFields((v) => ({
      ...v,
      [evt.target.name]: handleDate(evt.target.value),
    }));
  };

  const submit = async (e?: React.FormEvent<HTMLFormElement>) => {
    if (e) {
      e.preventDefault();
    }

    const { valid, errors } = await yupValidation(fields, scheme);

    setErrors(errors);

    if (valid) {
      const { firstName, lastName, exp, ...restFields } = fields;
      const [expMonth, expYear] = [exp.slice(0, 2), exp.slice(2)];

      const params = {
        ...restFields,
        cardholderName: `${firstName} ${lastName}`,
        expMonth,
        expYear,
      };

      paymentRequest && paymentRequest(params);
    }
  };

  return {
    fields,
    errors,
    handleChange,
    handleNumber,
    handleExpirationDate,
    submit,
  };
};

export default usePaymentMethodForm;
