import moment from "moment";
import React, { useState } from "react";
import parse from "html-react-parser";
import { useNavigate } from "react-router-dom";
import { MEMBERSHIP } from "../../../Routes/RouteConstent";
import ReceiptView from "../../../Common/TermConditionPopup/receiptView";
import "../editProfile.scss";
import CloseIcon from "@mui/icons-material/Close";
import {
  getDataFromLocalStorage,
  getUser,
  getUserID,
} from "../../../Service/getLocalStorage";
import { CircularProgress, Grid, Box } from "@mui/material";
import { companyCodeMembershipApi } from "../../../Service/membership";
import { toast } from "react-toastify";
import { getUserBillingAPI } from "../../../Service/update_profile";
import PaymentMethod from "../../../Common/PaymentMethod";
import ConfirmPopup from "../cancelSubscriptionPopup";
import useModalWindowControls from "../../../Hooks/useModal";
import UpgradeModal from "../UpdateTariffModals/UpgradeModal";
import DowngradeModal from "../UpdateTariffModals/DowngradeModal";
import PaymentModal from "../UpdateTariffModals/PaymentModal";
import CongratsPopUp from "../../../Common/CongratsPopUp";
import { format } from "date-fns";

function Step2({
  billingNo,
  userMember,
  setAllPayment,
  allPayment,
  memberData,
  setUserMember,
  setMemberData,
  resetBilling,
  stripePayments,
}: any) {
  const upgradeMembershipModal = useModalWindowControls();
  const downgradeMembershipModal = useModalWindowControls();
  const paymentModal = useModalWindowControls();
  const successModal = useModalWindowControls();
  const successfullyCancelledModal = useModalWindowControls();

  const [activeStep, setActiveStep] = useState<number>(0);
  const [item, setItem] = useState<any>({});
  const [open, setOpen] = useState(false);
  const [isCompanyCode, setIsCompanyCode] = useState(false);
  const [isCancel, setIsCancel] = useState(false);
  const [code, setCode] = useState<any>("");
  const [codeErr, setCodeErr] = useState<any>("");
  const [codeLoading, setCodeLoading] = useState(false);
  const Navigator = useNavigate();

  const User: any = getDataFromLocalStorage();
  const id = getUserID();

  const handelReceipt = (res: any) => {
    setOpen(true);
    setItem(res);
  };

  const getMembershipDetail = async () => {
    let userid: any = { user_id: id };
    let res: any = await getUserBillingAPI(userid);
    setUserMember(res?.data?.user_membership);
    setMemberData(res?.data?.membership);
    setAllPayment(res?.data);
  };

  const handleCodeSubscription = async (
    e: React.FormEvent<HTMLFormElement> | any
  ) => {
    try {
      e.preventDefault();

      if (!code) {
        setCodeErr("Please enter company code");
        return;
      }
      setCodeLoading(true);
      let res: any = await companyCodeMembershipApi({
        user_id: User.id,
        company_code: code,
      });
      let msgArr = res?.data?.message;
      let msg = "";
      for (let i = 0; i < msgArr.length; i++) {
        msg += msgArr[i];
      }
      if (!res?.data?.success) {
        toast.error(msg);
      } else {
        localStorage.setItem(
          "Nurture_user_data",
          JSON.stringify(res?.data?.data)
        );
        window.dispatchEvent(new Event("storage"));
        getMembershipDetail();
        toast.success(msg);
      }
      setCodeLoading(false);
    } catch (error) {
      setCodeLoading(false);
    }
  };

  const handleStepper = (step: number) => {
    const handleConfirm = (membershipId: number) => {
      paymentModal.openModal(membershipId);
      upgradeMembershipModal.closeModal();
      downgradeMembershipModal.closeModal();
    };

    const successCB = (data: any) => {
      successModal.openModal(data);
      resetBilling();
    };

    const showUpgradeFlow =
      getUser().is_stripe_membership && !userMember?.subscription?.cancel_at;

    switch (step) {
      case 0:
        return (
          <div className="col-lg-12 col-md-12 your-membership-main">
            <UpgradeModal
              isVisible={upgradeMembershipModal.isVisible}
              closeModal={upgradeMembershipModal.closeModal}
              confirm={handleConfirm}
            />
            <DowngradeModal
              isVisible={downgradeMembershipModal.isVisible}
              closeModal={downgradeMembershipModal.closeModal}
              confirm={handleConfirm}
            />
            <ConfirmPopup
              open={isCancel}
              setOpen={setIsCancel}
              otherApi={getMembershipDetail}
              data={userMember}
              setData={setUserMember}
              setMemberData={setMemberData}
              openSuccessfullyCancelledModal={
                successfullyCancelledModal.openModal
              }
            />
            <CongratsPopUp
              closeModal={successfullyCancelledModal.closeModal}
              isVisible={successfullyCancelledModal.isVisible}
            >
              <h4>Your membership has been canceled.</h4>
            </CongratsPopUp>
            <PaymentModal
              isVisible={paymentModal.isVisible}
              closeModal={paymentModal.closeModal}
              membershipId={paymentModal.openModalData}
              successCB={successCB}
            />
            <CongratsPopUp
              isVisible={successModal.isVisible}
              closeModal={successModal.closeModal}
              doneBtnTitle="Go back to my settings"
            >
              {successModal.openModalData && (
                <p>
                  {successModal.openModalData?.membershipId === 1
                    ? `Your subscription has been downgraded to the Monthly Membership. You will be charged $149 at the start of your next billing cycle on ${
                        !!successModal.openModalData?.trialEnd &&
                        format(
                          new Date(successModal.openModalData?.trialEnd),
                          "MM/dd/yyyy"
                        )
                      }.`
                    : `Your subscription has been upgraded to the Annual Membership. You will be charged $999 at the start of your next billing cycle on ${
                        !!successModal.openModalData?.trialEnd &&
                        format(
                          new Date(successModal.openModalData?.trialEnd),
                          "MM/dd/yyyy"
                        )
                      }.`}
                </p>
              )}
            </CongratsPopUp>
            {userMember?.status == 1 && (
              <div
                className={`member_plan red_member mb-3 ${userMember?.membership?.class || 'customPlanTextStyles'}`}
              >
                <div className="plan_head d-flex justify-content-between align-items-center">
                  <div>
                    <h3>
                      <span className="plan-nm">{userMember?.title} </span>
                      Membership
                    </h3>
                  </div>
                  <div className="Plan-price-main d-flex flex-column text-white">
                    <div className="d-flex Plan-price align-items-center">
                      {userMember?.base_price != 0 && (
                        <span className="pCost-old">
                          {userMember?.base_price}
                        </span>
                      )}
                      <span className="pCost">
                        {" "}
                        ${userMember?.actual_price}
                      </span>
                      <span className="membership-cycle">/mon</span>
                    </div>
                  </div>
                </div>
                <div className="plan_content">
                  <div className="d-flex w-100 justify-content-between flex-column flex-md-row">
                    <div className="info-section">
                      {userMember?.renewal_date && (
                        <p>{userMember?.renewal_date} </p>
                      )}
                      {userMember?.trial_message && (
                        <div className="trial-exp-note">
                          <i
                            className="fa fa-info-circle me-2"
                            aria-hidden="true"
                          ></i>
                          {userMember?.trial_message}
                        </div>
                      )}
                    </div>

                    {userMember?.cancel_subscription_button && (
                      <div className="btn-container mt-3 mt-md-0">
                        <button
                          className="btn primary-blue-small-btn-40"
                          disabled={userMember?.is_cancel === 1}
                          onClick={() => setIsCancel(true)}
                        >
                          {userMember?.is_cancel === 1
                            ? "Cancelled"
                            : "Cancel subscription"}
                        </button>
                      </div>
                    )}
                  </div>

                  {userMember?.upgrade_membership && (
                    <div className="new-membership-activation-info w-100 mt-3">
                      <p className="p-0 m-0">
                        {userMember?.upgrade_membership}
                      </p>
                    </div>
                  )}
                </div>
              </div>
            )}
            <div className="company-code-main">
              <div className="w-100">
                <form id="comapny-code w-100">
                  {/* <div className="custom-checkbox mb-2">
                    <div className="form-check">
                      <input className="form-check-input" type="checkbox" checked={isCompanyCode} onClick={(e: any) => setIsCompanyCode(e.target.checked)} id="customCheck1" />
                      <label className="form-check-label">Do you have company code?</label>

                    </div>
                  </div> */}
                  {isCompanyCode && (
                    <div className="company-code-block position-relative p-4">
                      <div className="w-100">
                        <div className="d-flex flex-column">
                          <div>
                            <div className="form-group industry-selectbox mb-3 w-100">
                              <label>Enter Company Code</label>
                              <input
                                type="text"
                                name="company_code"
                                value={code}
                                onChange={(e: any) => setCode(e.target.value)}
                                className="form-control"
                              />
                              <h4 className="error-msgtext">{codeErr}</h4>
                              <label className="note">
                                {" "}
                                Note - If you have your spouse’s code you can
                                also enter here.
                              </label>
                            </div>
                          </div>

                          <div className="text-center">
                            <button
                              className="btn primary-blue-small-btn-40"
                              onClick={handleCodeSubscription}
                            >
                              {codeLoading ? <CircularProgress /> : "Apply"}
                            </button>
                          </div>
                        </div>
                      </div>
                      <div onClick={() => setIsCompanyCode(false)}>
                        <CloseIcon className="close-block" />
                      </div>
                    </div>
                  )}
                </form>
              </div>
            </div>

            <div className="upgrade-membership">
              <h3 className="upgrade-msg mt-4 py-2">
                {allPayment?.save_up_to}
              </h3>
              <div className="d-flex w-100 flex-column flex-md-row">
                {memberData?.map((res: any, i: number) => (
                  <div
                    className={`member_plan ${res?.class} w-50 me-md-2 me-0 mb-3 mb-md-0 position-relative`}
                  >
                    <div className="plan_head px-4 py-4">
                      <h3>
                        <span className="plan-nm">{res?.title} </span>{" "}
                        Membership
                      </h3>
                    </div>
                    <div className="plan_content px-4 py-4">
                      <div className="Plan-price-main d-flex flex-column">
                        {parseInt(res?.percentage) != 0 && (
                          <div className="plan-saving fw-bold">
                            Save {res?.percentage}%
                          </div>
                        )}
                        <div className="d-flex Plan-price align-items-center">
                          {res?.base_price && res?.base_price != 0 && (
                            <span className="pCost-old">
                              {" "}
                              ${res?.base_price}
                            </span>
                          )}
                          <span className="pCost">${res?.amount} </span>
                          <span className="membership-cycle">/mon</span>
                        </div>
                        <div className="plan-price-Total">
                          <span className="strong">
                            ${res?.price_deduction && res?.price_deduction}
                          </span>{" "}
                          Every {res?.title} membership
                        </div>
                      </div>
                      <div className="plan-details-list">
                        {parse(String(res?.description))}
                      </div>
                      {res?.is_show_button && (
                        <button
                          className="btn btn_trial text-white"
                          onClick={() =>
                            showUpgradeFlow
                              ? handleUpdateMembership(res?.title === "Annual")
                              : handlerMembership(res)
                          }
                        >
                          {!!showUpgradeFlow &&
                            (res?.title === "Annual"
                              ? "Upgrade Membership"
                              : "Downgrade Membership")}
                          {!showUpgradeFlow && "Purchase Membership"}
                        </button>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        );
    }
  };
  const handleUpdateMembership = (upgrade: any) => {
    if (upgrade) {
      upgradeMembershipModal.openModal();
    } else {
      downgradeMembershipModal.openModal();
    }
  };
  const handlerMembership = (res: any) => {
    if (User && User?.is_stripe_membership == 1) {
      Navigator(MEMBERSHIP + `/${res?.id}`, {
        state: { tab: 2, membership: res, isCancelled: true },
      });
    } else {
      Navigator(MEMBERSHIP + `/${res?.id}`);
    }
  };
  const paymentsHistory = React.useMemo(() => {
    const payments = stripePayments.stripePayments.data?.user?.payments || [];
    return payments.reduce((acc: any, payment: any) => {
      const history = payment.lines.data.map((h: any) => ({ ...h, payment }));
      return [...acc, ...history];
    }, []);
  }, [stripePayments.stripePayments.data?.user?.payments]);

  const getAmountWithDiscount = (amount_off: number, amount: number) => {
    const calcAmount = (amount - amount_off) / 100;

    return calcAmount >= 0 ? `$${calcAmount.toFixed(2)}` : "$0.00";
  };

  const getAmountWithPercentDiscount = (
    percent_off: number,
    amount: number
  ) => {
    return `$${(amount - amount * (percent_off / 100)).toFixed(2)}`;
  };

  return (
    <>
      {billingNo == 0 && <>{handleStepper(activeStep)}</>}

      {billingNo == 1 && (
        <>
          <ReceiptView open={open} setOpen={setOpen} value={item} />
          <Grid container xs={12} alignItems="flex-start">
            <Grid item xs={12} lg={5.3}>
              <PaymentMethod />
            </Grid>
            <Grid item xs={12} lg={6.7}>
              <Box display={"flex"} flexDirection={"column"} height={"100%"}>
                <h5 className="section-title">Payment History</h5>
                {stripePayments.stripePayments.pending ? (
                  <div className="paymentMethodEmpty my-3 d-flex w-100 edit-profile-payment-history-container align-items-center justify-content-center py-3 px-4">
                    <CircularProgress />
                  </div>
                ) : (
                  <div className="my-2 d-flex w-100 edit-profile-payment-history-container align-items-start py-3 px-3 px-lg-4 flex-column">
                    <div className="payment-history-list w-100 d-flex justify-content-between my-2">
                      <div className="payment-for-title">
                        <p className="payment-name m-0 text-start">
                          <strong>Name</strong>
                        </p>
                      </div>
                      <div
                        className="payment-date mx-2"
                        style={{ maxWidth: "68px" }}
                      >
                        <p className="payment-date m-0 text-start">
                          <strong>Date</strong>
                        </p>
                      </div>
                      <div className="payment-amount">
                        <p className="paid-amount m-0 text-start">
                          <strong>Amount</strong>
                        </p>
                      </div>
                      <div className="payment-amount mx-2">
                        <p className="payment-name m-0 text-start">
                          <strong>Discount name</strong>
                        </p>
                      </div>
                      <div className="payment-amount mx-2">
                        <p className="payment-name m-0 text-start">
                          <strong>Final amount</strong>
                        </p>
                      </div>
                      <div className="payment-amount mx-2">
                        <p className="payment-name m-0 text-start">
                          <strong>Status</strong>
                        </p>
                      </div>
                      <div className="payment-details">
                        <p className="action m-0 text-end">
                          <strong>Receipt</strong>
                        </p>
                      </div>
                    </div>
                    {paymentsHistory.map((res: any) => (
                      <div
                        className="payment-history-list w-100 d-flex justify-content-between align-items-center my-2"
                        key={res.id}
                      >
                        <div className="payment-for-title">
                          <p className="payment-name m-0 text-start">
                            {res?.description}
                          </p>
                        </div>

                        <div
                          className="payment-date mx-2"
                          style={{ maxWidth: "70px" }}
                        >
                          {res?.period?.start === res?.period?.end && (
                            <p className="payment-date m-0 text-start">
                              {moment(res?.period?.start * 1000).format(
                                "M/D/Y h:mm a"
                              )}
                            </p>
                          )}

                          {res?.period?.start !== res?.period?.end && (
                            <>
                              <p className="payment-date m-0 text-start">
                                {moment(res?.period?.start * 1000).format(
                                  "M/D/Y"
                                )}{" "}
                                -{" "}
                                {moment(res?.period?.end * 1000).format(
                                  "M/D/Y"
                                )}
                              </p>
                            </>
                          )}
                        </div>

                        <div className="payment-amount">
                          <p className="paid-amount m-0 text-start">
                            ${(res?.amount / 100).toFixed(2)}
                          </p>
                        </div>

                        <div className="payment-amount mx-2">
                          <p className="payment-name m-0 text-start">
                            {res?.payment?.discount?.coupon?.name}
                          </p>
                        </div>

                        <div className="payment-amount mx-2">
                          <p className="payment-name m-0 text-start">
                            {res?.payment?.discount?.coupon?.percent_off && (
                              <>
                                {getAmountWithPercentDiscount(
                                  res?.payment?.discount?.coupon?.percent_off,
                                  res?.amount
                                )}
                              </>
                            )}
                            {res?.payment?.discount?.coupon?.amount_off && (
                              <>
                                {getAmountWithDiscount(
                                  res?.payment?.discount?.coupon?.amount_off,
                                  res?.amount
                                )}
                              </>
                            )}
                            {!res?.payment?.discount?.coupon?.percent_off &&
                              !res?.payment?.discount?.coupon?.amount_off &&
                              `$${(res?.amount / 100).toFixed(2)}`}
                          </p>
                        </div>

                        <div className="payment-amount mx-2">
                          <p className="payment-name m-0 text-start">
                            {res?.payment?.status}
                          </p>
                        </div>

                        <div
                          className="payment-details"
                          onClick={() => handelReceipt(res)}
                        >
                          <p
                            className="action m-0 text-end"
                            style={{ cursor: "pointer" }}
                          >
                            <a>View Receipt</a>
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </Box>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
}

export default Step2;
