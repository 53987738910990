export const CityStateZipValSub = (value: any) => {
    // !value?.zip_code || value?.zip_code && value?.zip_code?.length !== 5 ||
    if ( !value?.state || !value?.city) {
        let errors: any = {}
        // if (value?.zip_code && value?.zip_code?.length !== 5) {
        //     errors.zip_code = "Please enter valid zip code";
        // }
        // if (!value?.zip_code) {
        //     errors.zip_code = "Please enter zip code";
        // }
        if (!value?.state) {
            errors.state = "Please enter state name";
        }
        if (!value?.city) {
            errors.city = "Please enter city name";
        }
        return errors
    } else {
        return false
    }
}

