import React, { useEffect } from "react";
import { Container, Row, Button } from "react-bootstrap";
import hero from "../../Assets/img/live-webinar.jpg";
import "./LiveWebinar.scss";
import { getCalenderApi } from "../../Service/contact";
import { getUserID } from "../../Service/getLocalStorage";
import EventCard from "../../Common/EventCard/EventCard";
import { EventLikeSetState } from "../../Utils/Events/Like";
import { EventFavSetSate } from "../../Utils/Events/Fav";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import NoUpEvents from "../../Common/EmptyState/Events/noUpEvents";
import { HeroBanner } from "../../Common/HeroBanner";
import useScrollToElementId from "../../Hooks/useScrollToElementId";
import { useLocation } from "react-router-dom";
import { isLoggedInSelector } from "../../Redux/user/user";
import { useSelector } from "react-redux";

const LiveWebinars = () => {
  const [wData, setWData] = React.useState<any>([]);
  const [allWData, setALLWData] = React.useState<any>([]);
  const months = React.useMemo(getNext12Months, []);
  const isUserLoggedIn = useSelector(isLoggedInSelector);

  let userId = getUserID();
  const getEventData = async (date?: any) => {
    try {
      let res: any = await getCalenderApi({
        date: months[0],
        user_id: userId,
      });
      let data1: any = res?.data?.data;
      setALLWData(data1);
      let data = res?.data?.data;

      if (data.length > 6) {
        data = data.slice(0, 6);
      }
      setWData(data);
    } catch (error) {}
  };

  useEffect(() => {
    getEventData();
  }, []);

  const updateData = (
    type: string,
    crrdata: any,
    isUpdate: boolean,
    isSuccess: any
  ) => {
    if (isSuccess) return;
    if (type === "like") {
      EventLikeSetState(crrdata, wData, setWData, isUpdate);
    } else if (type === "fav") {
      EventFavSetSate(crrdata, wData, setWData);
    }
  };
  const handleDataSubmit = async (index: any, arg: any) => {
    try {
      let res: any = await getCalenderApi({
        date: arg?.props?.children,
        user_id: userId,
      });
      let data = res?.data?.data;
      let limit;

      if (data.length > 6) {
        limit = data.slice(6 - data.length);
      } else {
        limit = data;
      }
      setWData(limit);
      setALLWData(res?.data?.data);
    } catch (error) {}
  };
  const ViewAll = () => {
    setWData(allWData);
  };

  const scrollToEventId = useLocation().state;

  useScrollToElementId({
    prefix: "event_card_",
    doScroll: !!wData.length,
    scrollId: scrollToEventId,
  });

  return (
    <div>
      <Container fluid className="live-webinar-container">
        <div className="py-3">
          <HeroBanner hero={hero} />
        </div>
        <Row no-gutter className="pale-bg py-4">
          <div className="col-lg-12 col-md-12 livewebinar-discription-section">
            <div className="container">
              <Row className="justify-content-center">
                <div className="col-lg-10 col-md-10">
                  <h3 className="text-center livewebinar-section-title myz-3">
                    Live Webinars & <span className="strong red">Events</span>
                  </h3>
                  <div className="px-5 livewebinar_description">
                    <p className="text-center">
                      NAPS™ is about more than classes, support groups and
                      education. Our mission is to build a community centered
                      around families. We want to make information easy and
                      accessible to everyone through our virtual and in person
                      offerings. Browse our upcoming lineup of virtual classes,
                      webinars, and in person events.
                    </p>
                  </div>
                </div>
              </Row>
            </div>
          </div>
        </Row>

        {isUserLoggedIn && 
          (<Row>
            <div className="col-lg-12 col-md-12">
              <Container>
                <div className="col-lg-12 col-md-12 my-3 live-event-calender">
                  <Carousel
                    className="text-slider position-relative"
                    showIndicators={false}
                    onChange={handleDataSubmit}
                  >
                    {months.map((date: any) => (
                      <div>{date}</div>
                    ))}
                  </Carousel>
                </div>
              </Container>
            </div>
          </Row>)
        }
        <Row className="podcast-form my-4">
          <div className="col-lg-12 col-md-12">
            <div className="container">
              {wData && wData.length > 0 ? (
                <EventCard data={wData} upDateData={updateData} />
              ) : (
                <NoUpEvents />
              )}
              <div className="col-md-12 col-lg-12 text-center">
                {allWData &&
                  allWData.length > 6 &&
                  allWData.length !== wData.length && (
                    <Button
                      className="btn primary-blue-small-btn-40"
                      onClick={ViewAll}
                    >
                      View more
                    </Button>
                  )}
              </div>
            </div>
          </div>
        </Row>
      </Container>
    </div>
  );
};

function getNext12Months() {
  const currentYear = new Date().getFullYear();
  const monthIndex = new Date().getMonth();

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  return [
    ...months.slice(monthIndex).map((month) => `${month} ${currentYear}`),
    ...months
      .slice(0, monthIndex)
      .map((month) => `${month} ${currentYear + 1}`),
  ];
}

export default LiveWebinars;
