import { getUserID } from "./getLocalStorage"
import httpService from "./httpService"

export const getPostLoginDataApi = () => {

    return httpService.post("/home", { user_id: getUserID() })
}

export const updateQuickScreenApi = () => {
    return httpService.post("welcome/screen/status/update", {
        user_id: getUserID(),
        welcome_screen_status: 1
    })
}