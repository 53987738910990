import { useCallback, useState } from "react";
import * as asyncState from "../Utils/asyncState";
import { toast } from "react-toastify";

const useRequestState = <T>({
  initData,
  onSuccess,
  asyncRequest,
}: {
  initData: T ;
  onSuccess?: (response: any) => void;
  asyncRequest: (...params: any[]) => Promise<any>;
}) => {
  const [requestState, setRequestState] = useState<
    asyncState.AsyncState & { data: T }
  >({
    ...asyncState.initState,
    data: initData,
  });

  const request = useCallback(
    async (...params) => {
      try {
        setRequestState({
          ...asyncState.pendingState,
          data: initData,
        });
        const res: any = await asyncRequest(...params);

        if (res.status !== 200) {
          throw new Error(res?.message || res?.data?.message?.join(" "));
        }

        setRequestState({
          ...asyncState.successState,
          data: initData,
        });

        onSuccess && onSuccess(res);
      } catch (err) {
        const message = (err as { message: string })?.message;
        toast.error(message);
        setRequestState({
          data: initData,
          ...asyncState.failedState,
          failedMessage: message,
        });
      }
    },
    [asyncRequest, initData, onSuccess]
  );

  return { requestState, request, setRequestState };
};

export default useRequestState;
