import React from "react";
import "./Membership.scss";
import MembershipDetail from "./membershipDetails";
import useModalWindowControls from "../../Hooks/useModal";
import ConfirmModal from "../../Common/ConfirmModal";
import { useNavigate, useSearchParams } from "react-router-dom";
import { MEMBERSHIP } from "../../Routes/RouteConstent";

const Step4 = ({
  data,
  handleNext,
  earlySubscribeAsyncState,
  annualMembership,
}: any) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const modal = useModalWindowControls();

  const isBumpUser = searchParams.get("code") === "BUMPFREE";

  return (
    <div>
      <ConfirmModal
        isVisible={modal.isVisible}
        closeModal={modal.closeModal}
        confirmBtnTitle="Choose annual"
        confirmBtnWhiteTitle="Confirm"
        confirmBtnCB={() => {
          modal.closeModal();
          navigate(MEMBERSHIP + `/${2}`, {
            state: { tab: 2, membership: annualMembership },
          });
        }}
        confirmBtnWhiteCB={() => handleNext(modal.openModalData)}
      >
        <div className="text-white">
          You have chosen a monthly membership. Are you sure you want to proceed
          with just a monthly plan vs. the annual plan? Annual Membership breaks
          down to less than $85 a month and includes classes, support group, 1:1
          calls and consultations.
        </div>
      </ConfirmModal>
      <MembershipDetail
        key={data?.id}
        couponCode={searchParams.get("code")}
        data={data}
        handleNext={data.id === 2 || isBumpUser ? handleNext : modal.openModal}
        type="member"
        earlySubscribeAsyncState={earlySubscribeAsyncState}
      />
    </div>
  );
};

export default Step4;
