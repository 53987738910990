import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Button, Image, ListGroup, Carousel } from 'react-bootstrap';

import "../stage.scss";
import "../Stage2/Stage2.scss";
import stage_baby_feature from "../../../Assets/img/stage/expecting-home-feature.jpg";
import parent_way from "../../../Assets/img/stage/parent-way-mockup.png";
import unswaddled_main from "../../../Assets/img/stage/unswaddled-main.jpg";
import Daytime_Overnigh_Nursing_Care from "../../../Assets/img/stage/Daytime_Overnigh_Nursing_Care.jpg";
import Lactation_Consultation from "../../../Assets/img/stage/Lactation_Consultation_offer.jpg";
import support_group from "../../../Assets/img/stage/support-group.jpg";
import newborn_long_sleep from "../../../Assets/img/stage/newborn-log-sleep.jpg";
import consult_nurse from "../../../Assets/img/stage/consult-nurse.jpg";
import Refusals_Resistance_Strikes from "../../../Assets/img/stage/Refusals_Resistance_Strikes.jpg";
import PodcastUI from '../../../Common/PodcastUi/PodcastUI';
import LockScreen from '../../../Common/LockScreen/LockScreen';
import StreetCred from '../../../Common/streetCred/streetCred';
import { getStagesDataApi } from '../../../Service/stage';
import BlogsBlock from '../../../Common/BlogCard/BlogsBlock';
import { NavServiceUrl } from '../../../Utils/ConsultRoute';
import { useNavigate } from 'react-router-dom';
import { COURSE, COURSEVIEW, DAYTIMEOVERNIGHT, LACTATIONCONSULT, LIVEWEBINAR, SUPPORTGROUP } from '../../../Routes/RouteConstent';
import LandingDescription from '../../../Common/LandingDescription';
import { HeroBannerHorizontal}  from '../../../Common/HeroBanner';
import hero from "../../../Assets/img/stage/0_3_banner.png";
import heroMobile from "../../../Assets/img/stage/0_3_banner_mobile.png";

const Stage2 = () => {

  const [data, setData] = useState([])

  const Navigate = useNavigate()

  const fetchData = async () => {
    try {
      let res: any = await getStagesDataApi({ stage: "stage_2" })
      if (res?.data?.success) setData(res?.data?.blogs)
    } catch (error: any) {

    }
  }
  const serviceNavigate = (prefix: any) => {
    let url = NavServiceUrl(prefix)

    Navigate(url)
  }
  useEffect(() => { fetchData() }, [])

  return (
    <div className='container-fluid stage-main stage2'>
      <div className="py-3">
        <HeroBannerHorizontal
          hero={hero}
          title={
            <>
                Parenting a<div className="strong pink" >Newborn</div>
            </>
          }
        />
      </div>

      <LandingDescription
        ySpace
        content={[
          <>
            <span className="strong">
              Why did they let me take this baby home?
            </span> {' '}
            If you’ve asked yourself this in those first few days, weeks, or
            months home with your newborn…{" "}
            <span className="strong">
              you’re in the right place!
            </span>{" "}
            We’re excited to join you as you take your first steps into your
            parenting journey, or re-enter the newborn phase with another baby.
          </>,
          <>
            That’s why our support services and classes,{" "}
            <span className="strong">
              all provided by experienced Registered Nurses,
            </span>{" "}
            are designed to empower you — to help you feel more{" "}
            <span className="strong">confident</span> and{" "}
            <span className="strong">comfortable </span> as you build your
            skills and wisdom as a parent.
          </>,
        ]}
      />

      <Row className="pale-bg">
        <div className='col-lg-12 col-md-12 my-3'>
          <Container>
            <Row className="justify-content-center my-md-5 my-3 stage-offering-header">
              <div className='col-lg-12 col-md-12 col-sm-12'>
                <div className="section-header text-center">
                  <h2 className="home-section-title py-0">Our<span className="strong pink"> Offerings</span></h2>
                </div>
              </div>
              <div className="col-lg-8 col-md-8 mt-2">
                <h3 className="text-center stage-description">Get the most out of our team of <span className='strong'>experienced nurses </span> with these services.</h3>
              </div>
            </Row>

            <Row className='stages-specific-offering mb-5 d-none d-sm-none d-md-block d-lg-block d-xl-block'>
              <Carousel controls={false} >
                <Carousel.Item>
                  <Container>
                    <Row className='align-items-stretch'>
                      <div className='col-lg-4 col-md-6 mb-4 '>
                        <Card className='video-container-main'>
                          <div className='video-container-inner'>
                            <img src={Daytime_Overnigh_Nursing_Care} className='image-fluid' />
                          </div>
                          <Card.Body className='position-relative'>
                            <div className='d-flex align-items-center justify-content-between mb-2'>
                              <Card.Title>Daytime and Overnight Nursing Care</Card.Title>
                            </div>
                            <Card.Text>
                              <p><span className='strong'>The care you received in the hospital, right in your home!</span> Our maternal-child health Registered Nurses come to your home to care for you and your baby during the day or overnight.</p>
                            </Card.Text>

                            <div className="d-flex justify-content-between mt-3">
                              <button className="buy_now btn buy-now" onClick={() => Navigate(DAYTIMEOVERNIGHT)}>Learn More</button>
                            </div>
                          </Card.Body>
                        </Card>
                      </div>

                      <div className='col-lg-4 col-md-6 mb-4'>
                        <Card className='video-container-main'>
                          <div className='video-container-inner'>
                            <img src={Lactation_Consultation} className='image-fluid' />
                          </div>
                          <Card.Body className='position-relative'>
                            <div className='d-flex flex-wrap align-items-center justify-content-between mb-2'>
                              <Card.Title>Lactation Consultation* </Card.Title>
                              <div className='cursive pink pt-3 fs-4'>Now accepting insurance!</div>
                            </div>
                            <Card.Text>
                              <p><span className='strong'>Need one-on-one support?</span> Talk to a NAPS maternal-child health nurse. Popular newborn  topics include: fussy/colociky baby, daytime & overnight sleep, reflux vs. sensitivity/allergy, exploring feeding options.  </p>
                            </Card.Text>

                            <div className="d-flex justify-content-between mt-3">
                              <button className="buy_now btn buy-now" onClick={() => Navigate(LACTATIONCONSULT)}>Learn More</button>
                            </div>
                          </Card.Body>
                        </Card>
                      </div>

                      <div className='col-lg-4 col-md-6 mb-4'>
                        <Card className='video-container-main'>
                          <div className='video-container-inner'>
                            <img src={support_group} className='image-fluid' />
                          </div>
                          <Card.Body className='position-relative'>
                            <div className='d-flex align-items-center justify-content-between mb-2'>
                              <Card.Title>Support Groups*</Card.Title>
                            </div>
                            <Card.Text>
                              <p><span className='strong'>Your village starts here.</span> Whether you’re looking for our signature mom survival groups (MSGs) or a free feeding support group to test the waters, we will make you feel heard, validated, and connected. </p>
                            </Card.Text>

                            <div className="d-flex justify-content-between mt-3">
                              <button className="buy_now btn buy-now" onClick={() => Navigate(SUPPORTGROUP)}>Learn More</button>
                            </div>
                          </Card.Body>
                        </Card>
                      </div>
                    </Row>
                  </Container>
                </Carousel.Item>
                <Carousel.Item>
                  <Container>
                    <Row className='align-items-stretch stages-specific-offering'>
                      <div className='col-lg-4 col-md-6 mb-4'>
                        <Card className='video-container-main'>
                          <div className='video-container-inner'>
                            <img src={newborn_long_sleep} className='image-fluid' />
                          </div>
                          <Card.Body className='position-relative'>
                            <div className='d-flex align-items-center justify-content-between mb-2'>
                              <Card.Title>How to Get Your Newborn to Sleep Longer at Night*</Card.Title>
                            </div>
                            <Card.Text>
                              <p><span className='strong'>Parenting a newborn is hard.</span> Let's help make it easier by teaching you 4 ways to get your baby to sleep better at night. Don’t forget to bring your partner to learn how to effectively split up the nights too!
                              </p>
                            </Card.Text>
                            <div className="d-flex justify-content-between mt-3">
                              <button className="buy_now btn buy-now" onClick={() => Navigate(LIVEWEBINAR, {state: 12})}>Learn More</button>
                            </div>
                          </Card.Body>
                        </Card>
                      </div>

                      <div className='col-lg-4 col-md-6 mb-4'>
                        <Card className='video-container-main'>
                          <div className='video-container-inner'>
                            <img src={consult_nurse} className='image-fluid' />
                          </div>
                          <Card.Body className='position-relative'>
                            <div className='d-flex align-items-center justify-content-between mb-2'>
                              <Card.Title>Consult a Nurse*</Card.Title>
                            </div>
                            <Card.Text>
                              <p><span className='strong'>Need some one-on-one parental support?</span> Reserve some private time with one of our Registered Nurses. During these 30 minute consultations, you can ask about anything from pregnancy to newborn care to difficult toddler behaviors.  </p>
                            </Card.Text>

                            <div className="d-flex justify-content-between mt-3">
                              <button className="buy_now btn buy-now" onClick={() => serviceNavigate("service_2")}>Learn More</button>
                            </div>
                          </Card.Body>
                        </Card>
                      </div>

                      <div className='col-lg-4 col-md-6 mb-4'>
                        <Card className='video-container-main'>
                          <div className='video-container-inner'>
                            <img src={Refusals_Resistance_Strikes} className='image-fluid' />
                          </div>
                          <Card.Body className='position-relative'>
                            <div className='d-flex align-items-center justify-content-between mb-2'>
                              <Card.Title>Bottle Troubles: Refusals, Resistance and Strikes*</Card.Title>
                            </div>
                            <Card.Text>
                              <p><span className='strong'>Refusal, Resistance and Strike, OH MY!</span> Learn strategies to help your baby more easily take a bottle, including trying to identify the issue and address it with a step by step plan.</p>
                            </Card.Text>

                            <div className="d-flex justify-content-between mt-3">
                              <button className="buy_now btn buy-now" onClick={() => Navigate(`${COURSEVIEW}bottle-troubles-resistance-and-refusals`)}>Learn More</button>
                            </div>
                          </Card.Body>
                        </Card>
                      </div>
                    </Row>
                  </Container>
                </Carousel.Item>
              </Carousel>
            </Row>

            <Row className='stages-specific-offering mb-5 d-block d-sm-block d-md-none d-lg-none d-xl-none'>
              <Carousel controls={false}>
                <Carousel.Item>
                  <Container className='g-0'>
                    <Row className='align-items-stretch g-0'>
                      <div className='col-sm-12 mb-4'>
                        <Card className='video-container-main'>
                          <div className='video-container-inner'>
                            <img src={Daytime_Overnigh_Nursing_Care} className='image-fluid' />
                          </div>
                          <Card.Body className='position-relative'>
                            <div className='d-flex align-items-center justify-content-between mb-2'>
                              <Card.Title>Daytime and Overnight Nursing Care</Card.Title>
                            </div>
                            <Card.Text>
                              <p><span className='strong'>The care you received in the hospital, right in your home!</span> Our maternal-child health Registered Nurses come to your home to care for you and your baby during the day or overnight.</p>
                            </Card.Text>
                            <div className="d-flex justify-content-between mt-3">
                              <button className="buy_now btn buy-now" onClick={() => Navigate(DAYTIMEOVERNIGHT)}>Learn More</button>
                            </div>
                          </Card.Body>
                        </Card>
                      </div>
                    </Row>
                  </Container>
                </Carousel.Item>

                <Carousel.Item>
                  <Container className='g-0'>
                    <Row className='align-items-stretch g-0'>
                      <div className='col-sm-12 mb-4'>
                        <Card className='video-container-main'>
                          <div className='video-container-inner'>
                            <img src={Lactation_Consultation} className='image-fluid' />
                          </div>
                          <Card.Body className='position-relative'>
                            <div className='d-flex flex-wrap align-items-center justify-content-between mb-2'>
                              <Card.Title>Lactation Consultation* </Card.Title>
                              <div className='cursive pink pt-3 fs-5'>Now accepting insurance!</div>
                            </div>
                            <Card.Text>
                            <p><span className='strong'>Need one-on-one support?</span> Talk to a NAPS maternal-child health nurse. Popular newborn  topics include: fussy/colociky baby, daytime & overnight sleep, reflux vs. sensitivity/allergy, exploring feeding options.  </p>
                            </Card.Text>
                            <div className="d-flex justify-content-between mt-3">
                              <button className="buy_now btn buy-now" onClick={() => Navigate(LACTATIONCONSULT)}>Learn More</button>
                            </div>
                          </Card.Body>
                        </Card>
                      </div>
                    </Row>
                  </Container>
                </Carousel.Item>

                <Carousel.Item>
                  <Container className='g-0'>
                    <Row className='align-items-stretch g-0'>
                      <div className='col-sm-12 mb-4'>
                        <Card className='video-container-main'>
                          <div className='video-container-inner'>
                            <img src={support_group} className='image-fluid' />
                          </div>
                          <Card.Body className='position-relative'>
                            <div className='d-flex align-items-center justify-content-between mb-2'>
                              <Card.Title>Support Groups*</Card.Title>
                            </div>
                            <Card.Text>
                              <p><span className='strong'>Your village starts here.</span> Whether you’re looking for our signature mom survival groups (MSGs) or a free feeding support group to test the waters, we will make you feel heard, validated, and connected. </p>
                            </Card.Text>

                            <div className="d-flex justify-content-between mt-3">
                              <button className="buy_now btn buy-now" onClick={() => Navigate(SUPPORTGROUP)}>Learn More</button>
                            </div>
                          </Card.Body>
                        </Card>
                      </div>
                    </Row>
                  </Container>
                </Carousel.Item>

                <Carousel.Item>
                  <Container className='g-0'>
                    <Row className='align-items-stretch g-0'>
                      <div className='col-sm-12 mb-4'>
                        <Card className='video-container-main'>
                          <div className='video-container-inner'>
                            <img src={newborn_long_sleep} className='image-fluid' />
                          </div>
                          <Card.Body className='position-relative'>
                            <div className='d-flex align-items-center justify-content-between mb-2'>
                              <Card.Title>How to Get Your Newborn to Sleep Longer at Night*</Card.Title>
                            </div>
                            <Card.Text>
                              <p><span className='strong'>Parenting a newborn is hard.</span> Let's help make it easier by teaching you 4 ways to get your baby to sleep better at night. Don’t forget to bring your partner to learn how to effectively split up the nights too!</p>
                            </Card.Text>
                            <div className="d-flex justify-content-between mt-3">
                              <button className="buy_now btn buy-now" onClick={() => Navigate(LIVEWEBINAR, {state: 12})}>Learn More</button>
                            </div>
                          </Card.Body>
                        </Card>
                      </div>
                    </Row>
                  </Container>
                </Carousel.Item>

                <Carousel.Item>
                  <Container className='g-0'>
                    <Row className='align-items-stretch g-0'>
                      <div className='col-sm-12 mb-4'>
                        <Card className='video-container-main'>
                          <div className='video-container-inner'>
                            <img src={consult_nurse} className='image-fluid' />
                          </div>
                          <Card.Body className='position-relative'>
                            <div className='d-flex align-items-center justify-content-between mb-2'>
                              <Card.Title>Consult a Nurse* </Card.Title>
                            </div>
                            <Card.Text>
                              <p><span className='strong'>Need some one-on-one parental support?</span> Reserve some private time with one of our Registered Nurses. During these 30 minute consultations, you can ask about anything from pregnancy to newborn care to difficult toddler behaviors.  </p>
                            </Card.Text>

                            <div className="d-flex justify-content-between mt-3">
                              <button className="buy_now btn buy-now" onClick={() => serviceNavigate("service_2")}>Learn More</button>
                            </div>
                          </Card.Body>
                        </Card>
                      </div>
                    </Row>
                  </Container>
                </Carousel.Item>

                <Carousel.Item>
                  <Container className='g-0'>
                    <Row className='align-items-stretch g-0'>
                      <div className='col-sm-12 mb-4'>
                        <Card className='video-container-main'>
                          <div className='video-container-inner'>
                            <img src={Refusals_Resistance_Strikes} className='image-fluid' />
                          </div>
                          <Card.Body className='position-relative'>
                            <div className='d-flex align-items-center justify-content-between mb-2'>
                              <Card.Title>Bottle Troubles: Refusals, Resistance and Strikes*</Card.Title>
                            </div>
                            <Card.Text>
                              <p><span className='strong'>Refusal, Resistance and Strike, OH MY!</span> Learn strategies to help your baby more easily take a bottle, including trying to identify the issue and address it with a step by step plan.  </p>
                            </Card.Text>

                            <div className="d-flex justify-content-between mt-3">
                              <button className="buy_now btn buy-now" onClick={() => Navigate(`${COURSEVIEW}bottle-troubles-resistance-and-refusals`)}>Learn More</button>
                            </div>
                          </Card.Body>
                        </Card>
                      </div>
                    </Row>
                  </Container>
                </Carousel.Item>
              </Carousel>
            </Row>

          </Container>
        </div>
      </Row>

      <Row className='pale-bg'>
        <div className='col-lg-12 col-md-12 stage-common-sec'>
          <Row>
            <LockScreen />
          </Row>

          <Row className='py-4'>
            <div className='col-lg-12 col-sm-12'>
              <PodcastUI/>                            
            </div>
          </Row>

          <Row >
            <div className='col-lg-12 col-md-12'>
              <div className='container d-none d-sm-block d-md-block d-lg-block d-xl-block'>
                <div className='row'>
                  <div className='col-lg-12 col-md-12'>
                    <hr ></hr>
                  </div>
                </div>
              </div>
            </div>
          </Row>

          {/* <Row >
            <div className='col-lg-12 col-md-12'>
              <div className='container d-none d-sm-block d-md-block d-lg-block d-xl-block'>
                <div className='row'>
                  Bloggs
                </div>
              </div>
            </div>
          </Row> */}
          <BlogsBlock news={data} />


          <StreetCred data={testimonialData} />
        </div>
      </Row>

    </div>
  )
}
let testimonialData = [
  {
    name: "Cailtin W.",
    data: "Just wanted to share with you how AMAZING Katie was during our lactation consult. I stopped just short of asking her to move in with us. 😂 She was so supportive and informative. She answered all of my questions and just made me feel like I was doing a great job. We've recently gone from 1 to 2 (loved your podcast on this topic, by the way!) and life feels so hectic. I didn't breastfeed very well, or for very long, with my first child and have been doing it a lot more with our second, so it's all new to me. Katie provided me with great tips and tricks to make feeds easier and more productive. She is a true gem and I'm so, so grateful it was her who came to our house, soothed my nerves and boosted my spirits.  I ended up signing up for the Veteran's Mom's Group because she reminded me how amazing the nurses at NAPS are and how important it is to feel supported by your village during this crazy newborn time."
  },
  {
    name: "Mary K.",
    data: "The Mom’s Survival Group from NAPS provides new and veteran mothers alike with a lifeline of community and expertise. For me, the postpartum months were easier to navigate knowing that I had regular check ins with fellow moms led by a NAPS expert—no question or discussion topic was off the table in this incredibly supportive platform. My MSG text chain is still alive and well after almost a year; we share photos, triumphs, frustrations and recipes. How wonderful to be empowered by this group of amazing women! I can honestly say that NAPS MSG is one of the best self care decisions you can make for yourself!"
  },
  {
    name: "Hannah J.",
    data: "Just wanted to say thank you so much for everything you & the NAPS crew do. The ease of just being able to jump on 'Ask A Nurse' and ask you a question is mind blowing.  Every parent should have access to resources like this!"
  }
]
export default Stage2