import React from "react";
import DateField from "../Components/DateField";

export const initFields = {
  dateOfConsultation: "",
  timeOfConsultation: "",
  clientFirstName: "",
  clientLastName: "",
  babyFirstName: "",
  babyLastName: "",

  babyDateOfBirth: "",
  consultantFirstName: "",
  consultantLastName: "",
  pediatricianFirstName: "",
  pediatricianLastName: "",
  OBFirstName: "",
  OBLastName: "",
  reasonForConsultation: "",
};

export const requiredFields = [
  "dateOfConsultation",
  "clientFirstName",
  "clientLastName",
  "babyFirstName",
  "babyLastName",
  "babyDateOfBirth",
  "consultantFirstName",
  "consultantLastName",
  "pediatricianFirstName",
  "pediatricianLastName",
  "OBFirstName",
  "OBLastName",
  "reasonForConsultation",
];
interface Props {
  handleChangeDate: any;
  handleChange: any;
  fields: { [key: string]: any };
  errors: { [key: string]: string };
}

export const ConsultationBasicDataForm = ({
  handleChangeDate,
  handleChange,
  fields,
  errors,
}: Props) => {
  return (
    <>
      <div className="col-lg-6 col-md-6 mb-3">
        <label>Date of Consultation (required)</label>
        <DateField
          value={fields.dateOfConsultation}
          onChange={handleChangeDate("dateOfConsultation")}
        />
        <h4 className="error-msgtext">{errors.dateOfConsultation}</h4>
      </div>

      <div className="form-group col-lg-12 col-md-12 mb-3">
        <label>Time of Consultation</label>
        <input
          type="text"
          value={fields.timeOfConsultation}
          name="timeOfConsultation"
          onChange={handleChange}
          className="form-control"
        />
      </div>

      <label>Client Name (required)</label>
      <div className="row">
        <div className="form-group col-lg-6 col-md-6 mb-3">
          <label className="tinyLabel">First Name</label>
          <input
            type="text"
            value={fields.clientFirstName}
            name="clientFirstName"
            onChange={handleChange}
            className="form-control"
          />
          <h4 className="error-msgtext">{errors.clientFirstName}</h4>
        </div>
        <div className="form-group col-lg-6 col-md-6 mb-3">
          <label className="tinyLabel">Last Name</label>
          <input
            type="text"
            value={fields.clientLastName}
            name="clientLastName"
            onChange={handleChange}
            className="form-control"
          />
          <h4 className="error-msgtext">{errors.clientLastName}</h4>
        </div>
      </div>

      <label>Baby's Name (required)</label>
      <div className="row">
        <div className="form-group col-lg-6 col-md-6 mb-3">
          <label className="tinyLabel">First Name</label>
          <input
            type="text"
            value={fields.babyFirstName}
            name="babyFirstName"
            onChange={handleChange}
            className="form-control"
          />
          <h4 className="error-msgtext">{errors.babyFirstName}</h4>
        </div>
        <div className="form-group col-lg-6 col-md-6 mb-3">
          <label className="tinyLabel">Last Name</label>
          <input
            type="text"
            value={fields.babyLastName}
            name="babyLastName"
            onChange={handleChange}
            className="form-control"
          />
          <h4 className="error-msgtext">{errors.babyLastName}</h4>
        </div>
      </div>

      <div className="col-lg-6 col-md-6 mb-3">
        <label>Baby's Date of Birth (required)</label>
        <DateField
          value={fields.babyDateOfBirth}
          onChange={handleChangeDate("babyDateOfBirth")}
        />
        <h4 className="error-msgtext">{errors.babyDateOfBirth}</h4>
      </div>

      <label>Consultant name (required)</label>
      <div className="row">
        <div className="form-group col-lg-6 col-md-6 mb-3">
          <label className="tinyLabel">First Name</label>
          <input
            type="text"
            value={fields.consultantFirstName}
            name="consultantFirstName"
            onChange={handleChange}
            className="form-control"
          />
          <h4 className="error-msgtext">{errors.consultantFirstName}</h4>
        </div>
        <div className="form-group col-lg-6 col-md-6 mb-3">
          <label className="tinyLabel">Last Name</label>
          <input
            type="text"
            value={fields.consultantLastName}
            name="consultantLastName"
            onChange={handleChange}
            className="form-control"
          />
          <h4 className="error-msgtext">{errors.consultantLastName}</h4>
        </div>
      </div>

      <label>Pediatrician Name (required)</label>
      <div className="row">
        <div className="form-group col-lg-6 col-md-6 mb-3">
          <label className="tinyLabel">First Name</label>
          <input
            type="text"
            value={fields.pediatricianFirstName}
            name="pediatricianFirstName"
            onChange={handleChange}
            className="form-control"
          />
          <h4 className="error-msgtext">{errors.pediatricianFirstName}</h4>
        </div>
        <div className="form-group col-lg-6 col-md-6 mb-3">
          <label className="tinyLabel">Last Name</label>
          <input
            type="text"
            value={fields.pediatricianLastName}
            name="pediatricianLastName"
            onChange={handleChange}
            className="form-control"
          />
          <h4 className="error-msgtext">{errors.pediatricianLastName}</h4>
        </div>
      </div>

      <label>OB Name (required)</label>
      <div className="row">
        <div className="form-group col-lg-6 col-md-6 mb-3">
          <label className="tinyLabel">First Name</label>
          <input
            type="text"
            value={fields.OBFirstName}
            name="OBFirstName"
            onChange={handleChange}
            className="form-control"
          />
          <h4 className="error-msgtext">{errors.OBFirstName}</h4>
        </div>
        <div className="form-group col-lg-6 col-md-6 mb-3">
          <label className="tinyLabel">Last Name</label>
          <input
            type="text"
            value={fields.OBLastName}
            name="OBLastName"
            onChange={handleChange}
            className="form-control"
          />
          <h4 className="error-msgtext">{errors.OBLastName}</h4>
        </div>
      </div>
      <div className="form-group col-lg-12 col-md-12 mb-3">
        <label>Reason for Consultation: (required)</label>
        <input
          type="text"
          value={fields.reasonForConsultation}
          name="reasonForConsultation"
          onChange={handleChange}
          className="form-control"
        />
        <h4 className="error-msgtext">{errors.reasonForConsultation}</h4>
      </div>
    </>
  );
};
