import React from "react";
import userBlankIcon from "../assets/userBlankIcon.svg";
import "./message.scss";
import "../editorMarkupStyles.scss";
import moment from "moment";

const Message = ({
  userName,
  userAvatar,
  message,
  date,
  toRight,
  className = "",
  onDelete,
  isUserQuestion,
}: {
  userName: string;
  userAvatar: string;
  message: string;
  date: string;
  toRight?: boolean;
  className?: string;
  onDelete?: () => void;
  isUserQuestion?: boolean;
}) => (
  <div
    className={classNames([
      "askNurseChatMessage",
      toRight ? "askNurseChatMessage--toRight" : "",
      className,
    ])}
  >
    {userAvatar ? (
      <img className="askNurseChatAvatar" src={userAvatar} alt={userName} />
    ) : (
      <div
        className="askNurseChatAvatar"
        style={{ backgroundImage: `url("${userBlankIcon}")` }}
      />
    )}
    <div
      className={classNames([
        "askNurseChatMain",
        toRight ? "askNurseChatMain--toRight" : "",
      ])}
    >
      <div className="askNurseChatMain__useName">{userName}</div>
      <div
        className={classNames([
          "askNurseChatMain__message",
          toRight ? "askNurseChatMain__message--toRight" : "",
        ])}
      >
        <div
          className="editor-markup-styles"
          dangerouslySetInnerHTML={{ __html: message }}
        />
        <div className={classNames(["askNurseChatMain__date"])}>
          {moment(date).fromNow()}
        </div>
      </div>
      {onDelete && isUserQuestion && (
        <button
          className="askNurseChatMain__delete"
          onClick={onDelete}
          type="button"
        >
          Delete
        </button>
      )}
    </div>
  </div>
);

const classNames = (list: string[]) => list.join(" ");

export default Message;
