import React from "react";
import "./isForYou.scss";
import useModalWindowControls from "../../../../Hooks/useModal";
import ConfirmModal from "../../../../Common/ConfirmModal";
import ModalWindow from "../../../../Common/ModalWindow";

const IsForYou = () => {
  const termsOfUseModal = useModalWindowControls();

  return (
    <div className="preLoginIsForYouWrapper">
      <div className="preLoginIsForYouContent">
        <div className="preLoginIsForYouMembership">
          <div className="preLoginIsForYouMembershipCard">
            <div className="preLoginIsForYouMembershipCard__head">
              <div>
                This membership <br /> <span>isn’t for you if</span>
              </div>
              <NotForYouSvg />
            </div>

            <ol className="preLoginIsForYouMembershipList">
              <li>You enjoy being judged by parents in your facebook group</li>
              <li>
                You get a kick out of seeing 5 different providers who tell you
                5 different things
              </li>
              <li>
                Your ideal resource for parenting advice is searching google at
                3am
              </li>
              <li>
                You love the unsolicited advice from your passive aggressive
                mother-inLaw
              </li>
              <li>You are offended by a few f-bombs</li>
            </ol>
          </div>

          <div className="preLoginIsForYouMembershipDivider"></div>

          <div className="preLoginIsForYouMembershipCard">
            <div className="preLoginIsForYouMembershipCard__head preLoginIsForYouMembershipCard__head--green">
              <div>
                This membership <br /> <span>is for you if...</span>
              </div>
              <ForYouSVg />
            </div>

            <ol className="preLoginIsForYouMembershipList preLoginIsForYouMembershipList--green">
              <li>
                You want healthcare providers who care about you and your child,
                not just one or the other
              </li>
              <li>
                You are tired of searching for new resources at every parenting
                and childhood milestone
              </li>
              <li>
                You wish you had a trustworthy nurse in your pocket for daily
                parenting questions
              </li>
              <li>
                You seek a non-judgemental community supporting your family
                goals
              </li>
              <li>You are okay with a few f-bombs, no offense taken</li>
            </ol>
          </div>
        </div>

        <div className="footerDescription">
          *14 day money back guarantee.{" "}
          <button className="openModalBtn" onClick={termsOfUseModal.openModal}>
            Terms and Conditions
          </button>{" "}
          apply
          <ModalWindow
            isVisible={termsOfUseModal.isVisible}
            closeModal={termsOfUseModal.closeModal}
          >
            <p>
              You are entitled to your money back on an annual membership, no
              questions asked, so long as you make your request within 14 days
              of purchase, have booked and showed up to your welcome call within
              14 days of purchase, and have not used your discount code on any
              services already rendered.
            </p>
          </ModalWindow>
        </div>
      </div>
    </div>
  );
};

const NotForYouSvg = () => (
  <svg
    width="54"
    height="57"
    viewBox="0 0 54 57"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.8677 8.66984C28.2664 3.99393 36.5819 5.08635 42.4663 10.0517C48.3904 15.0522 51.0415 24.3263 50.1385 31.7797C49.2324 39.2575 44.8515 46.2066 38.5836 50.3813C31.6341 55.0099 22.7624 53.2151 15.7855 49.7048C9.22413 46.4062 4.56977 39.841 3.3443 32.6497C2.74684 29.1424 2.92715 25.504 3.88369 22.0759C6.05807 14.2767 12.7401 5.55257 21.8746 3.16357"
      stroke="#FBF8EF"
      strokeWidth="6"
      strokeLinejoin="round"
    />
    <path
      d="M13.8105 15.9934C22.152 24.224 30.4934 32.4534 38.8348 40.684"
      stroke="#FBF8EF"
      strokeWidth="6"
      strokeLinejoin="round"
    />
    <path
      d="M41.1704 20.331C32.1006 25.2779 20.8797 31.3996 11.8086 36.3465"
      stroke="#FBF8EF"
      strokeWidth="6"
      strokeLinejoin="round"
    />
  </svg>
);

const ForYouSVg = () => (
  <svg
    width="54"
    height="57"
    viewBox="0 0 54 57"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.7549 8.67193C28.1075 3.99616 36.3712 5.08855 42.2189 10.0538C48.1062 15.0541 50.7408 24.3279 49.8433 31.7811C48.9429 39.2587 44.5893 46.2076 38.3604 50.3821C31.4542 55.0106 22.6378 53.2159 15.7043 49.7056C9.18536 46.4056 4.55848 39.8422 3.34215 32.651C2.74842 29.1438 2.9276 25.5056 3.87819 22.0776C6.0375 14.2771 12.6764 5.55475 21.7555 3.16431"
      stroke="#FBF8EF"
      strokeWidth="6"
      strokeLinejoin="round"
    />
    <path
      d="M17.9254 29.7548C19.2834 32.5799 21.2856 34.8875 22.9058 37.4927C29.9592 25.8187 40.8831 16.1561 49.2687 5.40311"
      stroke="#FBF8EF"
      strokeWidth="6"
      strokeLinejoin="round"
    />
  </svg>
);

export default IsForYou;
