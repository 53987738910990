import React from "react";
import usePaymentMethod from "./usePaymentMethod";
import NewPaymentMethodPopUp from "./NewPaymentMethodPopUp";

function RecheckPaymentMethod({ recheckPaymentMethodModal }: any) {
  const {
    postNewPaymentMethod,
    clearPostNewPaymentMethod,
    addPaymentMethodRequest,
  } = usePaymentMethod();

  return (
    <>
      <NewPaymentMethodPopUp
        isVisible={recheckPaymentMethodModal.isVisible}
        closeModal={recheckPaymentMethodModal.closeModal}
        postNewPaymentMethod={postNewPaymentMethod}
        clearPostNewPaymentMethod={clearPostNewPaymentMethod}
        addPaymentMethodRequest={addPaymentMethodRequest}
        title="One more step!"
        description="In order to ensure you have no interruptions in your current membership, please reverify your credit card information below."
      />
    </>
  );
}

export default RecheckPaymentMethod;
