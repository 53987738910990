import React, { useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Filter from "../../Common/Filter/FilterNew";
import { useNavigate } from "react-router-dom";
import { COURSE } from "../../Routes/RouteConstent";
import DetailPopup from "./detailPopup";
import { FilterApi, getCourseApi, getFavriteApi } from "../../Service/Course";
import SearchFilter from "../../Common/SearchFilterTab/SearchFilter";
import Header from "./Header/Header";
import AllCourse from "./AllCourseTab/AllCourse";
import Favourate from "./FavourateTab/Favourate";
import "./courses.scss";
import useCourses, { CoursesState } from "./useCourses";

const Courses = () => {
  const [value, setValue] = React.useState("");
  const [modalShow, setModalShow] = React.useState(false);

  let [modelData, setModelData] = useState<any>([]);
  let [filterdata, setFilterdata] = useState<any>({});

  const param = new URLSearchParams(window.location.search);
  const Navigate = useNavigate();

  let userID: any = localStorage.getItem("Nurture_user_data");
  userID = JSON.parse(userID)?.id;

  const coursesMain = useCourses({ userID, method: getCourseApi });
  const favoriteCoursesMain = useCourses({ userID, method: getFavriteApi });

  const updateCourses = (
    type: string,
    data: any,
    isUpdate: boolean,
    isSuccess: boolean
  ) => {
    if (isSuccess && type === "fav") {
      const courseId: number = data.id;
      const isFavorite = !data.favourite;

      coursesMain.setCourses((courses) => ({
        ...courses,
        data: courses.data.map((course) =>
          course.id === data.id
            ? { ...course, favourite: !course.favourite }
            : course
        ),
      }));

      if (isFavorite) {
        const nextCourseState = { ...data, favourite: isFavorite };

        favoriteCoursesMain.setCourses((courses) => ({
          ...courses,
          data: [...courses.data, nextCourseState],
        }));
      } else {
        favoriteCoursesMain.setCourses((courses) => ({
          ...courses,
          data: courses.data.filter(({ id }) => courseId !== id),
        }));
      }
    }

    if (isSuccess && type === "like") {
      const doLike = !data.like_course.some(
        ({ user_id }: { user_id: number }) => user_id === userID
      );
      const likeCB = (courses: CoursesState) => ({
        ...courses,
        data: courses.data.map((course) =>
          course.id === data.id
            ? {
                ...course,
                like: true,
                like_count: course.like_count + 1,
                like_course: [...course.like_course, { user_id: userID }],
              }
            : course
        ),
      });

      const removeLikeCB = (courses: CoursesState) => ({
        ...courses,
        data: courses.data.map((course) =>
          course.id === data.id
            ? {
                ...course,
                like: false,
                like_count: course.like_count - 1,
                like_course: course.like_course.filter(
                  ({ user_id }) => user_id !== userID
                ),
              }
            : course
        ),
      });

      if (doLike) {
        coursesMain.setCourses(likeCB);
        favoriteCoursesMain.setCourses(likeCB);
      } else {
        coursesMain.setCourses(removeLikeCB);
        favoriteCoursesMain.setCourses(removeLikeCB);
      }
    }
  };

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    tabHandle(newValue);
    setValue(newValue);
  };

  const tabHandle = (tab: string) => {
    Navigate(`${COURSE}?tab=${tab}`);
  };

  React.useEffect(() => {
    const getFilterData = async () => {
      try {
        let FilterRes: any = await FilterApi();

        setFilterdata(FilterRes?.data);
      } catch (error) {}
    };

    getFilterData();
  }, []);

  React.useEffect(() => {
    let tabId = param.get("tab");
    if (tabId) {
      setValue(tabId);
    } else {
      setValue("1");
    }
  }, []);

  React.useEffect(() => {
    coursesMain.coursesFilterModal.closeModal();
    favoriteCoursesMain.coursesFilterModal.closeModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <div>
      <DetailPopup
        show={modalShow}
        data={modelData}
        setData={setModelData}
        onHide={() => setModalShow(false)}
      />

      <div className="container-fluid course_content_page mt-5 mb-4">
        <div className="course_page">
          <div className="row">
            <div className="col-md-12 col-lg-12">
              <Header />
              <Box sx={{ width: "100%", typography: "body1" }}>
                <TabContext value={value}>
                  <Box
                    sx={{ borderColor: "divider" }}
                    className="course-tabs-main"
                  >
                    <div className="d-flex justify-content-center">
                      <TabList
                        onChange={handleChange}
                        aria-label="lab API tabs example"
                      >
                        <Tab label="All Courses" value="1" />
                        <Tab label="Favorites" value="2" />
                      </TabList>
                    </div>
                  </Box>
                  <div className="container search-main-container padding-lt-rt">
                    <div className="Row">
                      <div className="col-lg-12 col-md-12">
                        <div className="search-container">
                          {`${value}` === "1" && (
                            <SearchFilter
                              isFilter={
                                coursesMain.coursesFilterModal.isVisible
                              }
                              setIsFilter={
                                coursesMain.coursesFilterModal.openModal
                              }
                              handleChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) =>
                                coursesMain.filterCourses({
                                  search: e.target.value,
                                })
                              }
                              search={coursesMain.courses.search}
                            />
                          )}

                          {`${value}` === "2" && (
                            <SearchFilter
                              isFilter={
                                favoriteCoursesMain.coursesFilterModal.isVisible
                              }
                              setIsFilter={
                                favoriteCoursesMain.coursesFilterModal.openModal
                              }
                              handleChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) =>
                                favoriteCoursesMain.filterCourses({
                                  search: e.target.value,
                                })
                              }
                              search={favoriteCoursesMain.courses.search}
                            />
                          )}

                          <Filter
                            stage={filterdata?.stage || []}
                            category={filterdata?.category || []}
                            filter={coursesMain.filterCourses}
                            reset={coursesMain.resetCourses}
                            isVisible={coursesMain.coursesFilterModal.isVisible}
                            closeModal={
                              coursesMain.coursesFilterModal.closeModal
                            }
                            pending={coursesMain.courses.pending}
                            selectedStage={coursesMain.courses.filter.stage}
                            selectedCategory={
                              coursesMain.courses.filter.category
                            }
                          />
                          <Filter
                            stage={filterdata?.stage || []}
                            category={filterdata?.category || []}
                            filter={favoriteCoursesMain.filterCourses}
                            reset={favoriteCoursesMain.resetCourses}
                            isVisible={
                              favoriteCoursesMain.coursesFilterModal.isVisible
                            }
                            closeModal={
                              favoriteCoursesMain.coursesFilterModal.closeModal
                            }
                            pending={favoriteCoursesMain.courses.pending}
                            selectedStage={
                              favoriteCoursesMain.courses.filter.stage
                            }
                            selectedCategory={
                              favoriteCoursesMain.courses.filter.category
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <TabPanel value="1" className="all-course-container">
                    <div className="tab_course_content">
                      <AllCourse
                        courseData={coursesMain.courses.data}
                        updateCoursesData={updateCourses}
                      />
                    </div>
                  </TabPanel>
                  <TabPanel value="2" className="fav-course-container">
                    <div className="tab_course_content">
                      <Favourate
                        favData={favoriteCoursesMain.courses.data}
                        updateCoursesData={updateCourses}
                      />
                    </div>
                  </TabPanel>
                </TabContext>
              </Box>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Courses;
