import React from "react";
import { Modal } from "react-bootstrap";
import "./GiftCardDetailsModal.scss";
import useAddClassName from "../../../Hooks/useAddClassName";
import { getUser } from "../../../Service/getLocalStorage";
import { createSearchParams, useNavigate } from "react-router-dom";
import { emailRegex } from "../../../Utils/constant";
import { GIFTCARDPURCHASE } from "../../../Routes/RouteConstent";
import { MembershipsGiftData } from "../GiftMemberships/GiftMemberships";
import { ServiceGiftData } from "../AllServices/ServiceCard/ServiceCard";
import useModalWindowControls from "../../../Hooks/useModal";

const GiftCardDetailsModal = ({
  isVisible,
  closeModal,
  openModalData,
}: {
  isVisible: boolean;
  closeModal: () => void;
  openModalData: null | MembershipsGiftData | ServiceGiftData;
}) => {
  const navigate = useNavigate();

  const [fields, setFields] = React.useState({
    yourName: getUser()?.name || "",
    yourEmail: getUser()?.email || "",
    giftTargetName: "",
    giftTargetEmail: "",
  });

  const [errors, setErrors] = React.useState({
    yourName: "",
    yourEmail: "",
    giftTargetName: "",
    giftTargetEmail: "",
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFields((f) => ({ ...f, [e.target.name]: e.target.value }));
  };

  useAddClassName({
    element: "html",
    allowAddClassName: isVisible,
    className: "GiftCardDetailsModalPopUpOverflowBody",
  });

  const onSubmit = () => {
    const typeSpecificFields: { type?: string; id?: string; price?: string } =
      {};

    if (openModalData?.price) {
      typeSpecificFields.price = openModalData.price;
    }

    if (openModalData?.type) {
      typeSpecificFields.type = openModalData.type;
    }

    if (openModalData?.type === "service") {
      typeSpecificFields.id = openModalData?.service;
    }
    if (openModalData?.type === "membership") {
      typeSpecificFields.id = openModalData?.membership;
    }

    const checkErrors = {
      yourName: fields.yourName.length ? "" : "Enter name",
      yourEmail: emailRegex.test(fields.yourEmail) ? "" : "Enter valid email",
      giftTargetName: fields.giftTargetName.length ? "" : "Enter name",
      giftTargetEmail: emailRegex.test(fields.giftTargetEmail)
        ? ""
        : "Enter valid email",
    };

    setErrors(checkErrors);

    if (Object.values(checkErrors).every((v) => !v)) {
      navigate({
        pathname: GIFTCARDPURCHASE,
        search: `?${createSearchParams({ ...fields, ...typeSpecificFields })}`,
      });
    }
  };

  const billingDetailsStep = useModalWindowControls();

  return (
    <Modal
      show={isVisible}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="GiftCardDetailsModalPopUpWindow"
    >
      <Modal.Body className="GiftCardDetailsModalPopUp">
        <button
          className="GiftCardDetailsModalPopUpCross"
          onClick={() => {
            closeModal();
            billingDetailsStep.closeModal();
          }}
        >
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.5307 13.2694C16.1494 13.888 16.1494 14.912 15.5307 15.5307C15.2107 15.8507 14.8054 16 14.4 16C13.9947 16 13.5894 15.8507 13.2694 15.5307L8.00004 10.2614L2.7307 15.5307C2.4107 15.8507 2.00537 16 1.60004 16C1.1947 16 0.789371 15.8507 0.469371 15.5307C-0.149296 14.912 -0.149296 13.888 0.469371 13.2694L5.7387 8.00004L0.469371 2.7307C-0.149296 2.11204 -0.149296 1.08804 0.469371 0.469371C1.08804 -0.149296 2.11204 -0.149296 2.7307 0.469371L8.00004 5.7387L13.2694 0.469371C13.888 -0.149296 14.912 -0.149296 15.5307 0.469371C16.1494 1.08804 16.1494 2.11204 15.5307 2.7307L10.2614 8.00004L15.5307 13.2694V13.2694Z"
              fill="#30426D"
            />
          </svg>
        </button>
        {billingDetailsStep.isVisible && (
          <>
            <div className="GCDBillingModalTitle">
              {openModalData?.title}
            </div>
            <div className="GCDBilling">
              <div className="GCDBilling__title">
                Billing details
              </div>

              <div className="GCDBilling__lineWrapper">
                <div className="GCDBilling__line">
                  Total
                  <div className="GCDBilling__price">
                    ${+(openModalData?.price || 0)}
                  </div>
                </div>
                <div className="GCDBilling__divider" />
              </div>

              <div className="GCDBilling__lineWrapper">
                <div className="GCDBilling__line">
                  Taxes & Charges
                  <div className="GCDBilling__price">+$0</div>
                </div>
                <div className="GCDBilling__divider" />
              </div>

              <div className="GCDBilling__toPay">
                To pay <div>${+(openModalData?.price || 0)}</div>
              </div>
            </div>
            <div className="GCDBillingBtn">
              <button className="primary-blue-small-btn" onClick={onSubmit}>
                Confirm & Pay
              </button>
            </div>
          </>
        )}
        {!billingDetailsStep.isVisible && (
          <div className="GiftCardDetailsModalContent">
            <div className="GiftCardDetailsModalHead">
              <h3 className="GiftCardDetailsModalHeadTitle">
                {openModalData?.title}
              </h3>
              <h3 className="GiftCardDetailsModalHeadPrice">
                {openModalData?.type === "membership" &&
                  `$${openModalData?.price}`}
                {openModalData?.type === "service" &&
                  `$${(+(openModalData?.price || 0)).toFixed(2)}`}
              </h3>
            </div>
            <div className="GiftCardDetailsModalDescription">
              {openModalData?.description}
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                billingDetailsStep.openModal();
              }}
            >
              <div className="GiftCardDetailsModalForm">
                <div>
                  <label>Your Name</label>
                  <input
                    type="text"
                    name="yourName"
                    value={fields.yourName}
                    onChange={handleChange}
                    className="GiftCardDetailsModalFormField"
                  />
                  <p className="error">{errors.yourName}</p>
                </div>
                <div>
                  <label>Your Email</label>
                  <input
                    type="text"
                    name="yourEmail"
                    value={fields.yourEmail}
                    onChange={handleChange}
                    className="GiftCardDetailsModalFormField"
                  />
                  <p className="error">{errors.yourEmail}</p>
                </div>

                <div>
                  <label>Your Loved One’s Name</label>
                  <input
                    type="text"
                    name="giftTargetName"
                    value={fields.giftTargetName}
                    onChange={handleChange}
                    className="GiftCardDetailsModalFormField"
                  />
                  <p className="error">{errors.giftTargetName}</p>
                </div>
                <div>
                  <label>Your Loved One’s Email</label>
                  <input
                    type="text"
                    name="giftTargetEmail"
                    value={fields.giftTargetEmail}
                    onChange={handleChange}
                    className="GiftCardDetailsModalFormField"
                  />
                  <p className="error">{errors.giftTargetEmail}</p>
                </div>
              </div>
              <div className="GiftCardDetailsModalFormBtnWrapper">
                <button className="primary-blue-small-btn">Next</button>
              </div>
            </form>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default GiftCardDetailsModal;
