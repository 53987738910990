import React from "react";
import "./PaymentMethodForm.scss";
import { Row } from "react-bootstrap";
import { formatter } from "./helpers";
import { AsyncState } from "../../../../Utils/asyncState";
import usePaymentMethodForm from "./usePaymentMethodForm";

type usePaymentMethodFormReturnType = ReturnType<typeof usePaymentMethodForm>;

interface PaymentMethodFormProps extends usePaymentMethodFormReturnType {
  paymentAsyncState: AsyncState;
  maxWidth?: string;
  disableAll?: boolean;
}

const PaymentMethodForm = ({
  fields,
  errors,
  handleChange,
  handleNumber,
  handleExpirationDate,
  maxWidth,
  disableAll,
}: PaymentMethodFormProps) => {
  return (
    <div
      className="PaymentMethodFormMain"
      style={{ maxWidth, opacity: disableAll ? "0.4" : "1" }}
    >
      <Row className="mt-3">
        <div className="col-md-6">
          <label>First Name</label>
          <input
            disabled={disableAll}
            type="text"
            name="firstName"
            value={fields.firstName}
            onChange={handleChange}
            className="stripeFormInput"
            placeholder="First name"
          />
          <p className="error">{errors.firstName}</p>
        </div>
        <div className="col-md-6">
          <label>Last Name</label>
          <input
            disabled={disableAll}
            type="text"
            name="lastName"
            value={fields.lastName}
            className="stripeFormInput"
            onChange={handleChange}
            placeholder="Last name"
          />
          <p className="error">{errors.lastName}</p>
        </div>
      </Row>
      <div className="col-sm-12 mt-3">
        <label> Credit card number</label>
        <input
          disabled={disableAll}
          type="text"
          name="cardNumber"
          value={fields.cardNumber}
          className="stripeFormInput"
          onChange={handleNumber(16)}
          placeholder="xxxx xxxx xxxx xxxx"
        />
        <p className="error">{errors.cardNumber}</p>
      </div>

      <Row className="mt-3">
        <div className="col-md-4">
          <label>Expiration</label>
          <input
            disabled={disableAll}
            type="text"
            name="exp"
            value={formatter(fields.exp)}
            onChange={handleExpirationDate}
            className="stripeFormInput"
            placeholder="MM / YY"
          />
          <p className="error">{errors.exp}</p>
        </div>
        <div className="col-md-4">
          <label>CVV</label>
          <input
            disabled={disableAll}
            type="text"
            name="cvv"
            value={fields.cvv}
            className="stripeFormInput"
            placeholder="xxx"
            onChange={handleNumber(4)}
          />
          <p className="error">{errors.cvv}</p>
        </div>
        <div className="col-md-4">
          <label>Billing Zip Code</label>
          <input
            disabled={disableAll}
            type="text"
            name="zipCode"
            value={fields.zipCode}
            className="stripeFormInput"
            onChange={handleChange}
            placeholder="xxxxx"
          />
          <p className="error">{errors.zipCode}</p>
        </div>
      </Row>
    </div>
  );
};

export default PaymentMethodForm;
