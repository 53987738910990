import React from "react";
import UpdateTariffModal, {
  Benefits,
  BottomControls,
  MembershipCard,
  UpdateTariffModalProps,
} from "./UpdateTariffModal";
interface UpgradeModalProps extends Omit<UpdateTariffModalProps, "children"> {
  closeModal: () => void;
  confirm: (membershipId: number) => void;
}

const UpgradeModal = ({
  isVisible,
  closeModal,
  confirm,
}: UpgradeModalProps) => {
  return (
    <UpdateTariffModal isVisible={isVisible}>
      <h2>Woohoo! You’re almost there.</h2>
      <h3>
        By upgrading to the Annual Membership, you will{" "}
        <span className="strong">gain free access to</span>
      </h3>
      <Benefits
        benefits={[
          { text: "Pre Baby Bootcamp", positive: true, price: "$399" },
          { text: "Mom Survival Group", positive: true, price: "$249" },
          {
            text: "Group Infant and Child CPR with First Aid",
            positive: true,
            price: "$100",
          },
          { text: "Group Sleep Consult", positive: true, price: "$99" },
        ]}
      />
      <MembershipCard
        title="Annual Membership"
        price="$83"
        priceOld="$149"
        priceSum="$999"
        priceSumDescription="annually, cancel any time."
        type="green"
      />
      <BottomControls
        handleConfirm={() => confirm(2)}
        handleClose={closeModal}
        confirmText="Confirm Upgrade"
        closeText="Keep Monthly Membership"
      />
    </UpdateTariffModal>
  );
};

export default UpgradeModal;
