import React from "react";
import BecomeAMemberNow from "../../../Common/BecomeAMemberNow";
import "./BHBecomeMember.scss";
import { MEMBERSHIP_PRE_LOGIN } from "../../../Routes/RouteConstent";

const BHBecomeMember = () => (
  <div className="bhBecomeMember">
    <BecomeAMemberNow joinNowPath={MEMBERSHIP_PRE_LOGIN} />
  </div>
);

export default BHBecomeMember;
