import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as asyncState from "../../Utils/asyncState";
import { getChat, postChatReply, postDeleteReply } from "./transport";
import AskQuestion from "../ask-a-nurse/ask-question";
import "./askANurseChat.scss";
import Message from "./message";
import { getUser, getUserID } from "../../Service/getLocalStorage";
import { CircularProgress } from "@mui/material";
import MessageEditor from "./message-editor";
import useModalWindowControls from "../../Hooks/useModal";
import ConfirmDeleteModal from "../../Common/confirm-delete-modal";
import useRequestState from "../../Hooks/useRequestState";
import { postDeleteChat } from "../ask-a-nurse/transport";
import useAddClassName from "../../Hooks/useAddClassName";
import { useDispatch } from "react-redux";
import { getNewMessages } from "../../Redux/new-messages/newMessages";
import LockAskANurse from "../ask-a-nurse/lock-ask-a-nurse/LockAskANurse";

type Chat = {
  message: string;
  title: string;
  user_photo: string;
  created_at: string;
  user_name: { name: string };
  user_id: number | null;
  replies: {
    id: number;
    message: string;
    user_photo: string;
    user_name: { name: string };
    created_at: string;
    user_id: number;
    user_role: string;
  }[];
};

const AskANurseChat = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [editorValue, setEditorValue] = useState<string>("");

  const chatRequestState = useRequestState<Chat>({
    asyncRequest: getChat,
    initData: {
      user_photo: "",
      user_id: null,
      user_name: { name: "" },
      created_at: "",
      message: "",
      title: "",
      replies: [],
    },
    onSuccess: (res) => {
      dispatch(getNewMessages());
      chatRequestState.setRequestState({
        ...asyncState.successState,
        data: res?.data,
      });
    },
  });

  useEffect(() => {
    chatRequestState.request(id);

    const interval = setInterval(
      () =>
        getChat(id!).then((res) => {
          if (res?.data && res.status === 200) {
            chatRequestState.setRequestState((s) => ({
              ...s,
              data: res?.data as Chat,
            }));
          }
        }),
      10000
    );

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const chat = chatRequestState.requestState;
  const isUserChatOwner = chat.data.user_id === getUserID();

  const scrollToRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = useCallback((smooth?: boolean) => {
    const element = scrollToRef.current;
    if (element) {
      const rect = element.getBoundingClientRect();
      const scrollTop = document.documentElement.scrollTop;
      const viewportHeight = window.innerHeight;
      const topPositionToScroll =
        rect.top +
        scrollTop -
        (viewportHeight - rect.height - (viewportHeight * 20) / 100);

      if (scrollTop < topPositionToScroll) {
        smooth
          ? window.scrollTo({ top: topPositionToScroll, behavior: "smooth" })
          : window.scrollTo(0, topPositionToScroll);
      }
    }
  }, []);

  useAddClassName({
    className: "defaultScrollBehavior",
    element: "html",
    allowAddClassName: true,
  });

  const sendMessageRequestState = useRequestState({
    initData: {},
    onSuccess: (res) => {
      chatRequestState.setRequestState((chat) => {
        const replies = chat.data.replies;

        return {
          ...chat,
          data: {
            ...chat.data,
            replies: replies.some((r) => r.id === res.data)
              ? chat.data.replies
              : [...chat.data.replies, res.data],
          },
        };
      });
      setEditorValue("");
      setTimeout(() => {
        scrollToBottom(true);
      }, 100);
    },
    asyncRequest: postChatReply,
  });

  useEffect(() => {
    if (chatRequestState.requestState.success && isUserChatOwner) {
      scrollToBottom();
    }
  }, [chatRequestState.requestState.success, isUserChatOwner, scrollToBottom]);

  const deleteMessageModal = useModalWindowControls();
  const deleteMessageRequestState = useRequestState({
    initData: {},
    onSuccess: () => {
      chatRequestState.setRequestState((chat) => {
        const replies = chat.data.replies;
        return {
          ...chat,
          data: {
            ...chat.data,
            replies: replies.filter(
              (r) => +r.id !== +deleteMessageModal.openModalData
            ),
          },
        };
      });
      deleteMessageModal.closeModal();
    },
    asyncRequest: postDeleteReply,
  });

  const deleteChatModal = useModalWindowControls();
  const deleteChatRequestState = useRequestState({
    initData: {},
    onSuccess: () => {
      navigate(-1);
    },
    asyncRequest: postDeleteChat,
  });

  if (!getUser()?.is_stripe_membership) {
    return <LockAskANurse />;
  }

  return (
    <div className="pt-3">
      <ConfirmDeleteModal
        isVisible={deleteMessageModal.isVisible}
        closeModal={deleteMessageModal.closeModal}
        pending={deleteMessageRequestState.requestState.pending}
        confirmBtnCB={() =>
          deleteMessageRequestState.request({
            id: deleteMessageModal.openModalData,
          })
        }
        title="Delete message"
        description="Are you sure you want to delete this message?"
      />
      <ConfirmDeleteModal
        isVisible={deleteChatModal.isVisible}
        closeModal={deleteChatModal.closeModal}
        pending={deleteChatRequestState.requestState.pending}
        confirmBtnCB={() => deleteChatRequestState.request({ id })}
        title="Delete chat"
        description="Are you sure you want to delete this whole chat?"
      />
      <button
        className="askNurseChatBack px-3 mb-3"
        onClick={() => navigate(-1)}
      >
        <svg width={20} height={20} viewBox="0 0 24 24" fill="#30426d">
          <path d="M17.77 3.77 16 2 6 12l10 10 1.77-1.77L9.54 12z"></path>
        </svg>
        Back
      </button>
      <div className="px-3">
        <AskQuestion />
      </div>
      <div className="askNurseChatMaxWidth mb-4">
        {chat.success && isUserChatOwner && (
          <div className="messageEditorSticky">
            <MessageEditor
              value={editorValue}
              setValue={setEditorValue}
              chatId={id as string}
              onSubmit={(message) =>
                sendMessageRequestState.request({ message, id })
              }
              pending={sendMessageRequestState.requestState.pending}
              className="messageEditorSticky__content"
              shrinkOnFocus
            />
          </div>
        )}

        {chat.success && (
          <h1 className="px-3 px-sm-5 py-3 askNurseChatTitle">
            {chat.data.title}
          </h1>
        )}

        {chat.success && (
          <div className="pb-4 px-3">
            <Message
              userAvatar={chat.data.user_photo}
              userName={chat.data.user_name.name}
              message={chat.data.message}
              date={chat.data?.created_at}
              onDelete={deleteChatModal.openModal}
              toRight
            />
            {chat.data.replies.map((reply) => (
              <Message
                key={reply.id}
                userAvatar={reply.user_photo}
                userName={reply.user_name.name}
                message={reply.message}
                date={reply.created_at}
                className="mt-2"
                toRight={reply.user_role === "customer"}
                isUserQuestion={reply.user_id === getUserID()}
                onDelete={() => deleteMessageModal.openModal(reply.id)}
              />
            ))}
            <div ref={scrollToRef} />
          </div>
        )}
        {chat.success && isUserChatOwner && <div style={{ height: "120px" }} />}
        {chat.pending && (
          <div className="askNurseChatPending">
            <CircularProgress size={50} />
          </div>
        )}
      </div>
    </div>
  );
};

export default AskANurseChat;
