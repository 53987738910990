import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Button, Image, ListGroup, Carousel } from 'react-bootstrap';
import "../stage.scss";
import "../Stage4/Stage4.scss";
import One_Year_Old_Transitions from "../../../Assets/img/stage/One_Yea_Old_Transitions.jpg";
import Short_Naps_Early_Morning_WakeUpfrom from "../../../Assets/img/stage/Short_Naps_Early_Morning_WakeUp.jpg";
import Sleep_Consultation from "../../../Assets/img/stage/Sleep_Consultation.jpg";
import Working_Moms_Survival_Group from "../../../Assets/img/stage/Working_Moms_Survival_Group.jpg";
import Infant_Child_CPR from "../../../Assets/img/stage/Infant_&_Child_CPR.jpg";
import consult_nurse from "../../../Assets/img/stage/consult-nurse.jpg";
import PodcastUI from '../../../Common/PodcastUi/PodcastUI';
import LockScreen from '../../../Common/LockScreen/LockScreen';
import StreetCred from '../../../Common/streetCred/streetCred';
import { getStagesDataApi } from '../../../Service/stage';
import BlogsBlock from '../../../Common/BlogCard/BlogsBlock';
import { useNavigate } from 'react-router-dom';
import { COURSE, COURSEVIEW, LIVEWEBINAR, SLEEPCONSULT } from '../../../Routes/RouteConstent';
import { NavServiceUrl } from '../../../Utils/ConsultRoute';
import LandingDescription from '../../../Common/LandingDescription';
import { HeroBannerHorizontal}  from '../../../Common/HeroBanner';
import hero from '../../../Assets/img/stage/6-12banner.png';

const Stage4 = () => {
  const [data, setData] = useState([])
  const fetchData = async () => {
    try {
      let res: any = await getStagesDataApi({ stage: "stage_4" })
      if (res?.data?.success) setData(res?.data?.blogs)
    } catch (error: any) {

    }
  }
  useEffect(() => { fetchData() }, [])
  const Navigate = useNavigate()

  const serviceNavigate = (prefix: any) => {
    let url = NavServiceUrl(prefix)

    Navigate(url)
  }

  return (
    <div className='container-fluid stage-main stage4'>
      <div className="py-3">
        <HeroBannerHorizontal
          hero={hero}
          title={
            <>
                <div style={{color: '#30426D'}}>Parenting a</div>
                <div className="strong" style={{color: '#30426D'}}>
                  6-12 month old
                </div>
              </>
          }
        />
      </div>

      <LandingDescription
        ySpace
        content={[
          <>
            <span className='strong'>Here comes trouble!</span>
          </>,
          <>
            Your 6-12 month old is all about exploring. It’s time to do some baby-proofing, 
            mix up some tasty foods, and try out your sign language because this baby is 
            all about learning new things. Our courses will take you through this 
            developmental stage one little creep/crawl/toddle at a time.
          </>,
        ]}
      />

      <Row className="pale-bg">
        <div className='col-lg-12 col-md-12 my-3'>
          <Container>
            <Row className="justify-content-center my-md-5 my-3 stage-offering-header">
              <div className='col-lg-12 col-md-12 col-sm-12'>
                <div className="section-header text-center">
                  <h2 className="home-section-title py-0">Our<span className="strong pink"> Offerings</span></h2>
                </div>
              </div>
              <div className="col-lg-8 col-md-8 mt-2">
                <h3 className="text-center stage-description">Get the most out of our team of <span className='strong'>experienced nurses </span> with these services.</h3>
              </div>
            </Row>

            <Row className='stages-specific-offering mb-5 d-none d-sm-none d-md-block d-lg-block d-xl-block'>
              <div className='col-lg-12 col-md-12'>
                <Carousel controls={false}>
                  <Carousel.Item>
                    <Container>
                      <Row className='align-items-stretch'>
                        <div className='col-lg-4 col-md-6 mb-4'>
                          <Card className='video-container-main'>
                            <div className='video-container-inner'>
                              <img src={One_Year_Old_Transitions} className='image-fluid' />
                            </div>
                            <Card.Body className='position-relative'>
                              <div className='d-flex align-items-center justify-content-between mb-2'>
                                <Card.Title>One Year Old Transitions</Card.Title>
                              </div>
                              <Card.Text>
                                <p><span className='strong'>There are a lot of changes that happen and at the one year mark, and little support…until now!</span> Learn all about the transitions that will be happening around your child’s first birthday and how to handle them. </p>
                              </Card.Text>

                              <div className="d-flex justify-content-between mt-3">
                                <button className="buy_now btn buy-now" onClick={() => Navigate(`${COURSEVIEW}1-year-transitions`)}>Learn More</button>
                              </div>
                            </Card.Body>
                          </Card>
                        </div>

                        <div className='col-lg-4 col-md-6 mb-4'>
                          <Card className='video-container-main'>
                            <div className='video-container-inner'>
                              <img src={Short_Naps_Early_Morning_WakeUpfrom} className='image-fluid' />
                            </div>
                            <Card.Body className='position-relative'>
                              <div className='d-flex align-items-center justify-content-between mb-2'>
                                <Card.Title>Short Naps and Early Morning Wake Ups</Card.Title>
                              </div>
                              <Card.Text>
                                <p><span className='strong'>Struggling with short naps and/or early morning wake ups? Don’t fret!</span>  This live webinar will not only help you pinpoint the root of the issue, but give you strategies for how to tackle and resolve it!</p>
                              </Card.Text>

                              <div className="d-flex justify-content-between mt-3">
                                <button className="buy_now btn buy-now" onClick={() => Navigate(LIVEWEBINAR, {state: 13})}>Learn More</button>
                              </div>
                            </Card.Body>
                          </Card>
                        </div>

                        <div className='col-lg-4 col-md-6 mb-4'>
                          <Card className='video-container-main'>
                            <div className='video-container-inner'>
                              <img src={Sleep_Consultation} className='image-fluid' />
                            </div>
                            <Card.Body className='position-relative'>
                              <div className='d-flex align-items-center justify-content-between mb-2'>
                                <Card.Title>Sleep Consult</Card.Title>
                              </div>
                              <Card.Text>
                                <p><span className='strong'> If you can’t sleep, you can’t think. And if your baby isn’t sleeping, then neither are you. </span> Our expert in pediatric sleep is here to help! We’ll make you a customized plan to get sleep on track.
                                </p>
                              </Card.Text>

                              <div className="d-flex justify-content-between mt-3">
                                <button className="buy_now btn buy-now" onClick={() => Navigate(SLEEPCONSULT)}>Learn More</button>
                              </div>
                            </Card.Body>
                          </Card>
                        </div>
                      </Row>
                    </Container>
                  </Carousel.Item>
                  <Carousel.Item>
                    <Container>
                      <Row className='align-items-stretch stages-specific-offering'>
                        <div className='col-lg-4 col-md-6 mb-4'>
                          <Card className='video-container-main'>
                            <div className='video-container-inner'>
                              <img src={Working_Moms_Survival_Group} className='image-fluid' />
                            </div>
                            <Card.Body className='position-relative'>
                              <div className='d-flex align-items-center justify-content-between mb-2'>
                                <Card.Title>Working Moms’ Survival Group</Card.Title>
                              </div>
                              <Card.Text>
                                <p><span className='strong'>Working & parenting ain’t easy.</span>{" "}
                                  This group tackles the unique challenges working moms face. Tailored to fit into your busy life, the group meets once a month, in evenings, and fosters connection & community. </p>
                              </Card.Text>
                              <div className="d-flex justify-content-between mt-3">
                                <button className="buy_now btn buy-now" onClick={() => serviceNavigate("service_6")}>Learn More</button>
                              </div>
                            </Card.Body>
                          </Card>
                        </div>

                        <div className='col-lg-4 col-md-6 mb-4'>
                          <Card className='video-container-main'>
                            <div className='video-container-inner'>
                              <img src={Infant_Child_CPR} className='image-fluid' />
                            </div>
                            <Card.Body className='position-relative'>
                              <div className='d-flex align-items-center justify-content-between mb-2'>
                                <Card.Title>Infant and Child CPR</Card.Title>
                              </div>
                              <Card.Text>
                                <p><span className='strong'>With the introduction of more solid foods, this is a great time to book a CPR and choking refresher course!</span> Learn the steps to take if your child stops breathing or is choking in this live course. </p>
                              </Card.Text>

                              <div className="d-flex justify-content-between mt-3">
                                <button className="buy_now btn buy-now" onClick={() => serviceNavigate("service_6")}>Learn More</button>
                              </div>
                            </Card.Body>
                          </Card>
                        </div>

                        <div className='col-lg-4 col-md-6 mb-4'>
                          <Card className='video-container-main'>
                            <div className='video-container-inner'>
                              <img src={consult_nurse} className='image-fluid' />
                            </div>
                            <Card.Body className='position-relative'>
                              <div className='d-flex align-items-center justify-content-between mb-2'>
                                <Card.Title>Consult a Nurse</Card.Title>
                              </div>
                              <Card.Text>
                                <p><span className='strong'>Need one-on-one support?</span> Talk to a NAPS maternal-child health nurse. Popular 6-12mo topics include: all  liquid to all solid diet, ditching bottles, developmental milestones, and weaning.  </p>
                              </Card.Text>

                              <div className="d-flex justify-content-between mt-3">
                                <button className="buy_now btn buy-now" onClick={() => serviceNavigate("service_2")}>Learn More</button>
                              </div>
                            </Card.Body>
                          </Card>
                        </div>
                      </Row>
                    </Container>
                  </Carousel.Item>
                </Carousel>
              </div>
            </Row>

            <Row className='stages-specific-offering mb-5 d-block d-sm-block d-md-none d-lg-none d-xl-none'>
              <Carousel controls={false}>
                <Carousel.Item>
                  <Container className='g-0'>
                    <Row className='align-items-stretch g-0'>
                      <div className='col-sm-12 mb-4'>
                        <Card className='video-container-main'>
                          <div className='video-container-inner'>
                            <img src={One_Year_Old_Transitions} className='image-fluid' />
                          </div>
                          <Card.Body className='position-relative'>
                            <div className='d-flex align-items-center justify-content-between mb-2'>
                              <Card.Title>One Year Old Transitions</Card.Title>
                            </div>
                            <Card.Text>
                              <p><span className='strong'>There are a lot of changes that happen and at the one year mark, and little support…until now!</span> Learn all about the transitions that will be happening around your child’s first birthday and how to handle them. </p>
                            </Card.Text>
                            <div className="d-flex justify-content-between mt-3">
                              <button className="buy_now btn buy-now" onClick={() => Navigate(`${COURSEVIEW}1-year-transitions`)}>Learn More</button>
                            </div>
                          </Card.Body>
                        </Card>
                      </div>
                    </Row>
                  </Container>
                </Carousel.Item>

                <Carousel.Item>
                  <Container className='g-0'>
                    <Row className='align-items-stretch g-0'>
                      <div className='col-sm-12 mb-4'>
                        <Card className='video-container-main'>
                          <div className='video-container-inner'>
                            <img src={Short_Naps_Early_Morning_WakeUpfrom} className='image-fluid' />
                          </div>
                          <Card.Body className='position-relative'>
                            <div className='d-flex align-items-center justify-content-between mb-2'>
                              <Card.Title>Short Naps and Early Morning Wake Ups</Card.Title>
                            </div>
                            <Card.Text>
                              <p><span className='strong'>Struggling with short naps and/or early morning wake ups? Don’t fret!</span> This live webinar will not only help you pinpoint the root of the issue, but give you strategies for how to tackle and resolve it! </p>
                            </Card.Text>
                            <div className="d-flex justify-content-between mt-3">
                              <button className="buy_now btn buy-now" onClick={() => Navigate(LIVEWEBINAR, {state: 13})}>Learn More</button>
                            </div>
                          </Card.Body>
                        </Card>
                      </div>
                    </Row>
                  </Container>
                </Carousel.Item>

                <Carousel.Item>
                  <Container className='g-0'>
                    <Row className='align-items-stretch g-0'>
                      <div className='col-sm-12 mb-4'>
                        <Card className='video-container-main'>
                          <div className='video-container-inner'>
                            <img src={Sleep_Consultation} className='image-fluid' />
                          </div>
                          <Card.Body className='position-relative'>
                            <div className='d-flex align-items-center justify-content-between mb-2'>
                              <Card.Title>Sleep Consult</Card.Title>
                            </div>
                            <Card.Text>
                              <p><span className="strong">If you can’t sleep, you can’t think. And if your baby isn’t sleeping, then neither are you.</span> Our expert in pediatric sleep is here to help! We’ll make you a customized plan to get sleep on track.</p>
                            </Card.Text>

                            <div className="d-flex justify-content-between mt-3">
                              <button className="buy_now btn buy-now" onClick={() => Navigate(SLEEPCONSULT)}>Learn More</button>
                            </div>
                          </Card.Body>
                        </Card>
                      </div>
                    </Row>
                  </Container>
                </Carousel.Item>

                <Carousel.Item>
                  <Container className='g-0'>
                    <Row className='align-items-stretch g-0'>
                      <div className='col-sm-12 mb-4'>
                        <Card className='video-container-main'>
                          <div className='video-container-inner'>
                            <img src={Working_Moms_Survival_Group} className='image-fluid' />
                          </div>
                          <Card.Body className='position-relative'>
                            <div className='d-flex align-items-center justify-content-between mb-2'>
                              <Card.Title>Working Moms’ Survival Group</Card.Title>
                            </div>
                            <Card.Text>
                              <p><span className='strong'>Working & parenting ain’t easy.</span>{" "}
                                This group tackles the unique challenges working moms face. Tailored to fit into your busy life, the group meets once a month, in evenings, and fosters connection & community. </p>
                            </Card.Text>
                            <div className="d-flex justify-content-between mt-3">
                              <button className="buy_now btn buy-now" onClick={() => serviceNavigate("service_6")}>Learn More</button>
                            </div>
                          </Card.Body>
                        </Card>
                      </div>
                    </Row>
                  </Container>
                </Carousel.Item>

                <Carousel.Item>
                  <Container className='g-0'>
                    <Row className='align-items-stretch g-0'>
                      <div className='col-sm-12 mb-4'>
                        <Card className='video-container-main'>
                          <div className='video-container-inner'>
                            <img src={Infant_Child_CPR} className='image-fluid' />
                          </div>
                          <Card.Body className='position-relative'>
                            <div className='d-flex align-items-center justify-content-between mb-2'>
                              <Card.Title>Infant and Child CPR</Card.Title>
                            </div>
                            <Card.Text>
                              <p><span className='strong'>With the introduction of more solid foods, this is a great time to book a CPR and choking refresher course!</span> Learn the steps to take if your child stops breathing or is choking in this live course. </p>
                            </Card.Text>

                            <div className="d-flex justify-content-between mt-3">
                              <button className="buy_now btn buy-now" onClick={() => serviceNavigate("service_10")}>Learn More</button>
                            </div>
                          </Card.Body>
                        </Card>
                      </div>
                    </Row>
                  </Container>
                </Carousel.Item>

                <Carousel.Item>
                  <Container className='g-0'>
                    <Row className='align-items-stretch g-0'>
                      <div className='col-sm-12 mb-4'>
                        <Card className='video-container-main'>
                          <div className='video-container-inner'>
                            <img src={consult_nurse} className='image-fluid' />
                          </div>
                          <Card.Body className='position-relative'>
                            <div className='d-flex align-items-center justify-content-between mb-2'>
                              <Card.Title>Consult a Nurse</Card.Title>
                            </div>
                            <Card.Text>
                              <p><span className='strong'>Need one-on-one support?</span> Talk to a NAPS maternal-child health nurse. Popular 6-12mo topics include: all  liquid to all solid diet, ditching bottles, developmental milestones, and weaning.  </p>
                            </Card.Text>

                            <div className="d-flex justify-content-between mt-3">
                              <button className="buy_now btn buy-now" onClick={() => serviceNavigate("service_2")}>Learn More</button>
                            </div>
                          </Card.Body>
                        </Card>
                      </div>
                    </Row>
                  </Container>
                </Carousel.Item>
              </Carousel>
            </Row>

          </Container>
        </div>
      </Row>

      <Row className='pale-bg'>
        <div className='col-lg-12 col-md-12 stage-common-sec'>
          <Row>
            <LockScreen />
          </Row>

          <Row className='py-4'>
            <div className='col-lg-12 col-sm-12'>
              <PodcastUI/>                            
            </div>
          </Row>

          <Row >
            <div className='col-lg-12 col-md-12'>
              <div className='container d-none d-sm-block d-md-block d-lg-block d-xl-block'>
                <div className='row'>
                  <div className='col-lg-12 col-md-12'>
                    <hr ></hr>
                  </div>
                </div>
              </div>
            </div>
          </Row>


          <BlogsBlock news={data} />

          <StreetCred data={testimonialData} />
        </div>
      </Row>

    </div>
  )
}
let testimonialData = [
  {
    name: "Cynthia P.",
    data: "The (group sleep) class was great...already on night four and thriving. I am so happy - I actually sent the NAPS page to my friend in Mexico who has been sleep deprived for over 10 months and needs a solution now.  Thank you again so much for the gift of sleep. This is the second time sleep training and I was nervous about doing both night and naps sleep training at the same time, but you encouraged us.  We are not even a week in and my baby is just sleeping so fast on his own with minimal fuss. It is unbelievable."
  },
  {
    name: "Shayna S.",
    data: "NAPS has been such a huge help in providing me a sense of comfort, confidence and community throughout all stages of my pregnancy and parenting journey. Emily and Jamie are the absolute best and offer such real and practical advice that I was able to implement and see changes right away.  Everything from breastfeeding, taking care of myself and getting my baby to sleep through the night in his crib. I've built such a great network of moms through MSG andt he starting solids workshop was wonderful. Make sure to check out their webinars too! Thank you NAPS!!"
  }
]
export default Stage4