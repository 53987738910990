export const HOME = '/';
export const LOGIN = '/login';
export const SIGNUP: any = '/signup';
export const FORGOTPASSWORD = "/forgot-password";
export const VERIFYEMAIL = "/verify/email";
export const VERIFYEMAILPOPUP = "/verify/emailpopup";
export const MEMBERSHIP = "/membership";
export const COMPANY_SIGNUP = "/company/login";
export const CHECKMAIL = "/check-mail";
export const SETPASSWORD = "/new/password";
export const QUICK_SCREENS = "/quick/screens";
export const ABOUTUS = "/about-us"
export const COURSE = "/courses";
export const COURSEDETAILS: any = "/course/detail/";
export const COURSEVIEW: any = "/course/";
export const LOGINPOPUP = "/login/popup";
export const BUYCOURSEDETAIL = "/buy/course";
export const BUYCOURSE = "/buy/course/payment";

export const LIVEEVENT = '/live-event';

export const ASK_A_NURSE = 'ask-a-nurse';
export const ASK_A_NURSE_CHAT = 'ask-a-nurse-chat';

export const INSTRUCTOR = "/instructor/";
export const EVENTS = "/live-webinars";
export const LIVEWEBINAR = "/live-webinars";
export const EVENTSDETAIL = "/live-webinar/";
export const MEMBERCHAT = "/member/chat";
export const CONSULTS: string = "/consults";
export const MEMBER = "/home/member";
export const NONMEMBER = "/post/login";
export const CONSULTSTEMP = "/consult-temp";
export const CONSULTSTEMP2 = "/consult-temp2";
export const NURSECONSULTS: string = "/consults2";
export const DATECONSULTS: string = "/date-consults";
export const MULTIPLECLASS = "/multiple/class";
export const VIRTUALCLASSCONSULTS: string = "/virtual-consults";
export const PRIVATECLASS: any = "/private-class";
export const PRIVATECLASS2: any = "/private-class2";
export const PREBABYBOOTCAMP: string = "/book-pre-baby-bootcamp";
export const PRIVATESLEEPCONSULT = "/private-sleep";
export const GIFTCARD = "/gift-cards";
export const GIFTCARDPURCHASE = "/gift-card-purchase";
export const PROFILEUSER = "/user-profile";
export const EDITPROFILE = "/edit-profile";
export const PODCASTQUESTION = "/podcast-question-submission";
export const SPONSORSHIP = "/partners";
export const CONTACT = "/contact";
export const PROMISE = "/promise"
export const PRELOGIN = "/pre/login";
export const NURSECONSULT30 = "/service/consult/nursing";
export const MEMBERSHIP_PRE_LOGIN = "/membership/pre-login";
export const STAGE1 = "/pregnancy";
export const STAGE2 = "/0-to-3-month-baby";
export const STAGE3 = "/3-to-6-month-baby";
export const STAGE4 = "/6-to-12-month-baby";
export const STAGE5 = "/12-to-24-month-toddler";
export const STAGE6 = "/2-3-years";
export const CRPROGRAMS = "/corporate-program";
export const COURSELANDING = "/course/landing"
export const SUPPORTGROUP = "/support-group";
export const SLEEPCONSULT = "/sleep-consult-overview";
export const LACTATIONCONSULT = "/lactation-consult";
export const OURSTORY = "/about";
export const DAYTIMEOVERNIGHT = "/daytime-overnight-infant-care";
export const TERMS = "/client-services-agreement";
export const PRIVACY = "/privacy-practices";
export const CLIENT_SERVICE_AGREEMENT = "/client-services-agreement";
export const CONSENT_FOR_DISCLOSURE = '/consent-for-disclosure';
export const BUMP = '/bump';
