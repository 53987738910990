import React from "react";
import "./OwnersStory.scss";

const OwnersStory = () => (
  <div className="ownersStory">
    <div className="ownersStoryPictureWrapper">
      <div className="ownersStoryPictureWrapper__layer">
        <svg
          width="248"
          height="310"
          viewBox="0 0 248 310"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="ownersStoryPictureWrapper__curlyLine"
        >
          <path
            d="M130.335 195.62C111.451 194.941 93.0219 197.298 75.5434 202.622C56.2562 208.499 41.1546 217.173 30.6411 228.404C18.0228 241.893 12.125 260.005 15.2587 275.664C15.4147 276.434 15.5916 277.206 15.7925 277.964C20.1546 294.427 35.1459 306.958 50.6672 307.118C65.1147 307.265 79.6165 297.984 93.7573 279.52C112.615 254.907 125.678 226.418 131.53 197.127L131.822 195.668L130.327 195.615L130.335 195.62ZM246.969 245.781C246.59 246.112 246.226 246.445 245.857 246.787C237.026 237.566 227.307 229.449 216.89 222.658C196.734 209.512 173.057 200.711 148.435 197.225C144.165 196.623 139.818 196.17 135.509 195.884L134.391 195.808L134.18 196.899C128.312 226.827 115.039 255.923 95.7945 281.045C81.1372 300.175 65.9507 309.798 50.65 309.643C34.023 309.479 17.9752 296.119 13.3261 278.573C13.1151 277.777 12.9264 276.961 12.762 276.159C9.46891 259.717 15.6089 240.759 28.7721 226.685C39.589 215.123 55.074 206.212 74.7925 200.202C92.7729 194.727 111.736 192.34 131.162 193.122L132.278 193.165L132.467 192.067C134.23 181.784 135.1 171.394 135.067 161.199C135.011 146.691 133.1 132.232 129.392 118.234C122.02 90.4116 107.853 65.4312 88.4247 45.9926C73.5035 31.0587 54.9835 19.2708 34.8665 11.8913C14.8174 4.53484 -6.42448 1.7401 -26.6506 3.789L-26.9391 1.27587C-6.32727 -0.819183 15.3256 2.02498 35.7547 9.52065C56.221 17.0271 75.0574 29.0253 90.2393 44.2181C109.979 63.9773 124.373 89.3481 131.861 117.61C135.624 131.812 137.562 146.482 137.618 161.194C137.66 171.346 136.806 181.691 135.079 191.93L134.847 193.305L136.245 193.404C140.424 193.689 144.645 194.134 148.787 194.722C173.788 198.266 197.814 207.193 218.287 220.549C228.889 227.469 238.791 235.736 247.771 245.129C247.497 245.337 247.232 245.556 246.965 245.789"
            fill="#FBF8EF"
          />
        </svg>
      </div>
      <div className="ownersStoryPictureWrapper__picture" />
    </div>
    <div className="ownersStoryTextWrapper">
      <div>
        We're Jamie and Emily, former Labor & Delivery nurses in Boston, moms of
        6 girls combined, and co-founders of{" "}
        <span className="strong">NAPS™</span>, a unique program that provides{" "}
        <span className="strong">
          personalized support from pregnancy through childhood from experienced
          Registered Nurses.
        </span>
      </div>
    </div>
  </div>
);

export default OwnersStory;
