import React, { useState } from "react";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { CityStateZipValSub } from "../../../../Common/commonFIeld/validation";
import { Link } from "react-router-dom";
import * as path from "../../../../Routes/RouteConstent";

const WelcomeHomeUserInfo = ({
  handleNext,
  value,
  setValue,
  handlePrev,
  term_condition,
  setPolicy,
}: any) => {
  const [errors, setErrors] = useState<any>({});

  const handleChange = (e: React.ChangeEvent<HTMLInputElement> | any) => {
    let name = e.target.name;
    let inputValue = e.target.value;
    if (
      e.target.name === "client_service_agreement" ||
      e.target.name === "privacy_practises"
    ) {
      if (e.target.checked) {
        setErrors({
          ...errors,
          [e.target.name]: "",
        });
      } else {
        setErrors({
          ...errors,
          [e.target.name]: "Please select the field",
        });
      }
      setValue({
        ...value,
        [e.target.name]: e.target.checked,
      });
      return;
    }
    setValue({
      ...value,
      [name]: inputValue,
    });
  };
  const dateHandler = (date: Date | null | any, name: string) => {
    setErrors({
      ...errors,
      [name]: "",
    });
    setValue({
      ...value,
      [name]: date,
    });
  };

  const handleSubmit = () => {
    let cityStateZip = CityStateZipValSub(value);
    let error: any = {};

    if (!value?.due_date) {
      error.due_date = "Please select date";
    }
    if (!value?.child_name) {
        error.child_name = "Please select date";
      }
    if (value?.due_date === "Invalid date") {
      error.due_date = "Please select date";
    }
    if (!value?.is_your_first_baby) {
      error.is_your_first_baby = "Please select the value";
    }
    if (!value?.medical_surgical_history_baby) {
      error.medical_surgical_history_baby = "Please input the value";
    }

    if (!term_condition) {
      error.term_condition = "Please check the policy";
    }

    if (!value?.client_service_agreement) {
      error.client_service_agreement = "Please select the policy to continue";
    }
    if (!value?.privacy_practises) {
      error.privacy_practises = "Please select the policy to continue";
    }

    if (!value.full_street_address_with_zip) {
      error.full_street_address_with_zip =
        "Please input the full street address, including zip code";
    }

    if (!value.nurse_parking_information) {
      error.nurse_parking_information = "Please input the parking information";
    }

    if (!value?.pediatrician_name_practic_name) {
        error.pediatrician_name_practic_name = "Please input the value";
    }

    if (!value?.mother_dob) {
        error.mother_dob = "Please input the value";
    }

    if (!value?.ob_name_practic_name) {
        error.ob_name_practic_name = "Please input the value";
    }

    if (!value?.delivery_type) {
        error.delivery_type = "Please select the value";
    }

    if (!value?.pediatrician_name_practic_name) {
        error.pediatrician_name_practic_name = "Please input the value";
    }

    if (!value?.questions_or_concerns_you_want_to_cover) {
        error.questions_or_concerns_you_want_to_cover = "Please input the value";
    }

    if (!value?.child_birth_weight) {
        error.child_birth_weight = "Please input the value";
    }

    if (!value?.pets_at_home) {
        error.pets_at_home = "Please select the value";
    }

    if (Object.keys(error).length) {
      setErrors({ ...error, ...cityStateZip });
    } else {
      handleNext(value);
    }
  };

  const handleTermPolicy = (
    e: React.ChangeEvent<HTMLInputElement>,
    name: string
  ) => {
    setErrors({
      ...errors,
      [name]: "",
    });
    setPolicy(e.target.checked);
    setValue({
      ...value,
      term_condition: e.target.checked,
    });
  };
  let handleRadioButton = (e: any) => {
    let val = e.target.value;
    setErrors({
      ...errors,
      [e.target.name]: "",
    });
    setValue({
      ...value,
      [e.target.name]: val,
    });
  };

  // const DObHandler = (date: Date | null | any) => {
  //   setErrors({
  //     ...errors,
  //     ["mother_dob"]: "",
  //   });
  //   setValue({
  //     ...value,
  //     ["mother_dob"]: date,
  //   });
  // };

  return (
    <div>
      <section className="Pre-baby_bootcamp step-container step2">
        <div className="consult-section-heading text-center">
          <h3 className="my-5">Complete your Information</h3>
        </div>
        <form className=" ">
          <div className="row">
            <div className="form-group col-lg-12 col-md-12 mb-3 due_dt_container">
              <label>
                Due Date/Child's Birthday{" "}
                <span className="mandatory text-danger mx-2">*</span>
              </label>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack spacing={3}>
                  <MobileDatePicker
                    value={value?.due_date || ""}
                    onChange={(date: any) => dateHandler(date, "due_date")}
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        placeholder="MM-DD-YYYY"
                        className="due-date-field"
                      />
                    )}
                  />
                </Stack>
              </LocalizationProvider>
              <h4 className="error-msgtext">{errors?.due_date}</h4>
            </div>

            <div className="form-group col-lg-12 col-md-12 mb-3 due_dt_container">
              <label>
                Child's Name <span className="mandatory text-danger mx-2">*</span>
              </label>
              <input
                type="text"
                name="child_name"
                value={value?.child_name}
                className="form-control"
                onChange={handleChange}
              />
              <h4 className="error-msgtext">{errors?.child_name}</h4>
            </div>

            <div className="form-group col-lg-12 col-md-12 mb-3">
              <label>
                Is this your first baby?{" "}
                <span className="mandatory text-danger mx-2">*</span>
              </label>
              <div className="custom-radio custom-control-inline">
                <input
                  type="radio"
                  id="customRadio1"
                  name="is_your_first_baby"
                  value="yes"
                  checked={value?.is_your_first_baby === "yes"}
                  onChange={handleRadioButton}
                  className="custom-control-input form-check-input"
                />
                <label
                  className="custom-control-label me-3"
                  htmlFor="customRadio1"
                >
                  yes
                </label>
                <input
                  type="radio"
                  id="customRadio2"
                  name="is_your_first_baby"
                  value="no"
                  checked={value?.is_your_first_baby === "no"}
                  onChange={handleRadioButton}
                  className="custom-control-input form-check-input"
                />
                <label
                  className="custom-control-label step_two"
                  htmlFor="customRadio2"
                >
                  no
                </label>
              </div>
              <h4 className="error-msgtext">{errors?.is_your_first_baby}</h4>
            </div>

            <div className="form-group col-sm-12 mb-3">
              <label>
                Full street address, including zip code{" "}
                <span className="mandatory text-danger mx-2">*</span>
              </label>
              <input
                type="text"
                name="full_street_address_with_zip"
                value={value?.full_street_address_with_zip}
                className="form-control"
                onChange={handleChange}
              />
              <h4 className="error-msgtext">
                {errors?.full_street_address_with_zip}
              </h4>
            </div>

            <div className="form-group col-sm-12 mb-3">
              <label>
                Parking Information for nurse ( parking must be provided ){" "}
                <span className="mandatory text-danger mx-2">*</span>
              </label>
              <input
                type="text"
                name="nurse_parking_information"
                value={value?.nurse_parking_information}
                className="form-control"
                onChange={handleChange}
              />
              <h4 className="error-msgtext">
                {errors?.nurse_parking_information}
              </h4>
            </div>

            <div className="form-group col-lg-12 col-md-12 mb-3">
              <label>
                Partner or Support Person's Name{" "}
                <span className="fw-light"> (if applicable)</span>
              </label>
              <input
                type="text"
                name="partner_support_person_name"
                value={value?.partner_support_person_name}
                className="form-control"
                onChange={handleChange}
              />
            </div>

            <div className="form-group col-lg-12 col-md-12 mb-3">
              <label>
                Partner or Support Person's Email address{" "}
                <span className="fw-light">
                  ( for reminders, content, and follow up )
                </span>
              </label>
              <input
                type="text"
                name="partner_support_person_email_address"
                value={value?.partner_support_person_email_address}
                className="form-control"
                onChange={handleChange}
              />
            </div>

            <div className="form-group col-lg-12 col-md-12 mb-3">
              <label>
                Child's birth weight:{" "}
                <span className="mandatory text-danger mx-2">*</span>
              </label>
              <input
                type="text"
                name="child_birth_weight"
                value={value?.child_birth_weight}
                className="form-control"
                onChange={handleChange}
              />
              <h4 className="error-msgtext">{errors?.child_birth_weight}</h4>
            </div>

            <div className="form-group col-lg-12 col-md-12 mb-3">
              <label>
                Pediatrician's Name/Practice Name:{" "}
                <span className="mandatory text-danger mx-2">*</span>
              </label>
              <input
                type="text"
                name="pediatrician_name_practic_name"
                className="form-control"
                onChange={handleChange}
                value={value?.pediatrician_name_practic_name}
              />
              <h4 className="error-msgtext">
                {errors?.pediatrician_name_practic_name}
              </h4>
            </div>

            <div className="form-group col-lg-12 col-md-12 mb-3">
              <label>
                Medical and/or Surgical History for BABY:{" "}
                <span className="mandatory text-danger mx-2">*</span>
              </label>
              <textarea
                name="medical_surgical_history_baby"
                value={value?.medical_surgical_history_baby}
                onChange={handleChange}
                className="form-control"
              ></textarea>
              <h4 className="error-msgtext">
                {errors?.medical_surgical_history_baby}
              </h4>
            </div>

            <div className="form-group col-lg-12 col-md-12 mb-3 due_dt_container">
              <label>
                Mother's DOB:
                <span className="mandatory text-danger mx-2">*</span>
              </label>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack spacing={3}>
                  <MobileDatePicker
                    value={value?.mother_dob || ""}
                    onChange={(date: any) => dateHandler(date, "mother_dob")}
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        placeholder="MM-DD-YYYY"
                        className="due-date-field"
                      />
                    )}
                  />
                </Stack>
              </LocalizationProvider>
              <h4 className="error-msgtext">{errors?.mother_dob}</h4>
            </div>

            <div className="form-group col-lg-12 col-md-12 mb-3">
              <label>
                OB's Name/Practice Name{" "}
                <span className="mandatory text-danger mx-2">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                name="ob_name_practic_name"
                onChange={handleChange}
                value={value?.ob_name_practic_name}
              />
              <h4 className="error-msgtext">{errors?.ob_name_practic_name}</h4>
            </div>

            <div className="mb-3 delivery_type">
              <h4>
                Delivery Type{" "}
                <span className="mandatory text-danger mx-2">*</span>
              </h4>
              <div className="">
                <div className="form-check d-flex">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="delivery_type"
                    onChange={handleChange}
                    value="Vaginal Delivery"
                    checked={value?.delivery_type === "Vaginal Delivery"}
                    id="customCheck1"
                  />
                  <label className="form-check-label">Vaginal Delivery</label>
                </div>

                <div className="form-check d-flex">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="delivery_type"
                    onChange={handleChange}
                    value="Vacuum or Forcep Assisted Vaginal Delivery"
                    checked={
                      value?.delivery_type ===
                      "Vacuum or Forcep Assisted Vaginal Delivery"
                    }
                  />
                  <label className="form-check-label">
                    Vacuum or Forcep Assisted Vaginal Delivery
                  </label>
                </div>

                <div className="form-check d-flex">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="delivery_type"
                    onChange={handleChange}
                    value="C-section planned"
                    checked={value?.delivery_type === "C-section planned"}
                  />
                  <label className="form-check-label">C-section planned</label>
                </div>

                <div className="form-check d-flex">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="delivery_type"
                    onChange={handleChange}
                    value="C-section unplanned"
                    checked={value?.delivery_type === "C-section unplanned"}
                  />
                  <label className="form-check-label">
                    C-section unplanned
                  </label>
                </div>
              </div>
              <h4 className="error-msgtext">{errors?.delivery_type}</h4>
            </div>

            <div className="form-group col-lg-12 col-md-12 mb-3">
              <label>
                Medical and/or Surgical History for Birthing Mom:{" "}
                
              </label>
              <input
                type="text"
                className="form-control"
                name="medical_surgical_history_mother"
                placeholder="Enter history"
                value={value?.medical_surgical_history_mother}
                onChange={handleChange}
              />
            </div>
            <div className="form-group col-lg-12 col-md-12 mb-3">
              <label>
                Please feel free to list any questions or concerns you want to
                cover during this visit, including any feeding concerns.{" "}
                <span className="mandatory text-danger mx-2">*</span>
              </label>

              <textarea
                name="questions_or_concerns_you_want_to_cover"
                className="form-control"
                onChange={handleChange}
                value={value?.questions_or_concerns_you_want_to_cover}
              ></textarea>
              <h4 className="error-msgtext">
                {errors?.questions_or_concerns_you_want_to_cover}
              </h4>
            </div>

            <div className="mb-3 delivery_type">
              <h4>
                Do you have any pets at home? (check all that apply){" "}
                <span className="mandatory text-danger mx-2">*</span>
              </h4>
              <div className="">
                <div className="form-check d-flex">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="pets_at_home"
                    onChange={handleChange}
                    value="No"
                    checked={value?.pets_at_home === "No"}
                  />
                  <label className="form-check-label">No</label>
                </div>

                <div className="form-check d-flex">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="pets_at_home"
                    onChange={handleChange}
                    value="Yes (DOG)"
                    checked={value?.pets_at_home === "Yes (DOG)"}
                  />
                  <label className="form-check-label">Yes (dog)</label>
                </div>

                <div className="form-check d-flex">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="pets_at_home"
                    onChange={handleChange}
                    value="Yes (Cats)"
                    checked={value?.pets_at_home === "Yes (Cats)"}
                  />
                  <label className="form-check-label">Yes (Cats)</label>
                </div>
              </div>
              <h4 className="error-msgtext">{errors?.pets_at_home}</h4>
            </div>

            <div className="agreement-container py-4 d-flex flex-column">
              <div>
                <div className="custom-radio custom-control-inline">
                  <input
                    type="checkbox"
                    id="customRadio1"
                    name="term_condition"
                    className="custom-control-input form-check-input"
                    value="Accepted"
                    checked={term_condition}
                    onChange={(e) => handleTermPolicy(e, "term_condition")}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="customRadio1"
                  >
                    I have read and agree to the{" "}
                    <Link target="_blank" className="blue-link" to={path.TERMS}>
                      {" "}
                      terms of service
                    </Link>
                    <span className="mandatory text-danger mx-2">*</span>
                  </label>
                </div>
                <h4 className="error-msgtext">{errors?.term_condition}</h4>
              </div>

              <div>
                <div className="custom-radio custom-control-inline">
                  <input
                    type="checkbox"
                    id="customRadio1"
                    name="client_service_agreement"
                    className="custom-control-input form-check-input"
                    value="Accepted"
                    checked={value?.client_service_agreement}
                    onChange={handleChange}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="customRadio1"
                  >
                    I have read and agree to the{" "}
                    <Link
                      target="_blank"
                      className="blue-link"
                      to={path.CLIENT_SERVICE_AGREEMENT}
                    >
                      {" "}
                      client service agreement{" "}
                    </Link>
                    <span className="mandatory text-danger mx-2">*</span>
                  </label>
                </div>
                <h4 className="error-msgtext">
                  {errors?.client_service_agreement}
                </h4>
              </div>

              <div>
                <div className="custom-radio custom-control-inline">
                  <input
                    type="checkbox"
                    id=""
                    name="privacy_practises"
                    className="custom-control-input form-check-input"
                    value="Accepted"
                    checked={value?.privacy_practises}
                    onChange={handleChange}
                  />
                  <label className="custom-control-label" htmlFor="">
                    I have read and agree to the{" "}
                    <Link
                      target="_blank"
                      className="blue-link"
                      to={path.CONSENT_FOR_DISCLOSURE}
                    >
                      {" "}
                      consent for disclosure{" "}
                    </Link>
                    and{" "}
                    <Link
                      target="_blank"
                      className="blue-link"
                      to={path.PRIVACY}
                    >
                      {" "}
                      privacy practices
                    </Link>
                    <span className="mandatory text-danger mx-2">*</span>
                  </label>
                </div>
                <h4 className="error-msgtext">{errors?.privacy_practises}</h4>
              </div>
            </div>
          </div>
        </form>

        <div className="section-navigation my-5 d-flex justify-content-between">
          <button className="primary-blue-small-btn" onClick={handlePrev}>
            Previous
          </button>
          <button
            className="primary-blue-small-btn next"
            onClick={handleSubmit}
          >
            Next
          </button>
        </div>
      </section>
    </div>
  );
};

export default WelcomeHomeUserInfo;
