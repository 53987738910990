import React, { useState } from 'react';
import ConsultForm from '../../../../Common/Consults/dynamicForm';

const UserInfo = ({ handleNext, value, setValue, handlePrev,param }: any) => {
  const [term_condition, setTermPolicy] = useState<boolean>(false)


  return (
    <section className='step-container step2 user-info'>
      <ConsultForm
        handleNext={handleNext} value={value}
        setValue={setValue} handlePrev={handlePrev}
        term_condition={term_condition}
        setTermPolicy={setTermPolicy}
        param={param}
      />
    </section>
  )
}

export default UserInfo;