import React from 'react';
import FilterImg from "../../Assets/img/icons/filter.svg";
import SearchImg from "../../Assets/img/icons/Search.svg";
// SearchFilter.scss includes styles for search wrapper
import './SearchFilter.scss';

const SearchFilter = ({
    isFilter, isSearch,
    setIsFilter, setIsSearch, tab, isMember,
    handleChange,
    search,
}: any) => {
    const onClose = () => {
        setIsFilter(!isFilter)
        isSearch && setIsSearch(false)
    }


    return (
        <div className="searchFullWidthMain">
            <p
                className="filter_para mb-0 text-left"
                onClick={onClose}
            >
                <img src={FilterImg} className="mr-2" alt="filter" /> Filter
            </p>
            <input
                className="searchFullWidthField"
                id="searchFullWidthField"
                name="searchFullWidthField"
                onChange={handleChange}
                value={search}
            />
            <label
                className="d-inline-block text-right search_div_items mb-0 "
                htmlFor="searchFullWidthField"
            >
                <img src={SearchImg} alt="search icon" />
            </label>
        </div>
    )
}

export default SearchFilter