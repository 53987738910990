import React from "react";
import { Row } from "react-bootstrap";
import "./legal.scss";

export const ClientServiceAgreement = () => {
  return (
    <div className="container-fluid legal">
      <Row>
        <div className="Col-lg-12 Col-sm-12">
          <div className="container">
            <div className="Row">
              <div className="col-lg-12 col-sm-12 py-5">
                <h1 className="text-start">Client Services Agreement</h1>
                <p>
                  SCOPE OF THE TERM “CLIENT” For the purposes of this Agreement,
                  the term “Client” refers to the individual(s) seeking the
                  services of Boston NAPS and/or any children that the Company
                  and Client expressly agree to include as part of this
                  Agreement and the services that Boston NAPS provides. These
                  individuals and children are listed by name in the above
                  paragraph. The term “Client” does not include other children
                  in the same household, family, or otherwise, and Boston NAPS
                  is not expected to provide (nor will it provide) services to
                  children and individuals other than those expressly named in
                  and agreed upon by this document. Additionally, Boston NAPS
                  does not provide care to animals and/or pets. Should the
                  Client wish to expand the scope of care and add on additional
                  children or other individuals, the Client and Company shall
                  amend this Agreement to include the additional children and/or
                  individuals. Company reserves the right to modify the cost of
                  services in accordance with any change in services and/or
                  addition of individuals. SCOPE OF SERVICES. Boston NAPS, LLC
                  provides comprehensive prenatal and postpartum services to
                  families and individuals, including, but not limited to,
                  consulting, education, and lactation services and in home
                  nursing care to postpartum families and their newborns. In
                  home services provided by Boston NAPS include, but are not
                  limited to, pre-natal education, lactation consulting, newborn
                  consulting, sleep consulting and training, bottle consulting
                  and training, and car seat installation and/or checks to
                  ensure that a car seat is installed properly and safely in
                  accordance with federal and state regulations, and such other
                  services as may be required by the Client. Boston NAPS also
                  conducts pre-natal and postpartum educational classes and
                  workshops at various locations throughout Massachusetts this
                  Agreement will outline the terms that govern Companyʼs
                  services and the Clientʼs responsibilities. OBLIGATIONS OF THE
                  COMPANY. All consultation, educational, lactation, and car
                  seat checks and assisted installation services are provided by
                  Boston NAPS employees who are Registered Nurses (“Nurse”),
                  Nurse Practitioners, other licensed Medical and Para-Medical
                  Professionals, and unlicensed assistants as appropriate for
                  the particular services requested by the Client. he Company
                  acknowledges that privacy and discretion are of utmost
                  importance to the Client. The Company and its employees shall
                  maintain the Clientʼs confidentiality as required by federal
                  and state law. The Company will provide only such information
                  to those third parties as disclosed in the statement of
                  Clientʼs privacy rights and HIPAA disclosures, provided to the
                  Client in a separate disclosure. TERMS AND CONDITIONS. In Home
                  Services and Private Classes: All in home consultation and
                  educational services shall be agreed upon, scheduled and paid
                  for in full no later than 24 hours in advance of such
                  services. Outside Classes and Workshops: All outside classes
                  and workshops must be scheduled and paid for in full no later
                  than 24 hours before the class or workshop. To reschedule a
                  class, please provide 24 hoursʼ notice from the current class
                  you are enrolled in and your payment will be transferred
                  towards the class in which you re-enroll. This Client Services
                  Agreement must be signed and received by Boston NAPS no later
                  than twenty- four (24) hours prior to the date and time that
                  services are to be provided. Payment For Services. Company
                  services are private pay only. Boston NAPS does not bill third
                  party payers. It is Clientʼs responsibility to seek and ensure
                  Client will be reimbursed for services. The Company requires
                  payment in full no later than 24 hours prior to commencement
                  of services or of a class/workshop. For all services, the
                  Company requires a non-refundable $100 initial consultation
                  fee to be paid at the time of consultation. If the initial
                  consultation fee is not paid by the due date, the services
                  shall not be provided. Payment for both the initial
                  consultation fee and any fee for In Home services and private
                  classes may be made by personal check, money order or via
                  PayPal. Checks and money orders should be made payable to
                  “Boston NAPS, LLC,” and payment should be mailed to: Boston
                  NAPS, LLC 724 East 2nd Street Unit 1 Boston, MA 02127 All
                  payment for outside classes and workshops shall be made via
                  PayPal. By submitting payment via PayPal, you secure your seat
                  in the class or workshop. Payment is for you and one support
                  person. For additional support persons, please inquire for
                  rates. Cancellation Policy: Client must notify Boston NAPS of
                  a service cancellation at least twenty four (24) hours prior
                  to the scheduled time of service to avoid a charge for that
                  service. If the Client fails to provide 24 hoursʼ notice prior
                  to cancellation, Client shall pay the Company for that dayʼs
                  service since this time was specifically scheduled and held
                  for the Client. No refund will be provided for a cancellation
                  made less than 24 hours in advance, or in case of a no show.
                  In the event that you deliver prior to your class date, a
                  refund will be issued with a doctorʼs note. If a cancellation
                  is made less than 24 hours in advance, the non-refundable
                  payment may be used as a credit towards a postpartum service
                  offered by Boston NAPS. The Client shall not dispute the
                  charges billed pursuant to the mutually agreed upon schedule.
                  Inclement Weather or Instructor Illness: Boston NAPS will
                  contact all parties registered for a class if the class has
                  been canceled due to inclement weather or instructor illness.
                  In the event of a canceled class, Boston NAPS will offer
                  another date for the same missed class or re-enroll you into
                  the next scheduled class being offered. Additional Policies
                  and Information: We highly recommend you schedule your
                  prenatal class or workshop prior to 36 weeks gestation to
                  avoid missing you program due to delivery. Boston NAPS is not
                  responsible for any parking fees incurred during a class.
                  Information provided in all classes taught by Boston NAPS, LLC
                  is offered for educational purposes only and should not be
                  construed as medical advice. Such information is not intended
                  to be complete, nor to replace the proper and thorough medical
                  advice of your own physician or health care provider.
                  Attendees of group classes and private classes disclaim any
                  and all liability for any injury or other damages which may be
                  directly associated with the communication or review of
                  information contained in our classes/workshops/programs.
                  DISCLOSURE. The Company is not affiliated in any way with the
                  Clientʼs physician, obstetrician/gynecologist, surgeon or any
                  other health care providers or staff affiliated with such
                  individuals. The Company is also not affiliated in any way
                  with any third party service provider that may provide
                  services that are arranged by the Company. The Company does
                  not make legal or medical decisions for the Client and shall
                  not be liable for any consequences occurring as a result of
                  the legal or medical decisions made by the Client, the
                  Clientʼs legal representative or physician. Client hereby
                  waives all claims against Boston NAPS, LLC for any damages or
                  consequences of said legal or medical decisions. LIMITATION OF
                  SERVICES. Company will make every good faith effort to provide
                  the requested services to Client according to the agreed upon
                  terms. The Client agrees to provide accurate and complete
                  information to Boston NAPS and the employees of Boston NAPS to
                  ensure that appropriate services are provided. Agreement to a
                  schedule shall not constitute a guarantee of service, and
                  Company reserves the right to cancel or suspend services with
                  or without notice. Although Company will make every effort to
                  provide services, in the rare instance that Company is unable
                  to provide scheduled services (whether this is due to employee
                  cancellation, illness, holiday, extreme weather, exigent
                  circumstances or an unanticipated and/or last-minute request
                  for nursing services), Company shall not be held liable for
                  failure to provide services, and Client releases Company of
                  any potential liability hereunder. SERVICES DURING HOLIDAYS.
                  By signing this Agreement, Client acknowledges that pricing
                  for services may vary during holidays and other high-demand
                  occasions and times of the year. Company will notify Client
                  promptly of any proposed rate changes, and Client reserves the
                  right to decline services. Additionally, in accordance with
                  the terms of the above “Limitation of Services” section,
                  Client acknowledges that Company may be limited in its ability
                  to provide services during holidays and other high-demand time
                  periods, and releases Company from any potential liability
                  hereunder. STATEMENT OF PATIENT PRIVACY RIGHTS. Company will
                  collect health information to ensure Client receives quality
                  health care advice. Client has the right to have personal
                  health care information kept confidential, and Boston NAPS LLC
                  will keep personal health care information confidential. This
                  means that only those who are legally authorized to know, or
                  who have a medical need to know, will see Clientʼs personal
                  information. All other information for the purpose of
                  improving the care Boston NAPSʼ provides will be disclosed
                  anonymously. Client has the right to refuse to answer
                  questions, and does not have to answer every question to
                  obtain services. Client has the right to review Clientʼs
                  personal health information. Company knows how important it is
                  to collect and maintain correct health information, and Client
                  reserves the right to ask Company to modify Clientʼs health
                  records, should Client notice a mistake or discrepancy in the
                  health records. ADVANCE DIRECTIVE FOR HEALTHCARE. Unless an
                  advance directive is on file with Boston NAPS LLC, all efforts
                  will be made by the Nurse to sustain Clientʼs life in the
                  unlikely event of an emergency. It is Boston NAPS LLCʼs policy
                  to respect individual choice and to avoid discrimination based
                  on whether or not the Client has an advance directive. *
                </p>
                <p>
                  RELEASE OF INFORMATION. Client hereby consents to and
                  authorizes Boston NAPS to disclose and release information
                  contained in Clientʼs clinical record to the health care
                  providers involved in Clientʼs care. At times, information may
                  be released for the purpose of utilization review,
                  professional standards review organizations, regulatory review
                  entities and any other organizations that may/will assist
                  Client to meet Clientʼs home care needs. However, release of
                  information to these organizations will remain anonymous.
                  LIMITATION OF LIABILITY. Under no circumstances shall the
                  Company be liable or responsible for any services provided by
                  third parties. The Client shall indemnify, defend, and hold
                  harmless the Company and its successors, officers, members,
                  agents, independent contractors and employees from any and all
                  actions, causes of action, claims, demands, cost liabilities,
                  expenses and damages (including attorneys fees) arising out
                  of, or in connection with, any services provided by any third-
                  party service provider that provides services that are
                  arranged by the Company pursuant to the provided services or
                  in any way connected with the services rendered under the
                  terms of this Agreement. The Company shall not be liable for
                  any consequences occurring as a result of the legal or medical
                  decisions made by the Client, the Clientʼs legal
                  representative or physician. The Client hereby waives all
                  claims against the Company, its members, agents, independent
                  contractors and employees for any damages or consequences of
                  said medical or legal decisions. In the event Client elects
                  home care by a third party referred to the Client by the
                  Company rather than care at a medical facility or surgical
                  center that is contrary to the medical advice of the Clientʼs
                  treating physician, the Company shall not be liable for any
                  damages or the consequences of this decision. GOVERNING LAW;
                  ENFORCEMENT COSTS. This Agreement with be construed in accord
                  with, and any dispute arising from any breach or asserted
                  breach of this Agreement will be governed by, the laws of the
                  Commonwealth of Massachusetts. Neither party to this Agreement
                  shall institute a proceeding in any court or administrative
                  agency to resolve a dispute between the parties before that
                  party has sought to resolve the dispute through negotiations
                  with the other party. If a dispute is not resolved within
                  three (3) weeks after a demand for direct negotiation, the
                  parties shall attempt to resolve the dispute through
                  mediation. If the parties do not promptly agree on a mediator,
                  either party may request the appointment of a qualified
                  mediator. If the Company is forced to take legal action to
                  enforce this Agreement or institute collection efforts for any
                  amounts due under this Agreement, all fees and costs,
                  including but not limited to, collection fees, attorneyʼs
                  fees, travel expenses, court costs and any post-judgment
                  collection fees, shall be the sole responsibility of the
                  Client. INTEGRATION. This Agreement may be executed in two (2)
                  or more counterparts each of which shall be deemed an
                  original, but all of such taken together shall constitute only
                  one Agreement, superseding all prior understandings, oral or
                  written; and it is expressly understood and that this
                  Agreement does not obligate either party to enter into any
                  other or further agreements. A facsimile signature shall be
                  deemed legally binding. NO THIRD-PARTY BENEFICIARIES. Nothing
                  in this Agreement will be construed to give any rights or
                  benefits in this Agreement to anyone other than the Company
                  and the Client. All duties and responsibilities undertaken
                  pursuant to this Agreement will be for the sole and exclusive
                  benefit of the Company and the Client, and not for the benefit
                  of any other party. ENTIRE AGREEMENT. This Agreement and any
                  writing incorporated by reference herein constitute the
                  partiesʼ entire agreement. here are no restrictions, promises,
                  representations, warranties, covenants or understandings other
                  than those expressly set forth herein. This Agreement
                  supersedes all prior agreements between the parties and may be
                  modified or amended only by a writing signed by both parties.
                  AMENDMENT. This Agreement may be amended only by a writing
                  signed by the Client and by a duly authorized representative
                  of the Company. SEVERABILITY. The invalidity or
                  unenforceability of any provision hereof (as determined by a
                  court or arbitrator of competent jurisdiction) shall in no way
                  affect the validity or enforceability of the remainder of this
                  Agreement or any other provision hereof. MODIFICATION AND
                  WAIVER. No oral modifications shall be effective, and no delay
                  or failure on the part of either party to insist on compliance
                  with any provision hereof shall constitute a waiver of such
                  partyʼs right to enforce such provision. All waivers must be
                  in writing by a duly authorized representative of the party.
                  CONSTRUCTION. The headings and captions of this Agreement are
                  provided for convenience only and are intended to have no
                  effect in construing or interpreting this Agreement. The
                  language in all parts of this Agreement shall be in all cases
                  construed according to its fair meaning and not strictly for
                  or against either party. All pronouns and words denoting
                  gender shall be construed so as to refer to the masculine,
                  feminine, neuter, or singular form thereof as the identity of
                  the persons, entities and situation may require. TERM. This
                  Agreement shall commence on the date hereof and shall
                  terminate One (1) year after the birth of the child named in
                  this Agreement herein, or upon the end of the third successive
                  month of no services being booked by the Client, whichever
                  occurs first (the “term”). NOTICES. All notices and other
                  communications hereunder will be in writing or by written
                  telecommunication, and will be deemed to have been duly given
                  if delivered personally or if mailed by certified mail, return
                  receipt requested or by written telecommunication to the
                  relevant address of the party, or to such address as the
                  recipient of such notice or communication will have specified
                  to the other party hereto in accordance with this section. IN
                  WITNESS WHEREOF, the Parties have executed this Agreement as
                  noted below: *
                </p>
                <h2 className="text-start">
                  Client Services Agreement (large print)
                </h2>
                <p>
                  This Client Services Agreement (“Agreement”), is made by and
                  between <strong>Boston NAPS, LLC</strong>, a Massachusetts
                  Limited Liability Company, (the “Company,” or “Boston NAPS”),
                  and the “Client.”
                </p>
                <p>
                  The Company and the Client shall be referred to as the
                  “Parties.” This Agreement shall include any child/children of
                  the Client.
                </p>
                <p>
                  <strong>SCOPE OF THE TERM “CLIENT” </strong>For the purposes
                  of this Agreement, the term “Client” refers to the
                  individual(s) seeking the services of Boston NAPS and/or any
                  children that the Company and Client expressly agree to
                  include as part of this Agreement and the services that Boston
                  NAPS provides. These individuals and children are listed by
                  name in the above paragraph. he term “Client” does not include
                  other children in the same household, family, or otherwise,
                  and Boston NAPS is not expected to provide (nor will it
                  provide) services to children and individuals other than those
                  expressly named in and agreed upon by this document.
                  Additionally, Boston NAPS does not provide care to animals
                  and/or pets. Should the Client wish to expand the scope of
                  care and add on additional children or other individuals, the
                  Client and Company shall amend this Agreement to include the
                  additional children and/or individuals. Company reserves the
                  right to modify the cost of services in accordance with any
                  change in services and/or addition of individuals.
                </p>
                <p>
                  <strong>SCOPE OF SERVICES. </strong>Boston NAPS, LLC provides
                  comprehensive prenatal and postpartum services to families and
                  individuals, including, but not limited to, consulting,
                  education, and lactation services and in home nursing care to
                  postpartum families and their newborns. In home services
                  provided by Boston NAPS include, but are not limited to,
                  pre-natal education, lactation consulting, newborn consulting,
                  sleep consulting and training, bottle consulting and training,
                  and car seat installation and/or checks to ensure that a car
                  seat is installed properly and safely in accordance with
                  federal and state regulations, and such other services as may
                  be required by the Client. Boston NAPS also conducts pre-natal
                  and postpartum educational classes and workshops at various
                  locations throughout Massachusetts this Agreement will outline
                  the terms that govern Companyʼs services and the Clientʼs
                  responsibilities.
                </p>
                <p>
                  <strong>OBLIGATIONS OF THE COMPANY. </strong>All consultation,
                  educational, lactation, and car seat checks and assisted
                  installation services are provided by Boston NAPS employees
                  who are Registered Nurses (“Nurse”), Nurse Practitioners,
                  other licensed Medical and Para-Medical Professionals, and
                  unlicensed assistants as appropriate for the particular
                  services requested by the Client. he Company acknowledges that
                  privacy and discretion are of utmost importance to the Client.
                  The Company and its employees shall maintain the Clientʼs
                  confidentiality as required by federal and state law. The
                  Company will provide only such information to those third
                  parties as disclosed in the statement of Clientʼs privacy
                  rights and HIPAA disclosures, provided to the Client in a
                  separate disclosure.
                </p>
                <h3 className="text-start">TERMS AND CONDITIONS. </h3>
                <p>
                  <strong>In Home Services and Private Classes</strong>: All in
                  home consultation and educational services shall be agreed
                  upon, scheduled and paid for in full no later than 24 hours in
                  advance of such services.
                </p>
                <p>
                  <strong>Outside Classes and Workshops</strong>: All outside
                  classes and workshops must be scheduled and paid for in full
                  no later than 24 hours before the class or workshop. To
                  reschedule a class, please provide 24 hoursʼ notice from the
                  current class you are enrolled in and your payment will be
                  transferred towards the class in which you re-enroll.
                </p>
                <p>
                  This Client Services Agreement must be signed and received by
                  Boston NAPS no later than twenty- four (24) hours prior to the
                  date and time that services are to be provided.
                </p>
                <p>
                  <strong>Payment For Services</strong>. Company services are
                  private pay only. Boston NAPS does not bill third party
                  payers. It is Clientʼs responsibility to seek and ensure
                  Client will be reimbursed for services. The Company requires
                  payment in full no later than 24 hours prior to commencement
                  of services or of a class/workshop. For all services, the
                  Company requires a non-refundable $100 initial consultation
                  fee to be paid at the time of consultation. If the initial
                  consultation fee is not paid by the due date, the services
                  shall not be provided. Payment for both the initial
                  consultation fee and any fee for In Home services and private
                  classes may be made by personal check, money order or via
                  PayPal. Checks and money orders should be made payable to
                  “Boston NAPS, LLC,” and payment should be mailed to:
                </p>
                <p>
                  Boston NAPS, LLC 724 East 2nd Street Unit 1 Boston, MA 02127
                </p>
                <p>
                  All payment for outside classes and workshops shall be made
                  via PayPal. By submitting payment via PayPal, you secure your
                  seat in the class or workshop. Payment is for you and one
                  support person. For additional support persons, please inquire
                  for rates.
                </p>
                <p>
                  <strong>Cancellation Policy</strong>: Client must notify
                  Boston NAPS of a service cancellation at least twenty four
                  (24) hours prior to the scheduled time of service to avoid a
                  charge for that service. If the Client fails to provide 24
                  hoursʼ notice prior to cancellation, Client shall pay the
                  Company for that dayʼs service since this time was
                  specifically scheduled and held for the Client. No refund will
                  be provided for a cancellation made less than 24 hours in
                  advance, or in case of a no show. In the event that you
                  deliver prior to your class date, a refund will be issued with
                  a doctorʼs note. If a cancellation is made less than 24 hours
                  in advance, the non-refundable payment may be used as a credit
                  towards a postpartum service offered by Boston NAPS. The
                  Client shall not dispute the charges billed pursuant to the
                  mutually agreed upon schedule.
                </p>
                <p>
                  <strong>Inclement Weather or Instructor Illness</strong>:
                  Boston NAPS will contact all parties registered for a class if
                  the class has been canceled due to inclement weather or
                  instructor illness. In the event of a canceled class, Boston
                  NAPS will offer another date for the same missed class or
                  re-enroll you into the next scheduled class being offered.
                </p>
                <p>
                  <strong>Additional Policies and Information</strong>: We
                  highly recommend you schedule your prenatal class or workshop
                  prior to 36 weeks gestation to avoid missing you program due
                  to delivery. Boston NAPS is not responsible for any parking
                  fees incurred during a class. Information provided in all
                  classes taught by Boston NAPS, LLC is offered for educational
                  purposes only and should not be construed as medical advice.
                  Such information is not intended to be complete, nor to
                  replace the proper and thorough medical advice of your own
                  physician or health care provider. Attendees of group classes
                  and private classes disclaim any and all liability for any
                  injury or other damages which may be directly associated with
                  the communication or review of information contained in our
                  classes/workshops/programs.
                </p>
                <p>
                  <strong>DISCLOSURE. </strong>The Company is not affiliated in
                  any way with the Clientʼs physician,
                  obstetrician/gynecologist, surgeon or any other health care
                  providers or staff affiliated with such individuals. The
                  Company is also not affiliated in any way with any third party
                  service provider that may provide services that are arranged
                  by the Company. The Company does not make legal or medical
                  decisions for the Client and shall not be liable for any
                  consequences occurring as a result of the legal or medical
                  decisions made by the Client, the Clientʼs legal
                  representative or physician. Client hereby waives all claims
                  against Boston NAPS, LLC for any damages or consequences of
                  said legal or medical decisions.
                </p>
                <p>
                  <strong>LIMITATION OF SERVICES. </strong>Company will make
                  every good faith effort to provide the requested services to
                  Client according to the agreed upon terms. The Client agrees
                  to provide accurate and complete information to Boston NAPS
                  and the employees of Boston NAPS to ensure that appropriate
                  services are provided. Agreement to a schedule shall not
                  constitute a guarantee of service, and Company reserves the
                  right to cancel or suspend services with or without notice.
                  Although Company will make every effort to provide services,
                  in the rare instance that Company is unable to provide
                  scheduled services (whether this is due to employee
                  cancellation, illness, holiday, extreme weather, exigent
                  circumstances or an unanticipated and/or last-minute request
                  for nursing services), Company shall not be held liable for
                  failure to provide services, and Client releases Company of
                  any potential liability hereunder.
                </p>
                <p>
                  <strong>SERVICES DURING HOLIDAYS. </strong>By signing this
                  Agreement, Client acknowledges that pricing for services may
                  vary during holidays and other high-demand occasions and times
                  of the year. Company will notify Client promptly of any
                  proposed rate changes, and Client reserves the right to
                  decline services. Additionally, in accordance with the terms
                  of the above “Limitation of Services” section, Client
                  acknowledges that Company may be limited in its ability to
                  provide services during holidays and other high-demand time
                  periods, and releases Company from any potential liability
                  hereunder.
                </p>
                <p>
                  <strong>STATEMENT OF PATIENT PRIVACY RIGHTS. </strong>Company
                  will collect health information to ensure Client receives
                  quality health care advice. Client has the right to have
                  personal health care information kept confidential, and Boston
                  NAPS LLC will keep personal health care information
                  confidential. This means that only those who are legally
                  authorized to know, or who have a medical need to know, will
                  see Clientʼs personal information. All other information for
                  the purpose of improving the care Boston NAPSʼ provides will
                  be disclosed anonymously. Client has the right to refuse to
                  answer questions, and does not have to answer every question
                  to obtain services. Client has the right to review Clientʼs
                  personal health information. Company knows how important it is
                  to collect and maintain correct health information, and Client
                  reserves the right to ask Company to modify Clientʼs health
                  records, should Client notice a mistake or discrepancy in the
                  health records.
                </p>
                <p>
                  <strong>ADVANCE DIRECTIVE FOR HEALTHCARE. </strong>Unless an
                  advance directive is on file with Boston NAPS LLC, all efforts
                  will be made by the Nurse to sustain Clientʼs life in the
                  unlikely event of an emergency. It is Boston NAPS LLCʼs policy
                  to respect individual choice and to avoid discrimination based
                  on whether or not the Client has an advance directive.
                </p>
                <p>
                  <strong>RELEASE OF INFORMATION. </strong>Client hereby
                  consents to and authorizes Boston NAPS to disclose and release
                  information contained in Clientʼs clinical record to the
                  health care providers involved in Clientʼs care. At times,
                  information may be released for the purpose of utilization
                  review, professional standards review organizations,
                  regulatory review entities and any other organizations that
                  may/will assist Client to meet Clientʼs home care needs.
                  However, release of information to these organizations will
                  remain anonymous.
                </p>
                <p>
                  <strong>LIMITATION OF LIABILITY. </strong>Under no
                  circumstances shall the Company be liable or responsible for
                  any services provided by third parties. The Client shall
                  indemnify, defend, and hold harmless the Company and its
                  successors, officers, members, agents, independent contractors
                  and employees from any and all actions, causes of action,
                  claims, demands, cost liabilities, expenses and damages
                  (including attorneys fees) arising out of, or in connection
                  with, any services provided by any third- party service
                  provider that provides services that are arranged by the
                  Company pursuant to the provided services or in any way
                  connected with the services rendered under the terms of this
                  Agreement. The Company shall not be liable for any
                  consequences occurring as a result of the legal or medical
                  decisions made by the Client, the Clientʼs legal
                  representative or physician. The Client hereby waives all
                  claims against the Company, its members, agents, independent
                  contractors and employees for any damages or consequences of
                  said medical or legal decisions. In the event Client elects
                  home care by a third party referred to the Client by the
                  Company rather than care at a medical facility or surgical
                  center that is contrary to the medical advice of the Clientʼs
                  treating physician, the Company shall not be liable for any
                  damages or the consequences of this decision.
                </p>
                <p>
                  <strong>GOVERNING LAW; ENFORCEMENT COSTS. </strong>This
                  Agreement with be construed in accord with, and any dispute
                  arising from any breach or asserted breach of this Agreement
                  will be governed by, the laws of the Commonwealth of
                  Massachusetts. Neither party to this Agreement shall institute
                  a proceeding in any court or administrative agency to resolve
                  a dispute between the parties before that party has sought to
                  resolve the dispute through negotiations with the other party.
                  If a dispute is not resolved within three (3) weeks after a
                  demand for direct negotiation, the parties shall attempt to
                  resolve the dispute through mediation. If the parties do not
                  promptly agree on a mediator, either party may request the
                  appointment of a qualified mediator. If the Company is forced
                  to take legal action to enforce this Agreement or institute
                  collection efforts for any amounts due under this Agreement,
                  all fees and costs, including but not limited to, collection
                  fees, attorneyʼs fees, travel expenses, court costs and any
                  post-judgment collection fees, shall be the sole
                  responsibility of the Client.
                </p>
                <p>
                  <strong>INTEGRATION. </strong>This Agreement may be executed
                  in two (2) or more counterparts each of which shall be deemed
                  an original, but all of such taken together shall constitute
                  only one Agreement, superseding all prior understandings, oral
                  or written; and it is expressly understood and that this
                  Agreement does not obligate either party to enter into any
                  other or further agreements. A facsimile signature shall be
                  deemed legally binding.
                </p>
                <p>
                  <strong>NO THIRD-PARTY BENEFICIARIES. </strong>Nothing in this
                  Agreement will be construed to give any rights or benefits in
                  this Agreement to anyone other than the Company and the
                  Client. All duties and responsibilities undertaken pursuant to
                  this Agreement will be for the sole and exclusive benefit of
                  the Company and the Client, and not for the benefit of any
                  other party.
                </p>
                <p>
                  <strong>ENTIRE AGREEMENT. </strong>This Agreement and any
                  writing incorporated by reference herein constitute the
                  partiesʼ entire agreement. here are no restrictions, promises,
                  representations, warranties, covenants or understandings other
                  than those expressly set forth herein. This Agreement
                  supersedes all prior agreements between the parties and may be
                  modified or amended only by a writing signed by both parties.
                </p>
                <p>
                  <strong>AMENDMENT. </strong>This Agreement may be amended only
                  by a writing signed by the Client and by a duly authorized
                  representative of the Company.
                </p>
                <p>
                  <strong>SEVERABILITY. </strong>The invalidity or
                  unenforceability of any provision hereof (as determined by a
                  court or arbitrator of competent jurisdiction) shall in no way
                  affect the validity or enforceability of the remainder of this
                  Agreement or any other provision hereof.
                </p>
                <p>
                  <strong>MODIFICATION AND WAIVER. </strong>No oral
                  modifications shall be effective, and no delay or failure on
                  the part of either party to insist on compliance with any
                  provision hereof shall constitute a waiver of such partyʼs
                  right to enforce such provision. All waivers must be in
                  writing by a duly authorized representative of the party.
                </p>
                <p>
                  <strong>CONSTRUCTION. </strong>The headings and captions of
                  this Agreement are provided for convenience only and are
                  intended to have no effect in construing or interpreting this
                  Agreement. The language in all parts of this Agreement shall
                  be in all cases construed according to its fair meaning and
                  not strictly for or against either party. All pronouns and
                  words denoting gender shall be construed so as to refer to the
                  masculine, feminine, neuter, or singular form thereof as the
                  identity of the persons, entities and situation may require.
                </p>
                <p>
                  <strong>TERM. </strong>This Agreement shall commence on the
                  date hereof and shall terminate One (1) year after the birth
                  of the child named in this Agreement herein, or upon the end
                  of the third successive month of no services being booked by
                  the Client, whichever occurs first (the “term”).
                </p>
                <p>
                  <strong>NOTICES. </strong>All notices and other communications
                  hereunder will be in writing or by written telecommunication,
                  and will be deemed to have been duly given if delivered
                  personally or if mailed by certified mail, return receipt
                  requested or by written telecommunication to the relevant
                  address of the party, or to such address as the recipient of
                  such notice or communication will have specified to the other
                  party hereto in accordance with this section.
                </p>
              </div>
            </div>
          </div>
        </div>
      </Row>
    </div>
  );
};
