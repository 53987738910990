import React from "react";
import "./nursesSupport.scss";
import nurseSupportPng from "../../assets/nurseSupport.png";

const NursesSupport = () => (
  <div className="preLoginNursesSupportWrapper">
    <div className="preLoginNursesSupport">
      <img src={nurseSupportPng} alt="nurse support" />
      <div className="preLoginNursesSupportContent">
        <h3 className="heading3">
          Judgment-Free parenting support from{" "}
          <span>registered nurses and these professionals:</span>
        </h3>
        <ol>
          <li>
            <span>Pediatric Nurse Practitioner</span> for the health, wellness
            and development of your 👶🏽
          </li>
          <li>
            <span>Family Nurse Practitioner</span> for the health and wellness
            of your entire 👨‍👩‍👧‍👦
          </li>
          <li>
            <span>Certified Lactation Consultants</span> for all of your 🤱🏼,
            ⛽️, and 🍼 needs
          </li>
          <li>
            <span>Licensed Mental Health Counselor</span> for your adult 🧠
          </li>
          <li>
            <span>Occupational Therapist</span> for your child's 🧠 and
            developmental milestones
          </li>
          <li>
            <span>Pelvic Floor Physical Therapist</span> for your 🐱 and
            physical aches and ailments
          </li>
        </ol>
      </div>
    </div>
  </div>
);

export default NursesSupport;
