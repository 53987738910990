import { useEffect, useMemo, useState } from "react";
import { debounce } from "lodash";

const useDebounce = <s>(state: s, delay?: number) => {
  const [debounceValue, setDebounceValue] = useState<s>(state);
  const updateDebounce = useMemo(
    () => debounce((search) => setDebounceValue(search), delay || 500),
    [delay]
  );
  useEffect(() => {
    updateDebounce(state);
  }, [state, updateDebounce]);

  return debounceValue;
};

export default useDebounce;
