import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from '@reduxjs/toolkit'
import { getDataFromLocalStorage, getUserID } from "../../Service/getLocalStorage";
import { getMemberDataApi, getUserProfileEdit } from "../../Service/update_profile";

export const userData: any = createAsyncThunk("user/getUserdata", async () => {
    const user = getDataFromLocalStorage()
    let res: any = await getUserProfileEdit({ user_id: user?.id })
    return res?.data
})
export const getMemberPriffiled: any = createAsyncThunk("user/getPrefilled", async () => {
    const id = getUserID()
    let res: any = await getMemberDataApi({ user_id: id })
    if (res?.data?.success) return res?.data?.data
})
export const resendCodeInterval: any = createAsyncThunk("user/interval", async (_, { dispatch }) => {
    // const dispatch = useDispatch()
    var startTime = new Date().getTime();
    var interval = setInterval(function () {
        if (new Date().getTime() - startTime > 30050) {
            clearInterval(interval);
            return;
        }
        dispatch(verifyTimer())
    }, 1000);
})
const userSlice = createSlice({
    name: "user",
    initialState: {
        data: [],
        memberData: [],
        timer: 30,
        isLoggedIn: false,
    },
    reducers: {
        verifyTimer: (state) => {
            if (state.timer === 0 || state.timer < 0) {
                state.timer = 30
            }
            state.timer -= 1
        },
        isUserLoggedInAction(state, action: PayloadAction<boolean>) {
            state.isLoggedIn = action.payload
        },
    },
    extraReducers: {
        [userData.fulfilled]: (state, { payload }) => {
            state.data = payload;
        },
        [getMemberPriffiled.fulfilled]: (state, { payload }) => {
            state.memberData = payload;
        }
    }
})

export const isLoggedInSelector = (state: any) => state.userData.isLoggedIn;

export const { verifyTimer, isUserLoggedInAction } = userSlice.actions;
export default userSlice.reducer