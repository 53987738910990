import React from "react";
import { Modal } from "react-bootstrap";
import "./paymentModal.scss";
import usePaymentMethod from "../../../../Common/PaymentMethod/usePaymentMethod";
import { CircularProgress } from "@mui/material";
import AddCardForm from "./AddCardForm";
import {
  handleDate,
  scheme,
  yupValidation,
} from "../../../../Common/PaymentMethod/StripeForm/helpers";
import * as asyncState from "../../../../Utils/asyncState";
import { toast } from "react-toastify";
import { membershipUpgrade } from "../../../../Service/update_profile";
import { getUserID } from "../../../../Service/getLocalStorage";
import { usePostHog } from "posthog-js/react";

export interface PaymentModalProps {
  isVisible: boolean;
  closeModal: () => void;
  membershipId: number;
  successCB: (data: any) => void;
}

interface Errors {
  [key: string]: number;
}

const PaymentModal = ({
  isVisible,
  closeModal,
  membershipId,
  successCB,
}: PaymentModalProps) => {
  const posthog = usePostHog();

  const { paymentMethods, paymentsMethodsRequest } = usePaymentMethod();
  const defaultPaymentMethod =
    paymentMethods?.data?.customer?.invoice_settings?.default_payment_method;
  const primaryCard = React.useMemo(
    () =>
      paymentMethods.data?.methods?.data.find(
        ({ id }: { id: string }) => defaultPaymentMethod === id
      ),
    [defaultPaymentMethod, paymentMethods.data?.methods?.data]
  );

  const [
    changeTariffWithNewPaymentMethod,
    setChangeTariffWithNewPaymentMethod,
  ] = React.useState(asyncState.initState);
  const [
    changeTariffWithOldPaymentMethod,
    setChangeTariffWithOldPaymentMethod,
  ] = React.useState(asyncState.initState);

  const [fields, setFields] = React.useState({
    first_name: "",
    last_name: "",
    exp: "",
    cvv: "",
    zip_code: "",
    cardNumber: "",
  });

  const [errors, setErrors] = React.useState<Errors>({});

  const handleChange = (evt: any) => {
    setFields((v) => ({ ...v, [evt.target.name]: evt.target.value }));
  };

  const handleNumber = (max: number) => (evt: any) => {
    setFields((v) => ({
      ...v,
      [evt.target.name]: evt.target.value.slice(0, max),
    }));
  };

  const handleExpirationDate = (evt: any) => {
    setFields((v) => ({
      ...v,
      [evt.target.name]: handleDate(evt.target.value),
    }));
  };

  const submitNewMethod = async () => {
    const { valid, errors } = await yupValidation(fields, scheme);

    setErrors(errors);

    if (valid) {
      const { first_name, cvv, last_name, zip_code, exp, cardNumber } = fields;
      const [expMonth, expYear] = [exp.slice(0, 2), exp.slice(2)];

      const params = {
        card: cardNumber,
        cardHolder: `${first_name} ${last_name}`,
        expiration: `${expMonth}/${expYear}`,
        cvv,
        zip: zip_code,
        user_id: getUserID(),
        membership_id: membershipId,
      };
      try {
        setChangeTariffWithNewPaymentMethod(asyncState.pendingState);
        const res: any = await membershipUpgrade(params);

        if (res.status !== 200) {
          throw { message: res?.data?.message?.join(" ") };
        }

        setChangeTariffWithNewPaymentMethod(asyncState.successState);
        closeModal();
        successCB(res?.data);
        paymentsMethodsRequest();

        posthog.capture("user-changed-membership", {
          $set: {
            free_membership: false,
            monthly_membership: membershipId === 1,
            annual_membership: membershipId === 2,
          }
        });
      } catch (err: { message: string } | any) {
        toast.error(err?.message);
        setChangeTariffWithNewPaymentMethod({
          ...asyncState.failedState,
          failedMessage: err?.message,
        });
      }
    }
  };

  const submitOldMethod = async () => {
    try {
      setChangeTariffWithOldPaymentMethod(asyncState.pendingState);
      const res: any = await membershipUpgrade({
        user_id: getUserID(),
        membership_id: membershipId,
      });

      if (res.status !== 200) {
        throw { message: res?.data?.message?.join(" ") };
      }

      setChangeTariffWithOldPaymentMethod(asyncState.successState);
      successCB({
        trialEnd: res?.data?.data?.trial_end * 1000,
        membershipId: membershipId,
      });
      closeModal();
      paymentsMethodsRequest();
      posthog.capture("user-changed-membership", {
        $set: {
          free_membership: false,
          monthly_membership: membershipId === 1,
          annual_membership: membershipId === 2,
        }
      });
    } catch (err: { message: string } | any) {
      toast.error(err?.message);
      setChangeTariffWithOldPaymentMethod({
        ...asyncState.failedState,
        failedMessage: err?.message,
      });
    }
  };

  React.useEffect(() => {
    document
      .querySelector("html")
      ?.classList?.[isVisible ? "add" : "remove"](
        "paymentModalPopUpOverflowBody"
      );

    return () =>
      document
        .querySelector("html")
        ?.classList?.remove("paymentModalPopUpOverflowBody");
  }, [isVisible]);

  React.useEffect(() => {
    if (isVisible) {
      setChangeTariffWithOldPaymentMethod(asyncState.initState);
      setChangeTariffWithNewPaymentMethod(asyncState.initState);
      setFields({
        first_name: "",
        last_name: "",
        exp: "",
        cvv: "",
        zip_code: "",
        cardNumber: "",
      });
      setErrors({});
    }
  }, [isVisible]);

  return (
    <Modal
      show={isVisible}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="paymentModalPopUpWindow"
    >
      <Modal.Body className="paymentModalPopUp">
        <button className="paymentModalPopUpCross" onClick={closeModal}>
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.5307 13.2694C16.1494 13.888 16.1494 14.912 15.5307 15.5307C15.2107 15.8507 14.8054 16 14.4 16C13.9947 16 13.5894 15.8507 13.2694 15.5307L8.00004 10.2614L2.7307 15.5307C2.4107 15.8507 2.00537 16 1.60004 16C1.1947 16 0.789371 15.8507 0.469371 15.5307C-0.149296 14.912 -0.149296 13.888 0.469371 13.2694L5.7387 8.00004L0.469371 2.7307C-0.149296 2.11204 -0.149296 1.08804 0.469371 0.469371C1.08804 -0.149296 2.11204 -0.149296 2.7307 0.469371L8.00004 5.7387L13.2694 0.469371C13.888 -0.149296 14.912 -0.149296 15.5307 0.469371C16.1494 1.08804 16.1494 2.11204 15.5307 2.7307L10.2614 8.00004L15.5307 13.2694V13.2694Z"
              fill="#30426D"
            />
          </svg>
        </button>
        <h3 className="paymentModalPopUpTitle">
          Your primary payment method is
        </h3>
        <div className="paymentModalPopUpCardInfo">
          Credit Card:{" "}
          {paymentMethods.pending && <CircularProgress size={12} />}
          {!paymentMethods.pending && primaryCard && (
            <>
              <span className="paymentModalPopUpCardInfo--strong">
                {primaryCard?.card?.brand}
              </span>{" "}
              ending with ...
              <span className="paymentModalPopUpCardInfo--strong">
                {primaryCard?.card?.last4}
              </span>
            </>
          )}
          {!paymentMethods.pending && !primaryCard && <>missing</>}
        </div>
        <h3 className="paymentModalPopUpTitle paymentModalPopUpTitle--topSpace">
          Would you like to update this information?
        </h3>
        <AddCardForm
          fields={fields}
          errors={errors}
          handleChange={handleChange}
          handleNumber={handleNumber}
          handleExpirationDate={handleExpirationDate}
        />

        <div className="paymentModalPopUpButtons">
          <button
            className="btn primary-blue-btn paymentModalPopUpButton"
            onClick={submitNewMethod}
            disabled={changeTariffWithNewPaymentMethod.pending}
          >
            {changeTariffWithNewPaymentMethod.pending ? (
              <CircularProgress size={30} />
            ) : (
              "Update Payment Information"
            )}
          </button>
          <button
            className="btn primary-blue-btn paymentModalPopUpButton paymentModalPopUpButton--skip"
            onClick={submitOldMethod}
            disabled={changeTariffWithOldPaymentMethod.pending || !primaryCard}
          >
            {changeTariffWithOldPaymentMethod.pending ? (
              <CircularProgress size={30} />
            ) : (
              "Use Saved Payment Method"
            )}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default PaymentModal;
