import React from "react";
import * as asyncState from "../../Utils/asyncState";
import "./courses.scss";
import useModalWindowControls from "../../Hooks/useModal";
import { debounce } from "lodash";

interface CoursesFilter {
  stage: [];
  category: [];
}

export interface CoursesState extends asyncState.AsyncState {
  data:
    | {
        id: number;
        favourite: boolean;
        like_course: { user_id: number }[];
        like_count: number
        like: boolean
      }[]
    | [];
  search: string;
  filter: {
    stage: [];
    category: [];
  };
}

const useCourses = ({
  userID,
  method,
}: {
  userID: string;
  method: (data: any) => Promise<any>;
}) => {
  const [courses, setCourses] = React.useState<CoursesState>({
    data: [],
    search: "",
    filter: {
      stage: [],
      category: [],
    },
    ...asyncState.initState,
  });

  const coursesFilterModal = useModalWindowControls();

  const getCourses: any = React.useCallback(
    async (search: string, filter: CoursesFilter) => {
      setCourses((courses: any) => ({
        ...courses,
        ...asyncState.pendingState,
      }));

      try {
        const res: any = await method({
          user_id: userID,
          search,
          category_id: filter.category.join(","),
          stages_id: filter.stage.join(","),
        });
        setCourses((courses: any) => ({
          ...courses,
          data: res.data.data,
          ...asyncState.successState,
        }));
      } catch (err: any) {
        setCourses((courses: any) => ({
          ...courses,
          data: [],
          ...asyncState.failedState,
          errorMessage: err.message,
        }));
      }
    },
    [userID, method]
  );

  const filterCourses = ({
    stage,
    category,
    search,
  }: {
    stage?: [];
    category?: [];
    search?: string;
  }) => {
    setCourses((courses: any) => ({
      ...courses,
      filter: {
        stage: stage || courses.filter.stage,
        category: category || courses.filter.category,
      },
      search: search ?? courses.search,
    }));
  };

  const resetCourses = React.useCallback(() => {
    setCourses((courses: any) => ({
      ...courses,
      filter: {
        stage: [],
        category: [],
      },
      search: "",
    }));
  }, []);

  const searchRequestDebounce = React.useMemo(
    () => debounce(getCourses, 200),
    [getCourses]
  );

  React.useEffect(
    () => searchRequestDebounce(courses.search, courses.filter),
    [courses.search, courses.filter, searchRequestDebounce]
  );

  return {
    coursesFilterModal,
    resetCourses,
    filterCourses,
    setCourses,
    getCourses,
    courses,
  };
};

export default useCourses;
