import React from "react";
import "./hero.scss";
import NapsLogoSvg from "./NapsLogo";

const Hero = ({ scrollToMembership }: { scrollToMembership: () => void }) => (
  <div className="preLoginHero">
    <NapsLogoSvg className="preLoginHeroLogo" />
    <h1 className="heading">
      We are breaking <br /> the mold of traditional parenting support.{" "}
    </h1>
    <p>
      Because parenting <span>is f!cking hard</span>
    </p>
    <div className="preLoginHeroMobileGrow" />
    <button
      className="w-auto btn-small teal-btn-small"
      onClick={scrollToMembership}
    >
      Join the Club
    </button>
  </div>
);

export default Hero;
