import React from 'react';
import { Container, Row } from 'react-bootstrap';
import "./DayTimeOvernight.scss";
import featured_day_night from '../../Assets/img/service/Day-&-night-feature.jpg';

import welcomeHome from "../../Assets/img/day-night-care/welcomeHome.png";
import dayTimeCare from "../../Assets/img/day-night-care/dayTimeCare.png";
import nightTimeCare from "../../Assets/img/day-night-care/nightTimeCare.png";

import { Link, useNavigate } from 'react-router-dom';
import LockScreen from '../../Common/LockScreen/LockScreen';
import StreetCred from '../../Common/streetCred/streetCred';
import { CALL30MIN_URL, DAY_OF_DISCHARGE_URL } from '../../Utils/ServiceUrl';
import { HeroBannerHorizontal}  from '../../Common/HeroBanner';
import hero from '../../Assets/img/service/dayNight.png';

const DayTimeOvernight = () => {
  let testimonialData = [
    {
      name: "PAUL M.",
      data: "I want to highlight the incredible service, character, and personality of everyone who came into our house. Every nurse was driven, professional, confident, well educated, in full control, and knowledgeable about all aspects of babies. It gave us such peace of mind. It also gave us a chance to ask those dumb questions we had stored up to a real nurse (like when our baby had delayed drop-off of the umbilical cord and it was freaking us out!)."
    },
    {
      name: "Carly W.",
      data: "This service absolutely saved my mental health from spiraling - a true life saver! Lauren entered our home and was immediately met with a new mom crying - she instantly addressed the issue, comforted me and let me know that this was normal and OK to be feeling this way. I am so thankful I scheduled this service and will absolutely recommend to anyone I know having their first baby or adding another child to their family. I had a list of questions to ask about me as well as my 8 day old baby, and Lauren answered them fully and in a way that made me feel empowered about the plan moving forward. My wife and I learned so much in the 4 hours Lauren spent with us, which made us feel so much more confident as new parents. Wish I could give 10 stars!"
    }
  ]
  const Navigate = useNavigate()
  return (
    <div className='daytime_overnight'>
      <Container fluid>
        <div className="py-3">
          <HeroBannerHorizontal
            hero={hero}
            width={90}
            title={
              <>
                <div>Daytime and Overnight</div>
                <div className="strong" style={{color: '#F5CF70'}}>
                Nursing Care
                </div>
              </>
            }
          />
        </div>
        <Row>
          <div className='col-lg-12 col-md-12 col-sm-12'>
            <Container>
              <Row className='justify-content-center my-5 about-day-time'>
                <div className='col-lg-12 col-md-12 col-sm-12'>
                  <h3>It doesn’t matter if you are…</h3>
                  <ol>
                    <li>A <strong> first time parent </strong> with a few weeks of sleep deprivation under your belt.</li>
                    <li>An <strong> experienced parent </strong> hoping that this maternity leave allows for a bit more rest.</li>
                    <li>Wondering how to make your <strong> days or nights more manageable </strong> with your little one
                      Whatever your situation, we can help. We only staff daytime and overnight care with Registered Nurses. We believe in providing the highest quality of care possible from medical professionals. You can feel confident leaving your newborn with a qualified expert with real experience.<br />

                      Read below for details but it all starts with a <Link to={CALL30MIN_URL}>phone call</Link> to learn how we can support you and your family! <br />
                    </li>
                  </ol>
                </div>
                {/* <div className='col-lg-6 col-md-6 about-intro-txt '>
                    <h2 className='text-start lh-base mb-1 pb-1'>No matter what stage of parenting you’re at, we’ve designed our <span className='strong'> Nurture By NAPS<sup>TM</sup> Membership </span> to give you the tools you need <span className='pink cursive'> to trust your gut.</span> </h2>
                    <h2 className='text-start lh-base mb-1 pb-1'>Become part of our community <span className='strong'> & instantly receive access to all of our tools.</span></h2>
                </div> */}
              </Row>
            </Container>
          </div>
        </Row>

        <Row className='pt-0 pb-5'>
          <div className='col-lg-12 col-md-12 my-3'>
            <Container fluid className='registered-nurse-main'>
              <Row className='justify-content-center'>
                <div className='col-lg-10 col-md-10'>
                  <Container>
                    <Row className='justify-content-center flex-column flex-md-row my-5'>
                      <div className='col-lg-5 col-md-5 col-sm-12 registered-nurse-imgsection '>
                        <img src={featured_day_night} className='img-fluid pe-4' />
                      </div>
                      <div className='col-lg-5 col-md-5 col-sm-12 py-5 registered-nurse-info'>
                        <div className='d-flex flex-column'>
                          <div>
                            <h3 className='text-center text-md-start'>
                              Why a NAPS Nurse?
                            </h3>
                          </div>
                          <div>
                            <p className='text-center text-md-start'>We’ve done this with so much love for over 11 years! All of our 35+ RNs are educated and trained in maternal-child health. Further, our team is provided extensive NAPS training, on-site shadowing, and continued education lead by senior nurses with specialties that include: Certification in Perinatal Mental Health, Certification in Oral Rehab Therapy, International Board Certified Lactation Consultant (IBCLC) education, pediatric dietitian, holistic sleep training programming, etc. All nurses are certified in BLS (Basic Life Support - CPR, choking, and first aid).  Our visits may end in the morning but <span className='strong'>our nurses ensure you are supported throughout your day </span> with connections to finding community, mental health support, lactation support, and more. </p>
                          </div>
                        </div>

                      </div>
                    </Row>
                  </Container>
                </div>
              </Row>
            </Container>
          </div>
        </Row>

        <Row className='pale-bg offering-main'>
          <div className='col-lg-12 col-md-12 col-sm-12'>
            <Container>
              <Row className="justify-content-center my-md-5 my-3 stage-offering-header">
                <div className='col-lg-12 col-md-12 col-sm-12'>
                  <div className="section-header text-center">
                    <h2 className="home-section-title py-0">Our<span className="strong pink"> Offerings</span></h2>
                  </div>
                </div>
                <div className="col-lg-8 col-md-8 mt-2">
                  <h3 className="text-center stage-description">Get the most out of our team of <span className='strong'>experienced nurses </span> with these services.</h3>
                </div>
              </Row>
            </Container>

            <Container className='offerings'>
              <div className="row my-3 my-lg-3 py-3 py-lg-5 col-reverse offer-row position-relative justify-content-center">
                <div className="col-md-5 col-sm-12 order-1 order-md-0">
                  <div className="offering-block-content">
                    <h3 className="lh-1 mb-0 w-100 d-flex align-items-end justify-contnet-start">
                      <p className="d-md-inline-block d-block">
                        <span className="number pink">1. </span>
                        <span className="content">Welcome Home</span>
                        <span className="cursive pink bestsellerv ms-3 Bestseller keep-all">Bestseller!</span>
                      </p>

                    </h3>
                    <p className="lh-md pb-1 pb-md-2 pe-0 pe-lg-4 ">Whether you welcomed your first baby, you’re an experienced parent, or you’re now just being discharged home from a NICU stay - <span className='strong'>all parents deserve better care than what is offered to you </span> through our current healthcare system.  This 4 hour appointment focuses on your new baby and family’s overall health and wellness.</p>
                    <button className="w-auto btn-small pink-btn-small" onClick={() => Navigate(DAY_OF_DISCHARGE_URL)}>Learn More</button>
                  </div>
                </div>
                <div className="col-md-5 col-sm-12 order-0 order-md-1 position-relative">
                  <div className="offering-block-content pe-0 pe-lg-5">
                    <img src={welcomeHome} className='img-fluid' />
                  </div>
                </div>
              </div>

              <div className="row my-3 my-lg-3 py-3 py-lg-5 offer-row position-relative justify-content-center">
                <div className="col-md-5 position-relative">
                  <div className="offering-block-content pe-0 pe-lg-4">
                    <img src={dayTimeCare} className='img-fluid' />
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="offering-block-content">
                    <h3 className="lh-1 mb-0 w-100 d-flex align-items-end justify-contnet-start">
                      <p className="d-md-inline-block d-block">
                        <span className="number orange">2. </span>
                        <span className="content"> Daytime Care</span>
                      </p>
                    </h3>
                    <p className="dark-grey lh-md">Daytime shifts are a great opportunity to have your questions answered or get education from an experienced maternal-child health nurse, or simply to comfortably leave your baby with a trusted professional to get some rest, run errands, and have some alone time.</p>
                    <button className="w-auto btn-small yellow-btn-small" onClick={() => Navigate(CALL30MIN_URL)}>Learn More</button>
                  </div>
                </div>
              </div>


              <div className="row my-3 my-lg-3 py-3 py-lg-5 col-reverse offer-row position-relative justify-content-center">
                <div className="col-md-5 order-1 order-md-0">
                  <div className="offering-block-content">
                    <h3 className="lh-1 mb-0 consultations w-100">
                      <p className="d-md-inline-block d-block">
                        <span className="number lavender">3. </span>
                        <span className="content">Overnight Care</span>
                      </p>
                    </h3>
                    <p className="dark-grey lh-md"> The main purpose of night nursing care is to promote good eating and good sleep - whatever that looks like for your family. While our short-term goal is to provide parents with rest, we also focus on helping families improve sleep by creating a plan for  your baby to sleep longer stretches at night. </p>
                    <button className="w-auto btn-small purple-btn-small" onClick={() => Navigate(CALL30MIN_URL)}>Learn More</button>
                  </div>
                </div>
                <div className="col-md-5 order-0 order-md-1 position-relative">
                  <div className="offering-block-content pe-0 pe-lg-4">
                    <img src={nightTimeCare} className='img-fluid' />
                  </div>
                </div>
              </div>
            </Container>


            {/* </Container> */}
          </div>
        </Row>


        <LockScreen />
        {/* <PodcastUI /> */}

        {/* any testimonial ui */}
        <StreetCred data={testimonialData} />
        {/* any question ui */}

        {/* any testimonial ui */}
        {/* <AnyQuestion /> */}
        {/* any question ui end*/}
      </Container>
    </div>
  )
}

export default DayTimeOvernight;