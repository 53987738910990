import React, { useEffect, useMemo, useState } from 'react'
import Steppers from '../../../Common/Stepper/Stepper';
import AppoinmentDetail from './Steps/AppointmentDetail';
import UserInfo from './Steps/UserInfo';
import ChooseDate from './Steps/ChooseDate';
import Payment from './Steps/Payment';
import { bookConsultApi, getAddonClassApi, getVirtualClassDateApi } from '../../../Service/Cousult.service';
import { getDataFromLocalStorage } from '../../../Service/getLocalStorage';
import MultipleSlots from './Steps/MultipleSlots';
import CongratsModel2 from '../../../Common/CongratsPopup2/CongratsPopup2';
import { GIFTCARD } from '../../../Routes/RouteConstent';
import dayjs from 'dayjs';
import Popup from '../../Membership/Popup';
import isMebershipAndActiveCheck from '../../../Utils/membership_check';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { useMembershipData } from '../../../Utils/getUserData/getUserData';
import { useQuery } from '../../../Hooks/useQuery';
import { capturePurchasedServiceEvent, useCaptureServiceEvent } from '../../../Service/serviceData';


const StepsForVirtualClass = ({ state }: any) => {

    const [activeStep, setActiveStep] = useState(0)
    const [hostData, setHostData] = useState<any>([])
    const [classData, setClassData] = useState<any>([])
    const [dateVisible, setDateVisible] = useState<Array<number>>([])
    const [date, setDate] = useState<any>({})
    const [serviceId, setServiceId] = useState<any>()
    const [formField, setFormField] = useState<any>([])
    const [classTime, setClassTime] = useState<any>([])
    const [userInfoVal, setUserInfoVal] = useState<any>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [loadingWash, setLoadingWash] = useState<boolean>(false)
    const [modelOpen, setModelOpen] = useState<boolean>(false)
    const [term_condition, setPolicy] = useState<any>(false)
    let [price, setPrice] = useState<any>("")
    const [slots, setSlots] = useState<any>({})
    const [priceArry, setPriceArry] = useState<any>([])
    const [addOn, setAddOn] = React.useState<any>([])

    const [hostObj, setHostObj] = useState<any>({})

    const [objName, setObjName] = useState<any>({})
    const [serviceName, setServiceName] = useState<any>({})
    const [priceDisplay, setPriceDisplay] = useState<any>({
        price: price,
        actualPrice: price
    })

    const [selectClasses, setSelectClasses] = useState<any>([])
    const [selectSlots, setSelectSlots] = useState<any>([])
    let steps = [1, 2, 3, 4, 5]

    const serviceParam = useQuery().get('service') || state?.param;

    const Navigate = useNavigate()
    const dispatch = useDispatch()

    const isCheck = (res: any) => {
        isMebershipAndActiveCheck(res, Navigate, toast, dispatch)
    }

    const stepNames = useMemo(
        () => ['date-step', 'multiple-slots-step', 'user-step', 'details-step', 'payment-step'],
        []
    );
    useCaptureServiceEvent(activeStep, stepNames);

    function getStepContent(step: number) {
        switch (step) {
            case 0:
                return (
                    <ChooseDate
                        hostObj={hostObj}
                        objName={objName}
                        host={hostData}
                        data={classData}
                        handleNext={handleNext}
                        dateVisible={dateVisible}
                        loadingWash={loadingWash}
                        setDateVisible={setDateVisible}
                        value={date}
                        setValue={setDate}
                        price={price}
                        setPrice={setPrice}
                        setObjName={setObjName}
                        slots={slots}
                        setSlots={setSlots}
                        allData={serviceName}
                        setAddOn={setAddOn}
                        setSelectClasses={setSelectClasses}
                        setSelectSlots={setSelectSlots}
                        setClassTime={setClassTime}
                        priceArry={priceArry}
                        setPriceArry={setPriceArry}
                        setPriceDisplay={setPriceDisplay}
                    />
                );
            case 1:
                return (
                    <MultipleSlots
                        objName={objName}
                        hostObj={hostObj}
                        data={classData}
                        handleNext={handleNext}
                        dateVisible={dateVisible}
                        setDateVisible={setDateVisible}
                        value={date}
                        setValue={setDate}
                        price={price}
                        slots={slots}
                        setSlots={setSlots}
                        priceArry={priceArry}
                        setPriceArry={setPriceArry}
                        addOn={addOn}
                        setAddOn={setAddOn}
                        priceDisplay={priceDisplay}
                        setPriceDisplay={setPriceDisplay}
                        classTime={classTime}
                        setClassTime={setClassTime}
                        selectClasses={selectClasses}
                        setSelectClasses={setSelectClasses}
                        selectSlots={selectSlots}
                        setSelectSlots={setSelectSlots}
                    />
                )
            case 2:
                return (
                    <UserInfo
                        handleNext={handleNext}
                        value={userInfoVal}
                        setValue={setUserInfoVal}
                        handlePrev={handlePrev}
                        setPolicy={setPolicy}
                        term_condition={term_condition}
                        field={formField}
                    />
                );
            case 3:
                return (
                    <AppoinmentDetail
                        service_id={serviceParam.slice(8)}
                        price={price}
                        serviceName={serviceName}
                        hostObj={hostObj}
                        data={date}
                        setData={setDate}
                        handleSubmit={buyConsultHandler}
                        handlePrev={handlePrev}
                        handleNext={handleNext}
                        loading={loading}
                        setStep={setActiveStep}
                        objName={objName}
                        addOn={addOn}
                        setAddOn={setAddOn}
                        setDateVisible={setDateVisible}
                        setSlots={setSlots}
                        setSelectClasses={setSelectClasses}
                        setSelectSlots={setSelectSlots}
                        setClassTime={setClassTime}
                        setPrice={setPrice}
                        priceDisplay={priceDisplay}
                        setPriceDisplay={setPriceDisplay}
                        setPriceArry={setPriceArry}
                    />
                )
            case 4:
                return (
                    <Payment
                        handleNext={buyConsultHandler}
                    />
                )
            default:
                return "Unknown step";
        }
    }

    const [userMember] = useMembershipData()

    useEffect(() => {
        if (userMember) {
            setUserInfoVal({
                ...userInfoVal,
                ["due_date"]: userMember?.due_date,
                ["child_name"]: userMember?.child_name,
                ["is_your_first_baby"]: userMember?.your_first_baby,
                ["partner_support_person_name"]: userMember?.partner_name,
                ["partner_support_person_email_address"]: userMember?.partner_email
            })
        }
    }, [userMember]);

    const handleNext = () => {
        setActiveStep(activeStep + 1)
    }
    const handlePrev = () => {
        setActiveStep(activeStep - 1)
    }
    const getClassData = async () => {
        try {
            let prefix = {
                service_prefix: serviceParam
            }
            setLoadingWash(true)
            let res: any = await getVirtualClassDateApi(serviceParam, prefix)
            isCheck(res)
            if (res?.data?.success) {
                setHostData(res?.data?.data)
                setPrice(res?.data?.data[0]?.price)
                setServiceId(res.data.service_id)
                setHostObj(res?.data?.data[0])
                setServiceName(res?.data)
                setLoadingWash(false)
            }
        } catch (err) {
            console.log(err)
            setLoadingWash(false)
        }
    }


    const getAddonClass = async () => {
        try {
            let res: any = await getAddonClassApi({ service_prefix: serviceParam })
            isCheck(res)
            if (res?.data?.success) {
                setClassData(res?.data?.data)
            }
        } catch (err) {
            console.log(err)
        }
    }
    useEffect(() => {
        getAddonClass()
        getClassData()
    }, [])
    useEffect(() => {
        setPrice(date?.total_ammount)
    }, [])
    useEffect(() => {
        setPriceDisplay({
            price: price,
            actualPrice: price
        })
    }, [price])

    const buyConsultHandler = async (data: any = null) => {
      try {
        setLoading(true);
        const localData = getDataFromLocalStorage();
        userInfoVal.due_date = dayjs(userInfoVal.due_date).format("YYYY-MM-DD");

        let param = {
          ...date,
          ...userInfoVal,
          email: localData.email,
          service_id: serviceId,
          service_name: serviceParam,
          first_name: localData.first_name,
          last_name: localData.last_name,
          user_id: localData.id,
          phone: localData?.phone,
          paymentMethod: data,
        };

        let res: any = await bookConsultApi(param);

        if (res?.data?.success) {
          setModelOpen(true);
          setLoading(false);
          capturePurchasedServiceEvent(serviceParam);
        } else {
          let message = res?.data?.message;
          let allMsg = "";
          for (let i = 0; i < message.length; i++) {
            allMsg += message[i];
          }

          toast.error(allMsg);
          setLoading(false);

          return { ...res, params: param };
        }
      } catch (error: any) {
        toast.error(error.message);
        setLoading(false);
      }
    };

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            //@ts-ignore
            behavior: 'instant',
        });
    }, [activeStep])
    const popUpCallback = () => {
        getAddonClass()
        getClassData()
        setActiveStep(0)
    }
    return (
        <div>
            <CongratsModel2
                open={modelOpen}
                setOpen={setModelOpen}
                btnText="Send gift card to your friend"
                Page={GIFTCARD}
                callBack={() => popUpCallback()}
                text={'All information about your upcoming appointment has been sent to your email.<br>In the meantime, feel free to check out our other offerings, or if you have a friend who you think would be interested in the same booking as you, tell them using the button below!'} title="Thank you for booking with NAPS!" />

            <Steppers steps={steps} getStepContent={getStepContent}
                activeStep={activeStep} setActiveStep={setActiveStep}
                handleNext={handleNext}
            />

        </div>
    )
}

export default StepsForVirtualClass