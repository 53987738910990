import * as React from "react";
import { Container, Row } from "react-bootstrap";
import contact_banner from "../../Assets/img/Contact-banner.jpg";
import "./Contact.scss";
import location_icon from "../../Assets/img/location-map.svg";
import email_icon from "../../Assets/img/contact-email.svg";
import phone_icon from "../../Assets/img/icons/phone-icon.svg";

import { contactUs } from "../../Service/contact";
import { toast } from "react-toastify";
import { getUserID } from "../../Service/getLocalStorage";
import { CircularProgress } from "@mui/material";
import CongratsModel from "../../Common/CongratsPopoup/congratsModel";
import { emailRegex, phoneRegex } from "../../Utils/constant";
import { GetPrefilledData } from "../../Utils/getUserData/getUserData";
import isMebershipAndActiveCheck from "../../Utils/membership_check";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { HeroBanner } from "../../Common/HeroBanner";

const Contact = () => {
  return (
    <Container fluid className="contact-container">
      <div className="py-3">
        <HeroBanner hero={contact_banner} />
      </div>

      <Row no-gutter className="pale-bg py-4">
        <div className="col-lg-12 col-md-12 contact-discription-section">
          <div className="container">
            <Row className="justify-content-center">
              <div className="col-lg-10 col-md-10">
                <h3 className="text-center contact-section-title my-3">
                  Contact <span className="strong red">Us</span>
                </h3>
                <div className="px-0 px-md-5 contact_description">
                  <p className="text-center">
                    We’re not just an amazing website full of information- we
                    have a team ready to support and answer your questions along
                    the way! If you have any questions about the services we
                    offer, services you want to see, ways you want to work with
                    us, or, especially, if you need support but don’t know where
                    to start, please contact us. A member of our team will get
                    back to you!
                  </p>
                </div>
              </div>
            </Row>
          </div>
        </div>
      </Row>

      <Row className="contact-map-info">
        <div className="col-lg-12 col-md-12">
          <div className="bg-container">
            <Container>
              <Row className="py-5">
                <div className="col-lg-6 col-md-6">
                  <div className="map-container me-md-2 me-lg-4 me-0">
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2949.2483659551326!2d-71.05126642335719!3d42.33722787119507!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e37a89ee9beaab%3A0x828603f1a3ea0269!2s395%20W%20Broadway%2C%20Boston%2C%20MA%2002127%2C%20USA!5e0!3m2!1sen!2sin!4v1669795048452!5m2!1sen!2sin"
                      width="100%"
                      height="450"
                      style={{ border: 0 }}
                    ></iframe>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div
                    className="d-flex flex-column justify-content-center office-info ms-md-2 ms-lg-5 me-lg-5
                      pe-lg-5 pe-0 ms-0"
                  >
                    <div>
                      <h3>Office</h3>{" "}
                    </div>
                    <div className="d-flex align-items-start mb-3">
                      <div className="icon me-3">
                        <img src={location_icon} />
                      </div>
                      <div className="location">
                        395 West Broadway Boston, MA 02127
                      </div>
                    </div>
                    <div className="d-flex align-items-start mb-3">
                      <div className="icon me-3">
                        {" "}
                        <img src={email_icon} />
                      </div>
                      <div className="location">hello@nurturebynaps.com</div>
                    </div>
                    <div className="d-flex align-items-start">
                      <div className="icon me-3">
                        {" "}
                        <img src={phone_icon} />
                      </div>
                      <div className="phone">(857) 496-5095</div>
                    </div>
                  </div>
                </div>
              </Row>
            </Container>
          </div>
        </div>
      </Row>
      <Row className="contact-form my-4">
        <div className="col-lg-12 col-md-12">
          <div className="container">
            <Row className="justify-content-center">
              <div className="col-lg-8 col-md-8">
                <ContactUSForm />
              </div>
            </Row>
          </div>
        </div>
      </Row>
    </Container>
  );
};

export const ContactUSForm = () => {
  let iData = {
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    company_name: "",
    question: "",
  };
  interface Idata {
    first_name: string;
    last_name: string;
    email: string;
    phone: string;
    company_name: string;
    question: string;
  }
  const [data, setData] = React.useState<Idata>(iData);
  const [error, setError] = React.useState<any>(iData);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [user_data] = GetPrefilledData();

  const Navigate = useNavigate();
  const dispatch = useDispatch();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement> | any) => {
    let name = e.target.name;
    let value = e.target.value;
    if (name === "email") {
      if (!emailRegex.test(value)) {
        setError({
          ...error,
          ["email"]: "Please enter valid email",
        });
      } else {
        setError({
          ...error,
          ["email"]: "",
        });
      }
    }
    if (name === "phone") {
      if (!phoneRegex.test(value)) {
        setError({
          ...error,
          ["phone"]: "Please enter valid phone number",
        });
      } else {
        setError({
          ...error,
          ["phone"]: "",
        });
      }
    }
    if (name === "first_name" || name === "last_name" || name === "question") {
      if (error[name]) {
        setError({
          ...error,
          [name]: "",
        });
      }
    }

    setData({
      ...data,
      [name]: value,
    });
  };
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();

      if (
        !data.first_name ||
        !data.last_name ||
        !data.email ||
        (data.email && !emailRegex.test(data.email)) ||
        !data.phone ||
        (data.phone && !phoneRegex.test(data.phone)) ||
        !data.question
      ) {
        let errors: any = {};
        if (!data.first_name) {
          errors.first_name = "Please enter your first name";
        }
        if (!data.last_name) {
          errors.last_name = "Please enter your last name";
        }
        if (!data.email) {
          errors.email = "Please enter your email";
        }
        if (!data.phone) {
          errors.phone = "Please enter your phone number";
        }
        if (data.phone && !phoneRegex.test(data.phone)) {
          errors.phone = "Please enter valid phone number";
        }
        if (!data.question) {
          errors.question = "Please enter your message";
        }
        if (data.email && !emailRegex.test(data.email)) {
          errors.email = "Please enter valid email";
        }
        setError(errors);
        return;
      }
      setLoading(true);
      let user_id = getUserID();
      if (!user_id) user_id = null;
      let AllData: any = { user_id, ...data };
      let res: any = await contactUs(AllData);
      isMebershipAndActiveCheck(res, Navigate, toast, dispatch);
      let msg = "";
      let arrMsg = res.data.message;
      for (let i = 0; i < arrMsg.length; i++) {
        msg += arrMsg[i] + "\n";
      }
      if (!res.data.success) {
        toast.error(msg);
      } else if (res.data.success) {
        // toast.success(msg)
        setOpen(true);
        setData(iData);
      }
      setLoading(false);
    } catch (error: any) {
      if (typeof error?.message === "string") {
        toast.error(error?.message);
      } else {
        toast.error("internal server error");
      }
      setLoading(false);
    }
  };
  React.useEffect(() => {
    if (user_data) {
      setData(user_data);
    }
  }, [user_data]);

  return (
    <form className="" onSubmit={handleSubmit}>
      <CongratsModel
        open={open}
        setOpen={setOpen}
        text="Thank you for contacting us. Our team will be get back to you soon."
      />
      <div className="form-group col-lg-12 mb-3">
        <div className="row">
          <div className="form-group col-lg-6 col-md-6">
            <label>
              First Name <span className="required">*</span>
            </label>
            <input
              type="text"
              name="first_name"
              value={data?.first_name}
              onChange={handleChange}
              className="form-control"
              id=""
              placeholder="First name"
            />
            <h4 className="error-msgtext">{error.first_name}</h4>
          </div>
          <div className="form-group col-lg-6 col-md-6">
            <label>
              Last Name <span className="required">*</span>
            </label>
            <input
              type="text"
              onChange={handleChange}
              value={data?.last_name}
              name="last_name"
              className="form-control"
              id=""
              placeholder="Last name"
            />
            <h4 className="error-msgtext">{error.last_name}</h4>
          </div>
        </div>
      </div>

      <div className="form-group col-lg-12 mb-3">
        <div className="row">
          <div className="form-group col-lg-6 col-md-6">
            <label>
              Email <span className="required">*</span>
            </label>
            <input
              type="email"
              name="email"
              onChange={handleChange}
              value={data?.email}
              className="form-control"
              id=""
              placeholder="Email"
            />
            <h4 className="error-msgtext">{error.email}</h4>
          </div>
          <div className="form-group col-lg-6 col-md-6">
            <label>
              Phone Number <span className="required">*</span>
            </label>
            <input
              type="number"
              name="phone"
              onChange={handleChange}
              value={data?.phone}
              className="form-control"
              id=""
              placeholder="Phone number"
            />
            <h4 className="error-msgtext">{error.phone}</h4>
          </div>
        </div>
      </div>

      <div className="form-group col-lg-12 mb-3">
        <div className="row">
          <div className="form-group col-lg-12 col-md-12">
            <label>
              Message<span className="required">*</span>
            </label>
            <textarea
              name="question"
              placeholder="Message"
              value={data?.question}
              onChange={handleChange}
              className="form-control"
            />
            <h4 className="error-msgtext">{error.question}</h4>
          </div>
        </div>
      </div>

      <div className="col-lg-12 col-md-12">
        <button
          type="submit"
          disabled={loading}
          className="primary-blue-btn mt-5"
        >
          {loading ? <CircularProgress /> : "Submit"}
        </button>
      </div>
    </form>
  );
};

export default Contact;
