import React from "react";
import "./contactUs.scss";
import { useNavigate } from "react-router-dom";
import { CONTACT } from "../../../../Routes/RouteConstent";

const ContactUs = () => {
  const navigate = useNavigate();

  return (
    <div className="preLoginHowContactUs">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="138"
        height="146"
        viewBox="0 0 138 146"
        fill="none"
        className="preLoginHowContactUsLeftSvg"
      >
        <path
          d="M59.5705 112.789C44.4059 105.303 28.5059 100.471 12.3005 98.4211C-5.58291 96.1608 -21.0698 97.7297 -33.7436 103.078C-48.9585 109.505 -60.373 122.188 -63.5228 136.175C-63.6761 136.863 -63.8128 137.56 -63.9251 138.256C-66.3652 153.352 -58.6971 169.127 -46.0876 174.954C-34.3496 180.376 -19.1313 178.09 -0.860712 168.144C23.5011 154.888 44.5454 136.327 59.9967 114.463L60.7674 113.375L59.5656 112.783L59.5705 112.789ZM136.481 196.714C136.051 196.846 135.632 196.985 135.206 197.13C131.359 186.33 126.386 176.109 120.359 166.719C108.7 148.546 92.583 132.642 73.7578 120.748C70.4928 118.687 67.1104 116.721 63.6976 114.905L62.813 114.432L62.2425 115.249C46.5454 137.63 25.1088 156.61 0.245949 170.142C-18.6889 180.445 -34.5908 182.76 -47.0217 177.017C-60.5323 170.781 -68.7607 153.94 -66.1602 137.85C-66.0422 137.12 -65.8989 136.382 -65.7408 135.664C-62.4359 120.977 -50.5152 107.689 -34.6423 100.982C-21.6003 95.4742 -5.71438 93.8523 12.5695 96.1619C29.2397 98.2724 45.5867 103.275 61.1559 111.045L62.0511 111.49L62.6059 110.659C67.7922 102.877 72.2888 94.6788 75.9782 86.3092C81.2195 74.3957 84.9306 61.841 87.0052 49.0054C91.1288 23.4919 88.6714 -2.18501 79.8992 -25.2502C73.164 -42.9684 62.3451 -59.4284 48.616 -72.8606C34.934 -86.249 18.6159 -96.3356 1.36145 -102.079L2.04192 -104.245C19.628 -98.398 36.2635 -88.12 50.205 -74.478C64.1728 -60.8136 75.1734 -44.0651 82.0269 -26.039C90.9365 -2.59662 93.4368 23.4836 89.248 49.4002C87.1432 62.4227 83.3785 75.1604 78.0621 87.2414C74.3959 95.5789 69.9283 103.746 64.7877 111.506L64.0967 112.548L65.2021 113.141C68.5084 114.909 71.7912 116.823 74.9576 118.825C94.071 130.905 110.427 147.041 122.267 165.503C128.398 175.067 133.467 185.478 137.373 196.474C137.073 196.543 136.777 196.626 136.474 196.719"
          fill="#FBF8EF"
        />
      </svg>

      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="319"
        height="146"
        viewBox="0 0 319 146"
        fill="none"
        className="preLoginHowContactUsRightSvg"
      >
        <path
          d="M619.025 18.5964C595.512 5.68527 571.215 -7.48219 544.727 -10.5584C518.239 -13.6345 488.706 -4.11279 476.33 19.0039C464.087 41.874 471.477 70.0802 477.635 95.6447C483.795 121.211 487.441 151.168 470.13 170.472C454.21 188.222 425.044 189.429 403.254 178.454C381.463 167.478 366.011 146.926 354.336 125.591C342.662 104.256 333.88 81.3997 320.752 60.8952C307.622 40.3891 289.013 21.7914 265.509 15.5165C255.341 12.8041 244.576 12.5249 234.396 14.715C195.624 23.0525 173.372 64.4711 168.985 103.742C164.599 143.017 172.927 182.993 170.605 222.485C168.285 261.979 151.246 304.717 114.387 318.974C75.8155 333.894 31.2732 311.604 1.65618 281.722"
          stroke="#FBF8EF"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
      </svg>

      <h3>Still have a question?</h3>
      <button
        className="w-auto btn-small btn primary-blue-small-btn-40"
        onClick={() => navigate(CONTACT)}
      >
        Contact us
      </button>
    </div>
  );
};

export default ContactUs;
