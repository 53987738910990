import React from 'react';
import "./blog.scss";

const BlogsBlock = ({ news }: any) => {
    return (
        <section className='blog-section py-5'>
            <div className='container'>
                <div className='row mb-5'>
                    <div className='col-lg-12 col-md-12 text-center section-header'>
                        <h2 className="home-section-title p-0">Our <span className='strong'>Blog</span></h2>
                    </div>
                </div>
                <div className="row">
                    {Array.isArray(news) && news.map((newsData: any) => (
                        <div className="col-md-4 col-lg-4">
                            <div className="blog-card mx-0 mx-md-4 pb-3">

                                <div className="blog-img" style={{ 'backgroundImage': `url(${newsData?.thumbnail})` }}>
                                    {/* cgvhbh */}
                                </div>
                                <div className="blog-content mt-2 mx-2 pb-4 border-bottom">
                                    <span className="creation-date">{newsData?.days}</span>
                                    <h6 className="my-2">{newsData?.title}</h6>
                                    <p className="m-0 blog-summary">{newsData?.summary}</p>
                                    <a href={newsData?.url} className="d-inline-block mt-2">Read More</a>
                                </div>
                            </div>
                        </div>
                    ))}


                </div>

                {Array.isArray(news) && news.length >= 3 && <div className='row'>
                    <div className='col-lg-12 col-md-12 text-center '>
                        <button className="w-auto primary-blue-btn px-5 my-4" onClick={() => window.location.href = process.env.REACT_APP_BASE_URL + "blog/show"}>View All</button>
                    </div>
                </div>}
            </div>
        </section>
    )
}

export default BlogsBlock