import { CircularProgress } from '@mui/material'
import React, { useState } from 'react'
import { dateRegex } from '../../Utils/constant'
import ConfirmPopup from '../ConfirmPopup/confirmPopup'

const ReschuduleForm = ({ value, setValue, handleSubmit, loading, open, setOpen }: any) => {

    const [error, setError] = useState<any>({})

    const handleChange = (e: any) => {
        setError({
            ...error,
            [e.target.name]: ""
        })
        setValue({
            ...value,
            [e.target.name]: e.target.value
        })
    }
    const handleSubmit2 = (e: any) => {

        e.preventDefault()
        if (!value?.reschedule_reason || !value?.reschedule_date || !value?.reschedule_time) {
            let errors: any = {}
            if (!value?.reschedule_reason) {
                errors.reschedule_reason = "This field is required";
            }
            if (!value?.reschedule_date) {
                errors.reschedule_date = "This field is required"
            }
            if (!value?.reschedule_time) {
                errors.reschedule_time = "This field is requied"
            }
            setError(errors)
            return;
        }
        setOpen(true)
    }
    return (
        <div>
            <hr></hr>
            <ConfirmPopup open={open} setOpen={setOpen}
                loading={loading}
                text="Are you sure you want to reschedule your service registration?" callBack={handleSubmit} />
            <div className='py-5 py-md-5 d-flex flex-column justify-content-center'>
                <div className='step-container px-0'>
                    <form onSubmit={handleSubmit2}>
                        <div className="form-group mb-3">
                            <label>Reason for Rescheduling </label>
                            <input type="text" name="reschedule_reason"
                                onChange={handleChange}
                                value={value?.reschedule_reason} className=" form-control" id="" placeholder="Please enter reason for rescheduling" />
                            <p className="error">{error?.reschedule_reason}</p>
                            <p></p>
                        </div>
                        <div className="form-group mb-3">
                            <label>Enter Preferred Date </label>
                            <input type="text" name="reschedule_date"
                                onChange={handleChange}
                                value={value?.reschedule_date} className=" form-control" id="" placeholder="Please enter preferred date" />
                            <p className="error">{error?.reschedule_date}</p>
                        </div>
                        <div className="form-group mb-3">
                            <label>Enter Preferred Time </label>
                            <input type="text" name="reschedule_time"
                                onChange={handleChange}
                                value={value?.reschedule_time} className=" form-control" id="" placeholder="Please enter preferred time" />
                            <p className='reschedule-note my-3'> Note - Rescheduling can be done only once.</p>
                            <p className="error">{error?.reschedule_time}</p>
                        </div>
                        <div className='d-flex justify-content-center'>
                            <button type="submit" disabled={loading} className="primary-blue-small-btn btn w-50 me-2">Confirm </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default ReschuduleForm