import React from "react";
import { Modal } from "react-bootstrap";
import "./updateTariffModal.scss";

export interface UpdateTariffModalProps {
  children: JSX.Element | JSX.Element[];
  isVisible: boolean;
}

export interface BenefitsProps {
  benefits: { text: string; positive: boolean; price?: string }[];
}

export interface MembershipCardProps {
  title: string;
  price: string;
  priceOld?: string;
  priceSum?: string;
  priceSumDescription: string;
  type: "green" | "red";
}

export interface BottomControlsProps {
  confirmText: string;
  handleConfirm: (e: React.MouseEvent<HTMLElement>) => void;
  closeText: string;
  handleClose: (e: React.MouseEvent<HTMLElement>) => void;
}

const UpdateTariffModal = ({
  children,
  isVisible,
}: UpdateTariffModalProps) => {
  return (
    <Modal
      show={isVisible}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="updateTariffModalPopUpWindow"
    >
      <Modal.Body className="updateTariffModalPopUp">{children}</Modal.Body>
    </Modal>
  );
};

export const Benefits = ({ benefits }: BenefitsProps) => {
  return (
    <ul className="updateTariffModalBenefits">
      {benefits.map((benefit) => (
        <li
          className={`updateTariffModalBenefitsLi ${
            benefit.positive ? "updateTariffModalBenefitsLi--positive" : ""
          }`}
          key={benefit.text}
        >
          <div>
            {benefit.text}{" "}
            {benefit.price && (
              <span className="updateTariffModalBenefitsLi--price">
                ({benefit.price})
              </span>
            )}
          </div>
        </li>
      ))}
    </ul>
  );
};

export const MembershipCard = ({
  title,
  price,
  priceOld,
  priceSum,
  priceSumDescription,
  type,
}: MembershipCardProps) => {
  return (
    <div className="updateTariffModalMembershipCard">
      <h3 className={`updateTariffModalMembershipCard--${type}`}>{title}</h3>
      <div className="updateTariffModalMembershipCardContent">
        <div className="updateTariffModalMembershipCardContent__pricing">
          {priceOld && (
            <div className="updateTariffModalMembershipCardContent__price updateTariffModalMembershipCardContent__price--lineThrough">
              {priceOld}
            </div>
          )}
          <div className="updateTariffModalMembershipCardContent__price">
            {price}
          </div>
          <div className="updateTariffModalMembershipCardContent__frequency">
            /mo
          </div>
        </div>
        <div className="updateTariffModalMembershipCardContent__sum">
          {priceSum && (
            <span className="updateTariffModalMembershipCardContent__sum--price">
              {priceSum}
            </span>
          )}{" "}
          {priceSumDescription}
        </div>
        <div className="updateTariffModalMembershipCardContent__line" />
      </div>
    </div>
  );
};

export const BottomControls = ({
  handleConfirm,
  handleClose,
  confirmText,
  closeText,
}: BottomControlsProps) => (
  <div className="updateTariffModalButtons">
    <button
      className="btn primary-blue-small-btn-40 updateTariffModalButtons__confirm"
      onClick={handleConfirm}
    >
      {confirmText}
    </button>
    <button
      className="btn primary-blue-small-btn-40 updateTariffModalButtons__close"
      onClick={handleClose}
    >
      {closeText}
    </button>
  </div>
);

export default UpdateTariffModal;
