import React from 'react';
import Popup from '../Membership/Popup';
import StepsForNurse from './NursingConsults/StepsForNurse';

const NurseConsult = () => {
  

  return (
    
    <div>
      <Popup/>
      <StepsForNurse/>
    </div>
  )
}

export default NurseConsult
