import { configureStore } from '@reduxjs/toolkit'
import CourseSlice from "./Course/CourseReducer"
import EventSlice from "./Events/EventsReducer";
import userSlice from './user/user';
import newMessagesSlice from './new-messages/newMessages';

export const store = configureStore({
  reducer: {
    courseData: CourseSlice,
    events: EventSlice,
    userData:userSlice,
    newMessages: newMessagesSlice,
  },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch