import React, { useEffect, useState } from "react";
import filterIcon from "../assets/filterIcon.svg";
import loupeIcon from "../assets/loupeIcon.svg";
import closeIcon from "../assets/closeIcon.svg";
import { useNavigate, useSearchParams } from "react-router-dom";
import { xor } from "lodash";
import useModalWindowControls from "../../../Hooks/useModal";
import { CircularProgress } from "@mui/material";
import { ASK_A_NURSE } from "../../../Routes/RouteConstent";
import useAskNurseFilter from "./useAskFilter";
import "./filter.scss";
import useDebounce from "../../../Hooks/useDebounce";

const Filter = ({
  className = "",
  savedCategories,
  savedStages,
  updateSavedData,
}: {
  className?: string;
  savedCategories: string[];
  savedStages: string[];
  updateSavedData: (filter: { stages: string[]; categories: string[] }) => void;
}) => {
  const filterModal = useModalWindowControls();

  const { filterOptions } = useAskNurseFilter();

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [categories, setCategories] = useState<string[]>([]);
  const [stages, setStages] = useState<string[]>([]);
  const [search, setSearch] = useState<string>(
    searchParams.get("search") || ""
  );

  useEffect(() => {
    setCategories(savedCategories);
    setStages(savedStages);
  }, [savedCategories, savedStages]);

  const updateFilter =
    (seState: React.Dispatch<React.SetStateAction<string[]>>) =>
    (id: number) => {
      seState((state) => xor(state, [`${id}`]));
    };

  const clearFilterSavedData = () => {
    updateSavedData({ stages: [], categories: [] });
    filterModal.closeModal();
  };

  const updateFilterSavedData = () => {
    updateSavedData({ stages, categories });
    filterModal.closeModal();
  };

  const searchDebounce = useDebounce(search);

  useEffect(() => {
    if ((searchParams.get("search") || "") !== searchDebounce) {
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.set("search", searchDebounce);

      navigate(
        `/${ASK_A_NURSE}/1?${decodeURIComponent(newSearchParams.toString())}`
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchDebounce]);

  return (
    <div className={`filter ${className}`}>
      <button className="filter__btn" onClick={filterModal.openModal}>
        <img src={filterIcon} alt="filter" />
        Filter
      </button>
      <input
        className="filter__search"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
      <img src={loupeIcon} alt="loupe" />

      {filterModal.isVisible && (
        <div className="filterModal">
          <button
            className="filterModal__close"
            onClick={filterModal.closeModal}
          >
            <img src={closeIcon} alt="x" />
          </button>
          <div className="filterModal__title">Filter</div>
          {filterOptions.pending && (
            <div className="mt-4 filterModal__preloader">
              <CircularProgress className="" size={30} />
            </div>
          )}

          {filterOptions.success && (
            <>
              <div className="filterModal__listTitle mt-2">Stages</div>
              <div className="filterOptions mt-2">
                {filterOptions.data.stage.map(({ name, id }) => (
                  <Radio
                    key={id}
                    name={name}
                    value={id}
                    checked={!!stages?.includes(`${id}`)}
                    onChange={updateFilter(setStages)}
                  />
                ))}
              </div>
              <div className="filterModal__listTitle mt-2">Category</div>
              <div className="filterOptions mt-2">
                {filterOptions.data.category.map(({ name, id }) => (
                  <Radio
                    key={id}
                    name={name}
                    value={id}
                    checked={!!categories?.includes(`${id}`)}
                    onChange={updateFilter(setCategories)}
                  />
                ))}
              </div>
            </>
          )}
          <div className="mt-4">
            <button
              className="filterModal__btn filterModal__btn--reset"
              disabled={filterOptions.pending}
              onClick={clearFilterSavedData}
            >
              Reset
            </button>
            <button
              className="filterModal__btn"
              onClick={updateFilterSavedData}
              disabled={filterOptions.pending}
            >
              Apply
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

const Radio = ({
  name,
  value,
  checked,
  onChange,
}: {
  name: string;
  value: number;
  checked: boolean;
  onChange: (value: number) => void;
}) => (
  <label className="filterModal__radio">
    <input
      type="checkbox"
      id={name}
      name={name}
      className="filterModal__input form-check-input"
      onChange={() => onChange(value)}
      value={value}
      checked={checked}
    />
    <span className="filterModal__label">{name}</span>
  </label>
);
export default Filter;
