import React from 'react';
import Popup from '../Membership/Popup';
import StepsForBuy from './BuyConsults/StepsForBuy';

const Consult = () => {
  return (
    <div>
      <Popup />
      <StepsForBuy />
    </div>
  )
}

export default Consult
