import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { eventBookingStore } from "../../Service/equityApi";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { GetPrefilledData } from "../../Utils/getUserData/getUserData";
import { Stack, TextField } from "@mui/material";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Link } from "react-router-dom";
import * as path from "../../Routes/RouteConstent";
import dayjs from "dayjs";

const DetailPopup = ({
  show,
  handleClose,
  value,
  toast,
  trigPopup,
  setData,
}: any) => {
  const [inputData, setInputData] = useState<{
    term_condition: boolean;
    first_name: string;
    last_name: string;
    email: string;
    phone: string;
    due_date: Date | null;
    is_your_first_baby: string;
    city: string;
    state: string;
  }>({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    term_condition: false,
    due_date: null,
    is_your_first_baby: "",
    city: "",
    state: "",
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [user_data] = GetPrefilledData();

  let user: any = localStorage.getItem("Nurture_user_data");
  let data = JSON.parse(user);

  const handleChange = (e: any) => {
    let name = e.target.name;
    let value = e.target.value;
    if (!user) {
      setInputData({
        ...inputData,
        [name]: value,
      });
    }
  };

  const handleChangeNotProtected = (e: any) => {
    let name = e.target.name;
    let value = e.target.value;

    setInputData({
      ...inputData,
      [name]: value,
    });
  };

  const dateHandler = (date: Date | null | any) => {
    setInputData((s) => ({
      ...s,
      due_date: date,
    }));
  };

  const policyHandler = (date: Date | null | any) => {
    setInputData((s) => ({
      ...s,
      term_condition: !s.term_condition,
    }));
  };

  const handleSave = async (e: any) => {
    e.preventDefault();
    try {
      if (
        !inputData.first_name ||
        !inputData.last_name ||
        !inputData.email ||
        !inputData.phone ||
        inputData.phone.length < 10 ||
        !inputData.due_date ||
        !inputData.is_your_first_baby ||
        !inputData.city ||
        !inputData.state ||
        !inputData.term_condition
      ) {
        if (!inputData.phone) {
          toast.error("Contact number is required");
        }
        if (inputData.phone?.length < 10) {
          toast.error("Contact number must be 10 digit");
        }
        if (!inputData.due_date) {
          toast.error("Due date is required");
        }
        if (!inputData.is_your_first_baby) {
          toast.error("Is your first baby field is required");
        }
        if (!inputData.city) {
          toast.error("City field is required");
        }
        if (!inputData.state) {
          toast.error("State field is required");
        }
        if (!inputData.term_condition) {
          toast.error("Please read and confirm terms of service");
        }
      } else {
        setIsLoading(true);
        let userData = {
          ...inputData,
          due_date: dayjs(inputData.due_date).format("YYYY-MM-DD"),
          user_id: data?.id,
          event_id: value?.id,
          event_name: value?.title,
          appointment_id: value?.appointment_id,
          appointment_date_time: value?.event_date_time,
        };
        let res: any = await eventBookingStore(userData);
        let success = res.data.success;
        let message = res.data.message;
        let msg = "";
        for (let i = 0; i < message.length; i++) {
          msg += message[i];
        }
        if (!success) {
          toast.error(msg);
        }
        if (success) {
          setData((prev: any) => {
            return {
              ...prev,
              is_book: true,
            };
          });
          await handleClose();
          trigPopup();
        } else {
          setIsLoading(false);
          handleClose();
        }
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      handleClose();
    }
  };

  useEffect(() => {
    if (user_data) {
      setInputData((data) => ({ ...data, ...user_data }));
    }
  }, [user_data]);

  React.useEffect(() => {
    const htmlElem = document.querySelector("html");
    if (htmlElem) {
      htmlElem.style.overflow = show ? "hidden" : "auto";
    }

    return () => {
      if (htmlElem) htmlElem.style.overflow = "auto";
    };
  }, [show]);

  return (
    <Dialog open={show} onClose={handleClose}>
      <DialogTitle>
        <h3 className="px-4">Confirm your Information</h3>
        <IconButton
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon onClick={handleClose} />
          </IconButton>
      </DialogTitle>
      <DialogContent>
        <div className="confirm-info text-left">
          <form>
            <div className="mb-3 text-left">
              <label className="form-First-nm" htmlFor="first-name">
                First Name{" "}
                <span className="required mandatory text-danger mx-2">*</span>
              </label>
              <input
                name="first_name"
                value={inputData?.first_name}
                placeholder="First Name"
                type="text"
                id="first-nm"
                className="form-control"
                onChange={handleChange}
              />
            </div>
            <div className="mb-3 mt-3 text-left">
              <label className="form-Last-nm" htmlFor="last-name">
                Last Name{" "}
                <span className="required mandatory text-danger mx-2">*</span>
              </label>
              <input
                name="last_name"
                value={inputData?.last_name}
                placeholder="Last Name"
                type="text"
                id="last-nm"
                className="form-control"
                onChange={handleChange}
              />
            </div>
            <div className="mb-3 mt-3 text-left">
              <label className="form-Email" htmlFor="Email-id">
                Email Id{" "}
                <span className="required mandatory text-danger mx-2">*</span>
              </label>
              <input
                name="email"
                value={inputData?.email}
                placeholder="Email"
                type="text"
                id="email-id"
                className="form-control"
                onChange={handleChange}
              />
            </div>
            <div className="mb-3 mt-3 text-left">
              <label className="form-phone" htmlFor="phone">
                Contact No.{" "}
                <span className="required mandatory text-danger mx-2">*</span>
              </label>
              <input
                name="phone"
                placeholder="Contact No."
                value={inputData?.phone}
                type="text"
                id="contact-num"
                className="form-control"
                onChange={handleChangeNotProtected}
              />
            </div>
            <div className="mb-3 text-left">
              <label>
                Due Date/Child's Birthday{" "}
                <span className="mandatory text-danger mx-2">*</span>
              </label>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack spacing={3}>
                  <MobileDatePicker
                    toolbarTitle="Choose date"
                    value={inputData?.due_date}
                    onChange={dateHandler}
                    renderInput={(params: any) => (
                      <TextField
                        placeholder="MM-DD-YYYY"
                        {...params}
                        className="due-date-field"
                      />
                    )}
                  />
                </Stack>
              </LocalizationProvider>
            </div>
            <div className="mb-3 text-left">
              <label>
                Is this your first baby ?{" "}
                <span className="mandatory text-danger mx-2">*</span>
              </label>
              <div className="custom-radio custom-control-inline">
                <input
                  type="radio"
                  id="customRadio1"
                  name="is_your_first_baby"
                  checked={inputData?.is_your_first_baby === "yes"}
                  onChange={handleChangeNotProtected}
                  value="yes"
                  className="custom-control-input form-check-input"
                />
                <label
                  className="custom-control-label me-3"
                  htmlFor="customRadio1"
                >
                  yes
                </label>
                <input
                  type="radio"
                  id="customRadio2"
                  name="is_your_first_baby"
                  onChange={handleChangeNotProtected}
                  value="no"
                  checked={inputData?.is_your_first_baby === "no"}
                  className="custom-control-input form-check-input"
                />
                <label
                  className="custom-control-label step_two"
                  htmlFor="customRadio2"
                >
                  no
                </label>
              </div>
            </div>
            <div className="mb-3 text-left">
              <label>
                City <span className="mandatory text-danger mx-2">*</span>
              </label>
              <input
                type="text"
                name="city"
                value={inputData?.city}
                className="form-control"
                onChange={handleChangeNotProtected}
              />
            </div>
            <div className="mb-3 text-left">
              <label>
                State <span className="mandatory text-danger mx-2">*</span>
              </label>
              <input
                type="text"
                name="state"
                value={inputData?.state}
                className="form-control"
                onChange={handleChangeNotProtected}
              />
            </div>
            <div className="mb-3 text-left">
              <div className="custom-radio custom-control-inline">
                <input
                  type="radio"
                  id="customRadio1"
                  name="term_condition"
                  className="custom-control-input form-check-input"
                  checked={inputData?.term_condition}
                  onChange={policyHandler}
                />
                <label className="custom-control-label" htmlFor="customRadio1">
                  I have read and agree to the{" "}
                  <Link target="_blank" className="blue-link" to={path.TERMS}>
                    {" "}
                    terms of service
                  </Link>
                </label>
              </div>
            </div>
          </form>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          className="primary-blue-small-btn mx-auto w-100"
          onClick={handleSave}
        >
          {isLoading ? <CircularProgress size={18} /> : " Save changes"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DetailPopup;
