import React from 'react'
import { Container, Row } from 'react-bootstrap';
import "./Favourate.scss"
import CourseCard from '../../../Common/CourseCard/CourseCard';
import { UpdateFunType } from '../../../Types/Course/updateFunction';
import { getCourse, upDateFavData } from '../../../Redux/Course/CourseReducer';
import { useDispatch } from "react-redux"
import { courseLikeRedux } from '../../../Utils/course/like';
import FavCourseEmpty from '../../../Common/EmptyState/course/favCourse';

const Favourate = ({ favData, updateCoursesData }: any) => {
    const dispatch = useDispatch()
    let userID: any = localStorage.getItem("Nurture_user_data");
    userID = JSON.parse(userID)?.id;

    const favLikeFavCourse = (type: string, data: UpdateFunType, isUpdate: UpdateFunType, isSuccess: UpdateFunType) => {
        if (isSuccess) {
            dispatch(getCourse({ user_id: userID }))
            return;
        }
        if (type === "fav") {
            if (isUpdate) {
                let newFavValue = favData.filter((res: any) => res.id !== data.id)
                dispatch(upDateFavData(newFavValue))
            } else {
                dispatch(upDateFavData(favData))
            }
        } else if (type === "like") {
            courseLikeRedux(data, favData, dispatch, upDateFavData, isUpdate)
        }
    }
    return (
        <>
            <Container fluid className='p-0'>
                <Row>
                    <div className="tab_main_courseContent col-lg-12 col-md-12 py-5">
                        <Container className='paddingLR-00'>
                            <div className="row">
                                <div className="col-lg-12 col-md-12 text-left"><h2>Favorites</h2></div>
                            </div>
                            <div className="row">
                                <CourseCard data={favData} upDateData={updateCoursesData || favLikeFavCourse} />
                            </div>


                            <FavCourseEmpty data={favData} />
                        </Container>
                    </div>
                </Row>
            </Container>
        </>
    )
}

export default Favourate;