import React, { useCallback, useState } from "react";
import { Modal } from "react-bootstrap";
import "./startChatModal.scss";
import MessageEditor from "../../ask-a-nurse-chat/message-editor";
import * as asyncState from "../../../Utils/asyncState";
import { postChatCreate } from "../transport";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { xor } from "lodash";
import useAskNurseFilter from "../filter/useAskFilter";
import Radio from "../filter/Radio";
import useAddClassName from "../../../Hooks/useAddClassName";
import { CircularProgress } from "@mui/material";
import { ASK_A_NURSE_CHAT } from "../../../Routes/RouteConstent";

const StartChatModal = ({
  isVisible,
  closeModal,
}: {
  isVisible: boolean;
  closeModal: () => void;
}) => {
  const navigate = useNavigate();
  const [sendMessage, setSendMessage] = useState<asyncState.AsyncState>({
    ...asyncState.initState,
  });
  const [categories, setCategories] = useState<string[]>([]);
  const [stages, setStages] = useState<string[]>([]);
  const [title, setTitle] = useState<string>("");
  const [editorValue, setEditorValue] = useState<string>("");
  const [showErrors, setShowErrors] = useState(false);

  const closeModalHandler = useCallback(() => {
    closeModal();
    setTitle("");
    setEditorValue("");
    setCategories([]);
    setStages([]);
    setShowErrors(false);
  }, [closeModal]);

  const sendMessageRequest = useCallback(async () => {
    if (!title || !editorValue || editorValue === "<p><br></p>") {
      setShowErrors(true);
      return;
    }

    try {
      setShowErrors(false);

      setSendMessage({
        ...asyncState.pendingState,
      });

      const res: any = await postChatCreate({
        message: editorValue,
        title,
        categories,
        stages,
      });

      if (res.status !== 200) {
        throw new Error(res?.message || res?.data?.message?.join(" "));
      }

      setSendMessage({
        ...asyncState.successState,
      });

      navigate(`/${ASK_A_NURSE_CHAT}/${res.data.id}`);
      closeModalHandler();
    } catch (err: { message: string } | any) {
      toast.error(err?.message);
      setSendMessage({
        ...asyncState.failedState,
        failedMessage: err?.message,
      });
    }
  }, [categories, closeModalHandler, editorValue, navigate, stages, title]);

  const { filterOptions } = useAskNurseFilter();

  const updateFilter =
    (seState: React.Dispatch<React.SetStateAction<string[]>>) =>
    (id: number) => {
      seState((state) => xor(state, [`${id}`]));
    };

  useAddClassName({
    className: "preventScroll",
    allowAddClassName: isVisible,
    element: "html",
  });

  return (
    <Modal
      show={isVisible}
      onHide={() => {}}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="startChatModalWindow"
    >
      <Modal.Body className="startChatModal">
        <div className="startChatModal__content">
          <button className="startChatModalCross" onClick={closeModal}>
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.5307 13.2694C16.1494 13.888 16.1494 14.912 15.5307 15.5307C15.2107 15.8507 14.8054 16 14.4 16C13.9947 16 13.5894 15.8507 13.2694 15.5307L8.00004 10.2614L2.7307 15.5307C2.4107 15.8507 2.00537 16 1.60004 16C1.1947 16 0.789371 15.8507 0.469371 15.5307C-0.149296 14.912 -0.149296 13.888 0.469371 13.2694L5.7387 8.00004L0.469371 2.7307C-0.149296 2.11204 -0.149296 1.08804 0.469371 0.469371C1.08804 -0.149296 2.11204 -0.149296 2.7307 0.469371L8.00004 5.7387L13.2694 0.469371C13.888 -0.149296 14.912 -0.149296 15.5307 0.469371C16.1494 1.08804 16.1494 2.11204 15.5307 2.7307L10.2614 8.00004L15.5307 13.2694V13.2694Z"
                fill="#333"
              />
            </svg>
          </button>
          <h4 className="title">What’s your question?</h4>
          <p className="paragraph">
            Ask a nurse doesn't mean you have to have a sick kid! Our nurses and
            nurse practitioners can discuss concerns and answer questions about
            any age and stage of parenting. We can answers questions about you,
            as a parent, or about your child. When you post your question,
            please give us 24 hours to respond.
          </p>
          <p className="paragraph strong">
            Disclaimer: This forum is not meant to take the place of medical
            advice from your provider. If you are concerned about your health or
            your child's health, or are in need of medical attention, please
            contact you or your child's healthcare provider.
          </p>
          {filterOptions.success && (
            <>
              <div className="filterModal__listTitle mt-2">Stages</div>
              <div className="filterOptions mt-1">
                {filterOptions.data.stage.map(({ name, id }) => (
                  <Radio
                    key={id}
                    name={name}
                    value={id}
                    checked={!!stages?.includes(`${id}`)}
                    onChange={updateFilter(setStages)}
                  />
                ))}
              </div>
              <div className="filterModal__listTitle mt-2">Category</div>
              <div className="filterOptions mt-1">
                {filterOptions.data.category.map(({ name, id }) => (
                  <Radio
                    key={id}
                    name={name}
                    value={id}
                    checked={!!categories?.includes(`${id}`)}
                    onChange={updateFilter(setCategories)}
                  />
                ))}
              </div>
            </>
          )}
        </div>
        <label className="filterModal__listTitle mt-2 mb-0 w-100">
          Title *
          <input
            type="text"
            className="form-control mt-2"
            value={title}
            onChange={({ target }) => setTitle(target.value)}
          />
        </label>
        {showErrors && <div className="error text-end">Field is required</div>}
        <MessageEditor
          value={editorValue}
          setValue={setEditorValue}
          className="mt-3"
        />
        {showErrors && <div className="error text-end">Field is required</div>}
        <div className="d-flex justify-content-end">
          <button
            className="btn primary-blue-small-btn-40 mt-3"
            type="button"
            disabled={!!sendMessage.pending}
            onClick={sendMessageRequest}
          >
            {sendMessage.pending ? <CircularProgress size={20} /> : "Send"}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default StartChatModal;
