import React from 'react'

const NoUpEvents = () => {
    return (
        <div>
            <div className='emptys-container d-flex flex-column align-items-center justify-content-center my-5'>
                <div className='icon-container event-icon'>

                </div>
                <h3>No Upcoming <strong>Event</strong></h3>
                <p>There is no live event in this month. Please try another month.</p>
                {/* <button className="btn primary-blue-small-btn-40 mt-3">View Event</button> */}
            </div>
        </div>
    )
}

export default NoUpEvents;