import React from "react";
import UpdateTariffModal, {
  Benefits,
  BottomControls,
  MembershipCard,
  UpdateTariffModalProps,
} from "./UpdateTariffModal";

interface DowngradeModalProps extends Omit<UpdateTariffModalProps, "children"> {
  closeModal: () => void;
  confirm: (membershipId: number) => void;
}

const DowngradeModal = ({
  isVisible,
  closeModal,
  confirm,
}: DowngradeModalProps) => {
  return (
    <UpdateTariffModal isVisible={isVisible}>
      <h2>Are you sure you want to downgrade?</h2>
      <h3>
        By downgrading to the Monthly Membership, you will{" "}
        <span className="strong">lose access to</span>
      </h3>
      <Benefits
        benefits={[
          { text: "Pre Baby Bootcamp", positive: false },
          { text: "Mom Survival Group", positive: false },
          {
            text: "Group Infant and Child CPR with First Aid",
            positive: false,
          },
          { text: "Group Sleep Consult", positive: false },
        ]}
      />
      <MembershipCard
        title="Monthly Membership"
        price="$149"
        priceSumDescription="Renews monthly, cancel any time."
        type="red"
      />
      <BottomControls
        handleConfirm={() => confirm(1)}
        handleClose={closeModal}
        confirmText="Confirm Downgrade"
        closeText="Keep Annual Membership"
      />
    </UpdateTariffModal>
  );
};

export default DowngradeModal;
