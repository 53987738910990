import { DialogProps } from '@mui/material';
import React, { useState } from 'react';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { CityStateZipValSub } from '../../../../Common/commonFIeld/validation';
import { Link } from 'react-router-dom';
import * as path from "../../../../Routes/RouteConstent";

const UserInfo2 = ({ handleNext, value, setValue, handlePrev, term_condition, setTermPolicy, setPolicy }: any) => {

    const [errors, setErrors] = useState<any>({})
    const [open, setOpen] = React.useState<boolean>(false);
    const [scroll, setScroll] = React.useState<DialogProps['scroll']>('paper');
    const [clientService, setClientService] = useState(false)
    const [consent, setConsent] = useState(false)
    const [privacy, setPrivacy] = useState(false)

    const handleChange = (e: React.ChangeEvent<HTMLInputElement> | any) => {
        let name = e.target.name;
        let inputValue = e.target.value;
        if (e.target.name === "client_service_agreement" || e.target.name === "privacy_practises") {
            if (e.target.checked) {
                setErrors({
                    ...errors,
                    [e.target.name]: ""
                })
            } else {
                setErrors({
                    ...errors,
                    [e.target.name]: "Please select the field"
                })
            }
            setValue({
                ...value,
                [e.target.name]: e.target.checked
            })
            return

        }
        // if (name === "zip_code") {
        //     if (inputValue.length !== 5) {
        //         setErrors({
        //             ...errors,
        //             [name]: "Please enter valid zip code"
        //         })
        //     } else {
        //         setErrors({
        //             ...errors,
        //             [name]: ""
        //         })
        //     }
        // } else {
        //     setErrors({
        //         ...errors,
        //         [name]: ""
        //     })
        // }
        setValue({
            ...value,
            [name]: inputValue
        })
    }
    const dateHandler = (date: Date | null | any, name: string) => {
        setErrors({
            ...errors,
            [name]: ""
        })
        setValue({
            ...value,
            [name]: date
        })
    }


    const handleSubmit = () => {
        let cityStateZip = CityStateZipValSub(value)
        if (!value?.due_date || value?.due_date === "Invalid date" || cityStateZip || !term_condition || !value?.is_your_first_baby ||
            !value?.medical_surgical_history_baby || !value?.privacy_practises || !value?.client_service_agreement || !value?.what_method_of_sleep_training || !value?.what_is_your_biggest_sleep_concern || !value?.what_are_your_goals

        ) {
            let error: any = {}

            if (!value?.due_date) {
                error.due_date = "Please select date"
            }
            if (value?.due_date === "Invalid date") {
                error.due_date = "Please select date"
            }
            if (!value?.is_your_first_baby) {
                error.is_your_first_baby = "Please select the value"
            }
            if (!value?.medical_surgical_history_baby) {
                error.medical_surgical_history_baby = "Please input the value"
            }
            if (!value?.what_method_of_sleep_training) {
                error.what_method_of_sleep_training = "Please input the value"
            }
            if (!value?.what_is_your_biggest_sleep_concern) {
                error.what_is_your_biggest_sleep_concern = "Please input the value"
            }
            if (!value?.what_are_your_goals) {
                error.what_are_your_goals = "Please input the value"
            }
            if (!term_condition) {
                error.term_condition = "Please check the policy"
            }

            if (!value?.client_service_agreement) {
                error.client_service_agreement = "Please select the policy to continue"
            }
            if (!value?.privacy_practises) {
                error.privacy_practises = "Please select the policy to continue"
            }
            setErrors({ ...error, ...cityStateZip })
        } else {

            handleNext(value)
        }
    }

    const handleTermPolicy = (e: React.ChangeEvent<HTMLInputElement>, name: string) => {
        setErrors({
            ...errors,
            [name]: ""
        })
        setPolicy(e.target.checked)
        setValue({
            ...value,
            term_condition: e.target.checked
        })
    }
    let handleRadioButton = (e: any) => {
        let val = e.target.value;
        setErrors({
            ...errors,
            [e.target.name]: ""
        })
        setValue({
            ...value,
            [e.target.name]: val
        })
    }

    return (
        <div>
            <section className='Pre-baby_bootcamp step-container step2'>
                <div className='consult-section-heading text-center'>
                    <h3 className='my-5'>Complete your Information</h3>
                </div>
                <form className=' '>
                    <div className='row'>
                        <div className="form-group col-lg-12 col-md-12 mb-3 due_dt_container">
                            <label >Due Date/Child's Birthday <span className='mandatory text-danger mx-2'>*</span></label>
                            <LocalizationProvider dateAdapter={AdapterDayjs} >
                                <Stack spacing={3}>
                                    <MobileDatePicker
                                        value={value?.due_date || ""}
                                        onChange={(date: any) => dateHandler(date, "due_date")}
                                        renderInput={(params: any) => <TextField {...params} placeholder="MM-DD-YYYY" className='due-date-field' />}
                                    />
                                </Stack>
                            </LocalizationProvider>
                            <h4 className="error-msgtext">{errors?.due_date}</h4>
                        </div>

                        <div className="form-group col-lg-12 col-md-12 mb-3 due_dt_container">
                            <label >Child's Name <span className="fw-light">(if applicable)</span></label>
                            <input type="text" name='child_name' value={value?.child_name} className='form-control' onChange={handleChange} placeholder='Type Something' />
                        </div>

                        <div className="form-group col-lg-12 col-md-12 mb-3">
                            <label>Is this your first baby? <span className='mandatory text-danger mx-2'>*</span></label>
                            <div className="custom-radio custom-control-inline">
                                <input type="radio" id="customRadio1" name="is_your_first_baby" value="yes" checked={value?.is_your_first_baby === "yes"}
                                    onChange={handleRadioButton} className="custom-control-input form-check-input"

                                />
                                <label className="custom-control-label me-3" htmlFor="customRadio1">yes</label>
                                <input type="radio" id="customRadio2" name="is_your_first_baby"
                                    value="no" checked={value?.is_your_first_baby === "no"} onChange={handleRadioButton} className="custom-control-input form-check-input"
                                />
                                <label className="custom-control-label step_two" htmlFor="customRadio2">no</label>
                            </div>
                            <h4 className="error-msgtext">{errors?.is_your_first_baby}</h4>
                        </div>

                        <div className="form-group col-sm-12 mb-3">
                            <label>City <span className='mandatory text-danger mx-2'>*</span></label>
                            <input type="text" name='city'
                                value={value?.city}
                                className="form-control"
                                onChange={handleChange}
                            />
                            <h4 className="error-msgtext">{errors?.city}</h4>
                        </div>
                        <div className="form-group col-sm-12 mb-3">
                            <label>State <span className='mandatory text-danger mx-2'>*</span></label>
                            <input type="text" name='state'
                                value={value?.state}
                                className="form-control"
                                onChange={handleChange}
                            />
                            <h4 className="error-msgtext">{errors?.state}</h4>
                        </div>

                        <div className="form-group col-lg-12 col-md-12 mb-3">
                            <label>Partner or Support Person's Name <span className="fw-light"> (if applicable)</span></label>
                            <input type="text" name='partner_support_person_name' value={value?.partner_support_person_name} className='form-control' onChange={handleChange} placeholder='Type Something' />
                            <h4 className="error-msgtext d-none">{"error"}</h4>
                        </div>

                        <div className="form-group col-lg-12 col-md-12 mb-3">
                            <label>Partner or Support Person's Email address <span className="fw-light">( for reminders, content, and follow up )</span></label>
                            <input type="text" name='partner_support_person_email_address' value={value?.partner_support_person_email_address} className='form-control' onChange={handleChange} placeholder='Type Something' />
                            <h4 className="error-msgtext d-none">{"error"}</h4>
                        </div>

                        <div className="form-group col-lg-12 col-md-12 mb-3">
                            <label>Will your partner be on the call?   <span className="fw-light">(encouraged by not required!)</span> </label>
                            <div className="custom-radio custom-control-inline">
                                <input type="radio" id="customRadio1" name="your_partner_be_the_call" value="yes" onChange={handleRadioButton}
                                    checked={value?.your_partner_be_the_call === "yes"} className="custom-control-input form-check-input"

                                />
                                <label className="custom-control-label me-3" htmlFor="customRadio1">yes</label>
                                <input type="radio" id="customRadio2" name="your_partner_be_the_call"
                                    value="no" checked={value?.your_partner_be_the_call === "no"} onChange={handleRadioButton} className="custom-control-input form-check-input"
                                />
                                <label className="custom-control-label step_two" htmlFor="customRadio2">no</label>
                            </div>
                        </div>

                        <div className="form-group col-lg-12 col-md-12 mb-3"><label>Please list any medical or surgical history for your baby that we should be aware of: <span className="mandatory text-danger mx-2">*</span></label>
                            <textarea name='medical_surgical_history_baby' value={value?.medical_surgical_history_baby} onChange={handleChange} className="form-control" placeholder="Type something"> </textarea>
                            <h4 className="error-msgtext">{errors?.medical_surgical_history_baby}</h4>
                        </div>

                        <div className="form-group col-lg-12 col-md-12 mb-3"><label>What methods <span className='fw-light'>(if any)</span> of sleep training have you tried? <span className="mandatory text-danger mx-2">*</span></label>
                            <textarea name='what_method_of_sleep_training' value={value?.what_method_of_sleep_training} onChange={handleChange} className="form-control" placeholder="Type something"> </textarea>
                            <h4 className="error-msgtext ">{errors?.what_method_of_sleep_training}</h4>
                        </div>

                        <div className="form-group col-lg-12 col-md-12 mb-3"><label>What is your biggest sleep concern you would like to address during the consultation? <span className="mandatory text-danger mx-2">*</span></label>
                            <textarea name='what_is_your_biggest_sleep_concern' value={value?.what_is_your_biggest_sleep_concern} onChange={handleChange} className="form-control" placeholder="Type something"> </textarea>
                            <h4 className="error-msgtext ">{errors?.what_is_your_biggest_sleep_concern}</h4>
                        </div>

                        <div className="form-group col-lg-12 col-md-12 mb-3"><label>What are your goals for this consultation?  <span className="mandatory text-danger mx-2">*</span></label>
                            <textarea name='what_are_your_goals' value={value?.what_are_your_goals} onChange={handleChange} className="form-control" placeholder="Type something"> </textarea>
                            <h4 className="error-msgtext ">{errors?.what_are_your_goals}</h4>
                        </div>

                        <div className='agreement-container py-4 d-flex flex-column'>
                            <div>
                                <div className="custom-radio custom-control-inline">
                                    <input type="checkbox" id="customRadio1" name='term_condition'
                                        className="custom-control-input form-check-input" value="Accepted"
                                        checked={term_condition}
                                        onChange={(e) => handleTermPolicy(e, "term_condition")}
                                    />
                                    <label className="custom-control-label" htmlFor="customRadio1">I have read and agree to the <Link target="_blank" className='blue-link' to={path.TERMS} > terms of service</Link></label>
                                </div>
                                <h4 className="error-msgtext">{errors?.term_condition}</h4>
                            </div>

                            <div>
                                <div className="custom-radio custom-control-inline">
                                    <input type="checkbox" id="customRadio1" name='client_service_agreement'
                                        className="custom-control-input form-check-input" value="Accepted"
                                        checked={value?.client_service_agreement}
                                        onChange={handleChange}
                                    />
                                    <label className="custom-control-label" htmlFor="customRadio1">I have read and agree to the <Link target="_blank" className='blue-link' to={path.CLIENT_SERVICE_AGREEMENT} > client service agreement </Link></label>
                                </div>
                                <h4 className="error-msgtext">{errors?.client_service_agreement}</h4>
                            </div>

                            <div>
                                <div className="custom-radio custom-control-inline">
                                    <input type="checkbox" id="" name='privacy_practises'
                                        className="custom-control-input form-check-input" value="Accepted"
                                        checked={value?.privacy_practises}
                                        onChange={handleChange}
                                    />
                                    <label className="custom-control-label" htmlFor="">I have read and agree to the <Link target="_blank" className='blue-link' to={path.CONSENT_FOR_DISCLOSURE} > consent for disclosure </Link>and <Link target="_blank" className='blue-link' to={path.PRIVACY} > privacy practices</Link></label>
                                </div>
                                <h4 className="error-msgtext">{errors?.privacy_practises}</h4>
                            </div>
                        </div>
                    </div>
                </form>

                <div className='section-navigation my-5 d-flex justify-content-between'>
                    <button className='primary-blue-small-btn' onClick={handlePrev}>Previous</button>
                    <button className='primary-blue-small-btn next' onClick={handleSubmit}>Next</button>
                </div>
            </section>
        </div>
    )
}

export default UserInfo2;