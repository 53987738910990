
import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import './CongratsPopup2.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import { GIFTCARD } from '../../Routes/RouteConstent';
import parse from 'html-react-parser';
// import { parse } from 'node:path/win32';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    justifyContent: "flex-start",


};
interface Iprops {
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    text?: any,
    title?: string,
    isGift?: boolean,
    btnText?: string,
    Page?: String | any,
    otherPageSetting?: any,
    callBack?: any
}
export default function CongratsModel2(props: Iprops) {
    let { open, setOpen, text = "Thanks for buying our membership", isGift = true, btnText, Page, otherPageSetting, callBack, title } = props;

    const [serviceName, setServiceName] = React.useState<any>("Gift a Support group to your friend")

    const { state }: any = useLocation();
    let Navigate = useNavigate()

    const handleClose: any = (data: any, type: any) => {
        // debugger
        if (type === "backdropClick" || "escapeKeyDown") {
            return;
        } else {
            setOpen(false)
        }

    }


    const modelClose = () => {
        // debugger
        setOpen(false)
        callBack()
    }
    return (
        <div>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >

                <Box sx={style} className="membership-congrats">
                    <IconButton sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8
                    }}>
                        <CloseIcon onClick={modelClose} />

                    </IconButton>
                    {/* <button onClick={handleCls}>Close</button> */}
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        {title || 'Thank you!'}
                    </Typography>
                    {/* <h3> {text}</h3> */}
                    <p>{parse(text)}</p>

                    {/* <Typography className="charged" id="modal-modal-description" sx={{ mt: 2 }}>
                        You will be charged only after the 7-day free trial ends.
                    </Typography> */}

                    {isGift && <button className='primary-blue-btn' onClick={() => Navigate(Page, { state: otherPageSetting })} >{btnText}</button>}
                    <button className='btn gotohome' onClick={() => Navigate("/")}>Go to home page</button>
                </Box>
            </Modal>
        </div>
    );
}
