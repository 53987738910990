import React from 'react'
import moment from 'moment';
import { CircularProgress } from '@mui/material';
import timeDiff from '../../../../Utils/calculation/timeDiff';

const ChooseDate = ({ data, setData, handleNext, value, loadingWash, setValue, setObjName }: any) => {

    const chooseDateHandler = (res: any) => {
        setValue({
            ["service_name"]: res?.name,
            ["appointment_date_time"]: res?.time,
            ["appointment_type_id"]: res?.appointmentTypeID,
            ["calendar_id"]: res?.calendarID
        })
        setObjName(res)
        handleNext()
    }
    const disPlayTime = (date: any) => {
        const Cdate = new Date(date);
        let converted_date = Cdate.toLocaleString('en-US', {
            timeZone: 'America/New_York',
        })
        return moment(converted_date).format("LT")
    }

    const displayDate = (date: any) => {
        const Cdate = new Date(date);
        let converted_date = Cdate.toLocaleString('en-US', {
            timeZone: 'America/New_York',
        })
        return moment(converted_date).format("dddd DD MMM YYYY")
    }
    function getMonthDifference(startDate: any, endDate: any) {
        return (
            endDate.getMonth() -
            startDate.getMonth() +
            12 * (endDate.getFullYear() - startDate.getFullYear())
        );
    }


    return (
        <div>
            <section className='step-container step1'>
                <div className='consult-section-heading text-center'>
                <h3 className='my-4 consult-nm'>{data?.service_name}</h3>
                    <h5 className='mb-4'>Choose an Appointment</h5>
                    <p className='mb-5'>Please use the calendar below to book an appointment on a day and time that works best for you.</p>
                </div>

                {
                    loadingWash ? <div className='loader-wash'> <CircularProgress /> </div>
                        :
                        (
                            <>
                                {Array.isArray(data?.data) && data?.data.map((res: any) => {
                                    return (<div className="consult-card-container">
                                        <div className='d-flex py-3 px-3 justify-content-between'>
                                            <div className='consult-info d-flex flex-column w-100'>
                                                <div className='time-projection-details d-flex'>
                                                    <div className='time-projection'>{timeDiff(res?.time)}</div>
                                                    <div className='availablity'> {res?.slotsAvailable} /{res?.slots} spots left</div>
                                                </div>
                                                <div className='consult-date-time d-flex justify-content-between mt-3 mb-2'>
                                                    <div className='consult-date'> {displayDate(res?.time)} </div>
                                                    <div className='consult-price'>
                                                        <span className='price'>{res?.price ? res?.price : "Free"}</span>
                                                    </div>
                                                </div>

                                                <div className='consult-tm-dtls mb-1'>
                                                    <span className='consult-time'><span className='strong'> {disPlayTime(res?.time)} EST</span>  </span>
                                                    <span className='author-name'>| by {res?.calendar}</span><br />
                                                    <span>Duration: {res?.duration} Minutes</span>
                                                </div>

                                                <div className='btn-container mt-3'>
                                                    <button className='btn primary-blue-small-btn-40' onClick={() => chooseDateHandler(res)}>Book</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    )
                                })}
                            </>
                        )
                }
            </section>
        </div>
    )
}

export default ChooseDate