import React, { useEffect, useState } from 'react'
import { Container, Row, Image, Card } from 'react-bootstrap'
import hi_icon from "../../../Assets/img/homepage/hi-green.svg";
import "./PostLogin.scss";
import { getPostLoginDataApi } from '../../../Service/postLogin';
import CourseCard from '../../../Common/CourseCard/CourseCard';
import EventCard from '../../../Common/EventCard/EventCard';
import { COURSE, EDITPROFILE, SLEEPCONSULT, SUPPORTGROUP } from '../../../Routes/RouteConstent';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getCourse, getFavCourse } from '../../../Redux/Course/CourseReducer';
import { favCoursesetState } from '../../../Utils/course/fav';
import { likeCourseSetState } from '../../../Utils/course/like';
import { getFavEvent, getUpComingEvent } from '../../../Redux/Events/EventsReducer';
import { getUserID } from '../../../Service/getLocalStorage';
import { EventFavSetSate } from '../../../Utils/Events/Fav';
import { EventLikeSetState } from '../../../Utils/Events/Like';
import profile from "../../../Assets/img/profile.png";
import { NavServiceUrl } from '../../../Utils/ConsultRoute';
import BlogCrad from '../../../Common/BlogCard/BlogCrad';
import isMebershipAndActiveCheck from '../../../Utils/membership_check';
import { toast } from 'react-toastify';
import mainHero from "../../../Assets/img/mainHero.png";
import mainHeroMobile from "../../../Assets/img/mainHeroMobile.png";
import { HeroBanner } from '../../../Common/HeroBanner';
import OnboardingVideo from './OnboardingVideo';

const PostLogin = () => {
  const [course, setCourse] = useState<any>([])
  const [event, setEvent] = useState<any>([])
  const [data, setData] = useState<any>([])
  const [onGoingCourse, setOnGoingCourse] = useState<any>([])
  const [pastEvent, setPastEvent] = useState<any>([])
  const [service, setService] = useState<any>([])
  const [blog, setBlog] = useState<any>([])

  const Navigate = useNavigate();
  const dispatch = useDispatch();

  const getData = async () => {
    try {
      let res: any = await getPostLoginDataApi()
      isMebershipAndActiveCheck(res, Navigate, toast, dispatch)
      setCourse(res?.data?.courses)
      setEvent(res?.data?.upcoming_event)
      setData(res?.data)
      setOnGoingCourse(res?.data?.ongoing_courses)
      setPastEvent(res?.data?.registered_event)
      setService(res?.data?.services)
      setBlog(res?.data?.blog)
    } catch (error: any) {

    }
  }

  useEffect(() => {
    getData()
  }, [])
  const navigateTo = (to: any) => {
    Navigate(to)
  }

  console.log("all data", service)
  const courseLikeFav = (type: any, res: any, isUpdate: boolean, isSuccess: boolean) => {
    let id = getUserID()
    if (isSuccess) {
      dispatch(getCourse({ user_id: id }))
      dispatch(getFavCourse({ user_id: id }))
      return;
    }
    if (type === "fav") {
      favCoursesetState(res, course, setCourse)
    } else if (type === "like") {
      likeCourseSetState(res, course, setCourse)
    }
  }
  const onGoingCourseFavLike = (type: any, res: any, isUpdate: boolean, isSuccess: boolean) => {
    let id = getUserID()
    if (isSuccess) {
      dispatch(getCourse({ user_id: id }))
      dispatch(getFavCourse({ user_id: id }))
      return;
    }
    if (type === "fav") {
      favCoursesetState(res, onGoingCourse, setOnGoingCourse)
    } else if (type === "like") {
      likeCourseSetState(res, onGoingCourse, setOnGoingCourse)
    }
  }

  const eventLikeFav = (type: string, crrdata: any, isUpdate: boolean, isSuccess: boolean) => {
    if (isSuccess) {
      dispatch(getUpComingEvent())
      dispatch(getFavEvent())
      return;
    }
    if (type === "fav") {
      EventFavSetSate(crrdata, event, setEvent)
    } else if (type === "like") EventLikeSetState(crrdata, event, setEvent, isUpdate)

  }

  const pastEventLikeFav = (type: string, crrdata: any, isUpdate: boolean, isSuccess: boolean) => {
    if (isSuccess) {
      dispatch(getUpComingEvent())
      dispatch(getFavEvent())
      return;
    }
    if (type === "fav") {
      EventFavSetSate(crrdata, pastEvent, setPastEvent)
    } else if (type === "like") EventLikeSetState(crrdata, pastEvent, setPastEvent, isUpdate)

  }

  const serviceNav = (prefix: string) => {
    let url = NavServiceUrl(prefix)
    Navigate(url)
  }

  return (
    <div className='page-inner-section post-login'>
      <OnboardingVideo />
      <section className="members-banner m-3 position-relative">
      <HeroBanner
          className='postLoginBanner'
          hero={mainHero}
          heroMobile={mainHeroMobile}
          title={
            <>
              <img src={hi_icon} alt="HI" />
              <h1>
                Welcome to
                <div className='strong'>
                  Nurture by NAPS<span className="sup">™</span>
                </div>
              </h1>
              <p className="text-white lh-1.5 my-4 ">
                Unlimited support, when you need it, from trusted nurses
              </p>
            </>
          }
        />
        {data?.is_stripe_membership && <div className='container'>
          <Row className='member-info my-3 my-md-5'>
            <div className='col-lg-12 col-md-12'>
              <div className='member-info-container row align-items-center'>
                <div className='col-lg-6 col-sm-12'>
                  <div className='profile-info d-flex align-items-center position-relative w-100'>
                    <div className='pp profile-pic-main d-flex position-relative align-items-center justify-content-center me-3'>
                      <div className='profile-pic-container p-2 '><div className='profile-pic' style={{ 'backgroundImage': `url(${data?.user_profile?.photo ? data?.user_profile?.photo : profile})` }}></div>
                      </div>
                    </div>
                    <div className='pe-md-3 me-md-3 pe-lg-5 me-lg-5'>
                      <h3>Hi {data?.user_profile?.name} !</h3>
                      {/* <p className='mb-1'>Complete your profile and get a free 30 minute consult with one of our nurses.</p> */}
                      <div className='edit-profile' onClick={() => Navigate(EDITPROFILE)}>Edit Profile</div>
                    </div>
                  </div>
                </div>

                {/* <div className='col-lg-6 col-sm-12'>
                  <div className='random-messages d-flex align-items-center mt-3 mt-md-0 w-100'>
                    <div>
                      <h3 className='ps-md-5 ms-md-3 me-md-3 pe-md-3 text-center text-md-start'>{data?.user_profile?.quote}</h3>
                    </div>
                  </div>
                </div> */}
                {/* <div className='d-flex align-itmes-start justify-content-between flex-column flex-md-row'>
                  <div className='profile-info d-flex align-items-center position-relative'>
                    <div className='pp d-flex position-relative align-items-center justify-content-center'>
                      <div className='profile-pic-container p-2 '><div className='profile-pic' style={{ 'backgroundImage': `url(${data?.user_profile?.photo ? data?.user_profile?.photo : profile})` }}></div>
                      </div>
                      <Box sx={{ paddingRight: 2 }} className='d-flex align-items-center justify-content-center'> <CircularProgress variant="determinate" className='profile-progress' value={87} stroke-width={2} />
                        <div className='profile-pic-container'><div className='profile-pic' style={{ 'backgroundImage': `url(${data?.user_profile?.photo ? data?.user_profile?.photo : profile})` }}></div>
                        </div>
                      </Box>

                    </div>
                    <div className='pe-md-3 me-md-3 pe-lg-5 me-lg-5'>

                      <h3>Hi {data?.user_profile?.name} !</h3>
                      <p className='mb-1'>Complete your profile and get a free 30 minute consult with one of our nurses.</p>
                      <div className='edit-profile'>Edit Profile</div>
                    </div>
                  </div>
                  <div className='random-messages d-flex align-items-center mt-3 mt-md-0'>
                    <div>
                      <h3 className='ps-md-5 ms-md-3 me-md-3 pe-md-3'>{data?.user_profile?.quote}</h3>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </Row>
        </div>}
      </section>

      <div className="non-member-content pale-bg">
        {Array.isArray(onGoingCourse) && onGoingCourse?.length > 0 && <section className='ongoing-course'>
          <Container>
            <Row>
              <div className="ongoing-course-inner col-lg-12 mt-3 mb-0 py-2 py-md-5">
                <div className="row">
                  <div className="col-lg-12">
                    <h2 className="section-heading mb-3 pt-2 text-start">Your Ongoing <span className="head-bold">Course</span></h2>
                  </div>
                </div>
                <div className="row">
                  <CourseCard data={onGoingCourse} upDateData={onGoingCourseFavLike} />
                </div>
              </div>
            </Row>
          </Container>
        </section>}

        {/* <div className='container custom-hr'>
          <div className='col-lg-12 col-md-12'>
            <hr className='grey'></hr>
          </div>
        </div> */}

        {Array.isArray(pastEvent) && pastEvent?.length > 0 && <section className='ongoing-course'>
          <Container>
            <Row>
              <div className="ongoing-course-inner col-lg-12 mt-3 mb-0">
                <div className="row">
                  <div className="col-lg-12">
                    <h2 className="section-heading mb-3 pt-2 text-start">Your <span className="head-bold">Upcoming Events</span></h2>
                  </div>
                </div>
                <EventCard data={pastEvent} upDateData={pastEventLikeFav} />
              </div>
            </Row>
          </Container>
        </section>}

        <section className='get-ready'>
          <Container>
            <Row>
              <div className="get-ready-inner col-lg-12 mt-3 mb-0 py-5">
                <div className="row">
                  <div className="col-lg-12">
                    <h2 className="section-heading mb-3 pt-2">Get ready <span className="head-bold">for Baby!</span></h2>
                  </div>
                </div>
                <div className="row">
                  <CourseCard data={course} upDateData={courseLikeFav} customButtonText="Watch Now" />
                </div>
                <div className="row mt-5">
                  <div className="col-md-12 text-center">
                    <button className="btn w-auto primary-blue-btn px-5" onClick={() => navigateTo(COURSE)}>View All</button>
                  </div>
                </div>
              </div>
            </Row>
          </Container>
        </section>

        <section className='Upcoming-event-container pt-5 pb-3 margineLR-0'>
          <Container>
            <Row>
              <div className='col-lg-12 text-center'><h2 className='section-heading'>Your <span className='head-bold'>Ongoing Events</span></h2></div>
            </Row>
            <EventCard data={event} upDateData={eventLikeFav} />
          </Container>
        </section>

        <section className='recommended-classes'>
          <Container>
            <Row>
              <div className="recommended-classes-inner col-lg-12 mt-3 mb-0 py-5">
                <div className="row">
                  <div className="col-lg-12">
                    <h2 className="section-heading mb-3 pt-2">Recommended <span className="strong">Services</span></h2>
                  </div>
                </div>
                <div className="row">
                  <div className='col-lg-4 col-md-6 mb-4'>
                    <Card className='video-container-main'>
                      <div className='video-container-inner'>
                        <Image fluid src='' className='image-fluid support-thumb' />
                      </div>
                      <Card.Body className='position-relative'>
                        <div className='d-flex align-items-center justify-content-between mb-2'>
                          <Card.Title>Support Groups</Card.Title>
                        </div>
                        <Card.Text>
                          <p className='r-service-description'>We call our support groups,survival group for a reason. These virtual support group meets 75 minutes/session for 6 session to help you make it through the different challenges of motherhood. Whether your a new, seasoned, or working parent, our nurse-led support groups create community and belonging.</p>
                        </Card.Text>
                      </Card.Body>

                      <div className="card-body">
                        <div className="d-flex justify-content-between card-footer-con">
                          <div className="">
                            <button className="buy_now btn buy-now" onClick={() => Navigate(SUPPORTGROUP)}>View more</button>
                          </div>
                        </div>
                      </div>
                    </Card>
                  </div>

                  <div className='col-lg-4 col-md-6 mb-4'>
                    <Card className='video-container-main'>
                      <div className='video-container-inner'>
                        <Image fluid src=' ' className='image-fluid sleep-consult' />
                      </div>
                      <Card.Body className='position-relative'>
                        <div className='d-flex align-items-center justify-content-between mb-2'>
                          <Card.Title>Sleep consults</Card.Title>
                        </div>
                        <Card.Text>
                          <p className='r-service-description'>If you can’t sleep, you can’t think (well you can think, just not clearly!) Let us take the thought out of it for you, and work directly with our pediatric nurse, and expert in pediatric sleep, to formulate a plan to get you and your baby sleeping better!</p>
                        </Card.Text>
                      </Card.Body>

                      <div className="card-body">
                        <div className="d-flex justify-content-between card-footer-con">
                          <div className="">
                            <button className="buy_now btn buy-now" onClick={() => Navigate(SLEEPCONSULT)}>View more</button>
                          </div>
                        </div>
                      </div>
                    </Card>
                  </div>


                  <div className='col-lg-4 col-md-6 mb-4'>
                    <Card className='video-container-main'>
                      <div className='video-container-inner'>
                        <Image fluid src=' ' className='image-fluid nurse-consult' />
                      </div>
                      <Card.Body className='position-relative'>
                        <div className='d-flex align-items-center justify-content-between mb-2'>
                          <Card.Title>Consult a Nurse</Card.Title>
                        </div>
                        <Card.Text>
                          <p className='r-service-description'>Reserve some private, 1:1 time with one of our Registered Nurses or nurse practitioners. You can talk about the challenges that are unique to you and your family during these 30-minute consultations. You can ask about anything from pregnancy to newborn care to difficult toddler behaviors.</p>
                        </Card.Text>
                      </Card.Body>

                      <div className="card-body">
                        <div className="d-flex justify-content-between card-footer-con">
                          <div className="">
                            <button className="buy_now btn buy-now" onClick={() => serviceNav("service_2")}>View more</button>
                          </div>
                        </div>
                      </div>
                    </Card>
                  </div>

                </div>
              </div>
            </Row>
          </Container>
        </section>



        {/* <section className='free-consult'>
          <Container>
            {
              data?.is_stripe_membership ? <Free30Min />
                : <LockScreen />
            }
          </Container>
        </section> */}

        <BlogCrad blog={blog} />
      </div>
    </div >
  )
}

export default PostLogin;