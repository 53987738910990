import React from "react";

import CheckboxList from "../Components/CheckboxList";
import useSubmit from "../Hooks/useSubmit";
import DateField from "../Components/DateField";
import CongratsPopUp from "../../Common/CongratsPopUp";
import { CircularProgress } from "@mui/material";

const initFields = {
  dateOfVisit: "",
  timeOfVisit: "",
  clientFirstName: "",
  clientLastName: "",
  clientDateOfBirth: "",
  babyFirstName: "",
  babyLastName: "",
  babyDateOfBirth: "",
  birthWeight: "",
  lactationConsultantFirstName: "",
  lactationConsultantLastName: "",
  pediatricianFirstName: "",
  pediatricianLastName: "",
  OBFirstName: "",
  OBLastName: "",
  reasonForVisit: "",
  preFeedWeight: "",
  postFeedWeight: "",
  oralAssessment: "",
  correctionsToLatchPositioning: "",
  planFollowingVisit: "",
  additionalNotesRelatedToVisit: "",
  recommendedReferral: "",
  recommendedProductsAmazonNaps: "",
};

const requiredFields = [
  "dateOfVisit",
  "clientFirstName",
  "clientLastName",
  "clientDateOfBirth",
  "babyFirstName",
  "babyLastName",
  "babyDateOfBirth",
  "lactationConsultantFirstName",
  "lactationConsultantLastName",
  "pediatricianFirstName",
  "pediatricianLastName",
  "OBFirstName",
  "OBLastName",
  "reasonForVisit",
  "planFollowingVisit",
];

const Lactation = () => {
  const {
    onSubmit,
    submitState,
    successModal,
    fields,
    errors,
    handleChange,
    handleChangeDate,
    handleChangeCheckbox,
    checkboxFields,
  } = useSubmit({
    formName: "NAPS Lactation Consultation Note",
    requiredFields: [...requiredFields],
    initFields: initFields,
    initCheckboxFields: {
      latchAssessment: [],
      breastAssessment: [],
      recommendations: [],
      teachingProvided: [],
      followUp: [],
      additionalOpportunitiesForLearningAndSupport: [],
    },
  });

  return (
    <div className="container">
      <CongratsPopUp
        closeModal={successModal.closeModal}
        isVisible={successModal.isVisible}
      >
        <div className="notesLandingPagesSubmitModalTitle">Success!</div>
      </CongratsPopUp>
      <h1 className="py-5">NAPS Lactation Consultation Note</h1>
      <form onSubmit={onSubmit}>
        <div className="col-lg-6 col-md-6 mb-3">
          <label>Date of Visit (required)</label>
          <DateField
            value={fields.dateOfVisit}
            onChange={handleChangeDate("dateOfVisit")}
          />
          <h4 className="error-msgtext">{errors.dateOfVisit}</h4>
        </div>

        <div className="form-group col-lg-12 col-md-12 mb-3">
          <label>Time of Visit</label>
          <input
            type="text"
            value={fields.timeOfVisit}
            name="timeOfVisit"
            onChange={handleChange}
            className="form-control"
          />
        </div>

        <label>Client Name (required)</label>
        <div className="row">
          <div className="form-group col-lg-6 col-md-6 mb-3">
            <label className="tinyLabel">First Name</label>
            <input
              type="text"
              value={fields.clientFirstName}
              name="clientFirstName"
              onChange={handleChange}
              className="form-control"
            />
            <h4 className="error-msgtext">{errors.clientFirstName}</h4>
          </div>
          <div className="form-group col-lg-6 col-md-6 mb-3">
            <label className="tinyLabel">Last Name</label>
            <input
              type="text"
              value={fields.clientLastName}
              name="clientLastName"
              onChange={handleChange}
              className="form-control"
            />
            <h4 className="error-msgtext">{errors.clientLastName}</h4>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 mb-3">
          <label>Client's Date of Birth: (required)</label>
          <DateField
            value={fields.clientDateOfBirth}
            onChange={handleChangeDate("clientDateOfBirth")}
          />
          <h4 className="error-msgtext">{errors.clientDateOfBirth}</h4>
        </div>

        <label>Baby's Name (required)</label>
        <div className="row">
          <div className="form-group col-lg-6 col-md-6 mb-3">
            <label className="tinyLabel">First Name</label>
            <input
              type="text"
              value={fields.babyFirstName}
              name="babyFirstName"
              onChange={handleChange}
              className="form-control"
            />
            <h4 className="error-msgtext">{errors.babyFirstName}</h4>
          </div>
          <div className="form-group col-lg-6 col-md-6 mb-3">
            <label className="tinyLabel">Last Name</label>
            <input
              type="text"
              value={fields.babyLastName}
              name="babyLastName"
              onChange={handleChange}
              className="form-control"
            />
            <h4 className="error-msgtext">{errors.babyLastName}</h4>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 mb-3">
          <label>Baby's Date of Birth (required)</label>
          <DateField
            value={fields.babyDateOfBirth}
            onChange={handleChangeDate("babyDateOfBirth")}
          />
          <h4 className="error-msgtext">{errors.babyDateOfBirth}</h4>
        </div>

        <div className="form-group col-lg-12 col-md-12 mb-3">
          <label>Birth Weight</label>
          <input
            type="text"
            value={fields.birthWeight}
            name="birthWeight"
            onChange={handleChange}
            className="form-control"
          />
        </div>

        <label>Lactation Consultant Name (required)</label>
        <div className="row">
          <div className="form-group col-lg-6 col-md-6 mb-3">
            <label className="tinyLabel">First Name</label>
            <input
              type="text"
              value={fields.lactationConsultantFirstName}
              name="lactationConsultantFirstName"
              onChange={handleChange}
              className="form-control"
            />
            <h4 className="error-msgtext">
              {errors.lactationConsultantFirstName}
            </h4>
          </div>
          <div className="form-group col-lg-6 col-md-6 mb-3">
            <label className="tinyLabel">Last Name</label>
            <input
              type="text"
              value={fields.lactationConsultantLastName}
              name="lactationConsultantLastName"
              onChange={handleChange}
              className="form-control"
            />
            <h4 className="error-msgtext">
              {errors.lactationConsultantLastName}
            </h4>
          </div>
        </div>

        <label>Pediatrician Name (required)</label>
        <div className="row">
          <div className="form-group col-lg-6 col-md-6 mb-3">
            <label className="tinyLabel">First Name</label>
            <input
              type="text"
              value={fields.pediatricianFirstName}
              name="pediatricianFirstName"
              onChange={handleChange}
              className="form-control"
            />
            <h4 className="error-msgtext">{errors.pediatricianFirstName}</h4>
          </div>
          <div className="form-group col-lg-6 col-md-6 mb-3">
            <label className="tinyLabel">Last Name</label>
            <input
              type="text"
              value={fields.pediatricianLastName}
              name="pediatricianLastName"
              onChange={handleChange}
              className="form-control"
            />
            <h4 className="error-msgtext">{errors.pediatricianLastName}</h4>
          </div>
        </div>

        <label>OB Name (required)</label>
        <div className="row">
          <div className="form-group col-lg-6 col-md-6 mb-3">
            <label className="tinyLabel">First Name</label>
            <input
              type="text"
              value={fields.OBFirstName}
              name="OBFirstName"
              onChange={handleChange}
              className="form-control"
            />
            <h4 className="error-msgtext">{errors.OBFirstName}</h4>
          </div>
          <div className="form-group col-lg-6 col-md-6 mb-3">
            <label className="tinyLabel">Last Name</label>
            <input
              type="text"
              value={fields.OBLastName}
              name="OBLastName"
              onChange={handleChange}
              className="form-control"
            />
            <h4 className="error-msgtext">{errors.OBLastName}</h4>
          </div>
        </div>

        <div className="form-group col-lg-12 col-md-12 mb-3">
          <label>Reason for Visit: (required)</label>
          <input
            type="text"
            value={fields.reasonForVisit}
            name="reasonForVisit"
            onChange={handleChange}
            className="form-control"
          />
          <h4 className="error-msgtext">{errors.reasonForVisit}</h4>
        </div>

        <div className="form-group col-lg-12 col-md-12 mb-3">
          <label>Pre-Feed Weight</label>
          <textarea
            value={fields.preFeedWeight}
            name="preFeedWeight"
            onChange={handleChange}
            className="form-control"
          />
        </div>

        <div className="form-group col-lg-12 col-md-12 mb-3">
          <label>Post-Feed Weight</label>
          <input
            type="text"
            value={fields.postFeedWeight}
            name="postFeedWeight"
            onChange={handleChange}
            className="form-control"
          />
        </div>

        <CheckboxList
          className="mb-3"
          mainLabel="Latch Assessment"
          name="latchAssessment"
          onChange={handleChangeCheckbox}
          value={checkboxFields.latchAssessment}
          options={[
            "Positioned belly to belly",
            "Infant body alignment",
            "Gape response present",
            "Lips flanged",
            "Rocker motion",
            "Wide angle in corner of mouth",
            "Audible swallows",
            "Pain free throughout feeding",
            "Global Health Media on Latching: https://www.youtube.com/watch?v=wjt-Ashodw8&t=6s",
          ]}
        />

        <CheckboxList
          className="mb-3"
          mainLabel="Breast Assessment"
          name="breastAssessment"
          onChange={handleChangeCheckbox}
          value={checkboxFields.breastAssessment}
          options={[
            "cracked nipples",
            "nipple pain",
            "engorgement",
            "clogged ducts",
          ]}
        />

        <div className="form-group col-lg-12 col-md-12 mb-3">
          <label>Oral Assessment</label>
          <input
            type="text"
            value={fields.oralAssessment}
            name="oralAssessment"
            onChange={handleChange}
            className="form-control"
          />
        </div>

        <div className="form-group col-lg-12 col-md-12 mb-3">
          <label>Corrections made to latch and/or positioning</label>
          <input
            type="text"
            value={fields.correctionsToLatchPositioning}
            name="correctionsToLatchPositioning"
            onChange={handleChange}
            className="form-control"
          />
        </div>

        <CheckboxList
          className="mb-3"
          mainLabel="Recommendations"
          name="recommendations"
          onChange={handleChangeCheckbox}
          value={checkboxFields.recommendations}
          options={[
            "Breastfeed 8-12 times in 24 hours",
            "Pump both breasts at the same time for 10-20 minutes",
            "Supplement with breastmilk or formula as outlined",
            "Take herbal supplements as recommended",
            "Feed baby every 2-3 hours during the day",
            "Feed baby every 3-4 hours overnight until baby has regained birth weight",
            "Apply nipple ointment after every feed",
            "Use nipple shield as recommended",
            "Other (see below)",
          ]}
        />

        <CheckboxList
          className="mb-3"
          mainLabel="Teaching provided"
          name="teachingProvided"
          onChange={handleChangeCheckbox}
          value={checkboxFields.teachingProvided}
          options={[
            "Safe storage of breastmilk (Rule of 4's: https://www.cdc.gov/breastfeeding/recommendations/handling_breastmilk.htm)",
            "Proper setup/use/cleaning of breast pump",
            "Use of nipple shield including how to wean",
            "Warning signs for baby and when to call pediatrician",
            "Warning signs for mom and when to call OB",
            "Paced bottle feeding",
            "5 s's (1. swaddle 2. side lying 3. swinging 4. shushing 5. sucking)",
            "Proper preparation of infant formula",
            "Other (see below)",
          ]}
        />

        <CheckboxList
          className="mb-3"
          mainLabel="Follow up"
          name="followUp"
          onChange={handleChangeCheckbox}
          value={checkboxFields.followUp}
          options={[
            "Follow-up appointment recommended (contact Boston NAPS to schedule)",
            "Follow-up appointment scheduled",
            "Follow-up phone call recommended (contact Boston NAPS to schedule)",
            "Follow-up phone call scheduled",
            "Outside referral made (see below)",
            "Other (see below)",
          ]}
        />

        <div className="form-group col-lg-12 col-md-12 mb-3">
          <label>Plan following this visit: (required)</label>
          <textarea
            value={fields.planFollowingVisit}
            name="planFollowingVisit"
            onChange={handleChange}
            className="form-control"
          />
          <h4 className="error-msgtext">{errors.planFollowingVisit}</h4>
        </div>

        <div className="form-group col-lg-12 col-md-12 mb-3">
          <label>Additional notes related to this visit:</label>
          <textarea
            value={fields.additionalNotesRelatedToVisit}
            name="additionalNotesRelatedToVisit"
            onChange={handleChange}
            className="form-control"
          />
        </div>

        <div className="form-group col-lg-12 col-md-12 mb-3">
          <label>Recommended referral</label>
          <textarea
            value={fields.recommendedReferral}
            name="recommendedReferral"
            onChange={handleChange}
            className="form-control"
          />
        </div>

        <div className="form-group col-lg-12 col-md-12 mb-3">
          <label>
            Recommended Products: (https://www.amazon.com/shop/nurturebynaps)
          </label>
          <textarea
            value={fields.recommendedProductsAmazonNaps}
            name="recommendedProductsAmazonNaps"
            onChange={handleChange}
            className="form-control"
          />
        </div>

        <CheckboxList
          className="mb-3"
          mainLabel="Additional Opportunities for Learning & Support: (Here a few other resources available)"
          name="additionalOpportunitiesForLearningAndSupport"
          onChange={handleChangeCheckbox}
          value={checkboxFields.additionalOpportunitiesForLearningAndSupport}
          options={[
            "Free Breastfeeding Support Group: virtual every Wednesday at 10:30am. You can register here: https://www.nurturebynaps.com/free-breastfeeding-support-group",
            'Nurture by NAPS Free 14-day Trial: Ask any folllow up questions or just parening questions in general on our 24/7 "ask a nurse" message board following your visit. Link to your trial is here: boston-naps.teachable.com/p/nurture-by-naps-membership-program-2-week-trial',
            "Unswaddled: The Truth About Parenting Podcast. Listen here: https://podcasts.apple.com/us/podcast/unswaddled/id1560213381",
          ]}
        />

        <div className="d-flex justify-content-end">
          <button className="w-auto primary-blue-btn px-5 my-5 notesLandingPagesSubmitBtn">
            {submitState.pending ? <CircularProgress size={24} /> : "SUBMIT"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default Lactation;
