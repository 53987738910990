import React from "react";

export enum PayWithEnum {
  new_card = "new_card",
  saved_card = "saved_card",
}

export type PayWith = PayWithEnum.new_card | PayWithEnum.saved_card;

interface UseChooseWayToPayProps {
  initPayWith?: PayWith;
}

const useChooseWayToPay = ({
  initPayWith = PayWithEnum.saved_card,
}: UseChooseWayToPayProps = {}) => {
  const [payWith, setPayWith] = React.useState<PayWith>(initPayWith);

  return { payWith, setPayWith };
};

export default useChooseWayToPay;
