import { useNavigate } from "react-router-dom";
import { CONSULTS, DATECONSULTS, MULTIPLECLASS, NURSECONSULTS, PREBABYBOOTCAMP, PRIVATESLEEPCONSULT, VIRTUALCLASSCONSULTS } from "../Routes/RouteConstent"
import * as serviceUrl from "../Utils/ServiceUrl";
export const getConsultRoute = (prefix: any) => {

    if (prefix === "service_1") {
        return CONSULTS
    }
    if (prefix === "service_2" || prefix === "service_3") {
        return NURSECONSULTS
    }
    if (prefix === "service_4") {
        return DATECONSULTS
    }
    if (prefix === "service_5" || prefix === "service_6" || prefix === "service_7" || prefix === "service_23" || prefix === "service_24" || prefix === "service_8") {
        return MULTIPLECLASS
    }
    if (prefix === "service_9" || prefix === "service_10" || prefix === "service_11" || prefix === "service_12") {
        return VIRTUALCLASSCONSULTS
    }
    if (prefix === "service_13" || prefix === "service_25") {
        return PREBABYBOOTCAMP
    }
    if (prefix === "service_14" || prefix === "service_17") {
        return PRIVATESLEEPCONSULT;
    }
}

export const NavServiceUrl = (prefix: string) => {

    let url: any
    switch (prefix) {
        case "service_1":
            {
                url = serviceUrl.CALL30MIN_URL
            }
            break;
        case "service_2":
            {
                url = serviceUrl.VIRTUAL_NURSE_COSNULT_URL
            }
            break;
        case "service_3":
            {
                url = serviceUrl.REMOTE_LACTATION_URL
            }
            break;
        case "service_4":
            {
                url = serviceUrl.BREASTFEEDING_SUPPORT_URL
            }
            break;
        case "service_5":
            {
                url = serviceUrl.V_MOM_S_GROUP_URL
            }
            break;
        case "service_6":
            {
                url = serviceUrl.W_MOM_S_GROUP_URL
            }
            break;
        case "service_7":
            {
                url = serviceUrl.NEW_MOM_S_GROUP_URL
            }
            break;
        case "service_23":
            {
                url = serviceUrl.PREGNANT_MOM_S_GROUP_URL
            }
            break;
        case "service_24":
            {
                url = serviceUrl.DAD_S_GROUP_URL
            }
            break;
        case "service_25":
            {
                url = serviceUrl.VETERAN_BOOTCAMP_URL
            }
            break;
        case "service_8":
            {
                url = serviceUrl.GROUP_SLEEP_CONSULT_URL
            }
            break;
        case "service_9":
            {
                url = serviceUrl.BREASTFEEDING_BASICS_URL
            }
            break;
        case "service_10":
            {
                url = serviceUrl.INFANT_CHILD_CPR_URL
            }
            break;
        case "service_11":
            {
                url = serviceUrl.NEW_BORN_URL
            }
            break;
        case "service_12":
            {
                url = serviceUrl.LABOUR_DELEVERY_URL
            }
            break;
        case "service_13":
            {
                url = serviceUrl.PREBABYBOOT_URL
            }
            break;
        case "service_25":
            {
                url = serviceUrl.PREBABYBOOT_URL
            }
            break;
        case "service_14":
            {
                url = serviceUrl.PRIVATE_SLEEP_CONSULT_URL
            }
            break;
        case "service_17":
            {
                url = serviceUrl.DAY_OF_DISCHARGE_URL
            }
            break;

        default:
            break;
    }
    return url;
}
