import React from "react";
import {
  ConsultationBasicDataForm,
  initFields,
  requiredFields,
} from "../Modules/ConsultationBasicDataForm";
import CheckboxList from "../Components/CheckboxList";
import useSubmit from "../Hooks/useSubmit";
import { CircularProgress } from "@mui/material";
import CongratsPopUp from "../../Common/CongratsPopUp";

const SixMonthsSleepConsult = () => {
  const {
    onSubmit,
    submitState,
    successModal,
    fields,
    errors,
    handleChange,
    handleChangeDate,
    handleChangeCheckbox,
    checkboxFields,
  } = useSubmit({
    formName: "Boston NAPS Sleep Consult Note (24-plus Weeks)",
    requiredFields: [...requiredFields],
    initFields: {
      ...initFields,
      customNotes: "",
    },
    initCheckboxFields: {
      bedTime: [],
      plan: [],
      sleepEnvironment: [],
      morningWakeup: [],
      naps: [],
      awakeWindows: [],
      ellenPearlOfWisdom: [],
    },
  });

  return (
    <div className="container">
      <CongratsPopUp
        closeModal={successModal.closeModal}
        isVisible={successModal.isVisible}
      >
        <div className="notesLandingPagesSubmitModalTitle">Success!</div>
      </CongratsPopUp>
      <h1 className="py-5">Boston NAPS Sleep Consult Note (24-plus Weeks)</h1>

      <form onSubmit={onSubmit}>
        <ConsultationBasicDataForm
          fields={fields}
          errors={errors}
          handleChange={handleChange}
          handleChangeDate={handleChangeDate}
        />

        <CheckboxList
          className="mb-3"
          mainLabel="Bedtime"
          name="bedTime"
          onChange={handleChangeCheckbox}
          value={checkboxFields.bedTime}
          options={[
            "You must be very consistent with this every night",
            "Have a set time and routine that you do each night",
            "Once you are in the baby’s room, wrap your routine up within 20 minutes (Ex: diaper, sleep sack, white noise, “good night, I love you”)",
            "Once you are done with the last feed or song, 1-2 minutes then OUT!",
          ]}
        />

        <CheckboxList
          className="mb-3"
          mainLabel="Plan"
          name="plan"
          onChange={handleChangeCheckbox}
          value={checkboxFields.plan}
          options={[
            "We are letting baby CIO in increments, to help them learn to self soothe and fall asleep",
            "The fewer check-ins, the less crying",
            "Timed check-ins: 5 minutes for first check-in, then 10 minutes later, then 15 minutes later, then 20 minutes later...",
            "Avoid picking up your baby",
            "When you enter the room, go in softly, quietly and say, 'It's okay, it's bedtime, goodnight, I love you.' Use soft tones, then exit the room again",
            "No longer feeding overnight",
          ]}
        />

        <CheckboxList
          className="mb-3"
          mainLabel="Sleep Environment"
          name="sleepEnvironment"
          onChange={handleChangeCheckbox}
          value={checkboxFields.sleepEnvironment}
          options={[
            "Have the nursery dark, use black out shades",
            "Use a sound machine with white noise",
            "Night lights are not necessary at this age",
          ]}
        />

        <CheckboxList
          className="mb-3"
          mainLabel="Morning Wakeup"
          name="morningWakeup"
          onChange={handleChangeCheckbox}
          value={checkboxFields.morningWakeup}
          options={[
            "Be very consistent about wake up time. Once you have a typical wake-up time, aim to stick with that",
            "No morning check-ins within 2 hours of wake time!",
            "If still crying at wake time in the morning, wait for lull in cries, then go in, turn on lights, turn off sound machine, and take them out of their room for at least 20 minutes. (This typically would only happen after the first night or two of sleep training, and then they may need a nap within 20 minutes of waking)",
            'Make a big deal when you go in the next morning! "Good morning baby! I love you! You did such a good job sleeping in your crib last night." Open the shades and let the light in. They will be smiling to see you.',
          ]}
        />

        <CheckboxList
          className="mb-3"
          mainLabel="Naps"
          name="naps"
          onChange={handleChangeCheckbox}
          value={checkboxFields.naps}
          options={[
            "Naps will fall into place, once bedtime is sorted. This is anywhere from 3-5 days/nights after sleep training, and sometimes longer for other babies, perhaps up to 2 weeks.",
            "The first nap of the day should be in the crib and is often 30-90 minutes after waking. This is your shortest wake window for the day!",
            "No check-ins for naps",
            "CIO for up to 45 minutes. If still crying at 45 minutes, wait for a lull in cry and go into room and act like they took a nap.",
            "Take them out of the room for at least 10 minutes, keep them awake, then retry the nap again.",
            "A nap must be 20+ minutes when you are working on this. If they sleep less than 20 minutes, then leave them for another 15 minutes to see if they will put themselves back to sleep. If they do, great! If they don't, the nap is over - go get them.",
            "Your second nap of the day is typically 2 hours after waking from the first nap.",
            "Your third nap of the day is typically 2-3 hours after waking from the second nap.",
          ]}
        />

        <CheckboxList
          className="mb-3"
          mainLabel="Awake Windows"
          name="awakeWindows"
          onChange={handleChangeCheckbox}
          value={checkboxFields.awakeWindows}
          options={[
            "Normal awake windows for babies 6-12 months are about 2 – 3.5 hours",
          ]}
        />

        <CheckboxList
          className="mb-3"
          mainLabel="Ellen's Pearl of Wisdom:"
          name="ellenPearlOfWisdom"
          onChange={handleChangeCheckbox}
          value={checkboxFields.ellenPearlOfWisdom}
          options={[
            "Remember, you are giving them the gift of sleep",
            "There is no amount of crying that is too much",
            "Stick with it!",
            "You have a follow up call to use within 2 weeks. Text me directly when you are ready to set that up.",
          ]}
        />

        <div className="form-group col-lg-12 col-md-12 mb-3">
          <label>Custom Notes:</label>
          <textarea
            value={fields.customNotes}
            name="customNotes"
            onChange={handleChange}
            className="form-control"
          />
        </div>

        <div className="d-flex justify-content-end">
          <button className="w-auto primary-blue-btn px-5 my-5 notesLandingPagesSubmitBtn">
            {submitState.pending ? <CircularProgress size={24} /> : "SUBMIT"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default SixMonthsSleepConsult;
