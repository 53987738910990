import React from "react";
import {
  ConsultationBasicDataForm,
  initFields,
  requiredFields,
} from "../Modules/ConsultationBasicDataForm";
import CheckboxList from "../Components/CheckboxList";
import useSubmit from "../Hooks/useSubmit";
import { CircularProgress } from "@mui/material";
import CongratsPopUp from "../../Common/CongratsPopUp";

const initLocalFields = {
  customNotes: "",
};

const initLocalCheckboxFields: {
  [key: string]: string[] | [];
} = {
  awakeWindowsAndSleepCycles: [],
  bedTime: [],
  fussItOut: [],
  daytimeSleep: [],
  tipsForGoingBackToWork: [],
};

const ThreeMonthsSleepConsult = () => {
  const {
    onSubmit,
    submitState,
    successModal,
    fields,
    errors,
    handleChange,
    handleChangeDate,
    handleChangeCheckbox,
    checkboxFields,
  } = useSubmit({
    formName: "Boston NAPS Sleep Consult Note (12-24 Weeks)",
    requiredFields: [...requiredFields],
    initFields: {
      ...initFields,
      ...initLocalFields,
    },
    initCheckboxFields: initLocalCheckboxFields,
  });

  return (
    <div className="container">
      <CongratsPopUp
        closeModal={successModal.closeModal}
        isVisible={successModal.isVisible}
      >
        <div className="notesLandingPagesSubmitModalTitle">Success!</div>
      </CongratsPopUp>
      <h1 className="py-5">Boston NAPS Sleep Consult Note (12-24 Weeks)</h1>
      <form onSubmit={onSubmit}>
        <ConsultationBasicDataForm
          fields={fields}
          errors={errors}
          handleChange={handleChange}
          handleChangeDate={handleChangeDate}
        />

        <CheckboxList
          className="mb-3"
          mainLabel="Normal Awake Windows & Sleep Cycles"
          name="awakeWindowsAndSleepCycles"
          onChange={handleChangeCheckbox}
          value={checkboxFields.awakeWindowsAndSleepCycles}
          options={[
            "Your baby is likely able to stay awake for anywhere from 1 – 2 hours at this age",
            "Aim for sleep cycles by the 2 hour mark of being awake",
            "Naps will lengthen, anywhere from 45 minutes to 2 hours (more on naps below)",
          ]}
        />

        <CheckboxList
          className="mb-3"
          mainLabel="Bedtime"
          name="bedTime"
          onChange={handleChangeCheckbox}
          value={checkboxFields.bedTime}
          options={[
            "Set the same bedtime routine for each night",
            "Allow yourself an hour of flexibility for bedtime (it might be 30 minutes sooner, or later than your set bedtime you have created)",
            "Bedtime routine should include PJs, overnight diaper, white noise, and a dark room",
            "You can do a song or book, but keep it short and sweet",
          ]}
        />

        <CheckboxList
          className="mb-3"
          mainLabel="Fuss it Out"
          name="fussItOut"
          onChange={handleChangeCheckbox}
          value={checkboxFields.fussItOut}
          options={[
            "Start putting your baby down for bed awake or drowsy, so they know they are in their crib",
            "When you go into their room, you are going to do everything to soothe them before you pick them up to feed them",
            "Shhh your baby, apply pressure to their chest, gently rock them, and offer the pacifier",
            "Use low tones and keep the environment dark with white noise when you go in and out",
            "Use timed increments: 3 minutes the first night, 5 minutes the second night, 7 minutes and so on",
          ]}
        />

        <CheckboxList
          className="mb-3"
          mainLabel="Daytime Sleep"
          name="daytimeSleep"
          onChange={handleChangeCheckbox}
          value={checkboxFields.daytimeSleep}
          options={[
            "Ensure you are doing the first nap of the day in their bassinet or crib",
            "Mimic overnight sleep with this nap: Dark, noise, swaddle or sleep sack, pacifier",
            "Life happens: It’s okay to get out and about, and your other naps can be at home or on the go",
            "Evening cat nap: Remember, the last nap of the day might be shorter and you might have a smaller awake window after this nap before bedtime, and that is okay! (Example: If your baby is awake at 6:30 p.m. from a cat nap, bedtime will still be 7:30 p.m.)",
          ]}
        />

        <CheckboxList
          className="mb-3"
          mainLabel="Tips for Going Back to Work"
          name="tipsForGoingBackToWork"
          onChange={handleChangeCheckbox}
          value={checkboxFields.tipsForGoingBackToWork}
          options={[
            "If you are using a nanny, it’s okay to give clear guidelines on your baby’s awake windows and sleep windows",
            "Give them time to adjust together",
            "If your child is going to daycare, you do not need to change your sleep environment and home to prepare them or help them transition",
            "Daycare is daycare, they will learn and adjust",
            "All transitions for childcare and back to work can take up to 4 weeks to iron out, so be patient!",
            "The best thing you can do, is remain consistent by having the same bedtime routine and time each night",
          ]}
        />

        <div className="form-group col-lg-12 col-md-12 mb-3">
          <label>Custom Notes:</label>
          <textarea
            value={fields.customNotes}
            name="customNotes"
            onChange={handleChange}
            className="form-control"
          />
        </div>

        <div className="d-flex justify-content-end">
          <button className="w-auto primary-blue-btn px-5 my-5 notesLandingPagesSubmitBtn">
            {submitState.pending ? <CircularProgress size={24} /> : "SUBMIT"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default ThreeMonthsSleepConsult;
