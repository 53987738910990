import React from 'react'
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router'
import { isLoggedInSelector } from '../Redux/user/user';

export const NeedAuthRoute = (props: any) => {
    const { Component } = props
    const isLoggedIn = useSelector(isLoggedInSelector);

    return <>
        {isLoggedIn && <Navigate to="/" replace />}
        <Component />
    </>
}
