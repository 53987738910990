import React, { useEffect, useState } from 'react'
import { Container, Row, Card, Carousel } from 'react-bootstrap';

import "../stage.scss";
import "../Stage1/Stage1.scss";

import parent_way from "../../../Assets/img/stage/janellecarmela-20.jpg";
import breastfeeding_offering from "../../../Assets/img/stage/breastfeeding-offering.jpg";
import newborn_essential from "../../../Assets/img/stage/newborn-essential.jpg";
import Labor_Delivery_Prep from "../../../Assets/img/stage/janellecarmela-166.jpg";
import Infant_Child_CPR from "../../../Assets/img/stage/Infant_&_Child_CPR.jpg";
import pregnantMSG from "../../../Assets/img/stage/pregnantMSG.jpg";
import VeteranBootcamp from "../../../Assets/img/stage/VeteranBootcamp.jpeg";
import PodcastUI from '../../../Common/PodcastUi/PodcastUI';
import LockScreen from '../../../Common/LockScreen/LockScreen';
import StreetCred from '../../../Common/streetCred/streetCred';
import { getDataFromLocalStorage } from '../../../Service/getLocalStorage';
import { getStagesDataApi } from '../../../Service/stage';
import BlogsBlock from '../../../Common/BlogCard/BlogsBlock';
import { NavServiceUrl } from '../../../Utils/ConsultRoute';
import { useNavigate } from 'react-router-dom';
import { PREBABYBOOT_URL, PREGNANT_MOM_S_GROUP_URL, VETERAN_BOOTCAMP_URL } from '../../../Utils/ServiceUrl';
import { EVENTS, LIVEWEBINAR } from '../../../Routes/RouteConstent';
import PinkContentBox from '../../../Common/PinkContentBox';
import LandingDescription from '../../../Common/LandingDescription';
import { HeroBannerHorizontal}  from '../../../Common/HeroBanner';
import hero from "../../../Assets/img/service/expectingBabyStage1.png";

const Stage1 = () => {

  const [data, setData] = useState([])
  const Navigate = useNavigate()
  const userData = getDataFromLocalStorage()
  const fetchData = async () => {
    try {
      let res: any = await getStagesDataApi({ stage: "stage_1" })
      if (res?.data?.success) setData(res?.data?.blogs)
    } catch (error: any) {

    }
  }
  useEffect(() => { fetchData() }, [])

  const serviceNavigate = (prefix: any) => {
    let url = NavServiceUrl(prefix)

    Navigate(url)
  }
  const handleNavigate = () => {
    const userData = getDataFromLocalStorage();

    if (userData) {
      Navigate(EVENTS)
    } else {
      Navigate(LIVEWEBINAR)
    }
  }
  return (
    <div className='container-fluid stage-main stage1'>
      <div className="py-3">
        <HeroBannerHorizontal
          hero={hero}
          title={
            <>
              <div style={{color: '#30426D'}}>Congrats!</div>
              <div className="strong" style={{color: '#30426D'}}>
              <div>You’re expecting</div>
                a baby
              </div>
            </>
          }
        />
      </div>
      <LandingDescription
        ySpace
        content={[
          <>
            Expecting a baby is very exciting, no question about it, but you might
            also be wondering, <span className="strong">what do I do now? </span>
            Between creating a registry, planning for the birth, looking at gear and
            baby clothes, it can all be pretty overwhelming.
          </>,
          <>
            Our programs will bring you <span className="strong">expert content</span>{" "}
            from experienced labor and delivery nurses & moms, help you{" "}
            <span className="strong">connect </span>with other expectant parents like
            you, and bring you <span className="strong">support </span>and relief as
            you navigate these new waters.
          </>,
        ]}
      />
      <Row className="pale-bg">
        <div className='col-lg-12 col-md-12 col-sm-12'>
          <PinkContentBox
            btnClick={() => Navigate(PREBABYBOOT_URL)}
            btnText="Join Now"
            imgSrc={parent_way}
            title={<>Pre Baby Bootcamp <span className='strong'> Prenatal Education Reimagined!</span></>}
            paragraph="This bootcamp is your one-stop course, where you and your partner can learn all you need to know to prep and care for your baby (and yourself), all in one weekend! This is our most popular  prenatal educational program because it’s interactive, fun (yes we said fun!) and informative.  It’s only offered 5 times a year, so don’t wait on signing up. This course is included for Nurture Members!"
          />
        </div>
        <div className='col-lg-12 col-md-12 my-3'>
          <Container>
            <Row className="justify-content-center my-md-5 my-3 stage-offering-header">
              <div className='col-lg-12 col-md-12 col-sm-12'>
                <div className="section-header text-center">
                  <h2 className="home-section-title py-0">Our<span className="strong pink"> Offerings</span></h2>
                </div>
              </div>
              <div className="col-lg-8 col-md-8 mt-2">
                <h3 className="text-center stage-description">If the Pre Baby Bootcamp doesn’t work for your schedule or expected delivery date, we also offer these<span className="strong"> one-off prenatal classes. </span></h3>
              </div>
            </Row>
          </Container>
        </div>

        <div className='col-lg-12 col-md-12'>
          <Row className='stages-specific-offering mb-5 d-none d-sm-none d-md-block d-lg-block d-xl-block'>
            <Carousel controls={false}>
              <Carousel.Item>
                <Container>
                  <Row className='align-items-stretch'>
                    <div className='col-lg-4 col-md-6 mb-4'>
                      <Card className='video-container-main'>
                        <div className='video-container-inner'>
                          <img src={breastfeeding_offering} className='image-fluid' />
                        </div>
                        <Card.Body className='position-relative'>
                          <div className='d-flex align-items-center justify-content-between mb-2'>
                            <Card.Title>Breastfeeding Basics</Card.Title>
                          </div>
                          <Card.Text>
                            <p>No matter how you feed your baby, you’re going to be doing a lot of it. This class will walk you through the different ways to feed your baby including breastfeeding, pumping, bottles, and  formula.</p>
                          </Card.Text>
                          {/* <div className='instructor-dtls w-100 d-flex flex-row'>
                                          <div className='d-flex align-items-center justify-content-center px-4'>
                                              <div className='instructor-profile me-3' style={{ 'backgroundImage': `url(../../../../Assets/img/homepage/instructor-profile.png)` }}>
                                              </div>
                                              <div className='instructor-name'>
                                                  <p className='p-0 m-0'> Jamie O’Day</p>
                                              </div>
                                          </div>
                                          <div className='d-flex align-items-center justify-content-center px-4'>
                                              <div className='instructor-profile me-3' style={{ 'backgroundImage': `url(../../../../Assets/img/homepage/instructor-profile.png)` }}>
                                              </div>
                                              <div className='instructor-name'>
                                                  <p className='p-0 m-0'> Emily Silver</p>
                                              </div>
                                          </div>
                                      </div> */}
                          {/* <hr className='grey-50' /> */}
                          <div className="d-flex justify-content-between mt-3">
                            <button className="buy_now btn buy-now" onClick={() => serviceNavigate("service_9")} >Learn More</button>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>

                    <div className='col-lg-4 col-md-6 mb-4'>
                      <Card className='video-container-main'>
                        <div className='video-container-inner'>
                          <img src={newborn_essential} className='image-fluid' />
                        </div>
                        <Card.Body className='position-relative'>
                          <div className='d-flex align-items-center justify-content-between mb-2'>
                            <Card.Title>Newborn Essentials</Card.Title>
                          </div>
                          <Card.Text>
                            <p>Learn everything you need to know to care for your baby. Like how to diaper, bathe, swaddle, soothe, and feed your baby. Plus some strategies to help with sleep, and how your partner can support you.  </p>
                          </Card.Text>
                          {/* <div className='instructor-dtls w-100 d-flex flex-row'>
                                              <div className='d-flex align-items-center justify-content-center px-4'>
                                                  <div className='instructor-profile me-3' style={{ 'backgroundImage': `url(../../../../Assets/img/homepage/instructor-profile.png)` }}>
                                                  </div>
                                                  <div className='instructor-name'>
                                                      <p className='p-0 m-0'> Jamie O’Day</p>
                                                  </div>
                                              </div>
                                              <div className='d-flex align-items-center justify-content-center px-4'>
                                                  <div className='instructor-profile me-3' style={{ 'backgroundImage': `url(../../../../Assets/img/homepage/instructor-profile.png)` }}>
                                                  </div>
                                                  <div className='instructor-name'>
                                                      <p className='p-0 m-0'> Emily Silver</p>
                                                  </div>
                                              </div>
                                          </div> */}
                          {/* <hr className='grey-50' /> */}
                          <div className="d-flex justify-content-between mt-3">
                            <button className="buy_now btn buy-now" onClick={() => serviceNavigate("service_11")} >Learn More</button>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>

                    <div className='col-lg-4 col-md-6 mb-4'>
                      <Card className='video-container-main'>
                        <div className='video-container-inner'>
                          <img src={Labor_Delivery_Prep} className='image-fluid' />
                        </div>
                        <Card.Body className='position-relative'>
                          <div className='d-flex align-items-center justify-content-between mb-2'>
                            <Card.Title>Labor and Delivery Prep</Card.Title>
                          </div>
                          <Card.Text>
                            <p>We  cover everything you need to know from what to pack in your hospital bag, to how the f!ck to know you are in labor, to how your partner can support you, pain management options, your recovery and more.  </p>
                          </Card.Text>
                          <div className="d-flex justify-content-between mt-3">
                            <button className="buy_now btn buy-now" onClick={() => serviceNavigate("service_12")}>Learn More</button>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                  </Row>
                </Container>
              </Carousel.Item>
              <Carousel.Item>
                <Container>
                  <Row className='align-items-stretch stages-specific-offering'>
                    <div className='col-lg-4 col-md-6 mb-4'>
                      <Card className='video-container-main'>
                        <div className='video-container-inner'>
                          <img src={Infant_Child_CPR} className='image-fluid' />
                        </div>
                        <Card.Body className='position-relative'>
                          <div className='d-flex align-items-center justify-content-between mb-2'>
                            <Card.Title>Infant and Child CPR</Card.Title>
                          </div>
                          <Card.Text>
                            <p>There aren’t many things scarier than worrying about your child not breathing. The key is knowing what to do, and being able to act quickly. Which is why learning these skills for an emergency  is essential. </p>
                          </Card.Text>
                          <div className="d-flex justify-content-between mt-3">
                            <button className="buy_now btn buy-now" onClick={() => serviceNavigate("service_10")}>Learn More</button>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>

                    <div className='col-lg-4 col-md-6 mb-4'>
                      <Card className='video-container-main'>
                        <div className='video-container-inner'>
                          <img src={VeteranBootcamp} className='image-fluid' />
                        </div>
                        <Card.Body className='position-relative'>
                          <div className='d-flex align-items-center justify-content-between mb-2'>
                            <Card.Title>Veteran Bootcamp</Card.Title>
                          </div>
                          <Card.Text>
                            <p>Join our crash course for expecting veteran parents! Get a refresher on labor, newborn care, sibling prep, and communication strategies for expanding families. Expert-led session for smooth transitions</p>
                          </Card.Text>

                          <div className="d-flex justify-content-between mt-3">
                            <button className="buy_now btn buy-now" onClick={() => Navigate(VETERAN_BOOTCAMP_URL)}>Learn More</button>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>

                    <div className='col-lg-4 col-md-6 mb-4'>
                      <Card className='video-container-main'>
                        <div className='video-container-inner'>
                          <img src={pregnantMSG} className='image-fluid' />
                        </div>
                        <Card.Body className='position-relative'>
                          <div className='d-flex align-items-center justify-content-between mb-2'>
                            <Card.Title>Pregnant Moms Survival Group</Card.Title>
                          </div>
                          <Card.Text>
                            <p>Connect with expecting moms due the same month as you in a 6-session series covering all things pregnancy. Join us for support and expert guidance from a NAPS registered nurse.</p>
                          </Card.Text>

                          <div className="d-flex justify-content-between mt-3">
                            <button className="buy_now btn buy-now" onClick={() => Navigate(PREGNANT_MOM_S_GROUP_URL)}>Learn More</button>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                  </Row>
                </Container>
              </Carousel.Item>
            </Carousel>
          </Row>

          <Row className='stages-specific-offering mb-5 d-block d-sm-block d-md-none d-lg-none d-xl-none'>
            <Carousel controls={false}>
              <Carousel.Item>
                <Container>
                  <Row className='align-items-stretch'>
                    <div className='col-sm-12 mb-4'>
                      <Card className='video-container-main'>
                        <div className='video-container-inner'>
                          <img src={breastfeeding_offering} className='image-fluid' />
                        </div>
                        <Card.Body className='position-relative'>
                          <div className='d-flex align-items-center justify-content-between mb-2'>
                            <Card.Title>Breastfeeding Basics</Card.Title>
                          </div>
                          <Card.Text>
                            <p>No matter how you feed your baby, you’re going to be doing a lot of it. This class will walk you through the different ways to feed your baby including breastfeeding, pumping, bottles, and  formula.</p>
                          </Card.Text>
                          <div className="d-flex justify-content-between mt-3">
                            <button className="buy_now btn buy-now" onClick={() => serviceNavigate("service_9")}>Learn More</button>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                  </Row>
                </Container>
              </Carousel.Item>
              <Carousel.Item>
                <Container>
                  <Row className='align-items-stretch'>
                    <div className='col-sm-12 mb-4'>
                      <Card className='video-container-main'>
                        <div className='video-container-inner'>
                          <img src={newborn_essential} className='image-fluid' />
                        </div>
                        <Card.Body className='position-relative'>
                          <div className='d-flex align-items-center justify-content-between mb-2'>
                            <Card.Title>Newborn Essentials</Card.Title>
                          </div>
                          <Card.Text>
                            <p>Learn everything you need to know to care for your baby. Like how to diaper, bathe, swaddle, soothe, and feed your baby. Plus some strategies to help with sleep, and how your partner can support you.  </p>
                          </Card.Text>
                          <div className="d-flex justify-content-between mt-3">
                            <button className="buy_now btn buy-now" onClick={() => serviceNavigate("service_11")}>Learn More</button>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                  </Row>
                </Container>
              </Carousel.Item>

              <Carousel.Item>
                <Container>
                  <Row className='align-items-stretch'>
                    <div className='col-sm-12 mb-4'>
                      <Card className='video-container-main'>
                        <div className='video-container-inner'>
                          <img src={Labor_Delivery_Prep} className='image-fluid' />
                        </div>
                        <Card.Body className='position-relative'>
                          <div className='d-flex align-items-center justify-content-between mb-2'>
                            <Card.Title>Labor and Delivery Prep</Card.Title>
                          </div>
                          <Card.Text>
                            <p>We  cover everything you need to know from what to pack in your hospital bag, to how the f!ck to know you are in labor, to how your partner can support you, pain management options, your recovery and more.  </p>
                          </Card.Text>

                          <div className="d-flex justify-content-between mt-3">
                            <button className="buy_now btn buy-now" onClick={() => serviceNavigate("service_12")} >Learn More</button>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                  </Row>
                </Container>
              </Carousel.Item>

              <Carousel.Item>
                <Container>
                  <Row className='align-items-stretch stages-specific-offering'>
                    <div className='col-sm-12 mb-4'>
                      <Card className='video-container-main'>
                        <div className='video-container-inner'>
                          <img src={Infant_Child_CPR} className='image-fluid' />
                        </div>
                        <Card.Body className='position-relative'>
                          <div className='d-flex align-items-center justify-content-between mb-2'>
                            <Card.Title>Infant and Child CPR</Card.Title>
                          </div>
                          <Card.Text>
                            <p>There aren’t many things scarier than worrying about your child not breathing. The key is knowing what to do, and being able to act quickly. Which is why learning these skills for an emergency  is essential. </p>
                          </Card.Text>
                          <div className="d-flex justify-content-between mt-3">
                            <button className="buy_now btn buy-now" onClick={() => serviceNavigate("service_10")}>Learn More</button>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                  </Row>
                </Container>
              </Carousel.Item>

              <Carousel.Item>
                <Container>
                  <Row>
                    <div className='col-sm-12 mb-4'>
                      <Card className='video-container-main'>
                        <div className='video-container-inner'>
                          <img src={VeteranBootcamp} className='image-fluid' />
                        </div>
                        <Card.Body className='position-relative'>
                          <div className='d-flex align-items-center justify-content-between mb-2'>
                            <Card.Title>Veteran Bootcamp</Card.Title>
                          </div>
                          <Card.Text>
                            <p>Join our crash course for expecting veteran parents! Get a refresher on labor, newborn care, sibling prep, and communication strategies for expanding families. Expert-led session for smooth transitions</p>
                          </Card.Text>

                          <div className="d-flex justify-content-between mt-3">
                            <button className="buy_now btn buy-now" onClick={() => Navigate(VETERAN_BOOTCAMP_URL)}>Learn More</button>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                  </Row>
                </Container>
              </Carousel.Item>

              <Carousel.Item>
                <Container>
                  <Row>
                    <div className='col-sm-12 mb-4'>
                      <Card className='video-container-main'>
                        <div className='video-container-inner'>
                          <img src={pregnantMSG} className='image-fluid' />
                        </div>
                        <Card.Body className='position-relative'>
                          <div className='d-flex align-items-center justify-content-between mb-2'>
                            <Card.Title>Pregnant Moms Survival Group</Card.Title>
                          </div>
                          <Card.Text>
                            <p>Connect with expecting moms due the same month as you in a 6-session series covering all things pregnancy. Join us for support and expert guidance from a NAPS registered nurse.</p>
                          </Card.Text>

                          <div className="d-flex justify-content-between mt-3">
                            <button className="buy_now btn buy-now" onClick={() => Navigate(PREGNANT_MOM_S_GROUP_URL)}>Learn More</button>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                  </Row>
                </Container>
              </Carousel.Item>
            </Carousel>
          </Row>
        </div>

        <div className='col-lg-12 col-md-12 stage-common-sec'>
          <Row>

            {!userData?.is_stripe_membership && <LockScreen />}
          </Row>

          <Row className='py-4'>
            <div className='col-lg-12 col-sm-12'>
              <PodcastUI />
            </div>
          </Row>
          <BlogsBlock news={data} />
          <Row >
            <div className='col-lg-12 col-md-12'>
              <div className='container d-none d-sm-block d-md-block d-lg-block d-xl-block'>
                <div className='row'>
                  <div className='col-lg-12 col-md-12'>
                    <hr ></hr>
                  </div>
                </div>
              </div>
            </div>
          </Row>

          <Row >
            {/* <div className='col-lg-12 col-md-12'>
              <div className='container d-none d-sm-block d-md-block d-lg-block d-xl-block'>
                <div className='row'>
                  
                </div>
              </div>
            </div> */}
          </Row>

          <StreetCred data={testimonialData} />
        </div>


        {/* </div> */}
      </Row>
    </div>
  )
}

let testimonialData = [
  {
    name: "Tim P.",
    data: "My wife and I participated in the NAPS Baby Bootcamp a few weeks ago. I can't recommend it highly enough. There's just enough break-out sessions to keep it interactive.They cover a lot of ground, in a short period of time, without it feeling rushed. Newborn behavior, diaper changing, swaddling, breastfeeding, stages of labor, when to go to the hospital, what babies look like, what's going on in the body during delivery. Soup to nuts - it was covered.The atmosphere is light and fun, which encourages a supportive atmosphere. I expected it to be cheesy and geared exclusively to the moms. But I never really felt out-of-place. It gives you that last confidence injection you need before the big day. Definitely impressed, definitely exceeded expectations - and I'll be telling my buddies about it when they start having kids."
  },
  {
    name: "Caitlin G.",
    data: "NAPS has been an amazing resource for us during pregnancy. We’ve listened to almost every podcast episode, online videos through the membership, and thoroughly enjoyed the array of topics covered in the pre baby bootcamp. We’re about a month out from our due date now and feel as prepped as we likely could be!"
  },
  {
    name: "Kavita P.",
    data: "Baby Bootcamp was amazing. They really brought in the experts from nurses, to sleep consultants, to Pelvic floor PTs. My husband love it. He’s so adamant about being an equal, active parent, and it allowed him in-depth knowledge of prepping for the baby, labor, and what to expect when the baby comes that he felt he wasn’t finding in “dad” apps"
  }
]

export default Stage1