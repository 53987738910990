import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { VERIFYEMAIL } from './RouteConstent';

const PublicRoute = ({ Component }: any) => {

    let userAuth: any = localStorage.getItem("Nurture_user_data");
    const navigate = useNavigate()

    useEffect(() => {
        if (userAuth) {
            if (userAuth?.is_email_verify === 1) {
                navigate("/")
            } 

        }
    }, [])

    return (
        <>
            <Component />
        </>
    )
}

export default PublicRoute;