import React, { useEffect, useState } from 'react'

import "./CourseLanding.scss";
import { Container, Row, Card, Image, ProgressBar } from 'react-bootstrap';
import { preCourseAPi } from '../../Service/Course';
import CourseCard from '../../Common/CourseCard/CourseCard';
import { Link, useLocation } from 'react-router-dom';
import { MEMBERSHIP_PRE_LOGIN } from '../../Routes/RouteConstent';
import LandingDescription from '../../Common/LandingDescription';
import { HeroBannerHorizontal}  from '../../Common/HeroBanner';
import hero from '../../Assets/img/course/courses-desc.png';
import useScrollToElementId from '../../Hooks/useScrollToElementId';

const CourseLanding = () => {

  const [data, setData] = useState([]);

  const getData = async () => {
    let res: any = await preCourseAPi()
    if (res?.data?.success) {
      setData(res?.data?.data)
    }
  }
  useEffect(() => {
    getData()
  }, [])

  const scrollToCourseId = useLocation().state;

  useScrollToElementId({
    prefix: 'course_card_',
    doScroll: !!data.length,
    scrollId: scrollToCourseId,
  })

  return (
    <div className='courselanding'>
      <Container fluid>
        <div className="py-3">
          <HeroBannerHorizontal
            hero={hero}
            title={
              <>
                <div>Parenting support,</div>
                on demand
                <div className="strong" style={{color: '#ca93d2'}}>
                  that’s actually <div>supportive</div>
                </div>
              </>
            }
          />
        </div>

        <LandingDescription
          ySpace
          content={[
            <>
              Looking for some quick advice? We have on-demand classes for you
              at every stage of your pregnancy or parenting journey, to address your
              most pressing needs.{" "}
              <span className="strong">View when you can; </span>{" "}
              whether it’s 2 AM with a feeding baby or 2 PM snuggled on the
              couch, we’ve got you covered.
            </>,
            <>
              Invest in your people. These courses are perfect for parents that aren't ready to dive into a
              full <Link to={MEMBERSHIP_PRE_LOGIN}>Nurture Membership</Link>{' '}
              (which provides SO.MUCH.MORE!). Check out our courses based on the parenting stage you are in to purchase the perfect course for you.
            </>,
          ]}
        />

        <Row className='pale-bg py-5'>
          <div className='col-lg-12 col-md-12'>
            <Container>
              <Row>
                <CourseCard data={data} />
              </Row>
            </Container>
          </div>
        </Row>

      </Container>
    </div>
  )
}

export default CourseLanding;