import React, { useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Logo from "../../Assets/img/login/signup.svg"
import { FORGOTPASSWORD, HOME, SIGNUP, VERIFYEMAIL } from '../../Routes/RouteConstent';
import { UserLogin } from '../../Service/userAuth';
import "./Login.scss";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CircularProgress } from '@mui/material';
import Eye from "../../Assets/img/eye.svg"
import { useDispatch } from 'react-redux';
import { getCourse, getFavCourse } from '../../Redux/Course/CourseReducer';
import { getFavEvent, getPastEventData, getUpComingEvent } from '../../Redux/Events/EventsReducer';
import { isUserLoggedInAction } from '../../Redux/user/user';
import useModalWindowControls from '../../Hooks/useModal';
import RecheckPassword from '../../Common/RecheckPassword';

const Login = () => {
    let navigate = useNavigate()
    const dispatch = useDispatch()
    let [formData, setFormData] = useState({
        email: "",
        password: ""
    })
    const [error, setError] = useState({
        email: "",
        password: ""
    })
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isPasswordShow, setIsPasswordShow] = useState<boolean>(false);
    const recheckPasswordModal = useModalWindowControls();

    const relocate = useLocation()?.state?.relocate;

    let handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let name = e.target.name;
        let value = e.target.value;
        if (name === "email") {
            setError({
                ...error,
                email: ""
            })
        }
        if (name === "password") {
            setError({
                ...error,
                password: ""
            })
        }
        setFormData({
            ...formData,
            [name]: value
        })

    }

    const toggleIsPasswordShowValue = () => {
        setIsPasswordShow(!isPasswordShow);
    };


    let submitLogin = async (e: React.FormEvent<HTMLFormElement>) => {
        try {
            e.preventDefault();
            if (!formData.email || !formData.password) {
                let errors: any = {}
                if (!formData.email) {
                    errors.email = "Email is required";
                }
                if (!formData.password) {
                    errors.password = "Password is required";
                }
                setError(errors)
                return;
            }

            setIsLoading(true)
            let res:any = await UserLogin(formData)

            if (res.status !== 200) {
                const messageData = res?.response?.data?.message;
                const message =
                  typeof messageData === "object"
                    ? res?.response?.data?.message?.[0]
                    : res?.response?.data?.message;
                toast.error(message || "Invalid login details");
                setIsLoading(false);
                return;
            }

            if (res?.data?.user_need_recheck_password) {
                setIsLoading(false);
                recheckPasswordModal.openModal({ email: formData.email, password: formData.password });
                return;
            }

            const isSucess = res.data.success;
            let msgArr = res.data.message;
            let msg = "";
            for (let i = 0; i < msgArr.length; i++) {
                msg += msgArr[i];
            }

            if (!isSucess) {
                toast.error(msg)
            }
            if (isSucess) {
                localStorage.setItem("Nurture_user_data", JSON.stringify(res?.data?.data));
                localStorage.setItem("Nurture_user_token", res?.data?.data?.token);
                const user_id = res?.data?.data.id
                const email = res?.data?.data.email
                dispatch(getCourse({ user_id: res?.data?.data?.id }))
                dispatch(getFavCourse({ user_id: res?.data?.data?.id }))
                dispatch(getPastEventData({ user_id: res?.data?.data?.id }))
                dispatch(getUpComingEvent({ user_id: res?.data?.data?.id }))
                dispatch(getFavEvent({ user_id: res?.data?.data?.id }))
                dispatch(isUserLoggedInAction(true))
                
                window.dispatchEvent(new Event("storage"))
                if (res.data.data.email_verified_at === null) {
                    navigate(VERIFYEMAIL, { state: { user_id, email } })
                } else {

                    if (relocate) {
                        navigate(relocate.path, relocate.data);
                    } else {
                        navigate(HOME);
                    }
                }

            }
            setIsLoading(false)
        } catch (error: any) {
            setIsLoading(false);
                let msgArr = error.response.data.message;
                let msg = "";
                for (let i = 0; i < msgArr.length; i++) {
                    msg += msgArr[i];
                }
                toast.error(msg)
        }
    }


    return (
        <div>
            <RecheckPassword 
                isVisible={recheckPasswordModal.isVisible} 
                closeModal={recheckPasswordModal.closeModal} 
                email={recheckPasswordModal.openModalData?.email}
                password={recheckPasswordModal.openModalData?.password}
                successCB={updateAppData({dispatch, navigate})}
            />
            <div className="container-fluid login-page">
                <div className="row justify-content-center">
                    <div className='col-lg-6 col-md-6'>
                        <div className="login-inner my-5">
                            <div className="text-center top-image">
                                <img src={Logo} alt="" />
                            </div>
                            <h1>Welcome Back!</h1>
                            <form onSubmit={submitLogin}>
                                <div className="form-group mb-3">
                                    <label>Email</label>
                                    <input type="email" value={formData.email} name="email"
                                        className={`${error.email && "error-input"} form-control`}
                                        id="exampleInputEmail1"
                                        placeholder="Type something"
                                        onChange={handleChange}
                                    />
                                    <p className="error">{error.email}</p>
                                </div>
                                <div className="form-group position-relative mb-4">
                                    <label>Password</label>
                                    <input
                                        type={isPasswordShow ? 'text' : 'password'}
                                        value={formData.password} name="password"
                                        className={`${error.password && "error-input"} form-control`}
                                        id="exampleInputPassword1"
                                        placeholder="Type something"
                                        onChange={handleChange}
                                    />
                                    <img src={Eye} alt="" onClick={toggleIsPasswordShowValue} className="eye-passimg" />
                                    <p className="error">{error.password}</p>
                                </div>
                                <button type="submit" disabled={isLoading} className="btn sign-inbtn primary-blue-btn">{isLoading ? <CircularProgress /> : "Sign In"}</button>
                                <div className="text-center forget-password">
                                    <Link to={FORGOTPASSWORD} className="strong">Forgot Password</Link>
                                </div>
                            </form>
                            <div className="text-center have-account">
                                <p className='mb-0'>Don’t have an account? <Link to={SIGNUP} className="n-signup bold">Create one</Link></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const updateAppData =
  ({ dispatch, navigate }: any) =>
  (res: any) => {
    localStorage.setItem("Nurture_user_data", JSON.stringify(res?.data?.data));
    localStorage.setItem("Nurture_user_token", res?.data?.data?.token);
    const user_id = res?.data?.data.id;
    const email = res?.data?.data.email;
    dispatch(getCourse({ user_id: res?.data?.data?.id }));
    dispatch(getFavCourse({ user_id: res?.data?.data?.id }));
    dispatch(getPastEventData({ user_id: res?.data?.data?.id }));
    dispatch(getUpComingEvent({ user_id: res?.data?.data?.id }));
    dispatch(getFavEvent({ user_id: res?.data?.data?.id }));
    dispatch(isUserLoggedInAction(true));

    window.dispatchEvent(new Event("storage"));
    if (res.data.data.email_verified_at === null) {
      navigate(VERIFYEMAIL, { state: { user_id, email } });
    } else {
      navigate(HOME);
    }
  };

export default Login
