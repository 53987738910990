import React from "react";

import "./serviceCard.scss";
import { Link } from "react-router-dom";

interface ServiceCardProps {
  title: string;
  description: string;
  src: any;
  navTo?: any;
}

const ServiceCard = ({ title, description, src, navTo= '/' }: ServiceCardProps) => (
  <div className="serviceCard">
    <div className="serviceCardOverlayTitle">Virtual Class</div>
    <img src={src} alt="labor & delivery" />
    <h3>{title}</h3>
    <p>{description}</p>
    <Link to={navTo} className="btn primary-blue-small-btn-40" >Get Started</Link>
  </div>
);

interface ServiceCardWrapper {
  children: JSX.Element | string | JSX.Element[];
}
ServiceCard.ServiceCardWrapper = ({ children }: ServiceCardWrapper) => (
  <div className="serviceCardWrapper">{children}</div>
);

export default ServiceCard;
