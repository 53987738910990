import React from "react";
import "./BestServices.scss";
import nurseOnCall from "./nurseOnCall.png";
import group from "./group.png";
import janellecarmela from "./janellecarmela.png";

import baby from "./Baby.svg";
import hi from "./Hi.svg";
import stethoscope from "./stethoscope.png";
import GiftCardDetailsModal from "../GiftCardDetailsModal";
import useModalWindowControls from "../../../Hooks/useModal";
import serviceData from "../../../Service/serviceData";

const BestServices = () => {
  const sendGiftModal = useModalWindowControls();

  const giftPrebabyBootCamp = () => {
    const data = serviceData.find(({ giftCardOptions }: any) =>
      giftCardOptions.some(({ service }: any) => service === "13")
    );

    data &&
      sendGiftModal.openModal({
        ...data.giftCardOptions[0],
        price: data.giftCardOptions[0]?.price,
        title: data.tittle || data.title,
        description: data.topics,
        type: "service",
      });
  };

  const giftConsultANurse = () => {
    const data = serviceData.find(({ giftCardOptions }: any) =>
      giftCardOptions.some(({ service }: any) => service === "2")
    );

    data &&
      sendGiftModal.openModal({
        ...data.giftCardOptions[0],
        price: data.giftCardOptions[0]?.price,
        title: data.tittle || data.title,
        description: data.topics,
        type: "service",
      });
  };

  const giftFourHoursNursingCareShift = () => {
    sendGiftModal.openModal({
      price: 380,
      title: "Daytime Care (4 hrs) ",
      description:
        "Give the gift of care, support, and sleep from our team of experienced maternal and child health registered nurses.",
      type: "service",
      service: 22,
    });
  };

  const giftSixHoursNursingCareShift = () => {
    sendGiftModal.openModal({
      price: 570,
      title: "Daytime Care (6 hrs)",
      description:
        "Give the gift of care, support, and sleep from our team of experienced maternal and child health registered nurses.",
      type: "service",
      service: 19,
    });
  };

  const giftEightHoursNursingCareShift = () => {
    sendGiftModal.openModal({
      price: 760,
      title: "Overnight Care (8 hrs)",
      description:
        "Give the gift of care, support, and sleep from our team of experienced maternal and child health registered nurses.",
      type: "service",
      service: 20,
    });
  };

  const giftTenHoursNursingCareShift = () => {
    sendGiftModal.openModal({
      price: 950,
      title: "Overnight Care (10 hrs)",
      description:
        "Give the gift of care, support, and sleep from our team of experienced maternal and child health registered nurses.",
      type: "service",
      service: 21,
    });
  };

  return (
    <>
      <GiftCardDetailsModal
        isVisible={sendGiftModal.isVisible}
        closeModal={sendGiftModal.closeModal}
        openModalData={sendGiftModal.openModalData}
      />
      <h2 className="bestServicesTitle">
        Bestselling <span className="strong">Classes & Services</span> for every
        stage:
      </h2>
      <div className="bestServicesSupport">
        <div className="bestServicesSupportAlignToEnd bestServicesSupport-a">
          <div className="bestServicesSupportImgGroupWrapper">
            <img
              className="bestServicesSupportImgGroup"
              src={group}
              alt="group"
            />
            <img
              className="bestServicesSupportImgLayer"
              src={baby}
              alt="baby"
            />
          </div>
        </div>
        <div className="bestServicesSupportAlignToStart bestServicesSupport-b">
          <div className="bestServicesSupportWrapper">
            <h3>
              Pre-Baby <br />
              Bootcamp
            </h3>
            <div>
              Give the gift of education and community! This class is the
              perfect gift for the first time expecting parents in your life
              with all of their prenatal education given to them virtually over
              one, fun filled weekend!
            </div>
            <button
              className="bestServicesBtn bestServicesBtn--yellow"
              onClick={giftPrebabyBootCamp}
            >
              Send as a gift
            </button>
          </div>
        </div>

        <div className="bestServicesSupportAlignToEnd bestServicesSupport-c">
          <div className="bestServicesSupportWrapper">
            <h3>Daytime & Overnight Nursing Care</h3>
            <div>
              Give the gift of care, support, and sleep from our team of
              experienced maternal and child health registered nurses. Choose to
              gift a 4 hour day shift or an 8 hour night shift.
            </div>
            <div className="flex">
              <button
                className="bestServicesBtn bestServicesBtn--green"
                onClick={giftFourHoursNursingCareShift}
              >
                Gift 4 hour shift
              </button>
              <button
                className="bestServicesBtn bestServicesBtn--green"
                onClick={giftSixHoursNursingCareShift}
              >
                Gift 6 hour shift
              </button>
              <button
                className="bestServicesBtn bestServicesBtn--green"
                onClick={giftEightHoursNursingCareShift}
              >
                Gift 8 hour shift
              </button>
              <button
                className="bestServicesBtn bestServicesBtn--green"
                onClick={giftTenHoursNursingCareShift}
              >
                Gift 10 hour shift
              </button>
            </div>
          </div>
        </div>
        <div className="bestServicesSupportImgWrapper bestServicesSupportAlignToStart bestServicesSupport-d">
          <div className="bestServicesSupportImgSleepWrapper">
            <img
              className="bestServicesSupportImgSleep"
              src={janellecarmela}
              alt="sleep"
            />
            <img
              className="bestServicesSupportImgLayer"
              src={stethoscope}
              alt="stethoscope"
            />
          </div>
        </div>

        <div className="bestServicesSupportAlignToEnd bestServicesSupport-e">
          <div className="bestServicesSupportImgGroupWrapper">
            <img
              className="bestServicesSupportImgGroup"
              src={nurseOnCall}
              alt="group"
            />
            <img className="bestServicesSupportImgLayer" src={hi} alt="baby" />
          </div>
        </div>
        <div className="bestServicesSupportAlignToStart bestServicesSupport-f">
          <div className="bestServicesSupportWrapper">
            <h3>Consult a  Nurse</h3>
            <div>
              A great gift for any parent, this call allows your loved one to
              have a 1:1, 30 minute session with an experienced maternal and
              child health nurse to discuss any issue or topic related to their
              pregnancy or parenting stage.
            </div>
            <button
              className="bestServicesBtn bestServicesBtn--purple"
              onClick={giftConsultANurse}
            >
              Send as a gift
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default BestServices;
