import React from "react";
import "./MagnetHero.scss";

interface Props {
  children?: JSX.Element | string | JSX.Element[];
  image: string
}

const MagnetHero = ({ children,image }: Props) => {
  return (
    <div className="magnetHeroWrapper">
      <div className="magnetHeroWrapper__curlyLayer">
        <div
          className="magnetHeroWrapper__imageLayer"
          style={{
            backgroundImage: `url("${image}")`,
          }}
        >
          <div className="magnetHeroWrapper__textWrapper">{children}</div>
          <img
            className="magnetHeroWrapper__mobileImg"
            src={image}
            alt="hero"
          />
        </div>
      </div>
    </div>
  );
};

export default MagnetHero;
