import React from "react";
import "./filter.scss";

const Radio = ({
  name,
  value,
  checked,
  onChange,
}: {
  name: string;
  value: number;
  checked: boolean;
  onChange: (value: number) => void;
}) => (
  <label className="filterModal__radio">
    <input
      type="checkbox"
      id={name}
      name={name}
      className="filterModal__input form-check-input"
      onChange={() => onChange(value)}
      value={value}
      checked={checked}
    />
    <span className="filterModal__label">{name}</span>
  </label>
);
export default Radio;
