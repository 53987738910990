import httpService from "../../Service/httpService";

export const postBuyCourse = (data?: any) => {
  return httpService.post("course/payment", data);
};

export const postBuyMembership = (data?: any) => {
  return httpService.post("stripe", data);
};

export const postBuyService = (data?: any) => {
  return httpService.post("service/payment", data);
};

export const postBuyGiftCard = (data?: any) => {
  return httpService.post("gift/payment", data);
};