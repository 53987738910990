import React from "react";
import "./GiftMemberships.scss";
import photo from "./photo.png";
import logo from "../../../Assets/img/Logo.svg";
import useModalWindowControls from "../../../Hooks/useModal";
import GiftCardDetailsModal from "../GiftCardDetailsModal";

export interface MembershipsGiftData {
  type: "membership";
  membership: string;
  price: string;
  title: string;
  description: string;
}

const GiftMemberships = () => {
  const sendGiftModal = useModalWindowControls();

  const description =
    "A Nurture by NAPS membership gives your loved one the gift of judgment free parenting support from registered nurses at their fingertips! This membership is the ultimate gift for all parents from expecting to the toddler years. You can give a month or a year of support. A year of support is the perfect gift from a grandparent, sibling, best friend, or a group of family members, colleagues, or friends!";

  return (
    <div className="GiftMembershipsMain">
      <GiftCardDetailsModal
        isVisible={sendGiftModal.isVisible}
        closeModal={sendGiftModal.closeModal}
        openModalData={sendGiftModal.openModalData}
      />
      <div className="GiftMembershipsBanner">
        <img className="GiftMembershipsLogo" src={logo} alt="logo" />
        <div className="GiftMembershipsContentWrapper">
          <img className="GiftMembershipsImage" src={photo} alt="baby care" />
          <div className="GiftMembershipsText">
            <h1>
              The Ultimate Gift for <span className="strong">Any Parent</span>
            </h1>
            <p>{description}</p>
            <div className="GiftMembershipsButtons">
              <button
                className="primary-blue-btn"
                onClick={() =>
                  sendGiftModal.openModal({
                    type: "membership",
                    membership: "1",
                    price: "149",
                    title: "Monthly Membership",
                    description,
                  } as MembershipsGiftData)
                }
              >
                Gift one month of support
              </button>
              <button
                className="primary-blue-btn"
                onClick={() =>
                  sendGiftModal.openModal({
                    type: "membership",
                    membership: "2",
                    price: "999",
                    title: "Annual Membership",
                    description,
                  } as MembershipsGiftData)
                }
              >
                Gift a year of support
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GiftMemberships;
