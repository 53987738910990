import React, { useCallback, useState } from 'react'
import { Button, Modal } from 'react-bootstrap';
import Cropper from 'react-easy-crop';
import "./imagePopup.scss";
import Slider from '@mui/material/Slider';
import getCroppedImg from './imageSetting';

const ImagePopup = ({ open, setOpen, image, setImage, setValue, value, imagebase64, setImageBase64 }: any) => {
    // const [show, setShow] = useState(false);
    // debugger
    const handleClose = () => setOpen(false);
    const handleShow = () => setOpen(true);

    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState<any>(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [rotation, setRotation] = useState(0);

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        console.log(croppedArea, croppedAreaPixels)
        setCroppedAreaPixels(croppedAreaPixels);

    }, [])
    const showCroppedImage = useCallback(async () => {
        try {
            const croppedImage: any = await getCroppedImg(
                imagebase64,
                croppedAreaPixels,
                rotation,
                image
            );
            setImageBase64(croppedImage?.url)
            setValue({
                ...value,
                ["photo"]: croppedImage?.file
            })
            setOpen(false)

        } catch (e) {
            console.error(e);
        }
    }, [croppedAreaPixels, rotation, image]);

    return (
        <>
            <Modal show={open} onHide={handleClose} className="image-crop-modal">

                <div className='image-crop'>
                    <div className='image-cropper' >

                        <Cropper
                            image={imagebase64}
                            crop={crop}
                            // rotation={rotation}
                            zoom={zoom}
                            zoomSpeed={4}
                            maxZoom={3}
                            cropShape="round"
                            zoomWithScroll={true}
                            showGrid={true}
                            aspect={4 / 4}
                            onCropChange={setCrop}
                            onZoomChange={setZoom}
                            // onRotationChange={setRotation}
                            onCropComplete={onCropComplete}

                        />
                    </div>
                    {/* <div className="ctrl">
                        <div className='label-container'>
                           <div className='label-inner'>
                           <label className='d-block mb-0'>
                                
                                <Slider
                                    value={zoom}
                                    min={1}
                                    max={3}
                                    step={0.1}
                                    aria-labelledby="zoom"
                                    onChange={(e, zoom) => setZoom(zoom)}
                                    className="range"
                                />
                            </label>
                           </div>
                        </div>
                    </div> */}
                    {/* <img src={image} /> */}
                    {/* <div className="">
                        <label>
                            Rotate
                            <Slider
                                value={rotation}
                                min={0}
                                max={360}
                                step={1}
                                aria-labelledby="rotate"
                                onChange={(e, rotation: any) => setRotation(rotation)}
                                className="range"
                            />
                        </label>
                    </div> */}
                    {/* <button onClick={showCroppedImage}>Upload image</button> */}
                </div>
                <div className="ctrl">
                    <div className='label-container'>
                        <div className='label-inner'>
                            <label className='d-block mb-0'>

                                <Slider
                                    value={zoom}
                                    min={1}
                                    max={3}
                                    step={0.1}
                                    aria-labelledby="zoom"
                                    onChange={(e, zoom) => setZoom(zoom)}
                                    className="range"
                                />
                            </label>
                        </div>
                    </div>
                </div>
                <button onClick={showCroppedImage}>Upload image</button>
            </Modal>
        </>
    )
}

export default ImagePopup;