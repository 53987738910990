import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Button, Image, ListGroup, Carousel } from 'react-bootstrap';
import "../stage.scss";
import "../Stage6/Stage6.scss";
import Sleep_Toddler_and_Preschoolers from "../../../Assets/img/stage/Sleep_Toddler_and_Preschoolers.jpg";
import Potty_Training from "../../../Assets/img/stage/Potty_Training.jpg";
import Sleep_Consultation from "../../../Assets/img/stage/Sleep_Consultation.jpg";
import Working_Moms_Survival_Group from "../../../Assets/img/stage/Working_Moms_Survival_Group.jpg";
import Infant_Child_CPR from "../../../Assets/img/stage/Infant_&_Child_CPR.jpg";
import consult_nurse from "../../../Assets/img/stage/consult-nurse.jpg";
import PodcastUI from '../../../Common/PodcastUi/PodcastUI';
import LockScreen from '../../../Common/LockScreen/LockScreen';
import StreetCred from '../../../Common/streetCred/streetCred';
import { getStagesDataApi } from '../../../Service/stage';
import { getDataFromLocalStorage } from '../../../Service/getLocalStorage';
import BlogsBlock from '../../../Common/BlogCard/BlogsBlock';
import { useNavigate } from 'react-router-dom';
import { NavServiceUrl } from '../../../Utils/ConsultRoute';
import { COURSE, COURSEVIEW, EVENTS, LIVEWEBINAR, SLEEPCONSULT } from '../../../Routes/RouteConstent';
import LandingDescription from '../../../Common/LandingDescription';
import { HeroBannerHorizontal}  from '../../../Common/HeroBanner';
import hero from '../../../Assets/img/stage/toddle-banner.png';

const Stage6 = () => {
  const [data, setData] = useState([])
  const fetchData = async () => {
    try {
      let res: any = await getStagesDataApi({ stage: "stage_6" })
      if (res?.data?.success) setData(res?.data?.blogs)
    } catch (error: any) {

    }
  }

  const Navigate = useNavigate()

  const serviceNavigate = (prefix: any) => {
    let url = NavServiceUrl(prefix)
    Navigate(url)
  }

  useEffect(() => { fetchData() }, [])

  const handleNavigate = () => {
    const userData = getDataFromLocalStorage();
    if (userData) {
      Navigate(EVENTS)
    } else {
      Navigate(LIVEWEBINAR)
    }
  }
  return (
    <div className='container-fluid stage-main stage6'>
      <div className="py-3">
        <HeroBannerHorizontal
          hero={hero}
          title={
            <>
                <div>Parenting a</div>
                <div className="strong" style={{color: '#4CC3B0'}}>
                  Toddler
                </div>
              </>
          }
        />
      </div>
      <LandingDescription
        ySpace
        content={[
          <>
            <span className='strong'>Oh, the emotions!</span>
          </>,
          <>
            As your child grows, they are starting to develop their own opinions, 
            their own thoughts, their own desires. And very often, those desires 
            will conflict with things like safety or routine. We’ll walk you through 
            this stage, to find your way, together.
          </>,
        ]}
      />

      <Row className="pale-bg">
        <div className='col-lg-12 col-md-12 my-3'>
          <Container>
            <Row className="justify-content-center my-md-5 my-3 stage-offering-header">
              <div className='col-lg-12 col-md-12 col-sm-12'>
                <div className="section-header text-center">
                  <h2 className="home-section-title py-0">Our<span className="strong pink"> Offerings</span></h2>
                </div>
              </div>
              <div className="col-lg-8 col-md-8 mt-2">
                <h3 className="text-center stage-description">Get the most out of our team of <span className='strong'>experienced nurses </span> with these services.</h3>
              </div>
            </Row>

            <Row className='stages-specific-offering mb-5 d-none d-sm-none d-md-block d-lg-block d-xl-block'>
              <div className='col-lg-12 col-md-12'>
                <Carousel controls={false}>
                  <Carousel.Item>
                    <Container>
                      <Row className='align-items-stretch'>
                        <div className='col-lg-4 col-md-6 mb-4'>
                          <Card className='video-container-main'>
                            <div className='video-container-inner'>
                              <img src={Sleep_Toddler_and_Preschoolers} className='image-fluid' />
                            </div>
                            <Card.Body className='position-relative'>
                              <div className='d-flex align-items-center justify-content-between mb-2'>
                                <Card.Title>Sleep for Toddler and Preschoolers* </Card.Title>
                              </div>
                              <Card.Text>
                                <p><span className='strong'>Bedtime battles, transitioning to a big kid bed, to nap or not to nap? We’ve got you covered! </span> This course dives into the developmental changes in sleep for toddlers,  including how to handle common scenarios.</p>
                              </Card.Text>

                              <div className="d-flex justify-content-between mt-3 card-button">
                                <button className="buy_now btn buy-now" onClick={() => Navigate(`${COURSEVIEW}sleep-for-toddlers-and-preschoolers`)}>Learn More</button>
                              </div>
                            </Card.Body>
                          </Card>
                        </div>

                        <div className='col-lg-4 col-md-6 mb-4'>
                          <Card className='video-container-main'>
                            <div className='video-container-inner'>
                              <img src={Potty_Training} className='image-fluid' />
                            </div>
                            <Card.Body className='position-relative'>
                              <div className='d-flex align-items-center justify-content-between mb-2'>
                                <Card.Title>Potty Training*</Card.Title>
                              </div>
                              <Card.Text>
                                <p>Learn the basics on how to get started when potty training, creating a routine, sticking with your plan and knowing how and when to troubleshoot. </p>
                              </Card.Text>

                              <div className="d-flex justify-content-between mt-3 card-button">
                                <button className="buy_now btn buy-now" onClick={handleNavigate}>Learn More</button>
                              </div>
                            </Card.Body>
                          </Card>
                        </div>

                        <div className='col-lg-4 col-md-6 mb-4'>
                          <Card className='video-container-main'>
                            <div className='video-container-inner'>
                              <img src={Sleep_Consultation} className='image-fluid' />
                            </div>
                            <Card.Body className='position-relative'>
                              <div className='d-flex align-items-center justify-content-between mb-2'>
                                <Card.Title>Sleep Consult*</Card.Title>
                              </div>
                              <Card.Text>
                                <p><span className='strong'> If you can’t sleep, you can’t think. And if your baby isn’t sleeping, then neither are you. </span> Our expert in pediatric sleep is here to help! We’ll make you a customized plan to get sleep on track
                                </p>
                              </Card.Text>

                              <div className="d-flex justify-content-between mt-3 card-button">
                                <button className="buy_now btn buy-now" onClick={() => Navigate(SLEEPCONSULT)}>Learn More</button>
                              </div>
                            </Card.Body>
                          </Card>
                        </div>
                      </Row>
                    </Container>
                  </Carousel.Item>
                  <Carousel.Item>
                    <Container>
                      <Row className='align-items-stretch stages-specific-offering'>
                        <div className='col-lg-4 col-md-6 mb-4'>
                          <Card className='video-container-main'>
                            <div className='video-container-inner'>
                              <img src={Working_Moms_Survival_Group} className='image-fluid' />
                            </div>
                            <Card.Body className='position-relative'>
                              <div className='d-flex align-items-center justify-content-between mb-2'>
                                <Card.Title>Working Moms’ Survival Group*</Card.Title>
                              </div>
                              <Card.Text>
                                <p><span className='strong'>Working & parenting ain’t easy.</span>{" "}
                                  This group tackles the unique challenges working moms face. Tailored to fit into your busy life, the group meets once a month, in evenings, and fosters connection & community. </p>
                              </Card.Text>
                              <div className="d-flex justify-content-between mt-3 card-button">
                                <button className="buy_now btn buy-now" onClick={() => serviceNavigate("service_6")}>Learn More</button>
                              </div>
                            </Card.Body>
                          </Card>
                        </div>

                        <div className='col-lg-4 col-md-6 mb-4'>
                          <Card className='video-container-main'>
                            <div className='video-container-inner'>
                              <img src={Infant_Child_CPR} className='image-fluid' />
                            </div>
                            <Card.Body className='position-relative'>
                              <div className='d-flex align-items-center justify-content-between mb-2'>
                                <Card.Title>Infant and Child CPR*</Card.Title>
                              </div>
                              <Card.Text>
                                <p><span className='strong'>With the introduction of more solid foods, this is a great time to book a CPR and choking refresher course!</span> Learn the steps to take if your child stops breathing or is choking in this live course. </p>
                              </Card.Text>

                              <div className="d-flex justify-content-between mt-3 card-button">
                                <button className="buy_now btn buy-now" onClick={() => serviceNavigate("service_10")}>Learn More</button>
                              </div>
                            </Card.Body>
                          </Card>
                        </div>

                        <div className='col-lg-4 col-md-6 mb-4'>
                          <Card className='video-container-main'>
                            <div className='video-container-inner'>
                              <img src={consult_nurse} className='image-fluid' />
                            </div>
                            <Card.Body className='position-relative'>
                              <div className='d-flex align-items-center justify-content-between mb-2'>
                                <Card.Title>Consult a Nurse*</Card.Title>
                              </div>
                              <Card.Text>
                                <p><span className='strong'>Need one-on-one support?</span> Talk to a NAPS maternal-child health nurse. Popular toddler topics include: challenging toddler behaviors, parental preference strategies, sibling room sharing, picky eaters.  </p>
                              </Card.Text>

                              <div className="d-flex justify-content-between mt-3 card-button">
                                <button className="buy_now btn buy-now" onClick={() => serviceNavigate("service_2")} >Learn More</button>
                              </div>
                            </Card.Body>
                          </Card>
                        </div>
                      </Row>
                    </Container>
                  </Carousel.Item>
                </Carousel>
              </div>
            </Row>

            <Row className='stages-specific-offering mb-5 d-block d-sm-block d-md-none d-lg-none d-xl-none'>
              <Carousel controls={false}>
                <Carousel.Item>
                  <Container className='g-0'>
                    <Row className='align-items-stretch g-0'>
                      <div className='col-sm-12 mb-4'>
                        <Card className='video-container-main'>
                          <div className='video-container-inner'>
                            <img src={Sleep_Toddler_and_Preschoolers} className='image-fluid' />
                          </div>
                          <Card.Body className='position-relative'>
                            <div className='d-flex align-items-center justify-content-between mb-2'>
                              <Card.Title>Sleep for Toddler and Preschoolers*</Card.Title>
                            </div>
                            <Card.Text>
                              <p><span className='strong'>Bedtime battles, transitioning to a big kid bed, to nap or not to nap? We’ve got you covered! </span> This course dives into the developmental changes in sleep for toddlers,  including how to handle common scenarios.</p>
                            </Card.Text>
                            <div className="d-flex justify-content-between mt-3 card-button">
                              <button className="buy_now btn buy-now" onClick={() => Navigate(`${COURSEVIEW}sleep-for-toddlers-and-preschoolers`)}>Learn More</button>
                            </div>
                          </Card.Body>
                        </Card>
                      </div>
                    </Row>
                  </Container>
                </Carousel.Item>

                <Carousel.Item>
                  <Container className='g-0'>
                    <Row className='align-items-stretch g-0'>
                      <div className='col-sm-12 mb-4'>
                        <Card className='video-container-main'>
                          <div className='video-container-inner'>
                            <img src={Potty_Training} className='image-fluid' />
                          </div>
                          <Card.Body className='position-relative'>
                            <div className='d-flex align-items-center justify-content-between mb-2'>
                              <Card.Title>Potty Training*</Card.Title>
                            </div>
                            <Card.Text>
                              <p><span className='strong'> Let’s ditch those diapers once and for all!</span> In this live webinar we walk you through readiness, getting started, and how to know it’s working vs. when to stop and revisit it later.</p>
                            </Card.Text>
                            <div className="d-flex justify-content-between mt-3 card-button">
                              <button className="buy_now btn buy-now" onClick={handleNavigate}>Learn More</button>
                            </div>
                          </Card.Body>
                        </Card>
                      </div>
                    </Row>
                  </Container>
                </Carousel.Item>

                <Carousel.Item>
                  <Container className='g-0'>
                    <Row className='align-items-stretch g-0'>
                      <div className='col-sm-12 mb-4'>
                        <Card className='video-container-main'>
                          <div className='video-container-inner'>
                            <img src={Sleep_Consultation} className='image-fluid' />
                          </div>
                          <Card.Body className='position-relative'>
                            <div className='d-flex align-items-center justify-content-between mb-2'>
                              <Card.Title>Sleep Consult*</Card.Title>
                            </div>
                            <Card.Text>
                              <p><span className="strong">If you can’t sleep, you can’t think. And if your baby isn’t sleeping, then neither are you.</span> Our expert in pediatric sleep is here to help! We’ll make you a customized plan to get sleep on track.</p>
                            </Card.Text>

                            <div className="d-flex justify-content-between mt-3 card-button">
                              <button className="buy_now btn buy-now" onClick={() => Navigate(SLEEPCONSULT)}>Learn More</button>
                            </div>
                          </Card.Body>
                        </Card>
                      </div>
                    </Row>
                  </Container>
                </Carousel.Item>

                <Carousel.Item>
                  <Container className='g-0'>
                    <Row className='align-items-stretch g-0'>
                      <div className='col-sm-12 mb-4'>
                        <Card className='video-container-main'>
                          <div className='video-container-inner'>
                            <img src={Working_Moms_Survival_Group} className='image-fluid' />
                          </div>
                          <Card.Body className='position-relative'>
                            <div className='d-flex align-items-center justify-content-between mb-2'>
                              <Card.Title>Working Moms’ Survival Group*</Card.Title>
                            </div>
                            <Card.Text>
                              <p><span className='strong'>Working & parenting ain’t easy.</span>{" "}
                                This group tackles the unique challenges working moms face. Tailored to fit into your busy life, the group meets once a month, in evenings, and fosters connection & community. </p>
                            </Card.Text>
                            <div className="d-flex justify-content-between mt-3 card-button">
                              <button className="buy_now btn buy-now" onClick={() => serviceNavigate("service_6")} >Learn More</button>
                            </div>
                          </Card.Body>
                        </Card>
                      </div>
                    </Row>
                  </Container>
                </Carousel.Item>

                <Carousel.Item>
                  <Container className='g-0'>
                    <Row className='align-items-stretch g-0'>
                      <div className='col-sm-12 mb-4'>
                        <Card className='video-container-main'>
                          <div className='video-container-inner'>
                            <img src={Infant_Child_CPR} className='image-fluid' />
                          </div>
                          <Card.Body className='position-relative'>
                            <div className='d-flex align-items-center justify-content-between mb-2'>
                              <Card.Title>Infant and Child CPR*</Card.Title>
                            </div>
                            <Card.Text>
                              <p><span className='strong'>With the introduction of more solid foods, this is a great time to book a CPR and choking refresher course!</span> Learn the steps to take if your child stops breathing or is choking in this live course. </p>
                            </Card.Text>

                            <div className="d-flex justify-content-between mt-3 card-button">
                              <button className="buy_now btn buy-now" onClick={() => serviceNavigate("service_10")}>Learn More</button>
                            </div>
                          </Card.Body>
                        </Card>
                      </div>
                    </Row>
                  </Container>
                </Carousel.Item>

                <Carousel.Item>
                  <Container className='g-0'>
                    <Row className='align-items-stretch g-0'>
                      <div className='col-sm-12 mb-4'>
                        <Card className='video-container-main'>
                          <div className='video-container-inner'>
                            <img src={consult_nurse} className='image-fluid' />
                          </div>
                          <Card.Body className='position-relative'>
                            <div className='d-flex align-items-center justify-content-between mb-2'>
                              <Card.Title>Consult a Nurse*</Card.Title>
                            </div>
                            <Card.Text>
                              <p><span className='strong'>Need one-on-one support?</span> Talk to a NAPS maternal-child health nurse. Popular toddler topics include: challenging toddler behaviors, parental preference strategies, sibling room sharing, picky eaters.  </p>
                            </Card.Text>

                            <div className="d-flex justify-content-between mt-3 card-button">
                              <button className="buy_now btn buy-now" onClick={() => serviceNavigate("service_2")}>Learn More</button>
                            </div>
                          </Card.Body>
                        </Card>
                      </div>
                    </Row>
                  </Container>
                </Carousel.Item>
              </Carousel>
            </Row>

          </Container>
        </div>
      </Row>

      <Row className='pale-bg'>
        <div className='col-lg-12 col-md-12 stage-common-sec'>
          <Row>
            <LockScreen />
          </Row>

          <Row className='py-4'>
            <div className='col-lg-12 col-sm-12'>
              <PodcastUI/>                            
            </div>
          </Row>

          <Row >
            <div className='col-lg-12 col-md-12'>
              <div className='container d-none d-sm-block d-md-block d-lg-block d-xl-block'>
                <div className='row'>
                  <div className='col-lg-12 col-md-12'>
                    <hr ></hr>
                  </div>
                </div>
              </div>
            </div>
          </Row>

          <BlogsBlock news={data} />

          <StreetCred data={testimonialData} />
        </div>
      </Row>

    </div>
  )
}
let testimonialData = [
  {
    name: "Cynthia P.",
    data: "The (group sleep) class was great...already on night four and thriving. I am so happy - I actually sent the NAPS page to my friend in Mexico who has been sleep deprived for over 10 months and needs a solution now.  Thank you again so much for the gift of sleep. This is the second time sleep training and I was nervous about doing both night and naps sleep training at the same time, but you encouraged us.  We are not even a week in and my baby is just sleeping so fast on his own with minimal fuss. It is unbelievable."
  },
  {
    name: "Shayna S.",
    data: "NAPS has been such a huge help in providing me a sense of comfort, confidence and community throughout all stages of my pregnancy and parenting journey. Emily and Jamie are the absolute best and offer such real and practical advice that I was able to implement and see changes right away.  Everything from breastfeeding, taking care of myself and getting my baby to sleep through the night in his crib. I've built such a great network of moms through MSG andt he starting solids workshop was wonderful. Make sure to check out their webinars too! Thank you NAPS!!"
  }
]

export default Stage6