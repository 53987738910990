import React from 'react'
import { CourseLanding, Courses, PostLogin, PreLogin } from '../Screens'
import { getDataFromLocalStorage } from '../Service/getLocalStorage'

const HomePageRender = () => {
    let userData = getDataFromLocalStorage()
    if (userData && userData?.is_email_verify != 0) {
        return <PostLogin />
    } else {
        return (
            <PreLogin />
        )
    }

}
export const CourseRender = () =>{
    let userData = getDataFromLocalStorage()
    if (userData && userData?.is_email_verify != 0) {
        return <Courses />
    } else {
        return (
            <CourseLanding />
        )
    }
}

export default HomePageRender