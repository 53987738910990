import React, { useMemo, useRef, useState } from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./messageEditor.scss";
import "quill-image-uploader/dist/quill.imageUploader.min.css";
import { uploadChatImage } from "../transport";
import { CircularProgress } from "@mui/material";
import useBlurOutside from "../../../Hooks/useBlurOutside";
import { uniqueId } from "lodash";

const ImageUploader = require("quill-image-uploader").default;
Quill.register("modules/imageUploader", ImageUploader);

const Editor = ({
  chatId,
  onSubmit,
  pending,
  disabled,
  shrinkOnFocus,
  className,
  value,
  setValue,
}: {
  chatId?: string | number;
  onSubmit?: (value: string) => void;
  pending?: boolean;
  disabled?: boolean;
  success?: boolean;
  shrinkOnFocus?: boolean;
  sticky?: boolean;
  className?: string;
  value: string;
  setValue: (v: string) => void;
}) => {
  const quillRef = useRef(null);

  const [imagesUploads, setImagesUploads] = useState<string[]>([]);

  const modules = useMemo(() => {
    return {
      toolbar: [
        [
          { header: [1, 2, 3, 4, 5, 6, false] },
          "bold",
          "underline",
          "link",
          "image",
          {
            color: colors,
          },
          {
            background: colors,
          },
        ],
      ],

      imageUploader: {
        upload: (file: File) => {
          const id = uniqueId();
          setImagesUploads((uploads) => [...uploads, id]);
          return uploadChatImage({ file, chatId })
            .then((res) => res?.data?.url)
            .finally(() => {
              setImagesUploads((uploads) => uploads.filter((v) => v !== id));
            });
        },
      },
    };
  }, [chatId]);

  const formats = useMemo(
    () => [
      "header",
      "bold",
      "underline",
      "link",
      "image",
      "color",
      "background",
    ],
    []
  );

  const [focused, setFocused] = useState(!shrinkOnFocus);
  const editorWrapper = useRef<HTMLDivElement>(null);

  useBlurOutside({
    isFocused: focused,
    onBlur: () => setFocused(!shrinkOnFocus),
    avoidRef: editorWrapper,
  });

  return (
    <div
      className={`messageEditor editor-markup-styles ${className}`}
      ref={editorWrapper}
    >
      <ReactQuill
        className={`messageEditor__editor ${
          focused ? "messageEditor__editor--focused" : ""
        }`}
        theme="snow"
        modules={modules}
        formats={formats}
        value={value}
        onChange={setValue}
        ref={quillRef}
        onFocus={() => setFocused(true)}
      />

      {onSubmit && (
        <button
          className={`messageEditor__send ${
            !!imagesUploads.length || !value || disabled || value === "<p><br></p>"
              ? "messageEditor__send--disabled"
              : ""
          }`}
          onClick={() => onSubmit(value)}
          disabled={!!imagesUploads.length || disabled || pending || !value || value === "<p><br></p>"}
        >
          {pending ? <CircularProgress size={20} /> : arrow}
        </button>
      )}
    </div>
  );
};

const colors = [
  "#4CC3B0",
  "#F57D7D",
  "#30426D",
  "#B693C8",
  "#F5CF70",
  "#FAC9C2",
  "#B46464",
  "#4D4D4D",
  "#989898",
  "#EEEEEE",
  "#FBF8EF",
  "#fff",
];

const arrow = (
  <svg
    width="30"
    height="30"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M26.5 15L35.5 24"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26.5 33L35.5 24"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.5 24H35.5"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Editor;
