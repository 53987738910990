import React from "react";
import Wrapper from "./Components/Wrapper";
import MagnetHero from "./Components/MagnetHero";
import OwnersStory from "./Components/OwnersStory";
import SendGuideForm from "./Components/SendGuideForm";
import sleep from "./assets/sleep.png";

const TipsToSleep = () => {
  return (
    <Wrapper>
      <MagnetHero image={sleep}>
        <h3>Free download!</h3>
        <p>
          Feeling overwhelmed{" "}
          <span className="strong">with your new baby?</span>
        </p>
        <h1 className="mt-2 mt-md-5">
          6 Simple Steps to <div className="teal strong">More Sleep </div>
        </h1>
      </MagnetHero>
      <SendGuideForm
        event="BabyLeadMagNew"
        description={
          <>
            Whoever said, "Sleep when the baby sleeps" clearly never had a baby.
            Parents are bombarded with "expert" advice that doesn't solve any of
            the most common baby sleep issues... at least easily!{" "}
            <span className="strong">
              So we created this free guide to equip parents with the knowledge
              and tools they need to help their baby learn to sleep, including
              how to identify the right bedtime, creating a bedtime routine, 6
              small, but proven strategies to help your baby sleep longer and
              better, and a sleep cheat sheet to help guide your daily
              (flexible!) schedule.
            </span>
          </>
        }
      />
      <OwnersStory />
    </Wrapper>
  );
};

export default TipsToSleep;
