import { RefObject, useEffect } from "react";

const useBlurOutside = ({
  avoidRef,
  isFocused,
  onBlur,
}: {
  avoidRef: RefObject<HTMLDivElement>;
  isFocused: boolean;
  onBlur: () => void;
}) => {
  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      const clickedNode = e.target as HTMLElement;
      if (!avoidRef.current?.contains(clickedNode)) {
        e.stopPropagation();
        onBlur();
      }
    };
    if (isFocused) {
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [isFocused, avoidRef, onBlur]);

  return avoidRef;
};

export default useBlurOutside;
