import React from "react";
import Wrapper from "./Components/Wrapper";
import BHHero from "./Components/BHHero";
import IWantItAll from "./Components/IWantItAll";
import JudgmentFreeSupport from "./Components/JudjmentFreeSupport";
import BHCourses from "./Components/BHCourses";
import BHBecomeMember from "./Components/BHBecomeMember";
import useBHApi from "./hooks/useBHApi";

const SitterCity = () => {
  useBHApi();

  return (
    <Wrapper.BackgroundWrapper>
      <Wrapper.Header />
      <BHHero type="SC" />
      <Wrapper>
        <IWantItAll />
        <BHCourses />
        <JudgmentFreeSupport />
        <div style={{paddingBottom: '83px'}}>
        <BHBecomeMember />
        </div>
      </Wrapper>
    </Wrapper.BackgroundWrapper>
  );
};

export default SitterCity;
