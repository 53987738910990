export const mapDateArray = (dates: { date: string }[]) =>
  dates.map((d) => new Date(`${d.date}T12:00:00+00:00`));

export const convertTimeToEst = (avTime: any) => {
  if (!avTime) return;

  let date = new Date(avTime);
  let convertTime = date.toLocaleString("en-US", {
    timeZone: "America/New_York",
  });
  return `${convertTime.slice(10, -6)} ${convertTime.slice(-2)}`;
};
