import { CircularProgress } from "@mui/material";
import React from "react";
import { Modal } from "react-bootstrap";
import "./RecheckPassword.scss";
import * as asyncState from "../../Utils/asyncState";
import { submitEmail } from "../../Service/userAuth";

const RecheckPassword = ({
  isVisible,
  closeModal,
  email,
  password,
  successCB,
}: any) => {
  const [code, setCode] = React.useState("");
  const [updateCode, setUpdateCode] = React.useState(asyncState.initState);
  const [error, setError] = React.useState("");

  const handleCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCode(e.target.value);
  };

  const submitHandler = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    let newError = "";

    if (!code) {
      newError = "Code is required";
    }

    setError(newError);

    if (!newError) {
      try {
        setUpdateCode(asyncState.pendingState);
        const res = await submitEmail({ email, code, password });

        if (res.data.success) {
          successCB(res);
        }
        closeModal();
        setUpdateCode(asyncState.successState);
      } catch (err: any) {
        const message = err.message?.includes("Request failed with status code")
          ? "Please check your code and try again"
          : err.message;
        setUpdateCode({
          ...asyncState.failedState,
          failedMessage: message,
        });
      }
    }
  };

  React.useEffect(() => {
    setUpdateCode(asyncState.initState);
  }, [code]);

  React.useEffect(() => {
    if (isVisible) {
      setUpdateCode(asyncState.initState);
      setCode("");
      setError("");
    }
  }, [isVisible]);

  return (
    <div>
      <Modal
        show={isVisible}
        onHide={closeModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="custom-modalTeal recheckPasswordMain"
      >
        <Modal.Header
          closeButton
          onClick={closeModal}
          className="border-0 pb-0 d-flex justify-content-center"
        >
          <h3>Welcome to the (new) Club!</h3>
        </Modal.Header>
        <Modal.Body className="p-0 ">
          <p>
            We are excited to launch our new website. In order to keep your
            account secure please reverify your email address to regain access
            to your account.
          </p>
        </Modal.Body>
        <Modal.Footer className="border-0 d-flex justify-content-center">
          <form
            className="recheckPasswordForm d-flex flex-column align-item-center "
            onSubmit={submitHandler}
          >
            <input
              name="code"
              className={`recheckPasswordFormItem form-control`}
              placeholder="Enter verification code"
              value={code}
              onChange={handleCodeChange}
            />
            <p className="recheckPasswordFormItem recheckPasswordFormItemError">
              {error || updateCode.failedMessage}
            </p>
            <button
              className="primary-blue-btn h50 recheckPasswordFormItem"
              type="submit"
              disabled={updateCode.pending}
            >
              {updateCode.pending ? <CircularProgress /> : "Confirm"}
            </button>
          </form>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default RecheckPassword;
