import React from 'react'
import heart_outline from "../../../Assets/img/course/heart-outline.svg";
import heart_fill from "../../../Assets/img/course/heart-fill.svg";
import Like from "../../../Assets/img/icons/like.svg";
import Liked from "../../../Assets/img/icons/liked.svg";
import { Percentage } from '../.././../Utils/calculation/percantage'
import { ProgressBar } from 'react-bootstrap';
import "../ProfileUser.scss";
import { CONSULTS, DATECONSULTS, MULTIPLECLASS, NURSECONSULTS, PREBABYBOOTCAMP, PRIVATESLEEPCONSULT, SLEEPCONSULT, SUPPORTGROUP, VIRTUALCLASSCONSULTS } from '../../../Routes/RouteConstent';
import { useNavigate } from 'react-router-dom';
import { VIRTUAL_NURSE_COSNULT_URL } from '../../../Utils/ServiceUrl';

import supportGroup from '../../../Assets/img/homepage/Support-group.jpg'
import sleepConsult from '../../../Assets/img/homepage/sleep-consult-ft.jpg'
import consultNurse from '../../../Assets/img/homepage/consult-nurse.jpg'

const SuggestConsult = ({ data }: any) => {

    const Navigate = useNavigate()

    const handleNavigate = (serValue: any) => {
        let nav = ""
        if (serValue?.service_prefix === "service_1") {
            nav = CONSULTS
        }
        if (serValue?.service_prefix === "service_2" || serValue?.service_prefix === "service_3") {
            nav = NURSECONSULTS
        }
        if (serValue?.service_prefix === "service_4") {
            nav = DATECONSULTS
        }
        if (serValue?.service_prefix === "service_5" || serValue?.service_prefix === "service_6" || serValue?.service_prefix === "service_7" || serValue?.service_prefix === "service_8" || serValue?.service_prefix === "service_23" || serValue?.service_prefix === "service_24") {
            nav = MULTIPLECLASS
        }
        if (serValue?.service_prefix === "service_9" || serValue?.service_prefix === "service_10" || serValue?.service_prefix === "service_11" || serValue?.service_prefix === "service_12") {
            nav = VIRTUALCLASSCONSULTS
        }
        if (serValue?.service_prefix === "service_13" || serValue?.service_prefix === "service_25") {
            nav = PREBABYBOOTCAMP
        }
        if (serValue?.service_prefix === "service_14") {
            nav = PRIVATESLEEPCONSULT;
        }
        if (serValue?.service_prefix === "service_17") {
            nav = PRIVATESLEEPCONSULT;
        }
        Navigate(nav, { state: { param: serValue?.service_prefix } })

    }
    return (
        <div>
            <div className='row'>
                <div className='col-lg-12 col-md-12'>
                    <h2>Consults you might be interested in</h2>
                </div>

                <div className='col-lg-12 col-md-12'>
                    <div className='row'>
                        {
                            [
                                {
                                    id: 0,
                                    image: supportGroup,
                                    navigate: SUPPORTGROUP,
                                    description:
                                        "We call our support groups,survival group for a reason. These virtual support group meets 75 minutes/session for 6 session to help you make it through the different challenges of motherhood. Whether your a new, seasoned, or working parent, our nurse-led support groups create community and belonging...",
                                    title: "Support Groups",
                                },
                                {
                                    id: 1,
                                    image: sleepConsult,
                                    navigate: VIRTUAL_NURSE_COSNULT_URL,
                                    description:
                                        "Reserve some private, 1:1 time with one of our Registered Nurses or nurse practitioners. You can talk about the challenges that are unique to you and your family during these 30-minute consultations. You can ask about anything from pregnancy to newborn care to difficult toddler...",
                                    title: "Consult a Nurse",
                                },
                                {
                                    id: 2,
                                    image: consultNurse,
                                    navigate: SLEEPCONSULT,
                                    description:
                                        "If you can’t sleep, you can’t think (well you can think, just not clearly!) Let us take the thought out of it for you, and work directly with our pediatric nurse, and expert in pediatric sleep, to formulate a plan to get you and your baby sleeping better!",
                                    title: "Sleep consults",
                                },
                            ]?.map((res: any, index: any) => (
                                <div className="col-lg-4 col-md-6 mb-4 suggested-consult" key={res.id}>
                                    <div className="video-container-main card">
                                        <div className="video-container-inner">
                                            <div className='img-container'>
                                                <img src={res?.image} />
                                            </div>

                                            <div className="tags-likes d-flex justify-content-between">
                                                <div className="tags-row">
                                                    {Array.isArray(res?.tag) && res?.tag?.map((res: any) => (<span className='tag1 tags' style={{ backgroundColor: res?.color_code }}>{res?.name}</span>))}
                                                    {/* <span className="tag3 tags">virtual class</span> */}
                                                </div>

                                            </div>
                                        </div>
                                        <div className="position-relative card-body">
                                            <div className="d-flex align-items-start justify-content-between mb-2">
                                                <div className="card-title h5">{res?.title}</div>
                                            </div>
                                            <p className="card-text">{res?.description}</p>

                                            <div className="progress-bar-container">
                                                {(res?.is_membership || res?.is_course) &&
                                                    <div className="progress">
                                                        <div role="progressbar" className="progress-bar">
                                                            <ProgressBar now={res?.course_video_progress?.progress / res?.course_video_progress?.duration * 100} />
                                                            <div className='pregress-bar-caption'>{Percentage(res?.course_video_progress?.duration, res?.course_video_progress?.progress)}% complete</div>
                                                        </div>
                                                    </div>
                                                }

                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="d-flex justify-content-between my-3">
                                                <button className="btn primary-blue-small-btn-40" onClick={() => Navigate(res.navigate)}>Book Now</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }

                    </div>
                </div>


            </div>
        </div>
    )
}

export default SuggestConsult