import ThreeMonthsSleepConsult from "./ThreeMonthsSleepConsult";
import SixMonthsSleepConsult from "./SixMonthsSleepConsult";
import NewbornSleepConsult from "./NewbornSleepConsult";
import DayOfDischarge from "./DayOfDischarge";
import Lactation from "./Lactation";
import "./styles.scss";

export const screens = {
  ThreeMonthsSleepConsult,
  SixMonthsSleepConsult,
  NewbornSleepConsult,
  DayOfDischarge,
  Lactation,
};

export const navigateLinks = {
  threeMonthsSleepConsult: "3_months_sleep_consults_note",
  sixMonthsSleepConsult: "6_months_sleep_consults_note",
  newbornSleepConsult: "newborn_sleep_consults_note",
  dayOfDischarge: "day_of_discharge_note",
  lactation: "lactation_note",
};

export const nursingNotesRoutes = [
  {
    path: navigateLinks.threeMonthsSleepConsult,
    Screen: screens.ThreeMonthsSleepConsult,
  },
  {
    path: navigateLinks.sixMonthsSleepConsult,
    Screen: screens.SixMonthsSleepConsult,
  },
  {
    path: navigateLinks.newbornSleepConsult,
    Screen: screens.NewbornSleepConsult,
  },
  {
    path: navigateLinks.dayOfDischarge,
    Screen: screens.DayOfDischarge,
  },
  {
    path: navigateLinks.lactation,
    Screen: screens.Lactation,
  },
];
