import React from 'react'
import { Container, Row } from 'react-bootstrap';
import "./aboutUs.scss";

// import promises_banner from "../../Assets/img/about/About-us-banner.jpg";
import story_featured from "../../Assets/img/about/Story-main-sec.jpg";
import jamie_pic from "../../Assets/img/about/Jamie-pic.jpg";
import emily_pic from "../../Assets/img/about/Emily-pic.jpg";
import { useNavigate } from 'react-router-dom';
import { PROMISE } from '../../Routes/RouteConstent';
import { HeroBanner}  from '../../Common/HeroBanner';
import hero from '../../Assets/img/about/about-us-banner.jpg';

const AboutUs = () => {
  const Navigate = useNavigate();

  return (
    <div>
      <Container fluid className='about-container'>
        <div className="py-3">
          <HeroBanner
            hero={hero}
          />
        </div>

        <Row no-gutter className='py-4' >
          <div className='col-lg-12 col-md-12 about-discription-section'>
            <div className='container'>
              <Row className='justify-content-center'>
                <div className='col-lg-8 col-md-8 col-sm-12'>

                  <div className='px-2 px-md-5 about_description'>
                    <p className='text-center p-0 mx-0 my-0'>
                      Imagine chatting with your best mom friend who is also a registered nurse.
                      <span className='strong'> That’s us!</span>
                    </p>
                  </div>
                </div>
              </Row>
            </div>
          </div>
        </Row>

        <Row className='py-4'>
          <div className='col-lg-12 col-md-12'>
            <Container fluid className='registered-nurse-main'>
              <Row className='justify-content-center'>
                <div className='col-lg-10 col-md-10'>
                  <Container>
                    <Row className='justify-content-center flex-column flex-md-row my-5 py-0 py-md-5'>
                      <div className='col-lg-5 col-md-5 col-sm-12 registered-nurse-imgsection '>
                        <img src={story_featured} className='img-fluid pe-4' />
                      </div>
                      <div className='col-lg-5 col-md-5 col-sm-12 registered-nurse-info mt-5 mt-md-0'>
                        <div className='d-flex flex-column'>
                          <div>
                            <h3 className='text-center text-md-start'>
                              <span className='strong'>Our Story</span>
                            </h3>
                          </div>
                          <div>
                            <p className='text-center text-md-start'>
                              Working the overnight shift in the Labor & Delivery unit, Registered Nurses Jamie O’Day and Emily Silver prepared patients for discharge after coaching and supporting them through their child’s first moments. After so frequently hearing new parents say “Can I take you home with us?!”, they took charge and started NAPS in 2011.<br /> <br />
                              After working together on the company as a side hustle for six years, Jamie and Emily realized in 2017 that they either had to ‘sh!t or get off the pot’ and follow their true calling of running NAPS full time!<br /> <br />
                              Being moms themselves, and knowing that there is no one right way to parent, Jamie and Emily built NAPS as an honest, real-life, judgment-free space with personalized support from trained medical professionals. The classes, bootcamps, podcast, as well as their signature membership service, Nurture by NAPS, are designed to help parents thrive in every stage from conception through preschool.<br /> <br />
                              Nurture by NAPS is an in-depth online learning platform that provides families with a wide array of virtual classes, video how-to’s, live Q&As, in-depth care guides, the weekly Ask a Nurse™ forum, as well as sleep and lactation consultations. <br />

                            </p>
                          </div>
                        </div>

                      </div>
                    </Row>
                  </Container>
                </div>
              </Row>
            </Container>
          </div>
        </Row>
        <Row>
          <div className='col-lg-12 col-sm-12'>
            <Container className='founder-details'>
              <Row>
                <div className='col-lg-12 col-sm-12'>
                  <Row>
                    <div className='col-lg-12 col-sm-12 text-center'>
                      <h3 className="text-center about-section-title my-3 my-md-5"> Meet <span className="strong red">Our Founders </span></h3>
                    </div>
                  </Row>

                  <Row className='about-jamie about-founder justify-content-center mb-4'>
                    <div className='col-lg-5 col-md-5 col-sm-12 order-1 order-md-0'>
                      <span className="founder-nm d-block">Jamie</span>
                      {/* <span className='founder-full-info d-block mb-2'>Jamie O’Day, BSN, RN, IBCLC</span> */}
                      <p className='p-0 m-0 d-block'>Jamie is most passionate about helping families cut through all the bullsh!t and judgment and find the way that works best for you. You can always count on Jamie to give you the right medical answer and to help you feel empowered and confident with honest, real-life advice based on her own experiences as a nurse and as a mother to three young girls.
                        Refusing to take the traditional nursing path by starting in General Medicine, Jamie fought hard to find a job that fulfilled her passion of working with expecting mothers in Labor & Delivery, where she met NAPS co-founder Emily Silver.
                        Jamie graduated from Villanova University and is a Registered Nurse and International Board Certified Lactation Consultant (IBCLC). She lives in South Boston, MA with her husband, James, and her daughters Cate (age 7), Mackenzie (age 6), and Camila (age 3).
                      </p>
                    </div>
                    <div className='col-lg-5 col-md-5 col-sm-12 order-0 order-md-1 mb-4'>
                      <div className='founder-pic'>
                        <img src={jamie_pic} className="img-fluid me-0 me-md-5" />
                      </div>
                    </div>
                  </Row>

                  <Row className='about-emily about-founder justify-content-center my-5 pt-0 pt-md-3'>
                    <div className='col-lg-5 col-md-5 col-sm-12 me-0 me-md-5'>
                      <div className='founder-pic'>
                        <img src={emily_pic} className="img-fluid" />
                      </div>
                    </div>
                    <div className='col-lg-5 col-md-5 col-sm-12 mt-4 mt-md-0'>
                      <span className="founder-nm d-block">Emily</span>
                      {/* <span className='founder-full-info d-block'>Jamie O’Day, BSN, RN, IBCLC</span> */}
                      <p className='p-0 m-0 d-block'>
                        Emily’s passion for educating and supporting families stems from her personal experience dealing with loss. She lost a sister to SIDS (Sudden Infant Death Syndrome), has had multiple miscarriages, and has dealt with the anxiety and the stigma that comes with taking medication while pregnant and breastfeeding. You can be assured that taboo topics are just not taboo for Emily!
                        Emily began her career in General Medicine before working as a Labor & Delivery nurse, where she met NAPS co-founder Jamie O’Day. While working in Labor & Delivery, Emily went back to school to get her master’s degree in nursing and become a Family Nurse Practitioner. She practiced as a Nurse Practitioner in an OBGYN office for several years before running NAPS full time with Jamie. Emily best supports families through NAPS by pulling from her vast medical background, and her own experiences with pregnancy and parenting as a mother of three girls.
                        After graduating from Boston College, Emily received her graduate degree from UMass Boston. Emily is a Family Nurse Practitioner and International Board Certified Lactation Consultant (IBCLC). She lives in Charlestown, MA with her husband, AJ, three daughters Grace (age 7), Madelyn (age 5), Eloise (age 1), and dog Maisey (age 9).

                      </p>
                    </div>
                  </Row>
                </div>
              </Row>
            </Container>
          </div>
          </Row>
          <Row>
                  <div className='col-lg-12 col-sm-12'>
                      <Container className='founder-details'>
                          <Row>
                              <div className='col-lg-12 col-sm-12'>
                                <Row>
                                    <div className='col-lg-12 col-sm-12 text-center'>
                                      <h3 className="text-center about-section-title my-3 my-md-5"> Meet <span className="strong red">Our Founders </span></h3>
                                    </div>
                                </Row>

                                <Row className='about-jamie about-founder justify-content-center mb-4'>
                                    <div className='col-lg-5 col-md-5 col-sm-12 order-1 order-md-0'>
                                        <span className="founder-nm d-block">Jamie</span>
                                        
                                        <p className='p-0 m-0 d-block'>Jamie is most passionate about helping families cut through all the bullsh!t and judgment and find the way that works best for you. You can always count on Jamie to give you the right medical answer and to help you feel empowered and confident with honest, real-life advice based on her own experiences as a nurse and as a mother to three young girls.
                                        Refusing to take the traditional nursing path by starting in General Medicine, Jamie fought hard to find a job that fulfilled her passion of working with expecting mothers in Labor & Delivery, where she met NAPS co-founder Emily Silver.
                                        Jamie graduated from Villanova University and is a Registered Nurse and International Board Certified Lactation Consultant (IBCLC). She lives in South Boston, MA with her husband, James, and her daughters Cate (age 7), Mackenzie (age 6), and Camila (age 3).
                                        </p>
                                    </div>
                                    <div className='col-lg-5 col-md-5 col-sm-12 order-0 order-md-1 mb-4'>
                                        <div className='founder-pic'>
                                          <img src={jamie_pic} className="img-fluid me-0 me-md-5" />
                                        </div>
                                    </div>
                                </Row>

                                  <Row className='about-emily about-founder justify-content-center my-5 pt-0 pt-md-3'>
                                    <div className='col-lg-5 col-md-5 col-sm-12 me-0 me-md-5'>
                                        <div className='founder-pic'>
                                            <img src={emily_pic} className="img-fluid" />
                                        </div>
                                    </div>
                                    <div className='col-lg-5 col-md-5 col-sm-12 mt-4 mt-md-0'>
                                        <span className="founder-nm d-block">Emily</span>
                                        <p className='p-0 m-0 d-block'>
                                            Emily’s passion for educating and supporting families stems from her personal experience dealing with loss. She lost a sister to SIDS (Sudden Infant Death Syndrome), has had multiple miscarriages, and has dealt with the anxiety and the stigma that comes with taking medication while pregnant and breastfeeding. You can be assured that taboo topics are just not taboo for Emily!
                                            Emily began her career in General Medicine before working as a Labor & Delivery nurse, where she met NAPS co-founder Jamie O’Day. While working in Labor & Delivery, Emily went back to school to get her master’s degree in nursing and become a Family Nurse Practitioner. She practiced as a Nurse Practitioner in an OBGYN office for several years before running NAPS full time with Jamie. Emily best supports families through NAPS by pulling from her vast medical background, and her own experiences with pregnancy and parenting as a mother of three girls.
                                            After graduating from Boston College, Emily received her graduate degree from UMass Boston. Emily is a Family Nurse Practitioner and International Board Certified Lactation Consultant (IBCLC). She lives in Charlestown, MA with her husband, AJ, three daughters Grace (age 7), Madelyn (age 5), Eloise (age 1), and dog Maisey (age 9).

                                        </p>
                                    </div>
                                  </Row>
                              </div>
                          </Row>
                      </Container>
                  </div>
                  <Row className='py-4' >
                                    <div className='col-lg-12 col-md-12 '>
                                      <div className='container'>
                                        <Row className='curious-main'>
                                          <div className='col-lg-12'>
                                            <div className='read-story w-100 py-4 py-md-5 px-2 px-md-5 mb-5'>
                                              <div className='d-flex justify-content-center align-items-center flex-column mx-auto px-0 px-md-5 sec-caption'>
                                                  <h2>Still curious? <span className='head-bold'>Read about our promise

</span></h2>
                                                  {/* <p className='mb-0'>Complete your profile and get a free 30 minute consult with one of our nurses.
                                                  </p> */}
                        <button className="btn primary-blue-small-btn-40 mt-1 mt-md-4" onClick={() => Navigate(PROMISE)}>Read our promise</button>
                      </div>
                    </div>
                  </div>
                </Row>
              </div>
            </div>
          </Row>
        </Row>
      </Container>
    </div>
  )
}

export default AboutUs;