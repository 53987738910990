import React, { useEffect, useRef } from 'react'
import NavDropdown from 'react-bootstrap/NavDropdown';
import * as url from "../../Utils/ServiceUrl";
import "./Header.scss";
import Logo from "../../Assets/img/Logo.svg";
import LogoPreLogin from "../../Assets/img/Logo_preLogin.svg";
import { useNavigate, Link } from "react-router-dom";
import { useState } from 'react';
import { HOME, LOGIN, EVENTS, PROMISE } from '../../Routes/RouteConstent';
import * as path from '../../Routes/RouteConstent';
import ProfileMenu from './ProfileMenu';
import { getUserProfileEdit } from '../../Service/update_profile';
import useWindowDimensions from '../../Hooks/useViewWidth';
import { FiberManualRecord } from '@mui/icons-material';
import { isLoggedInSelector } from "../../Redux/user/user";
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { getNewMessages, newMessagesSelector } from '../../Redux/new-messages/newMessages';

const Header = () => {
    const isUserLoggedIn = useSelector(isLoggedInSelector);

    let navigate = useNavigate()
    const navRef2 = useRef<any>(null)

    let userToken: any = localStorage.getItem("Nurture_user_token");
    let user: any = localStorage.getItem("Nurture_user_data");
    user = JSON.parse(user)
    let is_email: any = localStorage.getItem("Nurture_is_email_verify")
    is_email = JSON.parse(is_email)

    const [profileData, setProfileData] = useState<any>({})
    const [viewData, setViewData] = useState<any>({})

    const updateTabs = () => {
        let userData: any = localStorage.getItem("Nurture_user_data");
        userData = JSON.parse(userData)

        if (userData?.is_stripe_membership === 1) {
            setViewData({
                ...viewData,
                membership: false
            })
        } else {
            setViewData({
                ...viewData,
                membership: true
            })
        }

    }
    const navigationLogin = () => {
        navRef2.current.click()
        navigate(LOGIN)
    }

    const getProfileData = async () => {
        try {

            let id: any = { user_id: user?.id }
            let res: any = await getUserProfileEdit(id)
            setProfileData(res?.data?.about_you)
        } catch (err) {
            console.log(err)
        }
    }
    useEffect(() => {
        // debugger
        getProfileData()
        updateTabs()
    }, [])


    React.useEffect(() => {
        window.addEventListener('storage', () => {
            updateTabs()
        })
    }, []);

    const memberBtn = () => {
        if (!user) {
            return true;
        }
        if (user) {
            if (user.is_stripe_membership !== 1) {
                return true;
            }
        }
        return false;
    }

    const closeNav = () => {
        let documents: any = document.getElementById("navbarSupportedContent")
        if (documents?.classList) documents.classList.remove("show");

    }

    const stopClose = (e: any) => {
        if (window?.screen?.width < 990) {
            e.stopPropagation()
        }
    }

    const navigateAndClose = (path: string, state?: {}) => {
        navigate(path, state);
        closeNav();
    };

    const dispatch = useDispatch();
    useEffect(() => {
        if (isUserLoggedIn) {
            dispatch(getNewMessages());
            const interval = setInterval(
                () => dispatch(getNewMessages()),
                10000
            );
            return () => clearInterval(interval);
        }
    }, [isUserLoggedIn, dispatch]);

    const newMessages = useSelector(newMessagesSelector);

    const nav = <>
        <ul className="navbar-nav ml-auto" >

            <li className="nav-item active" data-toggle="collapse" data-target=".navbar-collapse">
                <Link to={PROMISE} className="nav-link" onClick={closeNav} >Our Promise</Link>
            </li>

            {(!userToken || !user) && <li className="nav-item" onClick={stopClose}>
                <NavDropdown title=" Browse by Stage" id="navbarScrollingDropdown" >
                    <NavDropdown.Item onClick={() => navigateAndClose(path.STAGE1)}>Expecting</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item onClick={() => navigateAndClose(path.STAGE2)}>0-3 Months</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item onClick={() => navigateAndClose(path.STAGE3)}>3-6 Months</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item onClick={() => navigateAndClose(path.STAGE4)}>6-12 Months</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item onClick={() => navigateAndClose(path.STAGE5)}>12-24 Months</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item onClick={() => navigateAndClose(path.STAGE6)}>2-3 Years</NavDropdown.Item>
                </NavDropdown>
            </li>}

            <li className="nav-item" onClick={stopClose}>
                <NavDropdown
                    title={(userToken || user) ? "Book a Service" : "Browse by Service"}
                    id="navbarScrollingDropdown"
                >
                    <NavDropdown.Item onClick={() => navigateAndClose(path.STAGE1)}>
                        Prenatal Classes
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown title="Consults" id="navbarScrollingDropdown2">
                        <NavDropdown.Item className='consultSubLink' onClick={() => navigateAndClose(path.LACTATIONCONSULT)}>
                            <span className='consultSubLink'>Lactation Consults</span>
                        </NavDropdown.Item>
                        <NavDropdown.Item onClick={() => navigateAndClose(path.SLEEPCONSULT)}>
                            <span className='consultSubLink'>Sleep Consultations</span>
                        </NavDropdown.Item>
                        <NavDropdown.Item onClick={() => navigateAndClose(url.VIRTUAL_NURSE_COSNULT_URL, { param: "service_2" })}>
                            <span className='consultSubLink'>Consult a Nurse</span>
                        </NavDropdown.Item>
                    </NavDropdown>

                    <NavDropdown.Divider />
                    <NavDropdown.Item onClick={() => navigateAndClose(path.SUPPORTGROUP)}>
                        Support Groups
                    </NavDropdown.Item>

                    <NavDropdown.Divider />
                    <NavDropdown.Item onClick={() => navigateAndClose(path.DAYTIMEOVERNIGHT, { param: "service_1" })}>
                        Daytime and Overnight Care
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item onClick={() => navigateAndClose(path.CRPROGRAMS)}>
                        Corporate Program
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item onClick={() => navigateAndClose(path.GIFTCARD)}>
                        Gift Cards
                    </NavDropdown.Item>
                </NavDropdown>

            </li>

            <li className="nav-item"><Link to={path.COURSE} className="nav-link" onClick={closeNav} >Courses</Link></li>

            {(userToken || user) && <li className="nav-item">
                <Link className="nav-link" onClick={closeNav} to={EVENTS}>Event Calendar</Link>
            </li>}

            {(!userToken || !user) && <li>
                <Link to={path.LIVEWEBINAR} onClick={closeNav} className="nav-link" >Event Calendar</Link>
            </li>}

            {(userToken || user) && <li className="nav-item">
                <Link
                    to={`${path.ASK_A_NURSE}/1`}
                    onClick={closeNav}
                    className="nav-link"
                >
                    Ask a Nurse{" "}
                    {!!newMessages.data.length && <FiberManualRecord
                        style={{ color: "rgb(245, 125, 125)" }}
                        fontSize="small"
                    />}
                </Link>
            </li>}

            {(userToken && user.is_stripe_membership !== 1) && <li>
                <Link to={path.MEMBERSHIP_PRE_LOGIN} onClick={closeNav} className="nav-link" >Membership Plans</Link>
            </li>}
        </ul>

        {memberBtn() && (
            <div className="d-flex become-flex">
                <button
                    className="btn primary-blue-small-btn-40 px-4 become-member-btn"
                    onClick={() => {
                    navigate(
                        user ? `${path.MEMBERSHIP}/2` : path.MEMBERSHIP_PRE_LOGIN
                    );
                    closeNav();
                    }}
                >
                    Become a Member
                </button>
            </div>
        )}
        {(userToken || user) ? (
            <React.Fragment>

                <div className='user-profile-cont d-none d-md-block d-lg-block d-xl-block d-xxl-block'>
                    <ProfileMenu data={profileData} />
                </div>
            </React.Fragment>

        ) : (

            <div className='top-nav-ext-btn d-flex flex-column flex-md-row'>
                {/* <> */}
                <button className="btn primary-outline-btn mr-2 px-4" onClick={navigationLogin} >
                    Sign In
                </button>
                {/* <button className="btn startnow-btn" onClick={navigationSignup}>Start now</button> */}
                {/* </> */}
            </div>
        )}
    </>

    const mobile = useWindowDimensions(991);


    return (
        <div>
            <header className="header" >
                <nav className="navbar navbar-expand-lg navbar-light bg-light px-3">
                    <Link to={HOME} className="nav-link" > <img src={user ? Logo : LogoPreLogin} alt="" className='brand-logo' /></Link>

                    {
                        (userToken || user) &&
                        <div className='d-block d-sm-block d-md-none d-lg-none d-xl-none d-xxl-none profile-mobile-view'><ProfileMenu closeNav={closeNav} /></div>
                    }

                    <button ref={navRef2} className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>


                    {mobile.isSmaller && <div className="collapse navbar-collapse justify-content-end" data-toggle="collapse" data-target=".navbar-collapse" id="navbarSupportedContent">
                        {nav}
                    </div>}

                    {mobile.isBigger && <div className="collapse navbar-collapse justify-content-end" >
                        {nav}
                    </div>}
                </nav>
            </header>
        </div >
    )
}

export default Header
