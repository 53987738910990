import moment from "moment";
import { ToEst } from "../imageCrop/TimeConvert/convertToEst";

const getTotalDaysMonth = () => {

    let date: any = new Date()
    let year = date.getFullYear()
    let month = date.getMonth();
    return new Date(year, month, 0).getDate()
}

function diff_weeks(dt2: any, dt1: any) {

    var diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= (60 * 60 * 24 * 7);
    return Math.abs(Math.round(diff));

}
const getMonthDiff = (date: any) => {
    let d1 = new Date()
    let d2 = new Date(date)
    let months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months;
}
const timeDiff = (time: Date) => {

    let crrTime: any = moment()
    crrTime = ToEst(crrTime)
    let upTime = moment(time)
    var result = upTime.diff(crrTime, 'days')
    if (Number(result) >= 14) {
        let monthDiff = getMonthDiff(time)
        if (Number(monthDiff) === 1) {
            return `Next Month`;
        } else if (Number(monthDiff) === 0) {
            return `In ${result} days`
        } else {

            return `In ${monthDiff} Months`;
        }
    }
    if (Number(result) >= 31) {
        let todalDayCrr = getTotalDaysMonth()
        let crrDay = new Date().getDate()
        let dayLeft = Number(todalDayCrr) - Number(crrDay);

        if (dayLeft < result) {
            return "In next month"
        } else {
            return "In this month";
        }


    }
    if (Number(result) === 7) {
        return "In a week";
    }
    if (Number(result) > 7 && Number(result) <= 13) {
        let weekDiff = diff_weeks(new Date(), new Date(time))
        if (weekDiff != 0) {
            return `In ${weekDiff} week`;
        } else {
            return "In next week";
        }
    }

    if (Number(result) <= 6 && Number(result) > 1) {
        return `In ${result} days`
    }
    if (Number(result) === 1) {
        return "Next day";
    }
    if (Number(result) < 1) {
        return "Today"
    }

    return "Upcoming"
}
export default timeDiff;