import React from 'react';
import { Container, Row } from 'react-bootstrap';
import "./CorporatePrograms.scss";
import corporate_feature2 from "../../Assets/img/corporate/corporate-feature2.jpg";
import StreetCred from '../../Common/streetCred/streetCred';
import LandingDescription from '../../Common/LandingDescription';
import { HeroBannerHorizontal}  from '../../Common/HeroBanner';
import hero from '../../Assets/img/service/corpProgram.png';
import { useNavigate } from 'react-router-dom';
import { CONTACT } from '../../Routes/RouteConstent';
import Statistics from '../../Common/Statistics';

const CorporatePrograms = () => {
  let testimonialData = [
    {
      name:"Christine G. | Head of Parent ERG",
      data:"We began our partnership with NAPS in 2021 as a direct response to the challenges our employees with young children were facing in the wake of the pandemic. The NAPS team was able to provide our employees with tangible, practical resources to help with their unique parenting journeys. Since our partnership began, we have done over 10 webinars and provided access to Nurture by NAPS to all employees. The webinars are extremely well attended by our community and have provided a diverse range of topics, including mindful parenting, sleep and infant/child CPR. It has been wonderful to provide NAPS as a resource for our caregiving community! The first hand feedback I hear on the impact our NAPS partnership has with our employees is so rewarding and we are excited to be continuing our journey with this fantastic company!"
    },
    {
      name:"Emilie C. | Senior Benefits Specialist",
      data:"NAPS has been an incredible support for our new and expecting parents. They've supported and prepared our expecting parents through monthly workshops on topics like newborn essentials, labor & delivery prep, and infant & child CPR, among other topics. Our new parents have access to workshops on breastfeeding & pumping, returning to work, work/life balance, starting solids, 1 year old transitions, and more. Our employees consistently tell us that having access to this type of programming through their employer has shown them how we understand and care about supporting them and their wellbeing during such a life-changing time.On a personal note, I learned a great deal from Jamie and Emily over the past two years. They prepared me for labor and delivery as well as the newborn stages in their incredible 2-day online pre-baby bootcamp. I felt prepared and comforted after the bootcamp (and it was nice to get a discount through our partnership with them!). When I struggled with breastfeeding, I joined their free weekly breastfeeding support group and heard from other moms struggling with the same things. An IBCLC nurse was there to provide advice and help with everyone's issues. I cannot recommend NAPS enough, as they helped me tremendously and continue to help me and my partner as we enter the next stage of babyhood with our 6-month old (*hint* more sleep regressions, what solids should I start with? And getting that sleep schedule down!). I joined their Nurture by NAPS membership and am absolutely loving it. There's so much great content provided and I'm always impressed with how much I have access to. I love all the wonderful expert guest speakers they have each week and I feel like I'm constantly learning new strategies to cope with my little one's constant changing. I am so appreciative of Emily and Jamie and recommend them to all expecting, new, and even experienced parents."
    }
  ]

  const navigate = useNavigate();

  return (
    <div>
          <Container fluid className='corporate-container'>
          <div className="py-3">
            <HeroBannerHorizontal
              hero={hero}
              title={
                <>
                <div style={{color: '#30426D'}}>Corporate</div>
                <div className="strong" style={{color: '#30426D'}}>
                  Programing
                </div>
                </>
              }
            />
          </div>
          
            <LandingDescription
              ySpace
              content={[
                <>
                  Our programming{" "}
                  <span className="strong">increases productivity </span>for parent
                  employees <span className="strong">at work </span> by{" "}
                  <span className="strong">reducing stress and time spent </span>{" "}
                  hunting for answers to their biggest questions{" "}
                  <span className="strong">at home. </span>
                </>,
                <>
                  <span className="strong">Invest </span> in your people and
                  redefine your <span className="strong">company's culture </span>{" "}
                  through <span className="strong">NAPS' personalized benefits offering.</span>
                </>,
                <>
                  Employees who feel valued are less likely to look into other
                  opportunities or crunch numbers related to changing their
                  employment status.
                </>,
              ]}
            />

            <Row className='py-4'>
                <div className='col-lg-12 col-md-12'>
                    <Container fluid className='corporate-featured'>
                        <Row className='justify-content-center '>
                            <div className='col-lg-10 col-md-10'>
                                <Container>
                                    <Row className='justify-content-center align-items-center flex-column flex-md-row py-0 py-md-5'>
                                        <div className='col-lg-5 col-md-5 col-sm-12 registered-nurse-imgsection '>
                                            <img src={corporate_feature2} className='img-fluid pe-4' />
                                        </div>
                                        <div className='col-lg-5 col-md-5 col-sm-12 registered-nurse-info mt-5 mt-md-0'>
                                            <div className='d-flex flex-column'>
                                                <div>
                                                  <p className='text-center text-md-start'>
                                                  Every time an employee leaves their job, it costs the employer $50,000 - $100,000+ (depending on their position) to replace that employee. If you sign 50 parent employees up for NAPS, you pay the equivalent to a monthly gym membership. If just one parent doesn’t  leave their job because of the support NAPS provides outside of work, the fee for NAPS paid for itself. If 2 parent employees don’t leave their job, you saved $50,000+. 
                                                  </p>
                                                </div>
                                                <div>
                                                  <button className='w-auto btn primary-outline-btn' onClick={() => navigate(CONTACT)}>Learn More</button>
                                                </div>
                                            </div>

                                        </div>
                                    </Row>
                                </Container>
                            </div>
                        </Row>
                    </Container>
                </div>
            </Row>

            <Statistics 
              title="High belonging in a company can lead to…" 
              statistics={[
                {
                  title: "Reduction in sick days",
                  value: "75",
                  type: "%",
                  color: "#F57D7D",
                },
                {
                  title: "Increase in job performance",
                  value: "56",
                  type: "%",
                  color: "#B693C8",
                },
                {
                  title: "In yearly savings for a 10K person company",
                  value: "52",
                  type: "M",
                  color: "#F5CF70",
                },
                {
                  title: "Drop in turnover risk",
                  value: "50",
                  type: "%",
                  color: "#4CC3B0",
                },
              ]} 
            />
           
            <Row>
              <div className='col-lg-12 col-md-12 become-main'>
                <div className='become-member my-4'>
                    <div className='container'>
                        <div className='col-lg-12 col-md-12'>
                            <div className='become-member-container p-0 p-md-5'>
                                <div className='row'>
                                    <div className='col-lg-6 col-md-6 col-sm-12 ms-0 ms-md-5 me-0 me-md-5 order-1 order-md-0'>
                                        <div className='become-meber-captions'>
                                            <h3>Employees become members,
                                                <span className="strong">for total access to…</span>
                                            </h3>
                                            {/* <div className='price-dtls'>
                                                <p>from <span className='pvalue strong'>$83.00</span> /mo</p>
                                            </div> */}
                                            <div>
                                                <ol>
                                                    <li>Live parenting workshops</li>
                                                    <li>On demand content for the most popular parenting topics</li>
                                                    <li>24/7 access to a team of medical professionals specializing in pregnancy and parenting support</li>
                                                </ol>
                                            </div>
                                            <div>
                                                <button className='secondary-teal-btn-small mt-4' onClick={() => navigate(CONTACT)}>Learn More</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-sm-12 mob-membership-img-container order-0 order-md-1'>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
            </Row>
            <Row>
              <StreetCred data={testimonialData} />
            </Row>
          </Container>
        </div>
  )
}

export default CorporatePrograms;