import React from 'react'
import { Container, Row, Image, Card, Carousel, Button } from 'react-bootstrap';
import "./SleepConsultation.scss";

import private_sleep_consult from "../../../Assets/img/service/Private-sleep-consult.jpg";
import group_sleep_consult from "../../../Assets/img/service/group-sleep-consult.jpg";
import unswaddled_main from "../../../Assets/img/Prelogin/unswaddled-main.jpg";
import testimonial1 from '../../../Assets/img/membership-main/testimonial-profile.png';
import { GROUP_SLEEP_CONSULT_URL, PRIVATE_SLEEP_CONSULT_URL } from '../../../Utils/ServiceUrl';
import { useNavigate } from 'react-router-dom';
import { CONTACT, PODCASTQUESTION } from '../../../Routes/RouteConstent';
import LockScreen from "../../../Common/LockScreen/LockScreen";
import { getDataFromLocalStorage } from '../../../Service/getLocalStorage';
import AnyQuestion from '../../../Common/AnyQuestion/AnyQuestion';
import PodcastUI from '../../../Common/PodcastUi/PodcastUI';
import StreetCred from '../../../Common/streetCred/streetCred';
import LandingDescription from '../../../Common/LandingDescription';
import { HeroBannerHorizontal}  from '../../../Common/HeroBanner';
import hero from '../../../Assets/img/service/helpGetSleep.png';

const SleepConsultation = () => {

  const Navigate = useNavigate()
  const userData = getDataFromLocalStorage()

  let testimonialData = [
    {
      name:"Kendall M.",
      data:"I used NAPS sleep consulting services for my third baby (I thought I was a sleep expert after my first two, I was wrong). My five month old was waking up every two hours, wouldn’t take naps longer than 30 minutes unless I was lying with him (not really an option with a 5 year old and 2 year old at home). Ellen was really encouraging and helpful. Her plan wasn’t easy, but really effective. After 2 nights baby was sleeping 11 hours straight and by day 4 he was taking 1+ hour naps. He is now a super sleeper and self soother and I feel like I have my sanity back. His wake times are also much more enjoyable and he’s an even happier little well rested baby. I am so happy I contacted NAPS!!"
    }

   
    // {
    //   name:"Shayna S.",
    //   data:"NAPS has been such a huge help in providing me a sense of comfort, confidence and community throughout all stages of my pregnancy and parenting journey. Emily and Jamie are the absolute best and offer such real and practical advice that I was able to implement and see changes right away.  Everything from breastfeeding, taking care of myself and getting my baby to sleep through the night in his crib. I've built such a great network of moms through MSG andt he starting solids workshop was wonderful. Make sure to check out their webinars too! Thank you NAPS!!"
    // }
  ]

  return (
    <div className='sleepConsultation'>
      <Container fluid>
        <div className="py-3">
          <HeroBannerHorizontal
            hero={hero}
            title={
              <>
                <div>Let’s get you</div>
                <div>(and your child)</div>
                <div className="strong" style={{color: '#30426D'}}>
                some sleep!
                </div>
              </>
            }
          />
        </div>
        <LandingDescription
          ySpace
          content={[
            <>
              <span className="strong">
                If you can’t sleep, you can’t think (well you can think, just
                not clearly!).
              </span>{" "}
              And if your baby isn’t sleeping, then neither are you.
            </>,
            <>
              Let us take the thought out of it for you, and work directly with
              <span className="strong ">
                {" "}
                our pediatric nurse, and expert in pediatric sleep,
              </span>{" "}
              to formulate a plan to get{" "}
              <span className="strong">
                you and your baby sleeping better!
              </span>
            </>,
          ]}
        />
        <Row className='pale-bg offering-main'>
          <div className='col-lg-12 col-md-12 col-sm-12'>
            <Container>
              <Row className="justify-content-center my-5">
                <div className='col-lg-12 col-md-12 col-sm-12'>
                  <div className="section-header text-center">
                    <h2 className="home-section-title py-0">Our<span className="strong pink"> Offerings</span></h2>
                    <p className='mt-3'>NAPS offers Sleep Consultations in two different ways for children ages 0-3+</p>
                  </div>
                </div>
              </Row>

              <Row className='justify-content-center align-items-center my-5'>
                <div className='col-lg-5 col-md-5 col-sm-12 order-1 order-md-0 mt-4 mt-md-0'>
                  <div className='offering-block-content offer-number1 me-0 me-md-5'>
                    <h3 className='lh-1 w-100 '>
                      <span className="offer-number">1. </span>
                      Private Sleep Consultations
                    </h3>
                    <p><span className='strong'>Need one-on-one sleep support? We can help. </span>
                      At NAPS, we know that not all children respond to the same methods. That’s why we offer personalized sleep consultations with a custom plan for you and your child’s specific needs.</p>
                    <button className="w-auto btn-small teal-btn-small" onClick={() => Navigate(PRIVATE_SLEEP_CONSULT_URL)}>Book Now</button>
                  </div>
                </div>
                <div className='col-lg-5 col-md-5 col-sm-12 order-0 order-md-1'>
                  <div className='service-feature-img'>
                    <img src={private_sleep_consult} className='img-fluid' />
                  </div>
                </div>
              </Row>

              <Row className='justify-content-center align-items-center my-5'>
                <div className='col-lg-5 col-md-5 col-sm-12'>
                  <div className='service-feature-img me-0 me-md-5'>
                    <img src={group_sleep_consult} className='img-fluid' />
                  </div>
                </div>

                <div className='col-lg-5 col-md-5 col-sm-12 mt-4 mt-md-0'>
                  <div className='offering-block-content offer-number2'>
                    <h3 className='lh-1 w-100'>
                      <span className="offer-number">2. </span>
                      Group Sleep Consultations
                    </h3>
                    <p>For parents of children 5-18 months,  who need a clear direction and a plan for sleep, but also enjoy the camaraderie and support that comes with being surrounded by other parents who get it, we offer group sleep consults.</p>
                    <button className="w-auto btn-small purple-btn-small" onClick={() => Navigate(GROUP_SLEEP_CONSULT_URL)}>Book Now</button>
                  </div>
                </div>
              </Row>


            </Container>
          </div>
        </Row>

        {(userData && !userData?.is_stripe_membership) &&

          <LockScreen />
        }
        <PodcastUI />

        <StreetCred data={testimonialData} />

        {/* <AnyQuestion /> */}
      </Container>
    </div>
  )
}

export default SleepConsultation;