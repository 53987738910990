import React, { useState } from 'react'
import { ResendEmailVerify, verifyOtp } from '../../Service/userAuth';
// import "../../Screens/emailVerfication/emailVerify.scss"
import "./emailverifypopup.scss";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { CircularProgress, IconButton } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import 'react-toastify/dist/ReactToastify.css';
import { Alert } from 'react-bootstrap';
import CloseIcon from '@mui/icons-material/Close';
import { HOME, VERIFYEMAILPOPUP } from '../../Routes/RouteConstent';
import { getDataFromLocalStorage, getUser } from '../../Service/getLocalStorage';
import { useSelector } from 'react-redux';
import { resendCodeInterval } from '../../Redux/user/user';
import { useDispatch } from 'react-redux';

const EmailVerifyPopup = (props: any) => {

  let { isVerifyEmail, setIsVerifyEmail, data, handelClose, callBack } = props;

  // let { state }: any = useLocation()
  let state = data;
  let Navigate = useNavigate()
  const [code, setCode] = useState<any>("")
  const [error, setError] = useState<string>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [sMsg, setSMsg] = useState<string>("")
  let [timer, setTimer] = useState(30)

  const user = getDataFromLocalStorage()

  const dispatch = useDispatch()
  const resendVeryCode = async () => {
    // var startTime = new Date().getTime();
    // var interval = setInterval(function () {
    //   if (new Date().getTime() - startTime > 30050) {
    //     clearInterval(interval);
    //     return;
    //   }
    //   setTimer(timer -= 1)
    // }, 1000);
    dispatch(resendCodeInterval())

    try {
      let obj = {
        user_id: user?.id,
        email: user?.email
      }

      let res = await ResendEmailVerify(obj)

      let isSucess = res.data.success
      let message = res.data.message
      let msg = "";

      for (let i = 0; i < message.length; i++) {
        msg += message[i];
      }
      if (isSucess) {
        toast.success(msg)

      }
    } catch (err) {
      console.log(err)
    }
  }

  //onChange 

  const handleClickOpen = () => {
    setIsVerifyEmail(true);
  };

  const handleClose = (e: any, type: any) => {
    if (type === "backdropClick") {
      return;
    }
    setIsVerifyEmail(false);
  };


  let submitLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();
      if (!code) {
        setError("field may not be blank")
      } else {
        const user = getUser();

        let obj = {
          user_id: user?.id,
          email: user?.email,
          otp: code
        }

        setIsLoading(true)
        let res = await verifyOtp(obj)
        const isSucess = res.data.success;
        let msgArray = res.data.message;
        let msg = ""
        for (let i = 0; i < msgArray.length; i++) {
          msg += msgArray[i]
        }
        if (!isSucess) {
          toast.error(msg)
        }
        if (isSucess) {
          localStorage.removeItem('Nurture_user_data')
          localStorage.setItem('Nurture_user_data', JSON.stringify(res?.data?.data))
          setSMsg(msg)
          // Navigate(VERIFYEMAILPOPUP)
          setIsVerifyEmail(false)
          callBack && callBack()
        }
        setIsLoading(false)
        
        window.dispatchEvent(new Event("storage"))
      }
    } catch (error) {
      setIsLoading(false)
    }
  }
  const modalClose = () => {
    setIsVerifyEmail(false)
    handelClose()

  }
  const time = useSelector((state: any) => state?.userData?.timer)

  return (
    <div>
      {/* <Header /> */}
      
      <Dialog
        open={isVerifyEmail}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="email-verificationpopup modal-500"
      >
        <IconButton sx={{
          position: 'absolute',
          right: 8,
          top: 8
        }}  >

          <CloseIcon onClick={modalClose} />

        </IconButton>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div className="container-fluid">
              <div className="row">
                <div className="login-inner">
                  <div className='d-flex flex-column'>
                    <h1 className="email-head">Email Verification</h1>
                    {!sMsg && <p className='text-center sent-msg'>We have sent a verification code to your Email {state?.email && state?.email}</p>}
                    <div>
                      {sMsg && <Alert className="alert-body" variant="success" >
                        <Alert.Heading className="alert-msg">
                          {sMsg}
                        </Alert.Heading>
                      </Alert>}
                    </div>
                    <form onSubmit={submitLogin}>
                      <div className="form-group">
                        <label>Enter Verification Code</label>
                        <input type="text" value={code} name="email"
                          className={`${error && "error-input"} form-control mt-1`}
                          id="exampleInputEmail1"
                          placeholder="1234567"
                          onChange={(e) => setCode(e.target.value)}
                        />
                        <p className="error">{error}</p>
                      </div>

                      <div className="text-center forget-password">
                        {/* <a className="">Forgot Password</a> */}
                      </div>
                      <button type='submit' disabled={isLoading} className="btn primary-blue-btn">{isLoading ? <CircularProgress /> : "Submit"}</button>
                    </form>
                    {
                      // time < 30 ? <span>Resend verification code {timer}s</span> :
                      //     <Link to={''} onClick={resendVeryCode}>Resend verification code</Link>
                      (time === 30 || time === 0) ? <Link to={''} className="mt-1" onClick={resendVeryCode}>Resend verification code</Link> :
                        <span className="mt-1">Resend verification code {time}s</span>
                    }
                  </div>
                </div>
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default EmailVerifyPopup
