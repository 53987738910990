import React from "react";
import "./whatIsIncluded.scss";
import bootCampCoverPng from "../../assets/bootcamps.png";
import prenatalPng from "../../assets/prenatal.png";
import consultationPng from "../../assets/consultation.png";
import groupPng from "../../assets/group.png";
import survivalPng from "../../assets/survival.png";

const WhatIsIncluded = ({
  joinTheClubHandle,
  mainDescription = 'with your annual membership',
}: {
  joinTheClubHandle: () => void;
  mainDescription?: string
}) => (
  <div className="preLoginIncluded">
    <h2 className="heading2">Save over $1500</h2>
    <p className="mainDescription">{mainDescription}</p>

    <div className="preLoginIncludedList">
      <div className="preLoginIncludedJump">
        <h2 className="heading3">What’s included?</h2>
        <p className="description">
          From Bootcamps to 1:1 Consultations, your annual membership covers it
          all.
        </p>
        <button
          className="w-auto btn-small teal-btn-small"
          onClick={joinTheClubHandle}
        >
          Join the Club
        </button>
      </div>

      {[
        {
          id: 0,
          cover: bootCampCoverPng,
          title: "Bootcamps",
          description:
            "Pre Baby (for the first time expecting parent), Veteran (for the experienced parent) and Grandparent (well, you get it!)",
          price: "650",
        },
        {
          id: 1,
          cover: prenatalPng,
          title: "Monthly Prenatal Classes",
          description:
            "Labor and Delivery Prep, Newborn Essentials, Breastfeeding Basics, and Infant & Child CPR with First Aid",
          price: "400",
        },
        {
          id: 2,
          cover: survivalPng,
          title: "Mom Survival Group",
          description:
            "New (for the first time mom), Veteran (for the experienced mom) and Working (well you get it!)",
          price: "499",
        },
        {
          id: 3,
          cover: groupPng,
          title: "Group Consultations",
          description:
            "Feeding, sleep, developmental milestones, transitions, mental health, pelvic health and more",
          price: "500",
        },
        {
          id: 4,
          cover: consultationPng,
          title: "1:1 Consultations",
          description:
            "One virtual feeding consultation and two 30-minute phone consultations with a nurse",
          price: "550",
        },
      ].map((card) => (
        <div className="preLoginIncludedCard" key={card.id}>
          <img src={card.cover} alt="" />
          <div className="preLoginIncludedContent">
            <div className="preLoginIncludedBadge">Included</div>
            <p className="preLoginIncludedTitle">{card.title}</p>
            <p className="preLoginIncludedDescription">{card.description}</p>
            <p className="preLoginIncludedPrice">
              Non Member price <span>${card.price}</span>
            </p>
          </div>
        </div>
      ))}
    </div>
  </div>
);

export default WhatIsIncluded;
