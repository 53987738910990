import { DialogProps } from '@mui/material';
import React, { useState } from 'react';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { CityStateZipValSub } from '../../../../Common/commonFIeld/validation';
import { Link } from 'react-router-dom';
import * as path from "../../../../Routes/RouteConstent";


const UserInfo = ({ handleNext, value, setValue, handlePrev, term_condition, setPolicy, field }: any) => {

    const [errors, setErrors] = useState<any>({})
    const [open, setOpen] = React.useState<boolean>(false);
    const [scroll, setScroll] = React.useState<DialogProps['scroll']>('paper');

    const handleChange = (e: React.ChangeEvent<HTMLInputElement> | any) => {
        let name = e.target.name;
        let inputValue = e.target.value
        setValue({
            ...value,
            [name]: inputValue
        })
    }
    let handleRadioButton = (e: any) => {
        let val = e.target.value;
        setErrors({
            ...errors,
            [e.target.name]: ""
        })
        setValue({
            ...value,
            [e.target.name]: val
        })
    }
    let handleDueDate = (e: Date | null | any) => {

        setErrors({
            ...errors,
            ['due_date']: ""
        })
        setValue({
            ...value,
            ['due_date']: e
        })
    }

    const handleSubmit = () => {
        let cityStateError = CityStateZipValSub(value)
        
        if (!value?.due_date || value?.due_date === "Invalid date"
            || !value?.is_your_first_baby || cityStateError
            || !term_condition) {
            let error: any = {}
            if (!value?.due_date) {
                error.due_date = "Please select date"
            }
            if (value?.due_date === "Invalid date") {
                error.due_date = "Please select date"
            }

            if (!value?.is_your_first_baby) {
                error.is_your_first_baby = "Please input the value"
            }
            if (!term_condition) {
                error.term_condition = "Please check terms of service "
            }
            setErrors({ ...error, ...cityStateError })
        } else {
            console.log(value)
            console.log(term_condition)
            handleNext(value)
        }
    }

    const policyHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        setErrors({
            ...errors,
            [e.target.name]: ""
        })
        setPolicy(e.target.checked)
        setValue({
            ...value,
            term_condition: e.target.checked
        })
    }

    return (
        <div>
            <section className='step-container step2 user-info'>
                <div className='consult-section-heading text-center'>
                    <h3 className='my-5'>Complete your Information</h3>
                </div>

                <form className=' ' onSubmit={handleSubmit}>
                    <div className='row'>
                        <div className="form-group col-lg-12 col-md-12 mb-3 due_dt_container">
                            <label >Due Date/Child's Birthday <span className='mandatory text-danger mx-2'>*</span></label>
                            <LocalizationProvider dateAdapter={AdapterDayjs} >

                                <Stack spacing={3}>
                                    <MobileDatePicker
                                        toolbarTitle="Choose date"
                                        value={value?.due_date || ""}
                                        onChange={handleDueDate}
                                        renderInput={(params: any) => <TextField {...params} placeholder="MM-DD-YYYY" className='due-date-field' />}

                                    />
                                </Stack>
                            </LocalizationProvider>
                            <h4 className="error-msgtext">{errors?.due_date}</h4>
                        </div>

                        <div className="form-group col-lg-12 col-md-12 mb-3">
                            <label>Child's Name: <span className="fw-light"> (if applicable)</span> </label>
                            <input type="text" name='child_name' className='form-control'
                                value={value?.child_name} onChange={handleChange}
                            />
                        </div>

                        <div className="form-group col-lg-12 col-md-12 mb-3">
                            <label>Is this your first baby? <span className='mandatory text-danger mx-2'>*</span></label>
                            <div className="custom-radio custom-control-inline">
                                <input type="radio" id="customRadio1" name="is_your_first_baby" checked={value?.is_your_first_baby === "yes"} value="yes"
                                    className="custom-control-input form-check-input"
                                    onChange={handleRadioButton}
                                />
                                <label className="custom-control-label me-3" htmlFor="customRadio1">yes</label>
                                <input type="radio" id="customRadio2" name="is_your_first_baby" checked={value?.is_your_first_baby === "no"}
                                    value="no"
                                    onChange={handleRadioButton}
                                    className="custom-control-input form-check-input"
                                />
                                <label className="custom-control-label step_two" htmlFor="customRadio2">no</label>
                            </div>
                            <h4 className="error-msgtext">{errors?.is_your_first_baby}</h4>
                        </div>

                        <div className="form-group col-sm-12 mb-3">
                            <label>City <span className='mandatory text-danger mx-2'>*</span></label>
                            <input type="text" name='city'
                                value={value?.city}
                                className="form-control"
                                onChange={handleChange}
                            />
                            <h4 className="error-msgtext">{errors?.city}</h4>
                        </div>
                        <div className="form-group col-sm-12 mb-3">
                            <label>State <span className='mandatory text-danger mx-2'>*</span></label>
                            <input type="text" name='state'
                                value={value?.state}
                                className="form-control"
                                onChange={handleChange}
                            />
                            <h4 className="error-msgtext">{errors?.state}</h4>
                        </div>

                        {/* <div className="form-group col-lg-12 col-md-12 mb-3">
                            <label>Partner or Support Person's Name <span className="fw-light"> (if applicable):</span> </label>
                            <input type="text" className='form-control' name="partner_support_person_name" value={value?.partner_support_person_name} onChange={handleChange} placeholder='Enter name' />
                        </div>

                        <div className="form-group col-lg-12 col-md-12 mb-3">
                            <label>Partner or Support Person's Email address  <span className="fw-light"> (for reminders, content, and follow up): </span> </label>
                            <input type="text" className='form-control' name='partner_support_person_email_address' value={value?.partner_support_person_email_address} onChange={handleChange} placeholder='Enter email address' />
                        </div> */}

                        <div className='agreement-container py-4'>
                            <div className="custom-radio custom-control-inline">
                                <input type="radio" id="customRadio1" name="term_condition"
                                    onChange={policyHandler}
                                    checked={term_condition}
                                    className="custom-control-input form-check-input" />
                                <label className="custom-control-label" htmlFor="customRadio1">I have read and agree to the <Link target="_blank" className='blue-link' to={path.TERMS} > terms of service</Link></label>
                            </div>
                            <h4 className="error-msgtext">{errors?.term_condition}</h4>
                        </div>

                    </div>
                </form>
                <div className='section-navigation my-5 d-flex justify-content-between'>
                    <button className='primary-blue-small-btn'>Previous</button>
                    <button className='primary-blue-small-btn next' onClick={handleSubmit}>Next</button>
                </div>
            </section>
        </div>
    )
}

export default UserInfo;