import React from "react";
import "./lockAskANurse.scss";
import { useNavigate } from "react-router-dom";
import { MEMBERSHIP_PRE_LOGIN } from "../../../Routes/RouteConstent";

const LockAskANurse = () => {
  const navigate = useNavigate();

  return (
    <div className="lockAskANurse">
      <div className="lockAskANurse__central">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="116"
          height="153"
          viewBox="0 0 116 153"
          fill="none"
        >
          <path
            d="M3.33966 80.8153C3.1035 95.1487 2.29599 109.459 2.01412 123.785C1.82367 133.609 3.4387 145.638 14.3097 149.117C25.4016 152.672 39.6702 150.989 49.9926 145.789C60.9017 140.294 69.5558 130.311 69.053 117.577C68.8473 112.309 65.9068 107.299 61.0083 105.283C50.9373 101.122 43.205 112.097 43.8982 121.443C44.6295 131.327 51.7905 139.354 60.2542 143.909C68.1846 148.177 79.7792 148.382 88.7076 148.586C94.703 148.723 103.433 148.109 108.179 143.887C113.032 139.566 113.085 131.38 113.459 125.195C114.205 112.764 113.68 100.121 113.992 87.6674C114.419 70.6432 97.8569 68.3844 84.3196 68.9074C68.3598 69.529 52.4761 70.6735 36.4782 71.0677C19.8708 71.4694 16.9988 57.5074 20.0232 43.9699C22.979 30.7507 26.7119 15.7427 38.2075 7.19273C46.6559 0.924239 57.9154 0.674106 67.4151 4.60802C85.7061 12.1954 93.027 34.4269 93.4994 52.482"
            stroke="#4CC4B0"
            stroke-width="3"
            stroke-miterlimit="10"
          />
        </svg>
        <h1 className="lockAskANurse__title p-0 my-2">
          Become a Member <br />
          <span className="lockAskANurse__title--green">for total access</span>
        </h1>
        <p className="lockAskANurse__description">
          This is your all-access pass to our courses, videos, live webinars,
          Ask A Nurse, and more. Plus 1:1 consultation discounts and an
          onboarding call with a NAPS nurse to learn how we can best support
          you.
        </p>
        <button
          type="button"
          className="btn secondary-teal-btn my-3"
          onClick={() => navigate(MEMBERSHIP_PRE_LOGIN)}
        >
          Join Now
        </button>
      </div>
    </div>
  );
};

export default LockAskANurse;
