import { useEffect, useState } from "react";
import * as asyncState from "../../../Utils/asyncState";
import { getFilterOptions } from "../transport";
import { toast } from "react-toastify";

type FilterOptions = {
  data: {
    stage: { name: string; id: number }[];
    category: { name: string; id: number }[];
  };
} & asyncState.AsyncState;

const useAskNurseFilter = () => {
  const [filterOptions, setFilterOptions] = useState<FilterOptions>({
    ...asyncState.initState,
    data: { stage: [], category: [] },
  });

  useEffect(() => {
    const fetchFilterOptions = async () => {
      try {
        setFilterOptions({
          ...asyncState.pendingState,
          data: { stage: [], category: [] },
        });
        const res: any = await getFilterOptions();

        if (res.status !== 200) {
          throw new Error(res?.message || res?.data?.message?.join(" "));
        }

        setFilterOptions({ ...asyncState.successState, data: res?.data });
      } catch (err: { message: string } | any) {
        toast.error(err?.message);
        setFilterOptions({
          ...asyncState.failedState,
          failedMessage: err?.message,
          data: { stage: [], category: [] },
        });
      }
    };

    fetchFilterOptions();
  }, []);

  return { filterOptions };
};

export default useAskNurseFilter;
