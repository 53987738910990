import React from 'react'
import { Container, Row } from 'react-bootstrap'

const BlogCrad = ({ blog }: any) => {
    return (
        <section className='latest-news mt-3 pb-5'>
            <Container>
                <Row>
                    <div className='col-lg-12 col-md-12'>
                        <Row>
                            <div className='col-lg-12 text-left'><h2 className='section-heading'>Latest <span className='head-bold'>News</span></h2></div>
                        </Row>

                        <Row>
                            <div onClick={() => window.location.href = blog?.lastes_blog?.url} className='col-lg-6 col-md-6 col-sm-12 mb-3 mb-sm-3 mb-md-0 featured-blog-main'>
                                <div className='featured-blog d-flex flex-column'>
                                    <div className='featured-blog-img mb-3'>
                                        <img src={ blog?.lastes_blog?.thumbnail || blog?.lastes_blog?.image} className='img-responsive' />
                                    </div>
                                    <div className='blog-details d-flex flex-column ms-0 ms-md-3'>
                                        <div className='blog-date my-2'>
                                            <span>{blog?.lastes_blog?.days}</span>
                                        </div>
                                        <div className='blog-title'>
                                            <h3>{blog?.lastes_blog?.title} </h3>
                                        </div>
                                        <div className='blog-description'>
                                            <p>{blog?.lastes_blog?.summary}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-6 col-sm-12 blog-list-container'>
                                <div className='d-flex flex-column'>
                                    {Array.isArray(blog?.blogs) && blog?.blogs.map((resData: any) => (
                                        <div className='blog-list' onClick={() => window.location.href = resData.url}>
                                            <div className='d-flex flex-column flex-md-row'>
                                                <div className='blog-thumb-main me-3'>
                                                    <div className='blog-thumb'
                                                        style={{ backgroundImage: `url(${resData?.thumbnail || resData?.image})` }}>
                                                        {/* <img src={resData?.image} className="img-responsive" /> */}
                                                    </div>
                                                </div>

                                                <div className='blog-details d-flex flex-column'>
                                                    <div className='blog-date my-2'>
                                                        <span>{resData?.days}</span>
                                                    </div>
                                                    <div className='blog-title'>
                                                        <h3>{resData?.title} </h3>
                                                    </div>
                                                    <div className='blog-description'>
                                                        <p>{resData?.summary}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </Row>

                        <Row className='mb-5'>
                            <div className='col-lg-12 col-md-12 text-center my-3'>
                                <button className="w-auto primary-blue-btn px-5 my-4">View All</button>
                            </div>
                        </Row>
                    </div>
                </Row>
            </Container>
        </section>
    )
}

export default BlogCrad