import React from "react";
import "./monthFree.scss";

const MonthFree = ({
  openMembershipPage,
}: {
  openMembershipPage: () => void;
}) => {
  return (
    <div className="bumpMonthFreeWrapper" id="buyMembership">
      <div className="bumpMonthFree">
        <h2 className="heading2">
          Grab your first <span>month free</span>
        </h2>
        <div className="priceBox">
          <div className="priceBoxPrices">
            <div className="title">Save $150</div>
            <div className="priceAmount">
              <s>$150</s> <span>$0</span>
            </div>
          </div>
          <button
            className="w-auto btn-small btn primary-blue-small-btn-40"
            onClick={openMembershipPage}
          >
            Join now
          </button>
        </div>
      </div>
    </div>
  );
};

export default MonthFree;
