import dayjs from "dayjs";

const keys: { [key: string]: string } = {
  // dateOfConsultation: "Date of Consultation (required)",
  timeOfConsultation: "Time of Consultation",
  // babyDateOfBirth: "Baby's Date of Birth (required)",
  reasonForConsultation: "Reason for Consultation (required)",

  awakeWindowsAndSleepCycles: "Normal Awake Windows & Sleep Cycles",
  bedTime: "Bedtime",
  fussItOut: "Fuss it Out",
  daytimeSleep: "Daytime Sleep",
  tipsForGoingBackToWork: "Tips for Going Back to Work",

  plan: "Plan",
  sleepEnvironment: "Sleep Environment",
  morningWakeup: "Morning Wakeup",
  naps: "Naps",
  awakeWindows: "Awake Windows",
  ellenPearlOfWisdom: "Ellen's Pearl of Wisdom",
  customNotes: "Custom Notes",

  quickTip1Naps: "Quick tip #1 for what is NORMAL:",
  quickTip2Feeding: "Quick tip #2 for what is NORMAL:",
  quickTip3WakeUp: "Quick tip #3 for what is NORMAL:",
  yourPlanDetails: "More details about your plan:",

  daytimeAndOvernightSleep: "Daytime & Overnight Sleep",
  safeSleep: "Safe Sleep",
  getLongerStretchesOvernight1: "How to get longer stretches overnight: Step 1",
  getLongerStretchesOvernight2: "How to get longer stretches overnight: Step 2",
  getLongerStretchesOvernight3: "How to get longer stretches overnight: Step 3",

  // dateOfVisit: "Date of Visit (required)",
  timeOfVisit: "Time of Visit",
  // clientDateOfBirth: "Client's Date of Birth: (required)",
  birthWeight: "Birth Weight",
  reasonForVisit: "Reason for Visit: (required)",
  preFeedWeight: "Pre-Feed Weight",
  postFeedWeight: "Post-Feed Weight",
  oralAssessment: "Oral Assessment",
  correctionsToLatchPositioning: "Corrections made to latch and/or positioning",
  planFollowingVisit: "Plan following this visit: (required)",
  additionalNotesRelatedToVisit: "Additional notes related to this visit:",
  recommendedReferral: "Recommended referral",
  recommendedProductsAmazonNaps:
    "Recommended Products: (https://www.amazon.com/shop/nurturebynaps)",

  latchAssessment: "Latch Assessment",
  breastAssessment: "Breast Assessment",
  recommendations: "Recommendations",
  teachingProvided: "Teaching provided",
  followUp: "Follow up",
  additionalOpportunitiesForLearningAndSupport:
    "Additional Opportunities for Learning & Support: (Here a few other resources available)",

  deliveryType: "Delivery Type",
  patientConcerns: "Patient Concerns",
  maternalPPDAssessmentScore: "Maternal PPD Assessment Score",
  energyEmotionalStatus: "Energy / Emotional Status",
  pulse: "Pulse",
  bloodPressure: "Blood Pressure",
  lowerExtremityAssessment: "Lower Extremity Assessment",
  bleedingBladderBowelsBum: "Bleeding, Bladder, Bowels, Bum",
  other: "Other",
  newbornAppearanceColoring: "Newborn Appearance (coloring/HEENT)",
  heartAndLungs: "Heart and Lungs",
  oralFeedingAssessment: "Oral/Feeding Assessment",
  diaperCarePeesAndPoops: "Diaper care/ pees & poops",

  answerToPPDQuestion10: "Answer to PPD question #10",
  preeclampsiaSymptomsScreening:
    "Preeclampsia Screening- ANY SEVERE SYMPTOMS NEED TO GO TO ER:",
  perinium: "Perinium",
  incision: "Incision",
};

const assignFullNameByKey = ({
  fN,
  lN,
  key,
  obj,
}: {
  obj: { [key: string]: string };
  key: string;
  fN: string;
  lN: string;
}) => {
  if (fN) {
    obj[key] = `${fN} ${lN}`;
  }

  return `${fN} ${lN}`;
};

export const prepareRequestParams = (obj: { [key: string]: any }) => {
  const {
    clientFirstName,
    clientLastName,
    babyFirstName,
    babyLastName,
    pediatricianFirstName,
    pediatricianLastName,
    RNFirstName,
    RNLastName,
    OBFirstName,
    OBLastName,
    consultantFirstName,
    consultantLastName,
    lactationConsultantFirstName,
    lactationConsultantLastName,

    dateOfConsultation,
    babyDateOfBirth,
    dateOfVisit,
    clientDateOfBirth,

    ...restOfObj
  } = obj;

  const dates: { [key: string]: string } = {};

  if (dateOfConsultation) {
    dates["Date of Consultation (required)"] =
      dayjs(dateOfConsultation).format("YYYY-MM-DD");
  }
  if (babyDateOfBirth) {
    dates["Baby's Date of Birth (required)"] =
      dayjs(babyDateOfBirth).format("YYYY-MM-DD");
  }
  if (dateOfVisit) {
    dates["Date of Visit (required)"] = dayjs(dateOfVisit).format("YYYY-MM-DD");
  }
  if (clientDateOfBirth) {
    dates["Client's Date of Birth: (required)"] =
      dayjs(clientDateOfBirth).format("YYYY-MM-DD");
  }

  const fullNameData: { [key: string]: string } = {};

  assignFullNameByKey({
    obj: fullNameData,
    fN: clientFirstName,
    lN: clientLastName,
    key: "Client Name (required)",
  });

  assignFullNameByKey({
    obj: fullNameData,
    fN: babyFirstName,
    lN: babyLastName,
    key: "Baby's Name (required)",
  });

  assignFullNameByKey({
    obj: fullNameData,
    fN: pediatricianFirstName,
    lN: pediatricianLastName,
    key: "Pediatrician Name (required)",
  });

  assignFullNameByKey({
    obj: fullNameData,
    fN: OBFirstName,
    lN: OBLastName,
    key: "OB Name (required)",
  });

  assignFullNameByKey({
    obj: fullNameData,
    fN: RNFirstName,
    lN: RNLastName,
    key: "RN Name (required)",
  });

  assignFullNameByKey({
    obj: fullNameData,
    fN: consultantFirstName,
    lN: consultantLastName,
    key: "Consultant Name (required)",
  });

  assignFullNameByKey({
    obj: fullNameData,
    fN: lactationConsultantFirstName,
    lN: lactationConsultantLastName,
    key: "Lactation Consultant Name (required)",
  });

  const mapData = Object.entries(restOfObj).reduce(
    (acc, [propName, value]) => ({
      ...acc,
      [keys[propName] ?? propName]: value,
    }),
    {}
  );

  return { ...dates, ...fullNameData, ...mapData };
};
