import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as asyncStates from "../../Utils/asyncState";
import { getNewMessagesApi } from "../../Service/newMessages";

export const getNewMessages: any = createAsyncThunk(
  "newMessages/getNewMessages",
  async (_, { rejectWithValue }) => {
    try {
      let res: any = await getNewMessagesApi();
      return res.data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

const initialState: asyncStates.AsyncState & { data: string[] } = {
  ...asyncStates.initState,
  data: [],
};

const newMessagesSlice = createSlice({
  name: "newMessages",
  initialState,
  reducers: {},
  extraReducers: {
    [getNewMessages.pending]: (state) => {
      Object.assign(state, {
        ...asyncStates.pendingState,
      });
    },
    [getNewMessages.fulfilled]: (state, { payload }) => {
      Object.assign(state, {
        ...asyncStates.successState,
        data: payload || [],
      });
    },
    [getNewMessages.rejected]: (state) => {
      Object.assign(state, {
        ...asyncStates.failedState,
      });
    },
  },
});

export const newMessagesSelector = (state: any) => state.newMessages;

export default newMessagesSlice.reducer;
