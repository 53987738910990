import React from "react";
import "./sponsors.scss";
import logo1_icon from "../../Assets/img/homepage/boston.webp";
import logo2_icon from "../../Assets/img/homepage/cybex.svg";
import logo3_icon from "../../Assets/img/homepage/embeba.webp";
import logo4_icon from "../../Assets/img/homepage/fidelity.png";
import logo5_icon from "../../Assets/img/homepage/kudos.png";
import logo6_icon from "../../Assets/img/homepage/merlin.webp";
import logo7_icon from "../../Assets/img/homepage/mighty.webp";
import logo8_icon from "../../Assets/img/homepage/mysha.png";
import logo9_icon from "../../Assets/img/homepage/school.png";
import logo10_icon from "../../Assets/img/homepage/silver.webp";
import logo11_icon from "../../Assets/img/homepage/snapperrock.webp";
import logo12_icon from "../../Assets/img/homepage/tomy.png";

const Sponsors = () => {
  return (
    <div className="sponsorsIconsWrapper">
      <img src={logo1_icon} className="sponsorsIcon" alt="" />
      <img src={logo2_icon} className="sponsorsIcon" alt="" />
      <img src={logo3_icon} className="sponsorsIcon" alt="" />
      <img src={logo4_icon} className="sponsorsIcon" alt="" />
      <img src={logo5_icon} className="sponsorsIcon" alt="" />
      <img src={logo6_icon} className="sponsorsIcon" alt="" />
      <img src={logo7_icon} className="sponsorsIcon" alt="" />
      <img src={logo8_icon} className="sponsorsIcon" alt="" />
      <img src={logo9_icon} className="sponsorsIcon" alt="" />
      <img src={logo10_icon} className="sponsorsIcon" alt="" />
      <img src={logo11_icon} className="sponsorsIcon" alt="" />
      <img src={logo12_icon} className="sponsorsIcon" alt="" />
    </div>
  );
};

export default Sponsors;
