interface PushEventData {
  event: string;
  ecommerce: {
    currency?: string;
    value: string;
    affiliation?: string;
    transaction_id: string;
    coupon?: string;
    finalAmount?: string;
  };
  items: {
    item_name: string;
    item_id?: string;
    price: string;
    quantity: string;
    forever_free?: number;
  }[];
}
declare const window: any;

interface Purchase extends Omit<PushEventData, "event"> {
  event?: string;
}

export const pushGTMDateLayerEvent = (data: any) => {
  const push = window.dataLayer?.push;

  push?.(data);
};

export const purchaseEvent = ({ ecommerce, items }: Purchase) => {
  pushGTMDateLayerEvent({ event: "successful-purchase" });

  pushGTMDateLayerEvent({
    event: "purchase",
    ecommerce: {
      affiliation: "Nurture by Naps",
      currency: "USD",
      ...ecommerce,
    },
    items,
  });
};

export const pushPageViewEvent = () => {
  const scriptTag = document.createElement("script");
  scriptTag.async = true;
  scriptTag.src = "https://www.googletagmanager.com/gtag/js?id=AW-873663483";
  document.body.appendChild(scriptTag);

  window.dataLayer = window.dataLayer || [];
  function gtag(...args: any[]): void {
    window.dataLayer.push(args);
  }
  gtag("js", new Date());
  gtag("config", "AW-873663483");
};
