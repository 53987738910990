import React, { useState } from "react";
import usePaymentMethodForm from "../Components/PaymentMethodForm/usePaymentMethodForm";
import * as asyncStates from "../../../Utils/asyncState";
import PaymentMethodForm from "../Components/PaymentMethodForm";
import { CircularProgress } from "@mui/material";
import { postBuyGiftCard } from "../paymentsAPI";
import { getUserID } from "../../../Service/getLocalStorage";
import { toast } from "react-toastify";
import SavedCards from "../Components/SavedCards";
import useSavedCards from "../Components/SavedCards/useSavedCards";
import ChooseWayToPay from "../Components/ChooseWayToPay/ChooseWayToPay";
import useChooseWayToPay, {
  PayWithEnum,
} from "../Components/ChooseWayToPay/useChooseWayToPay";
import Lock from "../../../Assets/img/lock.svg";
import { usePostHog } from "posthog-js/react";
import CongratsPopUp from "../../CongratsPopUp";
import useModalWindowControls from "../../../Hooks/useModal";
import { useNavigate } from "react-router-dom";
import { grinEvent } from "../../../Utils/grin";

interface BuyGiftCardProps {
  type: string;
  id: string;
  yourName: string;
  yourEmail: string;
  giftTargetName: string;
  giftTargetEmail: string;
  price: string;
  isAuthorized: boolean;
}

const BuyGiftCard = ({
  type,
  id,
  yourName,
  yourEmail,
  giftTargetName,
  giftTargetEmail,
  price,
  isAuthorized,
}: BuyGiftCardProps) => {
  const posthog = usePostHog();
  const navigate = useNavigate();

  const successModal = useModalWindowControls();

  const chooseWayToPay = useChooseWayToPay();

  const [paymentAsyncState, setPaymentAsyncState] =
    useState<asyncStates.AsyncState>(asyncStates.initState);

  const BuyGiftCardRequest = async (fields: any) => {
    let params = {
      ...fields,
      user_id: getUserID(),
      type,
      id,
      yourName,
      yourEmail,
      giftTargetName,
      giftTargetEmail,
    };

    try {
      setPaymentAsyncState(asyncStates.pendingState);

      const res: any = await postBuyGiftCard(params);

      if (res.status !== 200) {
        throw { message: res?.data?.message?.join(" ") };
      }

      setPaymentAsyncState(asyncStates.successState);
      successModal.openModal();

      if (isAuthorized) {
        posthog.capture("user-purchases-a-gift-card", {
          type,
          id,
          price,
          userId: getUserID(),
        });

        grinEvent({
          amount: price,
          order_number: `${res?.data?.transaction_id}`,
        });
      }
    } catch (err: any) {
      toast.error(err?.message);
      setPaymentAsyncState({
        ...asyncStates.failedState,
        failedMessage: err?.message,
      });
    }
  };

  const savedCards = useSavedCards({ paymentRequest: BuyGiftCardRequest });

  const paymentMethodForm = usePaymentMethodForm({
    paymentRequest: BuyGiftCardRequest,
  });

  const closeSuccessModal = () => {
    successModal.closeModal();
    navigate("/");
  };

  React.useEffect(() => {
    if (
      (savedCards.paymentMethods.success && !savedCards.defaultPaymentMethod) ||
      !isAuthorized
    ) {
      chooseWayToPay.setPayWith(PayWithEnum.new_card);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedCards.paymentMethods.success, savedCards.defaultPaymentMethod]);

  return (
    <div className="position-relative">
      <CongratsPopUp
        isVisible={successModal.isVisible}
        closeModal={closeSuccessModal}
        doneBtnTitle="Done"
      >
        <h3>Congratulations</h3>
        <p>You have bought a gift for {giftTargetName}!</p>
      </CongratsPopUp>
      {isAuthorized && (
        <div className="mt-3">
          <ChooseWayToPay {...chooseWayToPay} />
        </div>
      )}
      {isAuthorized && (
        <div className="mt-3">
          <SavedCards
            {...savedCards}
            disableAll={
              chooseWayToPay.payWith === PayWithEnum.new_card ||
              savedCards.paymentMethods.pending
            }
          />
        </div>
      )}
      <form
        onSubmit={
          chooseWayToPay.payWith === PayWithEnum.new_card
            ? paymentMethodForm.submit
            : savedCards.submit
        }
      >
        <PaymentMethodForm
          {...paymentMethodForm}
          paymentAsyncState={paymentAsyncState}
          disableAll={
            chooseWayToPay.payWith === PayWithEnum.saved_card ||
            savedCards.paymentMethods.pending
          }
        />
        <div className="col-md-12  pt-5 ">
          <img src={Lock} className="me-1" />
          Your transaction is secure
        </div>
        <div className="col-sm-12 mb-5 mt-3">
          <button
            className="btn primary-blue-btn"
            disabled={
              paymentAsyncState.pending || savedCards.paymentMethods.pending
            }
          >
            {paymentAsyncState.pending ? (
              <CircularProgress size={30} />
            ) : (
              "Confirm"
            )}
          </button>
        </div>
      </form>
      {savedCards.paymentMethods.pending && (
        <CircularProgress
          className="position-absolute top-50 start-50 translate-middle"
          size={60}
        />
      )}
    </div>
  );
};

export default BuyGiftCard;
