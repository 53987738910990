import React from "react";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TextField, Stack } from "@mui/material";

interface props {className?: string, onChange: any, value: any };

const DateField = ({className, onChange, value}: props) => {
  return (
    <div className={className}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Stack spacing={3}>
            <MobileDatePicker
              toolbarTitle="Choose date"
              value={value}
              onChange={onChange}
              renderInput={(params: any) => (
                <TextField
                  placeholder="MM-DD-YYYY"
                  {...params}
                  className="due-date-field"
                />
              )}
            />
          </Stack>
        </LocalizationProvider>
    </div>
  );
};

export default DateField;
