import React from "react";
import "./giftCard.scss";
import GCBanner from "./Banner/GCBanner";
import GiftMemberships from "./GiftMemberships/GiftMemberships";
import BestServices from "./BestServices";
import AllServices from "./AllServices/AllServices";

const GiftCard = () => {
  return (
    <div className="GiftCardMain">
      <GCBanner />
      <GiftMemberships />
      <BestServices />
      <AllServices />
    </div>
  );
};

export default GiftCard;
