import React, { useState } from "react";
import usePaymentMethodForm from "../Components/PaymentMethodForm/usePaymentMethodForm";
import * as asyncStates from "../../../Utils/asyncState";
import PaymentMethodForm from "../Components/PaymentMethodForm";
import { CircularProgress } from "@mui/material";
import { postBuyCourse } from "../paymentsAPI";
import { getUserID } from "../../../Service/getLocalStorage";
import { toast } from "react-toastify";
import SavedCards from "../Components/SavedCards";
import useSavedCards from "../Components/SavedCards/useSavedCards";
import ChooseWayToPay from "../Components/ChooseWayToPay/ChooseWayToPay";
import useChooseWayToPay, {
  PayWithEnum,
} from "../Components/ChooseWayToPay/useChooseWayToPay";
import Lock from "../../../Assets/img/lock.svg";
import { usePostHog } from "posthog-js/react";
import { purchaseEvent } from "../../../Utils/gtm";
import { grinEvent } from "../../../Utils/grin";
import { formatSlug } from "../../../Utils/Regex";

interface BuyCourseProps {
  courseData: any;
  successCB: (params: any) => void;
  maxWidth?: string;
}

const BuyCourse = ({ courseData, successCB, maxWidth }: BuyCourseProps) => {
  const posthog = usePostHog();

  const chooseWayToPay = useChooseWayToPay();

  const [paymentAsyncState, setPaymentAsyncState] =
    useState<asyncStates.AsyncState>(asyncStates.initState);

  const buyCourseRequest = async (fields: any) => {
    let params = {
      ...fields,
      total_amount: courseData?.total_amount,
      coupon_amount: courseData?.coupon_amount,
      user_id: getUserID(),
      course_id: courseData?.course_id,
      coupon_id: courseData?.coupon_id,
      tax: courseData?.tax,
      amount: courseData?.amount,
    };

    try {
      setPaymentAsyncState(asyncStates.pendingState);

      const res: any = await postBuyCourse(params);

      if (res.status !== 200) {
        throw { message: res?.data?.message?.join(" ") };
      }

      setPaymentAsyncState(asyncStates.successState);
      successCB(courseData?.course_id);

      purchaseEvent({
        ecommerce: {
          value: courseData?.amount,
          transaction_id: `${res?.data?.transaction_id}`,
          coupon: courseData?.coupon_id || "",
          finalAmount: courseData.total_amount,
        },
        items: [
          {
            item_name: courseData?.data?.title,
            item_id: courseData?.course_id,
            price: courseData?.amount,
            quantity: "1",
          },
        ],
      });

      posthog.capture("user-purchases-a-course", {
        courseName: courseData?.data?.title,
        courseId: courseData?.course_id,
        price: courseData?.amount,
        userId: getUserID(),
        coupon: courseData.coupon_id || "",
        finalAmount: courseData.total_amount,
      });

      posthog.capture(`[course-purchased]:${formatSlug(courseData?.data?.title || '')}`, {
        $set: { "last-course-purchased": formatSlug(courseData?.data?.title || '') },
      });

      grinEvent({
        amount: courseData?.total_amount, 
        order_number: `${res?.data?.transaction_id}` 
      });
    } catch (err: any) {
      toast.error(err?.message);
      setPaymentAsyncState({
        ...asyncStates.failedState,
        failedMessage: err?.message,
      });
    }
  };

  const savedCards = useSavedCards({ paymentRequest: buyCourseRequest });

  const paymentMethodForm = usePaymentMethodForm({
    paymentRequest: buyCourseRequest,
  });

  React.useEffect(() => {
    if (savedCards.paymentMethods.success && !savedCards.defaultPaymentMethod) {
      chooseWayToPay.setPayWith(PayWithEnum.new_card);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedCards.paymentMethods.success, savedCards.defaultPaymentMethod]);

  return (
    <div className="position-relative">
      <div className="mt-3">
        <ChooseWayToPay {...chooseWayToPay} />
      </div>
      <div className="mt-3">
        <SavedCards
          {...savedCards}
          disableAll={
            chooseWayToPay.payWith === PayWithEnum.new_card ||
            savedCards.paymentMethods.pending
          }
        />
      </div>
      <form
        onSubmit={
          chooseWayToPay.payWith === PayWithEnum.new_card
            ? paymentMethodForm.submit
            : savedCards.submit
        }
      >
        <PaymentMethodForm
          {...paymentMethodForm}
          paymentAsyncState={paymentAsyncState}
          maxWidth={maxWidth}
          disableAll={
            chooseWayToPay.payWith === PayWithEnum.saved_card ||
            savedCards.paymentMethods.pending
          }
        />
        <div className="col-md-12  pt-5 ">
          <img src={Lock} className="me-1" />
          Your transaction is secure
        </div>
        <div className="col-sm-12 mb-5 mt-3">
          <button
            className="btn primary-blue-btn"
            disabled={
              paymentAsyncState.pending || savedCards.paymentMethods.pending
            }
          >
            {paymentAsyncState.pending ? (
              <CircularProgress size={30} />
            ) : (
              "Confirm"
            )}
          </button>
        </div>
      </form>
      {savedCards.paymentMethods.pending && (
        <CircularProgress
          className="position-absolute top-50 start-50 translate-middle"
          size={60}
        />
      )}
    </div>
  );
};

export default BuyCourse;
