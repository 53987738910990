import React, { useState } from 'react'
import { CHECKMAIL, CONTACT } from '../../Routes/RouteConstent';
import { Link, useNavigate } from 'react-router-dom';
import { emailRegex } from '../../Utils/Regex';
import { UserForgotPassword } from '../../Service/userAuth';
import { CircularProgress } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';


const ForgotPassword = () => {
    let Navigate =  useNavigate()
    let [formData, setFormData] = useState({
        email: "",
        confirmEmail: ""
    })
    const [error, setError] = useState({
        email: "",
        confirmEmail: ""
    })
    const [isLoading, setIsLoading] = useState<boolean>(false)
    let handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let name = e.target.name;
        let value = e.target.value;
        if (name === "email") {
            if (!emailRegex.test(value)) {
                setError({
                    ...error,
                    email: "Please enter a valid email"
                })
            } else {
                setError({
                    ...error,
                    email: ""
                })
            }
        }
        if (name === "confirmEmail") {
            if (value !== formData.email) {
                setError({
                    ...error,
                    confirmEmail: "Email and confirm email are not mathched"
                })
            } else {
                setError({
                    ...error,
                    confirmEmail: ""
                })
            }
        }
        setFormData({
            ...formData,
            [name]: value
        })

    }


    let submitLogin = async (e: React.FormEvent<HTMLFormElement>) => {
        try {
            e.preventDefault();
            if (!formData.email  || !emailRegex.test(formData.email)
                ) {
                let errors: any = {}
                if (!emailRegex.test(formData.email)) {
                    errors.email = "Please enter a valid email";
                }
                if (!formData.email) {
                    errors.email = "Please enter your email";
                }
                setError(errors)
            } else {
                setIsLoading(true)
                let res = await UserForgotPassword({ email: formData.email })
                
                let isSuccess = res.data.success;
                let msgArr = res.data.message;
                
                let msg = "";
                for(let i = 0; i < msgArr.length; i++){
                    msg += msgArr[i];
                }
                if(isSuccess){
                    Navigate(CHECKMAIL)
                }
                if(!isSuccess){
                    toast.error(msg)
                }
                setIsLoading(false)

            }
        } catch (error) {
            setIsLoading(false)

        }
    }


    return (
        <div>
            <ToastContainer/>
            <div className="container-fluid forgot-password-page">
                <div className="row justify-content-center">
                    <div className='col-lg-6 col-md-6'>
                    <div className="login-inner py-3">
                        <div className="text-center top-image">
                        </div>
                        <h1>Forgot Password?</h1>
                        <form onSubmit={submitLogin}>
                            <div className="form-group mb-4">
                                <label>Email</label>
                                <input type="email" value={formData.email} name="email"
                                    className={`${error.email && "error-input"} form-control`}
                                    id="exampleInputEmail1"
                                    placeholder="Type something"
                                    onChange={handleChange}
                                />
                                <p className="error">{error.email}</p>
                            </div>
                            <div className="text-center forget-password">
                            </div>
                            <button type="submit" disabled={isLoading} className="btn sign-inbtn primary-blue-btn">{isLoading ? <CircularProgress /> : "Reset Password"}</button>
                        </form>
                        <div className="text-center have-account mb-5">
                            <p>Having trouble? <Link to={CONTACT} className="bold" >Contact us</Link></p>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ForgotPassword
