import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


const Transcript = ({ open, setOpen,data }: any) => {
  
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"

            >
                <DialogTitle id="alert-dialog-title">
                    {"Transcript"}
                </DialogTitle>
                <DialogContent className='alert_dialog_description'>
                    <DialogContentText id="alert-dialog-description" >
                        {data}
                    </DialogContentText>
                </DialogContent>

            </Dialog>
        </div>
    )
}

export default Transcript

