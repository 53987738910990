import React from "react";
import {
  ConsultationBasicDataForm,
  initFields,
  requiredFields,
} from "../Modules/ConsultationBasicDataForm";
import CheckboxList from "../Components/CheckboxList";
import useSubmit from "../Hooks/useSubmit";
import CongratsPopUp from "../../Common/CongratsPopUp";
import { CircularProgress } from "@mui/material";

const NewbornSleepConsult = () => {
  const {
    onSubmit,
    submitState,
    successModal,
    fields,
    errors,
    handleChange,
    handleChangeDate,
    handleChangeCheckbox,
    checkboxFields,
  } = useSubmit({
    formName: "Boston NAPS Sleep Consult Note (0-12 Weeks)",
    requiredFields: [...requiredFields],
    initFields: {
      ...initFields,
      quickTip1Naps: "",
      quickTip2Feeding: "",
      quickTip3WakeUp: "",
      yourPlanDetails: "",
    },
    initCheckboxFields: {
      daytimeAndOvernightSleep: [],
      safeSleep: [],
      getLongerStretchesOvernight1: [],
      getLongerStretchesOvernight2: [],
      getLongerStretchesOvernight3: [],
    },
  });

  return (
    <div className="container">
      <CongratsPopUp
        closeModal={successModal.closeModal}
        isVisible={successModal.isVisible}
      >
        <div className="notesLandingPagesSubmitModalTitle">Success!</div>
      </CongratsPopUp>
      <h1 className="py-5">Boston NAPS Sleep Consult Note (0-12 Weeks)</h1>
      <form onSubmit={onSubmit}>
        <ConsultationBasicDataForm
          fields={fields}
          errors={errors}
          handleChange={handleChange}
          handleChangeDate={handleChangeDate}
        />

        <CheckboxList
          className="mb-3"
          mainLabel="Daytime & Overnight Sleep"
          name="daytimeAndOvernightSleep"
          onChange={handleChangeCheckbox}
          value={checkboxFields.daytimeAndOvernightSleep}
          options={[
            "Normal awake windows for your baby are 30-90 minutes",
            "Normal naps are anywhere from 20 minutes, to 45 minutes, to even 2 hours",
            "Focus on awake windows and sleep cycles:",
            "If you are nearing the 90-minute window, proactively work to get your baby to fall asleep",
            "Do this by: Swaddling your baby, getting them on their side or tummy on you, swinging or bouncing them, shhhing or offering white noise, and then offering sucking if needed (such as a pacifier or finger)",
            "Wake your sleeping baby if it’s been almost 3 hours and if it is time to eat during the day",
            "Remember, more calories in during the day will equal better sleep overnight!",
            "You are not making or breaking any bad habits",
            "It’s okay if naps are on the go, or in a carrier, swing or bassinet/crib",
          ]}
        />

        <CheckboxList
          className="mb-3"
          mainLabel="Safe Sleep"
          name="safeSleep"
          onChange={handleChangeCheckbox}
          value={checkboxFields.safeSleep}
          options={[
            "Babies should be placed on their backs for sleep",
            "In a tight swaddle that cannot come loose",
            "With a pacifier if you are using one",
            "There should be no bumpers, stuffed animals or loose blankets",
            "The bassinet or crib mattress should be firm",
          ]}
        />

        <CheckboxList
          className="mb-3"
          mainLabel="How to get longer stretches overnight: Step 1"
          name="getLongerStretchesOvernight1"
          onChange={handleChangeCheckbox}
          value={checkboxFields.getLongerStretchesOvernight1}
          options={["Watch your daytime feeds and sleep cycles as noted above"]}
        />

        <CheckboxList
          className="mb-3"
          mainLabel="How to get longer stretches overnight: Step 2"
          name="getLongerStretchesOvernight2"
          onChange={handleChangeCheckbox}
          value={checkboxFields.getLongerStretchesOvernight2}
          options={[
            "Around 6-8 weeks, stop changing your baby’s diaper overnight",
            "Put them to bed in an overnight diaper, swaddle and in the dark with white noise",
            "For overnight feeds, pick them up swaddled, in the dark and minimize stimulation and feed them",
            "Burp for 2 minutes, then place them right back down to sleep",
            "Note: You can change the diaper if your baby is younger than this, or if you know they have pooped",
          ]}
        />
        <CheckboxList
          className="mb-3"
          mainLabel="How to get longer stretches overnight: Step 3"
          name="getLongerStretchesOvernight3"
          onChange={handleChangeCheckbox}
          value={checkboxFields.getLongerStretchesOvernight3}
          options={[
            "At night, when your baby is fussing, or stirring, follow our NAPS acronym:",
            "N – First do NOTHING. Is your baby actually awake? Will they put themselves back to sleep? Wait. Still fussing?",
            "A – Next is audio. Can you increase white noise, or offer shhing to your baby, while…",
            "P – Apply pressure to their chest, and rock them back and forth or side to side in the crib or bassinet.",
            "S – Then offer sucking, such as a pacifier or your finger.",
            "*If this works even for only 5 minutes, or a half hour, or an hour and your baby goes back to sleep, this is a WIN!",
          ]}
        />

        <div className="form-group col-lg-12 col-md-12 mb-3">
          <label>Quick tip #1 for what is NORMAL:</label>
          <textarea
            value={fields.quickTip1Naps}
            name="quickTip1Naps"
            onChange={handleChange}
            className="form-control"
            placeholder="Normal number of naps for this age is ..."
          />
        </div>
        <div className="form-group col-lg-12 col-md-12 mb-3">
          <label>Quick tip #2 for what is NORMAL:</label>
          <textarea
            value={fields.quickTip2Feeding}
            name="quickTip2Feeding"
            onChange={handleChange}
            className="form-control"
            placeholder="Normal number of feedings for this age is ..."
          />
        </div>
        <div className="form-group col-lg-12 col-md-12 mb-3">
          <label>Quick tip #3 for what is NORMAL:</label>
          <textarea
            value={fields.quickTip3WakeUp}
            name="quickTip3WakeUp"
            onChange={handleChange}
            className="form-control"
            placeholder="Normal number of wake-ups at night for this age is ..."
          />
        </div>

        <div className="form-group col-lg-12 col-md-12 mb-3">
          <label>More details about your plan:</label>
          <textarea
            value={fields.yourPlanDetails}
            name="yourPlanDetails"
            onChange={handleChange}
            className="form-control"
          />
        </div>

        <div className="d-flex justify-content-end">
          <button className="w-auto primary-blue-btn px-5 my-5 notesLandingPagesSubmitBtn">
            {submitState.pending ? <CircularProgress size={24} /> : "SUBMIT"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default NewbornSleepConsult;
