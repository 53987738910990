import { CircularProgress } from "@mui/material";
import React from "react";
import "./paymentMethod.scss";
import Card from "./Card";
import { Button } from "react-bootstrap";
import useModalWindowControls from "../../Hooks/useModal";
import NewPaymentMethodPopUp from "./NewPaymentMethodPopUp";
import ConfirmDeletePaymentMethodPopUp from "./ConfirmDeletePaymentMethodPopUp";
import usePaymentMethod from "./usePaymentMethod";

function PaymentMethod() {
  const {
    paymentsMethodsRequest,
    addPaymentMethodRequest,
    removePaymentMethodRequest,
    makePrimaryPaymentMethod,
    clearPostNewPaymentMethod,
    clearRemovePaymentMethodRequest,
    paymentMethods,
    postNewPaymentMethod,
    removePaymentMethod,
    primaryPaymentMethod,
  } = usePaymentMethod();

  const addCardModal = useModalWindowControls();
  const removeCardModal = useModalWindowControls();

  const paymentMethodsList = paymentMethods.data?.methods?.data ?? [];

  const defaultPaymentMethod =
    paymentMethods.data?.customer?.invoice_settings?.default_payment_method;

  return (
    <div className="paymentMethodMain">
      <NewPaymentMethodPopUp
        isVisible={addCardModal.isVisible}
        closeModal={addCardModal.closeModal}
        postNewPaymentMethod={postNewPaymentMethod}
        clearPostNewPaymentMethod={clearPostNewPaymentMethod}
        addPaymentMethodRequest={addPaymentMethodRequest}
      />
      <ConfirmDeletePaymentMethodPopUp
        isVisible={removeCardModal.isVisible}
        closeModal={removeCardModal.closeModal}
        removePaymentMethod={removePaymentMethod}
        removePaymentMethodRequest={removePaymentMethodRequest}
        clearRemovePaymentMethodRequest={clearRemovePaymentMethodRequest}
        id={removeCardModal.openModalData}
      />
      <h5 className="section-title">Credit cards</h5>
      {paymentMethods.pending && (
        <div className="paymentMethodEmpty my-3 d-flex w-100 edit-profile-payment-history-container align-items-center justify-content-center py-3 px-4">
          <CircularProgress />
        </div>
      )}
      {paymentMethods.failed && (
        <div className=" paymentMethodEmptymy-3 d-flex w-100 edit-profile-payment-history-container align-items-center justify-content-center py-3 px-4">
          <button
            className="btn primary-blue-small-btn-40"
            onClick={paymentsMethodsRequest}
          >
            Refresh
          </button>
        </div>
      )}
      {paymentMethods.success && !paymentMethodsList.length && (
        <div className="paymentMethodEmpty my-3 d-flex w-100 edit-profile-payment-history-container align-items-center justify-content-center py-3 px-4">
          <Button
            className="btn primary-blue-small-btn-40"
            onClick={addCardModal.openModal}
          >
            Add your first card
          </Button>
        </div>
      )}
      {paymentMethods.success && (
        <>
          {paymentMethodsList.map((method: any) => (
            <Card
              method={method}
              key={method.id}
              className="mt-3"
              openRemoveCardModal={removeCardModal.openModal}
              defaultPaymentMethod={defaultPaymentMethod}
              makePrimaryPaymentMethod={makePrimaryPaymentMethod}
              primaryPaymentMethod={primaryPaymentMethod}
            />
          ))}
        </>
      )}
      {paymentMethods.success &&
        !!paymentMethodsList.length &&
        paymentMethodsList.length < 2 && (
          <div className="paymentMethodsAddButtonWrapper mt-3">
            <Button
              className="btn primary-blue-small-btn-40"
              onClick={addCardModal.openModal}
            >
              Add card
            </Button>
          </div>
        )}
    </div>
  );
}

export default PaymentMethod;
