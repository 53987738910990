import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Box, Tab } from '@mui/material'
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import LockScreen from '../../Common/LockScreen/LockScreen';
import { getFavEvent, getPastEventData, getUpComingEvent } from '../../Redux/Events/EventsReducer';
import FilterSearch from './FilterSearch';
import Tab1 from './Tab1';
import Tab2 from './Tab2';
import Tab3 from './Tab3';
import "./Tabs.scss";
import { EVENTS } from '../../Routes/RouteConstent';
import { useNavigate } from 'react-router-dom';

const Tabs = () => {

    let dispatch = useDispatch()
    const Navigate = useNavigate()

    const [value, setValue] = React.useState("1");
    const [upcomingData, setUpComingData] = React.useState([])
    const [pastData, setPastData] = React.useState([])
    const [favData, setFavData] = React.useState([])
    const [isLoading, setIsLoading] = React.useState<boolean>(false)

    const param = new URLSearchParams(window.location.search)
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };
    let user: any = localStorage.getItem("Nurture_user_data");
    user = JSON.parse(user)

    const {pastEvents, upComingEvents, favEvent} = useSelector((res: any) => res?.events)

    React.useEffect(() => {
        pastEvents && setPastData(pastEvents);
      }, [pastEvents]);
    
      React.useEffect(() => {
        upComingEvents && setUpComingData(upComingEvents);
      }, [upComingEvents]);
    
      React.useEffect(() => {
        favEvent && setFavData(favEvent);
      }, [favEvent]);
    
      React.useEffect(() => {
        const getAllEvents = async () => {
          try {
            setIsLoading(true);
            await dispatch(getPastEventData());
            await dispatch(getFavEvent());
            await dispatch(getUpComingEvent());
    
            setIsLoading(false);
          } catch (error) {
            setIsLoading(false);
          }
        };
    
        getAllEvents();
      }, [dispatch]);

    const setStep = (id: any) => {
        Navigate(`${EVENTS}?tab=${id}`)
    }
    React.useEffect(() => {
        let tabId = param.get("tab")
        if (tabId) {
            setValue(tabId)
        } else {
            setValue("1")
        }
    }, []);

    return (
        <div>
            <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={value}>
                    <Box
                        className="event-tabs-main"
                    >
                        <div className='d-flex justify-content-center'>
                            <TabList onChange={handleChange} aria-label="lab API tabs example" className='askN-tabs'>
                                <Tab
                                    onClick={() => setStep(1)}
                                    label="Upcoming Live" value="1"
                                    sx={{ border: 1, paddingLeft: 10, paddingRight: 10, borderTopLeftRadius: 50, borderTopRightRadius: 0, borderBottomRightRadius: 0, borderBottomLeftRadius: 50 }}
                                />
                                <Tab label="Past Events" value="2"
                                    sx={{ border: 1, paddingLeft: 10, paddingRight: 10, borderTopLeftRadius: 0, borderTopRightRadius: 50, borderBottomRightRadius: 50, borderBottomLeftRadius: 0 }}
                                    onClick={() => setStep(2)}
                                />
                                <Tab label="Favorites" value="3"
                                    sx={{ border: 1, paddingLeft: 10, paddingRight: 10, borderTopLeftRadius: 0, borderTopRightRadius: 50, borderBottomRightRadius: 50, borderBottomLeftRadius: 0 }}
                                    onClick={() => setStep(3)}
                                />

                            </TabList>
                        </div>
                    </Box>
                    <FilterSearch value={value} upcomingEvent={upcomingData}
                        setUpcomingEvent={setUpComingData} pastEvent={pastData}
                        setPastEvent={setPastData} favEvent={favData} setFavEvent={setFavData} />
                    <TabPanel value="1" className='upcoming-live-event-container'>
                        <Tab1 data={upcomingData} setData={setUpComingData} loading={isLoading} />
                    </TabPanel>
                    <TabPanel value="2" className='past-event-container'>
                        {
                            user?.is_stripe_membership ? <Tab2 data={pastData} setData={setPastData} /> :
                                <LockScreen />
                        }
                    </TabPanel>
                    <TabPanel value="3" className='favourite-event-container'>
                        <Tab3 data={favData} />
                    </TabPanel>
                </TabContext>
            </Box>
        </div>
    )
}

export default Tabs