import httpService from "./httpService";
interface cancelMem {
    user_id: string | number,
    membership_id: string | number
}
interface codeMem {
    user_id: string | number,
    company_code: string | number
}
export const getMembershipPlan = () => {
    return httpService.get("membership/")
}

export const onBoardingFamily = (data: any) => {
    return httpService.post("onboarding/family", data)
}

export const getCoupenData = (data: any) => {
    return httpService.post("coupon/check", data)
}

export const confirmMembership = (data: any) => {
    return httpService.post("membership/confirm", data)
}
export const cancelMembershipApi = (data: cancelMem) => {
    return httpService.post("membership/cancel", data)
}

export const companyCodeMembershipApi = (data: codeMem) => {
    return httpService.post("membership/company-code/update", data)
}

export const getMembershipPreLogin = () => {
    return httpService.post("pre-login")
}
// export const getCountryApi = () => {
//     return httpService.post("country")
// }

// export const getStateApi = (id: any) => {
//     return httpService.post("state", id)
// }
interface cPayment {
    user_id: string,
    user_membership_id: string
}
export const confirmPaymentApi = (data: cPayment) => {
    return httpService.post("onboarding/company-code/membership/confirm", data)
} 