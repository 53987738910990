import React from "react";
import { Routes, Route } from "react-router-dom";
import TipsToStopStressing from "./TipsToStopStressing";
import TipsToSleep from "./TipsToSleep";
import WhyDoAdvocate from "./WhyDoAdvocate";
import MentalHealth from "./MentalHealth";

export const screens = {
  TipsToStopStressing,
  TipsToSleep,
  WhyDoAdvocate,
  MentalHealth,
};

export const navigateLinks: { [key: string]: string } = {
  magnet_TipsToStopStressing: "/9-tips-to-stop-stressing-and-start-thriving",
  magnet_TipsToSleep: "/6-simple-steps-to-more-sleep",
  magnet_WhyDoAdvocate: "/how-and-why-to-advocate-for-your-labor-and-delivery",
  magnet_MentalHealth: "/perinatal-mental-health-resources",
};

export const navigateLinksList: string[] = Object.values(navigateLinks);

export const MagnetRouter = () => (
  <Routes>
    <Route
      path={navigateLinks.magnet_TipsToStopStressing}
      element={<screens.TipsToStopStressing />}
    />
    <Route
      path={navigateLinks.magnet_TipsToSleep}
      element={<screens.TipsToSleep />}
    />
    <Route
      path={navigateLinks.magnet_WhyDoAdvocate}
      element={<screens.WhyDoAdvocate />}
    />
    <Route
      path={navigateLinks.magnet_MentalHealth}
      element={<screens.MentalHealth />}
    />
  </Routes>
);
