import React from "react";
import usePaymentMethod from "../../../PaymentMethod/usePaymentMethod";

const useSavedCards = ({
  paymentRequest,
}: {
  paymentRequest: ({ savedCardNumber }: { savedCardNumber: string }) => void;
}) => {
  const [savedCard, setSavedCard] = React.useState("");
  const [showError, setShowError] = React.useState(false);

  const { paymentMethods } = usePaymentMethod();

  const paymentMethodsList = paymentMethods.data?.methods?.data ?? [];

  const defaultPaymentMethod =
    paymentMethods.data?.customer?.invoice_settings?.default_payment_method;

  const changeSavedCard = (id: string) => {
    setShowError(false);
    setSavedCard(id);
  };

  const submit = (e?: React.FormEvent<HTMLFormElement>) => {
    if (e) {
      e.preventDefault();
    }
    if (!savedCard) {
      setShowError(true);
    } else {
      setShowError(false);
      paymentRequest && paymentRequest({ savedCardNumber: savedCard });
    }
  };

  React.useEffect(() => {
    if (!savedCard && defaultPaymentMethod) {
      setShowError(false);
      setSavedCard(defaultPaymentMethod);
    }
  }, [defaultPaymentMethod, savedCard]);

  return {
    showError,
    paymentMethods,
    paymentMethodsList,
    savedCard,
    setSavedCard: changeSavedCard,
    submit,
    defaultPaymentMethod,
  };
};

export default useSavedCards;
