import React from "react";
import Logo from "../Logo";
import "./wrapper.scss";

import Facebook from "../../../Assets/img/footer/Facebook.svg";
import Instagram from "../../../Assets/img/footer/instagram.svg";
import Pintrest from "../../../Assets/img/footer/pinterest.svg";
import { Link } from "react-router-dom";

interface Props {
  children: JSX.Element | string | JSX.Element[];
}

const Wrapper = ({ children }: Props) => {
  return (
    <div className="magnetScreensWrapper">
      <header className="magnetScreensHeader">
        <Link to="/">
          <Logo className="magnetScreensLogo" />
        </Link>
      </header>
      <main className="magnetScreensMain">{children}</main>
      <footer className="magnetScreensFooter">
        <div className="magnetScreensFooter__inner">
          <div className="magnetScreensFooter__top">
            <Link to="/">
              <Logo className="magnetScreensFooter__logo" fill="#fff" />
            </Link>
            <h3 className="magnetScreensFooter__title">
              Newborn &<br /> Parenting Support
            </h3>
            <div className="magnetScreensFooter__social">
              <a
                href="https://www.facebook.com/NurturebyNAPS/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={Facebook} alt="" className="rounded" />
              </a>
              <a
                href="https://www.instagram.com/nurturebynaps/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={Instagram} alt="" />
              </a>
              <a
                href="https://www.pinterest.com/nurturebynaps/_created/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={Pintrest} alt="" />
              </a>
            </div>
          </div>
          <div className="magnetScreensFooter__bottom">
            <p className="magnetScreensFooter__copyright">Copyright © 2023 NAPS. All Rights Reserved.</p>
            <Link className="magnetScreensFooter__nurturebynaps" to="/">nurturebynaps.com</Link>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Wrapper;
