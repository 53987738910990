import React from "react";
import "react-toastify/dist/ReactToastify.css";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import "./newPaymentMethodPopUp.scss";
import StripeForm from "../StripeForm";

const NewPaymentMethodPopUp = ({
  isVisible,
  closeModal,
  title = "Add card",
  description,
  postNewPaymentMethod,
  clearPostNewPaymentMethod,
  addPaymentMethodRequest,
}: any) => {
  React.useEffect(() => {
    if (postNewPaymentMethod.success) {
      clearPostNewPaymentMethod();
      closeModal();
    }
  }, [clearPostNewPaymentMethod, closeModal, postNewPaymentMethod.success]);

  return (
    <div>
      <Dialog
        open={isVisible}
        onClose={closeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="modal-500"
      >
        <DialogContent>
          <IconButton
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon onClick={closeModal} />
          </IconButton>

          <DialogContentText>
            <div className="d-flex justify-content-center flex-wrap">
              <h2 className="memberPopUpTitle">{title}</h2>
              {description && <p className="text-center mt-2">{description}</p>}
              {isVisible && (
                <StripeForm
                  addPaymentMethodRequest={addPaymentMethodRequest}
                  postNewPaymentMethod={postNewPaymentMethod}
                />
              )}
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default NewPaymentMethodPopUp;
