import React from "react";
import Wrapper from "./Components/Wrapper";
import MagnetHero from "./Components/MagnetHero";
import OwnersStory from "./Components/OwnersStory";
import SendGuideForm from "./Components/SendGuideForm";
import advocate from "./assets/advocate.png";

const WhyDoAdvocate = () => {
  return (
    <Wrapper>
      <MagnetHero image={advocate}>
        <h3>Free download!</h3>
        <h1 className="mt-2 mt-md-2">
          How & Why to{" "}
          <div className="teal strong">
            Advocate for Your Labor and Delivery
          </div>
        </h1>
      </MagnetHero>
      <SendGuideForm
        event="PregnancyLeadMagNew"
        description={
          <>
            So many "experts" are out there offering parenting advice—how can
            you know who to trust? It can be overwhelming.{" "}
            <span className="strong">
              We created this free guide to empower expecting parents with the
              knowledge and tools they need to be their own best advocate in the
              delivery room including what it means to advocate for yourself (or
              your partner), why it's imporant, 6 ways steps you can take to
              self advocacy, and assembly your advocacy team.
            </span>
          </>
        }
      />
      <OwnersStory />
    </Wrapper>
  );
};

export default WhyDoAdvocate;
