import React from 'react';
import StepsForDate from './DateConsults/StepForDateConsult';

const DateConsult = () => {
  

  return (
    <div>
      <StepsForDate/>
    </div>
  )
}

export default DateConsult
