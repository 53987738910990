import React from "react";
import "./AllServices.scss";
import ServiceCard from "./ServiceCard";
import serviceData from "../../../Service/serviceData";
import useModalWindowControls from "../../../Hooks/useModal";
import GiftCardDetailsModal from "../GiftCardDetailsModal";
import { capitalize } from "lodash";

const AllServices = () => {
  const sendGiftModal = useModalWindowControls();

  return (
    <>
      <GiftCardDetailsModal
        isVisible={sendGiftModal.isVisible}
        closeModal={sendGiftModal.closeModal}
        openModalData={sendGiftModal.openModalData}
      />
      <h2 className="AllServicesLayerTitle">
        <svg
          className="AllServicesLayerCurlyLayer"
          viewBox="0 0 534 312"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M112.673 124.925C132.529 138.948 147.818 155.698 158.133 174.72C169.511 195.713 172.902 216.284 168.229 235.877C162.605 259.402 143.979 282.384 119.619 295.84C118.419 296.5 117.197 297.145 115.968 297.759C89.2868 311.074 57.2732 312.116 41.4916 300.186C26.804 289.08 24.8362 268.359 35.6734 238.598C50.1112 198.918 75.6125 159.819 109.432 125.524L111.116 123.814L112.687 124.927L112.673 124.925ZM-72.2604 84.9818C-72.3281 85.6126 -72.4145 86.2351 -72.5071 86.8695C-51.1569 84.3884 -30.4118 83.7191 -10.7637 84.9407C27.2613 87.2981 62.9303 96.8099 92.3463 112.454C97.4443 115.168 102.417 118.095 107.125 121.162L108.35 121.955L107.084 123.228C72.4167 158.183 46.304 198.061 31.5633 238.559C20.3416 269.4 22.5324 291 38.0878 302.763C54.9843 315.55 89.179 314.49 117.616 300.299C118.906 299.655 120.201 298.974 121.452 298.288C147.033 284.161 166.563 260.131 172.433 235.587C177.251 215.423 173.795 194.312 162.165 172.848C151.551 153.282 135.768 136.085 115.227 121.745L114.051 120.919L115.349 119.656C127.513 107.844 140.719 96.6187 154.566 86.2935C174.28 71.6093 195.788 58.4177 218.473 47.0969C263.564 24.5943 311.618 10.2705 357.441 5.67272C392.639 2.13644 427.184 4.59775 457.357 12.7786C487.431 20.9302 512.52 34.642 530.027 52.4797L533.722 50.1532C515.89 31.968 490.322 17.9862 459.679 9.68C428.983 1.35581 393.836 -1.13852 358.026 2.4575C311.456 7.13821 262.646 21.6815 216.842 44.5396C193.826 56.0253 172.004 69.4087 152.014 84.301C138.217 94.5752 125.056 105.743 112.915 117.482L111.284 119.06L109.749 118.07C105.171 115.105 100.336 112.268 95.3848 109.638C65.5105 93.7577 29.3194 84.1025 -9.30794 81.7121C-29.3161 80.4771 -50.4482 81.1551 -72.1809 83.6955C-72.1875 84.1202 -72.2188 84.5484 -72.2666 84.9937"
            fill="#62CCB8"
          />
        </svg>

        <span className="AllServicesLayerTextWrapper">
          Give the gift of <strong>support</strong> through these
        </span>
        <br />

        <div className="AllServicesCoursesCurly">Services</div>
      </h2>

      <div className="AllServicesList">
        {serviceData.map((data: any) =>
          data.giftCardOptions.length ? (
            <ServiceCard
              key={data.tittle}
              title={data.tittle}
              description={data.topics
                ?.map((v: string) => capitalize(v))
                .join(". ")}
              src={data.image}
              price={data.giftCardOptions[0].price}
              gift={{
                ...data.giftCardOptions[0],
                price: data.giftCardOptions[0]?.price,
                title: data.tittle || data.title,
                description: data.topics,
                type: "service",
              }}
              selectGift={sendGiftModal.openModal}
              url={data.url}
            />
          ) : null
        )}
      </div>
    </>
  );
};

export default AllServices;
