import React, { useEffect, useMemo, useState } from 'react'
import Steppers from '../../../Common/Stepper/Stepper';
import AppoinmentDetail from './Steps/AppoinmentDetail';
import ChooseDate from './Steps/ChooseDate';
import UserInfo from './Steps/UserInfo';
import { useLocation, useNavigate } from 'react-router-dom';
import { bookConsultApi, getFormFieldApi, gethostNameApi } from '../../../Service/Cousult.service';
import { getDataFromLocalStorage } from '../../../Service/getLocalStorage';
import CongratsModel2 from '../../../Common/CongratsPopup2/CongratsPopup2';
import { GIFTCARD } from '../../../Routes/RouteConstent';
import dayjs from 'dayjs';
import {useMembershipData } from '../../../Utils/getUserData/getUserData';
import isMebershipAndActiveCheck from '../../../Utils/membership_check';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { useQuery } from '../../../Hooks/useQuery';
import { capturePurchasedServiceEvent, useCaptureServiceEvent } from '../../../Service/serviceData';

const StepsForBuy = () => {
  const [userMember] = useMembershipData()
  const Navigate = useNavigate()
  const dispatch = useDispatch()

  const isCheck = (res: any) => {
    isMebershipAndActiveCheck(res, Navigate, toast, dispatch)
  }

  const { state }: any = useLocation()
  let steps = [1, 2, 3]
  const [hostName, setHostName] = useState<any>([])
  const [userInfoVal, setUserInfoVal] = useState<any>([])
  const [activeStep, setActiveStep] = React.useState(0);
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingWash, setLoadingWash] = useState<boolean>(false)
  const [dateVisible, setDateVisible] = useState<Array<number>>([])
  const [modelOpen, setModelOpen] = useState<boolean>(false)
  const [term_condition, setPolicy] = useState(false)
  const [formField, setFormField] = useState<any>([])
  const [date, setDate] = useState<any>({})
  const [serviceId, setServiceId] = useState<any>(1)
  const [objName, setObjName] = useState<any>({})
  
  const serviceParam = useQuery().get('service') || state?.param;

  const stepNames = useMemo(
    () => ['date-step', 'user-step', 'details-step'],
    []
  );
  useCaptureServiceEvent(activeStep, stepNames);

  function getStepContent(step: number) {
    switch (step) {
      case 0:
        return (
          <ChooseDate
            data={hostName}
            value={date}
            setValue={setDate}
            handleNext={handleNext}
            dateVisible={dateVisible}
            loadingWash={loadingWash}
            setDateVisible={setDateVisible}
            setObjName={setObjName}
          />
        );
      case 1:
        return (
          <UserInfo
            handleNext={handleNext}
            value={userInfoVal}
            setValue={setUserInfoVal}
            handlePrev={handlePrev}
            setPolicy={setPolicy}
            term_condition={term_condition}
            field={formField}
            state={state}
            param={"service_1"}
          />
        );
      case 2:
        return (
          <AppoinmentDetail
            host={hostName}
            data={date}
            handleSubmit={confirmHandler}
            handlePrev={handlePrev}
            loading={loading}
            setStep={setActiveStep}
            objName={objName}
          />
        )
      default:
        return "Unknown step";
    }
  }

  useEffect(() => {
    if (userMember) {
      setUserInfoVal({
        ...userInfoVal,
        ["due_date"]: userMember?.due_date,
        ["child_name"]: userMember?.child_name,
        ["is_your_first_baby"]: userMember?.your_first_baby
      })
    }
  }, [userMember])
  const getHostName = async () => {

    try {
      setLoadingWash(true)
      let res: any = await gethostNameApi({ service_prefix: serviceParam })
      isCheck(res)
      if (res?.data?.success) {
        getFormField(res?.data?.service_id)
        setServiceId(res?.data?.service_id)
        setHostName(res.data)
        setLoadingWash(false)
      }

    } catch (error: any) {
      setLoadingWash(false)
    }
  }
  const getFormField = async (id: any) => {
    try {

      let res: any = await getFormFieldApi({ service_id: id })
      isCheck(res)
      if (res?.data?.success) {
        setFormField(res.data.data)
      }

    } catch (error) {
      console.log("error in getting forms", error)
    }
  }



  const confirmHandler = async () => {
    try {
      setLoading(true)
      const localData = getDataFromLocalStorage()
      userInfoVal.due_date = dayjs(userInfoVal.due_date).format("YYYY-MM-DD")
      let param = {
        ...date,
        ...userInfoVal,
        email: localData.email,
        service_id: serviceId,
        service_name: serviceParam,
        first_name: localData.first_name,
        last_name: localData.last_name,
        user_id: localData.id,
        phone: localData?.phone,
        appointment_type_id: hostName?.acuity_appointment_type_id
      }
      let res: any = await bookConsultApi(param)
      isCheck(res)
      let message = res?.data?.message
      let allMsg = "";
      for (let i = 0; i < message.length; i++) {
        allMsg += message[i]
      }
      if (res?.data?.success) {
        setModelOpen(true)
        setLoading(false)
        capturePurchasedServiceEvent(serviceParam);
        return;
      }
      alert(allMsg)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }


  useEffect(() => {
    getHostName()
  }, [])

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      //@ts-ignore
      behavior: "instant"
    })
  }, [activeStep])

  const handleNext = () => {
    setActiveStep(activeStep + 1)
    setDateVisible([])
  };

  const handlePrev = () => {
    setActiveStep(activeStep - 1)
  }
  return (
    <div>
      <CongratsModel2
        open={modelOpen}
        setOpen={setModelOpen}
        btnText="Send gift card to your friend"
        Page={GIFTCARD}
        callBack={() => setActiveStep(0)}
        text={'All information about your upcoming appointment has been sent to your email.<br>In the meantime, feel free to check out our other offerings, or if you have a friend who you think would be interested in the same booking as you, tell them using the button below!'} 
        title="Thank you for booking with NAPS!" 
        />

      <Steppers steps={steps}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        getStepContent={getStepContent}
        handleNext={handleNext} />
    </div>
  )
}

export default StepsForBuy;