import React, { useEffect, useRef, useState } from 'react'
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import moment from 'moment';
import { Form } from 'react-bootstrap';
import { json } from 'stream/consumers';

// let priceArry: any = []
function SingleAddClass({ data, handleNext, objName, value, setValue, price,
    setPrice, slots, setSlots, priceArry, setPriceArry, addOn, setAddOn, selectClasses,
    setSelectClasses, selectSlots, setSelectSlots, classTime, setClassTime }: any) {

    const [error, setError] = useState<any>({})
    const [errorAdd, setErrorAdd] = useState<any>([])
    const [selectSlot, setSelectSlot] = useState<any>([])
    const [classData, setClassData] = useState<any>([])

    const handlePrice = (e: any, values: any, errorCls: any, index: number) => {
        if (!selectClasses[index]?.name || !selectSlots[index]?.appointment_date_time
            || selectSlots[index]?.appointment_date_time === 'Select Appointment' || errorCls) {
            let errors: any = {}
            if (!selectClasses[index]?.name) {
                errors.class = "Please select Class"
            }
            if (!selectSlots[index]?.appointment_date_time) {
                errors.app = "Please select appoinment date"
            }
            if (selectSlots[index]?.appointment_date_time === 'Select Appointment') {
                errors.app = "please select valid date and time"
            }
            if (errorCls) {
                errors.class = "please select valid class name"
            }
            setErrorAdd({
                ...errorAdd,
                [index]: errors
            })
            return;
        } else {
            let newPrice
            if (e.target.checked) {
                // newPrice = Number(values?.price) + Number(price)
                newPrice = 50 + Number(price)
                setSlots([selectSlots[index]])
                setPriceArry([selectSlots[index]?.appointment_type_id])
                let obj = {
                    ["add_class"]: selectClasses[index]?.name,
                    ["slots_time"]: selectSlots[index]?.appointment_date_time,
                    ["someCheckId"]: selectSlots[index]?.appointment_type_id
                }

                setClassData([selectSlots[index]])
                setAddOn(obj)
            } else {
                // newPrice = Number(price) - Number(values?.price)
                newPrice = Number(price) - 50

                let newValue = slots.filter((data: any) => data?.appointment_type_id !== addOn?.someCheckId)
                setSlots(newValue)

                let newValues = priceArry.filter((data: any) => data !== selectSlots[index]?.appointment_type_id)

                setPriceArry(newValues)
                setSelectSlots([])
                setSelectClasses([])
                setAddOn({})
                setClassData([])
            }
            setPrice(newPrice)
            setValue({
                ...value,
                ["someCheck_id"]: ""
            })

            setError({
                ...error,
                ["slot"]: "",
                ["addClass"]: "",
                ["slotTime"]: ""
            })

        }

    }

    const handlePrebabyClass = () => {

        setValue({
            ["slots"]: objName?.multipleSlots,
            ["addon_class"]: classData,
            ["service_name"]: objName?.name,
            ["appointment_date_time"]: objName?.time,
            ["appointment_type_id"]: objName?.appointmentTypeID,
            ["calendar_id"]: objName?.calendarID,
            ["amount"]: objName?.price,
            ["additional_amount"]: price ? price - Number(objName?.price) : 0,
            ["total_ammount"]: price,
            ["addition_class"]: addOn?.add_class,
            ["slot_time"]: addOn?.slots_time,
            ["someCheck_id"]: addOn?.someCheckId
        })
        handleNext()
    }

    const disPlayTime = (date: any) => {
        const Cdate = new Date(date);
        let converted_date = Cdate.toLocaleString('en-US', {
            timeZone: 'America/New_York',
        })
        return moment(converted_date).format("LT")
    }
    const displayDate = (date: any) => {
        const Cdate = new Date(date);
        let converted_date = Cdate.toLocaleString('en-US', {
            timeZone: 'America/New_York',
        })
        return moment(converted_date).format("dddd DD MMM YYYY")
    }

    const getTotalDuration = (slots: any) => {
        var totalDuration = slots?.reduce(function (accumulator: Number, value: any) {
            return accumulator + value.duration;
        }, 0);

        return <React.Fragment><span className='strong consult-time'>{Math.floor(totalDuration / 60)} hours {totalDuration % 60} minutes </span><span className='consult-time'>over {slots?.length} Classes</span></React.Fragment>
    }

    const convertDateToUtc = (data: any) => {
        const Cdate = new Date(data);
        let converted_date = Cdate.toLocaleString('en-US', {
            timeZone: 'America/New_York',
        })
        return moment(converted_date).format("dddd DD MMM YYYY")
    }


    const classChange = (e: any, data: any, index: number) => {

        if (e.target.value === "Select Class") {
            setError({
                ...error,
                [index]: "please select valid class name"
            })
            setPriceArry([])
            setPrice(objName?.price)
            setClassTime([])
            setSelectSlots([])
            let newValue = slots.filter((data: any) => data?.appointment_type_id !== addOn?.someCheckId)
            setSlots(newValue)
        }
        let allValue = JSON.parse(e.target.value)
        setClassTime({ ...classTime, [index]: [] })
        setClassTime({ ...classTime, [index]: allValue.multipleSlots })
        setSelectClasses({
            ...selectClasses,
            [index]: {
                ...selectClasses[index],
                name: allValue.name,
                calendar_id: allValue.calendarID,
                appointment_type_id: allValue.appointmentTypeID
            }
        })
        setErrorAdd({
            ...errorAdd,
            [index]: {
                ...errorAdd[index],
                ["class"]: ""
            }
        })
        setError(
            {
                ...error,
                [index]: ""
            })
    }
    const slotChange = (e: any, data: any, index: any) => {

        setPriceArry([])
        setPrice(objName?.price)
        // setClassTime([])

        setSelectSlots([])
        let newValue = slots.filter((data: any) => data?.appointment_type_id !== addOn?.someCheckId)
        setSlots(newValue)

        setSelectSlot({ ...selectSlot, [index]: [] })
        setSelectSlot({ ...selectSlot, [index]: e.target.value })

        setSelectSlots({
            ...selectSlots,
            [index]: {
                ...selectSlots[index],
                appointment_date_time: e.target.value,
                calendar_id: data?.calendarID,
                appointment_type_id: data?.appointmentTypeID
            }
        })

        setErrorAdd({
            ...errorAdd,
            [index]: {
                ...errorAdd[index],
                ["app"]: ""
            }
        })
        // setPriceArry([])
        // setPrice(objName?.price)
    }
    return (
        <section className='step-container step1'>
            <div className="consult-card-container">
                <div className='d-flex pt-3 px-3 justify-content-between'>
                    <div className='consult-info d-flex flex-column w-100'>
                        <div className='time-projection-details d-flex'>
                            <div className='time-projection'>{moment(objName?.time).startOf('day').fromNow()}</div>
                            <div className='availablity'>{objName?.slotsAvailable}/{objName?.slots} left</div>
                        </div>
                        {/* <div className='consult-date my-3'>Wednesday <span className='strong'>13 May </span> 2022</div> */}
                        <div className='consult-date-time d-flex justify-content-between mt-3 mb-1'>
                            <div className='consult-date'>{displayDate(objName?.time)}</div>
                            <div className='consult-price'>
                                <span className='price'>${objName?.price}</span>
                            </div>
                        </div>
                        <div className='consult-tm-dtls mb-3'>
                            <span className='consult-time'><span className='strong'> {disPlayTime(objName?.time)} EST</span>  </span>
                            <span className='author-name'>| by {objName?.calendar}</span><br />
                            <span>{getTotalDuration(objName?.multipleSlots)}</span>
                        </div>
                    </div>

                </div>

                <div className='additional-class pb-3 px-3'>
                    <hr />
                    <h2 className='text-start pb-0 mb-2 add-head' >Bundle and Save</h2>
                    <p>Add on class to your bootcamp including CPR first aid for infants | Virtual Class.</p> 
                    <hr />
                    {
                        data.map((val: any, i: any) => (
                            <div key={i} className='d-flex align-items-stretch'>
                                <div className='d-flex align-items-center'>
                                    <input
                                        type="checkbox"
                                        className="form-check-input align-self-md-end mb-3"
                                        name="price"
                                        value={addOn?.someCheckId}
                                        onChange={(e) => handlePrice(e, val, error[i], i)}
                                        checked={priceArry.some(
                                        (item: any, i: any) => item === addOn?.someCheckId
                                        )}
                                    />
                                </div>
                                <div className='class-list w-100 d-flex flex-column flex-md-row mb-3 mb-md-0 align-items-end'>
                                    <div className="class-container">
                                        <label>Add On</label>

                                        <Form.Select aria-label="Default select example" onChange={(e) => classChange(e, val, i)} >
                                            <option value={'Select Class'}>Select Class</option>
                                            {data?.map((clVal: any) => (
                                                <option
                                                    selected={selectClasses[i]?.appointment_type_id === clVal?.appointmentTypeID}
                                                    value={JSON.stringify(clVal)} >
                                                    {clVal.name}
                                                </option>
                                            ))}
                                        </Form.Select>
                                        <h4 className="error-msgtext">{errorAdd[i]?.class}</h4>
                                    </div>

                                    <div className="mx-md-2 appointment-container">
                                        <label>Select Appointment</label>
                                        <Form.Select aria-label="Default select example" onChange={(e) => slotChange(e, val, i)} >
                                            <option>Select Appointment</option>
                                            {Array.isArray(classTime[i]) && classTime[i]?.map((clVal: any) => (
                                                <option
                                                    selected={selectSlots[i]?.appointment_date_time === clVal?.time}
                                                    value={clVal.time}>
                                                    {convertDateToUtc(clVal.time)} {disPlayTime(clVal.time)}
                                                </option>
                                            ))}
                                        </Form.Select>
                                        <h4 className="error-msgtext">{errorAdd[i]?.app}</h4>
                                    </div>

                                    <div className='d-flex justify-content-end flex-md-column mb-3'>
                                        <div className='additional-cost mt-0 ms-3 ms-md-0 d-flex'><span className='actual-price text-decoration-line-through'>+${val?.price}</span><span className='strong discounted-price ms-1'> $50</span></div>
                                    </div>
                                </div>
                            </div>
                        ))
                    }

                    <hr />

                    <div className='total-amt d-flex justify-content-between'>
                        <div className='total-amount'>Total <span className="text-decoration-line-through actual-price me-2">{Number(objName?.price) < price ? '$499' : ''}</span><span className='total-value'>${price}</span></div>
                        <div><button className='primary-blue-small-btn-40 btn' onClick={() => handlePrebabyClass()} >Buy Now</button></div>
                    </div>
                </div>


            </div>
        </section>
    )
}

export default SingleAddClass
