import React from 'react';
import { DialogProps } from '@mui/material';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import CityStateZip from '../../../../Common/commonFIeld/cityStateZip';
import { CityStateZipValSub } from '../../../../Common/commonFIeld/validation';
import { Link } from 'react-router-dom';
import * as path from "../../../../Routes/RouteConstent";
import { useQuery } from '../../../../Hooks/useQuery';



const UserInfo = ({ handleNext, value, setValue, handlePrev, term_condition, setPolicy, field, state, error, setError, deliveryType, setDeliveryType }: any) => {
    const serviceParam = useQuery().get('service') || state?.param;

    const handleChange = (e: React.ChangeEvent<HTMLInputElement> | any) => {

        if (e.target.name === "client_service_agreement" || e.target.name === "privacy_practises") {
            if (e.target.checked) {
                setError({
                    ...error,
                    [e.target.name]: ""
                })
            } else {
                setError({
                    ...error,
                    [e.target.name]: "Please select the field"
                })
            }
            setValue({
                ...value,
                [e.target.name]: e.target.checked
            })
            return

        }
        // if (e.target.name === "zip_code") {
        //     if (e.target.value.length !== 5) {
        //         setError({
        //             ...error,
        //             [e.target.name]: "Please enter valid zip code"
        //         })
        //     } else {
        //         setError({
        //             ...error,
        //             [e.target.name]: ""
        //         })
        //     }
        // } else {
        //     setError({
        //         ...error,
        //         [e.target.name]: ""
        //     })
        // }
        setValue({
            ...value,
            [e.target.name]: e.target.value
        })
        if (e.target.name === "delivery_type") {

            setError({
                ...error,
                [e.target.name]: ""
            })
            setValue({
                ...value,
                [e.target.name]: e.target.value
            })
            return
        }
        setValue({
            ...value,
            [e.target.name]: e.target.value
        })

    }

    const dateHandler = (date: Date | null | any, name: string) => {
        setError({
            ...error,
            [name]: "",
        });
        setValue({
            ...value,
            [name]: date,
        });
    };

    const policyHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        setError({
            ...error,
            [e.target.name]: ""
        })
        setPolicy(e.target.checked)
        setValue({
            ...value,
            term_condition: e.target.checked
        })
    }
    const nursingHandleSubmit = (e: any) => {
        e.preventDefault()

        let stateCityZipErr = CityStateZipValSub(value)

        if (!value?.due_date || value?.due_date === "Invalid date"
            || !value?.is_your_first_baby || !value?.client_service_agreement
            || !value?.significant_or_surgical_health_information
            || !value?.specific_question_concerns_topic
            || !term_condition || stateCityZipErr || !value?.privacy_practises
            || !/^\+?[1-9][0-9]{7,14}$/.test(value?.phone)
            || !value?.phone
        ) {
            let errors: any = {}
            if (!value?.due_date) {
                errors.due_date = "Please select date"
            }
            if (value?.due_date === "Invalid date") {
                errors.due_date = "Please select date"
            }
            if (!/^\+?[1-9][0-9]{7,14}$/.test(value?.phone)) {
                errors.phone = "Invalid phone number"
            }
            if (!value?.phone) {
                errors.phone = "Phone number is Required"
            }
            if (!value?.is_your_first_baby) {
                errors.is_your_first_baby = "this select this field"
            }
            if (!value?.significant_or_surgical_health_information) {
                errors.significant_or_surgical_health_information = "Please input the value"
            }
            if (!value?.specific_question_concerns_topic) {
                errors.specific_question_concerns_topic = "Please input the value"
            }
            if (!term_condition) {
                errors.term_condition = "Please select the policy to continue "
            }

            if (!value?.client_service_agreement) {
                errors.client_service_agreement = "Please select the policy to continue"
            }
            if (!value?.privacy_practises) {
                errors.privacy_practises = "Please select the policy to continue"
            }
            setError({ ...errors, ...stateCityZipErr })
            return
        }
        setError({});
        handleNext()

    }
    const intilaLactiationSubmit = (e: any) => {
        e.preventDefault()
        let stateCityZipErr = CityStateZipValSub(value)

        if (!value?.due_date || value?.due_date === "Invalid date"
            || !value?.is_your_first_baby || stateCityZipErr || !value?.client_service_agreement
            || !value?.medical_surgical_history_baby || !value?.mother_dob || !value?.ob_name_practic_name || !value?.child_birth_weight
            || !value?.medical_surgical_history_mother || !value?.pediatrician_name_practic_name || !value?.privacy_practises
            || !value?.elaborate_on_your_breastfeeding
            || !term_condition
            || !value?.delivery_type
        ) {
            let errors: any = {}
            if (!value?.child_birth_weight) {
                errors.Child_birth_weight = "this input the value"
            }
            if (!value?.due_date) {
                errors.due_date = "Please select date"
            }
            if (value?.due_date === "Invalid date") {
                errors.due_date = "Please select date"
            }
            if (!value?.is_your_first_baby) {
                errors.is_your_first_baby = "Please input the value"
            }
            if (!value?.medical_surgical_history_baby) {
                errors.medical_surgical_history_baby = "Please input the value"
            }

            if (!value?.mother_dob) {
                errors.mother_dob = "Please input the value"
            }
            if (!value?.ob_name_practic_name) {
                errors.ob_name_practic_name = "Please input the value"
            }
            if (!value?.pediatrician_name_practic_name) {
                errors.pediatrician_name_practic_name = "Please input the value"
            }
            if (!value?.elaborate_on_your_breastfeeding) {
                errors.elaborate_on_your_breastfeeding = "Please input the value"
            }
            if (!value?.delivery_type) {
                errors.delivery_type = "Please input the value"
            }
            if (!value?.medical_surgical_history_mother) {
                errors.medical_surgical_history_mother = "Please input the value"
            }
            if (!term_condition) {
                errors.term_condition = "Please the select the policy to continue "
            }

            if (!value?.client_service_agreement) {
                errors.client_service_agreement = "Please select the policy to continue"
            }
            if (!value?.privacy_practises) {
                errors.privacy_practises = "Please select the policy to continue"
            }

            setError({ ...errors, ...stateCityZipErr })

            return
        }
        handleNext()
    }

    return (
        <>
            <section className='step-container step2 user-info'>
                {serviceParam === "service_2" && <div>
                    <div className='consult-section-heading text-center'>
                        <h3 className='my-5'>Complete your Information</h3>
                    </div>
                    <form className=' '>
                        <div className='row'>
                            <div className="form-group col-lg-12 col-md-12 mb-3 due_dt_container">
                                <label >Due Date/Child's Birthday <span className='mandatory text-danger mx-2'>*</span></label>
                                <LocalizationProvider dateAdapter={AdapterDayjs} >

                                    <Stack spacing={3}>
                                        <MobileDatePicker
                                            value={value?.due_date || ""}
                                            onChange={(date: any) => dateHandler(date, "due_date")}
                                            renderInput={(params: any) => <TextField {...params} placeholder="MM-DD-YYYY" className='due-date-field' />}
                                        />
                                    </Stack>
                                </LocalizationProvider>
                                <h4 className="error-msgtext">{error?.due_date}</h4>
                            </div>

                            <div className="form-group col-lg-12 col-md-12 mb-3">
                                <label>Child's Name: <span className="fw-light"> (if applicable)</span></label>
                                <input type="text" name='child_name' className='form-control'
                                    value={value?.child_name} onChange={handleChange}
                                />
                            </div>
                            <CityStateZip value={value} handleChange={handleChange} error={error} />
                            <div className="form-group col-lg-12 col-md-12 mb-3">
                                <label>Is this your first baby? <span className='mandatory text-danger mx-2'>*</span></label>
                                <div className="custom-radio custom-control-inline">
                                    <input type="radio" id="customRadio1"
                                        name="is_your_first_baby" value="yes" onChange={handleChange} checked={value?.is_your_first_baby === "yes"}
                                        className="custom-control-input form-check-input"

                                    />
                                    <label className="custom-control-label me-3" htmlFor="customRadio1">yes</label>
                                    <input type="radio" id="customRadio2" name="is_your_first_baby"
                                        value="no" className="custom-control-input form-check-input"
                                        checked={value?.is_your_first_baby === "no"}
                                        onChange={handleChange}
                                    />
                                    <label className="custom-control-label step_two" htmlFor="customRadio2">no</label>
                                </div>
                                <h4 className="error-msgtext">{error?.is_your_first_baby}</h4>
                            </div>
                            <div className="form-group col-lg-12 col-md-12 mb-3">
                                <label>Phone Number <span className='mandatory text-danger mx-2'>*</span></label>
                                <input type="text" name='phone' value={value?.phone} className='form-control' onChange={handleChange} />
                                <h4 className="error">{error?.phone}</h4>
                            </div>
                            <div className="form-group col-lg-12 col-md-12 mb-3">
                                <label>Please list any significant medical or surgical health information for mom or baby that we should be aware of, including any medications or known allergies <span className='fw-light'> (to medications or otherwise): </span> <span className='mandatory text-danger mx-2'>*</span></label>
                                <input type="text" className='form-control' onChange={handleChange} value={value?.significant_or_surgical_health_information}
                                    name="significant_or_surgical_health_information" placeholder='Enter' />
                                <h4 className="error-msgtext">{error?.significant_or_surgical_health_information}</h4>
                            </div>

                            <div className="form-group col-lg-12 col-md-12 mb-3">
                                <label>What specific questions, concerns, and/or topics would you like to discuss during your consultation? <span className='mandatory text-danger mx-2'>*</span></label>
                                <textarea className='form-control' onChange={handleChange} value={value?.specific_question_concerns_topic}
                                    name="specific_question_concerns_topic" placeholder='Type something' />
                                <h4 className="error-msgtext">{error?.specific_question_concerns_topic}</h4>
                            </div>

                            <div className='agreement-container py-4 d-flex flex-column'>
                                <div>
                                    <div className="custom-radio custom-control-inline">
                                        <input type="checkbox" id="customRadio1" name='term_condition'
                                            className="custom-control-input form-check-input" value="Accepted"
                                            checked={term_condition}
                                            onChange={policyHandler}
                                        />
                                        <label className="custom-control-label" htmlFor="customRadio1">I have read and agree to the <Link target="_blank" className='blue-link' to={path.TERMS} > terms of service</Link></label>
                                    </div>
                                    <h4 className="error-msgtext">{error?.term_condition}</h4>
                                </div>

                                <div>
                                    <div className="custom-radio custom-control-inline">
                                        <input type="checkbox" id="customRadio1" name='client_service_agreement'
                                            className="custom-control-input form-check-input" value="Accepted"
                                            checked={value?.client_service_agreement}
                                            onChange={handleChange}
                                        />
                                        <label className="custom-control-label" htmlFor="customRadio1">I have read and agree to the <Link target="_blank" className='blue-link' to={path.CLIENT_SERVICE_AGREEMENT} > client service agreement </Link></label>
                                    </div>
                                    <h4 className="error-msgtext">{error?.client_service_agreement}</h4>
                                </div>

                                <div>
                                    <div className="custom-radio custom-control-inline">
                                        <input type="checkbox" id="" name='privacy_practises'
                                            className="custom-control-input form-check-input" value="Accepted"
                                            checked={value?.privacy_practises}
                                            onChange={handleChange}
                                        />
                                        <label className="custom-control-label" htmlFor="">I have read and agree to the <Link target="_blank" className='blue-link' to={path.CONSENT_FOR_DISCLOSURE} > consent for disclosure </Link>and <Link target="_blank" className='blue-link' to={path.PRIVACY} > privacy practices</Link></label>
                                    </div>
                                    <h4 className="error-msgtext">{error?.privacy_practises}</h4>
                                </div>
                            </div>
                        </div>
                    </form>

                    <div className='section-navigation my-5 d-flex justify-content-between'>
                        <button className='primary-blue-small-btn previous' onClick={handlePrev}>Previous</button>
                        <button className='primary-blue-small-btn next' onClick={nursingHandleSubmit}>Next</button>
                    </div>
                </div>}

                {serviceParam === "service_3" && < div >
                    <div className='consult-section-heading text-center'>
                        <h3 className='my-5'>Complete your Information</h3>
                    </div>
                    <form className=' '>
                        <div className='row'>
                            <div className="form-group col-lg-12 col-md-12 mb-3 due_dt_container">
                                <label >Due Date/Child's Birthday <span className='mandatory text-danger mx-2'>*</span></label>
                                <LocalizationProvider dateAdapter={AdapterDayjs} >
                                    <Stack spacing={3}>
                                        <MobileDatePicker
                                            value={value?.due_date || ""}
                                            onChange={(date: any) => dateHandler(date, "due_date")}
                                            renderInput={(params: any) => <TextField {...params} placeholder="MM-DD-YYYY" className='due-date-field' />}
                                        />
                                    </Stack>
                                </LocalizationProvider>
                                <h4 className="error-msgtext">{error?.due_date}</h4>
                            </div>

                            <div className="form-group col-lg-12 col-md-12 mb-3">
                                <label>Is this your First Baby? <span className='mandatory text-danger mx-2'>*</span></label>
                                <div className="custom-radio custom-control-inline">
                                    <input type="radio" id="customRadio1" name="is_your_first_baby" value="yes"
                                        className="custom-control-input form-check-input"
                                        onChange={handleChange} checked={value?.is_your_first_baby === "yes"}
                                    />
                                    <label className="custom-control-label me-3" htmlFor="customRadio1">yes</label>
                                    <input type="radio" id="customRadio2" name="is_your_first_baby"
                                        value="no" className="custom-control-input form-check-input"
                                        onChange={handleChange} checked={value?.is_your_first_baby === "no"}
                                    />
                                    <label className="custom-control-label step_two" htmlFor="customRadio2">no</label>
                                </div>
                                <h4 className="error-msgtext">{error?.is_your_first_baby}</h4>
                            </div>

                            <div className="form-group col-lg-12 col-md-12 mb-3">
                                <label>Child's Name: <span className="fw-light"> (if applicable)</span></label>
                                <input type="text" className='form-control' name='child_name'
                                    onChange={handleChange} value={value?.child_name} placeholder='Enter name'
                                />
                                <h4 className="error-msgtext">{error?.child_name}</h4>
                            </div>

                            <div className="form-group col-sm-12 mb-3">
                                <label>City <span className='mandatory text-danger mx-2'>*</span></label>
                                <input type="text" name='city'
                                    value={value?.city}
                                    className="form-control"
                                    onChange={handleChange}
                                />
                                <h4 className="error-msgtext">{error?.city}</h4>
                            </div>
                            <div className="form-group col-sm-12 mb-3">
                                <label>State <span className='mandatory text-danger mx-2'>*</span></label>
                                <input type="text" name='state'
                                    value={value?.state}
                                    className="form-control"
                                    onChange={handleChange}
                                />
                                <h4 className="error-msgtext">{error?.state}</h4>
                            </div>

                            <div className="form-group col-lg-12 col-md-12 mb-3">
                                <label>Partner or Support Person's Name <span className="fw-light"> (if applicable):</span></label>
                                <input type="text" name="partner_support_person_name"
                                    onChange={handleChange} value={value?.partner_support_person_name}

                                    className='form-control' />

                            </div>

                            <div className="form-group col-lg-12 col-md-12 mb-3">
                                <label>Partner or Support Person's Email address  <span className="fw-light"> (for reminders, content, and follow up): </span> </label>
                                <input type="text" name="partner_support_person_email_address"

                                    className='form-control' onChange={handleChange} value={value?.partner_support_person_email_address} placeholder='Enter' />

                            </div>

                            <div className="form-group col-lg-12 col-md-12 mb-3">
                                <label>Pediatrician's Name/Practice Name: <span className="mandatory text-danger mx-2">*</span></label>
                                <input type="text" name="pediatrician_name_practic_name" className='form-control'
                                    onChange={handleChange} value={value?.pediatrician_name_practic_name}

                                    placeholder='Enter' />
                                <h4 className="error-msgtext">{error?.pediatrician_name_practic_name}</h4>
                            </div>

                            <div className="form-group col-lg-12 col-md-12 mb-3">
                                <label>Child's Birth Weight <span className="mandatory text-danger mx-2">*</span></label>
                                <input type="text" name="child_birth_weight" className='form-control' onChange={handleChange} value={value?.child_birth_weight}
                                    placeholder='Enter' />

                                <h4 className="error-msgtext">{error?.Child_birth_weight}</h4>
                            </div>

                            <div className="form-group col-lg-12 col-md-12 mb-3">
                                <label>Medical and/or Surgical History for Baby: <span className="mandatory text-danger mx-2">*</span></label>

                                <textarea name="medical_surgical_history_baby" className="form-control" onChange={handleChange} value={value?.medical_surgical_history_baby} placeholder="Type something"> </textarea>
                                <h4 className="error-msgtext">{error?.medical_surgical_history_baby}</h4>
                            </div>

                            <div className="form-group col-lg-12 col-md-12 mb-3 due_dt_container">
                                <label >Mother's DOB:<span className='mandatory text-danger mx-2'>*</span></label>
                                <LocalizationProvider dateAdapter={AdapterDayjs} >
                                    <Stack spacing={3}>
                                        <MobileDatePicker
                                            value={value?.mother_dob || ""}
                                            onChange={(date: any) => dateHandler(date, "mother_dob")}
                                            renderInput={(params: any) => <TextField {...params} placeholder="MM-DD-YYYY" className='due-date-field' />}
                                        />
                                    </Stack>
                                </LocalizationProvider>
                                <h4 className="error-msgtext">{error?.mother_dob}</h4>
                            </div>

                            <div className="form-group col-lg-12 col-md-12 mb-3">
                                <label>OB's Name/Practice Name <span className="mandatory text-danger mx-2">*</span></label>
                                <input type="text" className='form-control' name="ob_name_practic_name"
                                    onChange={handleChange} value={value?.ob_name_practic_name}

                                    placeholder='Enter' />
                                <h4 className="error-msgtext">{error?.ob_name_practic_name}</h4>

                            </div>

                            <div className="mb-3 delivery_type">
                                <h4>Delivery Type <span className="mandatory text-danger mx-2">*</span></h4>
                                <div className=''>
                                    <div className="form-check d-flex">
                                        <input className="form-check-input" type="radio" name="delivery_type" onChange={handleChange} value="Vaginal Delivery"
                                            checked={value?.delivery_type === 'Vaginal Delivery'}
                                            id="customCheck1" />
                                        <label className="form-check-label" >
                                            Vaginal Delivery
                                        </label>
                                    </div>

                                    <div className="form-check d-flex">
                                        <input className="form-check-input" type="radio" name="delivery_type" onChange={handleChange} value="Vaginal Delivery Healthcare professional" checked={value?.delivery_type === 'Vaginal Delivery Healthcare professional'} id="customCheck1" />
                                        <label className="form-check-label" >
                                            Forcep/Vacccum Assisted Vaginal Delivery
                                        </label>
                                    </div>

                                    <div className="form-check d-flex">
                                        <input className="form-check-input" type="radio" name="delivery_type" onChange={handleChange} value="C-section planned" checked={value?.delivery_type === 'C-section planned'} id="customCheck1" />
                                        <label className="form-check-label" >
                                            C-section (planned)
                                        </label>
                                    </div>

                                    <div className="form-check d-flex">
                                        <input className="form-check-input" type="radio" name="delivery_type" onChange={handleChange} value="C-section unplanned" checked={value?.delivery_type === 'C-section unplanned'} id="customCheck1" />
                                        <label className="form-check-label" >
                                            C-section unplanned (urgent/emergent)
                                        </label>
                                    </div>

                                </div>
                                <h4 className="error-msgtext">{error?.delivery_type}</h4>
                            </div>

                            <div className="form-group col-lg-12 col-md-12 mb-3">
                                <label>Medical and/or Surgical History for Birthing Mom: <span className="mandatory text-danger mx-2">*</span></label>
                                <input type="text" className='form-control'
                                    name="medical_surgical_history_mother"
                                    placeholder='Enter history'
                                    value={value?.medical_surgical_history_mother} onChange={handleChange}
                                />
                                <h4 className="error-msgtext">{error?.medical_surgical_history_mother}</h4>
                            </div>
                            <div className="form-group col-lg-12 col-md-12 mb-3">
                                <label>Please elaborate on your Breastfeeding and/or Feeding concerns: <span className="mandatory text-danger mx-2">*</span></label>

                                <textarea name="elaborate_on_your_breastfeeding" className="form-control" onChange={handleChange} value={value?.elaborate_on_your_breastfeeding} placeholder="Type something"> </textarea>
                                <h4 className="error-msgtext">{error?.elaborate_on_your_breastfeeding}</h4>
                            </div>

                            <div className='agreement-container py-4 d-flex flex-column'>
                                <div>
                                    <div className="custom-radio custom-control-inline">
                                        <input type="checkbox" id="customRadio1" name='term_condition'
                                            className="custom-control-input form-check-input" value="Accepted"
                                            checked={term_condition}
                                            onChange={policyHandler}
                                        />
                                        <label className="custom-control-label" htmlFor="customRadio1">I have read and agree to the <Link target="_blank" className='blue-link' to={path.TERMS} > terms of service</Link></label>
                                    </div>
                                    <h4 className="error-msgtext">{error?.term_condition}</h4>
                                </div>

                                <div>
                                    <div className="custom-radio custom-control-inline">
                                        <input type="checkbox" id="customRadio1" name='client_service_agreement'
                                            className="custom-control-input form-check-input" value="Accepted"
                                            checked={value?.client_service_agreement}
                                            onChange={handleChange}
                                        />
                                        <label className="custom-control-label" htmlFor="customRadio1">I have read and agree to the <Link target="_blank" className='blue-link' to={path.CLIENT_SERVICE_AGREEMENT} > client service agreement </Link></label>
                                    </div>
                                    <h4 className="error-msgtext">{error?.client_service_agreement}</h4>
                                </div>

                                <div>
                                    <div className="custom-radio custom-control-inline">
                                        <input type="checkbox" id="" name='privacy_practises'
                                            className="custom-control-input form-check-input" value="Accepted"
                                            checked={value?.privacy_practises}
                                            onChange={handleChange}
                                        />
                                        <label className="custom-control-label" htmlFor="">I have read and agree to the <Link target="_blank" className='blue-link' to={path.CONSENT_FOR_DISCLOSURE} > consent for disclosure </Link>and <Link target="_blank" className='blue-link' to={path.PRIVACY} > privacy practices</Link></label>
                                    </div>
                                    <h4 className="error-msgtext">{error?.privacy_practises}</h4>
                                </div>
                            </div>
                        </div>
                    </form>

                    <div className='section-navigation my-5 d-flex justify-content-between'>
                        <button className='primary-blue-small-btn' onClick={handlePrev}>Previous</button>
                        <button className='primary-blue-small-btn next' onClick={intilaLactiationSubmit}>Next</button>
                    </div>
                </div>}
            </section >

        </>

    )
}

export default UserInfo;