import { getUserID } from "../../Service/getLocalStorage";
import httpService from "../../Service/httpService";

export const getQuestions = (params?: {
  show?: string;
  search?: string;
  page?: number | string;
  categories?: string[];
  stages?: string[];
}) => {
  const queryParams = new URLSearchParams();
  queryParams.append("user_id", getUserID());

  const { show, categories, stages, ...restParams } = params || {};

  if (show === "most-popular") {
    queryParams.append("rate", "1");
  }
  if (show === "my") {
    queryParams.append("my", "1");
  }
  if (categories) {
    categories.forEach((c) => queryParams.append("categories[]", c));
  }
  if (stages) {
    stages.forEach((c) => queryParams.append("stages[]", c));
  }
  if (restParams) {
    Object.entries(restParams).forEach(
      ([key, value]) => value && queryParams.append(key, `${value}`)
    );
  }

  return httpService.get(`ask-a-nurse?${queryParams}`);
};

export const getFilterOptions = () => {
  return httpService.get("tag/filter/course");
};

export const postChatCreate = ({
  title,
  message,
  categories,
  stages,
}: {
  title: string;
  message: string;
  categories: string[];
  stages: string[];
}) => {
  return httpService.post(`ask-a-nurse/create`, {
    title,
    message,
    categories,
    stages,
    user_id: getUserID(),
  });
};

export const postDeleteChat = ({ id }: { id: string | number }) => {
  return httpService.post(`ask-a-nurse/delete/${id}`, {
    user_id: getUserID(),
  });
};

export const postToggleLikeChat = ({ id }: { id: string | number }) => {
  return httpService.post(`ask-a-nurse/like/${id}`, {
    user_id: getUserID(),
  });
};
