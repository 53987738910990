import React, { useEffect, useState } from "react";
//import LoginPopup from '../LoginPopup/LoginPopup';
import SignupPopup from "../../Utils/Popup/Signup";
import LoginPopup from "../../Utils/Popup/Login";
import EmailVerifyPopup from "../../Utils/Popup/emailVerifyPopup";

const Popup = (props: any) => {
  const { callBack, isPopup, setIsPopup, handleCLose } = props;
  const [isLogin, setIslogin] = useState<boolean>(false);
  const [isSignup, setIsSignup] = useState<boolean>(false);
  const [isVerifyEmail, setIsVerifyEmail] = useState<boolean>(false);
  const [data, setData] = useState<any>({});
  const isUser = JSON.parse(localStorage.getItem("Nurture_user_data")!);

  useEffect(() => {
    if (!isUser && !isPopup) {
      props.signUpFirst ? setIsSignup(true) : setIslogin(true);
    } else if (!isPopup && isUser && isUser?.is_email_verify == 0) {
      setIsVerifyEmail(true);
    }
  }, [isUser, isPopup, props.signUpFirst]);

  const HandleCLose = () => {
    setIsPopup(true);
  };
  return (
    <div>
      <LoginPopup
        isLogin={isLogin}
        setIsSignup={setIsSignup}
        setIslogin={setIslogin}
        callBack={callBack}
        handelClose={handleCLose || HandleCLose}
      />
      <SignupPopup
        isSignup={isSignup}
        setIsSignup={setIsSignup}
        setData={setData}
        setIsVerifyEmail={setIsVerifyEmail}
        setIslogin={setIslogin}
        handelClose={handleCLose || HandleCLose}
      />
      <EmailVerifyPopup
        isVerifyEmail={isVerifyEmail}
        data={data}
        setIsVerifyEmail={setIsVerifyEmail}
        callBack={callBack}
        handelClose={handleCLose || HandleCLose}
      />
    </div>
  );
};

export default Popup;
