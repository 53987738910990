
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Form } from 'react-bootstrap';

function MultipleSlots({ objName, hostObj, data, handleNext,
    setPriceDisplay, priceDisplay, setPriceArry, classTime, setClassTime, value,
    setValue, price, slots, setSlots, priceArry, addOn, setAddOn, selectClasses, setSelectClasses, selectSlots, setSelectSlots }: any) {

    const [error, setError] = useState<any>({})
    const [errorAdd, setErrorAdd] = useState<any>([])
    const [selectSlot, setSelectSlot] = useState<any>([])

    const disPlayTime = (date: any) => {
        const Cdate = new Date(date);
        let converted_date = Cdate.toLocaleString('en-US', {
            timeZone: 'America/New_York',
        })
        return moment(converted_date).format("llll")
    }
    const displayDate = (date: any) => {
        const Cdate = new Date(date);
        let converted_date = Cdate.toLocaleString('en-US', {
            timeZone: 'America/New_York',
        })
        return moment(converted_date).format("dddd DD MMM YYYY")
    }
    const convertDateToUtc = (data: any) => {
        const Cdate = new Date(data);
        let converted_date = Cdate.toLocaleString('en-US', {
            timeZone: 'America/New_York',
        })
        return moment(converted_date).format("dddd DD MMM YYYY")
    }


    const handlePrice = async (e: any, data: any, errorCls: any, index: any) => {
        let isChecked = e.target.checked;
        let values = data;
        let disPrice;
        let discount;
        
        if (!selectSlots[index]) {
            setErrorAdd({
                ...errorAdd,
                [index]: {
                    ["app"]: "Please select time"
                }
            })
            e.target.checked = false
            return;
        }

        setSelectSlots({
            ...selectSlots,
            [index]: {
                ...selectSlots[index],
                isChecked
            }
        })

        if (isChecked) {
            priceArry[index] = {
                id: e.target.value,
            }
            await setPriceArry({
                ...priceArry
            })

            if (Object.keys(priceArry).length === 1) {

                discount = (Number(price) + Number(values.price)) * 10 / 100
                disPrice = Number(price) + Number(values.price) - discount;
                setPriceDisplay(
                    {
                        ...priceDisplay,
                        ["price"]: Number(price) + Number(values.price),
                        ["actualPrice"]: disPrice
                    }
                )
            }
            if (Object.keys(priceArry).length === 2) {

                discount = (Number(priceDisplay.price) + Number(values?.price)) * 15 / 100;
                disPrice = Number(priceDisplay.price) + Number(values?.price) - discount;
                setPriceDisplay({
                    ...priceDisplay,
                    ["price"]: Number(priceDisplay.price ? priceDisplay.price : 0) + Number(values.price),
                    ["actualPrice"]: disPrice
                })
            }
            if (Object.keys(priceArry).length === 3) {
                discount = (Number(priceDisplay.price) + Number(values?.price)) * 20 / 100;
                disPrice = Number(priceDisplay.price) + Number(values?.price) - discount;
                setPriceDisplay({
                    ...priceDisplay,
                    ["price"]: Number(priceDisplay.price ? priceDisplay.price : 0) + Number(values.price),
                    ["actualPrice"]: disPrice
                })
            }
            addOn[index] = {
                name: selectClasses[index]?.name
            }

            await setAddOn({
                ...addOn
            })
        } else {
            if (Object.keys(priceArry).length === 1) {
                setPriceDisplay({
                    ...priceDisplay,
                    ["price"]: Number(values.price),
                    ["actualPrice"]: Number(values.price)
                })
            }
            if (Object.keys(priceArry).length === 2) {
                discount = (Number(priceDisplay.price) - Number(values?.price)) * 10 / 100;
                disPrice = Number(priceDisplay.price) - Number(values?.price) - discount;

                setPriceDisplay({
                    ...priceDisplay,
                    ["price"]: Number(priceDisplay.price) - Number(values.price),
                    ["actualPrice"]: disPrice

                })
            }
            if (Object.keys(priceArry).length === 3) {

                discount = (Number(priceDisplay.price) - Number(values?.price)) * 15 / 100;
                disPrice = Number(priceDisplay.price) - Number(values?.price) - discount;
                setPriceDisplay({
                    ...priceDisplay,
                    ["price"]: Number(priceDisplay.price ? priceDisplay.price : 0) - Number(values.price),
                    ["actualPrice"]: disPrice

                })
            }
            delete priceArry[index]
            setPriceArry({
                ...priceArry
            })

            delete selectSlots[index]
            setSelectSlots({ ...selectSlots })

            delete selectClasses[index]
            setSelectClasses({ ...selectClasses })
            delete addOn[index]
            setAddOn({ ...addOn })
        }

    }

    const handleVirtualClass = () => {
        let bookSlots: any = []
        if (Object.keys(selectSlots).length > 0) {
            for (const prop in selectSlots) {
                if (selectSlots[prop]?.isChecked) {
                    bookSlots.push({
                        appointment_date_time: selectSlots[prop].appointment_date_time, appointment_type_id: selectSlots[prop].appointment_type_id, calendar_id: selectSlots[prop].calendar_id
                        , name: selectSlots[prop]?.name
                    })
                }
            }
        }
        if (bookSlots.length > 0) {
            setValue({
                ...value,
                ["addon_class"]: bookSlots,
                ["service_name"]: hostObj?.name,
                ["amount"]: priceDisplay.actualPrice ? priceDisplay.actualPrice : price,
                ["all_price"]: priceDisplay.price ? priceDisplay.price : price,
                ["additional_amount"]: priceDisplay.actualPrice ? priceDisplay.actualPrice - price : 0,
                ["total_ammount"]: priceDisplay.actualPrice ? priceDisplay.actualPrice : price,
                ["addition_class"]: addOn,
                ["slot_time"]: addOn,
                ["someCheck_id"]: addOn
            })
        } else {
            setValue({
                ...value,
                ["service_name"]: hostObj?.name,
                ["amount"]: priceDisplay.actualPrice ? priceDisplay.actualPrice : price,
                ["all_price"]: price,
                ["additional_amount"]: 0,
                ["total_ammount"]: priceDisplay.price ? priceDisplay.price : price,
                ["addition_class"]: addOn,
                ["slot_time"]: addOn,
                ["someCheck_id"]: addOn
            })
        }


        handleNext()
    }
    useEffect(() => {
        const optionslot = data.map((val: any, i: any) => displayDate(val.time))
        setSelectSlot(optionslot)
    }, [])

    useEffect(() => {
        const optionslot = data.map((val: any, i: any) => val.time)
        setSelectSlot(optionslot)
    }, [])


    const selectTime = (e: any, i: number, value: any) => {
        setErrorAdd({
            ...errorAdd,
            [i]: {
                ["app"]: ""
            }
        })
        let allData = value.multipleSlots.find((res: any) => res.time === e.target.value)

        setSelectSlots({
            ...selectSlots,
            [i]: {
                ["appointment_date_time"]: allData?.time,
                ["appointment_type_id"]: value?.appointmentTypeID,
                ["calendar_id"]: allData?.calendarID,
                ["name"]: allData?.name,
                isChecked: !!selectSlots[i]
            }
        })
    }
    console.log("select slots", selectSlots)
    return (
        <div>
            <section className='step-container step1'>
                <div className="consult-card-container">
                    <div className='d-flex pt-3 px-3 justify-content-between'>
                        <div className='consult-info d-flex flex-column w-100'>
                            <div className='time-projection-details d-flex'>
                                <div className='time-projection'>{moment(objName?.time).startOf('day').fromNow()}</div>
                                <div className='availablity'>{objName?.slotsAvailable}/{objName?.slots} left</div>
                            </div>
                            <div className='consult-date-time d-flex justify-content-between mt-3 mb-1'>
                                <div className='consult-date'>
                                    {convertDateToUtc(objName?.time)}
                                </div>
                                <div className='consult-price'>
                                    <span className='price'>${objName?.price}</span>
                                </div>
                            </div>
                            <div className='consult-tm-dtls mb-3'>
                                <span className='consult-time'><span className='strong'> {disPlayTime(objName?.time)} EST</span>  </span>
                                <span className='host-name'>| by {objName?.calendar}</span>
                            </div>
                        </div>

                    </div>

                    <div className='additional-class pb-3 px-3'>
                        <hr />
                        <h2 className='text-start pb-0 mb-2 add-head' >Bundle and Save</h2>
                        <p className='additional-class-discount'>( 10% off on 1 additional class, 15% off on 2 additional classes AND 20% of on all additional classes ) </p>
                        {/* <p>Add on additional classes to your Infant and Child CPR course including <strong>Labor Prep, Newborn Essentials, and/or Breastfeeding Basics.</strong></p> */}
                        <hr />

                        {
                            data?.map((val: any, i: any) => {
                                return (
                                    <div key={i} className={`d-flex align-items-center pt-2 pb-md-3 ${data.length -1 === i ? '' : 'border-bottom'}`}>
                                        <div className='ms-auto me-0'>
                                            <input
                                                type='checkbox'
                                                className='form-check-input align-self-md-end mb-3'
                                                name='price'
                                                value={val.appointmentTypeID}
                                                onChange={(e) => handlePrice(e, val, error[i], i)}
                                                checked={selectSlots[i]?.isChecked}
                                            />
                                        </div>
                                        <div className='class-list w-100 d-flex flex-column flex-md-row mb-3 mb-md-0 align-items-end'>
                                            <div className='class-list-inner w-100 d-flex flex-column flex-md-row align-items-center'>
                                                <div className="class-container">

                                                    <label>Select Class {i + 1}</label>
                                                    <p>{val?.name}</p>
                                                    <h4 className="error-msgtext">{errorAdd[i]?.class}</h4>

                                                </div>

                                                <div className="appointment-container">

                                                    <label>Select Appointment</label>
                                                    <Form.Select aria-label="Default select example" onChange={(e) => selectTime(e, i, val)} >
                                                        <option>Select Appoinment</option>
                                                        {val?.multipleSlots.map((clVal: any) => (
                                                            <option
                                                                selected={selectSlots[i]?.appointment_date_time === clVal?.time}
                                                                value={clVal.time}>{clVal.appointment_type_id && convertDateToUtc(clVal.time)} {disPlayTime(clVal.time)}</option>
                                                        ))}

                                                    </Form.Select>
                                                    <h4 className="error-msgtext">{errorAdd[i]?.app}</h4>

                                                </div>

                                                <div className='price-container d-flex flex-row flex-grow-1 justify-content-end justify-content-md-center'>
                                                    <div className='additional-cost'><span className=''></span><span className='strong actual-price'>+${val?.price}</span></div>
                                                </div>
                                            </div>
                                            <h4 className="error-msgtext">{error?.errorIndex}</h4>
                                        </div>
                                    </div>
                                )
                            })
                        }

                        <hr />
                        <div className='total-amt d-flex justify-content-between'>
                            <div className='total-amount'>Total <span className='text-decoration-line-through me-1 total-actual-value'>{priceDisplay.price > priceDisplay.actualPrice && priceDisplay?.price}</span><span className='total-discounted'>${priceDisplay.actualPrice ? priceDisplay.actualPrice : price}</span></div>
                            <div><button className='primary-blue-small-btn-40 btn' onClick={() => handleVirtualClass()}>Buy Now</button></div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default MultipleSlots
