// export const NURSING_URL = "/nursing";
// export const LACTATION_URL = "/lactation";
export const CALL30MIN_URL = "/30-min-call";
// export const CALL30MIN_NURSE_URL = "/30-min-nurse-call";
// export const CALL30MIN_PROS_URL = "/30-min-call-prospective";
export const PREBABYBOOT_URL = "/pre-baby-bootcamp";
export const LABOUR_DELEVERY_URL = "/labor-delivery-prep-class";
export const NEW_BORN_URL = "/newborn-essentials";
export const BREASTFEEDING_BASICS_URL = "/breastfeeding-basics";
export const BREASTFEEDING_SUPPORT_URL = "/free-breastfeeding-support-group";
export const PRIVATE_SLEEP_CONSULT_URL = "/private-sleep-consult";
export const GROUP_SLEEP_CONSULT_URL = "/monthly-sleep-group";
export const VIRTUAL_NURSE_COSNULT_URL = "/phone-consult";
export const NEW_MOM_S_GROUP_URL = "/moms-survival-guide";
export const PREGNANT_MOM_S_GROUP_URL = "/pregnant-moms-survival-guide";
export const VETERAN_BOOTCAMP_URL = "/veteran-bootcamp";
export const DAD_S_GROUP_URL = "/dads-survival-guide";
export const V_MOM_S_GROUP_URL = "/veteran-moms-survival-guide";
export const W_MOM_S_GROUP_URL = "/working-moms-survival-guide";
export const INFANT_CHILD_CPR_URL = "/infant-child-cpr";
// export const INFANT_CHILD_CPR_URL_PRIVATE = "/infant-child-cpr-private";
// export const DAYTIME_OVERNIGHT_URL = "/daytime&overnight-infant-care";
export const REMOTE_LACTATION_URL = "/lactation-consult-request";
export const DAY_OF_DISCHARGE_URL = "/welcome-home";

export const serviceRoutesList: string[] = [
  // NURSING_URL,
  // LACTATION_URL,
  CALL30MIN_URL,
  // CALL30MIN_NURSE_URL,
  // CALL30MIN_PROS_URL,
  PREBABYBOOT_URL,
  LABOUR_DELEVERY_URL,
  NEW_BORN_URL,
  BREASTFEEDING_BASICS_URL,
  BREASTFEEDING_SUPPORT_URL,
  PRIVATE_SLEEP_CONSULT_URL,
  GROUP_SLEEP_CONSULT_URL,
  VIRTUAL_NURSE_COSNULT_URL,
  NEW_MOM_S_GROUP_URL,
  PREGNANT_MOM_S_GROUP_URL,
  VETERAN_BOOTCAMP_URL,
  DAD_S_GROUP_URL,
  V_MOM_S_GROUP_URL,
  W_MOM_S_GROUP_URL,
  INFANT_CHILD_CPR_URL,
  // INFANT_CHILD_CPR_URL_PRIVATE,
  // DAYTIME_OVERNIGHT_URL,
  REMOTE_LACTATION_URL,
  DAY_OF_DISCHARGE_URL,
];
