import React from "react";
import { Row } from "react-bootstrap";
import { formatter } from "../../../../Common/PaymentMethod/StripeForm/helpers";

const StripeForm = ({
  fields,
  errors,
  handleChange,
  handleNumber,
  handleExpirationDate,
}: any) => {
  return (
    <div className="paymentModalPopUpAddCardForm">
      <Row className="mt-3 ">
        <div className="col-md-6">
          <label>First Name</label>
          <input
            type="text"
            name="first_name"
            value={fields.first_name}
            onChange={handleChange}
            className="stripeFormInput"
            placeholder="First name"
          />
          <p className="error">{errors.first_name}</p>
        </div>
        <div className="col-md-6">
          <label>Last Name</label>
          <input
            type="text"
            name="last_name"
            value={fields.last_name}
            className="stripeFormInput"
            onChange={handleChange}
            placeholder="Last name"
          />
          <p className="error">{errors.last_name}</p>
        </div>
      </Row>
      <div className="col-sm-12">
        <label> Credit card number</label>
        <input
          type="text"
          name="cardNumber"
          value={fields.cardNumber}
          className="stripeFormInput"
          onChange={handleNumber(16)}
          placeholder="xxxxxxxxxxxx - xxxx"
        />
        <p className="error">{errors.cardNumber}</p>
      </div>

      <Row className="mt-3">
        <div className="col-md-4">
          <label>Expiration</label>
          <input
            type="text"
            name="exp"
            value={formatter(fields.exp)}
            onChange={handleExpirationDate}
            className="stripeFormInput"
            placeholder="05 / 22"
          />
          <p className="error">{errors.exp}</p>
        </div>
        <div className="col-md-4">
          <label>CVV</label>
          <input
            type="text"
            name="cvv"
            value={fields.cvv}
            className="stripeFormInput"
            placeholder="x x x x"
            onChange={handleNumber(4)}
          />
          <p className="error">{errors.cvv}</p>
        </div>
        <div className="col-md-4">
          <label>Billing Zip Code</label>
          <input
            type="text"
            name="zip_code"
            value={fields.zip_code}
            className="stripeFormInput"
            onChange={handleChange}
            placeholder="90210"
          />
          <p className="error">{errors.zip_code}</p>
        </div>
      </Row>
    </div>
  );
};



export default StripeForm;
