import React from "react";
import { scheme, addressScheme, handleDate, yupValidation } from "../Components/PaymentMethodForm/helpers";
import { getCountries, getStates } from "country-state-picker";

interface UsePaymentMethodFormProps {
  paymentRequest?: (params: any) => void;
}

interface Errors {
  [key: string]: number;
}

export interface PaymentFields {
  firstName: string;
  lastName: string;
  exp: string;
  cvv: string;
  zipCode: string;
  cardNumber: string;
  country: string | null;
  state: string | null;
  address: string;
  city: string;
}

const usePaymentMethodForm = ({
  paymentRequest,
}: UsePaymentMethodFormProps) => {
  const [fields, setFields] = React.useState<PaymentFields>({
    firstName: "",
    lastName: "",
    exp: "",
    cvv: "",
    zipCode: "",
    cardNumber: "",
    country: "United States of America",
    state: "",
    address: "",
    city: "",
  });

  const [errors, setErrors] = React.useState<Errors>({});

  const [statesList, setStatesList] = React.useState<any[]>([]);

  const handleChange = (evt: any) => {
    setFields((v) => ({ ...v, [evt.target.name]: evt.target.value }));
  };

  const handleNumber = (max: number) => (evt: any) => {
    setFields((v) => ({
      ...v,
      [evt.target.name]: evt.target.value.slice(0, max),
    }));
  };

  const handleExpirationDate = (evt: any) => {
    setFields((v) => ({
      ...v,
      [evt.target.name]: handleDate(evt.target.value),
    }));
  };

  const submit = async (e?: React.FormEvent<HTMLFormElement>) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    const { valid, errors } = await yupValidation(fields, {
      ...scheme,
      ...addressScheme,
    });

    setErrors(errors);

    if (valid) {
      const { firstName, lastName, exp, ...restFields } = fields;
      const [expMonth, expYear] = [exp.slice(0, 2), exp.slice(2)];

      const params = {
        ...restFields,
        cardholderName: `${firstName} ${lastName}`,
        expMonth,
        expYear,
      };

      paymentRequest && paymentRequest(params);
    }
  };

  const changeState = (_: any, value: string | null) => {
    setFields((v) => ({ ...v, state: value }));
  };

  const changeCountry = (_: any, value: string | null) => {
    setFields((v) => ({ ...v, country: value, state: "" }));
  };

  React.useEffect(() => {
    const getStatesList = (countryCode: string) => getStates(countryCode);

    const countryCode = getCountries().find(
      ({ name }: { name: string }) => name === fields.country
    );

    countryCode
      ? setStatesList(getStatesList(countryCode.code))
      : setStatesList([]);
  }, [fields.country]);

  return {
    fields,
    errors,
    handleChange,
    handleNumber,
    handleExpirationDate,
    submit,
    countriesList: getCountries().map((c: { name: string }) => c.name),
    statesList,
    changeState,
    changeCountry,
  };
};

export default usePaymentMethodForm;
