import React from "react";
import "react-toastify/dist/ReactToastify.css";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import CloseIcon from "@mui/icons-material/Close";
import { CircularProgress, IconButton } from "@mui/material";
import "./confirmDeletePaymentMethodPopUp.scss";

const ConfirmDeletePaymentMethodPopUp = ({
  isVisible,
  closeModal,
  removePaymentMethod,
  removePaymentMethodRequest,
  clearRemovePaymentMethodRequest,
  id,
}: any) => {
  const removeCard = () => removePaymentMethodRequest(id);

  React.useEffect(() => {
    if (removePaymentMethod.success) {
      clearRemovePaymentMethodRequest();
      closeModal();
    }
  }, [clearRemovePaymentMethodRequest, closeModal, removePaymentMethod.success]);

  return (
    <div>
      <Dialog
        open={isVisible}
        onClose={closeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="modal-500"
      >
        <DialogContent>
          <IconButton
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon onClick={closeModal} />
          </IconButton>

          <DialogContentText>
            <div className="d-flex justify-content-center flex-wrap">
              <h2 className="memberPopUpTitle">Remove credit card?</h2>
              <p className="my-4">
                Are you sure you want to delete the selected credit card?
              </p>
              <button
                className="btn primary-blue-btn"
                onClick={removeCard}
                disabled={removePaymentMethod.pending}
              >
                {removePaymentMethod.pending ? (
                  <CircularProgress size={30} />
                ) : (
                  "Confirm"
                )}
              </button>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ConfirmDeletePaymentMethodPopUp;
