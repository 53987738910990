import React from "react";
import "./BHHero.scss";
import { Link } from "react-router-dom";

interface Props {
  type: "BH" | "SC";
}

const BHHero = ({ type }: Props) => {
  return (
    <div className={type === "BH" ? "BHHeroWrapper" : "SCHeroWrapper"}>
      <div className="BHHeroContent">
        <div
          className={
            type === "BH" ? "BHHeroContent__bhlogo" : "BHHeroContent__scLogo"
          }
        />
        {type === "SC" && <div className="BHHeroContent__scLogoMobile" />}
        <p className="BHHeroContent__text">
          NAPS™ provides trustworthy, uncensored support and coaching from
          honest, judgment-free Registered Nurses who are parents themselves—so
          parents can feel confident and capable as they raise the next
          generation of confident and capable kids!
          <br />
          <br />
          <strong className="BHHeroContent__strong-text">
            As a member of the{" "}
            {type === "BH" ? "Bright Horizon" : "Sitter City"} community, we’re
            offering you exclusive discounts on our classes, support groups, and
            virtual services.
          </strong>
          <br />
          <div className="BHHeroContentCheckOut">
            Check out our suggestions of services below, including our
            comprehensive Nurture by NAPS membership program. When you're ready,
            submit your information on the form below for your discount code!
          </div>
        </p>
        <Link
          className="btn primary-blue-small-btn-40 BHHeroContent__btn"
          to="/"
        >
          Learn More
        </Link>
        <div className="BHHeroCode">
          <h2>20% OFF</h2>
          <p>
            using code <strong>{type === "BH" ? "Bh20" : "Sc20"}</strong>
          </p>
        </div>
      </div>
    </div>
  );
};

export default BHHero;
