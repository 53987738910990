import React from "react";
import "./ServiceCard.scss";
import { Link } from "react-router-dom";

export interface ServiceGiftData {
  type: "service";
  service: string;
  price: string;
  title: string;
  description: string;
}
interface ServiceCardProps {
  title: string;
  description: string;
  src: any;
  price: string;
  selectGift: (data: ServiceGiftData) => void;
  gift: ServiceGiftData;
  url: string;
}

const ServiceCard = ({
  title,
  description,
  src,
  price = "0",
  selectGift,
  gift,
  url,
}: ServiceCardProps) => {

  return (
    <div className="ServiceCardMain">
      <img className="ServiceCardPicture" src={src} alt={title} />
      <h3 className="ServiceCardTitle">{title}</h3>
      <p className="ServiceCardDescription">{description}</p>
      <Link
        to={url}
        className="ServiceCardSeeMore"
      >
        View Details
      </Link>
      <div className="ServiceCardFooter">
        <div className="ServiceCardFooterPrice">${(+price).toFixed(2)}</div>
        <button
          className="btn primary-blue-small-btn-40"
          onClick={() => selectGift(gift)}
        >
          Send as a gift
        </button>
      </div>
    </div>
  );
};

export default ServiceCard;
