import React from "react";
import "./MemberPopUp.scss";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import { MEMBERSHIP_PRE_LOGIN } from "../../../Routes/RouteConstent";

const MemberPopUp = ({ isVisible, closeModal, title, description }: any) => {
  const Navigate = useNavigate();
  const joinNow = () => {
    closeModal();
    Navigate(MEMBERSHIP_PRE_LOGIN);
  };

  return (
    <div>
      <Dialog
        open={isVisible}
        onClose={closeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="welcome-modal modal-500"
      >
        <DialogContent>
          <IconButton
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon onClick={closeModal} />
          </IconButton>

          <DialogContentText>
            <div className="d-flex justify-content-center flex-wrap">
              <h2 className="memberPopUpTitle">
                {title || 'This is for members only.'}
              </h2>

              <div className="text-center ">
                <p className="my-4 memberPopUpParagraph">
                  {description || "Would you like to sign up to become a member?"}
                </p>
                <button className="primary-blue-btn" onClick={joinNow}>
                  Join Now
                </button>
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </div>
  );
};

export default MemberPopUp;
