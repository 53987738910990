import {
  getSavedLeadId,
  removeSavedLeadId,
} from "../BrightHorizonsScreens/hooks/useBHApi";
import api from "./axios";

export const userSignup = async (data: any) => {
  const brighthorizons_id = getSavedLeadId();

  const request = await api.post(
    "signup",
    brighthorizons_id ? { ...data, brighthorizons_id } : data
  );

  if (
    brighthorizons_id &&
    request.data.success &&
    request?.data?.data?.brighthorizons_id === brighthorizons_id
  ) {
    removeSavedLeadId();
  }

  return request;
};

export const verifyOtp = (data: any) => {
  return api.post("verify/otp", data);
};

export const UserLogin = (data: any) => {
  return api.post("login", data);
};
export const UserForgotPassword = (email: any) => {
  return api.post("password/email", email);
};

export const ResetPassword = (data: any) => {
  return api.post("password/reset", data);
};

export const ResendEmailVerify = (data: any) => {
  return api.post("resend/otp", data);
};
export const submitEmail = (data: any) => {
  return api.post("submit-email", data);
};
