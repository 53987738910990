import React, { useState } from "react";
import { Container, Row } from "react-bootstrap";
import promises_banner from "../../Assets/img/Promises/Promises-banner.jpg";
import Registered_nurse from "../../Assets/img/Promises/Registered-nurse.png";
import "./Promises.scss";

import sponsor_info from "../../Assets/img/sponsors/Rectangle.png";
import do_not_harm from "../../Assets/img/Promises/do_not_harm.svg";
import Speak_truth from "../../Assets/img/Promises/Speak-truth.svg";
import Mouth_shut from "../../Assets/img/Promises/Mouth-shut.svg";
import Lead_curiosity from "../../Assets/img/Promises/Lead-curiosity.svg";
import no_normal from "../../Assets/img/Promises/no-normal.svg";
import { OURSTORY } from "../../Routes/RouteConstent";
import { useNavigate } from "react-router-dom";
import LandingDescription from "../../Common/LandingDescription";
import { HeroBanner } from "../../Common/HeroBanner";
import hero from "../../Assets/img/Promises/our-promise.jpg";
import ChessGridPromises from "../../Common/ChessGridPromises";

const Promises = () => {
  const Navigate = useNavigate();

  return (
    <div>
      <Container fluid className="promises-container">
        <div className="py-3">
          <HeroBanner hero={hero} />
        </div>

        <LandingDescription
          ySpace
          content={[
            <>
              Judgment is as common in parenthood as bodily fluids. And can
              appear out of nowhere and throw your day off course, too. Your
              family, in-laws, mail carriers, and Instagram feed all spew
              opinions about what you’re doing (or not doing) incorrectly. Not
              to mention being our own worst critics.{" "}
              <span className="strong">
                {" "}
                So we created a space to escape judgment so you can learn to
                trust your instincts.
              </span>
            </>,
          ]}
        />

        <Row className="py-4">
          <div className="col-lg-12 col-md-12 my-3">
            <Container fluid className="registered-nurse-main">
              <Row className="justify-content-center">
                <div className="col-lg-10 col-md-10">
                  <Container>
                    <Row className="justify-content-center flex-column flex-md-row my-5">
                      <div className="col-lg-5 col-md-5 col-sm-12 registered-nurse-imgsection ">
                        <img
                          src={Registered_nurse}
                          className="img-fluid pe-4"
                        />
                      </div>
                      <div className="col-lg-5 col-md-5 col-sm-12 py-5 registered-nurse-info">
                        <div className="d-flex flex-column">
                          <div>
                            <h3 className="text-center text-md-start">
                              Judgment-Free Parenting Support from{" "}
                              <span className="strong"> Registered Nurses</span>
                            </h3>
                          </div>
                          <div>
                            <p className="text-center text-md-start">
                              We provide trustworthy, uncensored support and
                              coaching from honest, judgment-free Registered
                              Nurses. Nurses who will both comfort you and also
                              tell you to get your sh!t together and stop
                              obsessively googling “does my baby hate me.” And
                              while our advice is based on our experience as
                              moms and as labor and delivery nurses, we believe
                              the real expert on your family’s needs is you.
                            </p>
                          </div>
                        </div>
                      </div>
                    </Row>
                  </Container>
                </div>
              </Row>
            </Container>
          </div>
        </Row>

        <Row className="py-4">
          <div className="">
            <Row className="justify-content-center">
              <h3 className="text-center promises-section-title my-2">
                Our <span className="strong red">Commitments</span>
              </h3>
              <p className="text-center">
                These are our commitments to ourselves and our community.{" "}
              </p>
            </Row>
            <ChessGridPromises
              className="my-5"
              promises={[
                {
                  description: (
                    <>
                      <span className="block">
                        First, do no harm.
                      </span>{" "}
                      Physically or emotionally. Temporarily or permanently.
                    </>
                  ), colorClass: 'greenText',
                  iconSrc: do_not_harm,
                },
                {
                  description: (
                    <>
                      <span>Speak your truth</span> so
                      parents can find theirs.
                    </>
                  ), colorClass: 'purpleText',
                  iconSrc: Mouth_shut,
                },
                {
                  description: (
                    <>
                      If you wouldn’t recommend it to your best friend,{" "}
                      <span>keep your mouth shut.</span>
                    </>
                  ), colorClass: 'yellowText',
                  iconSrc: Lead_curiosity,
                },
                {
                  description: (
                    <>
                      <span >Lead with curiosity.</span>{" "}
                      Parenting is tough enough without judgment—including from
                      ourselves...
                    </>
                  ),
                  colorClass: 'redText',
                  iconSrc: Speak_truth,
                },
                {
                  description: (
                    <>
                      Normalize the idea{" "}
                      <span className="brownText">there is no normal.</span>
                    </>
                  ), colorClass: 'brownText',
                  iconSrc: no_normal,
                },
              ]}
            />
          </div>
        </Row>

        <Row className="py-4">
          <div className="col-lg-12 col-md-12 ">
            <div className="container">
              <Row className="curious-main">
                <div className="col-lg-12">
                  <div className="read-story w-100 py-4 py-md-5 px-5 mb-5">
                    <div className="d-flex justify-content-center align-items-center flex-column mx-auto px-0 px-md-5 sec-caption">
                      <h2>
                        Still curious{" "}
                        <span className="head-bold">about us?</span>
                      </h2>
                      {/* <p className='mb-0'>Complete your profile and get a free 30 minute consult with one of our nurses.
                        </p> */}
                      <button
                        className="btn primary-blue-small-btn-40 mt-1 mt-md-4"
                        onClick={() => Navigate(OURSTORY)}
                      >
                        Read our story
                      </button>
                    </div>
                  </div>
                </div>
              </Row>
            </div>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default Promises;
