import React from "react";
import Wrapper from "./Components/Wrapper";
import MagnetHero from "./Components/MagnetHero";
import OwnersStory from "./Components/OwnersStory";
import SendGuideForm from "./Components/SendGuideForm";
import mentalHealth from "./assets/mentalHealth.png";

const MentalHealth = () => {
  return (
    <Wrapper>
      <MagnetHero image={mentalHealth}>
        <h3>Free download!</h3>
        <p>
          Feeling overwhelmed{" "}
          <span className="strong">with your new baby?</span>
        </p>
        <h1 className="mt-2 mt-md-5">
          Perinatal <div className="teal strong">Mental Health Resources</div>
        </h1>
      </MagnetHero>
      <SendGuideForm
        event="PerinatalMentalHealthDownload"
        description={
          <>
            Did you know that 1 in 4 women AND 1 in 10 men suffer from perinatal
            (conception through the first year after welcoming your baby) mental
            health disorders? It's important to understand the different
            disorders, the symtoms associated with all of them, emergency
            services, national support systems, how to find local support
            systems.{" "}
            <span className="strong">
              This document includes resources, links, and advice for parents
              experiencing postpartum mood disorders, as well as info for family
              members, friends, community members, and providers.
            </span>
          </>
        }
      />
      <OwnersStory />
    </Wrapper>
  );
};

export default MentalHealth;
