import React, { useMemo } from "react";
import useQuestions from "./useQuestions";
import "./askANurse.scss";
import AskQuestion from "./ask-question";
import Tabs from "./tabs";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Filter from "./filter";
import Question from "./question";
import { ASK_A_NURSE } from "../../Routes/RouteConstent";
import { CircularProgress, Pagination } from "@mui/material";
import useModalWindowControls from "../../Hooks/useModal";
import useRequestState from "../../Hooks/useRequestState";
import { postDeleteChat, postToggleLikeChat } from "./transport";
import ConfirmDeleteModal from "../../Common/confirm-delete-modal";
import { useSelector } from "react-redux";
import { newMessagesSelector } from "../../Redux/new-messages/newMessages";
import { getUser } from "../../Service/getLocalStorage";
import LockAskANurse from "./lock-ask-a-nurse/LockAskANurse";

const AskANurse = () => {
  const navigate = useNavigate();
  const { page } = useParams();
  const [searchParams, setSearchParams] = useSearchParams({});

  const categories = useMemo(
    () =>
      searchParams
        .get("category")
        ?.split(",")
        .filter((c) => !!c) || [],
    [searchParams]
  );

  const stages = useMemo(
    () =>
      searchParams
        .get("stages")
        ?.split(",")
        .filter((s) => !!s) || [],
    [searchParams]
  );

  const { questions, deleteChat, updateChatQuestion } = useQuestions({
    show: searchParams.get("show") || "most-popular",
    search: searchParams.get("search") || "",
    categories,
    stages,
    page: page || "1",
  });

  const updateActiveTab = (show: string) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set("show", `${show}`);
    setSearchParams(newSearchParams);
  };

  const updateFilter = (filter: { stages: string[]; categories: string[] }) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set("stages", filter.stages.filter((v) => v).join(","));
    newSearchParams.set(
      "category",
      filter.categories.filter((v) => v).join(",")
    );

    navigate(
      `/${ASK_A_NURSE}/1?${decodeURIComponent(newSearchParams.toString())}`
    );
  };

  const deleteChatModal = useModalWindowControls();
  const deleteChatRequestState = useRequestState({
    initData: {},
    onSuccess: () => {
      deleteChat(deleteChatModal.openModalData);
      deleteChatModal.closeModal();
    },
    asyncRequest: postDeleteChat,
  });

  const toggleLikeChatRequestState = useRequestState({
    initData: {},
    onSuccess: (res) => {
      updateChatQuestion(res.data);
    },
    asyncRequest: postToggleLikeChat,
  });

  const newMessages = useSelector(newMessagesSelector);

  const questionsWithNewMessages = useMemo(() => {
    return questions.data.map((question) => ({
      ...question,
      hasNewMessages: !!newMessages.data.includes(question.id),
    }));
  }, [questions.data, newMessages.data]);

  const updatePage = (nextPage: number) =>
    navigate(
      `/${ASK_A_NURSE}/${nextPage}?${decodeURIComponent(
        new URLSearchParams(searchParams).toString()
      )}`
    );

  if (!getUser()?.is_stripe_membership) {
    return <LockAskANurse />;
  }

  return (
    <>
      <ConfirmDeleteModal
        isVisible={deleteChatModal.isVisible}
        closeModal={deleteChatModal.closeModal}
        pending={deleteChatRequestState.requestState.pending}
        confirmBtnCB={() =>
          deleteChatRequestState.request({ id: deleteChatModal.openModalData })
        }
        title="Delete chat"
        description="Are you sure you want to delete this chat?"
      />
      <div className="askNurseWrapper">
        <AskQuestion />
        <div className="d-flex justify-content-center">
          <Tabs
            className="mt-4"
            active={searchParams.get("show") ?? "most-popular"}
            updateActiveTab={updateActiveTab}
            tabs={[
              { value: "most-popular", text: "Most Popular" },
              { value: "all", text: "All Questions" },
              { value: "my", text: "My Questions" },
            ]}
          />
        </div>
        <div className="askNurseArea">
          <Filter
            className="mt-4"
            savedCategories={categories}
            savedStages={stages}
            updateSavedData={updateFilter}
          />
        </div>
      </div>
      <div className="askNurseMain mt-4">
        <div className="askNurseQuestions">
          {questions.pending && (
            <div className="d-flex justify-content-center pt-4">
              <CircularProgress size={30} />
            </div>
          )}

          {questionsWithNewMessages?.map((question) => (
            <Question
              className="mt-4"
              question={question}
              key={question.id}
              onDelete={(id) => deleteChatModal.openModal(id)}
              onToggleLike={(id) => toggleLikeChatRequestState.request({ id })}
              hasNewMessages={question.hasNewMessages}
            />
          ))}

          {questions.currentPage && questions.lastPage && (
            <div className="d-flex justify-content-center mt-4">
              <Pagination
                page={questions.currentPage}
                count={questions.lastPage}
                onChange={(_, page) => updatePage(page)}
                variant="outlined"
                shape="rounded"
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default AskANurse;
